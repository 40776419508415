import { Ionicons } from "@expo/vector-icons";
import { SaveFormat, manipulateAsync } from "expo-image-manipulator";
import * as ImagePicker from 'expo-image-picker';
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import React, { forwardRef, useEffect, useState } from "react";
import { Cropper } from "react-advanced-cropper";
import "react-advanced-cropper/dist/style.css";
import { Controller } from "react-hook-form";
import { ActivityIndicator, TouchableOpacity, View } from "react-native";
import StyledText from "../common/StyledText";
import Hint from "../common/Hint";

const ImageField = forwardRef(({
	uri,
	control,
	initialValue,
	label,
	moreInfo,
	error = false,
	rules = {},
	containerProps = { style: { flex: 1 } },
	type = "text",
	zoomButtons = true,
	hint = false,
	// defaultSize,
	height = 200,
	stencilProps = {
		handlers: {},
		lines: {},
		movable: false,
		resizable: false,
		aspectRatio: 1,
	},
	...props
}, cropperRef) => {

	// const cropperRef = useRef()
	const [image, setImage] = useState(uri);
	const [loadingImage, setLoadingImage] = useState(false)
	const [fieldState, setFieldState] = useState({
		position: null,
		size: null,
		transforms: null
	})

	const storage = getStorage()

	const signImage = async (uri) => {
		const fileRef = ref(storage, uri)
		const p = await getDownloadURL(fileRef).then(res => res).catch(err => "")
		return p
	}

	useEffect(() => {

		if (initialValue && !initialValue?.includes("https") && !initialValue?.includes("http") && !initialValue?.includes("data:image")) {
			signImage(initialValue).then(res => setImage(res))
		}

	}, [initialValue])

	const getImage = (change) => {
		setLoadingImage(true)
		ImagePicker.launchImageLibraryAsync({
			allowsEditing: true,
			mediaTypes: ImagePicker.MediaTypeOptions.Images,
		}).then(async res => {
			if (!res.cancelled) {
				setFieldState({
					position: null,
					size: null,
					transforms: null
				})
				const manipResult = await manipulateAsync(
					res.assets[0].uri,
					[],
					{ compress: .5, format: SaveFormat.JPEG, base64: true }
				).catch(err => {
					throw new Error(err)
				})
				setImage(manipResult.uri)
				change(manipResult.uri)
			}
			setLoadingImage(false)
			return res
		}).catch(err => {
			console.log(err)
			alert("Error al cargar la imagen, formato incompatible")
			setLoadingImage(false)
			return null
		})

		setTimeout(() => {
			setLoadingImage(false)
		}, 3000)

	}

	const defaultSize = ({ imageSize, visibleArea }) => {
		return {
			width: (visibleArea || imageSize).width,
			height: (visibleArea || imageSize).height,
		};
	}

	return (
		<View {...containerProps} >
			{label && (
				<StyledText style={{ color: "gray", fontSize: 16, marginBottom: 10, display: 'flex', flexDirection: 'row', gap: 8, alignItems: 'flex-start' }}>
					{label}
					{
						hint && <Hint title={hint.title} rules={hint.rules} image={hint.image} color={hint.color} iconSize={hint.iconSize} />
					}
				</StyledText>
			)}
			

			<View style={{ flex: 1, marginBottom: 10, }}>
				<Controller
					{...props}
					control={control}
					rules={rules}
					render={({ field }) => {
						return (
							image ?
								<View style={{ width: "100%", height: height, borderRadius: 10, overflow: "hidden" }}>
									<Cropper
										ref={cropperRef}
										src={image}
										stencilProps={stencilProps}
										imageRestriction="stencil"
										autoZoom={true}
										stateSettings={fieldState}
										defaultSize={defaultSize}
										style={{ height: height - 45 }}
										// onChange={ev => field.onChange(ev.getImage())}
										// onTransformImageEnd={(ev) => field.onChange(ev.getCanvas().toDataURL())}
										// onMoveEnd={(ev) => field.onChange(ev.getCanvas().toDataURL())}
										// onChange={ev => field.onChange(ev.getImage().src)}
										className={'cropper'}
									/>

									<View style={{ height: 45, alignItems: "center", justifyContent: "space-between", backgroundColor: "#f2f2f2", flexDirection: "row" }}>
										<View style={{ flexDirection: "row" }}>
											<TouchableOpacity onPress={() => {
												field.onChange("")
												setImage("")
											}} style={{ zIndex: 10, width: 50, height: 50, alignItems: "center", justifyContent: "center" }}>
												<Ionicons name="trash" size={25} color="black" />
											</TouchableOpacity>

											<TouchableOpacity onPress={() => getImage(field.onChange)} style={{ width: 50, height: 50, alignItems: "center", justifyContent: "center" }}>
												{loadingImage ? <ActivityIndicator color="black" size="large" /> : <Ionicons name="ios-camera" size={25} color="black" />}
											</TouchableOpacity>
										</View>
										<View style={{ flexDirection: "row" }}>
											<TouchableOpacity onPress={() => cropperRef.current.rotateImage(90)} style={{ width: 50, height: 50, alignItems: "center", justifyContent: "center" }}>
												<Ionicons name="ios-reload" size={25} color="black" />
											</TouchableOpacity>
											{zoomButtons &&
												<>
													<TouchableOpacity onPress={() => cropperRef.current.zoomImage(1.2)} style={{ width: 50, height: 50, alignItems: "center", justifyContent: "center" }}>
														<Ionicons name="add-circle" size={25} color="black" />
													</TouchableOpacity>
													<TouchableOpacity onPress={() => cropperRef.current.zoomImage(.8)} style={{ width: 50, height: 50, alignItems: "center", justifyContent: "center" }}>
														<Ionicons name="remove-circle" size={25} color="black" />
													</TouchableOpacity>
												</>
											}
										</View>
									</View>
								</View>
								:
								<TouchableOpacity onPress={() => getImage(field.onChange)} style={{ width: "100%", minHeight: height, borderRadius: 10, marginBottom: 10, backgroundColor: "#f2f2f2", alignItems: "center", justifyContent: 'center' }}>
									{loadingImage ?
										<ActivityIndicator color={"black"} size={"large"} style={{ marginBottom: 10 }} />
										:
										<Ionicons name={"ios-camera-outline"} size={50} color="black" />
									}
									<StyledText>Cargar Imagen</StyledText>
								</TouchableOpacity>
						);
					}}
				/>

				{moreInfo && (
					<StyledText style={{ marginTop: 10, fontSize: 12 }}>
						{moreInfo}
					</StyledText>
				)}
				{error && (
					<StyledText style={{ fontSize: 12, color: "red" }}>
						{error?.message}
					</StyledText>
				)}
			</View>
		</View >
	);
});

export default ImageField;
