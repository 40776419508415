import moment from 'moment';
import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import fixDate from '../../utils/fixDate';
import Button from '../common/Button';
import StyledText from '../common/StyledText';

const RowCourse = ({ el, index }) => {

    return (
        <TouchableOpacity
            style={{
                width: "100%",
                backgroundColor: !(index % 2) ? "#f2f2f2" : "white",
                borderTopWidth: index === 0 ? 2 : 0,
                flexDirection: "row",
                alignItems: "center",
                borderColor: "#e5e5e5",
                borderLeftWidth: 1,
            }}
            onPress={() => window.open(`https://${window.location.host}/fed/${el.tenantId}/curso/${el.id}`, "_blank")}>

            <View style={{ minWidth: 200, flex: 2, padding: 15, borderRightWidth: 1, borderColor: "gainsboro", height: "100%", justifyContent: "center" }}>
                <StyledText style={{ fontSize: 16, fontFamily: "DemiBold", color: "black" }} numberOfLines={2}>{el?.name} {el?.tag}</StyledText>
                <StyledText numberOfLines={1} style={{ fontSize: 14, color: "gray" }}>{el?.courseType} | {el?.courseCategory}</StyledText>
                <StyledText numberOfLines={1} style={{ fontSize: 14, color: "gray" }}>{el?.tenantName}</StyledText>
            </View>
            <View style={{ minWidth: 120, flex: 1, padding: 15, borderRightWidth: 1, borderColor: "gainsboro", height: "100%", justifyContent: "center" }}>
                <StyledText style={{ fontSize: 14, color: "black" }}>{el?.city}</StyledText>
            </View>
            <View style={{ minWidth: 150, flex: .7, padding: 15, borderRightWidth: 1, borderColor: "gainsboro", height: "100%", justifyContent: "center" }}>
                <StyledText style={{ fontSize: 14, color: "black" }}>Inicio: {moment(fixDate(el?.preInitDate))?.format("DD/MM/YYYY")}</StyledText>
                <StyledText style={{ fontSize: 14, color: "black" }}>Final: {moment(fixDate(el?.preEndDate))?.format("DD/MM/YYYY")}</StyledText>
            </View>
            <View style={{ minWidth: 120, flex: .7, padding: 15, borderRightWidth: 1, borderColor: "gainsboro", height: "100%", justifyContent: "center" }}>
                <StyledText style={{ fontSize: 14, textAlign: "center", color: "black" }}>{moment(fixDate(el?.initDate))?.format("DD/MM/YYYY")}</StyledText>
            </View>
            <View style={{ minWidth: 120, flex: .5, padding: 15, borderRightWidth: 1, borderColor: "gainsboro", height: "100%", justifyContent: "center" }}>
                <StyledText style={{ fontSize: 16, textAlign: "center", fontFamily: "DemiBold", color: "black" }}>{el?.programFee ? el?.programFee + "€" : "Bonificado"}</StyledText>
            </View>
            <View style={{ minWidth: 150, flex: 1, padding: 15, borderRightWidth: 1, borderColor: "gainsboro", height: "100%", justifyContent: "center" }}>
                <Button height={35} round={5} label={"Información"} onPress={() => window.open(`https://${window.location.host}/fed/${el.tenantId}/curso/${el.id}`, "_blank")} />
            </View>

        </TouchableOpacity>
    );
}

export default RowCourse;