import { useNavigation } from "@react-navigation/native";
import { getApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import React, { useState } from "react";
import { Image, ImageBackground, Text, View } from "react-native";
import Button from "../../components/common/Button";

const VerifyEmail = () => {

    const navigation = useNavigation()
    const auth = getAuth()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    const app = getApp()
    const functions = getFunctions(app, "europe-west1")
    process.env.NODE_ENV === "development" && connectFunctionsEmulator(functions, "localhost", 5001);


    const sendEmailAgain = () => {
        const user = auth.currentUser.email
        setLoading(true)
        const sendUserVerificationEmail = httpsCallable(functions, "sendUserVerificationEmail")

        sendUserVerificationEmail({ email: user }).then(res => {
            // console.log(res)
            setLoading(false)
        }).catch(err => {
            console.log(err.message)
            setLoading(false)
            setError(err.message)
        })
    }



    return (
        <ImageBackground
            style={{
                flex: 1,
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 2,
                paddingVertical: 20
            }}
            source={{ uri: "https://images.unsplash.com/photo-1511204338744-5d4e9b3ffee0?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=900&ixid=MnwxfDB8MXxyYW5kb218MHx8c29jY2VyfHx8fHx8MTY2NDg5MjU5Ng&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1600" }}
            resizeMethod="resize"
        >

            <View style={{ backgroundColor: "rgba(0,0,0,.6)", flex: 1, position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: 1 }} />

            <View style={{ zIndex: 3, alignItems: "center" }}>

                <View style={{ height: 150, width: 300, marginBottom: 20 }}>
                    <Image style={{ resizeMode: "contain", height: 150, width: 300 }} source={{ uri: "https://www.rfef.es/themes/custom/rfef/logo-icon.svg" }} />
                    {/* <Image style={{ resizeMode: "contain", height: 150, width: 300 }} source={require("../../assets/images/logo.png")} /> */}
                </View>

                <View style={{ marginBottom: 20 }}>

                </View>



                <Text style={{ color: "white", fontSize: 30, textAlign: "center" }}>
                    Debes verificar tu correo para continuar
                </Text>
                <Text style={{ color: "white", fontSize: 18, textAlign: "center" }}>
                    Si no has recibido tu correo de verificación, puedes solicitar otro
                </Text>
                {error && (
                    <View
                        style={{
                            width: "100%",
                            borderRadius: 10,
                            marginTop: 15,
                            paddingHorizontal: 15,
                            alignItems: "center",
                            justifyContent: "center",
                            paddingVertical: 15,
                            backgroundColor: "#E9312B",
                        }}
                    >
                        <Text style={{ fontFamily: "Regular", color: "white", fontSize: 18 }}>{error}</Text>
                    </View>
                )}

                <View style={{ width: "100%", marginTop: 30, justifyContent: "center", alignItems: "center", flexDirection: "row" }}>
                    <Button disabled={loading} onPress={() => navigation.navigate("StackPublic", { screen: "HomeScreen" })} label={"Volver"} color="white" round={7} />
                    <Button disabled={loading} loading={loading} onPress={sendEmailAgain} label={"Volver a enviar"} round={7} style={{ marginLeft: 10 }} />
                </View>
            </View>

        </ImageBackground>
    );
};

export default VerifyEmail;
