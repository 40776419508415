import { FontAwesome, Ionicons } from "@expo/vector-icons";
import { useFirestoreDocumentData } from "@react-query-firebase/firestore";
import * as ImagePicker from "expo-image-picker";
import { getApp } from "firebase/app";
import { doc, getDoc, getFirestore, updateDoc } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import { getDownloadURL, getStorage, ref, uploadString } from "firebase/storage";
import { clamp, head, omit } from "lodash";
import moment from "moment";
import { useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { ActivityIndicator, Dimensions, ScrollView, TextInput, TouchableOpacity, View } from "react-native";
import * as yup from "yup";
import AuthContext from "../context/AuthContext";
import fixDate from "../utils/fixDate";
import makeCecabankPayment from "../utils/makeCecabankPayment";
import useYupValidationResolver from "../utils/useYupValidationResolver";
import Button from "./common/Button";
import Modal from "./common/Modal";
import StyledText from "./common/StyledText";
import FileField from "./formComponents/FileField";
import SelectField from "./formComponents/SelectField";



const PaymentsModal = ({ setOpen, course, userData, courseId, tenantId, originUrl }) => {

    const hasSinglePaymentMethod = (course?.paymentOptions || [])?.length === 1;
    const hasInstallmentPayment = course.payment?.length > 0

    const sh = Dimensions.get("window").height;
    const { customization } = useContext(AuthContext)

    const app = getApp()
    const functions = getFunctions(app, "europe-west1");

    process.env.NODE_ENV === "development" && connectFunctionsEmulator(functions, "localhost", 5001);

    const db = getFirestore()
    const storage = getStorage()
    const makePayment = httpsCallable(functions, "makePayment")


    const [loading, setLoading] = useState(false);
    const [loadingDiscount, setLoadingDiscount] = useState(false);
    const [loadingFile, setLoadingFile] = useState(false)
    const [error, setError] = useState(false)
    const [exporting, setExporting] = useState(false)
    const [discountApplied, setDiscountApplied] = useState(false);
    const [discountError, setDiscountError] = useState(false)
    const [paymentCompleted, setPaymentCompleted] = useState(false);
    const [newPayment, setNewPayment] = useState(false)
    const [orderId, setOrderId] = useState(null)
    const [tempCode, setTempCode] = useState(null)

    const courseOriginalPrice = (course?.programFee || 0);

    const docRef = doc(db, `tenants/${tenantId}/courses/${courseId}/payments/${userData.id}`);
    const { data, isLoading, isError: isErrorData } = useFirestoreDocumentData(
        ["payment", { id: userData.id, course: courseId, tenant: tenantId }],
        docRef,
        {
            subscribe: true
        },
        {
            refetchOnMount: "always",
            onError: e => console.log("ERROR", e),
        });

    const paymentDocRef = orderId ? doc(db, "payments", orderId) : null

    const { data: paymentData, isError } = useFirestoreDocumentData(
        ["paymentDoc", orderId],
        paymentDocRef,
        { subscribe: true },
        {
            enabled: !!orderId,
            onError: e => console.log(e)
        }
    )


    const validationSchema = yup.object({
        fullPayment: yup.boolean(),
        paymentMethod: yup.string().oneOf(course.paymentOptions.map(p => p.id)).required("Selecciona un método de pago"),
        amountToPay: yup.number().when("fullPayment", {
            is: false,
            then: yup.lazy(value => {
                if (value) {
                    return yup.number().min(1).max(remainingBalance).required("Ingresa un monto válido")
                }
                return yup.number().required("Ingresa un monto válido")
            })
        })
    })

    const resolver = useYupValidationResolver(validationSchema);

    const {
        handleSubmit,
        getValues,
        setValue,
        control,
        watch,
        reset,
        formState: { errors }
    } = useForm({
        mode: "onChange",
        resolver,
        defaultValues: {
            paymentMethod: hasSinglePaymentMethod ? (head(course?.paymentOptions)?.id || "") : "",
            discountPreinscription: false,
            discountAmount: 0,
            discountCode: "",
            fullPayment: true,
            amountToPay: 0,
            paymentFile: ""
        }
    })

    const discountAmount = watch("discountAmount");
    const discountPreinscription = watch("discountPreinscription");
    const discountCode = watch("discountCode");
    const fullPayment = watch("fullPayment");
    const paymentMethod = watch("paymentMethod");
    const amountToPay = watch("amountToPay");




    useEffect(() => {
        setupForm(data)
    }, [data])

    const setupForm = (data) => {
        const discountOffset = data?.discountAmount || 0

        const totalPaid = data?.payments?.filter(p => p.status === "PAID").reduce((acc, cur) => acc + cur.amount, 0) || 0;

        const remainingBalance = clamp(courseOriginalPrice - totalPaid - (discountOffset || 0), 0, course.programFee);

        reset({
            discountAmount: data?.discountAmount || 0,
            discountPreinscription: data?.discountPreinscription || false,
            paymentMethod: hasSinglePaymentMethod ? (head(course?.paymentOptions)?.id || "") : "",
            discountCode: data?.discountCode || "",
            fullPayment: true,
            amountToPay: remainingBalance,
            paymentFile: ''
        })
    }

    const discountOffset = discountAmount || 0

    const totalAmount = clamp(courseOriginalPrice - discountOffset, 0, courseOriginalPrice);

    const totalPaid = data?.payments.reduce((acc, cur) => acc + (cur.status === "PAID" ? cur.amount : 0), 0) || 0;

    const remainingBalance = clamp(courseOriginalPrice - totalPaid - discountOffset, 0, courseOriginalPrice);

    const coursePayments = course.payment.filter(p => p.amount);



    const adjustPayments = (coursePayments, totalPaid, remainingBalance) => {

        let pendingAmount = remainingBalance;
        let paid = totalPaid

        let adjustedPayments = coursePayments.map((payment, i) => {
            if (paid >= payment.amount) {
                paid -= payment.amount
                return { ...payment, amount: 0 }
            } else {
                if (i === coursePayments.length - 1) {
                    return { ...payment, amount: pendingAmount }
                } else {
                    const adjustedPayment = {
                        ...payment,
                        amount: pendingAmount > payment.amount ? payment.amount : pendingAmount
                    };
                    pendingAmount -= adjustedPayment.amount;
                    paid = 0;
                    return adjustedPayment;
                }
            }
        });



        return adjustedPayments;

    };

    const paymentWithInstallment = useMemo(() => {


        const adjustedPayments = adjustPayments(coursePayments || [], totalPaid, remainingBalance);

        return adjustedPayments


    }, [coursePayments, remainingBalance, totalPaid, totalAmount])


    const loadDocument = async (index) => {
        setLoadingFile(true)
        ImagePicker.launchImageLibraryAsync({
            allowsEditing: true
        }).then(async res => {

            if (!res.canceled) {
                const uri = res.assets[0].uri
                const init = uri.split(";")[0]
                const type = init.split('/')[1];

                try {
                    setLoadingFile(true)
                    const file = ref(storage, `courses/${tenantId}/${courseId}/${userData.id}/paymentReceip-${new Date().getTime()}.${type}`);
                    await uploadString(file, uri.replace(`${init};base64,`, ""), "base64", {
                        contentType: init.replace("data:", ""),
                    }).then(res => {
                        const arr = [...data?.payments] || []
                        arr[index].link = res.ref.fullPath
                        const docRef = doc(db, `tenants/${tenantId}/courses/${courseId}/payments/${userData.id}`);

                        updateDoc(docRef, {
                            payments: arr
                        }).then(res => {
                            alert("El documento se guardó correctamente, recuerda que debes esperar a que el administrador lo valide, si te equivocaste al cargar el documento puedes modificarlo antes de ser validado")
                            setLoadingFile(false)
                        }).catch(err => {
                            console.log(err)
                            alert("Ha ocurrido un error guardando el documento")
                            setLoadingFile(false)
                        })

                    }).catch(err => {
                        console.log(err)
                        alert("Ha ocurrido un error cargando el documento")
                        setLoadingFile(false)
                    })
                    setLoadingFile(false)

                } catch (err) {
                    alert("Ha ocurrido un error cargando el documento")
                    setLoadingFile(false)
                    console.log(err)
                }
            } else {
                setLoadingFile(false)
            }
            return res
        }).catch(err => {
            alert("Ocurrió un error cargando el docuento")
            console.log(err)
            setLoadingFile(false)
            return null
        })

        setTimeout(() => {
            setLoadingFile(false)
        }, 3000)
    }


    async function applyDiscount() {
        if (!tempCode) return alert("Introduce un código de descuento");

        const appDiscount = httpsCallable(functions, "applyDiscount");
        setLoadingDiscount(true);



        appDiscount({
            tenantId,
            courseId,
            code: tempCode,
            userId: userData.id,
        })
            .then(({ data }) => {
                const prevAmount = getValues("amountToPay");
                setValue("discountCode", data?.discountCode);
                setValue("discountAmount", data?.discountAmount);
                setValue("discountPreinscription", false);
                setValue("amountToPay", clamp(prevAmount - (data?.discountAmount || 0), 0, prevAmount));
                setDiscountApplied(true);
                setLoadingDiscount(false);
                alert("Descuento aplicado correctamente")
                setTempCode("")
            })
            .catch(e => {
                setLoadingDiscount(false)
                alert("El descuento introducido no es válido")
                setValue("discountCode", "")
                setValue("discountAmount", 0);
                setValue("discountPreinscription", false);
                setDiscountError(e.message)
            });
    }

    async function makeRedsysPayment({ formData }) {
        const methodRef = doc(db, "tenants", tenantId, "paymentMethods", paymentMethod)
        const d = (await getDoc(methodRef)).data()
        const form = document.createElement("form")
        if (!d?.endpoint) return
        form.setAttribute("name", "from")
        form.setAttribute("method", "post")
        form.setAttribute("target", "_self")
        form.setAttribute("action", d?.endpoint)
        form.setAttribute("style", "display: none")
        Object.keys(formData).forEach(key => {
            const input = document.createElement("input")
            input.setAttribute("type", "hidden")
            input.setAttribute("name", key)
            input.setAttribute("value", formData[key])
            form.appendChild(input)
        })

        const input = document.createElement("input")
        input.setAttribute("type", "submit")
        input.setAttribute("value", "Pay with credit card")
        form.appendChild(input)
        document.body.appendChild(form)
        form.submit()
        document.body.removeChild(form)
    }

    async function submitPayment(values) {
        // test data para redsys
        //4548810000000003
        //1249
        //123


        setLoading(true);

        const hasDiscount = discountCode && discountAmount;

        const valuesWithExtra = hasDiscount
            ? values
            : omit(values, [
                "discountCode",
                "discountAmount",
                "discountPreinscription",
                "discount",
            ]);
        try {
            // * AGREGAR PAGO A course/tenantId/courseId/userId/payments
            const pMethod = course?.paymentOptions?.find((el) => el.id === paymentMethod)?.paymentMethod;
            if (pMethod === "BANK TRANSFER") {
                if (values.paymentFile === "") {
                    alert("Debes cargar el justificantede pago para completar esta acción");
                    return;
                }
                setLoading(false);
                setValue("amountToPay", 0);
                setPaymentCompleted(true);
                setNewPayment(false);
            }
            const { data: dataPayment } = await makePayment({
                ...valuesWithExtra,
                fullPayment,
                tenantId,
                courseId,
                amountToPay,
                originUrl,
                link: values?.paymentFile || null
            });


            if (dataPayment?.tpvType === "redsys") {
                const { orderId, form } = dataPayment;
                setOrderId(orderId);
                makeRedsysPayment({ formData: form });
            }
            if (dataPayment?.tpvType === "addonpayments") {
                const { orderId, hppPayByLink } = dataPayment;
                setOrderId(orderId);
                location.href = hppPayByLink;
            }

            if (dataPayment.tpvType === "cecabank") {
                const { form, orderId, action } = dataPayment;
                setOrderId(orderId);
                makeCecabankPayment({ formData: { ...form, action } });
            }
        } catch (err) {
            console.log(err);
            alert(err.message);
            setLoading(false);
        }
        finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (paymentData?.status && !isError) {
            if (paymentData?.status === "PAID" && orderId) {
                setNewPayment(false)
                setPaymentCompleted(true)
                setValue("amountToPay", 0)
                setLoading(false)
                setError(null)
            } else if (paymentData?.status === "ERROR") {
                setError(true)
                setLoading(false)
            }
        }
    }, [paymentData, isError, orderId])

    function isPaymentDisabled() {
        if (newPayment && getValues("paymentFile")) {
            if (paymentMethod == "" || paymentMethod === "Método de pago...") {
                return true
            } else if (!fullPayment) {
                if (amountToPay == "" || amountToPay == 0) {
                    return true
                }
            }
        }
        return false;
    }


    const exportPayments = async () => {
        const tenant = (await getDoc(doc(db, "tenants", tenantId))).data()
        const generatePdfFn = httpsCallable(functions, "generatePdfFromHTMLLayout");
        try {
            const programFee = course?.programFee
            const discountAmount = data?.discountAmount || 0
            const remainingBalance = programFee - discountAmount - (data?.payments || []).filter(p => p.status === "PAID").reduce((acc, it) => acc + it.amount, 0)

            const pdfResp = await generatePdfFn({
                layoutType: "exportacionPagos",
                data: {
                    name: data?.userName,
                    surname: data?.userSurname,
                    courseName: data?.courseName,
                    federationName: course?.tenantName,
                    programFee,
                    discountAmount,
                    remainingBalance,
                    payments: (data?.payments || []).map(p => {
                        const statusDict = {
                            "PAID": "Pagado",
                            "PENDING": "Pendiente",
                            "ERROR": "Error"
                        }
                        return {
                            amount: p.amount,
                            status: statusDict[p.status],
                            date: moment(p?.createdAt?.seconds, "X").format("DD/MM/YYYY HH:mm"),
                            paymentMethod: p.name,
                            preinscriptionPayment: p.preinscriptionPayment
                        }
                    }),
                    director: tenant?.director,
                    exportDate: moment().format("DD/MM/YYYY HH:mm")
                },
                dataToSign: {
                    mainLogo: tenant?.customization?.mainLogo,
                    secondaryLogo: (tenant?.customization?.mainLogo !== "assets/logoRFEF.png" ? tenant.customization.mainLogo : "") || "",
                    signature: tenant?.signature
                },
                outputRoute: `temporaryCertificates/${course.id}/payments${userData.id}.pdf`,
            })
            return pdfResp?.data?.signedFile
        } catch (err) {
            console.log(err)
            alert("Falló la exportación de pagos")
        }
    }
    return (
        <Modal modalContainerStyle={{ maxHeight: sh - 50, width: "95%", maxWidth: 750 }} duration={100} padding={0} >
            <ScrollView contentContainerStyle={{ padding: 15 }}>
                {(loading || loadingDiscount) &&
                    <View style={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0, zIndex: 10, backgroundColor: "white", alignItems: "center", justifyContent: "center", }}>
                        <ActivityIndicator color={customization.mainColor} size="large" />
                        <StyledText style={{ fontSize: 34, fontFamily: "Title", marginTop: 20 }}>Comprobando información</StyledText>
                    </View>
                }

                {/* INDICADOR DE PAGO TOTAL Y PENDIENTES */}
                {(!newPayment && !paymentCompleted && totalAmount > 0)
                    ? <CoursePayments programFee={course?.programFee} />
                    : null
                }

                {/* DESCUENTO YA APLICADO */}
                {discountCode !== "" && !paymentCompleted
                    ? <DiscountCode
                        isPreinscription={discountPreinscription}
                        discountCode={discountCode}
                        discountAmount={discountAmount}
                    />
                    : null
                }

                {/* LISTADO DE TODOS LOS PAGOS REALIZADOS */}
                {(!newPayment && !paymentCompleted)
                    ? <PaymentsAdvance isLoading={isLoading} payments={data?.payments} loadingFile={loadingFile} loadDocument={loadDocument} control={control} error={error} />
                    : null
                }

                {(!newPayment && data && !paymentCompleted) ? <View style={{ backgroundColor: "#f3f3f3", flexDirection: "row", alignItems: "center", justifyContent: "space-between", padding: 15, flex: 1, borderRadius: 5 }}>
                    <StyledText style={{ fontSize: 16 }}>Pendiente</StyledText>
                    <StyledText style={{ fontFamily: "Title", fontSize: 28 }}>{`${remainingBalance}€`}</StyledText>
                </View> : null}

                {/* ELEGIR MODALIDAD DE NUEVO PAGO */}
                {newPayment
                    ? <View>
                        <StyledText style={{ fontSize: 34, fontFamily: "Title", paddingBottom: 15 }}>Nuevo Pago</StyledText>
                        {/* SELECTOR DEL MÉTODO DE PAGO */}
                        <View style={{ width: "100%", flexDirection: "row", marginBottom: 15 }}>
                            <View style={{ flex: 1 }}>
                                {hasSinglePaymentMethod ?
                                    <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                                        <StyledText style={{ color: "gray", fontSize: 16, marginBottom: 10 }}>
                                            Método de pago
                                        </StyledText>
                                        <StyledText style={{ fontSize: 16, fontFamily: 'DemiBold' }}>{head(course?.paymentOptions)?.name || ""}</StyledText>
                                    </View>
                                    : <SelectField
                                        containerProps={{ style: { flex: 1 } }}
                                        options={course.paymentOptions}
                                        labelKey={"name"}
                                        valueKey={"id"}
                                        name={"paymentMethod"}
                                        key="paymentMethod"
                                        label="Método de Pago"
                                        placeholder="Método de Pago..."
                                        control={control}
                                    />
                                }
                                {errors?.paymentMethod ? <StyledText style={{ fontSize: 14, color: "red", paddingTop: 5 }}>Debe seleccionar un método de pago</StyledText> : null}
                                {Boolean(course?.paymentOptions?.find(el => el.id === paymentMethod) && (course?.paymentOptions?.find(el => el.id === paymentMethod)?.description || course?.paymentOptions?.find(el => el.id === paymentMethod)?.iban)) ? (
                                    <>
                                        <View style={{ padding: 15, backgroundColor: "#f3f3f3", borderRadius: 5 }}>
                                            {course?.paymentOptions?.find(el => el.id === paymentMethod)?.description && (
                                                <StyledText style={{ fontSize: 16 }}>
                                                    {course?.paymentOptions?.find(el => el.id === paymentMethod)?.description}
                                                </StyledText>
                                            )}
                                            {course?.paymentOptions?.find(el => el.id === paymentMethod)?.iban && (
                                                <View style={{ marginTop: 10, backgroundColor: "white", padding: 10, borderRadius: 5 }}>
                                                    <StyledText style={{ fontSize: 18 }}>IBAN: {course?.paymentOptions?.find(el => el.id === paymentMethod)?.iban}</StyledText>
                                                </View>
                                            )}
                                        </View>
                                    </>
                                ) : null}
                            </View>
                        </View>

                        {/* BARRA DE DESCUENTOS */}
                        {(!discountCode && !discountApplied) ?
                            <View style={{ gap: 10 }}>
                                <View style={{ flexDirection: "row", columnGap: 15 }}>
                                    <TextInput
                                        control={control}
                                        disabled={data?.discountCode}
                                        label="Código de Descuento"
                                        name="discountCode"
                                        placeholder="Código de descuento..."
                                        autoCapitalize="characters"
                                        onFocus={() => discountError && setDiscountError(false)}
                                        onChangeText={setTempCode}
                                        style={{ height: 45, width: "100%", borderWidth: 1, borderColor: "gainsboro", borderRadius: 5, paddingHorizontal: 15 }}
                                    />
                                    <Button
                                        disabled={data?.discountCode}
                                        primary
                                        label="Aplicar"
                                        round={5}
                                        height={45}
                                        width={100}
                                        onPress={() => applyDiscount()}
                                    />
                                </View>

                                {/* <TextInput
                                        label={'Documento de pago'}
                                        moreInfo={'Agrega el extracto bancario con la cantidad correcta para poder ser verificado'}
                                        error={error}
                                        required={true}
                                        name={'paymentFile'}
                                        route={`courses/${tenantId}/${courseId}/${userData.id}`}
                                        minimal={false}
                                        editable={true}
                                        control={control}
                                        style={{ height: 45, width: "100%", borderWidth: 1, borderColor: "gainsboro", borderRadius: 5, paddingHorizontal: 15 }}
                                    /> */}
                                {discountError ?
                                    <StyledText style={{ marginTop: 10, fontSize: 12, color: "red" }}>
                                        {discountError}
                                    </StyledText>
                                    : null
                                }
                            </View>
                            : null
                        }

                        {course?.paymentOptions?.find((el) => el.id === paymentMethod)?.paymentMethod === "BANK TRANSFER" &&
                            <View style={{ gap: 10 }}>
                                <View style={{ flexDirection: "row", columnGap: 15 }}>
                                    <FileField
                                        label={'Documento de pago'}
                                        moreInfo={'Agrega el justificante bancario con la cantidad correcta para poder ser verificado'}
                                        error={error}
                                        required={true}
                                        name={'paymentFile'}
                                        route={`courses/${tenantId}/${courseId}/${userData.id}`}
                                        minimal={false}
                                        editable={true}
                                        control={control}
                                    />
                                </View>
                            </View>
                        }

                        {/* PAGO FRACCIONADO SI HAY */}
                        {hasInstallmentPayment && <View style={{ flex: 1, flexDirection: "row", marginTop: 15 }}>
                            <Button height={40} round={5} style={{ marginRight: 10, flex: 1 }} primary={fullPayment}
                                onPress={() => {
                                    setValue("fullPayment", true)
                                    setValue("amountToPay", remainingBalance)
                                }} label="Pago Completo"
                            />
                            <Button
                                height={40}
                                round={5}
                                primary={!fullPayment}
                                style={{ flex: 1 }}
                                onPress={() => {
                                    setValue("fullPayment", false)
                                    setValue("amountToPay", 0)
                                }}
                                label="Pago Fraccionado"
                            />

                        </View>}

                        {/* PAGO FRACCIONADO */}
                        {!fullPayment ?
                            <View style={{ width: "100%" }}>
                                <View style={{ marginTop: 15 }}>
                                    <StyledText style={{ fontSize: 16 }}>
                                        Selecciona uno o varios plazos que se van a abonar (los pagos restantes quedrán pendientes de pago en el area de pagos en tu perfil y se deberán abonar en los plazos correspondientes.)
                                    </StyledText>
                                </View>

                                <View style={{
                                    width: "100%",
                                    marginTop: 15
                                }}>
                                    {paymentWithInstallment?.map((el, i, arr) => {

                                        const totalUpToCurrentIndex = arr.slice(0, i + 1).reduce((acc, el) => acc + el.amount, 0);
                                        const checked = amountToPay >= totalUpToCurrentIndex

                                        return (
                                            <TouchableOpacity
                                                disabled={el?.amount === 0}
                                                onPress={() => {
                                                    if (!checked) {
                                                        return setValue("amountToPay", totalUpToCurrentIndex)
                                                    } else if (checked && amountToPay === totalUpToCurrentIndex) {
                                                        return setValue("amountToPay", amountToPay - el?.amount)
                                                    }
                                                    return setValue("amountToPay", totalUpToCurrentIndex)
                                                }}
                                                key={i}
                                                style={{
                                                    height: 60,
                                                    paddingHorizontal: 30,
                                                    alignItems: "center",
                                                    width: "100%",
                                                    borderBottomWidth: i < arr.length - 1 ? 3 : 0,
                                                    borderBottomColor: "white",
                                                    flexDirection: "row",
                                                    borderRadius: 5,
                                                    backgroundColor: moment(el.endDate.toDate()).isBefore(moment()) ? "#ff9292" : "#f3f3f3",
                                                }}
                                            >
                                                {
                                                    el?.amount !== 0
                                                        ? <View
                                                            style={{ backgroundColor: "white", marginRight: 15, width: 30, height: 30, borderWidth: 3, opacity: 1, borderRadius: 4, borderColor: "#e4002b", alignItems: "center", justifyContent: "center" }}>
                                                            {
                                                                checked
                                                                    ? <FontAwesome name="check" color="#e4002b" size={20} />
                                                                    : null
                                                            }
                                                        </View>
                                                        : null
                                                }
                                                <View >
                                                    <StyledText style={{ fontSize: 16, fontFamily: "DemiBold", color: "#061934" }}>
                                                        {el?.name}
                                                    </StyledText>
                                                </View>
                                                <View style={{ flex: 1, alignItems: "flex-end", paddingRight: 20 }}>
                                                    <StyledText style={{ fontSize: 16, color: "#061934" }}>
                                                        Del {moment(el.initDate.toDate()).format("DD-MM-YYYY")} al {moment(el.endDate.toDate()).format("DD-MM-YYYY")}
                                                    </StyledText>
                                                </View>
                                                <View >
                                                    <StyledText style={{ fontSize: 24, fontFamily: "Title", color: "#061934" }}>
                                                        {el?.amount}€
                                                    </StyledText>
                                                </View>
                                            </TouchableOpacity>
                                        )
                                    })}
                                </View>
                                {errors['amountToPay'] ?
                                    <StyledText style={{ marginTop: 10, fontSize: 14, color: "red" }}>
                                        {errors['amountToPay'].message}
                                    </StyledText>
                                    : null
                                }
                            </View>
                            : null
                        }

                        {/* BARRA INFERIOR RESUMEN DEL TOTAL A PAGAR */}
                        <View style={{ padding: 15, marginTop: 25, backgroundColor: "#061934", borderRadius: 5, }}>
                            {(discountCode && !discountPreinscription && discountAmount > 0 && !paymentCompleted) ?
                                <View style={{ flexDirection: "row" }}>
                                    <View style={{ flex: 1 }}>
                                        <StyledText style={{ fontSize: 22, fontFamily: "Title", color: "white" }}>Descuento</StyledText>
                                    </View>
                                    <StyledText style={{ fontSize: 26, fontFamily: "Title", color: "white" }}>
                                        {`-${discountAmount}€`}
                                    </StyledText>
                                </View>
                                : null
                            }
                            <View style={{ flexDirection: "row" }}>
                                <View style={{ flex: 1 }}>
                                    <StyledText style={{ fontSize: 26, fontFamily: "Title", color: "white" }}>Total a Abonar</StyledText>
                                </View>
                                <StyledText style={{ fontSize: 26, fontFamily: "Title", color: "white" }}>
                                    {fullPayment ? remainingBalance : amountToPay}€
                                </StyledText>
                            </View>
                        </View>
                    </View>
                    : null
                }

                {paymentCompleted ?
                    <View pointerEvents={paymentCompleted ? "auto" : "none"} style={{ position: paymentCompleted ? "relative" : 'absolute', overflow: "hidden", height: paymentCompleted ? "auto" : 0, opacity: paymentCompleted ? 1 : 0 }} >
                        <View style={{ alignItems: "center", justifyContent: "center", flex: 1 }}>
                            <Ionicons name={"checkmark-circle-outline"} color={"green"} size={120} />
                            <StyledText style={{ fontSize: 34, fontFamily: "Title", textAlign: "center" }}>¡Tu pago se ha realizado con éxito!</StyledText>
                            {course?.paymentOptions?.find(el => el.id === paymentMethod)?.paymentMethod !== "TPV" ?
                                <StyledText style={{ textAlign: "center", fontSize: 16, maxWidth: 500, marginVertical: 15, fontFamily: "DemiBold" }}>
                                    Al elegir el método de pago de transferencia tu justificante será verificado antes de marcar el pago como completado.
                                </StyledText>
                                :
                                null
                            }

                            <View style={{ flexDirection: "row", marginTop: 10, marginBottom: 30, alignItems: "center", justifyContent: "center" }}>
                                <Button
                                    primary
                                    round={7}
                                    label="Volver"
                                    style={{ marginRight: 10 }}
                                    onPress={() => {
                                        setNewPayment(false)
                                        setPaymentCompleted(false)
                                        setOpen(null)
                                    }} />
                            </View>
                        </View>
                    </View>
                    : null
                }

            </ScrollView>


            {/* BOTONES INFERIORES */}
            {!loading && !loadingDiscount && !paymentCompleted
                ? <View style={{ flexDirection: "row", padding: 15, width: "100%", alignItems: "center", justifyContent: "space-between" }}>
                    <Button height={35} style={{ marginRight: 10 }} color="gainsboro" round={7} label={newPayment ? "Atrás" : "Cerrar"} primary onPress={() =>
                        newPayment
                            ? setNewPayment(false)
                            : setOpen(null)
                    } />

                    <View style={{ flexDirection: 'row', justifyContent: "flex-end", gap: 10 }}>
                        {data?.invoice && <Button
                            height={35}
                            round={7}
                            label={"Factura"}
                            primary
                            color="#0b1831"
                            disabled={exporting}
                            onPress={async () => {
                                getDownloadURL(ref(storage, data?.invoice)).then(url => {
                                    window.open(url, "_blank")
                                }).catch(err => {
                                    console.log(err)
                                    alert("Ha ocurrido un error cargando la factura")
                                })
                            }}
                        />}
                        {(data?.payments || []).length > 0 && <Button
                            height={35}
                            round={7}
                            label={"Exportar pagos"}
                            primary
                            loading={exporting}
                            disabled={exporting}
                            onPress={async () => {
                                setExporting(true)
                                const goTo = await exportPayments()
                                if (goTo) { window.open(goTo, "_blank") }
                                setExporting(false)
                            }}
                        />}

                        {data && remainingBalance > 0 ?
                            <Button
                                disabled={isPaymentDisabled()}
                                height={35}
                                round={7}
                                label="Realizar Pago"
                                primary
                                onPress={
                                    !newPayment
                                        ? () => setNewPayment(true)
                                        : handleSubmit(submitPayment)
                                }
                            />
                            : null
                        }
                    </View>
                </View> : null}
        </Modal>
    )
}

function DiscountCode({ discountCode, isPreinscription, discountAmount }) {
    return (
        <View style={{ flexDirection: "row", justifyContent: "space-between", padding: 20, backgroundColor: "#f2f2f2", borderRadius: 7, marginVertical: 10 }}>
            <View style={{ flex: 1, justifyContent: "space-between" }}>
                <StyledText style={{ fontSize: 16 }}>Descuento</StyledText>
                <StyledText style={{ fontFamily: "Title", fontSize: 28, paddingTop: 5 }} >{discountCode}</StyledText>
                <StyledText style={{ fontSize: 16, opacity: .5 }}>
                    {`Descuento aplicado ${isPreinscription ? "a la preinscripción" : "al programa"}`}
                </StyledText>
            </View>
            <View style={{ flex: 1, alignItems: "flex-end", justifyContent: "center" }}>
                <StyledText style={{ fontFamily: "Title", fontSize: 28 }}>-{discountAmount}€</StyledText>
            </View>
        </View>
    )
}

function CoursePayments({ programFee }) {
    return (
        <>
            <StyledText style={{ fontSize: 34, fontFamily: "Title" }}>Pagos del Curso</StyledText>
            <View style={{ flexDirection: "row", borderRadius: 7, marginVertical: 10 }}>
                <View style={{ flexDirection: "column", flex: 1, backgroundColor: "#f3f3f3", borderRadius: 7, padding: 10 }}>

                    {programFee > 0 ?
                        <View style={{ backgroundColor: "#f3f3f3", flexDirection: "row", alignItems: "center", justifyContent: "space-between", paddingHorizontal: 10, flex: 1, }}>
                            <StyledText style={{ fontSize: 16 }}>Coste del programa</StyledText>
                            <StyledText style={{ fontFamily: "Title", fontSize: 28 }}>{`${programFee}€`}</StyledText>
                        </View>
                        : null
                    }
                </View>
            </View>
        </>
    )
}

function PaymentsAdvance({ payments, loadingFile, loadDocument, isLoading, control, error }) {
    return (
        <View>
            <StyledText style={{ fontSize: 34, fontFamily: "Title" }}>Pagos</StyledText>
            <View style={{ flexDirection: "row", marginVertical: 10 }}>
                {isLoading ?
                    <View style={{ backgroundColor: "#f3f3f3", alignItems: "center", justifyContent: "center", padding: 15, flex: 1 }}>
                        <StyledText style={{ fontSize: 20 }}>Cargando...</StyledText>
                    </View> :
                    payments?.length === 0 ?
                        <View style={{ backgroundColor: "#f3f3f3", alignItems: "center", justifyContent: "center", padding: 15, flex: 1 }}>
                            <StyledText style={{ fontSize: 20 }}>Sin Transacciones</StyledText>
                        </View>
                        :
                        <View style={{ width: "100%" }}>
                            {payments?.map((el, i) => {
                                return (
                                    <PaymentRow
                                        key={i}
                                        withPreinscription={payments?.some(p => p.preinscriptionPayment)}
                                        isFullPayment={payments?.length <= 2 && payments?.some(p => p.preinscriptionPayment)}
                                        idx={i}
                                        data={el}
                                        loadingFile={loadingFile}
                                        loadCallback={() => loadDocument(i)}
                                    />
                                )
                            })}
                        </View>
                }
            </View>
        </View>
    )
}

const PaymentRow = ({ data, loadCallback, loadingFile, idx, withPreinscription, isFullPayment }) => {


    const [open, setOpen] = useState(false);
    const storage = getStorage()
    const openLink = () => {
        getDownloadURL(ref(storage, data.link)).then(url => {
            window.open(url)
        }).catch(err => {
            console.log(err)
            alert("Error abriendo el documento")
        })
    }

    return (
        <View style={{ flexDirection: "column", width: "100%", marginBottom: 10 }}>
            <TouchableOpacity onPress={() => setOpen(!open)} style={{ flex: 1, borderRadius: 5, padding: 10, borderWidth: 1, borderColor: "gray" }}>
                <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", }}>
                    <Ionicons name={open ? "chevron-up" : "chevron-down"} size={25} style={{}} />
                    <View style={{ flex: 1, paddingHorizontal: 10 }}>

                        {data?.preinscriptionPayment ?
                            <StyledText numberOfLines={1} style={{ fontFamily: "Demibold", fontSize: 16 }}>
                                Tasa de Preinscripción
                            </StyledText>
                            :
                            <StyledText numberOfLines={1} style={{ fontFamily: "Demibold", fontSize: 16 }}>
                                {isFullPayment ? 'Pago Completo' : `Plazo ${withPreinscription ? idx : idx + 1}`}
                            </StyledText>
                        }
                        <StyledText numberOfLines={1} style={{ fontFamily: "DemiBold", fontSize: 16 }}>
                            {data.name} - <StatusBadge status={data.status} />
                        </StyledText>
                        <StyledText numberOfLines={1} style={{ fontSize: 12 }}>{moment(fixDate(data.createdAt)).format("DD-MM-YYYY HH:mm")}</StyledText>
                    </View>

                    <StyledText style={{ fontFamily: "title", fontSize: 35 }}>-{data.amount.toFixed(2)}€</StyledText>
                </View>
            </TouchableOpacity>
            {open && <View style={{ borderWidth: 1, borderRadius: 5, borderTopWidth: 0, paddingVertical: 5, paddingHorizontal: 15, backgroundColor: "#f2f2f2", borderColor: "gainsboro", paddingTop: 10 }}>
                {data.paymentMethod === "BANK TRANSFER" &&
                    <View style={{ flexDirection: "row", paddingVertical: 10, alignItems: "center", justifyContent: "space-between" }}>
                        <View style={{ flex: 1 }}>
                            <StyledText style={{ fontSize: 16 }}>Justificante de Pago:</StyledText>
                            {data.updatedAt && <StyledText style={{ fontSize: 12 }}>{moment(fixDate(data?.updatedAt)).format("DD-MM-YYYY HH:mm")}</StyledText>}
                        </View>
                        {data.link && <Button label={"Visualizar"} style={{ marginRight: 10 }} primary={false} round={5} height={35} onPress={openLink} />}
                        {data.status === "PENDING" && <Button label={(data.link) ? "Reemplazar" : "Cargar Justificante"} loading={loadingFile} round={5} height={35} onPress={loadCallback} />}
                    </View>
                }
                {data.paymentMethod !== "BANK TRANSFER" &&
                    <View style={{ flexDirection: "row", paddingVertical: 10, alignItems: "center", justifyContent: "space-between" }}>
                        <View style={{ flex: 1 }}>
                            <StyledText style={{ fontSize: 16 }}>Id de la transacción:</StyledText>
                        </View>
                        <View>
                            <StyledText style={{ fontSize: 16 }}>{data.orderId}</StyledText>
                        </View>

                    </View>
                }

                {data.paymentMethod !== "BANK TRANSFER" && data.status !== "PAID" &&
                    <View style={{ flexDirection: "row", paddingVertical: 10, alignItems: "center", justifyContent: "space-between" }}>
                        <View style={{ flex: 1 }}>
                            <StyledText style={{ fontSize: 16 }}>Reintentar Pago:</StyledText>
                        </View>
                        <Button label={"Reintentar"} round={5} height={35} onPress={() => null} />

                    </View>
                }
            </View>}
        </View>
    )
}

const StatusBadge = ({ status }) => {
    const text = status === "PENDING" ? "Pendiente" : status === "PAID" ? "Pagado" : status === "FAILED" ? "Error" : "Desconocido";
    const color = status === "PENDING" ? "orange" : status === "PAID" ? "green" : status === "FAILED" ? "red" : "gray";
    return (
        <View style={{ backgroundColor: color, paddingHorizontal: 10, paddingVertical: 2, borderRadius: 5 }}>
            <StyledText style={{ fontSize: 12, fontWeight: "bold", color: "white" }}>{text}</StyledText>
        </View>
    )
}



export default PaymentsModal;