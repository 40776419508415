import { FontAwesome } from '@expo/vector-icons';
import moment from 'moment';
import React from 'react';
import { Image, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import Link from './Link';
import StyledText from './StyledText';

const Footer = ({ limited }: { limited: boolean }) => {

    const isPhone = useMediaQuery({ query: '(max-width: 1000px)' })

    return (
        <View style={{ width: "100%", paddingVertical: 10, backgroundColor: "#e4002b" }}>
            <View style={{ alignItems: "center", justifyContent: "center", flexDirection: isPhone ? "column" : "row", maxWidth: 1200, alignSelf: "center" }}>

                <Image source={{ uri: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/OF4VYpRfgUJEH5rG2syJ%2Fadmin%2Fimages%2F1654849409559-logo-rfef.png?alt=media&token=4e19e230-b0dc-44b2-b58d-558d94f177a8" }} style={{ width: 40, height: 40, resizeMode: "contain" }} />
                <View style={{ marginVertical: isPhone ? 15 : 0, flex: 1, flexDirection: isPhone ? "column" : "row", alignItems: "center" }}>
                    <StyledText style={{ paddingHorizontal: 10, fontWeight: "bold", color: "white" }}>© {moment().format("YYYY")} Real Federación Española de Fútbol</StyledText>
                    <View style={{ marginVertical: isPhone ? 15 : 0, flexDirection: isPhone ? "column" : "row", alignItems: "center" }}>
                        {!limited && <Link text="Contacto" to={{ screen: "StackPublic", params: { screen: "Contact" } }} accentColor='white' style={{ fontWeight: "bold", fontSize: 14, flexDirection: "row", display: "flex", alignItems: "center", justifyContent: "center", paddingHorizontal: 10, borderLeftWidth: isPhone ? 0 : 1, borderLeftColor: "white" }} />}
                        <Link text="Cookies" to={{ screen: "StackPublic", params: { screen: "GlobalPage", params: { slug: "politica-cookies" } } }} accentColor='white' style={{ fontWeight: "bold", fontSize: 14, flexDirection: "row", display: "flex", alignItems: "center", justifyContent: "center", paddingHorizontal: 10, borderLeftWidth: isPhone ? 0 : 1, borderLeftColor: "white" }} />
                        <Link text="Protección de Datos" to={{ screen: "StackPublic", params: { screen: "GlobalPage", params: { slug: "proteccion-datos" } } }} accentColor='white' style={{ fontWeight: "bold", fontSize: 14, flexDirection: "row", display: "flex", alignItems: "center", justifyContent: "center", paddingHorizontal: 10, borderLeftWidth: isPhone ? 0 : 1, borderLeftColor: "white" }} />
                        {!limited && <Link text="Gestión" onPress={() => window.open("https://onformacionadmin.rfef.es")} accentColor='white' style={{ fontWeight: "bold", fontSize: 14, flexDirection: "row", display: "flex", alignItems: "center", justifyContent: "center", paddingHorizontal: 10, borderLeftWidth: isPhone ? 0 : 1, borderLeftColor: "white" }} />}
                        {!limited && <Link text="Profesores" onPress={() => window.open("https://onformacionadmin.rfef.es")} accentColor='white' style={{ fontWeight: "bold", fontSize: 14, flexDirection: "row", display: "flex", alignItems: "center", justifyContent: "center", paddingHorizontal: 10, borderLeftWidth: isPhone ? 0 : 1, borderLeftColor: "white" }} />}
                    </View>

                    <View style={{ flexDirection: "row", paddingHorizontal: 20 }}>
                        <Link onPress={() => window.open("https://www.facebook.com/RFEF/")} accentColor='white' containerStyle={{ flexDirection: "row", display: "flex", alignItems: "center", justifyContent: "center", paddingHorizontal: 10, }}>
                            <FontAwesome name="facebook-f" color="white" size={24} />
                        </Link>
                        <Link onPress={() => window.open("https://twitter.com/rfef")} accentColor='white' containerStyle={{ flexDirection: "row", display: "flex", alignItems: "center", justifyContent: "center", paddingHorizontal: 10, }}>
                            <FontAwesome name="twitter" color="white" size={24} />
                        </Link>
                        <Link onPress={() => window.open("https://www.youtube.com/c/federacionespa%C3%B1olafutbol", "_blank")} accentColor='white' containerStyle={{ flexDirection: "row", display: "flex", alignItems: "center", justifyContent: "center", paddingHorizontal: 10, }}>
                            <FontAwesome name="youtube-play" color="white" size={24} />
                        </Link>
                        <Link onPress={() => window.open("https://www.instagram.com/rfef")} accentColor='white' containerStyle={{ flexDirection: "row", display: "flex", alignItems: "center", justifyContent: "center", paddingHorizontal: 10, }}>
                            <FontAwesome name="instagram" color="white" size={24} />
                        </Link>
                    </View>
                </View>
            </View>
        </View>
    );
}

export default Footer;