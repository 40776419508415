import { Ionicons } from "@expo/vector-icons";
import React, { useContext, useState } from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import AuthContext from "../context/AuthContext";

import { getAuth } from "firebase/auth";
import { arrayUnion, doc, getDoc, getFirestore, serverTimestamp, setDoc, updateDoc } from "firebase/firestore";
import * as Animatable from "react-native-animatable";
import { useMediaQuery } from "react-responsive";
import Link from "./common/Link";
import Modal from "./common/Modal";
import SecureImage from "./common/SecureImage";
import QRScan from "./QRScan";


function isClassInTime(lowerBound, upperBound) {
	const timestamp = new Date().getTime();

	const extraHour = 3600 * 1000; // ms in hour

	const lowerBoundWithExtraHour = new Date((lowerBound.seconds * 1000) - extraHour).getTime();
	const upperBoundWithExtraHour = new Date((upperBound.seconds * 1000) + extraHour).getTime();

	const isBefore = timestamp < lowerBoundWithExtraHour;
	const isAfter = timestamp > upperBoundWithExtraHour;
	const isValid = (timestamp > lowerBoundWithExtraHour) && (timestamp < upperBoundWithExtraHour);

	return { isBefore, isAfter, isValid };
}

const HeaderWeb = () => {

	const db = getFirestore()
	const { authFunctions, userData, customization } = useContext(AuthContext);


	const isPhone = useMediaQuery({ query: '(max-width: 1000px)' })

	const [open, setOpen] = useState(false);
	const [openScanner, setOpenScanner] = useState(false);


	const handleQRData = async (value) => {
		if (value) {

			const [tenantId, courseId, classId, type] = value.split(",")

			const parsedType = type === "1"

			if (!tenantId || !courseId || !classId) return alert("QR invalido")

			try {

				const courseRef = doc(db, `tenants/${tenantId}/courses/${courseId}`)
				const co = await getDoc(courseRef)

				if (!co.exists()) return alert("QR invalido")

				const advanceRef = doc(db, `tenants/${tenantId}/courses/${courseId}/courseavs/${userData.id}`)
				const da = await getDoc(advanceRef);

				if (!da.exists()) return alert("QR inválido")

				const docRef = doc(db, `tenants/${tenantId}/courses/${courseId}/courseavs/${userData.id}/attendance/${classId}`);
				const registered = true

				// if (registered !== "APPROVED") return alert("No estas registrado en este curso, asegurate que escaneaste el código correcto")

				const d = await getDoc(docRef);
				const courseData = co.data();

				const classes = courseData?.contentDetails?.reduce(
					(acc, cur) =>
						[
							...acc,
							...(cur.classes || []),
							...(cur.sections ? cur.sections?.reduce((sacc, scur) => [...sacc, ...(scur.contents || []), ...(scur.classes || [])], []) : []),
						].map((el) => ({
							...el,
							id: el.id,
						})),
					[]);

				const classDetails = classes.find(c => c.id === classId);
				if (!classDetails) return alert("La clase a la que esta intentando acceder no existe")

				const {
					isBefore,
					isAfter,
					isValid
				} = isClassInTime(classDetails?.start, classDetails?.end);

				if (!isValid) {
					if (isBefore) {
						return alert("El plazo para la entrada a esta clase aún no ha comenzado")
					} else if (isAfter) {
						return alert("El plazo para la entrada a esta clase ha finalizado")
					}
				}

				// console.log(d.data(), d.exists(), tenantId, courseId, classId, type)

				if (d.exists()) {
					const data = d.data()

					const lastEntry = [...(data?.entries || [])]?.pop()

					if (lastEntry.type === parsedType) {
						setOpenScanner(false)
						return alert(parsedType ? "Ya has registrado la entrada a esta clase, registra la salida para volver a registrar una entrada" : "Ya has registrado la salida a esta clase, registra la entrada para volver a registrar una salida")
					}

					await updateDoc(docRef, {
						updatedAt: serverTimestamp(),
						entries: arrayUnion({
							createdAt: new Date(),
							type: parsedType,
						})
					})
					setOpenScanner(false)
					alert(`Se ha registrado la ${parsedType ? "entrada" : "salida"} a esta clase correctamente`)
				} else {
					if (!parsedType) return alert("El código escaneado no es válido, asegurate de escanear el código correcto")
					await setDoc(docRef, {
						createdAt: serverTimestamp(),
						updatedAt: serverTimestamp(),
						userId: userData.id,
						entries: [{
							createdAt: new Date(),
							type: true,
						}]
					})
					setOpenScanner(false)
					alert(`Se ha registrado la entrada a esta clase correctamente`)
				}
			} catch (err) {
				console.log(err)
				setOpenScanner(false)
				alert("Error al registrar la asistencia")
			}
		} else {
			setOpenScanner(false)
			alert("QR invalido")
		}

	}


	return (
		<>
			{openScanner ?
				<Modal modalContainerStyle={{ backgroundColor: "black" }}>
					<QRScan enabled={openScanner} width={400} height={400} onFind={handleQRData} closeCallback={() => setOpenScanner(false)} />
				</Modal>
				:
				null
			}
			<View >

				{!isPhone ?
					<View style={{ position: "fixed", borderBottomLeftRadius: 0, borderBottomRightRadius: 0, zIndex: 10, height: 100, width: 100, alignItems: "center", justifyContent: "center", top: 0, left: 0, backgroundColor: customization.mainColor }}>
						<Link to={{ screen: "StackPublic", params: { screen: "HomeScreen" } }}>
							<SecureImage style={{ width: 70, height: 70, resizeMode: "contain" }} uri={"https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/OF4VYpRfgUJEH5rG2syJ%2Fadmin%2Fimages%2F1654849409559-logo-rfef.png?alt=media&token=4e19e230-b0dc-44b2-b58d-558d94f177a8"} placeholder={undefined} />
						</Link>
					</View>
					:
					null
				}


				<MenuLinks open={open} onQR={() => setOpenScanner(!openScanner)} menuCallback={() => setOpen(!open)} direction="row" isPhone={isPhone} zonas={userData?.zonas || []} userData={userData} authFunctions={authFunctions} customization={customization} />


				{!isPhone ?
					<View style={[styles.shadow, { paddingHorizontal: 10, width: "100%", height: 50, backgroundColor: customization.mainColor, borderBottomColor: "white", borderBottomWidth: 1, flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }]}>
						<Link text="INICIO" to={{ screen: "StackPublic", params: { screen: "HomeScreen" } }} style={{ fontFamily: "Title", fontWeight: "bold", letterSpacing: 1.5, fontSize: 22 }} containerStyle={{ flexDirection: "row", display: "flex", height: 70, alignItems: "center", justifyContent: "center", marginHorizontal: 10 }} />
						<Link text="ESCUELA DE ENTRENADORES" to={{ screen: "StackPublic", params: { screen: "CoachesSchool" } }} style={{ fontFamily: "Title", fontWeight: "bold", letterSpacing: 1.5, fontSize: 22 }} containerStyle={{ flexDirection: "row", display: "flex", height: 70, alignItems: "center", justifyContent: "center", marginHorizontal: 10 }} />
						<Link text="REVALUACIÓN" to={{ screen: "StackPublic", params: { screen: "VirtualReevaluation" } }} style={{ fontFamily: "Title", fontWeight: "bold", letterSpacing: 1.5, fontSize: 22 }} containerStyle={{ flexDirection: "row", display: "flex", height: 70, alignItems: "center", justifyContent: "center", marginHorizontal: 10 }} />
						<Link text="FORMACIÓN RFEF" to={{ screen: "StackPublic", params: { screen: "RfefPrograms" } }} style={{ fontFamily: "Title", fontWeight: "bold", letterSpacing: 1.5, fontSize: 22 }} containerStyle={{ flexDirection: "row", display: "flex", height: 70, alignItems: "center", justifyContent: "center", marginHorizontal: 10 }} />
						<Link text="PROCESO SOLICITUD LICENCIAS" to={{ screen: "StackPublic", params: { screen: "Process" } }} style={{ fontFamily: "Title", fontWeight: "bold", letterSpacing: 1.5, fontSize: 22 }} containerStyle={{ flexDirection: "row", display: "flex", height: 70, alignItems: "center", justifyContent: "center", marginHorizontal: 10 }} />
						<Link text="RECONOCIMIENTO DE COMPETENCIAS" to={{ screen: "StackPublic", params: { screen: "Recognition" } }} style={{ fontFamily: "Title", fontWeight: "bold", letterSpacing: 1.5, fontSize: 22 }} containerStyle={{ flexDirection: "row", display: "flex", height: 70, alignItems: "center", justifyContent: "center", marginHorizontal: 10 }} />
						{/* <Link text="SELLO DE CALIDAD" to={{ screen: "StackPublic", params: { screen: "QualityForm" } }} style={{ fontFamily: "Title", fontWeight: "bold", letterSpacing: 1.5, fontSize: 22 }} containerStyle={{ flexDirection: "row", display: "flex", height: 70, alignItems: "center", justifyContent: "center", marginHorizontal: 10 }} /> */}
						<Link text="CONTACTO" to={{ screen: "StackPublic", params: { screen: "Contact" } }} style={{ fontFamily: "Title", fontWeight: "bold", letterSpacing: 1.5, fontSize: 22, flexDirection: "row", display: "flex", height: 70, alignItems: "center", justifyContent: "center", marginHorizontal: 10 }} />
					</View>
					:
					null
				}

				{open && isPhone ?

					<Animatable.View animation={"fadeIn"} duration={250} style={{ position: "fixed", top: 52, left: 0, right: 0, bottom: 0, padding: 15, backgroundColor: customization.mainColor }} >
						<Link text="INICIO" to={{ screen: "StackPublic", params: { screen: "HomeScreen" } }} onPress={() => setOpen(false)} style={{ fontFamily: "Title", fontWeight: "bold", letterSpacing: 1.5, fontSize: 22 }} containerStyle={{ flexDirection: "row", display: "flex", height: 70, alignItems: "center", justifyContent: "center", marginHorizontal: 10 }} />
						<Link text="ESCUELA DE ENTRENADORES" to={{ screen: "StackPublic", params: { screen: "CoachesSchool" } }} onPress={() => setOpen(false)} style={{ fontFamily: "Title", fontWeight: "bold", letterSpacing: 1.5, fontSize: 22 }} containerStyle={{ flexDirection: "row", display: "flex", height: 70, alignItems: "center", justifyContent: "center", marginHorizontal: 10 }} />
						<Link text="REVALUACIÓN" to={{ screen: "StackPublic", params: { screen: "VirtualReevaluation" } }} onPress={() => setOpen(false)} style={{ fontFamily: "Title", fontWeight: "bold", letterSpacing: 1.5, fontSize: 22 }} containerStyle={{ flexDirection: "row", display: "flex", height: 70, alignItems: "center", justifyContent: "center", marginHorizontal: 10 }} />
						<Link text="FORMACIÓN RFEF" to={{ screen: "StackPublic", params: { screen: "RfefPrograms" } }} onPress={() => setOpen(false)} style={{ fontFamily: "Title", fontWeight: "bold", letterSpacing: 1.5, fontSize: 22 }} containerStyle={{ flexDirection: "row", display: "flex", height: 70, alignItems: "center", justifyContent: "center", marginHorizontal: 10 }} />
						<Link text="PROCESO SOLICITUD LICENCIAS" to={{ screen: "StackPublic", params: { screen: "Process" } }} onPress={() => setOpen(false)} style={{ fontFamily: "Title", fontWeight: "bold", letterSpacing: 1.5, fontSize: 22 }} containerStyle={{ flexDirection: "row", display: "flex", height: 70, alignItems: "center", justifyContent: "center", marginHorizontal: 10 }} />
						<Link text="RECONOCIMIENTO DE COMPETENCIAS" to={{ screen: "StackPublic", params: { screen: "Recognition" } }} onPress={() => setOpen(false)} style={{ fontFamily: "Title", fontWeight: "bold", letterSpacing: 1.5, fontSize: 22 }} containerStyle={{ flexDirection: "row", display: "flex", height: 70, alignItems: "center", justifyContent: "center", marginHorizontal: 10 }} />
						{/* <Link text="SELLO DE CALIDAD" to={{ screen: "StackPublic", params: { screen: "QualityForm" } }} onPress={() => setOpen(false)} style={{ fontFamily: "Title", fontWeight: "bold", letterSpacing: 1.5, fontSize: 22 }} containerStyle={{ flexDirection: "row", display: "flex", height: 70, alignItems: "center", justifyContent: "center", marginHorizontal: 10 }} /> */}
						<Link text="CONTACTO" to={{ screen: "StackPublic", params: { screen: "Contact" } }} onPress={() => setOpen(false)} style={{ fontFamily: "Title", fontWeight: "bold", letterSpacing: 1.5, fontSize: 22 }} containerStyle={{ flexDirection: "row", display: "flex", height: 70, alignItems: "center", justifyContent: "center", marginHorizontal: 10 }} />
						{userData?.id ?
							<Link key={"logout"} text={(userData.id ? "Salir" : "Acceso  Plataforma")} iconSize={24} onPress={userData?.id && authFunctions.signOut} style={{ fontFamily: "Title", fontWeight: "bold", letterSpacing: 1.5, fontSize: 22 }} containerStyle={{ flexDirection: "row", display: "flex", height: 70, alignItems: "center", justifyContent: "center", marginHorizontal: 10 }} />
							:
							<Link key={"login"} text={(userData.id ? "Salir" : "Acceso  Plataforma")} iconSize={24} to={{ screen: "Login" }} style={{ fontFamily: "Title", fontWeight: "bold", letterSpacing: 1.5, fontSize: 22 }} containerStyle={{ flexDirection: "row", display: "flex", height: 70, alignItems: "center", justifyContent: "center", marginHorizontal: 10 }} />}
						{/* <Link
						text={userData.id ? "Salir" : "Acceso  Plataforma"}
						{...(userData?.id) && { to: { screen: "Login" } }}
						onPress={userData?.id && authFunctions.signOut} style={{ fontFamily: "Title", fontWeight: "bold", letterSpacing: 1.5, fontSize: 22 }} containerStyle={{ flexDirection: "row", display: "flex", height: 70, alignItems: "center", justifyContent: "center", marginHorizontal: 10 }} /> */}
					</Animatable.View>
					:
					null
				}
			</View>
		</>
	);
};

const MenuLinks = ({ authFunctions, clickCallback, isPhone, menuCallback, open, onQR }) => {

	const auth = getAuth()

	const { userData, customization } = useContext(AuthContext);

	const emailVerified = auth?.currentUser?.emailVerified


	return (
		<View style={{ width: "100%", gap: isPhone ? 10 : 15, paddingHorizontal: isPhone ? 10 : 20, backgroundColor: "#0B1831", borderBottomWidth: isPhone ? 2 : 0, borderBottomColor: "white", alignItems: "flex-end", justifyContent: "flex-end", flexDirection: "row" }}>

			{isPhone ?
				<View style={{ backgroundColor: customization.mainColor, left: 0, top: 0, position: "fixed", height: 50, width: 50, alignItems: "center", justifyContent: "center" }}>
					<Link to={{ screen: "StackPublic", params: { screen: "HomeScreen" } }} >
						<SecureImage style={{ width: 35, height: 35, resizeMode: "contain" }} uri={"https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/OF4VYpRfgUJEH5rG2syJ%2Fadmin%2Fimages%2F1654849409559-logo-rfef.png?alt=media&token=4e19e230-b0dc-44b2-b58d-558d94f177a8"} />
					</Link>
				</View>
				: null
			}
			{userData?.id && !emailVerified ?
				<Link icon={"mail-outline"} text={!isPhone ? "VERIFICAR EMAIL" : null} to={{ screen: "VerifyEmail" }} onPress={clickCallback} style={{ fontFamily: "Title", fontSize: 18 }} containerStyle={{ flexDirection: "row", gap: 5, display: "flex", height: 50, alignItems: "center", justifyContent: "center", minWidth: 35 }} />
				: null
			}
			{userData?.id && emailVerified ?
				<Link icon={"md-book-outline"} text={!isPhone ? "MIS PROGRAMAS" : null} to={{ screen: "MainTabNavigator", params: { screen: "SmartHome" } }} onPress={clickCallback} style={{ fontFamily: "Title", fontSize: 18 }} containerStyle={{ flexDirection: "row", gap: 5, display: "flex", height: 50, alignItems: "center", justifyContent: "center", minWidth: 35 }} />
				: null
			}
			{userData?.id && emailVerified ?
				<Link icon={"person-circle-outline"} text={!isPhone ? "MI PERFIL" : null} to={{ screen: "MainTabNavigator", params: { screen: "ProfileHome" } }} onPress={clickCallback} style={{ fontFamily: "Title", fontSize: 18 }} containerStyle={{ flexDirection: "row", gap: 5, display: "flex", height: 50, alignItems: "center", justifyContent: "center", minWidth: 35 }} />
				: null
			}
			{userData?.id && emailVerified ?
				<Link icon={"card"} text={!isPhone ? "LICENCIAS" : null} to={{ screen: "MainTabNavigator", params: { screen: "LicensesScreen" } }} onPress={clickCallback} style={{ fontFamily: "Title", fontSize: 18 }} containerStyle={{ flexDirection: "row", gap: 5, display: "flex", height: 50, alignItems: "center", justifyContent: "center", minWidth: 35 }} />
				: null
			}
			{userData?.id && emailVerified &&
				<Link icon={"calendar-outline"} text={!isPhone ? "Calendario" : null} to={{ screen: "MainTabNavigator", params: { screen: "CalendarScreen" } }} onPress={clickCallback} style={{ fontFamily: "Title", fontSize: 18 }} containerStyle={{ flexDirection: "row", gap: 5, display: "flex", height: 50, alignItems: "center", justifyContent: "center", minWidth: 35 }} />
			}
			{userData.id && emailVerified ?
				<Link icon={"notifications-outline"} text={!isPhone ? "NOTIFICACIONES" : null} to={{ screen: "MainTabNavigator", params: { screen: "MessagesScreen" } }} onPress={clickCallback} style={{ fontFamily: "Title", fontSize: 18 }} containerStyle={{ flexDirection: "row", gap: 5, display: "flex", height: 50, alignItems: "center", justifyContent: "center", minWidth: 35 }} >
					{userData?.notificationsCount > 0 ? <View style={{ position: "absolute", justifyContent: "center", alignItems: 'center', top: 5, left: isPhone ? 5 : -5, backgroundColor: customization.mainColor, height: 15, paddingHorizontal: 5, borderRadius: 8 }}>
						<Text style={{ fontSize: 10, color: "white", fontFamily: "DemiBold" }}>{userData?.notificationsCount || 0}</Text>
					</View> : null}
				</Link>
				: null
			}
			{userData.id && emailVerified ?
				<Link icon={"build"} iconSize={22} text={!isPhone ? "Herramientas" : null} to={{ screen: "MainTabNavigator", params: { screen: "ToolScreen" } }} onPress={clickCallback} style={{ fontFamily: "Title", fontSize: 18 }} containerStyle={{ flexDirection: "row", gap: 5, display: "flex", height: 50, alignItems: "center", justifyContent: "center", minWidth: 35 }} />
				: null
			}
			{userData.id && emailVerified ?
				<Link icon={"qr-code"} iconSize={22} text={!isPhone ? "Escaner" : null} onPress={onQR} to={{}} style={{ fontFamily: "Title", fontSize: 18 }} containerStyle={{ flexDirection: "row", gap: 5, display: "flex", height: 50, alignItems: "center", justifyContent: "center", minWidth: 35 }} />
				: null
			}
			{isPhone && userData?.id ? null :
				userData?.id ?
					<Link key={"logout"} text={!isPhone ? (userData.id ? "Salir" : "Acceso  Plataforma") : null} icon={userData?.id ? "exit-outline" : "enter-outline"} iconSize={24} onPress={userData?.id && authFunctions.signOut} style={{ fontFamily: "Title", fontSize: 18 }} containerStyle={{ flexDirection: "row", gap: 5, display: "flex", height: 50, alignItems: "center", justifyContent: "center", minWidth: 35 }} />
					:
					<Link key={"login"} text={!isPhone ? (userData.id ? "Salir" : "Acceso  Plataforma") : null} icon={userData?.id ? "exit-outline" : "enter-outline"} iconSize={24} to={{ screen: "Login" }} style={{ fontFamily: "Title", fontSize: 18 }} containerStyle={{ flexDirection: "row", gap: 5, display: "flex", height: 50, alignItems: "center", justifyContent: "center", minWidth: 35 }} />
			}
			{isPhone ?
				<TouchableOpacity onPress={menuCallback} style={{ flexDirection: "row", gap: 5, display: "flex", height: 50, alignItems: "center", justifyContent: "center", minWidth: 35 }}>
					<Ionicons name={open ? "close" : "menu"} size={24} color="white" />
				</TouchableOpacity>
				: null
			}
		</View >
	);
};

const styles = StyleSheet.create({
	shadow: {
		shadowColor: "rgba(0, 0, 0, 0.3)",
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.4,
		shadowRadius: 3,
		elevation: 10,
	},
	superShadow: {
		shadowColor: "rgba(0, 0, 0, 0.3)",
		shadowOffset: { width: 0, height: 5 },
		shadowOpacity: 0.8,
		shadowRadius: 10,
		elevation: 10,
	},
});

export default HeaderWeb;
