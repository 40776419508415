import { DocumentReference, Timestamp } from "firebase/firestore";
import { isNaN } from "lodash";
import moment from "moment";

export default function sanitizeObject(input) {
    if (Array.isArray(input)) {
        return input.map((item) => {
            if (item === null || item === undefined || isNaN(item)) {
                return null;
            } else if (typeof input === "object") {
                return sanitizeObject(item);
            } else if (item === "") {
                return ""
            } else {
                return item;
            }
        });
    } else if (input === null || input === undefined || isNaN(input)) {
        return null
    } else if (input instanceof Timestamp) {
        return input.toDate()
    } else if (typeof input === "object" && input?.seconds) {
        return moment(input?.seconds * 1000).toDate()
    } else if (input instanceof DocumentReference) {
        return input
    } else if (input instanceof Date) {
        return input
    } else if (typeof input === 'object') {
        const result = {};
        for (const key in input) {
            if (input.hasOwnProperty(key)) {
                const value = input[key];
                result[key] = sanitizeObject(value);
            }
        }
        return result;
    } else {
        return input;
    }
}