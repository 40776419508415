import { useNavigation } from "@react-navigation/native";
import React, { useContext, useEffect } from "react";
import { ActivityIndicator, StyleSheet, View } from "react-native";
import AuthContext from "../../context/AuthContext";


const GhostLogin = ({ navigation, route }) => {


    const { userData } = useContext(AuthContext)
    const { navigate } = useNavigation();
    const params = route.params

    useEffect(() => {
        const key = params.key
        if (key && userData) {
            // console.log(key)
            window.localStorage.setItem('impersonatedUser', key)
            document.location = "/alumno/mi-perfil"
        } else {
            // console.log("NO LOGIN")
            navigate('Home')
        }

    }, [])

    return (
        <View style={StyleSheet.absoluteFillObject}>
            <ActivityIndicator />
        </View>
    );
};

GhostLogin.navigationOptions = {
    header: null,
};

export default GhostLogin;
