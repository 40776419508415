import * as yup from "yup"

const getValidationType = (type, message = "", required) => {
    switch (type) {
        case 'text':
            return required ?
                yup.string().required(message).typeError(message)
                :
                yup.string().nullable()
        case 'textarea':
            return required ?
                yup.string().required(message).typeError(message)
                :
                yup.string().nullable()
        case 'document':
            return required ?
                yup.string().required(message).typeError(message)
                :
                yup.string().nullable()
        case 'number':
            return required ?
                yup.string().required(message).typeError(message)
                :
                yup.string().nullable()
        case 'boolean':
            return required ?
                yup.string().required(message).typeError(message)
                :
                yup.string().nullable()
        case 'date':
            return required ?
                yup.date().required(message).typeError(message)
                :
                yup.date().nullable()
        case 'datetime':
            return required ?
                yup.date().required(message).typeError(message)
                :
                yup.date().nullable()
        case 'time':
            return required ?
                yup.string().required(message).typeError(message)
                :
                yup.string().nullable()
        case 'selector':
            return required ?
                yup.string().required(message).typeError(message)
                :
                yup.string().nullable()
        default:
            return required ?
                yup.string().required(message).typeError(message)
                :
                yup.string().nullable()
    }
}

export default getValidationType;