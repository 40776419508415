import React, { useEffect, useState } from 'react';
import PdfReader from "rn-pdf-reader-js";
import { Platform, View } from 'react-native';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import Button from './Button';

const SecurePDF = ({ uri, style = {} }) => {
    const [file, setFile] = useState(null)
    const storage = getStorage()

    useEffect(() => {
        if (uri) {
            if (uri.includes("http")) {
                setFile(uri)
            } else if (uri.includes("base64")) {
                setFile(uri)
            } else {
                const fileRef = ref(storage, uri)
                getDownloadURL(fileRef).then(res => {
                    setFile(res)
                }).catch(err => {
                    console.log(err)
                })
            }
        }
    }, [uri])

    if (!file) return null
    return (
        <View style={{ flex: 1 }}>
            <View style={{ flex: 1, backgroundColor:"#f3f3f3", borderRadius:7 }}>
                {Platform.OS !== "web" ? (
                    <PdfReader
                        style={{ flex: 1, ...style }}
                        withScroll
                        renderType="DIRECT_URL"
                        source={{
                            uri: file
                        }}
                    />
                ) : (
                    file.includes("pdf") ?
                        // <object onError={(ev) => console.log(ev)} data={file} style={{ flex: 1, ...style }} type="application/pdf" />
                        <iframe onError={(ev) => console.log(ev)} src={file} frameBorder='0' style={{ flex: 1, ...style }} />
                        :
                        <iframe onError={(ev) => console.log(ev)} src={`https://drive.google.com/viewerng/viewer?url=${encodeURIComponent(file)}&embedded=true`} frameBorder='0' style={{ flex: 1, ...style }} />
                    // <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${file}`} frameborder='0' style={{ flex: 1, ...style }} />

                )}
            </View>
            <View style={{ marginTop: 15, flexDirection: "row", justifyContent: "flex-end", alignItems: "center" }}>
                <Button onPress={() => window.open(file, "", "download")} round="7px" height="35px" label="Descargar Documento" />
            </View>
        </View>
    );
}

export default SecurePDF;