import { getApp } from "firebase/app";
import { applyActionCode, getAuth } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { Image, ImageBackground, Text, View } from "react-native";
import Button from "../../components/common/Button";
import customLog from "../../utils/customLog";

const EmailVerification = () => {

    const auth = getAuth()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const app = getApp()
    const functions = getFunctions(app, "europe-west1")

    useEffect(() => {
        // var mode = getParameterByName('mode');
        // // Get the one-time code from the query parameter.
        // var actionCode = getParameterByName('oobCode');
        // // (Optional) Get the continue URL from the query parameter if available.
        // var continueUrl = getParameterByName('continueUrl');
        // // (Optional) Get the language code if available.
        // var lang = getParameterByName('lang') || 'en';	
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const oobCode = urlParams.get("oobCode")
        // const apiKey = urlParams.get("apiKey")
        // const mode = urlParams.get("mode")
        // const lang = urlParams.get("lang")

        // console.log(oobCode, lang, apiKey, mode)
        // console.log(queryString)

        applyActionCode(auth, oobCode).then(res => {
            // console.log(res)
            // dispatchUserData({ type: "UPDATE_DATA", ...userData, emailVerified: true });
            setLoading(false)

            customLog({ screen: "EmailVerification", action: "login", description: "Usuario logueado Mediante Email" })
            setTimeout(() => {
                // auth.currentUser ? navigation.navigate("StackPublic", { screen: "HomeScreen" }) : navigation.navigate("Login")
                window.location.replace("/")
            }, 4000)
        }).catch(err => {
            console.log(err)
            setError(err)
            setLoading(false)
        })

    }, [])

    const sendEmailAgain = () => {
        const user = auth.currentUser.email
        setLoading(true)
        const sendUserVerificationEmail = httpsCallable(functions, "sendUserVerificationEmail")

        sendUserVerificationEmail({ email: user }).then(res => {
            // console.log(res)
            setLoading(false)
        }).catch(err => {
            console.log("EL ERROR ", err.message)
            setLoading(false)
        })
    }


    return (
        <ImageBackground
            style={{
                flex: 1,
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 2,
                paddingVertical: 20
            }}
            source={{ uri: "https://source.unsplash.com/1600x900/?soccer" }}
            resizeMethod="resize"
        >

            <View style={{ backgroundColor: "rgba(0,0,0,.6)", flex: 1, position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: 1 }} />

            <View style={{ zIndex: 3, alignItems: "center" }}>

                <View style={{ height: 150, width: 300, marginBottom: 20 }}>
                    <Image style={{ resizeMode: "contain", height: 150, width: 300 }} source={{ uri: "https://www.rfef.es/themes/custom/rfef/logo-icon.svg" }} />
                    {/* <Image style={{ resizeMode: "contain", height: 150, width: 300 }} source={require("../../assets/images/logo.png")} /> */}
                </View>

                <View style={{ marginBottom: 20 }}>

                </View>

                {error ? (
                    <>
                        <View
                            style={{
                                width: "100%",
                                borderRadius: 10,
                                marginTop: 15,
                                paddingHorizontal: 15,
                                alignItems: "center",
                                justifyContent: "center",
                                paddingVertical: 15,
                                backgroundColor: "#E9312B",
                            }}
                        >
                            <Text style={{ color: "white", fontSize: 18, textAlign: "center" }}>
                                Ocurrió un error verificando tu email, inténtalo de nuevo o haz click en el botón de reenviar email de confirmación.
                            </Text>
                            <Text style={{ fontFamily: "Regular", color: "white", fontSize: 18 }}>{error.message}</Text>
                        </View>
                        <View style={{ width: "100%", marginTop: 30 }}>
                            <Button loading={loading} onPress={sendEmailAgain} label="Volver a enviar" round={7} />
                        </View>
                    </>
                ) :
                    <>
                        <Text style={{ color: "white", fontSize: 30, textAlign: "center" }}>
                            ¡Tu correo se ha confirmado con éxito, ya puedes acceder a la aplicación!
                        </Text>
                        <Text style={{ color: "white", fontSize: 18, textAlign: "center" }}>
                            En unos segundos será redirigido a la aplicación. Si no es así, haz clic en el botón para ir a la aplicación.
                        </Text>
                        <View style={{ width: "100%", marginTop: 30, alignItems: "center" }}>
                            <Button loading={loading} onPress={() => window.location.replace("/")
                                // auth.currentUser ? navigation.navigate("StackPublic", { screen: "HomeScreen" }) : navigation.navigate("Login")
                            } label={error ? "Volver" : "Comenzar"} round={7} />
                        </View>
                    </>
                }

            </View>

        </ImageBackground>
    );
};

export default EmailVerification;
