import { useNavigation } from "@react-navigation/native";
import { useFirestoreDocument } from "@react-query-firebase/firestore";
import { doc, getFirestore } from "firebase/firestore";
import React from "react";
import { Dimensions, StyleSheet, useWindowDimensions, View } from "react-native";

const screenWidth = Dimensions.get("window").width;

const IframeScreen = ({ navigation, route }) => {

    const sc = useWindowDimensions()
    const sh = useWindowDimensions().height
    const isPhone = sc.width < 1000
    const db = getFirestore()

    const { navigate } = useNavigation();

    // console.log(navigation, route)

    const docRef = doc(db, "/tools/" + route?.params?.id)
    // console.log(route?.params?.id)
    const { data: tool } = useFirestoreDocument(["tools", route?.params?.id], docRef, {},
        {
            select: t => {
                if (t) { return t.data() }
            },
            onSuccess: it => {
                if (!it) {
                    alert("La herramienta solicitada no existe")
                    navigate("ToolScreen")
                }
            },
            onError: () => {
                alert("Ocurrió un error al cargar la herramienta solicitada")
                navigate("ToolScreen")
            }
        }
    )

    return (
        <View style={styles.outerWrapper}>
            {/* <View style={styles.wrapper}> */}
            {/* <StyledText style={{ fontFamily: "title", fontSize: isPhone ? 35 : 45, textAlign: "left", marginBottom: 10 }}>HERRAMIENTAS {tool ? ` - ${tool.name}` : ""}</StyledText>
                <TouchableOpacity onPress={() => navigate("ToolScreen")} style={{ marginBottom: "1em" }}>
                    <StyledText>&lt; Volver</StyledText>
                </TouchableOpacity> */}
            {tool && <iframe
                src={tool.url}
                style={{ width: "100%", height: "100%", border: 0 }}
            />}
            {/* </View> */}
        </View>
    );
};

IframeScreen.navigationOptions = {
    header: null,
};

const styles = StyleSheet.create({
    outerWrapper: {
        flex:1
    },
    wrapper: {
        // paddingTop: "2em",
        // maxWidth: 800,
        // width: "90%"
    }
});

export default IframeScreen;
