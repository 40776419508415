import React, { useEffect } from 'react';
import { Text, View } from 'react-native-web';

const PaymentRedirectScreen = () => {

    useEffect(() => {
        window.close();
    }, [])

    return (
        <View style={{ flex: 1, backgroundColor: "white", justifyContent: "center", alignItems: "center" }}>
            <Text>PaymentRedirectScreen</Text>
        </View>
    )
}

export default PaymentRedirectScreen;