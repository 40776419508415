import React, { useEffect, useState } from 'react';



const Elements = (el) => {


    const [elements, setElements] = useState({
        mainLogo: 'https://blacknosaur.s3.eu-west-3.amazonaws.com/apdlogo.png',
        homeLogo: 'https://blacknosaur.s3.eu-west-3.amazonaws.com/minilapolarlogo.png',
        placeholder_training: "https://test.unifranz.edu.bo/wp-content/uploads/2018/02/placeholder-square.jpg",
        placeholder: "https://nutrisport.es/web/wp-content/uploads/2019/12/placeholder.png",
        placeholderUser: "https://test.unifranz.edu.bo/wp-content/uploads/2018/02/placeholder-square.jpg",
        imgNotiPNG: "https://cdn.dribbble.com/users/992274/screenshots/9653848/media/0b20c7ff4076b354b014ca86156684a5.jpg",
        imgMessaPNG: "https://cdn.dribbble.com/users/992274/screenshots/9653848/media/0b20c7ff4076b354b014ca86156684a5.jpg",
        imgLead: "https://test.unifranz.edu.bo/wp-content/uploads/2018/02/placeholder-square.jpg",
        mainColor:'#017676',
        secondaryColor:'black',
    })

    const id = "5e55137efbbc064fbd0beb24"

    // const { data, error, loading, networkStatus } = useQuery(queries.getBrandAssets, { fetchPolicy:"cache-first", variables: { id } })

    // loading && console.log(loading, "LOGOS CARGAAA")
    // console.log(networkStatus)

    // useEffect(() => {
    //     data && data.customization && setElements({
    //         ...elements,
    //         placeholder: data.customization.placeholder[0] && data.customization.placeholder[0].url,
    //         mainLogo: data.customization.mainLogo[0].url,
    //         homeLogo: data.customization.homeLogo[0].url,
    //         placeholderUser: data.customization.placeholderUser[0] && data.customization.placeholderUser[0].url,
    //         mainColor: data.customization.mainColor,
    //         secondaryColor: data.customization.secondaryColor

    //     })
    // }, [data])

    return elements[el]
}

export default Elements;