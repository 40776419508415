import Portal from '@burstware/react-native-portal';
import React from 'react';
import { TouchableWithoutFeedback, View } from 'react-native';
import * as Animatable from "react-native-animatable";




const Modal = ({ children, onClickOutside = () => null, duration = 300, padding = 15, margin = 10, modalBackdropColor = "rgba(0,0,0,.5)", modalContainerStyle = {}, ...props }) => {



    return (
        <Portal>

            <Animatable.View animation={"fadeIn"} duration={300} style={{ width: "100%", height: "100%", overflow: "hidden", alignItems: "center", justifyContent: "center", backgroundColor: modalBackdropColor }}>
                <TouchableWithoutFeedback onPress={onClickOutside}>
                    <View style={{ position: "absolute", top: 0, bottom: 0, right: 0, left: 0 }} />
                </TouchableWithoutFeedback>

                <Animatable.View animation={"fadeInUp"} duration={300} delay={200} style={{ padding, margin, borderRadius: 10, backgroundColor: "white", ...modalContainerStyle }}>
                    {children}
                </Animatable.View>
            </Animatable.View>

        </Portal>
    );
}

export default Modal;