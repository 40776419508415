import { Ionicons, MaterialIcons } from "@expo/vector-icons";
import { useContext } from "react";
import { useWindowDimensions, View } from "react-native";
import AuthContext from "../context/AuthContext";
import Button from "./common/Button";
import Modal from "./common/Modal";
import StyledText from "./common/StyledText";

const PaymentCompletedModal = ({ paymentError, closeCallback }) => {

    const { customization } = useContext(AuthContext);
    const sh = useWindowDimensions().height

    return (
        <Modal modalContainerStyle={{ maxHeight: sh - 50, width: "95%", maxWidth: 750 }} duration={100} padding={0} >
            {paymentError ?
                <View pointerEvents={"auto"} style={{ overflow: "hidden", height: "auto", opacity: 1, borderRadius: 7 }} >
                    <View style={{ alignItems: "center", justifyContent: "center", flex: 1 }}>
                        <MaterialIcons name={"error-outline"} color={customization.mainColor} size={120} />
                        <StyledText style={{ fontSize: 34, fontFamily: "Title", textAlign: "center" }}>Ha ocurrido un error al procesar su pago</StyledText>
                        <StyledText style={{ textAlign: "center", fontSize: 16, maxWidth: 500, marginVertical: 15, fontFamily: "DemiBold" }}>Por favor, inténtalo de de nuevo.</StyledText>
                        <View style={{ flexDirection: "row", marginTop: 10, marginBottom: 30, alignItems: "center", justifyContent: "center" }}>
                            <Button
                                style={{ marginRight: 10 }}
                                round="7px"
                                label="Volver"
                                primary
                                onPress={() => closeCallback()}
                            />
                        </View>
                    </View>
                </View>
                :
                <View pointerEvents="auto" style={{ overflow: "hidden", height: "auto", opacity: 1, backgroundColor: "white", borderRadius: 7 }} >
                    <View style={{ alignItems: "center", justifyContent: "center", flex: 1 }}>
                        <Ionicons name={"checkmark-circle-outline"} color={"green"} size={120} />
                        <StyledText style={{ fontSize: 34, fontFamily: "Title", textAlign: "center" }}>¡Tu pago se ha realizado con éxito!</StyledText>
                        <StyledText style={{ textAlign: "center", fontSize: 16, maxWidth: 500, marginVertical: 15, fontFamily: "DemiBold" }}>
                            Tu pago se ha procesado correctamente
                        </StyledText>
                        <View style={{ flexDirection: "row", marginTop: 10, marginBottom: 30, alignItems: "center", justifyContent: "center" }}>
                            <Button style={{ marginRight: 10 }} round="7px" label="Cerrar" primary onPress={() => closeCallback()} />
                        </View>
                    </View>
                </View>}
        </Modal>
    )
}

export default PaymentCompletedModal;
