import React, { useContext, useEffect } from "react";
import { View, useWindowDimensions } from "react-native";


import { ScrollView } from "react-native-gesture-handler";

import moment from "moment";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Button from "../../components/common/Button";
import Modal from "../../components/common/Modal";
import StyledText from "../../components/common/StyledText";
import CheckBoxField from "../../components/formComponents/CheckBoxField";
import AuthContext from "../../context/AuthContext";
import useYupValidationResolver from "../../utils/useYupValidationResolver";
import TextField from "../formComponents/TextField";


const CustomFormDeclaracionMedica = ({ submitCallback, closeCallback, initialData, course, tenantData }) => {

    const { userData } = useContext(AuthContext)
    const screen = useWindowDimensions()
    const validationSchema = yup.object({
        terms: yup.boolean().oneOf([true], "Debe aceptar los términos y condiciones"),
        location: yup.string().required("Debe ingresar la ubicación"),
    });

    const resolver = useYupValidationResolver(validationSchema);
    const {
        handleSubmit,
        reset,
        control,
        formState: { errors },
    } = useForm({
        mode: "onChange",
        resolver: resolver,
        defaultValues: {
            address: userData.address || "",
            courseName: course?.name || "",
            federationName: course?.tenantName || "",
            location: userData.town || userData.province || "",
            date: new Date(),
            terms: false,
            name: userData.name || "",
            surname: userData.surname || "",
            idNumber: userData.idNumber || "",
        },
    });
    useEffect(() => {
        if (initialData) {
            reset({
                address: userData.address || "",
                location: userData.town || userData.province || "",
                courseName: initialData.courseName || course?.name || "",
                federationName: initialData.federationName || course?.tenantName || "",
                terms: false,
                ...initialData,
                date: new Date(),
                name: userData.name || "",
                surname: userData.surname || "",
                idNumber: userData.idNumber || "",
            })
        }
    }, [initialData])


    return (
        <Modal padding={0} modalContainerStyle={{ maxWidth: 900, width: "95%", minWidth: 400, backgroundColor: "white", borderRadius: 20 }} >
            <ScrollView contentContainerStyle={{ padding: 20 }} style={{ maxHeight: screen.height - 120 }}>
                <StyledText style={{ fontSize: 34, fontFamily: "Title", fontWeight: "bold" }}>Declaración Responsable Certificado Médico</StyledText>


                <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "#f2f2f2", borderRadius: 10, padding: 15, marginVertical: 20 }}>
                    <StyledText style={{ fontWeight: "bold" }}>El Sr/a {userData.name} {userData.surname} con {userData.idType} {userData.idNumber}.</StyledText>
                </View>

                <View style={{ flexDirection: "row", alignItems: "center", borderRadius: 10, paddingLeft: 15, marginBottom: 15, columnGap: 10 }}>
                    <StyledText>{`Y domicilio en`}</StyledText>
                    <TextField style={{ height: 40 }} placeholder="escribe..." name="address" control={control} error={errors["address"]} />
                </View>
                <View style={{ flexDirection: "row", alignItems: "center", borderRadius: 10, paddingLeft: 15, marginBottom: 15, columnGap: 10 }}>
                    <StyledText>Como alumno del curso</StyledText>
                    <TextField style={{ height: 40 }} placeholder="escribe..." name="courseName" control={control} error={errors["courseName"]} />
                </View>
                <View style={{ flexDirection: "row", alignItems: "center", borderRadius: 10, paddingLeft: 15, marginBottom: 20, columnGap: 10 }}>
                    <StyledText>Impartido por la federación</StyledText>
                    <TextField style={{ height: 40 }} placeholder="escribe..." name="federationName" control={control} error={errors["federationName"]} />
                </View>

                <View style={{ paddingHorizontal: 15 }}>
                    <StyledText style={{ fontWeight: "Bold", marginBottom: 15 }}>Declaro Responsablemente</StyledText>
                    <StyledText style={{ marginBottom: 15 }}>1 Que no tengo impedimento de salud alguno para poder realizar aquellas funciones propias de un entrenador de fútbol, tales como la dirección de entrenamientos y de partidos.</StyledText>
                    <StyledText style={{ marginBottom: 15 }}>2 Que no me ha sido diagnosticada o detectada enfermedad o impedimento físico que sea incompatible con la práctica de entrenador de fútbol.</StyledText>
                </View>


                <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "#f2f2f2", borderRadius: 10, padding: 15, marginVertical: 20 }}>
                    <StyledText style={{ marginRight: 15 }}>En</StyledText>
                    <TextField style={{ height: 40, marginHorizontal: 15 }} placeholder="Localización" name="location" control={control} error={errors["location"]} />
                    <StyledText style={{ marginLeft: 15 }}>a {moment().format("DD [de] MMMM [de] YYYY")}</StyledText>
                </View>
                {
                    errors["location"]
                        ? <StyledText style={{ color: "red", marginTop: 10 }}>{errors["location"].message}</StyledText>
                        : null
                }

                <View style={{ paddingHorizontal: 15 }}>
                    <CheckBoxField error={errors["terms"]} name="terms" key="terms" label={"ACEPTO que conozco las consecuencias de lo declarado y las asumo."} control={control} />
                </View>
            </ScrollView>

            <View style={{ padding: 20, flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                <Button height="35px" color="#f2f2f2" label="Volver" round="7px" onPress={closeCallback} />
                <Button height="35px" label="Guardar" round="7px" onPress={handleSubmit(submitCallback)} />
            </View>
        </Modal>
    )
}


export default CustomFormDeclaracionMedica