import { Ionicons } from "@expo/vector-icons";
import { useNavigation, useRoute } from "@react-navigation/native";
import moment from "moment";
import React, { useContext, useState } from "react";
import { ActivityIndicator, ScrollView, Text, TouchableOpacity, View } from "react-native";
import ImageRel from "../../components/common/ImageRel";

// import firebase from "firebase/app";
import { useFirestoreQuery } from "@react-query-firebase/firestore";
import { addDoc, collection, doc, getFirestore, orderBy, query, serverTimestamp } from "firebase/firestore";
import { useForm } from "react-hook-form";
import Button from "../../components/common/Button";
import Modal from "../../components/common/Modal";
import SecureImage from "../../components/common/SecureImage";
import StyledText from "../../components/common/StyledText";
import TextField from "../../components/formComponents/TextField";
import AdvanceContext from "../../context/AdvanceContext";
import AuthContext from "../../context/AuthContext";

const CourseForum = () => {
	const { customization, userData } = useContext(AuthContext);
	const { courses } = useContext(AdvanceContext)
	const db = getFirestore()
	const navigation = useNavigation();
	const route = useRoute()
	const params = route.params
	const id = params?.id;

	const course = courses.find(c => c.id === id)
	const tenant = course ? doc(db, "tenants/" + course?.tenantId) : null

	const { navigate } = useNavigation();
	const [loadingMore, setLoadingMore] = useState(false);
	const [isMoreContent, setIsMoreContent] = useState(false);
	const [limit, setLimit] = useState(4);
	const [newForumModal, setNewForumModal] = useState(false);


	const topics = tenant ? collection(db, `${tenant.path}/courses/${id}/topics`) : null;
	const topicsQuery = tenant ? query(topics, orderBy("lastActive", "desc")) : null;
	const { data, isLoading } = useFirestoreQuery([tenant?.id, id, "forum"], topicsQuery, {
		subscribe: true,
	}, {
		refetchOnMount: "always",
		enabled: !!tenant,
		select: d => d?.docs?.map(x => ({ ...x.data(), id: x.id, ref: x.ref }))
	})


	if (isLoading)
		return (
			<View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
				<ActivityIndicator size="large" />
			</View>
		);

	return (
		<>
			<View style={{ backgroundColor: "white", flex: 1 }}>
				<ScrollView
					showsVerticalScrollIndicator={false}
					contentContainerStyle={{
						paddingBottom: 100,
					}}>
					<View>
						<SecureImage uri={course?.image} />
						<ImageRel
							source={course?.image}
							background={true}
							style={{
								width: "100%",
								overflow: "hidden",
								alignItems: "center",
							}}>
							<View style={{ flex: 1, backgroundColor: "rgba(0,0,0,0.5)", position: "absolute", top: 0, left: 0, bottom: 0, right: 0 }} />
							<View style={{ padding: 20 }}>
								<StyledText
									style={{
										color: "white",
										textAlign: "center",
										fontFamily: "DemiBold",
										zIndex: 100,
										fontSize: 22,
										padding: 20,
									}}>
									Foro del curso {course?.name}
								</StyledText>
							</View>
						</ImageRel>
					</View>


					<View style={{ maxWidth: 800, width: "100%", alignSelf: "center" }}>
						{data?.length ? data?.map((h, i) => {
							return (
								<TouchableOpacity
									key={"topic-" + h.id}
									onPress={() =>
										navigate("SingleTopic", {
											id: h.id,
											courseID: h.forum.id,
										})
									}>
									<View
										style={{
											...styles.topicBox,
											borderTopWidth: i === 0 ? 1 : 0,
										}}>
										{/* <Image source={{ uri: h.user && h.user.image && (URL.URL_API + h.user.image.url) }} style={styles.imageRedond}/> */}

										<View style={{ flex: 1 }}>
											<View style={{ flexDirection: "row", justifyContent: "space-between" }}>
												<View style={{ flex: 1 }}>
													<Text style={{ fontSize: 22, fontFamily: "DemiBold" }}>{h.title}</Text>
												</View>
												<View style={{ width: 50, marginTop: 5 }}>
													<Text style={{ fontSize: 12, textAlign: "right", color: "#aaa" }}>{moment(h.lastActive ? h.lastActive.toDate() : "").fromNow("mm:mm")}</Text>
												</View>
											</View>

											<View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginTop: 20 }}>
												<View style={{ flexDirection: "row", alignItems: "center", borderRadius: 15, height: 30, paddingRight: 15, backgroundColor: "#f2f2f2" }}>
													<ImageRel placeholder={"https://picsum.photos/200/300/?blur=2"} source={h.user && h.user.image} style={{ width: 35, height: 35, borderRadius: 18, marginRight: 10 }} />
													<Text style={{ color: "grey", fontFamily: "Regular" }}>
														{h.user.name}
														{/* {moment(h.createdAt).format("DD/MM/YYYY")} */}
													</Text>
												</View>

												<View
													style={{
														marginLeft: 30,
														display: "flex",
														flexDirection: "row",
														alignItems: "center",
														backgroundColor: "#f2f2f2",
														height: 30,
														borderRadius: 15,
														paddingHorizontal: 15,
													}}>
													<Ionicons name="chatbox" size={15} color="gray" style={{ marginRight: 5 }} />
													<Text style={{ fontSize: 12, fontFamily: "DemiBold" }}>{h.responses}</Text>
												</View>
											</View>
										</View>
									</View>
								</TouchableOpacity>
							);
						}) :

							<View style={{ rowGap: 15, marginTop: 40, backgroundColor: "#f2f2f2", padding: 15, borderRadius: 10, paddingVertical: 100, alignItems: "center", justifyContent: "center" }}>
								<StyledText style={{ fontSize: 16 }}>
									No hay hilos en este foro
								</StyledText>
								<Button
									label="Crear Hilo"
									color={customization.mainColor}
									height={35}
									round={7}
									onPress={() => setNewForumModal(true)}
								/>
							</View>

						}
						<View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginTop: 20 }}>
							{isMoreContent ? (
								<Button
									loading={loadingMore}
									label="Más Hilos"
									color={customization.mainColor}
									onPress={() => {
										setLoadingMore(true);
										setLimit(limit + 4);
									}}
								/>
							) : (
								<View />
							)}
							{data?.length ? <Button
								label="Nuevo Hilo"
								color={customization.mainColor}
								height={35}
								round={7}
								onPress={() => setNewForumModal(true)}
							/> : null}
						</View>
					</View>
				</ScrollView >
			</View >
			{newForumModal ?
				<NewForum courseRef={course.ref} tenantRef={course?.tenantId ? doc(db, `tenants/${course.tenantId}`) : null} closeCallback={() => setNewForumModal(false)} />
				: null}
		</>
	);
};

const NewForum = ({ closeCallback, courseRef, tenantRef }) => {
	const { userData, customization, } = useContext(AuthContext)
	const [loading, setLoading] = useState(false)
	const navigate = useNavigation().navigate
	const db = getFirestore()

	const {
		handleSubmit,
		control,
		formState: { errors }
	} = useForm({
		mode: "onChange",
		defaultValues: {
			forum: courseRef,
			lastActive: serverTimestamp(),
			responses: 1,
			tenant: tenantRef,
			title: "",
			body: "",
			user: {
				id: userData.id,
				image: userData.image,
				name: userData.name || "",
				surname: userData.surname || "",
				email: userData.email || "",
			},
		}
	})

	const sendNewTopic = (values) => {
		setLoading(true)

		const colRef = collection(db, `${courseRef.path}/topics`)

		addDoc(colRef, values)
			.then(ev => {
				closeCallback()
				setLoading(false)
				navigate("MainTabNavigator", { screen: "SingleTopic", params: { courseID: courseRef.id, id: ev.id } })
			})
			.catch(err => {
				console.log(err)
				alert("Ha ocurrido un error al crear este hilo")
				setLoading(false)
			})
	}


	return (
		<Modal padding={0} modalContainerStyle={{ maxWidth: 800, width: "100%", maxHeight: "95%" }} onClickOutside={closeCallback}>
			<View style={{ marginBottom: 15, paddingLeft: 15, paddingRight: 10, paddingTop: 15, width: "100%", flexDirection: "row", alignItems: "center" }}>
				<View style={{ flex: 1 }}>
					<StyledText style={{ fontFamily: "Title", textAlign: "left", fontSize: 34 }}>Nuevo Hilo</StyledText>
				</View>
				<TouchableOpacity style={{ width: 40, height: 40, alignItems: "center", justifyContent: "center" }} onPress={closeCallback} >
					<Ionicons name="close" size={30} color="black" />
				</TouchableOpacity>
			</View>
			<View style={{ paddingHorizontal: 15, paddingBottom: 15, rowGap: 10 }}>
				<View>
					<TextField
						label="Título"
						control={control}
						name="title"
						error={errors.title}
						placeholder="Título..."
					/>
				</View>
				<View>
					<TextField
						multiline={true}
						label="Contenido"
						control={control}
						name="body"
						error={errors.body}
						style={{ minHeight: 200, paddingTop: 10 }}
						placeholder="Contenido del hilo..."
					/>

				</View>
				<Button
					disabled={loading}
					loading={loading}
					label="Crear Hilo"
					color={customization.mainColor}
					height={35}
					round={7}
					onPress={handleSubmit(sendNewTopic)}
				/>
			</View>
		</Modal>
	)
}

const styles = {
	topicBox: {
		width: "100%",
		padding: 15,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		borderBottomWidth: 1,
		borderColor: "gainsboro",
	},
	imageRedond: {
		height: 30,
		width: 30,
		borderRadius: 15,
		borderWidth: 1,
		borderColor: "gainsboro",
		marginRight: 10,
	},
	btn: {
		width: "100%",
		height: 50,
		flexDirection: "row",
		backgroundColor: "#017676",
		borderRadius: 25,
		justifyContent: "center",
		alignItems: "center",
	},
};
export default CourseForum;
