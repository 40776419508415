import { FontAwesome } from "@expo/vector-icons";
import React, { useContext } from "react";
import { Controller } from "react-hook-form";
import { View } from "react-native";
import { TouchableOpacity } from "react-native-web";
import AuthContext from "../../context/AuthContext";
import StyledText from "../common/StyledText";

const CheckBoxField = ({ label, moreInfo, error = false, link = false, rules = {}, labelColor = "gray", checkboxColor = null, secureInput = false, containerProps = { style: { flex: 1 } }, type = "text", ...props }) => {

	const { customization } = useContext(AuthContext)

	return (
		<View {...containerProps} >

			<View style={{ flex: 1 }}>
				<Controller
					{...props}
					rules={rules}
					render={({ field }) => {
						return (
							<TouchableOpacity onPress={() => field.onChange(!field.value)} style={{ flex: 1, flexDirection: "row", alignItems: "center" }}>
								<View style={{ marginRight: 10, borderWidth: 3, borderColor: checkboxColor || customization.mainColor, borderRadius: 4, width: 30, height: 30, alignItems: "center", justifyContent: "center" }}>
									{field.value && <FontAwesome name={"check"} size={20} color={checkboxColor || customization.mainColor} />}
								</View>
								<View style={{ flex: 1 }}>
									{label && (
										typeof label === "string" ? (
										<StyledText style={{ color: labelColor, fontSize: 16 }}>
											{label}
										</StyledText>
										) : (
											label
										)
									)}
								</View>
							</TouchableOpacity>
						);
					}}
				/>

				{moreInfo && (
					<StyledText style={{ marginTop: 10, fontSize: 12 }}>
						{moreInfo}
					</StyledText>
				)}
				{error && (
					<StyledText style={{ marginTop: 10, fontSize: 12, color: "red" }}>
						{error?.message}
					</StyledText>
				)}
			</View>
		</View>
	);
};

export default CheckBoxField;
