import { Ionicons } from '@expo/vector-icons';
import * as DocumentPicker from 'expo-document-picker';
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import React, { useContext, useState } from "react";
import { Controller } from "react-hook-form";
import { ActivityIndicator, TouchableOpacity, View } from "react-native";
import AuthContext from "../../context/AuthContext";
import StyledText from "../common/StyledText";

const FileField = ({ label, moreInfo, error = false, rules = {}, editable = true, containerProps = { style: { flex: 1 } }, route = "",control, ...props }) => {

	const { customization } = useContext(AuthContext);
	const [loading, setLoading] = useState(false)
	const storage = getStorage()

	const signFile = async (uri) => {
		const fileRef = ref(storage, uri)
		const p = await getDownloadURL(fileRef).then(res => res).catch(err => "")
		return p
	}

	const getFile = async () => {
		setLoading(true)
		const p = await DocumentPicker.getDocumentAsync().then(async res => {
			try {
				if (res.type === "success") {
					// console.log(res)
					// console.log(`${route + "/"}${new Date().valueOf()}-${res.name}`)
					const fileRef = ref(storage, `${route + "/"}${new Date().valueOf()}-${res.name}`)
					const p = await uploadBytes(fileRef, res.file).then(res => res.ref.fullPath).catch(err => "")
					props.setValue && props.setValue(p)
					setLoading(false)
					return p
				} else {
					setLoading(false)
					return null
				}
			} catch (err) {
				setLoading(false)
				return null
			}
		}).catch(err => {
			setLoading(false)
			return null
		})
		setLoading(false)
		return p
	}

	return (
		<View {...containerProps} >
			{label && (
				<StyledText style={{ color: "gray", fontSize: 16, marginBottom: 10 }}>
					{label}
				</StyledText>
			)}

			<View style={{ flex: 1, marginBottom: 10 }}>
				<Controller
					{...props}
					control={control}
					rules={rules}
					name={props.name}
					render={({ field }) => {

						return (
							<View style={{ flexDirection: "row", flex: 1 }}>
								<TouchableOpacity
									disabled={!editable}
									onPress={() => getFile().then(res => field.onChange(res))}
									style={{ overflow: "hidden", flexDirection: "row", flex: 1, height: 40, borderRadius: 7, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderWidth: 1, borderColor: "gainsboro" }}>
									{editable ?
										<View
											style={{ backgroundColor: "#f3f3f3", width: 45, height: 40, alignItems: "center", justifyContent: "center", borderRightWidth: 1, borderRightColor: "#f3f3f3" }}>
											{loading ? <ActivityIndicator color="black" /> : <Ionicons name={"cloud-upload-outline"} size={25} color="black" />}
										</View>
										: null
									}

									<View style={{ flex: 1, justifyContent: "center", paddingHorizontal: 15 }}>
										<StyledText numberOfLines={1}>{field?.value ? JSON.stringify(field?.value).replaceAll(`"`, "").split("/").pop() || "Selecciona un documento..." : "Selecciona un documento..."}</StyledText>
									</View>

								</TouchableOpacity>
								{
									field.value ?
										<>
											<TouchableOpacity
												onPress={async () => {
													let uri = await signFile(field.value);
													if (uri) window.open(uri)
												}}
												style={{ backgroundColor: customization?.mainColor, width: 45, height: 40, alignItems: "center", justifyContent: "center", borderTopEndRadius: editable ? 0 : 7, borderBottomEndRadius: editable ? 0 : 7, }}>
												<Ionicons name="eye-outline" size={20} color="white" />
											</TouchableOpacity>
											{editable ?
												<TouchableOpacity onPress={() => field.onChange("")} style={{ backgroundColor: "gray", width: 45, height: 40, alignItems: "center", borderTopEndRadius: 7, borderBottomEndRadius: 7, justifyContent: "center" }}>
													<Ionicons name="trash-bin-outline" size={20} color="white" />
												</TouchableOpacity>
												: null
											}
										</>
										: null
								}
							</View>
						)
					}}
				/>


				{moreInfo && (
					<StyledText style={{ marginTop: 10, fontSize: 12 }}>
						{moreInfo}
					</StyledText>
				)}
				{error && (
					<StyledText style={{ fontSize: 14, color: "red", marginTop: 5 }}>
						{error?.message}
					</StyledText>
				)}
			</View>
		</View >
	);
}

export default FileField;
