import { useNavigation, useRoute } from '@react-navigation/native';
import { arrayUnion, collection, deleteDoc, doc, getDoc, getFirestore, serverTimestamp, setDoc, updateDoc } from 'firebase/firestore';
import React, { FC, forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { ActivityIndicator, Alert, ScrollView, useWindowDimensions, View } from 'react-native';
import AuthContext from '../../context/AuthContext';

import { useFirestoreDocument, useFirestoreQuery } from '@react-query-firebase/firestore';
import { Timeline, TimelineAction, TimelineRow, TimelineState } from '@xzdarcy/react-timeline-editor';

import { Ionicons } from '@expo/vector-icons';
import chroma from "chroma-js";
import { deleteObject, getDownloadURL, getStorage, ref, ref as StorageRef, uploadBytes, uploadString } from 'firebase/storage';
import { clamp, flatMap, get, groupBy, orderBy } from 'lodash';
import { v4 } from "uuid";
import Button from '../../components/common/Button';
import Modal from '../../components/common/Modal';
import StyledText from '../../components/common/StyledText';
import TimelinePlayer from '../../components/playerEditor/player';
import VideoBoard from './VideoBoard';

// import { FFmpeg } from '@ffmpeg/ffmpeg';
import { FFmpeg } from "@ffmpeg/ffmpeg";

import { fetchFile, toBlobURL } from '@ffmpeg/util';
import { useForm } from 'react-hook-form';


async function isBase64UrlImage(base64String: string) {
    let image = new Image()
    image.src = base64String
    return await (new Promise((resolve) => {
        image.onload = function () {
            if (image.height === 0 || image.width === 0) {
                resolve(false);
                return;
            }
            resolve(true)
        }
        image.onerror = () => {
            resolve(false)
        }
    }))
}


const VideoExercise = () => {

    const { userData } = useContext(AuthContext)
    const { id } = useRoute().params as any
    const db = getFirestore()
    const navigation = useNavigation()
    const storage = getStorage()

    const [loaded, setLoaded] = useState(false);
    const ffmpegRef = useRef(new FFmpeg());

    const messageRef = useRef(null);

    const videoPreviewRef = useRef(null)

    // const [isLoading, setIsLoading] = React.useState(false)
    const [viewBoard, setViewBoard] = React.useState(false)
    const [rendering, setRendering] = React.useState(false)
    const progressRef = useRef(null)
    const relativeDuration = useRef(0)


    const screen = useWindowDimensions()

    const [setSelected, setSetSelected] = React.useState(null)
    const [eventMenu, setEventMenu] = React.useState(false)
    const [selectedEvent, setSelectedEvent] = React.useState(null)
    const [selectedDraw, setSelectedDraw] = React.useState(null)
    const [loadingDraw, setLoadingDraw] = React.useState(false)
    const [zoom, setZoom] = useState(-2.5)


    const load = async () => {

        const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.6/dist/esm'
        // const baseURL = 'https://unpkg.com/@ffmpeg/core-mt@0.12.6/dist/esm'
        const ffmpeg = ffmpegRef.current;

        // ffmpeg.on("log", ({ message }) => {
        //     console.log(message)
        // })

        ffmpeg.on("progress", ({ progress, time }) => {
            progressRef.current.style.width = `${(progress / relativeDuration?.current) * 100}%`
        })




        await ffmpeg.load({
            coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
            wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm'),
            // workerURL: await toBlobURL(`${baseURL}/ffmpeg-core.worker.js`, 'text/javascript'),
        })
        setLoaded(true);
    }


    useEffect(() => {
        load()
        return () => {
            ffmpegRef?.current?.terminate()
        }
    }, [])


    const docRef = doc(db, `users/${userData.id}/videoExercises/${id}`)

    const { data, isLoading } = useFirestoreDocument(["videoExercises", userData.id, { id }], docRef, {
        subscribe: true
    }, {
        select: (d) => ({ ...d.data() as { draws: any[], events: any[] }, ref: d.ref }),
    })

    const eventsRef = collection(db, `users/${userData.id}/eventSets`)

    const { data: events } = useFirestoreQuery(["eventSets", userData.id], eventsRef, {
    }, {
        select: (d) => d.docs.map(x => ({ ...x.data(), id: x.id, ref: x.ref })).sort((a, b) => {
            const dateA = a?.updatedAt?.toDate()
            const dateB = b?.updatedAt?.toDate()
            return dateB.getTime() - dateA.getTime()
        })
    })

    const videoRef = useRef(null)
    const timelineState = useRef<TimelineState>();
    const scrollViewRef = useRef<HTMLDivElement>(null)
    const autoScrollWhenPlay = useRef<boolean>(true);


    const generateVideo = async (cuts, overlays, width = 1280, height = 720, name) => {
        try {
            setRendering(true)
            const ffmpeg = ffmpegRef.current;



            const originalVideoDuration = videoRef?.current?.duration()
            const validOverlays = orderBy(overlays, ["start"], "asc").filter(x => cuts.some(y => y.start <= x.start && y.end >= x.start))

            const overlayUris = await Promise.all((validOverlays).map(async (overlay) => {
                const uri = await getDownloadURL(ref(storage, overlay.uri)).then(res => res).catch(err => null)
                return uri
            }))


            const clipDuration = cuts.reduce((acc, cur) => acc + (cur.end - cur.start), 0) + validOverlays.reduce((acc, cur) => acc + cur.duration || 5, 0)

            relativeDuration.current = clipDuration / originalVideoDuration


            let concat = []
            let finalCuts = []
            let overlayTimes = []
            let startTime = 0

            orderBy(cuts, ["start"], "asc").forEach((cut, index) => {

                const hasOverlay = validOverlays.filter(x => x.start >= cut.start && x.start <= cut.end)

                if (hasOverlay?.length > 0) {
                    // first part
                    finalCuts.push({
                        ...cut,
                        start: cut.start,
                        end: hasOverlay[0].start
                    })

                    hasOverlay.forEach((overlay, index, arr) => {

                        if (hasOverlay.length === 1) {
                            //IF THERE IS ONLY ONE OVERLAY, JUST PUT THE MIDDLE PART
                            finalCuts.push({
                                ...overlay,
                                overlay: true,
                                start: 0,
                                end: overlay.duration
                            })
                        } else if (index === 0) {
                            //IF THE OVERLAYS ARE MORE THAN 1 AND ITS THE FIRST OVERLAY 

                            //ADD THE OVERLAY
                            finalCuts.push({
                                ...overlay,
                                overlay: true,
                                start: 0,
                                end: overlay.duration
                            })

                            // ADD THE SECOND PART
                            finalCuts.push({
                                ...cut,
                                start: overlay.start,
                                end: arr[index + 1].start
                            })


                        } else if (index === arr.length - 1) {
                            //IF THE OVERLAYS ARE MORE THAN 1 AND ITS THE LAST ONE

                            //ADD THE OVERLAY
                            finalCuts.push({
                                ...overlay,
                                overlay: true,
                                start: 0,
                                end: overlay.duration
                            })

                        } else {
                            //ALL THE REST OF THE OVERLAYS IN THE MIDDLE 

                            finalCuts.push({
                                ...overlay,
                                overlay: true,
                                start: 0,
                                end: overlay.duration
                            })


                            finalCuts.push({
                                ...cut,
                                start: overlay.start,
                                end: arr[index + 1].start
                            })
                        }

                        overlayTimes.push({
                            ...overlay,
                            start: startTime + overlay.start - cut.start,
                            end: startTime + overlay.start - cut.start + overlay.duration
                        })

                        startTime = startTime + cut.end - cut.start + overlay.duration
                    })




                    // last part
                    finalCuts.push({
                        ...cut,
                        start: hasOverlay[hasOverlay.length - 1].start,
                        end: cut.end
                    })
                } else {
                    finalCuts.push(cut)
                    startTime = startTime + cut.end - cut.start
                }
            })


            let overlayIndex = 0

            const videoTrims = finalCuts.map((cut, index) => {
                if (cut.overlay) {
                    const overlayPosition = overlayIndex + 1
                    concat.push(`[v${overlayPosition}o][a${index}]`)
                    const str = `[0:v]trim=start=${Math.fround(cut.start)}:end=${Math.fround(cut.end)},setpts=PTS-STARTPTS[v${index}];[${overlayPosition}:v]scale=${width}:${height}[o${overlayPosition}];[v${index}][o${overlayPosition}]overlay=0:0:enable='between(t,0,${cut.end})'[v${overlayPosition}o]`;
                    overlayIndex++
                    return str
                } else {
                    concat.push(`[v${index}][a${index}]`)
                    return `[0:v]trim=start=${Math.fround(cut.start)}:end=${Math.fround(cut.end)},setpts=PTS-STARTPTS[v${index}]${cut?.id ? `;[v${index}]drawtext=text='${cut.name || ""}':x=10:y=(h-text_h)-10:fontfile=font.woff:fontsize=30:fontcolor=${chroma(cut.color).luminance() > 0.5 ? "black" : "white"}:enable='between(t,${0},${5})':box=1:boxcolor=${cut.color}:boxborderw=10[v${index}]` : ""}`;
                }
            }).join(';');



            const audioTrims = finalCuts.map((cut, index) => {
                return `[0:a]atrim=start=${Math.fround(cut.start)}:end=${Math.fround(cut.end)}${cut.overlay ? ",volume=0" : ""},asetpts=PTS-STARTPTS[a${index}]`;
            }).join(';');


            const videoUriFragment = videoRef?.current?.src + `&t=${Math.round(finalCuts[0].start)},${Math.round(finalCuts[finalCuts.length - 1].end)}`
            // console.log("LOAD ", videoUriFragment)

            await Promise.all([
                ffmpeg.writeFile('font.woff', await fetchFile('https://cdnjs.cloudflare.com/ajax/libs/iCheck/1.0.1/demo/css/montserrat-regular.woff')),
                // ffmpeg.writeFile('input.mp4', await fetchFile(videoUriFragment)),
                ffmpeg.writeFile('input.mp4', await fetchFile(videoUriFragment)),

                //GET THE BLACK CLIP
                ffmpeg.writeFile('black.mp4', await fetchFile("https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fblack.mp4?alt=media&token=85ddaab2-e210-4c5a-8ce6-808231ce2879")),
                //GET THE OVERALY URIS AND INCLUDE THEM INTO THE FETCHFILES PROMISE
                ...orderBy(overlayUris, ["start"], "asc").map(async (uri, index) => ffmpeg.writeFile(`overlay${index}.jpg`, await fetchFile(uri)))
            ]);


            const concatFilter = concat.join("") + `concat=n=${concat.length}:v=1:a=1[vout]`


            const command = [
                '-i', "input.mp4",
                ...(overlayTimes.length > 0 ? overlayTimes.map((x, i) => ['-i', `overlay${i}.jpg`]).flat() : []),
                '-filter_complex',
                `${videoTrims};${audioTrims};${concatFilter}`,
                '-map', '[vout]',
                "-threads", "4",
                "-preset", "superfast",
                'output.mp4'
            ]

            // console.log("EL COMANDO ", command)

            progressRef.current.style.width = "2%"


            await ffmpeg.exec(command);
            const data = await ffmpeg.readFile('output.mp4');

            // videoPreviewRef.current.src = URL.createObjectURL(new Blob([data.buffer], {type: 'video/mp4'}));

            progressRef.current.style.width = `0%`

            setRendering(false)
            Alert.alert("Video generado correctamente")
            const a = document.createElement('a');
            a.download = (name || "Resumen") + '.mp4';
            a.href = URL.createObjectURL(new Blob([data.buffer], { type: 'video/mp4' }));
            a.click();


        } catch (err) {
            console.log(err)
            progressRef.current.style.width = `0%`
            setRendering(false)
            alert("Ocurrió un evento al generar el video, asegurate de estar en un navegador compatible y que los dibujos no se solapen entre vaios eventos")
        }
    }


    const downloadDraw = async (draw) => {
        try {

            const uri = await getDownloadURL(StorageRef(storage, draw.thumbnail))
            fetch(uri)
                .then(res => res.blob())
                .then(blob => {
                    const a = document.createElement('a');
                    a.download = draw.id + '.jpg';
                    a.href = URL.createObjectURL(blob);
                    a.click();
                })
                .catch(err => {
                    console.log(err)
                    alert("Ha ocurrido un error al descargar el dibujo")
                })
        } catch (err) {
            console.log(err)
        }
    }


    const createDraw = async (retry?: boolean) => {
        try {

            setLoadingDraw(true)
            setSelectedDraw(null)
            setSelectedEvent(null)
            videoRef.current.pause()

            const time = videoRef?.current?.currentTime()

            const video = videoRef?.current?.videoRef.current
            const canvas = document.createElement('canvas');

            canvas.width = videoRef?.current?.videoWidth;
            canvas.height = videoRef?.current?.videoHeight;

            const ctx = canvas.getContext('2d');
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            const thumbnailBase64 = canvas.toDataURL('image/png');

            const isValid = await isBase64UrlImage(thumbnailBase64)
            if (!thumbnailBase64 || !isValid) {
                if (retry) {
                    setLoadingDraw(false)
                    return alert("Ha ocurrido un error al generar el dibujo")
                }
                setTimeout(() => {
                    createDraw(true)
                }, 250)

                return null
            }

            const drawId = v4()
            const thumbnail = `users/${userData.id}/videoExercises/${id}/draws/base-${drawId}.png`
            const image = await uploadString(ref(storage, thumbnail), thumbnailBase64, "data_url").catch(err => null)

            if (image) {
                setDoc(doc(db, `users/${userData.id}/videoExercises/${id}/draws/${drawId}`), {
                    id: drawId,
                    data: null,
                    thumbnail: "",
                    base: thumbnail,
                    createdAt: serverTimestamp(),
                    updatedAt: serverTimestamp(),
                }).then(res => {
                    updateDoc(doc(db, `users/${userData.id}/videoExercises/${id}`), {
                        draws: arrayUnion({
                            id: drawId,
                            uri: "",
                            time: time,
                            start: time,
                            end: time,
                            duration: 5,
                            flexible: false,
                            movable: false,
                        })
                    })
                        .then(res => {
                            setSelectedDraw(drawId)
                            selectDraw({
                                id: drawId,
                                uri: "",
                                time: time,
                                start: time,
                                end: time,
                                duration: 5,
                                flexible: false,
                                movable: false,
                            })
                            setLoadingDraw(false)
                        })
                        .catch(err => {
                            console.log(err)
                            alert("Ha ocurrido un error al guardar el dibujo")
                            videoRef.current.pause()
                            setLoadingDraw(false)
                        })
                }).catch(err => {
                    console.log(err)
                    alert("Ha ocurrido un error al guardar el dibujo")
                    videoRef.current.pause()
                    setLoadingDraw(false)
                })
            } else {
                alert("Ha ocurrido un error al guardar el dibujo")
                return
            }
        } catch (err) {
            console.log(err)
            alert("Ha ocurrido un error al guardar el dibujo")
            videoRef.current.pause()
            setLoadingDraw(false)
        }

    }

    const deleteDraw = async () => {

        videoRef.current.pause()

        const p = confirm("¿Estás seguro de que quieres eliminar este dibujo? Esta acción no se puede deshacer")

        if (p) {
            await updateDoc(doc(db, `users/${userData.id}/videoExercises/${id}`), {
                draws: data?.draws?.filter(x => x.id !== selectedDraw)
            }).then(res => {

            }).catch(err => {
                alert("Ha ocurrido un error al guardar el dibujo")
            })

            await deleteObject(ref(storage, `users/${userData.id}/videoExercises/${id}/draws/${selectedDraw}.png`)).catch(err => null)
            await deleteObject(ref(storage, `users/${userData.id}/videoExercises/${id}/draws/base-${selectedDraw}.png`)).catch(err => null)
            await deleteObject(ref(storage, `users/${userData.id}/videoExercises/${id}/draws/store-${selectedDraw}.json`)).catch(err => null)

            await deleteDoc(doc(db, `users/${userData.id}/videoExercises/${id}/draws/${selectedDraw}`)).then(res => {

            }).catch(err => {
                alert("Ha ocurrido un error al eliminar el dibujo")
            })

            setSelectedEvent(undefined)
            setSelectedDraw(undefined)
            alert("Dibujo eliminado correctamente")
        }
    }

    const addEvent = (event) => {
        const time = videoRef?.current?.currentTime()
        const duration = videoRef?.current?.duration()
        updateDoc(doc(db, `users/${userData.id}/videoExercises/${id}`), {
            events: arrayUnion({
                id: v4(),
                eventId: event.eventId,
                start: clamp(time - event.timeBefore, 0, duration),
                end: clamp(time + event.timeAfter, 0, duration),
                name: event.name || "",
                abr: event.abr || "",
                color: event.color,
            })
        }).catch(err => {
            alert("Ha ocurrido un error al guardar el evento")
            videoRef?.current?.pause()
        })
    }

    const getDrawData = async (draw) => {
        const uri = `users/${userData.id}/videoExercises/${id}/draws/store-${draw}.json`
        const docRef = StorageRef(storage, uri)
        getDownloadURL(docRef).then(res => {
            fetch(res).then(res => res.json()).then(res => {
                setValue("data", res)
            }).catch(err => {
                console.log(err)
                alert("Ha ocurrido un error al cargar el dibujo")
            })
        }).catch(err => {
            setValue("data", undefined)
            null
        })
    }

    const updateDraw = async (ev) => {
        setValue("data", ev.snap)

        const drawId = selectedDraw
        const uri = `users/${userData.id}/videoExercises/${id}/draws/${drawId}.png`
        const uristore = `users/${userData.id}/videoExercises/${id}/draws/store-${drawId}.json`

        const res = await uploadBytes(ref(storage, uri), ev.svg)
        const store = await uploadString(ref(storage, uristore), JSON.stringify(ev.snap)).catch(err => console.log(err))

        await updateDoc(doc(db, `users/${userData.id}/videoExercises/${id}/draws/${drawId}`), {
            thumbnail: uri,
            updatedAt: serverTimestamp(),
        }).then(res => {
            alert("Ejercicio guardado correctamente")
        }).catch(err => {
            console.log(err)
            alert("Ha ocurrido un error al guardar el ejercicio")
        })

        await updateDoc(doc(db, `users/${userData.id}/videoExercises/${id}`), {
            draws: (data?.draws || [])?.map(x => {
                if (x.id === drawId) return { ...x, uri: uri }
                return x
            })
        })

        // uploadBytes(ref(storage, uri), ev.svg)
        //     .then(async res => {



        //     }).catch(err => {
        //         console.log(err)
        //         alert("Ha ocurrido un error al guardar la pizarra")
        //     })
        setViewBoard(false)

    }

    const selectDraw = async (draw) => {
        videoRef.current.pause()
        if (draw) {
            videoRef.current.setCurrentTime(draw.start)

            const drawRef = doc(db, `users/${userData.id}/videoExercises/${id}/draws/${draw.id}`)
            const dataDraw = await getDoc(drawRef)
                .then(res => res.data())
                .catch(err => null)

            const uri = `users/${userData.id}/videoExercises/${id}/draws/store-${draw.id}.json`

            const docRef = StorageRef(storage, uri)
            const drawDataStore = await getDownloadURL(docRef).then(async res => {
                return fetch(res).then(res => res.json()).then(res => {
                    return res
                }).catch(err => {
                    console.log(err)
                    return undefined
                })
            }).catch(err => {
                // setValue("data", undefined)
                return undefined
            })


            if (!dataDraw) {
                alert("Ha ocurrido un error al cargar el dibujo")
                return
            }
            // await getDrawData(draw.id).catch(err => null)
            // setValue("data", dataDraw?.data ? dataDraw?.data : undefined)

            const base64 = await getDownloadURL(StorageRef(storage, dataDraw.thumbnail || dataDraw.base)).then(res => {
                return fetch(res).then(res => res.blob()).then(blob => {
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(blob);
                        reader.onloadend = () => {
                            resolve(reader.result);
                        };
                    });
                })
            }).catch(err => null)


            setViewBoard({
                id: draw?.id,
                data: drawDataStore,
                thumbnail: base64,
                height: data?.height,
                width: data?.width
            })

        } else {
            alert("Ha ocurrido un error al cargar el dibujo")
        }
    }

    const timelineValues = useMemo(() => {
        return ({
            // scaleWidth: clamp(Math.round(150 * ((data?.duration + 10) / 200)), 150, 500),
            // scale: clamp(Math.round(10 * ((data?.duration + 10) / 200)), 2, 100),
            // startLeft: 20,
            scaleWidth: 250,
            scale: 10 * Math.abs(zoom),
            startLeft: 20,
        })
    }, [zoom])


    const groupedEvents = useMemo(() => {
        if (!data?.events) return []
        const grouped = groupBy(data?.events, "eventId")
        const final = Object.keys(grouped).map(x => ({ id: x, actions: grouped[x].map(x => ({ ...x, effectId: `${x.id}-${x.color}` })) }))

        return [{ id: "draws", actions: data?.draws || [] }, ...final, { id: "video", actions: [{ id: "video", start: 0, end: data?.duration || 0, name: "Video", color: "#151515", flexible: false, movable: false }] }]
    }, [data])



    const {
        getValues,
        setValue
    } = useForm({
        mode: "onChange",
        defaultValues: { data: null }
    });


    if (isLoading || !loaded) return (
        <View style={{ flex: 1, minHeight: 400, alignItems: "center", justifyContent: "center" }}>
            <ActivityIndicator />
        </View>
    )


    return (
        <>
            {rendering ?
                <Modal>
                    <View style={{ gap: 10, alignItems: "center", padding: 15 }}>
                        <StyledText style={{ textAlign: "center" }}>Generando video, una vez terminado se descargará automaticamene a tu equipo</StyledText>
                        {/* <video ref={videoPreviewRef} autoPlay loop muted style={{ width: 200, height: 200, borderRadius: 5, boxShadow: "0px 2px 5px rgba(0,0,0,0.1)" }}/> */}
                        <View style={{ overflow: "hidden", width: "100%", backgroundColor: "#f3f3f3", height: 15, borderRadius: 5, position: "relative" }}>
                            <View ref={progressRef} style={{ position: "absolute", transition: "all .5s", top: 0, left: 0, height: 15, borderRadius: 5, backgroundColor: "red" }} />
                        </View>
                        <Button
                            height={35}
                            label="Cancelar"
                            onPress={async () => {
                                const ffmpeg = ffmpegRef.current;
                                try {
                                    ffmpeg.terminate()
                                    setRendering(false);
                                    await ffmpeg.load();

                                } catch (err) {
                                    console.log(err)
                                }
                            }}
                            icon={undefined}
                            color={undefined}
                            children={undefined}
                            textColor={undefined}
                            title={undefined}
                        />
                    </View>
                </Modal>
                : null
            }
            {viewBoard ?
                <Modal modalContainerStyle={{ height: "100%", width: "100%" }} padding={0} margin={0}>
                    <View style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: viewBoard ? 100 : -1 }}>
                        <VideoBoard
                            closeCallback={() => setViewBoard(false)}
                            saveCallback={updateDraw}
                            initialData={get(viewBoard, "data", undefined)}
                            element={viewBoard}
                            id={selectedDraw}
                            style={{ flex: 1 }}
                        />

                    </View>
                </Modal>
                :
                null
            }

            {eventMenu ?
                <Modal onClickOutside={() => setEventMenu(false)} visible={eventMenu} onClose={() => setEventMenu(false)} style={{ position: "absolute", top: 50, right: 10, zIndex: 100 }}>
                    <View style={{ padding: 10, backgroundColor: "white", borderRadius: 4, maxWidth: 300, width: "100%" }}>
                        <StyledText style={{ fontSize: 16, fontFamily: "TitleWide", marginBottom: 10 }}>Botoneras</StyledText>
                        {events.length === 0 ?
                            <View style={{ paddingVertical: 50, alignItems: "center", justifyContent: "center" }}>
                                <StyledText style={{ fontSize: 12, fontFamily: "TitleWide", marginBottom: 10 }}>No tienes botones creados</StyledText>
                            </View>
                            :
                            events?.map((ev, i) => (
                                <Button
                                    key={i}
                                    style={{ padding: 10, borderRadius: 4, marginBottom: 10, boxShadow: "0px 2px 5px rgba(0,0,0,0.1)" }}
                                    height={35}
                                    label={ev.name}
                                    onPress={() => {
                                        setSetSelected(ev)
                                        setEventMenu(false)
                                    }}
                                    round={5}
                                    color={"white"}
                                />
                            ))}
                    </View>
                </Modal>
                : null
            }

            <View style={{ backgroundColor: "rgba(0,0,0,.96)", flex: 1 }}>
                <View style={{ flex: 3, zIndex: 10, minHeight: 180 }}>
                    <View style={{ position: "absolute", top: 10, left: 10, zIndex: 100, gap: 5 }}>
                        <Button
                            style={{ padding: 10, borderRadius: 4 }}
                            width={"35px"}
                            height={35}
                            paddingHorizontal={0}
                            onPress={() => navigation.goBack()}
                            round={5}
                            icon="arrow-back"
                            title="Volver Atrás"
                        />
                        <Button
                            style={{ padding: 10, borderRadius: 4 }}
                            width={"35px"}
                            height={35}
                            paddingHorizontal={0}
                            onPress={() => setEventMenu(!eventMenu)}
                            round={5}
                            color={"white"}
                            icon="stopwatch"
                            title="Seleccionar Botonera"
                        />
                        <Button
                            disabled={loadingDraw || videoRef?.current?.currentTime() === 0 || selectedDraw}
                            loading={loadingDraw}
                            style={{ padding: 10, borderRadius: 4 }}
                            width={"35px"}
                            height={35}
                            paddingHorizontal={0}
                            onPress={() => createDraw(false)}
                            round={5}
                            color={"white"}
                            icon="brush"
                            title="Añadir Dibujo en el segundo actual"
                        />

                        <Button
                            style={{ padding: 10, borderRadius: 4 }}
                            width={"35px"}
                            height={35}
                            paddingHorizontal={0}
                            disabled={data?.draws?.length === 0 && data?.events?.length === 0}
                            // onPress={() => exportEvents(data.events, "resumen-de-eventos")}
                            // onPress={() => transcode(0, 5)}
                            onPress={() => {
                                if (data?.events?.length === 0) {
                                    generateVideo([{ start: 0, end: videoRef?.current?.duration() }], data.draws, data?.width, data?.height, "resumen-de-eventos")
                                } else {
                                    generateVideo(data.events, data.draws, data?.width, data?.height, "resumen-de-eventos")
                                }
                            }}
                            round={5}
                            color={"white"}
                            icon="download"
                            title="Renderizar Eventos"
                        />

                    </View>
                    {setSelected ?
                        <View
                            style={{ zIndex: 100, position: "absolute", top: 0, bottom: 0, right: 0, backgroundColor: "rgba(0,0,0,.2)" }}
                        >
                            <ScrollView
                                showsVerticalScrollIndicator={false}
                                contentContainerStyle={{ padding: 10, zIndex: 100, gap: 5, alignItems: "flex-end", justifyContent: "center" }}
                                style={{ flex: 1 }}
                            >
                                {setSelected?.events?.map((ev, i) => (
                                    <Button
                                        height={35}
                                        paddingHorizontal={10}
                                        round={5}
                                        key={i}
                                        color={ev.color}
                                        label={ev.name}
                                        onPress={() => addEvent(ev)}
                                        icon={undefined}
                                        children={undefined}
                                        textColor={undefined} />
                                ))}
                            </ScrollView>

                        </View>
                        :
                        null
                    }
                    {selectedDraw ? <ImageOverlay videoId={id} drawId={selectedDraw} /> : null}
                    <VideoViewer timelineState={timelineState} ref={videoRef} uri={data?.media} draws={data?.draws || []} style={{ height: "100%", objectFit: "contain" }} />

                </View>

                <TimelinePlayer
                    selectedDraw={selectedDraw}
                    deleteDrawCallback={deleteDraw}
                    editDrawCallback={() => selectDraw(data?.draws.find(x => x.id === selectedDraw))}
                    downloadDrawCallback={() => {
                        const draw = data?.draws.find(x => x.id === selectedDraw)
                        if (draw) {
                            downloadDraw(draw)
                        }
                    }}
                    deleteCallback={() => {
                        videoRef.current.pause()
                        const p = confirm("¿Estás seguro de que quieres eliminar este dibujo? Esta acción no se puede deshacer")
                        if (p) {
                            updateDoc(doc(db, `users/${userData.id}/videoExercises/${id}`), {
                                events: data?.events?.filter(x => x.id !== selectedEvent)
                            }).then(res => {
                                setSelectedEvent(null)
                                setSelectedDraw(null)
                            }).catch(err => {
                                alert("Ha ocurrido un error al guardar el ejercicio")
                            })
                        }
                    }}
                    renderCallback={() => {
                        const ev = data.events?.find(x => x.id === selectedEvent)
                        if (ev) {
                            generateVideo([ev], data.draws, data?.width, data?.height, ev?.name)
                        } else {
                            alert("No se ha encontrado el evento que exportar")
                        }
                    }}
                    moveCallback={(time, selected) => {
                        if (selected) {
                            setSelectedDraw(null)
                        }
                    }}
                    selectedEvent={selectedEvent}
                    timelineValues={timelineValues}
                    timelineState={timelineState}
                    autoScrollWhenPlay={autoScrollWhenPlay}
                    videoRef={videoRef}
                />

                <View style={{ flexDirection: "row", flex: 1, minHeight: 250 }}>
                    <View style={{ width: "20%", maxWidth: 300, height: "100%" }}>
                        <View style={{ height: 40, gap: 5, alignItems: "center", justifyContent: "space-between", paddingHorizontal: 10, flexDirection: "row" }}>
                            <Ionicons name="remove" size={18} color="white" />
                            <input className='zoom' style={{ width: "100%" }} value={zoom} type="range" min={-5} max={-.1} step={.1} onChange={(ev) => setZoom(parseFloat(ev.target.value))} />
                            <Ionicons name="add" size={18} color="white" />
                        </View>
                        <div

                            // showsVerticalScrollIndicator={false}
                            ref={scrollViewRef}
                            // scrollEventThrottle={16}
                            className='timeline-scroll'
                            style={{ borderTopColor: "rgba(256,256,256,.2)", borderTopWidth: 1, overflow: "auto" }}
                            onScroll={(e) => {
                                const target = e.target as HTMLDivElement;
                                timelineState.current.setScrollTop(target.scrollTop);
                            }}
                        // onScroll={(e: { nativeEvent: { contentOffset: { y: any; }; }; }) => {
                        //     if(scrolling.current === "right") return
                        //     const y = e.nativeEvent.contentOffset.y;
                        //     timelineState?.current?.setScrollTop(y);
                        // }}
                        >
                            {groupedEvents.map((item, index) => {
                                return (
                                    <View style={{ height: 40 }} key={item.id}>
                                        <View style={{ height: "100%", paddingLeft: 10, flexDirection: "row", alignItems: "center", justifyContent: "space-between", borderBottomWidth: 1, borderColor: "rgba(256,256,256,.2)", }}>

                                            <StyledText numberOfLines={1} style={{ color: "white" }}>{`${item.id === "draws" ? "Dibujos" : item.actions[0]?.name || ""}`}</StyledText>
                                            {(index >= 1 && item.id !== "video") ? <Button
                                                icon="download"
                                                iconSize={12}
                                                paddingHorizontal={5}
                                                color="black"
                                                width={"25px"}
                                                height={20}
                                                round={2}
                                                title="Renderizar Eventos"
                                                onPress={() => {
                                                    generateVideo(item.actions, data.draws, data?.width, data?.height, item.id)
                                                }}
                                            /> : null}
                                        </View>
                                    </View>
                                );
                            })}
                        </div>
                    </View>
                    <Timeline
                        style={{ height: "100%", width: "100%" }}
                        editorData={groupedEvents}
                        effects={undefined}
                        onCursorDrag={(ev) => videoRef?.current?.setCurrentTime(ev)}
                        rowHeight={40}
                        onCursorDragStart={() => videoRef?.current?.pause()}
                        autoScroll={true}
                        ref={timelineState}
                        scale={timelineValues.scale}
                        startLeft={timelineValues.startLeft}
                        scaleWidth={timelineValues.scaleWidth}
                        scaleSplitCount={10}
                        onChange={(ev) => {
                            updateDoc(doc(db, `users/${userData.id}/videoExercises/${id}`), {
                                events: flatMap(ev.filter(x => x.id !== "draws" && x.id !== "video"), x => x.actions)
                            }).then(res => {
                                // console.log("EVENTO AÑADIDO")
                            }).catch(err => {
                                alert("Ha ocurrido un error al guardar el ejercicio")
                                videoRef.current.pause()
                            })
                        }}
                        onClickAction={(e, { action, row, time }) => {
                            if (row.id === "video") {
                                return
                            }
                            videoRef.current?.setCurrentTime(action.start)
                            timelineState?.current?.setTime(action.start)
                            if (row.id === "draws") {
                                setSelectedEvent(null)
                                if (action.id === selectedDraw) {
                                    setSelectedDraw(null)
                                } else {
                                    setSelectedDraw(action.id)
                                }
                            } else {
                                setSelectedDraw(null)
                                if (action.id === selectedEvent) {
                                    setSelectedEvent(null)
                                } else {
                                    setSelectedEvent(action.id)
                                }
                            }
                        }}
                        getScaleRender={(scale) => <CustomScale scale={scale} />}
                        onClickTimeArea={(ev) => {
                            videoRef?.current?.pause()
                            videoRef?.current?.setCurrentTime(ev)
                        }}
                        onActionMoving={(ev) => {
                            videoRef?.current?.setCurrentTime(ev.start)
                            timelineState?.current?.setTime(ev.start)
                        }}
                        onActionResizing={(ev) => {
                            if (ev.dir === "left") {
                                videoRef?.current?.setCurrentTime(ev.start)
                                timelineState?.current?.setTime(ev.start)
                            } else {
                                videoRef?.current?.setCurrentTime(ev.end)
                                timelineState?.current?.setTime(ev.end)
                            }
                        }}
                        // onScroll={({ scrollTop }) => {
                        //     scrollViewRef?.current?.scrollTo({ y: scrollTop })
                        // }}
                        onScroll={({ scrollTop }) => {
                            scrollViewRef.current.scrollTop = scrollTop;
                            // scrolling.current = "right"
                            // scrollViewRef?.current?.scrollTo({ y: scrollTop })
                            // scrolling.current = null
                        }}
                        getActionRender={(action, row) => {
                            if (row.id === "draws") {
                                return <CustomRender1 action={action} row={row} selected={action.id === selectedDraw} />
                            } else {
                                return <CustomRender0 action={action} row={row} selected={action.id === selectedEvent} />
                            }
                        }}
                    // onClickAction={(ev) => console.log(ev)}
                    // onChange={ev => console.log(ev)}
                    />

                </View>
            </View >
        </>
    );
}

const CustomScale = (props: { scale: number }) => {
    const { scale } = props;
    const min = parseInt(scale / 60 + '');
    const second = (scale % 60 + '').padStart(2, '0');
    return <>{`${min}:${second}`}</>
}

export default VideoExercise;




export const CustomRender1: FC<{ action: TimelineAction; row: TimelineRow, selected: boolean }> =
    ({ action, row, selected }) => {
        return (
            <View style={{ overflow: "hidden", width: 40, height: 40, marginLeft: -20, alignItems: "center", justifyContent: "center", cursor: "pointer" }}>
                <Ionicons name="brush" size={20} color={selected ? "orange" : "white"} />
            </View>
        );
    };

export const CustomRender0: FC<{ action: TimelineAction; row: TimelineRow, selected: boolean }> =
    ({ action, row, selected }) => {
        const textColor = useMemo(() => chroma(action.color).luminance() > 0.5 ? "black" : "white", [action.color])
        return (
            <View style={{ overflow: "hidden", marginHorizontal: 8, marginVertical: 1, flex: 1, height: 38, borderWidth: 2, borderColor: selected ? "orange" : "transparent", alignItems: "center", justifyContent: "center", backgroundColor: action.color }}>
                <StyledText style={{ pointerEvents: "none", fontSize: 14, color: textColor }}>{`${secondsToHMS(action.end - action.start)}`}</StyledText>
            </View>
        );
    };


const ImageOverlay = ({ videoId, drawId }) => {
    const { userData } = useContext(AuthContext)
    const db = getFirestore()
    const storage = getStorage()
    const [signed, setSigned] = useState(null)
    const { data } = useFirestoreDocument(["image", videoId, drawId], doc(db, `users/${userData?.id}/videoExercises/${videoId}/draws/${drawId}`), {
        subscribe: true
    }, {
        select: (data) => {
            if (!data) return null
            return {
                ...data.data(),
            }
        }
    })

    useEffect(() => {
        if (data?.thumbnail || data?.base) {
            const gsReference = StorageRef(storage, data?.thumbnail || data?.base);
            getDownloadURL(gsReference).then((url) => {
                setSigned(url)
            }).catch((error) => {
                console.log(error)
            });
        }

    }, [data])

    if (!signed) return null
    return (
        <img src={signed} style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: 10, width: "100%", height: "100%", objectFit: "contain" }} />
    )
}


const VideoViewer = forwardRef(({ uri, style, timelineState }: { uri: string, style: any, timelineState: React.RefObject<TimelineState> }, ref) => {
    const [signed, setSigned] = useState(null)
    const videoRef = useRef(null)
    const intervalRef = useRef(null)

    useEffect(() => {
        const storage = getStorage()
        if (uri) {
            const gsReference = StorageRef(storage, uri);
            getDownloadURL(gsReference).then((url) => {
                setSigned(url)
            }).catch((error) => {
                console.log(error)
            });
        }
    }, [uri])


    useImperativeHandle(ref, () => ({
        duration: () => {
            return videoRef.current.duration
        },
        pause: () => {
            videoRef.current.pause()
            timelineState?.current?.pause()
            clearInterval(intervalRef.current)
            intervalRef.current = null
        },
        play: () => {
            intervalRef.current = setInterval(function () {
                timelineState?.current?.setTime(videoRef.current.currentTime)
            }, 66);
            videoRef.current.play()
            timelineState?.current?.play({ autoEnd: true })
        },
        setCurrentTime: (time) => {
            videoRef.current.currentTime = time
            if (intervalRef.current) {
                clearInterval(intervalRef.current)
                intervalRef.current = null
            }
        },
        playbackRate: (rate) => {
            videoRef.current.playbackRate = rate
        },
        currentTime: () => {
            return videoRef.current.currentTime
        },
        videoRef: videoRef,
        videoWidth: videoRef.current.videoWidth,
        videoHeight: videoRef.current.videoHeight,
        src: signed
    }));

    const handleEnd = (ev) => {
        timelineState?.current?.setTime(0)
        timelineState?.current?.setScrollLeft(0)
        clearInterval(intervalRef.current)
        intervalRef.current = null
    }


    const setupVideoElement = (video) => {
        if (video) {
            videoRef.current = video
            video.addEventListener('ended', handleEnd);
        }
    };

    return (
        <video playsInline={true} webkit-playsinline={"true"} preload="metadata" crossOrigin="anonymous" style={style} ref={setupVideoElement} src={signed} controls={false} />
    )
})

function secondsToHMS(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.round(seconds % 60);

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');

    if (hours > 0) return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    return `${formattedMinutes}:${formattedSeconds}`;
}