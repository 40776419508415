import { FontAwesome, Ionicons, MaterialIcons } from "@expo/vector-icons";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useFirestoreDocument, useFirestoreDocumentData, useFirestoreQuery } from "@react-query-firebase/firestore";
import { getApp } from "firebase/app";
import { arrayUnion, collection, doc, DocumentReference, getDoc, getFirestore, limit, orderBy, query, serverTimestamp, setDoc, updateDoc } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import * as _ from "lodash";
import { clamp, head } from "lodash";
import moment from "moment";
import { get } from "radash";
import React, { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { ActivityIndicator, Dimensions, Pressable, ScrollView, StyleSheet, TouchableOpacity, useWindowDimensions, View } from "react-native";
import * as Animatable from "react-native-animatable";
import * as yup from "yup";
import AdvanceContext from "../../context/AdvanceContext";
import AuthContext from "../../context/AuthContext";
import useLastDebtWarning from "../../hooks/useLastDebtWarning";
import getValidationType from "../../utils/getValidationType";
import swapIcon from "../../utils/swapIcon";
import useYupValidationResolver from "../../utils/useYupValidationResolver";
import DateFancyDisplayer from "../calendarComponents/DateFancyDisplayer";
import ClassModal from "../ClassModal";
import Button from "../common/Button";
import Modal from "../common/Modal";
import SecureImage from "../common/SecureImage";
import StyledText from "../common/StyledText";
import Subform from "../common/Subform";
import ContentViewer from "../ContentViewer";
import CustomFormCV from "../customForms/CustomFormCV";
import CustomFormDeclaracionJurada from "../customForms/CustomFormDeclaracionJurada";
import CustomFormDeclaracionMedica from "../customForms/CustomFormDeclaracionMedica";
import MultiField from "../formComponents/MultiField";
import LinearBarIndicator from "../LinearBarIndicator";
import PaymentsModal from "../PaymentsModal";
import QRScan from "../QRScan";
import CourseFeaturedForums from "../smartComponents/CourseFeaturedForums";

import { useMediaQuery } from "react-responsive";
import fixDate from "../../utils/fixDate";
import generatePdf from "../../utils/generatePdf";
import SelectField from "../formComponents/SelectField";
import TextField from "../formComponents/TextField";

import { useQuery } from "react-query";
import { Course } from "../../types/CourseTypes";
import countries from "../../utils/countries.json";
import { getStatusColor, getStatusName } from "../../utils/getStatus";
import provinces from "../../utils/provinces.json";
import sanitizeObject from "../../utils/sanitizeObject";


var checkRanges = function (start, end) {
	var compareDate = moment();
	var startDate = moment(start).subtract(1, "hour")
	var endDate = moment(end).add(1, "hour")
	var percentage = (compareDate.valueOf() - moment(start).valueOf()) / (moment(end).valueOf() - moment(start).valueOf()) * 100
	const p = {
		isBeforeEnd: compareDate.isBefore(endDate),
		isOngoing: compareDate.isBetween(startDate, endDate),
		percentage: clamp(percentage, 0, 100)
	}
	return p
};


export const CourseContext = React.createContext<undefined | Course>(undefined);


const PathContainer = ({ course }) => {
	const app = getApp();
	const db = getFirestore(app)
	const params = useRoute().params;
	const route = useRoute();
	const navigation = useNavigation();
	const storage = getStorage();
	const { userData } = useContext(AuthContext);
	const { advances, payments } = useContext(AdvanceContext);
	process.env.NODE_ENV === "development" && connectFunctionsEmulator(getFunctions(getApp()), "localhost", 5001);

	const isPhone = useMediaQuery({ query: '(max-width: 1000px)' })
	const isAfterCourseStart = course?.initDate ? moment(course?.initDate?.toDate ? course?.initDate?.toDate() : course?.initDate).toDate() < new Date() : true
	const courseEnded = course?.endDate ? moment(course?.endDate?.toDate ? course?.endDate?.toDate() : course?.endDate).toDate() < new Date() : false

	const [advance, setAdvance] = useState(null);


	const [selectedSection, setSelectedSection] = useState(null);
	const [contentSelected, setContentSelected] = useState(null);
	const [editDataModal, setEditDataModal] = useState(false);
	const [paymentsModal, setPaymentsModal] = useState(false);
	const [calificationModal, setCalificationModal] = useState(false);
	const [daySelected, setDaySelected] = useState(null);
	const [classDetails, setClassDetails] = useState(null);
	const [paymentCompleted, setPaymentCompleted] = useState(false);
	const [paymentError, setPaymentError] = useState(false);
	const [generatingPdf, setGeneratingPdf] = useState(false);
	const [billingModal, setBillingModal] = useState(false);

	const paymentRef = course?.ref ? doc(db, `${course?.ref?.path}/payments/${userData?.uid}`) : null
	const { data: paymentData } = useFirestoreDocumentData(["payment", { id: userData.uid, course: course?.id, tenant: course?.tenantId }], paymentRef, {}, {
		enabled: !!course?.ref,
		onError: e => console.log(e)
	});

	const userPaymentRef = params?.order ? doc(db, `payments/${params?.order}`) : null;

	const { data, isSuccess } = useFirestoreDocumentData(["userPayment", params.order], userPaymentRef, { subscribe: true }, {
		enabled: !!params.order,
		onError: e => console.log(e)
	});

	const tenantRef = course?.tenantId ? doc(db, `tenants/${course?.tenantId}`) : null;
	const { data: tenantData } = useFirestoreDocumentData(["tenants", course.tenantId], tenantRef, {}, {
		enabled: !!course.tenantId,
		onError: e => console.log(e)
	});


	const upToDatePayments = useLastDebtWarning({
		courses: [course],
		payments: payments
	});

	const paymentWarning = !upToDatePayments[course.id]?.upToDate;

	const advancesRef = advance?.ref ? collection(db, `${advance?.ref?.path}/contents`) : null

	const { data: dataAdvances } = useFirestoreQuery(
		["contentsAdvances", course.id],
		advancesRef,
		{ subscribe: false },
		{
			enabled: advance?.ref?.path !== undefined,
			select: (data) => {
				return data.docs.map((doc) => {
					return {
						...doc.data(),
						deadlineId: doc.data().deadlineId ? doc.data().deadlineId : doc.id,
						id: doc.id,
						ref: doc.ref,
					};
				});
			},
			onError: e => console.log(e)
		});

	const attendanceRef = advance?.ref ? collection(db, `${advance?.ref?.path}/attendance`) : null

	const { data: dataAttendance } = useFirestoreQuery(
		["attendance", course.id],
		attendanceRef,
		{ subscribe: false },
		{
			enabled: advance?.ref?.path !== undefined,
			select: (data) => {
				return data.docs.map((doc) => {
					return {
						...doc.data(),
						id: doc.id,
						ref: doc.ref,
					};
				});
			},
			onError: e => console.log("ERROR HERE ", e)
		});



	useEffect(() => {
		if (isSuccess && data && params?.order) {
			if (data && data.status === "PAID") {
				setPaymentCompleted(true)
			}

			if (data.status === "ERROR") {
				setPaymentError(true)
			}
		}
	}, [isSuccess, data, params?.order])

	useEffect(() => {
		if (course) {
			const d = advances.find(a => a.courseRef?.id === course.id);
			if (d) {
				if (d.status === "PENDINGREVIEW" || d.status === "REJECTED" || d.visible === false) {
					navigation.navigate("MainTabNavigator", { screen: "SmartHome" })
				} else {
					setAdvance(d)
				}
			} else {
				navigation.navigate("MainTabNavigator", { screen: "SmartHome" })
			}
		}
	}, [course])

	useEffect(() => {
		getContentByPath()
	}, [route, course])


	const canAccessSection = (section) => {
		// if section initDate is after today return true
		if (isAfterCourseStart) {
			if (section?.startDate) {
				const today = new Date();
				const initDate = section?.startDate?.toDate ? section?.startDate?.toDate() : new Date(section?.startDate);
				if (initDate > today) return false;
				return true
			} else {
				return true;
			}
		} else {
			return false;
		}
	}


	const getContentByPath = () => {
		if (course && params?.path) {
			const path = params?.path?.split("-")
			const pathString = path.reduce((acc, curr, i) => acc + (i === 0 ? `course?.contentDetails[${curr}]` : `?.sections[${curr}]`), "")
			const sectionData = eval(pathString) || []

			if (!canAccessSection(sectionData)) {
				setSelectedSection(null)
				setContentSelected(null)
				navigation.setParams({ path: undefined, content: undefined, material: undefined, resource: undefined });
				return
			}

			setSelectedSection(sectionData)
			setContentSelected(null)
			if (params?.content !== undefined) {
				const contentData = sectionData?.contents[params?.content] || []
				const ref = typeof contentData.ref === "string" ? doc(db, contentData.ref) : contentData.ref
				setContentSelected({ ...contentData, ref })
			}
			if (params?.material !== undefined) {
				const contentData = sectionData?.additionalMaterials[params?.material] || []
				const ref = typeof contentData.ref === "string" ? doc(db, contentData.ref) : contentData.ref
				setContentSelected({ ...contentData, ref })
			}
			if (params?.resource !== undefined) {
				const contentData = sectionData?.resources[params?.resource] || []
				const ref = typeof contentData.ref === "string" ? doc(db, contentData.ref) : contentData.ref
				setContentSelected({ ...contentData, ref })
			}

		} else {
			setSelectedSection(null)
			setContentSelected(null)
		}
	}

	const handleActiveModal = (section, path) => {
		navigation.setParams({ path, content: undefined, material: undefined, resource: undefined });
		history.pushState({ path, content: undefined, material: undefined, resource: undefined }, "")
	};

	const getClasses = (sections, courseId, tenantId) =>
		sections?.reduce(
			(acc, curr) => [
				...acc,
				...(curr?.classes?.map((el) => ({
					...el,
					courseId,
					classId: el.id,
					tenantId,
					start: el.start?.toDate ? el.start?.toDate() : new Date(el.start),
					end: el.end?.toDate ? el.end?.toDate() : new Date(el.end),
					isClass: true
				})) || []),
				...(curr?.sections ? getClasses(curr.sections, courseId, tenantId) : []),
			],
			[]
		);

	// REDUCE LAS TAREAS DE TODOS LOS CURSOS Y LAS AÑADE EL VALOR DEL COURSE ID
	const getContents = (sections, courseId, path) =>
		sections?.reduce(
			(acc, curr, index) => [
				...acc,
				...(curr?.contents.reduce(
					(accContent, currContent, contentIndex) => [
						...accContent,
						...(currContent?.deadlines?.reduce(
							(accDead, currDead) => [
								...accDead,
								{
									...currContent,
									courseId,
									path: path + (path === "" ? "" : "-") + index,
									index: contentIndex,
									title: (currDead.name || "") + " - " + (currContent.title || currContent.name || ""),
									allDay: true,
									start: currDead.start?.toDate ? currDead.start?.toDate() : new Date(currDead.start),
									end: currDead.end?.toDate ? currDead.end?.toDate() : new Date(currDead.end),
									// color: "red",
								},
							], []
						) || []),
					],
					[]
				) || []),
				...(curr?.sections ? getContents(curr.sections, courseId, path ? path + "-" + index : index) : []),
			],
			[]
		);


	const allElements = useMemo(() => [...getContents(course?.contentDetails || [], course.id, ""), ...getClasses(course?.contentDetails || [])], [course?.id])

	const closePaymentSuccess = () => {
		navigation.reset({
			index: 0,
			routes: [{ name: 'MainTabNavigator', params: { screen: 'Course', params: { id: route.params.id } } }],
		});
		setPaymentCompleted(false)
	}



	if (course.videoCourse) {

		return (
			<CourseContext.Provider value={course}>
				{/* BREADCRUMS */}
				<View style={{ marginBottom: 15 }}>
					<StyledText style={{ gap: 5, flexWrap: "wrap", whiteSpace: "normal" }} >
						<TouchableOpacity style={{ height: 40, alignItems: "center", justifyContent: "center" }} onPress={() => navigation.navigate("SmartHome")}>
							<Ionicons name="book-outline" size={28} />
						</TouchableOpacity>
						<View style={{ transform: [{ translateY: -2 }] }} >
							<Ionicons color="black" name="chevron-forward" size={22} />
						</View>
						<Breadcrumb
							onPress={() => handleActiveModal(null, undefined)}
							name={course.name}
							selected={false}
						/>
						{contentSelected &&
							<Fragment>
								<View style={{ transform: [{ translateY: -2 }] }} >
									<Ionicons color="black" name="chevron-forward" size={22} />
								</View>
								<Breadcrumb
									onPress={() => null}
									name={contentSelected?.title}
									selected={true}
								/>
							</Fragment>
						}
					</StyledText>
				</View>

				{contentSelected ?
					<ContentViewer
						content={contentSelected}
						courseId={course?.id}
						tenantId={course?.tenantId}
						course={course}
						teachers={[...(course?.teachers || []), ...(selectedSection?.teachers || [])]}
					/>
					:
					<View style={{ flex: 1, gap: 20 }}>

						<VideoCourse course={course} setContentSelected={setContentSelected} />

						<View style={{ flex: isPhone ? undefined : course?.contentDetails?.length > 0 ? .6 : 1, paddingTop: isPhone ? 20 : 0, paddingLeft: isPhone ? 0 : 15 }}>

							<View style={{ backgroundColor: "white", marginTop: 15, borderRadius: 7, padding: 15 }}>
								<StyledText style={{ fontFamily: "Title", marginBottom: 15, fontSize: 30 }}>
									Información del Curso
								</StyledText>

								{(course?.withDiploma && course?.diploma && advance?.finalGradeStatus === "APPROVED") && !advance?.needValidation ?
									<Button
										disabled={generatingPdf}
										loading={generatingPdf}
										primary={true}
										onPress={async () => {
											setGeneratingPdf(true)
											try {
												if (course.diploma instanceof DocumentReference) {
													generatePdf(
														course.diploma,
														{
															...{ ...(advance || {}), userName: advance.name },
															...course,
															...(course?.extraData || {}),
															...(course?.preinscriptionData || []),
															...(course?.convocatoryData || []),
														},
														db
													).then(res => {
														setGeneratingPdf(false)
													})
												} else {
													alert("No se puede generar el diploma")
												}

											} catch (err) {
												console.log(err)
												setGeneratingPdf(false)
											}
											// const fileRef = ref(storage, course.temporaryCertificate)
											// getDownloadURL(fileRef).then(url => {
											//   window.open(url, "_blank")
											// })
										}}
										label="Diploma"
										height={35}
										round={5}
										style={{ marginTop: 10 }}
									/>
									: null
								}

								{(course?.withAttendingCertificate && isAfterCourseStart && !courseEnded && advance?.finalGradeStatus !== "APPROVED") ?
									<Button
										disabled={generatingPdf}
										loading={generatingPdf}
										onPress={async () => {
											setGeneratingPdf(true)
											try {
												const docRef = course.attendingCertificate instanceof DocumentReference ? course.attendingCertificate : doc(db, course.attendingCertificate);
												generatePdf(docRef, {
													...advance,
													...course,
													...(course?.extraData || {}),
													...(course?.preinscriptionData || []),
													...(course?.convocatoryData || []),
													userName: advance.name
												}, db).then(res => {
													setGeneratingPdf(false)
												})
											} catch (err) {
												console.log(err)
												setGeneratingPdf(false)
											}
										}}
										primary={false}
										label="Certificado Cursando"
										height={35}
										round={5}
										style={{ marginTop: 10 }}
									/>
									: null
								}

								{(course?.withCompletionCertificate && (advance?.finalGradeStatus === "APPROVED" && advance?.needValidation)) ?
									<Button
										disabled={generatingPdf}
										loading={generatingPdf}
										onPress={async () => {
											setGeneratingPdf(true)
											try {
												const docRef = course.completionCertificate instanceof DocumentReference ? course.completionCertificate : doc(db, course.completionCertificate);
												generatePdf(docRef, {
													...advance,
													...course,
													...(course?.extraData || {}),
													...(course?.preinscriptionData || []),
													...(course?.convocatoryData || []),
													userName: advance.name
												}, db).then(res => {
													setGeneratingPdf(false)
												})
											} catch (err) {
												console.log(err)
												setGeneratingPdf(false)
											}
										}}
										primary={false}
										label="Certificado Transitorio"
										height={35}
										round={5}
										style={{ marginTop: 10 }}
									/>
									: null
								}

								{course?.preinscriptionData?.length > 0 ? <Button primary={false} onPress={() => setEditDataModal({ ...advance, course })} label="Datos de Preinscripción" height={35} round={5} style={{ marginTop: 10 }} /> : null}

								{(course?.programBases) ?
									<Button height={35} style={{ marginTop: 10 }} onPress={() => {
										const fileRef = ref(storage, `courses/${course.tenantId}/${course.id}/programBases.pdf`)
										getDownloadURL(fileRef).then(url => {
											window.open(url)
										}).catch(err => {
											console.log(err)
										})
									}} reverse icon="document" label="Bases del Programa" round={5} />
									: null
								}

								{(course?.programInformation) ?
									<Button height={35} style={{ marginTop: 10 }} onPress={() => {
										const fileRef = ref(storage, course.programInformation)
										getDownloadURL(fileRef).then(url => {
											window.open(url)
										}).catch(err => {
											console.log(err)
										})
									}} reverse icon="document" label="Información del Programa" round={5} />
									: null
								}
							</View>
						</View>

						{editDataModal ? <EditDataModal setOpen={setEditDataModal} course={editDataModal} courseId={editDataModal.courseId} preinscriptionData={editDataModal?.course?.preinscriptionData || []} /> : null}

					</View >
				}

			</CourseContext.Provider>
		)

	} else {
		return (

			<CourseContext.Provider value={course}>
				{/* BREADCRUMS */}
				<View style={{ marginBottom: 15 }}>
					<StyledText style={{ gap: 5, flexWrap: "wrap", whiteSpace: "normal" }} >
						<TouchableOpacity style={{ height: 40, alignItems: "center", justifyContent: "center" }} onPress={() => navigation.navigate("SmartHome")}>
							<Ionicons name="book-outline" size={28} />
						</TouchableOpacity>
						<View style={{ transform: [{ translateY: -2 }] }} >
							<Ionicons color="black" name="chevron-forward" size={22} />
						</View>
						<Breadcrumb
							onPress={() => handleActiveModal(null, undefined)}
							name={course.name}
							selected={false}
						/>

						{params?.path &&
							params?.path?.split("-").map((cur, i, arr) => (i === 0 ?
								<Fragment key={i}>
									<View style={{ transform: [{ translateY: -2 }] }} >
										<Ionicons color="black" name="chevron-forward" size={22} />
									</View>
									<Breadcrumb
										onPress={() => handleActiveModal(course?.contentDetails[cur], params?.path?.split("-").slice(0, 1).join())}
										name={get(course, `contentDetails[${cur}].name`, "")}
										selected={(!contentSelected && arr.length === i + 1)}
									/>
								</Fragment>
								:
								<Fragment key={i}>
									<View style={{ transform: [{ translateY: -2 }] }} >
										<Ionicons color="black" name="chevron-forward" size={22} />
									</View>
									<Breadcrumb
										onPress={() => handleActiveModal(course?.contentDetails[cur], params?.path)}
										name={get(course, `contentDetails[${arr[0]}].sections[${cur}].name`, "")}
										selected={(!contentSelected && arr.length === i + 1)}
									/>
								</Fragment>
							))
						}
						{contentSelected &&
							<Fragment>
								<View style={{ transform: [{ translateY: -2 }] }} >
									<Ionicons color="black" name="chevron-forward" size={22} />
								</View>
								<Breadcrumb
									onPress={() => null}
									name={contentSelected?.title}
									selected={true}
								/>
							</Fragment>
						}
					</StyledText>
				</View>

				{contentSelected ?
					<ContentViewer
						content={contentSelected}
						courseId={course?.id}
						tenantId={course?.tenantId}
						course={course}
						teachers={[...(course?.teachers || []), ...(selectedSection?.teachers || [])]}
					/>
					:
					selectedSection ?
						<SectionsComponent
							dataAdvances={dataAdvances}
							dataAttendance={dataAttendance}
							courseName={course?.name}
							courseTag={course?.tag}
							courseId={course.id}
							courseRef={course.ref}
							tenantId={course.tenantId}
							advanceRef={advance.ref}
							section={selectedSection}
							setContentSelected={setContentSelected}
							handleActiveModal={handleActiveModal}
							path={params?.path || ""}
						/>
						:
						<View style={{ flexDirection: isPhone ? "column" : "row", width: "100%" }}>
							{course?.contentDetails.length > 0 ? <View style={{ flex: isPhone ? null : 1 }}>
								<View style={{ flexDirection: "row", flexWrap: "wrap", width: "100%", }}>
									{course?.contentDetails ?
										course?.contentDetails?.length === 0 ?
											<View style={{ padding: 15, flex: isPhone ? null : 1, width: "100%", height: 300, borderRadius: 7, backgroundColor: "white", alignItems: "center", justifyContent: "center" }}>
												<StyledText style={{ fontFamily: "DemiBold", textAlign: "center", fontSize: 16, color: "black", opacity: .5 }}>Este curso no dispone de contenidos</StyledText>
											</View>
											:
											course?.contentDetails?.map((section, i, arr) => {
												const { name } = section;
												const sectionGrade = advance?.grades?.find((grade) => grade.id === section.id);
												return (
													<Animatable.View
														animation={"fadeInUp"}
														duration={300}
														delay={i * 100}
														// entering={FadeIn}
														style={{
															width: isPhone ? "100%" : "50%",
															// paddingLeft: (i === 0 || i % 2 === 0) ? 0 : 10,
															paddingRight: ((i === (arr.length - 1)) && (i % 2) === 0) ? 10 : (i === arr.length - 1 || i % 2) ? 0 : 10,
															paddingBottom: 10,
														}}
														key={i}
													>
														<TouchableOpacity
															accessibilityRole="button"
															disabled={!canAccessSection(section)}
															onPress={() => handleActiveModal(section, JSON.stringify(i))}
															style={[styles.shadow, {
																opacity: canAccessSection(section) ? 1 : 0.4,
																backgroundColor: "white",
																borderRadius: 7,
																flex: 1,
															}]}
														>
															{sectionGrade && <View style={{ backgroundColor: "#0b1831", borderRadius: 5, paddingHorizontal: 15, paddingVertical: 10, zIndex: 10, position: "absolute", top: 10, left: 10 }}>
																<StyledText style={{ color: "white", fontWeight: "bold" }}>{getStatusName(sectionGrade?.status)}</StyledText>
															</View>}
															<View style={{ position: "relative", height: 150, width: "100%" }}>
																<SecureImage
																	accessibilityRole="none"
																	key={i}
																	style={{ borderRadius: 7, flex: 1 }}
																	placeholder="https://brandemia.org/sites/default/files/inline/images/03-rfef-imagenes-brandemia-blog_0.jpg"
																	uri={section.image}
																/>

															</View>
															<View accessibilityRole="none" style={{ padding: 15 }}>
																<StyledText numberOfLines={3} style={{ marginRight: 10, fontFamily: "Bold", fontSize: 20 }}>
																	{name}
																</StyledText>
																{section.startDate && section.endDate ?
																	<StyledText style={{ fontSize: 16, color: "black", opacity: .5 }}>
																		Inicio: {moment(section.startDate?.toDate ? section?.startDate?.toDate() : section?.startDate).format("DD-MM-YYYY")} -  Final: {moment(section.endDate?.toDate ? section?.endDate?.toDate() : section?.endDate).format("DD-MM-YYYY")}
																	</StyledText>
																	: null
																}
															</View>
														</TouchableOpacity>
													</Animatable.View>
												)
											})
										: null
									}

								</View>
							</View> : null}
							<View style={{ flex: isPhone ? undefined : course?.contentDetails?.length > 0 ? .6 : 1, paddingTop: isPhone ? 20 : 0, paddingLeft: isPhone ? 0 : 15 }}>
								{allElements?.length > 0 ?
									<AgendaComponent
										isAfterCourseStart={isAfterCourseStart}
										contents={allElements}
										title="Agenda"
										color="#0b1831"
										onPress={(ev) => {
											if (ev.isClass) {
												setClassDetails(ev)
											} else {
												// setContentSelected(ev)
												navigation.setParams({ path: ev.path, content: ev.index, material: undefined, resource: undefined });
												history.pushState({ path: ev.path, content: ev.index, material: undefined, resource: undefined }, `?path=${ev.path}&content=${ev.index}`);
											}
										}}
									/>
									: null
								}

								<TeachersComponent courseName={course?.name} title="Responsables del Curso" teachers={course?.teachers?.map(el => el.path) || []} courseRef={course.ref} />

								{course?.hasForum && <CourseFeaturedForums forumRef={course.ref} id={course.id} />}
								{/* {course?.hasForum && <CourseFormumComponent course={course} />} */}

								<View style={{ backgroundColor: "white", marginTop: 15, borderRadius: 7, padding: 15 }}>
									<StyledText style={{ fontFamily: "Title", marginBottom: 15, fontSize: 30 }}>
										Información del Curso
									</StyledText>

									{(course?.withDiploma && course?.diploma && advance?.finalGradeStatus === "APPROVED") && !advance?.needValidation ?
										<Button
											disabled={generatingPdf}
											loading={generatingPdf}
											primary={true}
											onPress={async () => {
												setGeneratingPdf(true)
												try {
													if (course.diploma instanceof DocumentReference) {
														generatePdf(
															course.diploma,
															{
																...{ ...(advance || {}), userName: advance.name },
																...course,
																...(course?.extraData || {}),
																...(course?.preinscriptionData || []),
																...(course?.convocatoryData || []),
															},
															db
														).then(res => {
															setGeneratingPdf(false)
														})
													} else {
														alert("No se puede generar el diploma")
													}

												} catch (err) {
													console.log(err)
													setGeneratingPdf(false)
												}
												// const fileRef = ref(storage, course.temporaryCertificate)
												// getDownloadURL(fileRef).then(url => {
												//   window.open(url, "_blank")
												// })
											}}
											label="Diploma"
											height={35}
											round={5}
											style={{ marginTop: 10 }}
										/>
										: null
									}

									<Button primary={false} onPress={() => setCalificationModal(true)} label="Calificaciones y Asistencia" height={35} round={5} style={{ marginTop: 10 }} />


									{(course?.withAttendingCertificate && isAfterCourseStart && !courseEnded && advance?.finalGradeStatus !== "APPROVED") ?
										<Button
											disabled={generatingPdf}
											loading={generatingPdf}
											onPress={async () => {
												setGeneratingPdf(true)
												try {
													const docRef = course.attendingCertificate instanceof DocumentReference ? course.attendingCertificate : doc(db, course.attendingCertificate);
													generatePdf(docRef, {
														...advance,
														...course,
														...(course?.extraData || {}),
														...(course?.preinscriptionData || []),
														...(course?.convocatoryData || []),
														userName: advance.name
													}, db).then(res => {
														setGeneratingPdf(false)
													})
												} catch (err) {
													console.log(err)
													setGeneratingPdf(false)
												}
											}}
											primary={false}
											label="Certificado Cursando"
											height={35}
											round={5}
											style={{ marginTop: 10 }}
										/>
										: null
									}

									{(course?.withCompletionCertificate && (advance?.finalGradeStatus === "APPROVED" && advance?.needValidation)) ?
										<Button
											disabled={generatingPdf}
											loading={generatingPdf}
											onPress={async () => {
												setGeneratingPdf(true)
												try {
													const docRef = course.completionCertificate instanceof DocumentReference ? course.completionCertificate : doc(db, course.completionCertificate);
													generatePdf(docRef, {
														...advance,
														...course,
														...(course?.extraData || {}),
														...(course?.preinscriptionData || []),
														...(course?.convocatoryData || []),
														userName: advance.name
													}, db).then(res => {
														setGeneratingPdf(false)
													})
												} catch (err) {
													console.log(err)
													setGeneratingPdf(false)
												}
											}}
											primary={false}
											label="Certificado Transitorio"
											height={35}
											round={5}
											style={{ marginTop: 10 }}
										/>
										: null
									}

									{course.preinscriptionFee || course.programFee || course?.courseTax
										? <View>
											{
												paymentWarning &&
												<View style={{ position: "absolute", top: 5, right: -10, alignItems: "center", justifyContent: "center", width: 25, height: 25, borderRadius: 18, backgroundColor: "orange", zIndex: 10 }}>
													<Ionicons name="alert" color="white" size={15} />
												</View>
											}
											<Button primary={false} onPress={() => setPaymentsModal(course)} label="Gestión de Pagos" height={35} round={5} style={{ marginTop: 10 }} />
										</View>
										: null
									}
									{tenantData?.useBilling ? <Button primary={false} onPress={() => setBillingModal(true)} label="Datos de Facturación" height={35} round={5} style={{ marginTop: 10 }} /> : null}

									{course?.preinscriptionData?.length > 0 ? <Button primary={false} onPress={() => setEditDataModal({ ...advance, course })} label="Datos de Preinscripción" height={35} round={5} style={{ marginTop: 10 }} /> : null}

									{(course?.programBases) ?
										<Button height={35} style={{ marginTop: 10 }} onPress={() => {
											const fileRef = ref(storage, `courses/${course.tenantId}/${course.id}/programBases.pdf`)
											getDownloadURL(fileRef).then(url => {
												window.open(url)
											}).catch(err => {
												console.log(err)
											})
										}} reverse icon="document" label="Bases del Programa" round={5} />
										: null
									}

									{(course?.programInformation) ?
										<Button height={35} style={{ marginTop: 10 }} onPress={() => {
											const fileRef = ref(storage, course.programInformation)
											getDownloadURL(fileRef).then(url => {
												window.open(url)
											}).catch(err => {
												console.log(err)
											})
										}} reverse icon="document" label="Información del Programa" round={5} />
										: null
									}
								</View>
							</View>
						</View>
				}

				{paymentCompleted ? <PaymentCompletedModal paymentError={paymentError} closeCallback={() => closePaymentSuccess()} /> : null}
				{paymentsModal ? <PaymentsModal setOpen={setPaymentsModal} course={paymentsModal} courseId={paymentsModal.id} userData={userData} tenantId={paymentsModal.tenantId} originUrl={`/alumno/curso/${paymentsModal.id}`} /> : null}
				{editDataModal ? <EditDataModal setOpen={setEditDataModal} course={editDataModal} courseId={editDataModal.courseId} preinscriptionData={editDataModal?.course?.preinscriptionData || []} /> : null}
				{calificationModal ? <CalificationModal setOpen={setCalificationModal} course={course} advance={advance} dataAdvances={dataAdvances} dataAttendance={dataAttendance} /> : null}
				{classDetails ? <ClassModal data={classDetails} advanceRef={advance?.ref?.parent?.parent} onClose={() => setClassDetails(false)} openCallback={undefined} /> : null}
				{daySelected ?
					<Modal onClickOutside={() => setDaySelected(false)}>
						<View style={{ minWidth: 350, alignItems: "center", justifyContent: "center" }}>
							<View style={{ marginBottom: 15, width: "100%", flexDirection: "row", alignItems: "center" }}>
								<View style={{ flex: 1 }}>
									<StyledText style={{ fontFamily: "Title", textAlign: "left", fontSize: 34 }}>Clases</StyledText>
								</View>
								<TouchableOpacity style={{ width: 40, height: 40, alignItems: "center", justifyContent: "center" }} onPress={() => setDaySelected(false)} >
									<Ionicons name="close" size={30} color="black" />
								</TouchableOpacity>
							</View>
							{(!daySelected || daySelected?.length === 0) ?
								<View style={{ paddingHorizontal: 10, paddingVertical: 25 }}>
									<StyledText style={{ fontFamily: "DemiBold", textAlign: "center", fontSize: 16, opacity: .5 }}>No hay clases programadas</StyledText>
								</View>
								:
								daySelected?.sort((a, b) => new Date(a.start) - new Date(b.start))?.map((content, i, arr) => {
									const { title, type, id } = content;
									const now = new Date();

									return (
										<TouchableOpacity
											accessibilityRole="button"
											// disabled={!previousSectionCompleted}
											key={i}
											onPress={() => {
												const clss = _.get(course, content.path, false);
												if (clss) {
													setClassDetails(clss)
												}
												// const d = allClassesOriginal.find(el => el.id === content.id)
											}}
											style={[styles.shadow, {
												// opacity: previousSectionCompleted ? 1 : 0.4,
												width: "100%",
												backgroundColor: "white",
												borderRadius: 7,
												flexDirection: "row",
												padding: 10,
												marginBottom: arr?.length === i + 1 ? 0 : 10
											}]}
										>

											<DateFancyDisplayer date={content?.start} />

											<View accessibilityRole="none" style={{ flex: 1, justifyContent: "center", paddingLeft: 15 }}>
												<StyledText style={{ fontSize: 20 }}>
													{title}
												</StyledText>
												<StyledText style={{ marginRight: 15, color: "grey", fontSize: 16, marginTop: 5, }}>
													{content.start && content?.startTime} - {content.end && content?.endTime}
												</StyledText>
											</View>

											{checkRanges(content.start, content.end)?.isOngoing ?
												<TouchableOpacity onPress={() => console.log("BARCODE")}>
													<Ionicons name="qr-code" size={30} color="black" />
												</TouchableOpacity>
												: null}

										</TouchableOpacity>
									)
								})}

						</View>
					</Modal>
					: null
				}

				{billingModal ?
					<BillingModal
						closeCallback={() => setBillingModal(false)}
						courseRef={course?.ref}
						invoice={paymentData?.document}
						initialData={paymentData?.billingInfo || {}}
					/>
					: null
				}

			</CourseContext.Provider>
		);
	}
};

export default PathContainer;

const Breadcrumb = ({ onPress, selected, name }) => {
	return (
		<StyledText
			style={{
				fontFamily: "Title",
				fontSize: 30,
				color: selected ? "#e4002a" : undefined,
				wordBreak: "break-all",
				flexWrap: "wrap"
			}}
			onPress={onPress}
		>
			{name}
		</StyledText>
	)
}

const BillingModal = ({ initialData, courseRef, closeCallback, invoice }) => {

	const { userData } = useContext(AuthContext);
	const db = getFirestore();
	const storage = getStorage()

	const [loading, setLoading] = useState(false)

	const validationSchema = yup.object().shape({
		name: yup.string().required("El nombre es obligatorio"),
		address: yup.string().required("La dirección es obligatoria"),
		city: yup.string().required("La ciudad es obligatoria"),
		province: yup.string().required("La provincia es obligatoria"),
		postalCode: yup.string().required("El código postal es obligatorio"),
		country: yup.string().required("El país es obligatorio"),
		taxNumber: yup.string().required("El NIF es obligatorio"),
	});


	const resolver = useYupValidationResolver(validationSchema);
	const height = Dimensions.get("window").height;
	const isPhone = useMediaQuery({ query: '(max-width: 1000px)' })

	const {
		handleSubmit,
		control,
		watch,
		formState: { errors },
	} = useForm({
		mode: "onChange",
		resolver,
		defaultValues: {
			name: initialData?.name || "",
			country: initialData?.country || "",
			province: initialData?.province || "",
			city: initialData?.city || "",
			postalCode: initialData?.postalCode || "",
			address: initialData?.address || "",
			taxNumber: initialData?.taxNumber || "",
		},
	});

	const submitForm = (values) => {
		setLoading(true)
		try {
			const ref = courseRef ? doc(db, `${courseRef.path}/payments/${userData?.uid}`) : null

			if (ref) {
				updateDoc(ref, {
					billingInfo: values
				}).then(res => {
					closeCallback()
				})
			}
			alert("Los datos de facturación se han guardado correctamente")
			setLoading(false)
			closeCallback()

		} catch (err) {
			console.log(err)
			setLoading(false)
			alert("Ha ocurrido un error al guardar los datos de facturación")
		}

	}

	const country = watch("country")
	const province = watch("province")

	const cities = useMemo(() => provinces.find(x => x.provincia === province)?.localidades || [], [province])


	return (
		<Modal modalContainerStyle={{ maxHeight: height - 100, width: "95%", maxWidth: 650 }} duration={100} padding={0} >
			<View style={{ overflow: "hidden", backgroundColor: "white", borderRadius: 7, flex: 1 }} >
				<ScrollView style={{ flex: 1, padding: 15 }} contentContainerStyle={{ gap: 10 }}>

					<StyledText style={{ fontSize: 34, fontFamily: "Title" }}>Datos de Facturación</StyledText>
					<StyledText style={{ fontSize: 16, fontFamily: "DemiBold" }}>
						Si deseas una factura con datos de facturación diferentes a los introducidos en tu preinscripción, rellena el siguiente formulario
					</StyledText>

					<TextField error={get(errors, "name", undefined)} name={"name"} key="name" label="Nombre" placeholder="Nombre..." control={control} />
					<TextField error={get(errors, "address", undefined)} name={"address"} key="address" label="Dirección" placeholder="Dirección..." control={control} />

					<View style={{ width: "100%", flexDirection: isPhone ? "column" : "row", gap: 10 }}>
						<SelectField error={get(errors, "country", undefined)} containerProps={{ style: { flex: 1 } }} options={countries} valueKey="code" labelKey="name" name={"country"} key="country" label="Pais" placeholder="Pais..." control={control} />
						{country !== "ESP" ?
							<TextField error={get(errors, "province", undefined)} name={"province"} key="province" label="Provincia" placeholder="Provincia..." control={control} />
							:
							<SelectField error={get(errors, "province", undefined)} defaultValue={null} options={provinces} valueKey="provincia" labelKey="provincia" name={"province"} key="province" label="Provincia" placeholder="Provincia..." control={control} />
						}
					</View>

					<View style={{ width: "100%", flexDirection: isPhone ? "column" : "row", gap: 10 }}>
						{!province ? null :
							country !== "ESP" ?
								<TextField error={get(errors, "city", undefined)} name={"city"} key="city" label="Localidad" placeholder="Localidad..." control={control} />
								:
								<SelectField error={get(errors, "city", undefined)} containerProps={{ style: { flex: 1 } }} options={cities || []} name={"city"} key="city" label="Localidad" placeholder="Localidad..." control={control} />
						}
						<TextField error={get(errors, "postalCode", undefined)} containerProps={{ style: { flex: 1 } }} name={"postalCode"} key="postalCode" label="Código Postal" placeholder="Código Postal..." control={control} />
					</View>

					<TextField error={get(errors, "taxNumber", undefined)} name={"taxNumber"} key="taxNumber" label="Identificador Fiscal (NIF/CIF)" placeholder="NIF..." control={control} />

				</ScrollView>
				<View style={{ flexDirection: "row", padding: 10, alignItems: "center", justifyContent: "space-between", width: "100%" }}>
					<Button disabled={loading} color="gainsboro" height={35} round={7} label="Cerrar" primary onPress={closeCallback} />
					<View style={{ flexDirection: "row", gap: 10 }}>
						{invoice && <Button
							height={35}
							round={7}
							label={"Factura"}
							primary
							color="#0b1831"
							disabled={loading}
							onPress={async () => {
								getDownloadURL(ref(storage, invoice)).then(url => {
									window.open(url, "_blank")
								}).catch(err => {
									console.log(err)
									alert("Ha ocurrido un error cargando la factura")
								})
							}}
						/>}
						<Button disabled={loading} loading={loading} height={35} round={7} label="Guardar" primary onPress={handleSubmit(submitForm)} />
					</View>
				</View>
			</View>
		</Modal>
	)
}

const PaymentCompletedModal = ({ paymentError, closeCallback }) => {

	const { customization } = useContext(AuthContext);
	const sh = useWindowDimensions().height

	return (
		<Modal modalContainerStyle={{ maxHeight: sh - 50, width: "95%", maxWidth: 750 }} duration={100} padding={0} >
			{paymentError ?
				<View pointerEvents={"auto"} style={{ overflow: "hidden", height: "auto", opacity: 1, borderRadius: 7 }} >
					<View style={{ alignItems: "center", justifyContent: "center", flex: 1 }}>
						<MaterialIcons name={"error-outline"} color={customization.mainColor} size={120} />
						<StyledText style={{ fontSize: 34, fontFamily: "Title", textAlign: "center" }}>Ha ocurrido un error al procesar su pago</StyledText>
						<StyledText style={{ textAlign: "center", fontSize: 16, maxWidth: 500, marginVertical: 15, fontFamily: "DemiBold" }}>Por favor, inténtalo de de nuevo.</StyledText>
						<View style={{ flexDirection: "row", marginTop: 10, marginBottom: 30, alignItems: "center", justifyContent: "center" }}>
							<Button
								style={{ marginRight: 10 }}
								round={7}
								label="Volver"
								primary
								onPress={() => closeCallback()}
							/>
						</View>
					</View>
				</View>
				:
				<View pointerEvents="auto" style={{ overflow: "hidden", height: "auto", opacity: 1, backgroundColor: "white", borderRadius: 7 }} >
					<View style={{ alignItems: "center", justifyContent: "center", flex: 1 }}>
						<Ionicons name={"checkmark-circle-outline"} color={"green"} size={120} />
						<StyledText style={{ fontSize: 34, fontFamily: "Title", textAlign: "center" }}>¡Tu pago se ha realizado con éxito!</StyledText>
						<StyledText style={{ textAlign: "center", fontSize: 16, maxWidth: 500, marginVertical: 15, fontFamily: "DemiBold" }}>
							Tu pago se ha procesado correctamente
						</StyledText>
						<View style={{ flexDirection: "row", marginTop: 10, marginBottom: 30, alignItems: "center", justifyContent: "center" }}>
							<Button style={{ marginRight: 10 }} round={7} label="Cerrar" primary onPress={() => closeCallback()} />
						</View>
					</View>
				</View>}
		</Modal>
	)
}

const AgendaComponent = ({ isAfterCourseStart, contents, onPress, title, color, style = {} }) => {

	const isPhone = useMediaQuery({ query: '(max-width: 1000px)' })
	const [type, setType] = useState(0)

	return (
		<View style={{ padding: 15, flex: isPhone ? null : 1, width: "100%", maxHeight: 500, borderRadius: 7, backgroundColor: color, ...style }}>
			<View style={{ flexDirection: "row" }}>
				<View style={{ flex: 1 }}>
					<StyledText style={{ fontFamily: "Title", marginBottom: 15, color: "white", fontSize: 30 }}>{title}</StyledText>
				</View>

				<View style={{ flexDirection: "row" }}>
					<Button round={7} color="white" height={35} onPress={() => setType(0)} label="Próximas" primary={type === 0} style={{ marginRight: 10 }} />
					<Button round={7} color="white" height={35} onPress={() => setType(1)} label="Todas" primary={type === 1} />
				</View>

			</View>
			<ScrollView showsVerticalScrollIndicator={false}>
				{!contents || contents?.filter(el => type === 1 ? true : checkRanges(el?.start, el?.end).isBeforeEnd).length === 0 ?
					<View style={{ paddingHorizontal: 10, paddingVertical: 25 }}>
						<StyledText style={{ fontFamily: "DemiBold", textAlign: "center", fontSize: 16, color: "white", opacity: .5 }}>Sin clases programadas</StyledText>
					</View>
					:
					contents?.filter(el => type === 1 ? true : checkRanges(el?.start, el?.end).isBeforeEnd).sort((a, b) => a?.end - b?.end)?.map((content, i, arr) => {
						const { title } = content;
						const percentage = content?.isClass ? 0 : content.start && content?.end ? checkRanges(content.start, content.end).percentage : 0;
						return (
							<TouchableOpacity
								accessibilityRole="button"
								disabled={!isAfterCourseStart}
								key={i}
								onPress={() => onPress(content)}
								style={[styles.shadow, {
									width: "100%",
									backgroundColor: "white",
									borderRadius: 7,
									flexDirection: content?.isClass ? "row" : "column",
									padding: 10,
									marginBottom: arr?.length === i + 1 ? 0 : 10
								}]}
							>

								{content.isClass ?
									<View style={{ position: "relative", width: 60, borderRadius: 5, alignItems: "center", marginRight: 15, justifyContent: "center", backgroundColor: "#f2f2f2", paddingVertical: 5 }}>
										<StyledText style={{ fontWeight: "bold", fontFamily: "Title", fontSize: 40, }}>
											{content.start && moment(content?.start).format("DD")}
										</StyledText>
										<StyledText style={{ fontFamily: "Title", fontSize: 14, marginTop: -5 }}>
											{content.start && moment(content?.start).format("MMMM")}
										</StyledText>
									</View> : false
								}
								<View accessibilityRole="none" style={{ flex: 1, justifyContent: "center" }}>
									<StyledText style={{ fontSize: 16, fontFamily: "TitleWide" }}>
										{title}
									</StyledText>
									{content.isClass ? <StyledText style={{ marginRight: 15, color: "grey", fontSize: 16, marginTop: 5, }}>
										{content.start && moment(content?.start).format("HH:mm")} - {content.end && moment(content?.end).format("HH:mm")}
									</StyledText> : null}
								</View>
								{!content?.isClass && percentage < 100 ?
									<View style={{ marginVertical: 10 }}>
										<LinearBarIndicator useNativeDriver={true} percentage={percentage} fillColor={percentage < 33 ? "green" : percentage < 66 ? "orange" : "red"} width="100%" styles={undefined} />
									</View>
									: null
								}
								{!content.isClass ?
									<View style={{ flexDirection: "row" }}>
										<View style={{ position: "relative", flex: 1, marginRight: 10, borderRadius: 5, alignItems: "center", justifyContent: "center", backgroundColor: "#f2f2f2", paddingVertical: 5 }}>
											<StyledText style={{ fontFamily: "Title", fontSize: 30 }}>
												Inicio {content.start && moment(content?.start).format("DD MMMM")}
											</StyledText>
											<StyledText style={{ fontFamily: "TitleWide", fontSize: 14 }}>
												{content.start && moment(content?.start).format("HH:mm")}
											</StyledText>
										</View>
										<View style={{ position: "relative", flex: 1, marginRight: 10, borderRadius: 5, alignItems: "center", justifyContent: "center", backgroundColor: "#f2f2f2", paddingVertical: 5 }}>
											<StyledText style={{ fontFamily: "Title", fontSize: 30 }}>
												Final {content.end && moment(content?.end).format("DD MMMM")}
											</StyledText>
											<StyledText style={{ fontFamily: "TitleWide", fontSize: 14 }}>
												{content.end && moment(content?.end).format("HH:mm")}
											</StyledText>
										</View>
									</View>
									: false
								}
							</TouchableOpacity>
						)
					})}
			</ScrollView>
		</View>
	)
}

function isClassInTime(lowerBound, upperBound) {
	const timestamp = new Date().getTime();

	const extraHour = 3600 * 1000; // ms in hour

	const lowerBoundWithExtraHour = new Date((lowerBound.seconds * 1000) - extraHour).getTime();
	const upperBoundWithExtraHour = new Date((upperBound.seconds * 1000) + extraHour).getTime();

	const isBefore = timestamp < lowerBoundWithExtraHour;
	const isAfter = timestamp > upperBoundWithExtraHour;
	const isValid = (timestamp > lowerBoundWithExtraHour) && (timestamp < upperBoundWithExtraHour);
	return { isBefore, isAfter, isValid };
}

const SectionsComponent = ({ courseRef, courseId, tenantId, advanceRef, section, setContentSelected, handleActiveModal, path, courseName, courseTag }) => {

	const db = getFirestore()
	const storage = getStorage()
	const params = useRoute().params;
	const navigation = useNavigation()
	const isPhone = useMediaQuery({ query: '(max-width: 1000px)' })

	const [openScanner, setOpenScanner] = useState(false)
	const [open, setOpen] = useState(false)
	const [loadingDoc, setLoadingDoc] = useState(false)

	const { userData } = useContext(AuthContext);
	const { advances, courses } = useContext(AdvanceContext);

	const handleQRData = async (value) => {
		if (value) {

			const [tenantId, courseId, classId, type] = value.split(",");

			const courseData = courses.find(c => c.id === courseId);

			const parsedType = type === "1"

			if (!tenantId || !courseId || !classId) return "QR invalido";

			const classes = courseData?.contentDetails?.reduce(
				(acc, cur) =>
					[
						...acc,
						...(cur.classes || []),
						...(cur.sections ? cur.sections?.reduce((sacc, scur) => [...sacc, ...(scur.contents || []), ...(scur.classes || [])], []) : []),
					].map((el) => ({
						...el,
						id: el.id,
					})),
				[]);

			const classDetails = classes.find(c => c.id === classId);
			if (!classDetails) return "La clase a la que esta intentando acceder no existe."

			const {
				isBefore,
				isAfter,
				isValid
			} = isClassInTime(classDetails?.start, classDetails?.end);

			if (!isValid) {
				if (isBefore) {
					return "El plazo para la entrada a esta clase aún no ha comenzado";
				} else if (isAfter) {
					return "El plazo para la entrada a esta clase ha finalizado";
				}
			}

			try {

				const docRef = doc(db, `tenants/${tenantId}/courses/${courseId}/courseavs/${userData.id}/attendance/${classId}`);
				const registered = advances?.find(el => el.courseId === courseId)?.status || null

				if (registered !== "APPROVED") return "No estas registrado en este curso, asegurate que escaneaste el código correcto";

				const d = await getDoc(docRef);

				if (d.exists()) {
					const data = d.data()

					const lastEntry = [...(data?.entries || [])]?.pop()

					if (lastEntry.type === parsedType) {
						setOpenScanner(false)
						return parsedType
							? "Ya has registrado la entrada a esta clase, registra la salida para volver a registrar una entrada"
							: "Ya has registrado la salida a esta clase, registra la entrada para volver a registrar una salida"
					}

					await updateDoc(docRef, {
						updatedAt: serverTimestamp(),
						entries: arrayUnion({
							createdAt: new Date(),
							type: parsedType,
						})
					})

					setOpenScanner(false)
					return `Se ha registrado la ${parsedType ? "entrada" : "salida"} a esta clase correctamente`;
				} else {
					if (!parsedType) return "El código escaneado no es válido, asegurate de escanear el código correcto";
					await setDoc(docRef, {
						createdAt: serverTimestamp(),
						updatedAt: serverTimestamp(),
						userId: userData.id,
						entries: [{
							createdAt: new Date(),
							type: true,
						}]
					})
					setOpenScanner(false)
					return `Se ha registrado la entrada a esta clase correctamente`
				}
			} catch (err) {
				console.log(err)
				setOpenScanner(false)
				return "Error al registrar la asistencia";
			}
		} else {
			setOpenScanner(false)
			return "QR invalido";
		}

	}

	const canAccessSection = (section) => {
		// if section initDate is after today return true


		if (section?.startDate) {
			const today = new Date();
			const initDate = section?.startDate?.toDate ? section?.startDate?.toDate() : new Date(section?.startDate);
			if (initDate > today) return false;
			return true
		} else {
			return true;
		}



	}

	return (
		<>
			<View style={{ flex: 1 }}>
				{/* <StyledText style={{ fontFamily: "Title", fontSize: 34 }}>{section?.name}</StyledText> */}
				<View style={{ flexDirection: "row", flexWrap: "wrap", marginBottom: 15 }}>
					{section?.sections?.length > 0 && section?.sections?.map((section, i, arr) => {
						return (
							<TouchableOpacity
								accessibilityRole="button"
								disabled={!canAccessSection(section)}
								onPress={() => handleActiveModal(section, path + "-" + i)}
								key={i}
								style={[styles.shadow, {
									opacity: canAccessSection(section) ? 1 : 0.4,
									width: isPhone ? "100%" : "32%",
									backgroundColor: "white",
									borderRadius: 7,
									marginTop: 10,
									marginRight: arr.length === i + 1 ? 0 : "1%"
								}]}
							>
								<View style={{ position: "relative", height: 120, width: "100%", }}>
									<SecureImage
										accessibilityRole="none"
										key={i}
										style={{ borderRadius: 7, flex: 1 }}
										// placeholder="https://pbs.twimg.com/profile_images/1527575245112098816/j7D73IDV_400x400.jpg"
										placeholder="https://brandemia.org/sites/default/files/inline/images/03-rfef-imagenes-brandemia-blog_0.jpg"
										uri={section.image}
									/>
								</View>
								<View accessibilityRole="none" style={{ padding: 15 }}>
									<StyledText style={{ marginRight: 10, fontFamily: "Bold", fontSize: 20 }}>
										{section.name}
									</StyledText>
									{section.startDate && section.endDate ?
										<StyledText style={{ fontSize: 16, color: "black", opacity: .5 }}>
											Inicio: {moment(section.startDate?.toDate ? section?.startDate?.toDate() : section?.startDate).format("DD-MM-YYYY")} -  Final: {moment(section.endDate?.toDate ? section?.endDate?.toDate() : section?.endDate).format("DD-MM-YYYY")}
										</StyledText>
										: null
									}
								</View>
							</TouchableOpacity>
						)
					})}
				</View>

				<View style={{ flexDirection: isPhone ? "column" : "row", alignItems: "flex-start", gap: 15 }}>
					{(section?.contents?.length > 0 || section?.resources?.length > 0 || section?.additionalMaterials?.length > 0) ?
						<View style={{ flex: 1, width: "100%", gap: 15 }}>
							{section?.contents?.length > 0 ?
								<ContentsComponent
									setContentSelected={(ev) => {
										navigation.setParams({ content: ev.index })
										const path = params.path
										history.pushState({ content: ev.index, material: undefined, resource: undefined }, "", `?path=${path}&content=${ev.index}`)
										setContentSelected(ev)
									}}
									contents={section.contents}
									courseId={courseId}
									tenantId={tenantId}
								/>
								: null
							}

							<View style={{ flexDirection: isPhone ? "column" : "row", alignItems: "flex-start", gap: 15 }}>
								{section?.resources?.length > 0 ?
									<ResourcesComponent
										courseId={courseId} setContentSelected={(ev) => {
											navigation.setParams({ resource: ev.index })
											const path = params.path
											history.pushState({ content: ev.index, material: undefined, resource: undefined }, "", `?path=${path}&resource=${ev.index}`)
											setContentSelected(ev)
										}}
										contents={section.resources}
										title={"Apuntes"}
										color="#420515"
									// style={{
									//   marginRight: isPhone ? 0 : 15,
									//   marginBottom: isPhone ? 15 : 0
									// }}
									/>
									: null
								}

								{section?.additionalMaterials?.length > 0 ?
									<ResourcesComponent
										courseId={courseId}
										setContentSelected={(ev) => {
											navigation.setParams({ material: ev.index })
											const path = params.path
											history.pushState({ content: ev.index, material: undefined, resource: undefined }, "", `?path=${path}&material=${ev.index}`)
											setContentSelected(ev)
										}}
										contents={section.additionalMaterials}
										title={"Recursos"}
										color="#e4002a"
									/>
									: null
								}

							</View>
						</View>
						: null
					}
					{(section?.classes?.length > 0 || section?.program || section?.teachers?.length > 0 || section.description) ?
						<View
							style={{
								flex: isPhone ?
									undefined :
									(section?.contents?.length > 0 || section?.resources?.length > 0 || section?.additionalMaterials?.length > 0 || section.description) ? .6 : 1,
								position: isPhone ? undefined : "sticky",
								top: isPhone ? undefined : 20,
								height: "auto",
								width: isPhone ? "100%" : undefined,
								gap: 15,
							}}
						>
							{section.classes?.length > 0 ?
								<ClassesComponent
									onPress={(ev) => setOpen(ev)}
									contents={section.classes}
									title={"Clases"}
									color="#0b1831"
									setOpenScanner={setOpenScanner}
								/>
								: null
							}
							{section.description ?
								<View style={{ width: "100%", padding: 15, borderRadius: 7, backgroundColor: "white" }}>

									<View style={{ flex: 1 }}>
										<StyledText style={{ fontFamily: "Title", marginBottom: 15, fontSize: 30 }}>Descripción del Módulo</StyledText>
									</View>

									<StyledText>{section.description}</StyledText>
								</View>

								: null
							}
							{section?.program ?
								<View style={{ width: "100%", padding: 15, borderRadius: 7, backgroundColor: "white" }}>

									<View style={{ flex: 1 }}>
										<StyledText style={{ fontFamily: "Title", marginBottom: 15, fontSize: 30 }}>Programa del Módulo</StyledText>
									</View>

									<Button onPress={() => {
										setLoadingDoc(true)
										const docRef = ref(storage, section.program)
										getDownloadURL(docRef).then((url) => {
											setLoadingDoc(false)
											window.open(url, "_blank")
										}).catch(err => {
											setLoadingDoc(false)
										})
									}} label={`Descargar`} loading={loadingDoc} height={35} round={7} />
								</View>
								: null
							}
							{section?.teachers?.length > 0 ?
								<TeachersComponent
									title="Profesores"
									teachers={section.teachers}
									courseRef={courseRef}
									courseName={courseName}
									courseTag={courseTag}
								/>
								: null
							}

						</View>
						: null
					}
				</View>
			</View>

			{open &&
				<ClassModal
					data={open}
					advanceRef={advanceRef?.parent?.parent}
					openCallback={() => setOpenScanner(true)} onClose={() => setOpen(false)}
				/>
			}
			{openScanner && (
				<Modal modalContainerStyle={{ backgroundColor: "black" }}>
					<QRScan enabled={openScanner} width={400} height={400} onFind={handleQRData} closeCallback={() => setOpenScanner(false)} />
				</Modal>
			)}
		</>
	)
}

const ClassesComponent = ({ setOpenScanner, contents, onPress, title, color, style = {} }) => {

	const isPhone = useMediaQuery({ query: '(max-width: 1000px)' })
	const [type, setType] = useState(0)

	return (
		<View style={{ flex: isPhone ? null : 1, width: "100%", padding: 15, borderRadius: 7, backgroundColor: color, ...style }}>
			<View style={{ flexDirection: "row" }}>
				<View style={{ flex: 1 }}>
					<StyledText style={{ fontFamily: "Title", marginBottom: 15, color: "white", fontSize: 30 }}>{title}</StyledText>
				</View>

				<View style={{ flexDirection: "row" }}>
					<Button round={7} color="white" height={35} onPress={() => setType(0)} label="Próximas" primary={type === 0} style={{ marginRight: 10 }} />
					<Button round={7} color="white" height={35} onPress={() => setType(1)} label="Todas" primary={type === 1} />
				</View>

			</View>
			<View >
				{!contents || contents?.filter(el => type === 1 ? true : checkRanges(fixDate(el?.start), fixDate(el?.end)).isBeforeEnd).length === 0 ?
					<View style={{ paddingHorizontal: 10, paddingVertical: 25 }}>
						<StyledText style={{ fontFamily: "DemiBold", textAlign: "center", fontSize: 16, color: "white", opacity: .5 }}>Sin próximas clases</StyledText>
					</View>
					:
					contents?.filter(el => type === 1 ? true : checkRanges(fixDate(el?.start), fixDate(el?.end)).isBeforeEnd)
						.sort((a, b) => fixDate(a?.start) < fixDate(b?.end))?.map((content, i, arr) => {
							const { title } = content;

							return (
								<TouchableOpacity
									accessibilityRole="button"
									// disabled={!previousSectionCompleted}
									key={i}
									onPress={() => onPress(content)}
									style={[styles.shadow, {
										// opacity: previousSectionCompleted ? 1 : 0.4,
										width: "100%",
										backgroundColor: "white",
										borderRadius: 7,
										flexDirection: "row",
										padding: 10,
										marginBottom: arr?.length === i + 1 ? 0 : 10
									}]}
								>

									<View style={{ position: "relative", width: 60, borderRadius: 5, alignItems: "center", justifyContent: "center", backgroundColor: "#f2f2f2", paddingVertical: 5 }}>
										<StyledText style={{ fontWeight: "bold", fontFamily: "Title", fontSize: 40, }}>
											{content.start && moment((fixDate(content?.start))).format("DD")}
										</StyledText>
										<StyledText style={{ fontFamily: "Title", fontSize: 14, marginTop: -5 }}>
											{content.start && moment((fixDate(content?.start))).format("MMMM")}
										</StyledText>
									</View>
									<View accessibilityRole="none" style={{ flex: 1, justifyContent: "center", paddingLeft: 15 }}>
										<StyledText style={{ fontSize: 20 }}>
											{title}
										</StyledText>
										<StyledText style={{ marginRight: 15, color: "grey", fontSize: 16, marginTop: 5, }}>
											{content.start && moment((fixDate(content?.start))).format("HH:mm")} - {content.end && moment(fixDate(content?.end)).format("HH:mm")}
										</StyledText>

									</View>

									{checkRanges((fixDate(content?.start)), fixDate(content?.end)).isOngoing ? <TouchableOpacity
										onPress={() => setOpenScanner(true)}>
										<Ionicons name="qr-code" size={30} color="black" />
									</TouchableOpacity> : null}
								</TouchableOpacity>
							)
						})}
			</View>
		</View>
	)
}

const ContentsComponent = ({ contents, setContentSelected, courseId, tenantId }) => {

	const [type, setType] = useState(0)
	const isPhone = useMediaQuery({ query: '(max-width: 1000px)' })
	const db = getFirestore()

	return (
		<View style={[styles.shadow, { padding: 15, borderRadius: 7, flex: 1, backgroundColor: "white" }]}>
			<View style={{ flexDirection: isPhone ? "column" : "row", marginBottom: 15 }}>
				<View style={{ flex: 1 }}>
					<StyledText style={{ fontFamily: "Title", fontSize: 30, }}>Tareas y Exámenes</StyledText>
				</View>

				<View style={{ flexDirection: "row", marginTop: isPhone ? 10 : 0 }}>
					<Button onPress={() => setType(0)} round={7} height={35} primary={type === 0} label="Todo" style={{ marginRight: 10 }} />
					<Button onPress={() => setType(1)} round={7} height={35} primary={type === 1} label="Tareas" style={{ marginRight: 10 }} />
					<Button onPress={() => setType(2)} round={7} height={35} primary={type === 2} label="Exámenes" />
				</View>
			</View>
			<View style={{ paddingVertical: 10 }}>
				{contents?.filter(el => type === 0 ? true : type === 1 ? el.contentType !== "EXAM" : el.contentType === "EXAM").length === 0 ?
					<View style={[{ backgroundColor: "#f2f2f2", paddingVertical: 40, paddingHorizontal: 15, borderRadius: 7 }]}>
						<StyledText style={{ fontFamily: "DemiBold", textAlign: "center", fontSize: 20, color: "gray" }}>No hay contenido disponible</StyledText>
					</View>
					:
					contents?.filter(el => type === 0 ? true : type === 1 ? el.contentType !== "EXAM" : el.contentType === "EXAM").map((content, i) => {
						const { title, type, id, ref, deadlines } = content;
						return (
							<TouchableOpacity
								accessibilityRole="button"
								// disabled={!previousSectionCompleted}
								key={i}
								// onPress={() => navigation.navigate("Content", { courseId, id: ref.id, tenantId: ref?.parent?.parent?.id })}
								onPress={() => {
									const index = contents.findIndex(el => el.id === content.id)
									const ref = typeof content.ref === "string" ? doc(db, content.ref) : content.ref
									setContentSelected({ ...content, index: index, ref })
								}}
								style={{
									// opacity: previousSectionCompleted ? 1 : 0.4,
									width: "100%",
									flexDirection: "row",
									marginBottom: 15
								}}
							>

								<View style={{ position: "relative", height: 80, width: 80, borderRadius: 40 }}>
									{content.image ? <SecureImage
										accessibilityRole="none"
										style={{ borderRadius: 45, flex: 1 }}
										placeholder="https://pbs.twimg.com/profile_images/1527575245112098816/j7D73IDV_400x400.jpg"
										uri={content.image}
									/> :
										<View style={{ position: "relative", height: 80, width: 80, borderRadius: 40, alignItems: "center", justifyContent: "center", backgroundColor: content.contentType === "EXAM" ? "#0b1831" : "#e4002a" }}>
											<Ionicons name={swapIcon(content.contentType).icon} size={35} color="white" />
										</View>
									}
								</View>
								<View accessibilityRole="none" style={{ flex: 1, justifyContent: "center", paddingHorizontal: 15 }}>
									<StyledText style={{ marginRight: 10, fontFamily: "DemiBold", fontSize: 16 }}>
										{title}
									</StyledText>

									{deadlines.length > 0 &&
										<View style={{ padding: 10, backgroundColor: "#f2f2f2", borderRadius: 7, marginTop: 10 }}>
											{deadlines.map((el, i) => {
												if (!el.start || !el.end) return null
												return (
													<StyledText key={i} style={{ fontSize: 14 }}>
														{`${el.name} - ${el.start ? moment(fixDate(el?.start)).format("DD-MM-YYYY HH:mm") : "-"} a ${el?.end ? moment(fixDate(el?.end)).format("DD-MM-YYYY HH:mm") : "-"}`}
														{/* {section.contents.length > 0 && `${completed} de ${total} Completado`} */}
													</StyledText>
												)
											})}
										</View>
									}
									{/* <StyledText style={{ marginRight: 15, fontFamily: "Bold", color: "grey", fontSize: 18, marginTop: 5, }}>
										{section.contents.length > 0 && `${completed} de ${total} Completado`}
									</StyledText> */}
								</View>
							</TouchableOpacity>
						)
					})}
			</View>
		</View>
	)
}

const ResourcesComponent = ({ courseId, contents, setContentSelected, title, color, style = {} }) => {
	const isPhone = useMediaQuery({ query: '(max-width: 1000px)' })
	return (
		<ScrollView style={{ flex: isPhone ? undefined : 1, width: isPhone ? "100%" : undefined, maxHeight: 600, padding: 15, borderRadius: 7, backgroundColor: color, ...style }}>
			<StyledText style={{ fontFamily: "Title", marginBottom: 15, color: "white", fontSize: 30 }}>{title}</StyledText>
			<View >
				{(!contents || contents?.length === 0) ?
					<View style={{ paddingHorizontal: 10, paddingVertical: 25 }}>
						<StyledText style={{ fontFamily: "DemiBold", textAlign: "center", fontSize: 16, color: "white", opacity: .5 }}>Sin contenidos disponibles</StyledText>
					</View>
					:
					contents?.map((content, i, arr) => {
						const { title, type, id, ref } = content;

						return (
							<TouchableOpacity
								accessibilityRole="button"
								// disabled={!previousSectionCompleted}
								key={i}
								onPress={() => setContentSelected({ ...content, index: i })}
								// onPress={() => navigation.navigate("Content", { courseId, id: ref.id, tenantId: ref?.parent?.parent?.id })}
								style={[styles.shadow, {
									// opacity: previousSectionCompleted ? 1 : 0.4,
									width: "100%",
									backgroundColor: "white",
									borderRadius: 7,
									flexDirection: "row",
									padding: 10,
									marginBottom: arr?.length === i + 1 ? 0 : 10
								}]}
							>

								<View style={{ position: "relative", height: 50, width: 50, borderRadius: 25, alignItems: "center", justifyContent: "center", backgroundColor: "#f2f2f2" }}>
									<Ionicons name={swapIcon(content.contentType).icon} size={20} color="black" />
								</View>
								<View accessibilityRole="none" style={{ flex: 1, justifyContent: "center", paddingLeft: 15 }}>
									<StyledText style={{ fontSize: 14 }}>
										{title}
									</StyledText>
									{/* <StyledText style={{ marginRight: 15, fontFamily: "Bold", color: "grey", fontSize: 18, marginTop: 5, }}>
										{section.contents.length > 0 && `${completed} de ${total} Completado`}
									</StyledText> */}
								</View>
							</TouchableOpacity>
						)
					})}
			</View>
		</ScrollView>
	)
}

const TeachersComponent = ({ teachers, title, courseRef, courseName, courseTag }) => {

	const { setChatVisible, userData } = useContext(AuthContext)

	return (
		<View style={{ width: "100%", padding: 15, borderRadius: 7, backgroundColor: "white" }}>
			<View style={{ flexDirection: "row" }}>
				<View style={{ flex: 1 }}>
					<StyledText style={{ fontFamily: "Title", marginBottom: 15, fontSize: 30 }}>{title}</StyledText>
				</View>
			</View>
			<View>
				{(teachers?.length === 0 || !teachers) ?
					<View style={{ paddingHorizontal: 10, paddingVertical: 25 }}>
						<StyledText style={{ fontFamily: "DemiBold", textAlign: "center", fontSize: 16, opacity: .5 }}>Sin Profesores Asignados</StyledText>
					</View>
					:
					teachers?.map((teacher, i, arr) =>
						<TeacherViewer
							teacher={teacher}
							tenantId={get(courseRef, "parent.parent.id")}
							last={arr?.length === i + 1}
							key={i}
							onPress={(ev) => setChatVisible({
								name: ev.teacherName,
								user: userData.id,
								userName: userData.name + " " + userData.surname,
								userImage: userData.image,
								courseName: courseName,
								courseTag: courseTag,
								teacherImage: ev.teacherImage,
								teacherName: ev.teacherName,
								teacher,
								courseRef
							})} />)}
			</View>
		</View>
	)
}

const TeacherViewer = ({ teacher, tenantId, last, onPress }) => {


	//REEMPLAZAR POR FUNCION QUE LE PASEMOS UN ARRAY DE PROFESORES Y SOLO DEVUELVA IMGEN Y NOMBRE (AHORA ESTA DEVOLVIENDO TODOS LOS DATOS)

	const app = getApp()
	const functions = getFunctions(app, "europe-west1")
	const teacherId = teacher instanceof DocumentReference ? teacher?.path.includes("tenants") ? teacher.path.split("/").pop() : teacher : teacher.split("/").pop()
	const getTeacher = httpsCallable(functions, "getTeacher");


	const { data, isLoading } = useQuery({
		queryKey: ["teacher", teacherId],
		queryFn: async () => getTeacher({
			userId: teacherId,
			tenantId: tenantId
		}).then(res => res.data),
	})

	// console.log(data)

	// const docRef = teacher instanceof DocumentReference ? teacher.path.includes("tenants") ? doc(db, `users/${teacher.path.split("/").pop()}`) : teacher : doc(db, teacher.includes("tenants") ? `users/${teacher.split("/").pop()}` : teacher)

	// const { data } = useFirestoreDocumentData([docRef.id], docRef, {}, {
	//   onError: e => console.log(e)
	// })

	if (isLoading) return null

	return (
		<TouchableOpacity
			accessibilityRole="button"
			onPress={() => onPress({
				teacherName: data?.name + " " + data?.surname,
				teacherImage: data?.image
			})}
			style={{
				width: "100%",
				flexDirection: "row",
				alignItems: "center",
				paddingHorizontal: 10,
				marginBottom: last ? 0 : 10
			}}
		>
			<SecureImage uri={data?.image} style={{ height: 45, width: 45, borderRadius: 28, backgroundColor: "#f3f3f3", marginRight: 10 }} />
			<View style={{ flex: 1 }}>
				<StyledText numberOfLines={1} style={{ fontFamily: "DemiBold", fontSize: 18, }}>
					{data?.name} {data?.surname}
				</StyledText>
			</View>
			<View>
				<Ionicons name="chatbox-ellipses-outline" size={24} color="black" />
			</View>
		</TouchableOpacity>
	)
}

const EditDataModal = ({ setOpen, course, courseId, preinscriptionData }) => {

	const [openSubform, setOpenSubform] = useState(false);
	const [step, setStep] = useState(0)
	const [loading, setLoading] = useState(false)
	const [registerSuccess, setRegisterSuccess] = useState(false)
	const [showErrorModal, setShowErrorModal] = useState(false)

	const storage = getStorage()
	const sh = useWindowDimensions().height
	const { customization, tenant, userData } = useContext(AuthContext)

	const db = getFirestore()

	const collectionRef = query(collection(db, `${course.ref.path}/messages`), orderBy("createdAt", "desc"), limit(1));

	const { data, isLoading } = useFirestoreQuery(["courseavs", courseId], collectionRef, {}, {
		select: snapshot => {
			return snapshot?.docs?.map(doc => ({ ...doc.data(), id: doc.id, ref: doc.ref })) || []
		},
		onError: (err) => console.log(err)
	})
	const tenantRef = doc(db, `tenants/${course.tenantId}`)
	const { data: tenantData } = useFirestoreDocumentData(["tenant", course.tenantId], tenantRef, {}, {
		onError: e => console.log(e)
	})


	const validationSchema = yup.object({
		...preinscriptionData.reduce((acc, item) => {
			if (item.type.includes("form") || item.type.includes("16") || item.type.includes("12") || item.type.includes("51")) {
				return {
					...acc,
					[item.key]: yup.object().test("formChecker", " - El formulario debe estar completado", ev => {
						const t = ev && Object.keys(ev)?.length > 0
						return item.required ? t : true
					}).typeError(item.name + " Este formulario está incompleto o contiene errores")
				}
			} else {
				return { ...acc, [item.key]: getValidationType(item.type, item.name + " - Este campo es obligatorio", item.required) }
			}
		}, {})
	});

	const resolver = useYupValidationResolver(validationSchema);

	const {
		handleSubmit,
		setValue,
		getValues,
		watch,
		trigger,
		control,
		formState: { errors },
	} = useForm({
		mode: "onChange",
		resolver,
		defaultValues: {
			...preinscriptionData.reduce((acc, el) => ({
				...acc,
				[el.key]: course[el.key] || null
			}), {}),
			preinscriptionDataStatus: course?.preinscriptionDataStatus
		}
	})

	useEffect(() => {
		if (errors && Object.values(errors).length > 0) {
			setShowErrorModal(true)
		}
	}, [errors])

	const preinscriptionDataStatus = watch("preinscriptionDataStatus")

	const hasDataPending = preinscriptionData?.map(el => el.key).some(key => course?.preinscriptionDataStatus?.[key] !== "APPROVED")

	const submitForm = async (values) => {
		setLoading(true)
		try {
			const sanitizedValues = sanitizeObject(values)
			updateDoc(course.ref, sanitizedValues).then(res => {
				setStep(1)
				setRegisterSuccess(true)
				setLoading(false)
			}).catch(err => {
				console.log(err)
				setLoading(false)
			})
		} catch (err) {
			console.log(err)
			setLoading(false)
			alert("Error al actualizar los datos, por favor intenta de nuevo")
		}
	}


	function renderCustomForm(type, closeCb, submitCb, initialData) {
		switch (type) {
			case 16:
				return <CustomFormCV closeCallback={closeCb} submitCallback={submitCb} initialData={initialData} />
			case 12:
				return <CustomFormDeclaracionJurada closeCallback={closeCb} submitCallback={submitCb} initialData={initialData} />
			case 51:
				return <CustomFormDeclaracionMedica closeCallback={closeCb} submitCallback={submitCb} initialData={initialData} course={course?.course || course} tenantData={tenantData} />
			default:
				throw new Error("El tipo de formulario es incorrecto y/o no existe")
		}
	}


	return (
		<>
			{showErrorModal ? <Modal modalContainerStyle={{ width: 350 }} duration={100} padding={0}>
				<View style={{ padding: 15 }}>
					<StyledText style={{ fontSize: 18, fontWeight: "bold" }}>
						El formulario tiene los siguientes errores, solucionalos antes de continuar
					</StyledText>
					<View style={{ gap: 10, marginTop: 15 }}>
						{Object.values(errors).map((el, i) => {
							return (
								<StyledText key={i}>- {el?.message}</StyledText>
							)
						})}
					</View>
				</View>
				<View style={{ paddingHorizontal: 20, paddingBottom: 20, marginTop: 20, flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
					<Button
						disabled={loading}
						onPress={() => setShowErrorModal(false)}
						label={"Volver"}
						round={5}
						height={40}
						color="gainsboro"
					/>
				</View>
			</Modal>
				: null
			}
			<Modal modalContainerStyle={{ maxHeight: sh - 50, width: "95%", maxWidth: 750 }} duration={100} padding={0} >
				{loading &&
					<View style={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0, zIndex: 10, backgroundColor: "white", alignItems: "center", justifyContent: "center", }}>
						<ActivityIndicator color={customization.mainColor} size="large" />
						<StyledText style={{ fontSize: 34, fontFamily: "Title", marginTop: 20 }}>Comprobando información</StyledText>
					</View>
				}
				<View style={{ width: "100%" }}>
					<ScrollView style={{ maxHeight: sh - 120, flex: 1 }} contentContainerStyle={{ paddingHorizontal: 20, flexGrow: 1 }}>
						<View pointerEvents={step === 0 ? "auto" : "none"}
							style={{ position: step === 0 ? "relative" : 'absolute', overflow: "hidden", height: step === 0 ? "auto" : 0, opacity: step === 0 ? 1 : 0 }} >
							<View style={{ width: "100%", marginBottom: 1 }}>
								<StyledText style={{ marginTop: 20, fontSize: 34, fontFamily: "Title", paddingBottom: 0 }}>Datos Adicionales</StyledText>

								{/* MENSAJE DE CAMBIOS */}
								<View style={{ paddingBottom: 10 }}>
									{(course?.status === "CHANGESREQUIRED" || Object.values(preinscriptionDataStatus || {})?.some(el => el === "CHANGESREQUIRED")) ?
										<View style={{ backgroundColor: "orange", borderRadius: 5, padding: 10 }}>

											{!isLoading && data?.length > 0 && <StyledText style={{ color: "white", fontSize: 12, marginTop: 5 }}>{moment(data?.sort((a, b) => a?.createdAt?.toDate() > b?.createdAt?.toDate())[0]?.createdAt?.toDate()).format("DD-MM-YYYY HH:mm")}</StyledText>}
											<View style={{ flex: 1 }}>
												<StyledText style={{ fontFamily: "DemiBold", color: "white", fontSize: 16, whiteSpace: "break-spaces" }}>{isLoading ? "Por favor, revisa los datos y envía los cambios. solicitados" : data?.sort((a, b) => a?.createdAt?.toDate() > b?.createdAt?.toDate())[0]?.body || "Por favor, revisa los datos y envía los cambios. solicitados"}</StyledText>
											</View>

										</View>
										: null
									}

								</View>

								{/* LISTADO DE ARCHIVOS */}
								{preinscriptionData.map((field, idx) => {
									if (field.type && field.key) {
										return field.type.includes("form") || field.type.includes("16") || field.type.includes("12") || field.type.includes("51") ?
											<View id={field.key} key={idx} style={{ width: "100%", paddingBottom: 10 }}>
												<View key={`${field.name}`} >
													<StyledText style={{ fontSize: 16, color: "gray" }}>{field.name}</StyledText>
													<View style={{ flexDirection: "row", alignItems: "center", flex: 1, marginVertical: 10 }}>
														<Button
															round={7}
															style={{ flex: 1 }}
															height={35}
															label={getValues(field.key) ? "Modificar" : "Editar"}
															onPress={() => {
																const form = getValues(field.key);
																const defaultValues = form ? form : {};
																setOpenSubform({
																	key: field.key,
																	formId: field.id,
																	formRef: field.ref,
																	index: idx,
																	defaultValues: { ...defaultValues, idNumber: data?.idNumber || "", idType: data?.idType === "passport" ? "pasaporte" : data?.idType || "dni" },
																	documentType: (field.type === "form-NrCcaX760nhT7MsGnn3C" || field.type.includes("16")) ? 16 : (field.type === "form-g9BGWNtCXMVF3PZtya5X" || field.type.includes("12")) ? 12 : (field.type === "form-Rs0Uvb149qAQ5jihS55A" || field.type.includes("51")) ? 51 : field.documentType
																})
															}}
														/>

														{getValues(field.key) ?
															<FontAwesome name="check" color="#e4002b" size={20} style={{ marginLeft: 10 }} />
															:
															null
														}
													</View>
												</View>
												{get(errors, field.key) ? (
													<StyledText style={{ marginTop: 10, fontSize: 14, color: "red" }}>
														{get(errors, field.key)?.message}
													</StyledText>
												) : null}
												{get(preinscriptionDataStatus, field.key, undefined) === "CHANGESREQUIRED" ?
													<View style={{ backgroundColor: "red", borderRadius: 7, padding: 6, marginBottom: 15 }}>
														<StyledText style={{ fontSize: 16, color: "white" }}>Este documento necesita cambios</StyledText>
													</View>
													:
													null
												}
											</View>
											:
											<Fragment key={idx}>
												<MultiField
													name={field.key}
													error={errors[field.key]}
													route={`preinscriptionDocuments/${course.tenantId}/course/${courseId}/${userData?.id}`}
													label={field.name}
													type={field.type}
													required={field.required}
													customRef={field.ref}
													containerProps={{ style: { marginTop: 10 } }}
													editable={preinscriptionDataStatus?.[field.key] !== "APPROVED"}
													setValue={value => {
														console.log(value)
														setValue(field.key, value)
														setValue(`preinscriptionDataStatus.${field.key}`, "PENDINGREVIEW")
													}}
													control={control}
												/>
												{field.template ?
													<TouchableOpacity onPress={() => {
														getDownloadURL(ref(storage, field.template)).then(res => {
															window.open(res, "_blank")
														}).catch(err => {
															console.log({ err })
															alert("No se ha podido descargar el archivo")
														})
													}} style={{ marginBottom: 15, flexDirection: "row", alignItems: "center", columnGap: 5, paddingHorizontal: 15, paddingVertical: 10, backgroundColor: "#f2f2f2" }}>
														<Ionicons name="download-outline" size={20} />
														<StyledText style={{ fontSize: 16, color: "black" }}>Descargar plantilla {field.name}</StyledText>
													</TouchableOpacity>
													: null
												}
												{get(preinscriptionDataStatus, field.key, undefined) === "CHANGESREQUIRED" ?
													<View style={{ backgroundColor: "red", borderRadius: 7, padding: 6, marginBottom: 15 }}>
														<StyledText style={{ fontSize: 16, color: "white" }}>Este documento necesita cambios</StyledText>
													</View>
													:
													null
												}
											</Fragment>
									}
								})
								}
							</View>
						</View>


						{openSubform ?
							(openSubform?.documentType === 16 || openSubform?.documentType === 12 || openSubform?.documentType === 51 ?
								renderCustomForm(
									openSubform.documentType,
									() => setOpenSubform(false),
									(data) => {
										setValue(openSubform?.key, data)
										setValue(`preinscriptionDataStatus.${openSubform?.key}`, "PENDINGREVIEW")
										setOpenSubform(false)
									},
									openSubform.defaultValues,
								)
								:
								<Modal padding={0} modalContainerStyle={{ maxWidth: 900, width: "95%", backgroundColor: "white", borderRadius: 7 }} >
									<Subform
										defaultValues={openSubform.defaultValues}
										formRef={openSubform?.formRef}
										update={(ev) => {
											setValue(openSubform?.key, ev)
											setValue(`preinscriptionDataStatus.${openSubform?.key}`, "PENDINGREVIEW")
										}}
										fieldRoute={`preinscriptionDocuments/${course.tenantId}/course/${courseId}/${userData?.id}`}
										index={openSubform.index}
										formId={openSubform.formId}
										onClose={() => setOpenSubform(false)}
									/>
								</Modal>
							)
							: null}



						<View pointerEvents={(step === 1) ? "auto" : "none"} style={{ position: step === 1 ? "relative" : 'absolute', overflow: "hidden", height: step === 1 ? "auto" : 0, opacity: step === 1 ? 1 : 0 }} >
							<View style={{ alignItems: "center", justifyContent: "center", paddingVertical: 80 }}>
								<Ionicons name={registerSuccess ? "checkmark-circle-outline" : "warning-outline"} color={!registerSuccess ? "orange" : "green"} size={120} style={{ marginBottom: 50 }} />
								<StyledText style={{ fontSize: 34, fontFamily: "Title", textAlign: "center" }}>{registerSuccess ? "Se han modificado tus datos de preinscripción con éxito" : "Ocurrió un error modificando tus datos de preinscripción"}</StyledText>
								{registerSuccess
									? (<StyledText style={{ textAlign: "center", fontSize: 16, maxWidth: 500, marginVertical: 15, fontFamily: "DemiBold" }}>
										Revisaremos tus datos y te enviaremos un correo electrónico con la confirmación de tu preinscripción.
									</StyledText>)
									: <StyledText style={{ textAlign: "center", fontSize: 16, maxWidth: 500, marginVertical: 15, fontFamily: "DemiBold" }}>
										Vuelve a intentarlo en unos minutos o ponte en contacto con nosotros. Disculpa las molestias
									</StyledText>
								}
								<View style={{ flexDirection: "row", marginTop: 10, alignItems: "center", justifyContent: "center" }}>
									<Button
										style={{ marginRight: 10 }}
										round={7}
										label="Cerrar"
										onPress={() => {
											setOpen(null)
										}}
									/>
								</View>
							</View>
						</View>
					</ScrollView>
				</View >

				{step === 0
					? <View style={{ paddingHorizontal: 20, paddingBottom: 20, marginTop: 20, flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
						<Button
							disabled={loading}
							onPress={() => setOpen(null)}
							label={"Cerrar"}
							round={7}
							height={40}
							color="gainsboro"
						/>
						{hasDataPending
							? <Button
								disabled={loading}
								onPress={handleSubmit(submitForm)}
								label={loading ? "Cargando.." : "Guardar"}
								round={7}
								height={40}
							/>
							: null}
					</View>
					: null
				}

			</Modal >
		</>
	)
}

const CalificationModal = ({ setOpen, course, advance, dataAdvances, dataAttendance }) => {
	return (
		<Modal padding={0} modalContainerStyle={{ maxWidth: 800, width: "100%", maxHeight: "95%" }} onClickOutside={() => setOpen(false)}>
			<View style={{ marginBottom: 15, paddingLeft: 15, paddingRight: 10, paddingTop: 15, width: "100%", flexDirection: "row", alignItems: "center" }}>
				<View style={{ flex: 1 }}>
					<StyledText style={{ fontFamily: "Title", textAlign: "left", fontSize: 34 }}>Mis Calificaciones</StyledText>
				</View>
				<TouchableOpacity style={{ width: 40, height: 40, alignItems: "center", justifyContent: "center" }} onPress={() => setOpen(false)} >
					<Ionicons name="close" size={30} color="black" />
				</TouchableOpacity>
			</View>
			<ScrollView contentContainerStyle={{ paddingHorizontal: 15, paddingBottom: 15 }}>
				<View style={{ gap: 10 }}>
					{course?.contentDetails?.map((section, i) => {
						const sectionGrade = advance?.grades?.find(x => x.id === section.id)
						return (
							<CalificationSectionComponent path={i} key={i} section={section} sectionGrade={sectionGrade} dataAdvances={dataAdvances} dataAttendance={dataAttendance} advance={advance} clickCallback={() => setOpen(false)} />
						)
					})}

					<View style={{ backgroundColor: "#f2f2f2", flexDirection: "row", alignItems: "center", justifyContent: "space-between", paddingHorizontal: 10, paddingVertical: 10, borderRadius: 5 }}>
						<View style={{ flex: 1 }}>
							<StyledText style={{ fontFamily: "TitleWide", fontSize: 12 }} numberOfLines={1}>Calificación Final</StyledText>
						</View>
						<View style={{ backgroundColor: getStatusColor(advance?.finalGradeStatus || "NOTRATED"), borderRadius: 5, paddingHorizontal: 15, paddingVertical: 10, zIndex: 10, }}>
							<StyledText style={{ fontSize: 10, color: "white", fontWeight: "bold" }}>{getStatusName(advance?.finalGradeStatus) || "Pendiente de Calificación"}</StyledText>
						</View>
					</View>

				</View>

			</ScrollView>
		</Modal>
	)
}

const CalificationSectionComponent = ({ path, section, dataAdvances, dataAttendance, sectionGrade, advance, clickCallback }) => {
	const [open, setOpen] = useState(false)

	return (
		<View style={{ width: "100%" }}>
			<TouchableOpacity onPress={() => setOpen(!open)} style={{ backgroundColor: "#f2f2f2", flexDirection: "row", alignItems: "center", justifyContent: "space-between", paddingHorizontal: 10, paddingVertical: 10, borderRadius: 5 }}>
				<View style={{ width: 35 }} >
					<Ionicons name={open ? "chevron-up" : "chevron-down"} size={24} color="black" />
				</View>
				<View style={{ flex: 1 }}>
					<StyledText style={{ fontFamily: "TitleWide", fontSize: 12 }} numberOfLines={1}>{section.name}</StyledText>
				</View>
				{sectionGrade && <View style={{ backgroundColor: getStatusColor(sectionGrade?.status), borderRadius: 5, paddingHorizontal: 15, paddingVertical: 10, zIndex: 10, }}>
					<StyledText style={{ fontSize: 10, color: "white", fontWeight: "bold" }}>{getStatusName(sectionGrade?.status)}</StyledText>
				</View>}

			</TouchableOpacity>

			<View style={{ height: open ? "auto" : 0, overflow: "hidden", paddingVertical: open ? 10 : 0, paddingHorizontal: 10, gap: 10, borderLeftWidth: 2, borderRightWidth: 2, borderBottomWidth: open ? 2 : 0, borderTopLeftRadius: 0, borderTopRightRadius: 0, borderColor: "gainsboro", borderRadius: 10 }} >

				{section?.sections?.length > 0 ?
					<View style={{ gap: 10 }}>
						{section?.sections?.map((section, i) => {
							const sectionGrade = advance?.grades?.find(x => x.id === section.id)
							return (
								<CalificationSectionComponent
									key={i}
									path={path + "-" + i}
									section={section}
									sectionGrade={sectionGrade}
									dataAdvances={dataAdvances}
									dataAttendance={dataAttendance}
									clickCallback={clickCallback}
									advance={advance}
								/>
							)
						})}
					</View>
					: null}

				<ActivitiesCalification path={path} section={section} dataAdvances={dataAdvances} dataAttendance={dataAttendance} clickCallback={clickCallback} />
			</View>

		</View>
	)
}

const SectionCalification = ({ section, advance }) => {
	return (
		<View style={{ width: "100%", marginBottom: 20 }}>
			<View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginBottom: 15 }}>
				<StyledText style={{ fontFamily: "Title", fontSize: 24 }}>{section.name}</StyledText>
			</View>
			<View style={{ width: "100%", height: 10, backgroundColor: "gainsboro", borderRadius: 5 }}>
				<View style={{ width: `${advance}%`, height: 10, backgroundColor: "orange", borderRadius: 5 }} />
			</View>
		</View>
	)
}

const getActivityStatusName = (status) => {
	switch (status) {
		case "PENDINGREVIEW":
			return "Pendiente de revisión"
		case "CHANGESREQUIRED":
			return "Necesita cambios"
		case "FAILED":
			return "No Apto"
		case "APPROVED":
			return "Apto"
		case "NOTSUBMITTED":
			return "No entregado"
		default:
			return "Pendiente de revisión"
	}
}

const ActivitiesCalification = ({ path, section, dataAdvances, dataAttendance, clickCallback }) => {
	const navigation = useNavigation()
	return (
		<View style={{ width: "100%", gap: 10 }}>

			{section?.contents?.length > 0 ?
				<>
					<View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
						<StyledText style={{ fontFamily: "TitleWide", fontSize: 12 }}>Tareas y Exámenes</StyledText>
					</View>
					<View style={{ padding: 10, gap: 10, paddingRight: 10, backgroundColor: "#f2f2f2" }}>
						{section?.contents?.length === 0 ?
							<View style={{ backgroundColor: "#f3f3f3", padding: 15 }}>
								<StyledText style={{ fontSize: 14, opacity: .5, textAlign: "center" }}>No hay actividades programadas</StyledText>
							</View>
							:
							section?.contents?.map((content, i) => {
								const advance = dataAdvances?.filter(x => x.contentId === content.id)?.sort((a, b) => a?.updatedAt?.toDate() > b?.updatedAt?.toDate() ? -1 : 1)[0]
								return (
									<Pressable
										onPress={() => {
											navigation.setParams({ path: path.toString(), content: i.toString(), material: undefined, resource: undefined })
											history.pushState({ path: path, content: i, material: undefined, resource: undefined }, "", `?path=${path}&content=${i}`)
											clickCallback()
										}}
										key={i}
										style={{ flexDirection: "row", height: 30, alignItems: "center", justifyContent: "space-between" }}
									>

										<View style={{ flex: 1 }}>
											<StyledText numberOfLines={1} style={{ fontSize: 14, fontWeight: "bold" }}>{content.title}</StyledText>
										</View>
										{advance ?
											(advance?.contentType === "EXAM" || advance?.status === "COMPLETED") ?
												(((content?.percentageToPass * 10) <= advance?.percentage) || (advance?.grade >= content?.percentageToPass)) ?
													<View style={{ marginLeft: 15, backgroundColor: "#0b1831", borderRadius: 5, paddingHorizontal: 15, paddingVertical: 10, zIndex: 10, }}>
														<StyledText style={{ fontSize: 10, color: "white", fontWeight: "bold" }}>Apto</StyledText>
													</View>
													:
													<View style={{ marginLeft: 15, backgroundColor: "#0b1831", borderRadius: 5, paddingHorizontal: 15, paddingVertical: 10, zIndex: 10, }}>
														<StyledText style={{ fontSize: 10, color: "white", fontWeight: "bold" }}>No Apto</StyledText>
													</View>
												:
												<View style={{ marginLeft: 15, backgroundColor: "#0b1831", borderRadius: 5, paddingHorizontal: 15, paddingVertical: 10, zIndex: 10, }}>
													<StyledText style={{ fontSize: 10, color: "white", fontWeight: "bold" }}>
														{getActivityStatusName(advance?.status)}
													</StyledText>
												</View>
											:
											<View style={{ marginLeft: 15, width: 50 }} />
										}
									</Pressable>
								)
							})}
					</View>
				</>
				: null
			}

			{section?.classes?.length > 0 ?
				<>
					<View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
						<StyledText style={{ fontFamily: "TitleWide", fontSize: 12 }}>Asistencia</StyledText>
					</View>

					<View style={{ padding: 10, gap: 10, backgroundColor: "#f2f2f2" }}>
						{section?.classes?.length === 0 ?
							<View style={{ backgroundColor: "#f3f3f3", padding: 15 }}>
								<StyledText style={{ fontSize: 14, opacity: .5, textAlign: "center" }}>No hay clases programadas</StyledText>
							</View>
							:
							section?.classes?.map((content, i) => {
								const attendance = dataAttendance?.filter(x => x.id === content.id)?.sort((a, b) => a?.updatedAt?.toDate() > b?.updatedAt?.toDate() ? -1 : 1)[0]
								const classDate = fixDate(content.start)
								const start = fixDate(attendance?.entries[0]?.createdAt)
								const end = fixDate(attendance?.entries[attendance?.entries?.length - 1]?.createdAt)
								return (
									<View key={i} style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
										<View style={{ flex: 1 }}>
											<StyledText numberOfLines={1} style={{ fontSize: 14, fontWeight: "bold" }}>{content.title}</StyledText>
											<StyledText numberOfLines={1} style={{ fontSize: 12 }}>{moment(classDate).format("DD-MM-YYYY - HH:mm")}</StyledText>
										</View>
										{attendance && attendance?.entries?.length ?
											<View style={{ marginLeft: 15, flexDirection: "row", gap: 10, backgroundColor: "#0b1831", borderRadius: 5, paddingHorizontal: 15, paddingVertical: 10 }}>
												<View style={{ flexDirection: "row", gap: 5, alignItems: "center" }}>
													<Ionicons name="enter-outline" size={16} color="white" />
													<StyledText style={{ fontSize: 12, color: "white", fontWeight: "bold" }}>{moment(start).format("HH:mm")}</StyledText>
												</View>
												{attendance?.entries?.length > 1 ?
													<View style={{ flexDirection: "row", gap: 5, alignItems: "center" }}>
														<Ionicons name="exit-outline" size={16} color="white" />
														<StyledText style={{ fontSize: 12, color: "white", fontWeight: "bold" }}>{moment(end).format("HH:mm")}</StyledText>
													</View>
													: null
												}
											</View>
											: null
										}
									</View>
								)
							})}

					</View>
				</>
				: null
			}

		</View>
	)
}


const VideoCourse = ({ course, setContentSelected }) => {

	const { userData } = useContext(AuthContext)
	const isPhone = useMediaQuery({ query: '(max-width: 1000px)' })
	const db = getFirestore()

	const advanceRef = get(course, "advance.ref", null)

	const { data, isLoading } = useFirestoreQuery(
		["videoAttendance", userData.id, course.id],
		collection(db, advanceRef?.path + "/videoAttendance"),
		{
			subscribe: true
		},
		{
			enabled: !!advanceRef,
			refetchOnMount: "always",
			select: d => d.docs.map(x => ({ ...x.data(), id: x.id, ref: x.ref })),
			onError: e => console.log(e)
		});


	const videos = useMemo(() => get(course, "contentDetails[0].contents", []), [course])

	return (
		<>
			<VideoMetter course={course} videos={videos} data={data} />
			<View style={{ width: "100%", flexDirection: "row", gap: 10, flexWrap: "wrap" }}>
				{videos?.map((el, i, arr) => {

					const lastDeadline = head(el.deadlines?.sort((a, b) => a.start - b.start))
					const videoAttendance = data?.find(x => x.id === lastDeadline?.id)

					return (
						<View key={i} style={{ flexBasis: isPhone ? "100%" : "32%", }}>
							<VideoCard
								el={el}
								key={i}
								attendance={videoAttendance}
								onPress={(ev) => {
									const path = "0"
									history.pushState({ content: i.toString(), material: undefined, resource: undefined }, "", `?path=${path}&content=${i.toString()}`)
									setContentSelected(el)
								}} />
						</View>
					)
				})}
			</View>
		</>
	)
}

const VideoMetter = ({ course, videos, data }) => {
	const completedActivities = (data || [])?.filter(x => x.checks?.filter(y => y.status).length >= 2)
	const timeMade = completedActivities?.reduce((acc, cur) => acc + cur.lastWatched, 0) || 0

	return (
		<View style={{ width: "100%", padding: 15, borderRadius: 7, backgroundColor: "white" }}>
			<StyledText style={{ fontSize: 22, fontFamily: "TitleWide" }}>{course.name}</StyledText>
			<div dangerouslySetInnerHTML={{ __html: course?.description || "<p></p>" }} style={{ fontFamily: "Regular", fontSize: 18, color: "#303030" }} />

			<View style={{ flexDirection: "row", gap: 10 }}>
				<View style={{ borderRadius: 7, borderWidth: 2, borderColor: "#f2f2f2", padding: 10, paddingHorizontal: 15, paddingVertical: 15, alignItems: "center" }}>
					<StyledText style={{ fontSize: 14, fontFamily: "TitleWide" }}>Videos</StyledText>
					<StyledText style={{ fontSize: 40, fontFamily: "Title" }}>{videos?.length || 0}</StyledText>
				</View>
				<View style={{ borderRadius: 7, borderWidth: 2, borderColor: "#f2f2f2", padding: 10, paddingHorizontal: 15, paddingVertical: 15, alignItems: "center" }}>
					<StyledText style={{ fontSize: 14, fontFamily: "TitleWide" }}>Horas Revaluadas</StyledText>
					<StyledText style={{ fontSize: 40, fontFamily: "Title" }}>{secondsToHHMMSS(timeMade)}</StyledText>
				</View>
			</View>
		</View>
	)

}

const VideoCard = ({ el, attendance, onPress }) => {

	const { data, isLoading } = useFirestoreDocument(["contentCard", el.id], el.ref, {}, {
		enabled: !!el?.ref?.id,
		select: d => ({ ...d.data(), id: d.id, ref: d.ref }),
		onError: e => console.log(e)
	});

	const lastWatched = attendance?.lastWatched || 0

	const checks = get(attendance, "checks", [])?.filter(x => x.status)?.length || 0


	return (
		<View style={[styles.shadow, { flex: 1, backgroundColor: "white", borderRadius: 10 }]}>
			<SecureImage uri={data?.image} style={{ width: "100%", aspectRatio: 1.77, borderRadius: 10 }} placeholder={"https://brandemia.org/sites/default/files/inline/images/03-rfef-imagenes-brandemia-blog_0.jpg"} />
			<View>
				<View style={{ flexDirection: "row", gap: 10, alignItems: "center", backgroundColor: "#f2f2f2", padding: 10 }}>
					<View style={{ flex: 1, gap: 5 }}>
						{/* <LinearBarIndicator useNativeDriver={true} percentage={percentage} fillColor={percentage < 33 ? "green" : percentage < 66 ? "orange" : "red"} width="100%" styles={undefined} /> */}
						<View style={{ width: "100%", flexDirection: "row", gap: 10, alignItems: "center" }}>
							{[1, 2, 3].map((el, i) => (
								<View key={i}
									style={{
										width: 20, height: 20, borderRadius: 10,
										backgroundColor: checks >= i + 1 ? "green" : "gray",
									}}
								/>
							))}
						</View>
						<StyledText>Visualizado: {secondsToHHMMSS(lastWatched)}</StyledText>
					</View>
					<Button label={attendance ? "Continuar" : "Comenzar"} color="#0b1831" round={7} height={35} onPress={() => onPress(data)} />
				</View>
				<View style={{ padding: 10 }}>
					<StyledText style={{ fontSize: 16, fontFamily: "TitleWide" }}>{el.title}</StyledText>
				</View>
			</View>
		</View>
	)
}




function secondsToHHMMSS(seconds) {
	const hours = Math.floor(seconds / 3600);
	const remainingSeconds = seconds % 3600;
	const minutes = Math.floor(remainingSeconds / 60);
	const remainingSecondsFinal = remainingSeconds % 60;
	return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${remainingSecondsFinal.toString().padStart(2, "0")}`;
}

const styles = StyleSheet.create({
	shadow: {
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 5,
		},
		shadowOpacity: 0.1,
		shadowRadius: 6.68,

		elevation: 11,
	},
});
