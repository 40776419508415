import { useRoute } from "@react-navigation/native";
import chroma from "chroma-js";
import { uniqBy } from "lodash";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { ScrollView, StyleSheet, TextInput, TouchableOpacity, View } from "react-native";
import { useQuery } from "react-query";
import { useMediaQuery } from "react-responsive";
import Button from "../../components/common/Button";
import FilterSelector from "../../components/common/FilterSelector";
import StyledText from "../../components/common/StyledText";
import CourseCardSquare from "../../components/publicComponents/CourseCardSquare";
import fixDate from "../../utils/fixDate";

const filters = [
	{
		name: "Todos",
	}, {
		name: "Fútbol",
		categories: [
			{
				name: "Todos",
			}, {
				name: "CURSO DE ENTRENADOR DE FÚTBOL UEFA PRO"
			}, {
				name: "CURSO DE ENTRENADOR DE FÚTBOL UEFA A"
			}, {
				name: "CURSO DE ENTRENADOR DE FÚTBOL UEFA B"
			}, {
				name: "CURSO DE ENTRENADOR DE FÚTBOL UEFA C"
			}, {
				name: "UEFA GRASSROOTS LEADER"
			}, {
				name: "UEFA GOALKEEPER A"
			}, {
				name: "UEFA GOALKEEPER B"
			}, {
				name: "ESPECIALISTA ENTRENAMIENTO PORTEROS FÚTBOL NACIONAL C"
			}
		]
	}, {
		name: "Futsal",
		categories: [
			{
				name: "FÚTBOL SALA NACIONAL PROFESIONAL"
			}, {
				name: "FÚTBOL SALA NACIONAL A"
			}, {
				name: "FÚTBOL SALA NACIONAL B"
			}, {
				name: "FÚTBOL SALA NACIONAL C"
			}, {
				name: "FÚTBOL SALA PORTEROS NACIONAL B"
			}, {
				name: "FÚTBOL SALA PORTEROS NACIONAL C"
			}
		]

	}, {
		name: "Fútbol Playa",
		categories: [
			{
				name: "ENTRENADOR DE FÚTBOL PLAYA NACIONAL B"
			}
		]
	}, {
		name: "Proceso de Solicitud",
		categories: [
			{
				name: "PROCESO SOLICITUD LICENCIA UEFA PRO"
			}, {
				name: "PROCESO SOLICITUD LICENCIA UEFA A"
			}, {
				name: "PROCESO SOLICITUD LICENCIA UEFA B"
			}, {
				name: "PROCESO SOLICITUD LICENCIA UEFA C"
			}, {
				name: "PROCESO SOLICITUD LICENCIA UEFA FUTSAL B"
			}, {
				name: "PROCESO SOLICITUD LICENCIA NACIONAL C FÚTBOL SALA"
			}, {
				name: "PROCESO DE SOLICITUD LICENCIA UEFA GOALKEEPER B"
			}
		]
	}, {
		name: "Reconocimiento de competencias UEFA",
		categories: [
			{
				name: "RECONOCIMIENTO DE COMPETENCIAS DE FORMACIÓN Y PRÁCTICAS DE MOVILIDAD DEPORTIVA INTERNACIONAL UEFA PRO"
			}, {
				name: "RECONOCIMIENTO DE COMPETENCIAS DE FORMACIÓN Y PRÁCTICAS DE MOVILIDAD DEPORTIVA INTERNACIONAL UEFA A "
			}, {
				name: "RECONOCIMIENTO DE COMPETENCIAS DE FORMACIÓN Y PRÁCTICAS DE MOVILIDAD DEPORTIVA INTERNACIONAL UEFA B"
			}, {
				name: "RECONOCIMIENTO DE COMPETENCIAS DE FORMACIÓN Y PRÁCTICAS DE MOVILIDAD DEPORTIVA INTERNACIONAL UEFA FUTSAL B"
			}
		]
	}, {
		name: "Master",
		categories: [
			{
				name: "MÁSTER DE RENDIMIENTO Y REEDUCACIÓN FUNCIONAL DEPORTIVA"
			}
		]
	}, {
		name: "Curso superior universitario",
		categories: [
			{
				name: "CURSO SUPERIOR UNIVERSITARIO DE DIRECTOR DEPORTIVO EN FUTBOL"
			}, {
				name: "CURSO SUPERIOR UNIVERSITARIO DE ANALISIS TACTICO, SCOUTING Y GESTION DE RECURSOS TECNOLOGICOS APLICADOS AL FUTBOL"
			}, {
				name: "CURSO SUPERIOR UNIVERSITARIO FIFA/CIES/RFEF/URJC DE GESTIÓN DEL DEPORTE"
			}, {
				name: "CURSO SUPERIOR UNIVERSITARIO DE ENTRENADOR DE FUTBOL, DIRECCION DE EQUIPO Y METODOLOGIA"
			}
		]
	}, {
		name: "Cursos E-Learning",
		categories: [
			{
				name: "CURSO AVANZADO DE INSTATSCOUT"
			}
		]
	}
]

const Embedded = () => {

	const isPhone = useMediaQuery({ query: '(max-width: 1000px)' })
	const params = useRoute().params
	const tenantId = params?.tenantId
	const view = params?.view

	const [modality, setModality] = useState("Todos")
	const [courseType, setCourseType] = useState("Todos")
	const [courseMonth, setCourseMonth] = useState("Todos")
	const [territorial, setTerritorial] = useState("Todos")
	const [search, setSearch] = useState("")

	const scale = chroma.scale(['#e4002b', '#8e051d']).colors(5);


	const { data } = useQuery({
		queryKey: ['courses'],
		queryFn: () => fetch('https://onformacion.comerciogo.workers.dev/courses').then((res) => res.json()),
		select: querySnapshot => querySnapshot?.filter(x => x?.tenantId === tenantId)
			.map(doc => {
				const initDate = fixDate(doc?.initDate)
				const endDate = fixDate(doc?.endDate)
				const preInitDate = fixDate(doc?.preInitDate)
				const preEndDate = fixDate(doc?.preEndDate)
				return {
					...doc,
					initDate,
					endDate,
					preInitDate,
					preEndDate,
				}
			})?.filter(x => x.preEndDate > moment().toDate()) || []
	})


	const courses = useMemo(() => (data || [])?.filter(el => (
		(el.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()) || el.city.toLocaleLowerCase().includes(search.toLocaleLowerCase()) || el.tag.toLocaleLowerCase().includes(search.toLocaleLowerCase())) &&
		(modality === "Todos" || el?.courseType?.toLocaleLowerCase() === modality?.toLocaleLowerCase()) &&
		(courseType === "Todos" || el?.courseCategory?.toLocaleLowerCase() === courseType?.toLocaleLowerCase()) &&
		(courseMonth === "Todos" || moment(courseMonth)?.toDate() < fixDate(el?.preInitDate)) &&
		(territorial === "Todos" || el.territorial === territorial))
	), [data, search, modality, courseType, courseMonth, territorial])

	if (!tenantId) return <></>
	return (
		<>
			<ScrollView style={{ flex: 1, backgroundColor: "white" }} contentContainerStyle={{ flexGrow: 1 }}>

				{view === "table" ?
					<View>
						<View style={{ backgroundColor: "#003349", paddingVertical: 20, paddingHorizontal: 25 }}>
							{/* <StyledText style={{ color: "white", fontSize: 20, fontWeight: "bold" }}>Cursos</StyledText> */}
							<TextInput value={search} onChangeText={(ev) => setSearch(ev)} style={{ maxWidth: 1200, width: "100%", alignSelf: "center", borderRadius: 5, backgroundColor: "white", paddingHorizontal: 15, alignItems: "center", height: 45 }} placeholder="Buscar..." />
						</View>
						<View style={{ flexDirection: isPhone ? "column" : "row", alignItems: "center", width: "100%", marginTop: 0, maxWidth: 1200, alignSelf: "center" }}>
							<FilterSelector
								options={["Todos", ...uniqBy(data, "courseType")?.map(x => x?.courseType)]}
								title={"Modalidad"}
								onChange={(value) => setModality(value)}
								background={scale[0]}
								searchable={true}
								value={modality}
							/>
							<FilterSelector
								options={["Todos", ...uniqBy(data, "courseCategory")?.map(x => x?.courseCategory)]}
								title={"Tipo de Curso"}
								onChange={(value) => setCourseType(value)}
								background={scale[1]}
								searchable={true}
								value={courseType}
							/>
							<FilterSelector
								options={
									[{ name: "Todos" }, ...new Array(12).fill(0).map((el, i) => ({ name: moment().add(i, "months").format("MMMM YYYY") }))]
								}
								title={"Inicio del Curso"}
								labelKey="name"
								valueKey="name"
								onChange={(value) => setCourseMonth(value)}
								background={scale[2]}
								searchable={true}
								value={courseMonth}
							/>
						</View>
						<View style={{ overflow: "scroll", maxWidth: 1200, width: "100%", alignSelf: "center" }}>
							<View style={{ flexDirection: "row", borderLeftWidth: 1, borderColor: "gainsboro" }}>
								<View style={{ minWidth: 200, flex: 2, padding: 15, borderRightWidth: 1, borderBottomWidth: 1, borderColor: "gainsboro" }}>
									<StyledText>Nombre del Curso</StyledText>
								</View>
								<View style={{ minWidth: 120, flex: 1, padding: 15, borderRightWidth: 1, borderBottomWidth: 1, borderColor: "gainsboro" }}>
									<StyledText>Localidad</StyledText>
								</View>
								<View style={{ minWidth: 150, flex: .7, padding: 15, borderRightWidth: 1, borderBottomWidth: 1, borderColor: "gainsboro" }}>
									<StyledText style={{ textAlign: "center", }}>Inscripción</StyledText>
								</View>
								<View style={{ minWidth: 120, flex: .7, padding: 15, borderRightWidth: 1, borderBottomWidth: 1, borderColor: "gainsboro" }}>
									<StyledText numberOfLines={1} style={{ textAlign: "center", }}>Inicio del Programa</StyledText>
								</View>
								<View style={{ minWidth: 120, flex: .5, padding: 15, borderRightWidth: 1, borderBottomWidth: 1, borderColor: "gainsboro" }}>
									<StyledText style={{ textAlign: "center", }}>Precio</StyledText>
								</View>
								<View style={{ minWidth: 150, flex: 1, padding: 15, borderRightWidth: 1, borderBottomWidth: 1, borderColor: "gainsboro" }}>
									{/* <StyledText>Precio</StyledText> */}
								</View>
							</View>

							{courses?.length === 0 ?
								<View style={{ flex: 1, paddingHorizontal: 15, minHeight: 200, alignItems: "center", justifyContent: "center", backgroundColor: 'white' }} >
									<StyledText style={{ fontSize: 20, textAlign: "center", color: "black", opacity: .2 }}>No hay cursos que coincidan con tu búsqueda</StyledText>
								</View>
								:
								courses?.map((el, i) =>
									<TouchableOpacity key={i} onPress={() => window.open(`https://onformacion.rfef.es/fed/${tenantId}/curso/${el.id}`, "_blank")}>
										<View style={{ flexDirection: "row", alignItems: "center", width: "100%", borderBottomWidth: 1, borderColor: "#e5e5e5", borderLeftWidth: 1 }}>
											<View style={{ minWidth: 200, flex: 2, padding: 15, borderRightWidth: 1, borderBottomWidth: 1, borderColor: "gainsboro", height: "100%", justifyContent: "center" }}>
												<StyledText style={{ fontSize: 16, fontFamily: "DemiBold", color: "black" }}>{el?.name}</StyledText>
												<StyledText numberOfLines={1} style={{ fontSize: 14, color: "gray" }}>{el?.courseType} | {el?.courseCategory}</StyledText>
											</View>
											<View style={{ minWidth: 120, flex: 1, padding: 15, borderRightWidth: 1, borderBottomWidth: 1, borderColor: "gainsboro", height: "100%", justifyContent: "center" }}>
												<StyledText style={{ fontSize: 14, color: "black" }}>{el?.city}</StyledText>
											</View>
											<View style={{ minWidth: 150, flex: .7, padding: 15, borderRightWidth: 1, borderBottomWidth: 1, borderColor: "gainsboro", height: "100%", justifyContent: "center" }}>
												<StyledText style={{ fontSize: 14, color: "black" }}>Inicio: {moment(fixDate(el?.preInitDate))?.format("DD/MM/YYYY")}</StyledText>
												<StyledText style={{ fontSize: 14, color: "black" }}>Final: {moment(fixDate(el?.preEndDate))?.format("DD/MM/YYYY")}</StyledText>
											</View>
											<View style={{ minWidth: 120, flex: .7, padding: 15, borderRightWidth: 1, borderBottomWidth: 1, borderColor: "gainsboro", height: "100%", justifyContent: "center" }}>
												<StyledText style={{ fontSize: 14, textAlign: "center", color: "black" }}>{moment(fixDate(el?.initDate))?.format("DD/MM/YYYY")}</StyledText>
											</View>
											<View style={{ minWidth: 120, flex: .5, padding: 15, borderRightWidth: 1, borderBottomWidth: 1, borderColor: "gainsboro", height: "100%", justifyContent: "center" }}>
												<StyledText style={{ fontSize: 16, textAlign: "center", fontFamily: "DemiBold", color: "black" }}>{el?.programFee}€</StyledText>
											</View>
											<View style={{ minWidth: 150, flex: 1, padding: 15, borderRightWidth: 1, borderBottomWidth: 1, borderColor: "gainsboro", height: "100%", justifyContent: "center" }}>
												<Button height={35} round={5} label={"Información"} onPress={() => window.open(`https://onformacion.rfef.es/fed/${tenantId}/curso/${el.id}`, "_blank")} />
											</View>
										</View>
									</TouchableOpacity>)}
						</View>

					</View>
					:
					<>
						<View style={{ flexDirection: "row", alignItems: "center", width: "100%", paddingTop: 0, alignSelf: "center" }}>
							<View style={{ flexDirection: "row", backgroundColor: "#003349", width: "100%", paddingTop: 50, paddingHorizontal: 30, paddingBottom: 80 }}>
								{/* <View style={{ flex: 1 }}>
							<StyledText style={{ fontFamily: "Title", textAlign: "center", fontSize: 60, color: "white" }}>Formación RFEF</StyledText>
						</View> */}
							</View>
						</View>
						<View style={{ flexDirection: isPhone ? "column" : "row", alignItems: "center", width: "100%", marginTop: -40, maxWidth: 1200, alignSelf: "center" }}>
							<FilterSelector
								options={filters}
								labelKey="name"
								valueKey="name"
								title={"Modalidad"}
								onChange={(value) => setModality(value)}
								background={scale[0]}
								searchable={true}
								value={modality}
							/>
							<FilterSelector
								options={modality === "Todos" ? filters.reduce((acc, el) => [...acc, ...(el?.categories || [])], []) : modality !== "Todos" ? filters.find(el => el.name === modality).categories : []}
								title={"Tipo de Curso"}
								labelKey="name"
								valueKey="name"
								onChange={(value) => setCourseType(value)}
								background={scale[1]}
								searchable={true}
								value={courseType}
							/>
							<FilterSelector
								options={
									[{ name: "Todos" }, ...new Array(12).fill(0).map((el, i) => ({ name: moment().add(i, "months").format("MMMM YYYY") }))]
								}
								title={"Inicio del Curso"}
								labelKey="name"
								valueKey="name"
								onChange={(value) => setCourseMonth(value)}
								background={scale[2]}
								searchable={true}
								value={courseMonth}
							/>
						</View>
						{/* FIN SECCION DE CURSOS DESTACADOS */}


						<View style={{ flexGrow: 1 }}>
							<View style={{ paddingVertical: 15, marginTop: 10, flexWrap: "wrap", flexDirection: "row" }}>
								{courses?.length === 0 ?
									<View style={{ flex: 1, paddingHorizontal: 15, minHeight: 200, alignItems: "center", justifyContent: "center", backgroundColor: 'white' }} >
										<StyledText style={{ fontSize: 20, textAlign: "center", color: "black", opacity: .2 }}>No hay cursos que coincidan con tu búsqueda</StyledText>
									</View>
									:
									courses?.map((el, i) => <CourseCardSquare key={i} onPress={() => window.open(`${window.location.host}/fed/${tenantId}/curso/${el.id}`, "_blank")} data={el} />)}
							</View>
						</View>
					</>}





			</ScrollView>
		</>
	);
};

export default Embedded;

const styles = StyleSheet.create({
	wrapper: {},
	slide1: {
		flex: 1,
	},
	slide2: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "#97CAE5",
	},
	slide3: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "#92BBD9",
	},
	text: {
		color: "#fff",
		fontSize: 30,
		fontWeight: "bold",
	},
	shadow: {
		shadowColor: "rgba(0, 0, 0, 1)",
		shadowOffset: { width: 0, height: 7 },
		shadowOpacity: 0.1,
		shadowRadius: 10,
		elevation: 10,
	},
});
