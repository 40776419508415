import React, { useMemo } from 'react';

import { DefaultColorThemePalette, DefaultMainMenu, DefaultMainMenuContent, TLComponents, TLUiOverrides, Tldraw, TldrawUiMenuGroup, TldrawUiMenuItem, getSvgAsImage, toolbarItem, useEditor } from '@tldraw/tldraw';

import '@tldraw/tldraw/tldraw.css';

import { ActivityIndicator } from 'react-native';
import { ArrowShapeTool } from '../../components/board/customArrow/ArrowShapeTool';
import { ArrowShapeUtil } from '../../components/board/customArrow/ArrowShapeUtil';
import FieldSelector from '../../components/board/menus/FieldSelector';
import ItemSelector from '../../components/board/menus/ItemSelector';

DefaultColorThemePalette.lightMode.grey.solid = 'white'


export default function ({ style = {}, initialData, saveCallback, closeCallback, id }) {

    const [field, setField] = React.useState<any>(false)
    const [items, setItems] = React.useState(false)
    const [loading, setLoading] = React.useState(false)


    const uiOverrides: TLUiOverrides = useMemo(() => ({
        tools(editor, tools) {

            tools.field = {
                id: 'field',
                icon: "pack",
                label: 'Field' as any,
                kbd: 'f',
                readonlyOk: field,

                onSelect: () => {
                    if (field) {
                        setField(false)
                        setItems(false)
                        editor.setCurrentTool("select")
                    } else {
                        setField(true)
                        setItems(false)
                        // editor.setSelectedTool("field")
                    }
                },
            }

            tools.element = {
                id: 'element',
                icon: "avatar",
                label: 'Element' as any,
                kbd: 'i',
                readonlyOk: false,
                onSelect: () => {
                    if (items) {
                        setItems(false)
                        setField(false)
                        editor.setCurrentTool("select")
                    } else {
                        setItems(true)
                        setField(false)
                        // editor.setSelectedTool("element")
                    }
                }
            }

            tools.customarrow = {
                id: "customarrow",
                icon: "chevron-right",
                label: "Conducción",
                kbd: "",
                readonlyOk: true,
                onSelect: () => {
                    editor.setCurrentTool("customarrow");
                }
            };

            return tools
        },
        toolbar(_app, toolbar, { tools }) {
            //BOTTOM TOOLBAR
            toolbar.splice(4, 0, toolbarItem(tools.field))

            toolbar.splice(5, 0, toolbarItem(tools.element))

            toolbar.splice(7, 0, toolbarItem(tools.customarrow))

            return toolbar
        },


    }), [initialData])



    function CustomMainMenu() {
        const editor = useEditor()

        return (
            <DefaultMainMenu>
                <TldrawUiMenuGroup id="example">
                    <TldrawUiMenuItem
                        id="closeItem"
                        label="Salir"
                        icon="exit"
                        readonlyOk
                        onSelect={() => {
                            closeCallback()
                        }}
                    />
                    <TldrawUiMenuItem
                        id="save"
                        label="Guardar"
                        icon="save"
                        readonlyOk
                        onSelect={async (source) => {
                            setLoading(true)

                            const editorData = editor.store.getSnapshot()
                            const shapes = editor.getCurrentPageShapeIds()

                            const ids = [...shapes]
                            const svg = await editor.getSvg(ids, {
                                scale: 1,
                                background: false,
                            })
                            if (!svg) {

                                alert("La pizarra está en blanco, no hay nada que guardar")
                                setLoading(false)
                                return
                            }
                            const dataURL = await getSvgAsImage(svg, false, { type: "png", quality: 1, scale: 1 })

                            saveCallback({ snap: JSON.stringify(editorData), svg: dataURL })
                            setLoading(false)
                        }}
                    />
                </TldrawUiMenuGroup>
                <DefaultMainMenuContent />
            </DefaultMainMenu>
        )
    }

    const components: TLComponents = {
        MainMenu: CustomMainMenu,
    }

    const customShapeUtils = [ArrowShapeUtil];
    const customTools = [ArrowShapeTool];

    return (
        <>
            {loading ?
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", position: 'fixed', zIndex: 100, background: "rgba(0,0,0,.5)", inset: 0, top: 0, }}>
                    <ActivityIndicator />
                </div>
                : null
            }
            <div className="tldraw__editor" style={{ position: 'fixed', inset: 0, ...style }} >
                <Tldraw
                    autoFocus
                    overrides={uiOverrides}
                    shapeUtils={customShapeUtils}
                    tools={customTools}
                    snapshot={initialData ? initialData : undefined}
                    persistenceKey={id === "new" ? undefined : id}
                    components={components}
                >
                    <FieldSelector fields={data.fields} visible={field} closeCallback={() => setField(false)} />
                    <ItemSelector elements={data.items} visible={items} closeCallback={() => setItems(false)} />

                </Tldraw>

            </div>
        </>
    )
}







const data = {
    items: [
        {
            id: "balls",
            name: "Balones",
            items: [
                {
                    id: "1",
                    name: "ball",
                    description: "Balon 1",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fball.png?alt=media&token=30d1e0fb-7d28-40f3-8347-ae9db01ad4e3",
                    width: 30,
                    height: 30,
                },
                {
                    id: "2",
                    name: "ball",
                    description: "Balon 2",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fball2.png?alt=media&token=3527ce33-1e7a-4d6b-835e-2be47450e146",
                    width: 30,
                    height: 30,
                },
                {
                    id: "3",
                    name: "ball",
                    description: "Balon 3",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fball3.png?alt=media&token=a5e4f47f-f025-47b1-8cbb-e42fedc5560d",
                    width: 30,
                    height: 30,
                },
                {
                    id: "4",
                    name: "ball",
                    description: "Balon Sala",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fball4.png?alt=media&token=9faa4497-5f2b-404c-a34c-c879814d7bf9",
                    width: 30,
                    height: 30,
                },
                {
                    id: "5",
                    name: "ball",
                    description: "Balon Sala 2",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fball5.png?alt=media&token=7121eafa-b846-4d88-aa15-c4a4ef66f619",
                    width: 30,
                    height: 30,
                }
            ]
        },
        {
            id: "goal",
            name: "Porterías",
            items: [
                {
                    id: "1",
                    name: "ball",
                    description: "Portería",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fgoal.png?alt=media&token=8df94eac-2e35-44ec-9e04-b605df61cd3a",
                    width: 240,
                    height: 96,
                },
                {
                    id: "1",
                    name: "ball",
                    description: "Portería 2",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fgoal2.png?alt=media&token=a65ddd86-3b99-4477-9314-ed37d10f19e1",
                    width: 200,
                    height: 120,
                },
                {
                    id: "1",
                    name: "ball",
                    description: "Portería Sala",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fgoal4.png?alt=media&token=8bd447cc-525d-4215-9e15-bb55d3c619da",
                    width: 200,
                    height: 120,
                },
                {
                    id: "1",
                    name: "ball",
                    description: "Mini Portería",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fgoalmini.png?alt=media&token=f91173d1-0e3e-4bb4-83b9-279f0997f5da",
                    width: 125,
                    height: 75,
                }
            ]
        },
        {
            id: "senalizacion",
            name: "Accesorios",
            items: [
                {
                    id: "1",
                    name: "ball",
                    description: "Obstaculo 1",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fhurdle1.png?alt=media&token=7d11d145-1607-4dd2-b44e-83ba7e634d0f",
                    width: 80,
                    height: 80,
                },
                {
                    id: "1",
                    name: "ball",
                    description: "Obstaculo 2",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fhurdle2.png?alt=media&token=d758a0ef-ff00-42ae-825f-c00e9a84ac82",
                    width: 80,
                    height: 80,
                },
                {
                    id: "1",
                    name: "ball",
                    description: "Obstaculo 3",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fhurdle3.png?alt=media&token=9280d6c9-147d-4f41-a03a-62884c640b92",
                    width: 80,
                    height: 80,
                },
                {
                    id: "1",
                    name: "ball",
                    description: "Barrier",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fbarrier.png?alt=media&token=52b3db8c-8ef0-40fa-9268-389da0d92abe",
                    width: 142,
                    height: 85,
                },
                {
                    id: "1",
                    name: "ball",
                    description: "Single Barrier",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fsinglebarrier.png?alt=media&token=26c7cf8e-ece1-4b57-8aad-9dcc8dcf75c3",
                    width: 85,
                    height: 85,
                },
                {
                    id: "1",
                    name: "ball",
                    description: "Cone",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fcone.png?alt=media&token=510181d9-dd16-472d-b827-f7a627141e9a",
                    width: 70,
                    height: 70,
                },
                {
                    id: "1",
                    name: "ball",
                    description: "Striped Cone",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fconestripe.png?alt=media&token=40aea0b9-d383-4511-af6b-acd5aeedbd02",
                    width: 70,
                    height: 70,
                },
                {
                    id: "1",
                    name: "ball",
                    description: "Bar",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fbar.png?alt=media&token=d3142664-d823-43a4-9c6c-209d4cf0d0e8",
                    width: 70,
                    height: 70,
                },
                {
                    id: "1",
                    name: "ball",
                    description: "Disc Red",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fdiscred.png?alt=media&token=dc677c28-a4f8-4405-bb60-53a0015f29e1",
                    width: 25,
                    height: 25,
                },
                {
                    id: "1",
                    name: "ball",
                    description: "Disc Yellow",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fdiscorange.png?alt=media&token=c9a11014-669d-4fff-93bf-12cd271e210a",
                    width: 25,
                    height: 25,
                },
                {
                    id: "1",
                    name: "ball",
                    description: "Disc Blue",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fdiscblue.png?alt=media&token=b4a978ea-713c-4642-bd56-f19efeb60014",
                    width: 25,
                    height: 25,
                },
                {
                    id: "1",
                    name: "ball",
                    description: "Disc Black",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fdiscblack.png?alt=media&token=ad753953-3174-4178-8f7b-197c5b5986b7",
                    width: 25,
                    height: 25,
                },
                {
                    id: "1",
                    name: "ball",
                    description: "Disc Black",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fagilityladder.svg?alt=media&token=447f81bb-937d-4992-9ffd-e4e9cbbc1526",
                    width: 200,
                    height: 40,
                },
                {
                    id: "1",
                    name: "ball",
                    description: "Disc Black",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fhalfbalanceball.png?alt=media&token=07db05a1-facd-4164-bd1a-aa48f1ae932c",
                    width: 60,
                    height: 60,
                },
            ]
        },
        {
            id: "senalizacion",
            name: "Jugadores",
            items: [
                {
                    id: "1",
                    name: "ball",
                    description: "Pointer Blue",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fpointerblue.svg?alt=media&token=b30ac92d-59c0-4684-ad54-00942fd4da7c",
                    width: 70,
                    height: 70,
                },
                {
                    id: "1",
                    name: "ball",
                    description: "Pointer Red",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fpointerred.svg?alt=media&token=f60a05fa-7e9f-4b40-9bd5-1b7a9475341c",
                    width: 70,
                    height: 70,
                },
                {
                    id: "1",
                    name: "ball",
                    description: "Pointer Green",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fpointergreen.svg?alt=media&token=6a483309-550c-4dc4-a87f-fb2ba04f26d7",
                    width: 70,
                    height: 70,
                },
                {
                    id: "1",
                    name: "ball",
                    description: "Pointer Black",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fpointerblack.svg?alt=media&token=259bdeec-b7b7-4271-a0a5-4b909e0833e3",
                    width: 70,
                    height: 70,
                },
                {
                    id: "1",
                    name: "ball",
                    description: "Player Blue",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fplayerblue.svg?alt=media&token=bb6be778-d901-420f-b6a2-d11ed4abfd3c",
                    width: 70,
                    height: 70,
                },
                {
                    id: "1",
                    name: "ball",
                    description: "Player Red",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fplayerred.svg?alt=media&token=2bdd7cea-69ab-4825-8085-315bef38ca2c",
                    width: 70,
                    height: 70,
                },
                {
                    id: "1",
                    name: "ball",
                    description: "Player Green",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fplayergreen.svg?alt=media&token=dadfef9f-5f97-47c0-a481-76545b9f3603",
                    width: 70,
                    height: 70,
                },
                {
                    id: "1",
                    name: "ball",
                    description: "Player Black",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fplayerblack.svg?alt=media&token=c3b11a9c-1ee8-46e2-8f4a-9df62562fe58",
                    width: 70,
                    height: 70,
                },
                {
                    id: "1",
                    name: "ball",
                    description: "Chip Blue",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fchipblue.svg?alt=media&token=04507ba1-dd5b-47c8-ad8c-faa863fe8f89",
                    width: 70,
                    height: 70,
                },
                {
                    id: "1",
                    name: "ball",
                    description: "Chip Red",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fchipred.svg?alt=media&token=ec7aa318-3896-47d1-b978-e9813eadea32",
                    width: 70,
                    height: 70,
                },
                {
                    id: "1",
                    name: "ball",
                    description: "Chip Green",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fchipgreen.svg?alt=media&token=23598b42-0da4-463a-8820-a447b5c47733",
                    width: 70,
                    height: 70,
                },
                {
                    id: "1",
                    name: "ball",
                    description: "Chip Black",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fchipblack.svg?alt=media&token=f2d8aa3b-6edb-4fb6-bc35-640877e71956",
                    width: 70,
                    height: 70,
                },
                {
                    id: "1",
                    name: "ball",
                    description: "Jugador Esp",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fspainjersey.svg?alt=media&token=3022e6c9-b11a-499c-8a74-095dd93c50c3",
                    width: 70,
                    height: 70,
                },
                {
                    id: "1",
                    name: "ball",
                    description: "Obstaculo 2",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fblackjersey.svg?alt=media&token=386175f4-68b5-4ca9-8833-3e1e7f649e0a",
                    width: 70,
                    height: 70,
                },
                {
                    id: "1",
                    name: "ball",
                    description: "Obstaculo 3",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Forangejersey.svg?alt=media&token=8a41cc33-920d-43fa-ab7b-bc5e2bf9a3cb",
                    width: 70,
                    height: 70,
                },
                {
                    id: "1",
                    name: "ball",
                    description: "Barrier",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fyellowjersey.svg?alt=media&token=cd294ff3-ef71-4cad-a5d2-c35cfe39f327",
                    width: 70,
                    height: 70,
                },
                {
                    id: "1",
                    name: "ball",
                    description: "Single Barrier",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fredjersey.svg?alt=media&token=2a42f1a8-7f5f-4fff-ac9e-3faf933ef21f",
                    width: 70,
                    height: 70,
                },
                {
                    id: "1",
                    name: "ball",
                    description: "Single Barrier",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fwhitejersey.svg?alt=media&token=46a6ee58-cd3a-4588-9c3b-c3b1142693d3",
                    width: 70,
                    height: 70,
                },
                {
                    id: "1",
                    name: "ball",
                    description: "Single Barrier",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fbluejersey.svg?alt=media&token=d0203a81-074c-4fe4-9004-4d7afcc9d939",
                    width: 70,
                    height: 70,
                },
                {
                    id: "1",
                    name: "ball",
                    description: "Stripe Jersey",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fstripejersey.svg?alt=media&token=b25b0b15-27d2-4acd-8799-6ed45bf3cb3f",
                    width: 70,
                    height: 70,
                }
            ]
        }
    ],
    fields: [
        {
            id: "generic",
            name: "Genéricos",
            items: [
                {
                    id: "1",
                    name: "Cuatro Areas",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2F4areas.svg?alt=media&token=86a43014-2419-478c-b37a-a41c458f3f59",
                    width: 1200,
                    height: 1200,
                },
            ]
        },
        {
            id: "soccer",
            name: "Fútbol 11",
            items: [
                {
                    id: "1",
                    name: "Campo Completo Franjas",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fsoccerstripes.svg?alt=media&token=256c8311-f056-41dc-bbd4-07ae0872dbd7",
                    width: 1920,
                    height: 1200,
                },
                {
                    id: "1",
                    name: "Campo Completo Plano",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fsoccerplain.svg?alt=media&token=c1888199-51cb-48ba-a38a-b836931f0bcd",
                    width: 1920,
                    height: 1200,
                },
                {
                    id: "1",
                    name: "Campo Completo Negro",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fsoccerblack.svg?alt=media&token=f3a152e7-57c2-45dc-aa27-d4dff73545d3",
                    width: 1920,
                    height: 1200,
                },
                {
                    id: "1",
                    name: "Medio Campo",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fhalfsoccerplain.svg?alt=media&token=94713dae-fbe4-4182-b297-f09bc1696a8f",
                    width: 1200,
                    height: 1200,
                },
            ]
        },
        {
            id: "sala",
            name: "Fútbol Sala",
            items: [
                {
                    id: "1",
                    name: "Futbol Sala",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fsala.svg?alt=media&token=eeacc887-d7fe-4125-a25c-52a2841798a4",
                    width: 1920,
                    height: 1200,
                },
                {
                    id: "2",
                    name: "Futbol Sala Gris",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2FFSalaGris.svg?alt=media&token=28845e1c-e2a2-4166-a9a9-415841b02869",
                    width: 1920,
                    height: 1200,
                },
            ]
        },
        {
            id: "beach",
            name: "Fútbol Playa",
            items: [
                {
                    id: "1",
                    name: "Futbol Sala",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fbeach.svg?alt=media&token=172ada6f-b87a-476c-8ce8-31017de69f5e",
                    width: 1920,
                    height: 1200,
                },
            ]
        }



    ],
    layouts: []
}