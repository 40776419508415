import { Ionicons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { orderBy } from "lodash";
import React, { useContext, useMemo } from "react";
import { Image, ScrollView, TouchableOpacity, View } from "react-native";
import { useQuery } from "react-query";
import { useMediaQuery } from "react-responsive";
import Button from "../../components/common/Button";
import Footer from "../../components/common/Footer";
import StyledText from "../../components/common/StyledText";
import CourseCardSquare from "../../components/publicComponents/CourseCardSquare";
import RowCourse from "../../components/publicComponents/RowCourse";
import AuthContext from "../../context/AuthContext";
import fixDate from "../../utils/fixDate";


const filters = [
	{
		name: "Todos",
	}, {
		name: "Fútbol",
		categories: [
			{
				name: "Todos",
			}, {
				name: "CURSO DE ENTRENADOR DE FÚTBOL UEFA PRO"
			}, {
				name: "CURSO DE ENTRENADOR DE FÚTBOL UEFA A"
			}, {
				name: "CURSO DE ENTRENADOR DE FÚTBOL UEFA B"
			}, {
				name: "CURSO DE ENTRENADOR DE FÚTBOL UEFA C"
			}, {
				name: "UEFA GRASSROOTS LEADER"
			}, {
				name: "UEFA GOALKEEPER A"
			}, {
				name: "UEFA GOALKEEPER B"
			}, {
				name: "ESPECIALISTA ENTRENAMIENTO PORTEROS FÚTBOL NACIONAL C"
			}
		]
	}, {
		name: "Futsal",
		categories: [
			{
				name: "FÚTBOL SALA NACIONAL PROFESIONAL"
			}, {
				name: "FÚTBOL SALA NACIONAL A"
			}, {
				name: "FÚTBOL SALA NACIONAL B"
			}, {
				name: "FÚTBOL SALA NACIONAL C"
			}, {
				name: "FÚTBOL SALA PORTEROS NACIONAL B"
			}, {
				name: "FÚTBOL SALA PORTEROS NACIONAL C"
			}
		]

	}, {
		name: "Fútbol Playa",
		categories: [
			{
				name: "ENTRENADOR DE FÚTBOL PLAYA NACIONAL B"
			}
		]
	}, {
		name: "Proceso de Solicitud",
		categories: [
			{
				name: "PROCESO SOLICITUD LICENCIA UEFA PRO"
			}, {
				name: "PROCESO SOLICITUD LICENCIA UEFA A"
			}, {
				name: "PROCESO SOLICITUD LICENCIA UEFA B"
			}, {
				name: "PROCESO SOLICITUD LICENCIA UEFA C"
			}, {
				name: "PROCESO SOLICITUD LICENCIA UEFA FUTSAL B"
			}, {
				name: "PROCESO SOLICITUD LICENCIA NACIONAL C FÚTBOL SALA"
			}, {
				name: "PROCESO DE SOLICITUD LICENCIA UEFA GOALKEEPER B"
			}
		]
	}, {
		name: "Reconocimiento de competencias UEFA",
		categories: [
			{
				name: "RECONOCIMIENTO DE COMPETENCIAS DE FORMACIÓN Y PRÁCTICAS DE MOVILIDAD DEPORTIVA INTERNACIONAL UEFA PRO"
			}, {
				name: "RECONOCIMIENTO DE COMPETENCIAS DE FORMACIÓN Y PRÁCTICAS DE MOVILIDAD DEPORTIVA INTERNACIONAL UEFA A "
			}, {
				name: "RECONOCIMIENTO DE COMPETENCIAS DE FORMACIÓN Y PRÁCTICAS DE MOVILIDAD DEPORTIVA INTERNACIONAL UEFA B"
			}, {
				name: "RECONOCIMIENTO DE COMPETENCIAS DE FORMACIÓN Y PRÁCTICAS DE MOVILIDAD DEPORTIVA INTERNACIONAL UEFA FUTSAL B"
			}
		]
	}, {
		name: "Master",
		categories: [
			{
				name: "MÁSTER DE RENDIMIENTO Y REEDUCACIÓN FUNCIONAL DEPORTIVA"
			}
		]
	}, {
		name: "Curso superior universitario",
		categories: [
			{
				name: "CURSO SUPERIOR UNIVERSITARIO DE DIRECTOR DEPORTIVO EN FUTBOL"
			}, {
				name: "CURSO SUPERIOR UNIVERSITARIO DE ANALISIS TACTICO, SCOUTING Y GESTION DE RECURSOS TECNOLOGICOS APLICADOS AL FUTBOL"
			}, {
				name: "CURSO SUPERIOR UNIVERSITARIO FIFA/CIES/RFEF/URJC DE GESTIÓN DEL DEPORTE"
			}, {
				name: "CURSO SUPERIOR UNIVERSITARIO DE ENTRENADOR DE FUTBOL, DIRECCION DE EQUIPO Y METODOLOGIA"
			}
		]
	}, {
		name: "Cursos E-Learning",
		categories: [
			{
				name: "CURSO AVANZADO DE INSTATSCOUT"
			}
		]
	}
]



const HomeScreen = () => {

	const { customization } = useContext(AuthContext)
	const navigation = useNavigation()

	const isPhone = useMediaQuery({ query: '(max-width: 1000px)' })


	const { isLoading, error, data } = useQuery({
		queryKey: ['courses'],
		queryFn: () => fetch('https://onformacion.comerciogo.workers.dev/courses').then((res) => res.json()),
		select: querySnapshot => querySnapshot?.map(doc => {
			const initDate = fixDate(doc?.initDate)
			const endDate = fixDate(doc?.endDate)
			const preInitDate = fixDate(doc?.preInitDate)
			const preEndDate = fixDate(doc?.preEndDate)
			return {
				...doc,
				initDate,
				endDate,
				preEndDate,
				preInitDate
			}
		}) || []
	})


	const filteredCourses = useMemo(() => orderBy((data || [])?.filter(x => x?.preEndDate >= new Date()), "preEndDate", "asc").slice(0, 13), [data])
	const featuredCourses = useMemo(() => orderBy((data || [])?.filter(x => x.featured)?.filter(x => x?.preEndDate >= new Date()), "preEndDate", "asc")?.slice(0, 3), [data])
	const topCourses = [...featuredCourses, ...filteredCourses].slice(0, 3)


	return (

		<View style={{ flex: 1, backgroundColor: "white", zIndex: 2 }}>
			<ScrollView>
				{/* SLIDER TOP */}
				<View style={{ height: isPhone ? 200 : 270, borderBottomWidth: 0, borderBottomColor: "white", backgroundColor: customization.mainColor }}>
					<View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
						<Image style={{ height: 170, width: isPhone ? "85%" : "50%", resizeMode: "contain" }} source={require("../../assets/images/logoon.png")} />
					</View>
				</View>
				{/* END SLIDER TOP */}

				<View style={{ height: 10, width: "100%", backgroundColor: "#003349" }} />

				<View style={{ flexDirection: isPhone ? "column" : "row", flexWrap: "wrap" }}>
					<View style={{ flex: isPhone ? null : 1, maxWidth: isPhone ? undefined : "25%", aspectRatio: isPhone ? undefined : 1, backgroundColor: "#e4002b", justifyContent: isPhone ? null : "flex-end", padding: 15, paddingBottom: isPhone ? undefined : 45, flexDirection: isPhone ? "row" : "column" }}>
						<View style={{ flex: isPhone ? undefined : 1, padding: isPhone ? 0 : 20, marginRight: isPhone ? 20 : 0 }}>
							<Ionicons name={"calendar-outline"} size={isPhone ? 50 : 60} color="white" />
						</View>
						<View>
							<View>
								<StyledText style={{ fontSize: 20, fontFamily: "Title", color: "#f9bb2c" }}>Próximos</StyledText>
								<StyledText style={{ color: "white", fontFamily: "Bold", fontSize: 24 }} numberOfLines={4}>Programas destacados</StyledText>
							</View>
						</View>
					</View>
					{topCourses?.length === 0 ? <View /> : topCourses.map((el, i) => <CourseCardSquare key={i} onPress={() => navigation.navigate("PreviewCourseScreen", { tenantId: el.tenantId, id: el.id })} data={el} />)}
				</View>
				{/* SECCION DE CURSOS DESTACADOS */}


				<View style={{ marginTop: 50, flexDirection: "row", alignItems: "center", width: "100%", paddingTop: 0, alignSelf: "center" }}>
					<View style={{ flexDirection: "row", backgroundColor: "#061934", width: "100%", paddingTop: 50, paddingHorizontal: 30, paddingBottom: 50 }}>
						<View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
							<StyledText style={{ fontFamily: "Title", fontSize: 50, color: "white" }}>Próximos Programas</StyledText>
							<StyledText style={{ fontSize: 16, color: "white" }}>Programas en finalizar el preiodo de preinscripción</StyledText>
							<View style={{ flexDirection: isPhone ? "column" : "row", alignItems: "center", justifyContent: "center", padding: 10, marginTop: 25, gap: 10 }}>
								<Button
									round={7}
									height={45}
									label="Programas RFEF"
									width={isPhone ? "100%" : undefined}
									onPress={() => navigation.navigate("RfefPrograms")}
								/>
								<Button
									round={7}
									height={45}
									color="white"
									label="Programas Territoriales"
									width={isPhone ? "100%" : undefined}
									onPress={() => navigation.navigate("CoachesSchool")}
								/>
							</View>
						</View>
					</View>
				</View>
				{/* OTROS CURSOS */}

				<View style={{}}>
					{filteredCourses?.length === 0 ?
						<View style={{ flex: 1, paddingHorizontal: 15, minHeight: 200, alignItems: "center", justifyContent: "center", backgroundColor: 'white' }} >
							<StyledText style={{ fontSize: 20, textAlign: "center", color: "black", opacity: .2 }}>No hay cursos que coincidan con tu búsqueda</StyledText>
						</View>
						:
						<View style={{ width: "100%", flexGrow: 1, overflow: "auto", borderBottomWidth: 2, borderBottomColor: "#f3f3f3" }}>
							<View style={{ borderTopWidth: 2, borderTopColor: "gainsboro", width: "100%", flexDirection: "row", borderLeftWidth: 1, borderColor: "gainsboro" }}>
								<View style={{ minWidth: 200, flex: 2, padding: 15, borderRightWidth: 1, borderBottomWidth: 1, borderColor: "gainsboro" }}>
									<StyledText>Nombre del Curso</StyledText>
								</View>
								<View style={{ minWidth: 120, flex: 1, padding: 15, borderRightWidth: 1, borderBottomWidth: 1, borderColor: "gainsboro" }}>
									<StyledText>Localidad</StyledText>
								</View>
								<View style={{ minWidth: 150, flex: .7, padding: 15, borderRightWidth: 1, borderBottomWidth: 1, borderColor: "gainsboro" }}>
									<StyledText>Inscripción</StyledText>
								</View>
								<View style={{ minWidth: 120, flex: .7, padding: 15, borderRightWidth: 1, borderBottomWidth: 1, borderColor: "gainsboro" }}>
									<StyledText numberOfLines={1}>Inicio del Programa</StyledText>
								</View>
								<View style={{ minWidth: 120, flex: .5, padding: 15, borderRightWidth: 1, borderBottomWidth: 1, borderColor: "gainsboro" }}>
									<StyledText>Matrícula</StyledText>
								</View>
								<View style={{ minWidth: 150, flex: 1, padding: 15, borderRightWidth: 1, borderBottomWidth: 1, borderColor: "gainsboro" }}>
									{/* <StyledText>Precio</StyledText> */}
								</View>
							</View>
							{filteredCourses?.map((el, i) => <RowCourse key={i} el={el} index={i} />)}
						</View>
					}


				</View>
				{/* FIN OTROS CURSOS */}

				<View style={{ flexDirection: "row", marginTop: 50, borderTopWidth: 8, borderColor: "#e4002b", alignItems: "center", width: "100%", paddingTop: 0, alignSelf: "center" }}>
					<View style={{ alignItems: "center", justifyContent: "center", backgroundColor: "#06162D", width: "100%", paddingTop: 50, paddingHorizontal: 30, paddingBottom: 80 }}>
						<View style={{ width: "100%", maxWidth: 1200, alignItems: "center", justifyContent: "center", gap: 10, alignSelf: "center" }}>
							<StyledText style={{ fontFamily: "Title", textAlign: "center", fontSize: 50, color: "white" }}>Federaciones Territoriales</StyledText>
							<StyledText style={{ fontSize: 16, color: "white", textAlign: "center" }}>Haz click en el escudo de la federación para ir a sus cursos</StyledText>
						</View>



						<View style={{ flexWrap: "wrap", maxWidth: 1200, flexDirection: "row", alignItems: "center", justifyContent: "center", padding: 20, gap: 20 }}>
							{territoriales.map((x, i) => (
								<TouchableOpacity key={i} style={{ width: 110, height: 110 }} onPress={() => navigation.navigate("SingleCoachesSchool", { fed: x.id })}>
									<Image source={{ uri: x.logo }} style={{ flex: 1, resizeMode: "contain" }} />
								</TouchableOpacity>
							))}
						</View>
					</View>
				</View>


				<Footer limited={false} />
			</ScrollView>
		</View>

	);
};


export default HomeScreen;


const territoriales = [
	{
		logo: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2FTerritorialesWeb_108x108_Andaluci%CC%81a.png?alt=media&token=7dc2fabe-de6c-4a0e-9b3c-1f89d622ccdb",
		id: "6HRTm9IePvg11ck8K8mZ",
		name: "Andalucía"
	},
	{
		logo: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2FTerritorialesWeb_108x108_Arago%CC%81n_0.png?alt=media&token=7b624a0a-b816-4447-a2e2-d118986491dd",
		id: "7W6TSquf4c0CUmwHthTR",
		name: "Aragón"
	},
	{
		logo: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2FAlavesa.png?alt=media&token=36501aab-9ba1-4592-aa85-b6e0cad17f43&_gl=1*teb2nr*_ga*MTA5NzI3MDk5LjE2NDAwMTUwNzg.*_ga_CW55HF8NVT*MTY5NjUzNDkzNS41MTguMS4xNjk2NTM0OTY4LjI3LjAuMA..",
		id: "lIdM7sdVP9KTi4N8QwnD",
		name: "Alavesa"
	},
	{
		logo: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2FTerritorialesWeb_108x108_Asturias_0.png?alt=media&token=188ad48d-53f6-4b1c-93b7-e103fa03a776",
		id: "Pjr6SrvZcHea8Km0FurV",
		name: "Asturias"
	},
	{
		logo: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Flaspalmas.png?alt=media&token=1dd1dba4-edea-4d97-b435-369fcf1ff9e0&_gl=1*k3cqdc*_ga*MTA5NzI3MDk5LjE2NDAwMTUwNzg.*_ga_CW55HF8NVT*MTY5NjUzNDkzNS41MTguMS4xNjk2NTM1OTkxLjUxLjAuMA..",
		id: "PhOpwo6FQ06wQorFnZC3",
		name: "Canarias"
	},
	{
		logo: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2FTerritorialesWeb_108x108_Cantabria.png?alt=media&token=dbb6a5f5-83f5-4282-a533-2bec595f561b",
		id: "TlBxwgTfkbgWOfQyMSf5",
		name: "Cantabria"
	},
	{
		logo: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2FTerritorialesWeb_108x108_CastillaYLeon.png?alt=media&token=0ab24d92-55bb-40a4-a0e7-24e58d16bd39",
		id: "Yl6rFFT2xzam8i9a8M3W",
		name: "Castilla y León"
	},
	{
		logo: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2FTerritorialesWeb_108x108_Catalun%CC%83a.png?alt=media&token=5c8618e1-e9f5-44c2-8ea4-96d6f71446d1",
		id: "OOTGRhIf9vn1ujU2nqLA",
		name: "Cataluña"
	},
	{
		logo: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2FTerritorialesWeb_108x108_Ceuta_0.png?alt=media&token=6c5cdd72-92d0-4b2d-9b94-90df53f0e189",
		id: "GzvcO0AICs5OZPpRXOsc",
		name: "Ceuta"
	},
	{
		logo: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2FTerritorialesWeb_108x108_Castilla%20La%20Mancha_0.png?alt=media&token=17c88dc0-2153-4cd1-a064-a15e9d1c4521",
		id: "9erd6xGIqMtUgX9TMA0Z",
		name: "Castilla La Mancha"
	},
	{
		logo: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2FGuipuzcoana.png?alt=media&token=f11600fd-8698-4a7a-bd25-fb34c9f2bd56&_gl=1*okzc4l*_ga*MTA5NzI3MDk5LjE2NDAwMTUwNzg.*_ga_CW55HF8NVT*MTY5NjUzNDkzNS41MTguMS4xNjk2NTM1MDQzLjYwLjAuMA..",
		id: "BMRzDiDRbHUjNiYwn81u",
		name: "Guipuzcoana"
	},
	{
		logo: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2FTinerfen%CC%83a.png?alt=media&token=c023610a-4c02-4e9d-85d7-f8bd360c4135&_gl=1*s6aaj5*_ga*MTA5NzI3MDk5LjE2NDAwMTUwNzg.*_ga_CW55HF8NVT*MTY5NjUzNDkzNS41MTguMS4xNjk2NTM1MDY5LjM0LjAuMA..",
		id: "iyHsTMmGqQZtIFuHINTE",
		name: "Tinerfeña"
	},
	{
		logo: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2FTerritorialesWeb_108x108_Valencia.png?alt=media&token=3260af09-1db9-49d2-930c-80c555b4877c",
		id: "8lkXGIQnhKT4hWA6lW0R",
		name: "Valencia"
	},
	{
		logo: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2FTerritorialesWeb_108x108_Extremadura_0.png?alt=media&token=dfaedb4a-a23a-4237-a207-f0659ff0c0de",
		id: "kfrIWZBXpNbaIOpRw3Q0",
		name: "Extremadura"
	},
	{
		logo: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2FTerritorialesWeb_108x108_Galicia.png?alt=media&token=253f6711-ad6f-413b-a26e-4a75bdf29e26",
		id: "Cg3yAoHoAFLEMaD56sDf",
		name: "Galicia"
	},
	{
		logo: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2FTerritorialesWeb_108x108_Baleares.png?alt=media&token=25952284-df37-41fa-bc7d-4ecf857b4099",
		id: "Sv8o6LcbBZH59IeuNXky",
		name: "Baleares"
	},
	{
		logo: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2FTerritorialesWeb_108x108_La%20Rioja.png?alt=media&token=2d691712-af33-444b-9492-45b9273b1894",
		id: "dTZl8Hif3FtWuQ6IhBuj",
		name: "La Rioja"
	},
	{
		logo: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2FTerritorialesWeb_108x108_Madrid.png?alt=media&token=d4b4b27a-3ffd-41f8-99c1-d1fde241043e",
		id: "soUmAGlEt2o5OXJYwrTQ",
		name: "Madrid"
	},
	{
		logo: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2FTerritorialesWeb_108x108_Melilla.png?alt=media&token=152a22e0-be4e-4271-b208-90d895753117",
		id: "qRW8fEDROj8tIgvCdvEr",
		name: "Melilla"
	},
	{
		logo: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2FTerritorialesWeb_108x108_Navarra.png?alt=media&token=6e052453-c578-4b7c-9e65-9c50c4909f1d",
		id: "9r7MwCJoby873ImLnX8p",
		name: "Navarra"
	},
	{
		logo: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2FTerritorialesWeb_108x108_Pai%CC%81s%20Vasco.png?alt=media&token=dd4009a1-22e7-4b26-a421-40069752cea9",
		id: "9r7MwCJoby873ImLnX8p",
		name: "País Vasco"
	},
	{
		logo: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2FTerritorialesWeb_108x108_Murcia.png?alt=media&token=7af458c4-54a1-4859-8a66-e65b9e187765",
		id: "hILep5wqQRQZ093bvTn2",
		name: "Murcia"
	},
	{
		logo: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2FVizcaina.png?alt=media&token=a4886076-54f1-4699-99a3-69965c591b0e&_gl=1*1gg3gr0*_ga*MTA5NzI3MDk5LjE2NDAwMTUwNzg.*_ga_CW55HF8NVT*MTY5NjUzNDkzNS41MTguMS4xNjk2NTM1MTYwLjYwLjAuMA..",
		id: "79g8HLLjWCrqfFTDUf7i",
		name: "Vizcaina"
	},
]