import { Ionicons } from '@expo/vector-icons';
import { useNavigation, useRoute } from '@react-navigation/native';
import { addDoc, collection, doc, getDoc, getFirestore, query, serverTimestamp, updateDoc } from 'firebase/firestore';
import React, { useContext, useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { ActivityIndicator, Pressable, ScrollView, View, useWindowDimensions } from 'react-native';
import Button from '../../components/common/Button';
import StyledText from '../../components/common/StyledText';
import TextField from '../../components/formComponents/TextField';
import AuthContext from '../../context/AuthContext';
import Board from './Board';

import { Document, Font, Image as ImagePDF, Page, StyleSheet, Text as TextPDF, View as ViewPDF, pdf } from "@react-pdf/renderer";
import { useFirestoreDocumentData, useFirestoreQuery } from '@react-query-firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import LaRojaAnchaBold from "../../assets/fonts/La_Roja-AnchaBold.otf";
import LaRojaAncha from "../../assets/fonts/La_Roja-AnchaRegular.otf";
import LaRojaBold from "../../assets/fonts/La_Roja-EstrechaBold.otf";
import LaRoja from "../../assets/fonts/La_Roja-EstrechaRegular.otf";
import MontserratBold from "../../assets/fonts/MRB.otf";
import Montserrat from "../../assets/fonts/MRR.otf";
import Modal from '../../components/common/Modal';
import SecureImage from '../../components/common/SecureImage';


Font.register({
    family: "LaRojaPDF",
    fonts: [
        {
            src: LaRoja,
        },
        {
            src: LaRojaBold,
            fontWeight: "bold",
        },
    ],
});
Font.register({
    family: "MontserratPDF",
    fonts: [
        {
            src: Montserrat,
        },
    ],
});
Font.register({
    family: "MontserratBoldPDF",
    fonts: [
        {
            src: MontserratBold,
        },
    ],
});
Font.register({
    family: "LaRojaAnchaPDF",
    fonts: [
        {
            src: LaRojaAncha,
        },
        {
            src: LaRojaAnchaBold,
            fontWeight: "bold",
        },
    ],
});

const defaultValues = {
    name: "",
    time: "",
    numberOfPlayers: "",
    materials: "",
    goals: "",
    explanation: "",
    missing: "",
    structure: [{
        name: "Calentamiento",
        teams: "",
        moreInfo: "",
        exercises: []
    }, {
        name: "Parte Principal",
        teams: "",
        moreInfo: "",
        exercises: []
    }, {
        name: "Vuelta a la Calma",
        teams: "",
        moreInfo: "",
        exercises: []
    }],
    thumbnail: ""
}

const TemplateQRPage = ({ data }) => {



    return (
        <Document>
            <Page size={"A4"} wrap={true} style={{ paddingBottom: 15 }}>
                <ViewPDF fixed style={{ flexDirection: "row", backgroundColor: "#e4002a", width: "100%", padding: 20, marginBottom: 15, paddingVertical: 10, alignItems: "center" }}>
                    <ImagePDF allowDangerousPaths src={"https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/OF4VYpRfgUJEH5rG2syJ%2Fadmin%2Fimages%2F1654849409559-logo-rfef.png?alt=media&token=4e19e230-b0dc-44b2-b58d-558d94f177a8"} style={{ width: 30, height: 30, marginRight: 10 }} />
                    <TextPDF style={styles.classTitle}>Entrega Sesión</TextPDF>
                </ViewPDF>

                <ViewPDF style={{ padding: 20, paddingTop: 0 }}>

                    <ViewPDF style={{ width: "100%", flexDirection: "row", marginBottom: 10, marginTop: 5 }}>
                        <ViewPDF style={{ flex: 1 }}>
                            <ViewPDF style={styles.header}>
                                <TextPDF style={[styles.headerText, { marginLeft: 5 }]}>Detalles de la Sesión</TextPDF>
                            </ViewPDF>
                            <ViewPDF style={[styles.container, { padding: 0, flexDirection: "row" }]}>
                                <ViewPDF style={{ width: "30%", flexDirection: "column", backgroundColor: "#f4f4f4" }}>
                                    <ViewPDF style={{ justifyContent: "center", paddingHorizontal: 10, height: 25, width: "100%", borderRightWidth: 1, borderBottomWidth: 1 }}><TextPDF style={styles.text}>Título</TextPDF></ViewPDF>
                                    <ViewPDF style={{ justifyContent: "center", paddingHorizontal: 10, height: 25, width: "100%", borderRightWidth: 1, borderBottomWidth: 1 }}><TextPDF style={styles.text}>Tiempo</TextPDF></ViewPDF>
                                    <ViewPDF style={{ justifyContent: "center", paddingHorizontal: 10, height: 25, width: "100%", borderRightWidth: 1 }}><TextPDF style={styles.text}>N Jugadores</TextPDF></ViewPDF>

                                </ViewPDF>
                                <ViewPDF style={{ width: "70%", flexDirection: "column" }}>
                                    <ViewPDF style={{ justifyContent: "center", paddingHorizontal: 10, height: 25, width: "100%", borderBottomWidth: 1 }}><TextPDF style={styles.text}>{data.name}</TextPDF></ViewPDF>
                                    <ViewPDF style={{ justifyContent: "center", paddingHorizontal: 10, height: 25, width: "100%", borderBottomWidth: 1 }}><TextPDF style={styles.text}>{data.time}</TextPDF></ViewPDF>
                                    <ViewPDF style={{ justifyContent: "center", paddingHorizontal: 10, height: 25, width: "100%" }}><TextPDF style={styles.text}>{data.numberOfPlayers}</TextPDF></ViewPDF>

                                </ViewPDF>
                            </ViewPDF>

                            <ViewPDF style={[styles.container, { padding: 0, flexDirection: "row", marginTop: 5 }]}>
                                <ViewPDF style={{ flex: 1, flexDirection: "column" }}>
                                    <ViewPDF style={{ backgroundColor: "#f4f4f4", justifyContent: "center", paddingHorizontal: 10, height: 25, width: "100%", borderTopWidth: 1, borderBottomWidth: 1, borderRightWidth: 1 }}>
                                        <TextPDF style={styles.text}>Materiales</TextPDF>
                                    </ViewPDF>
                                    <ViewPDF style={{ justifyContent: "center", paddingHorizontal: 10, paddingVertical: 10, minHeight: 25, width: "100%", borderRightWidth: 1 }}>
                                        <TextPDF style={styles.text}>{data.materials}</TextPDF>
                                    </ViewPDF>
                                </ViewPDF>
                            </ViewPDF>
                            <ViewPDF style={[styles.container, { padding: 0, flexDirection: "row", marginTop: 5 }]}>
                                <ViewPDF style={{ flex: 1, flexDirection: "column" }}>
                                    <ViewPDF style={{ backgroundColor: "#f4f4f4", justifyContent: "center", paddingHorizontal: 10, height: 25, width: "100%", borderTopWidth: 1, borderBottomWidth: 1, borderRightWidth: 1 }}>
                                        <TextPDF style={styles.text}>Objetivos</TextPDF>
                                    </ViewPDF>
                                    <ViewPDF style={{ justifyContent: "center", paddingHorizontal: 10, paddingVertical: 10, minHeight: 25, width: "100%", borderRightWidth: 1 }}>
                                        <TextPDF style={styles.text}>{data.goals}</TextPDF>
                                    </ViewPDF>
                                </ViewPDF>
                            </ViewPDF>
                            <ViewPDF style={[styles.container, { padding: 0, flexDirection: "row", marginTop: 5 }]}>
                                <ViewPDF style={{ flex: 1, flexDirection: "column" }}>
                                    <ViewPDF style={{ backgroundColor: "#f4f4f4", justifyContent: "center", paddingHorizontal: 10, height: 25, width: "100%", borderTopWidth: 1, borderBottomWidth: 1, borderRightWidth: 1 }}><TextPDF style={styles.text}>Lesionados / Ausencias</TextPDF></ViewPDF>
                                    <ViewPDF style={{ justifyContent: "center", paddingHorizontal: 10, paddingVertical: 10, minHeight: 25, width: "100%", borderRightWidth: 1 }}><TextPDF style={styles.text}>{data.missing}</TextPDF></ViewPDF>
                                </ViewPDF>
                            </ViewPDF>

                        </ViewPDF>
                    </ViewPDF>
                </ViewPDF>


                {data.structure.map((x, i) => {
                    return (
                        <React.Fragment key={i}>


                            <ViewPDF style={{ padding: 20, paddingTop: 0 }}>

                                <ViewPDF style={{ width: "100%", flexDirection: "row", marginBottom: 10, marginTop: 5 }}>
                                    <ViewPDF style={{ flex: 1 }}>
                                        <ViewPDF style={styles.header}>
                                            <TextPDF style={[styles.headerText, { marginLeft: 5 }]}>Detalles de la Sección</TextPDF>
                                        </ViewPDF>
                                        <ViewPDF style={[styles.container, { padding: 0, flexDirection: "row" }]}>
                                            <ViewPDF style={{ width: "30%", flexDirection: "column", backgroundColor: "#f4f4f4" }}>
                                                <ViewPDF style={{ justifyContent: "center", paddingHorizontal: 10, height: 25, width: "100%", borderRightWidth: 1 }}><TextPDF style={styles.text}>Nombre</TextPDF></ViewPDF>
                                            </ViewPDF>
                                            <ViewPDF style={{ width: "70%", flexDirection: "column" }}>
                                                <ViewPDF style={{ justifyContent: "center", paddingHorizontal: 10, height: 25, width: "100%" }}><TextPDF style={styles.text}>{x.name}</TextPDF></ViewPDF>
                                            </ViewPDF>
                                        </ViewPDF>

                                        <ViewPDF style={[styles.container, { padding: 0, flexDirection: "row", marginTop: 5 }]}>
                                            <ViewPDF style={{ flex: 1, flexDirection: "column" }}>
                                                <ViewPDF style={{ backgroundColor: "#f4f4f4", justifyContent: "center", paddingHorizontal: 10, height: 25, width: "100%", borderTopWidth: 1, borderBottomWidth: 1, borderRightWidth: 1 }}><TextPDF style={styles.text}>Equipos</TextPDF></ViewPDF>
                                                <ViewPDF style={{ justifyContent: "center", paddingHorizontal: 10, paddingVertical: 10, minHeight: 25, width: "100%", borderRightWidth: 1 }}><TextPDF style={styles.text}>{x.teams}</TextPDF></ViewPDF>
                                            </ViewPDF>
                                            <ViewPDF style={{ flex: 1, flexDirection: "column" }}>
                                                <ViewPDF style={{ backgroundColor: "#f4f4f4", justifyContent: "center", paddingHorizontal: 10, height: 25, width: "100%", borderTopWidth: 1, borderBottomWidth: 1, borderRightWidth: 1 }}><TextPDF style={styles.text}>Otros</TextPDF></ViewPDF>
                                                <ViewPDF style={{ justifyContent: "center", paddingHorizontal: 10, paddingVertical: 10, minHeight: 25, width: "100%", borderRightWidth: 1 }}><TextPDF style={styles.text}>{x.moreInfo}</TextPDF></ViewPDF>
                                            </ViewPDF>

                                        </ViewPDF>

                                    </ViewPDF>
                                </ViewPDF>


                            </ViewPDF>


                            {x.exercises.map((data, i) => (
                                <ViewPDF key={i} wrap={true} style={{ paddingBottom: 10 }}>
                                    <ViewPDF wrap={true}>

                                        <ViewPDF style={{ padding: 20, paddingTop: 0 }}>

                                            <ViewPDF style={{ width: "100%", flexDirection: "row", marginBottom: 10, }}>
                                                <ViewPDF style={{ flex: 1 }}>
                                                    <ViewPDF style={styles.header}>
                                                        <TextPDF style={[styles.headerText, { marginLeft: 5 }]}>Detalles del Ejercicio</TextPDF>
                                                    </ViewPDF>
                                                    <ViewPDF style={[styles.container, { padding: 0, flexDirection: "row" }]}>
                                                        <ViewPDF style={{ width: "20%", flexDirection: "column", backgroundColor: "#f4f4f4" }}>
                                                            <ViewPDF style={{ justifyContent: "center", paddingHorizontal: 10, height: 25, width: "100%", borderBottomWidth: 1 }}><TextPDF style={styles.text}>Título</TextPDF></ViewPDF>
                                                            <ViewPDF style={{ justifyContent: "center", paddingHorizontal: 10, height: 25, width: "100%", borderBottomWidth: 1 }}><TextPDF style={styles.text}>Estrategia</TextPDF></ViewPDF>
                                                            <ViewPDF style={{ justifyContent: "center", paddingHorizontal: 10, minHeight: 25, paddingVertical: 5, height: "auto", width: "auto" }}><TextPDF style={styles.text}>Objetivos</TextPDF></ViewPDF>
                                                        </ViewPDF>
                                                        <ViewPDF style={{ width: "80%", flexDirection: "column" }}>
                                                            <ViewPDF style={{ justifyContent: "center", paddingHorizontal: 10, height: 25, width: "100%", borderBottomWidth: 1, borderLeftWidth: 1 }}><TextPDF style={styles.text}>{data.name}</TextPDF></ViewPDF>
                                                            <ViewPDF style={{ justifyContent: "center", paddingHorizontal: 10, height: 25, width: "100%", borderBottomWidth: 1, borderLeftWidth: 1 }}><TextPDF style={styles.text}>{data.strategy}</TextPDF></ViewPDF>
                                                            <ViewPDF style={{ justifyContent: "center", paddingHorizontal: 10, minHeight: 25, paddingVertical: 5, height: "auto", width: "100%", borderLeftWidth: 1 }}><TextPDF style={styles.text}>{data.goals}</TextPDF></ViewPDF>
                                                        </ViewPDF>
                                                    </ViewPDF>

                                                    <ViewPDF style={[styles.container, { padding: 0, flexDirection: "row", marginTop: 5 }]}>
                                                        <ViewPDF style={{ flex: 1, flexDirection: "column" }}>
                                                            <ViewPDF style={{ backgroundColor: "#f4f4f4", justifyContent: "center", paddingHorizontal: 10, height: 25, width: "100%", borderTopWidth: 1, borderBottomWidth: 1, borderRightWidth: 1 }}><TextPDF style={styles.text}>Espacio</TextPDF></ViewPDF>
                                                            <ViewPDF style={{ justifyContent: "center", paddingHorizontal: 10, height: 25, width: "100%", borderRightWidth: 1 }}><TextPDF style={styles.text}>{data.space}</TextPDF></ViewPDF>
                                                        </ViewPDF>
                                                        <ViewPDF style={{ flex: 1, flexDirection: "column" }}>
                                                            <ViewPDF style={{ backgroundColor: "#f4f4f4", justifyContent: "center", paddingHorizontal: 10, height: 25, width: "100%", borderTopWidth: 1, borderBottomWidth: 1, borderRightWidth: 1 }}><TextPDF style={styles.text}>Tiempo</TextPDF></ViewPDF>
                                                            <ViewPDF style={{ justifyContent: "center", paddingHorizontal: 10, height: 25, width: "100%", borderRightWidth: 1 }}><TextPDF style={styles.text}>{data.time}</TextPDF></ViewPDF>
                                                        </ViewPDF>
                                                        <ViewPDF style={{ flex: 1, flexDirection: "column" }}>
                                                            <ViewPDF style={{ backgroundColor: "#f4f4f4", justifyContent: "center", paddingHorizontal: 10, height: 25, width: "100%", borderTopWidth: 1, borderBottomWidth: 1, borderRightWidth: 1 }}><TextPDF style={styles.text}>Situación de Juego</TextPDF></ViewPDF>
                                                            <ViewPDF style={{ justifyContent: "center", paddingHorizontal: 10, height: 25, width: "100%", borderRightWidth: 1 }}><TextPDF style={styles.text}>{data.gameSituation}</TextPDF></ViewPDF>
                                                        </ViewPDF>
                                                        <ViewPDF style={{ flex: 1, flexDirection: "column" }}>
                                                            <ViewPDF style={{ backgroundColor: "#f4f4f4", justifyContent: "center", paddingHorizontal: 10, height: 25, width: "100%", borderTopWidth: 1, borderBottomWidth: 1 }}><TextPDF style={styles.text}>Coordinación</TextPDF></ViewPDF>
                                                            <ViewPDF style={{ justifyContent: "center", paddingHorizontal: 10, height: 25, width: "100%" }}><TextPDF style={styles.text}>{data.coordination}</TextPDF></ViewPDF>
                                                        </ViewPDF>

                                                    </ViewPDF>


                                                    <ViewPDF style={[styles.container, { padding: 0, flexDirection: "row", marginTop: 5 }]}>
                                                        <ViewPDF style={{ flex: 1, flexDirection: "column" }}>
                                                            <ViewPDF style={{ backgroundColor: "#f4f4f4", justifyContent: "center", paddingHorizontal: 10, height: 25, width: "100%", borderTopWidth: 1, borderBottomWidth: 1, borderRightWidth: 1 }}><TextPDF style={styles.text}>Habilidades Coordinativas</TextPDF></ViewPDF>
                                                            <ViewPDF style={{ justifyContent: "center", paddingHorizontal: 10, height: 25, width: "100%", borderRightWidth: 1 }}><TextPDF style={styles.text}>{data.habilities}</TextPDF></ViewPDF>
                                                        </ViewPDF>
                                                        <ViewPDF style={{ flex: 1, flexDirection: "column" }}>
                                                            <ViewPDF style={{ backgroundColor: "#f4f4f4", justifyContent: "center", paddingHorizontal: 10, height: 25, width: "100%", borderTopWidth: 1, borderBottomWidth: 1 }}><TextPDF style={styles.text}>Intención /  Acción Táctica</TextPDF></ViewPDF>
                                                            <ViewPDF style={{ justifyContent: "center", paddingHorizontal: 10, height: 25, width: "100%" }}><TextPDF style={styles.text}>{data.intention}</TextPDF></ViewPDF>
                                                        </ViewPDF>


                                                    </ViewPDF>
                                                </ViewPDF>
                                            </ViewPDF>

                                            {data.image ?
                                                <ViewPDF style={{ width: "100%", flexDirection: "row", marginBottom: 10, marginTop: 0 }} wrap={false}>
                                                    <ViewPDF style={{ flex: 1 }}>
                                                        <ViewPDF style={styles.header}>
                                                            <TextPDF style={[styles.headerText, { marginLeft: 5 }]}>Descripción Gráfica</TextPDF>
                                                        </ViewPDF>
                                                        <ViewPDF style={[styles.container, { padding: 2, alignItems: "center", justifyContent: "center" }]}>

                                                            <ImagePDF allowDangerousPaths src={data.image} style={{ width: "80%", height: "auto", maxHeight: 400, objectFit: "contain" }} />

                                                        </ViewPDF>
                                                    </ViewPDF>
                                                </ViewPDF>
                                                : null
                                            }

                                            {data.explanation ?
                                                <ViewPDF style={{ width: "100%", flexDirection: "row", marginBottom: 0, marginTop: 0 }}>
                                                    <ViewPDF style={{ flex: 1 }}>
                                                        <ViewPDF style={styles.header}>
                                                            <TextPDF style={[styles.headerText, { marginLeft: 5 }]}>Consigna / Explicación</TextPDF>
                                                        </ViewPDF>
                                                        <ViewPDF style={styles.container}>
                                                            <TextPDF style={styles.text}>{data.explanation}</TextPDF>
                                                        </ViewPDF>
                                                    </ViewPDF>
                                                </ViewPDF>
                                                : null
                                            }



                                        </ViewPDF>
                                    </ViewPDF>
                                </ViewPDF>
                            ))}
                        </React.Fragment>
                    )

                })}
            </Page>
        </Document >
    );
};

const Session = () => {

    const { userData } = useContext(AuthContext)
    const { id } = useRoute().params as any
    const db = getFirestore()
    const navigation = useNavigation()
    const storage = getStorage()

    const [isLoading, setIsLoading] = React.useState(false)
    const [viewBoard, setViewBoard] = React.useState(false)
    const [dataThumb, setDataThumb] = React.useState(null)
    const [loadingPdf, setLoadingPdf] = React.useState(false)

    const screen = useWindowDimensions()

    const {
        handleSubmit,
        control,
        reset,
        getValues,
        setValue,
        watch,
        formState: { errors },
    } = useForm({
        mode: "onChange",
        defaultValues,
    });


    useEffect(() => {
        reset(defaultValues)
        if (userData.id && id && id !== "new") fetchExercise()
        if (id === "new") setDataThumb(null)
    }, [id, userData])

    const fetchExercise = async () => {
        setIsLoading(true)
        setDataThumb(null)
        const docRef = doc(db, `users/${userData.id}/sessions/${id}`)
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {

            const data = docSnap.data()
            if (data.thumbnail) {
                await getDownloadURL(ref(storage, data.thumbnail)).then(async res => {
                    setDataThumb(res)
                })
            }
            reset(docSnap.data())

            setIsLoading(false)
        } else {
            setIsLoading(false)
            alert("No existe el ejercicio")
        }
    }

    const onSubmit = (data: any) => {
        if (id === "new") {
            addDoc(collection(db, `users/${userData.id}/sessions`), {
                ...data,
                updatedAt: serverTimestamp(),
                createdAt: serverTimestamp(),
            }).then(res => {
                navigation.setParams({ id: res.id })
            }).catch(err => {
                alert("Ha ocurrido un error al guardar el ejercicio")
            })
        } else {
            updateDoc(doc(db, `users/${userData.id}/sessions/${id}`), {
                ...data,
                updatedAt: serverTimestamp(),
            }).then(res => {
                alert("Ejercicio guardado correctamente")
            }).catch(err => {
                alert("Ha ocurrido un error al guardar el ejercicio")
            })
        }
    }


    const printPDF = async () => {
        setLoadingPdf(true)
        const data = getValues()

        const structure = await Promise.all(
            data.structure.map(async x => {
                return {
                    ...x,
                    exercises: await Promise.all(x.exercises.map(async y => {
                        const data = await getDoc(doc(db, y.ref)).then(res => res.data() as Object).catch(res => null)

                        if (data !== null) {

                            const thumbnail = data?.thumbnail ? await getDownloadURL(ref(storage, data.thumbnail)).then(res => res).catch(res => "") : ""
                            return {
                                ...data,
                                image: thumbnail,
                            }

                        } else {
                            return null
                        }
                    })).then(res => res.filter(x => x !== null))
                }
            }))

        const processedData = {
            ...data,
            structure
        }


        const bb = await pdf(<TemplateQRPage data={processedData} />).toBlob()
        const url = URL.createObjectURL(bb)
        const link = document.createElement('a')
        link.href = url
        link.target = '_blank'
        link.click()

        setLoadingPdf(false)

    }

    const structure = watch()

    if (isLoading) return (
        <View style={{ flex: 1, minHeight: 400, alignItems: "center", justifyContent: "center" }}>
            <ActivityIndicator />
        </View>
    )

    return (
        <>
            {viewBoard && <View style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: viewBoard ? 100 : -1 }}>
                <Board
                    closeCallback={() => setViewBoard(false)}
                    saveCallback={(ev) => {
                        setValue("data", ev.snap)
                        uploadBytes(ref(storage, `users/${userData.id}/exercises/${id}.png`), ev.svg)
                            .then(async res => {
                                setValue("thumbnail", res.ref.fullPath)

                                if (id === "new") {
                                    addDoc(collection(db, `users/${userData.id}/exercises`), {
                                        ...getValues(),
                                        thumbnail: res.ref.fullPath,
                                        updatedAt: serverTimestamp(),
                                        createdAt: serverTimestamp(),
                                    }).then(res => {
                                        navigation.setParams({ id: res.id })
                                    }).catch(err => {
                                        alert("Ha ocurrido un error al guardar el ejercicio")
                                    })
                                } else {
                                    updateDoc(doc(db, `users/${userData.id}/exercises/${id}`), {
                                        ...getValues(),
                                        thumbnail: res.ref.fullPath,
                                        updatedAt: serverTimestamp(),
                                    }).then(res => {
                                        alert("Ejercicio guardado correctamente")
                                    }).catch(err => {
                                        alert("Ha ocurrido un error al guardar el ejercicio")
                                    })
                                }

                                getDownloadURL(res.ref).then(res => {
                                    setDataThumb(res)
                                })

                            }).catch(err => {
                                console.log(err)
                                alert("Ha ocurrido un error al guardar la pizarra")
                            })
                        setViewBoard(false)
                    }}
                    initialData={getValues("data")}
                    id={id}
                    style={{ flex: 1 }}
                />
            </View>}
            <ScrollView contentContainerStyle={{ padding: 15, gap: 10, maxWidth: 1000, width: "100%", alignSelf: "center" }}>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Pressable onPress={() => navigation.navigate("Exercises")}>
                        <Ionicons name="chevron-back" size={24} color="black" />
                    </Pressable>
                    <View style={{ flex: 1 }}>
                        <StyledText style={{ fontFamily: "TitleWide", fontSize: 24, fontWeight: "bold" }}>{id !== "new" ? "Editar" : "Nueva"} Sesión</StyledText>
                    </View>
                    <View style={{ flexDirection: "row", gap: 10 }}>
                        {id !== "new" &&
                            <Button
                                textStyles={{ textDecoration: "none" }}
                                height={35}
                                round={7}
                                onPress={printPDF}
                                label="Imprimir"
                                loading={loadingPdf}
                                disabled={loadingPdf}
                                primary={false}
                            />
                        }

                        {/* <Button color="#0b1831" height={35} round={7} onPress={() => setViewBoard(true)} label="Abrir Pizarra" /> */}
                        <Button height={35} round={7} onPress={handleSubmit(onSubmit)} label={"Guardar"} />
                    </View>
                </View>
                <View style={{ width: "100%", padding: 15, borderRadius: 7, backgroundColor: "white", gap: 10, shadowColor: "#000", shadowOffset: { width: 0, height: 5 }, shadowOpacity: 0.15, shadowRadius: 10, elevation: 5 }}>


                    <View style={{ flexDirection: 'row', gap: 10 }}>
                        <TextField error={errors.name} control={control} name="name" label="Nombre" />
                        <TextField error={errors.time} control={control} name="time" label="Tiempo(min)" />
                        <TextField error={errors.numberOfPlayers} control={control} name="numberOfPlayers" label="N Jugadores" />
                    </View>

                    <View style={{ flexDirection: 'row', gap: 10 }}>
                        <View style={{ flex: 1 }}>
                            <TextField error={errors.materials} control={control} name="materials" label="Materiales" multiline={"true"} textinputstyle={{ minHeight: 100, paddingTop: 15 }} />
                        </View>
                        <View style={{ flex: 1 }}>
                            <TextField error={errors.goals} control={control} name="goals" label="Objetivos" multiline={"true"} textinputstyle={{ minHeight: 100, paddingTop: 15 }} />
                        </View>
                        <View style={{ flex: 1 }}>
                            <TextField error={errors.missing} control={control} name="missing" label="Lesionados / Ausencias" multiline={"true"} textinputstyle={{ minHeight: 100, paddingTop: 15 }} />
                        </View>
                    </View>



                </View>


                <View style={{ width: "100%", padding: 15, borderRadius: 7, backgroundColor: "white", gap: 10, shadowColor: "#000", shadowOffset: { width: 0, height: 5 }, shadowOpacity: 0.15, shadowRadius: 10, elevation: 5 }}>
                    <StructureRepeater control={control} watch={watch} setValue={setValue} getValues={getValues} />

                </View>
            </ScrollView>
        </>
    );
}

export default Session;


const StructureRepeater = ({ control, watch, setValue, getValues }) => {

    const [selected, setSelected] = React.useState(0)
    const [exercisesModal, setExercisesModal] = React.useState(false)
    const [contentsSelected, setContentsSelected] = React.useState([])
    const { fields, append, remove } = useFieldArray({
        name: "structure",
        control
    })

    useEffect(() => {
        setContentsSelected([])
    }, [exercisesModal])

    const structure = watch("structure")

    return (
        <>
            <View style={{ flex: 1 }}>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <View style={{ flex: 1 }}>
                        <StyledText style={{ fontFamily: "TitleWide", fontSize: 14, fontWeight: "bold" }}>Estructura de la sesión</StyledText>
                    </View>

                    <View style={{ flexDirection: "row", gap: 10 }}>

                        <Button
                            height={35}
                            round={7}
                            paddingHorizontal={8}
                            onPress={() => append({
                                name: "Parte " + (fields.length + 1),
                                teams: "",
                                moreInfo: "",
                                exercises: []
                            })}
                            icon="add"
                            iconSize={16}
                            color="#0b1831"
                        />
                        <Button
                            height={35}
                            round={7}
                            paddingHorizontal={8}
                            onPress={() => {
                                remove(selected)
                                setSelected(0)
                            }}
                            icon="trash"
                            iconSize={16}
                        />
                    </View>
                </View>

                {structure.length > 0 &&
                    <ScrollView horizontal showsHorizontalScrollIndicator={false}>
                        {structure.map((field, i) => {
                            return (
                                <Button
                                    key={field.id}
                                    height={35}
                                    round={5}
                                    primary={selected === i}
                                    label={field.name}
                                    onPress={() => setSelected(i)}
                                />
                            )
                        })}
                    </ScrollView>
                }
                <View style={{ borderWidth: 1, borderColor: "gainsboro", borderRadius: 7, padding: 10 }}>
                    {fields.length === 0 && fields[selected] ?
                        <View style={{ flex: 1, minHeight: 200, alignItems: "center", justifyContent: "center" }}>
                            <StyledText style={{ fontSize: 16 }}>No hay secciones</StyledText>
                        </View>
                        :
                        fields[selected] ?
                            <View style={{ flexDirection: "row", gap: 10, flexGrow: 1 }} key={fields[selected].id}>
                                <View style={{ flex: 1, gap: 10, flexGrow: 1 }}>
                                    <View>
                                        <TextField control={control} name={`structure[${selected}].name`} label="Nombre" />
                                    </View>

                                    <TextField control={control} name={`structure[${selected}].teams`} label="Equipos" multiline={"true"} textinputstyle={{ minHeight: 80, paddingTop: 15, resize: "vertical" }} />


                                    <TextField control={control} name={`structure[${selected}].moreInfo`} label="Otros" multiline={"true"} textinputstyle={{ minHeight: 80, paddingTop: 15, resize: "vertical" }} />

                                </View>
                                <View style={{ flex: 1 }}>
                                    <View style={{ flexDirection: "row", alignItems: "center", marginBottom: 10 }}>
                                        <View style={{ flex: 1 }}>
                                            <StyledText style={{ color: "gray" }}>Ejercicios</StyledText>
                                        </View>
                                        <Button height={35} round={7} color="#0b1831" label="Añadir Ejercicios" onPress={() => setExercisesModal(true)} />

                                    </View>
                                    <ScrollView style={{ minHeight: 300, backgroundColor: "#f3f3f3", borderRadius: 7 }} contentContainerStyle={{ gap: 10, padding: 10 }}>
                                        <ExercisesRepeater control={control} sectionIndex={selected} />
                                    </ScrollView>
                                </View>
                            </View>
                            : null
                    }
                </View>
            </View>

            {exercisesModal &&
                <Modal onClickOutside={() => setExercisesModal(false)} modalContainerStyle={{ maxHeight: 700 }}>
                    <ScrollView style={{ minWidth: 350 }} contentContainerStyle={{ gap: 10 }}>
                        <StyledText style={{ fontSize: 16 }}>Añadir ejercicios</StyledText>
                        <ExercisesTab
                            itemsSelected={contentsSelected}
                            selectCallback={(ex) => {

                                if (contentsSelected.find(x => x.ref === ex.ref.path)) {
                                    setContentsSelected(contentsSelected.filter(x => x.ref !== ex.ref.path))
                                } else {
                                    setContentsSelected([...contentsSelected, { ref: ex.ref.path }])
                                }
                            }} />
                        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: 'space-between' }}>
                            <Button height={35} round={7} color="gainsboro" label="Cancelar" onPress={() => setExercisesModal(false)} />
                            <Button height={35} round={7} color="#0b1831" label="Guardar"
                                onPress={() => {
                                    const prev = getValues(`structure[${selected}].exercises`) || []

                                    setValue(`structure[${selected}].exercises`, [...prev, ...contentsSelected])
                                    setExercisesModal(false)
                                }} />
                        </View>
                    </ScrollView>
                </Modal>
            }
        </>
    )
}

const ExerciseCard = ({ docRef, first, last, up, down, remove }) => {

    const { userData } = useContext(AuthContext)
    const db = getFirestore()

    const d = docRef ? doc(db, docRef) : null
    const { data, isLoading } = useFirestoreDocumentData(["exercises", userData.id, docRef], d, {}, { enabled: !!docRef })

    if (isLoading || !data) return null
    return (
        <View style={{ width: "100%", padding: 15, borderRadius: 7, backgroundColor: "white", flexDirection: "row", alignItems: "center", justifyContent: "space-between", gap: 10, shadowColor: "#000", shadowOffset: { width: 0, height: 5 }, shadowOpacity: 0.15, shadowRadius: 10, elevation: 5 }} >
            <View style={{ flexDirection: "row", alignItems: "center", gap: 10, flex: 1 }}>
                <SecureImage uri={data.thumbnail} style={{ width: 60, height: 40, borderRadius: 8 }} />
                <StyledText numberOfLines={1} style={{ fontSize: 14 }}>{data.name}</StyledText>
            </View>
            <View style={{ flexDirection: "row", gap: 10 }}>

                <Button color="gray" disabled={first} iconSize={18} height={35} paddingHorizontal={5} round={7} onPress={up} icon="arrow-up" />
                <Button color="gray" disabled={last} iconSize={18} height={35} paddingHorizontal={5} round={7} onPress={down} icon="arrow-down" />
                <Button iconSize={18} height={35} paddingHorizontal={5} round={7} onPress={remove} icon="trash" />
            </View>
        </View>
    )

}

const ExercisesTab = ({ itemsSelected, selectCallback }) => {
    const { userData } = useContext(AuthContext)

    const db = getFirestore()
    const docRef = query(collection(db, `users/${userData.id}/exercises`))
    const { data, isLoading } = useFirestoreQuery(["exercises", userData.id], docRef, {}, {
        select: (d) => d.docs.map(x => ({ ...x.data(), id: x.id, ref: x.ref }))
            .sort((a, b) => {
                const dateA = a.updatedAt.toDate()
                const dateB = b.updatedAt.toDate()
                return dateB.getTime() - dateA.getTime()
            })
    })


    return (
        <>
            {data?.length === 0 ?
                <View style={{ padding: 100, gap: 15, alignItems: "center", justifyContent: "center", width: "100%", borderRadius: 8, backgroundColor: "#f3f3f3" }}>
                    <StyledText>No hay ejercicios</StyledText>
                </View>
                :
                data?.map((ex: any) => (
                    <Pressable
                        key={ex.id}
                        onPress={() => selectCallback(ex)}
                        style={{ borderWidth: itemsSelected.find(x => x.ref === ex.ref.path) ? 2 : 0, borderColor: "orange", width: "100%", padding: 15, borderRadius: 7, backgroundColor: "white", flexDirection: "row", alignItems: "center", justifyContent: "space-between", gap: 10, shadowColor: "#000", shadowOffset: { width: 0, height: 5 }, shadowOpacity: 0.15, shadowRadius: 10, elevation: 5 }}
                    >
                        <View style={{ flexDirection: "row", alignItems: "center", gap: 10 }}>
                            <SecureImage placeholder={""} uri={ex.thumbnail} style={{ width: 100, height: 60, borderRadius: 8 }} />
                            <StyledText style={{ fontSize: 18 }}>{ex.name}</StyledText>
                        </View>
                    </Pressable>
                ))}
        </>
    )
}

const ExercisesRepeater = ({ control, sectionIndex }) => {
    const { fields, remove, move } = useFieldArray({
        name: `structure[${sectionIndex}].exercises`,
        control
    })
    return (
        fields?.length === 0 ?
            <View style={{ padding: 100, gap: 15, alignItems: "center", justifyContent: "center", width: "100%", borderRadius: 8, backgroundColor: "#f3f3f3" }}>
                <StyledText>No hay ejercicios</StyledText>
            </View>
            :
            fields?.map((ex: any, i: number) => (
                <ExerciseCard key={ex.id} docRef={ex.ref} remove={() => remove(i)} up={() => move(i, i - 1)} down={() => move(i, i + 1)} first={i === 0} last={fields.length - 1 === i} />
            ))
    )
}

const styles = StyleSheet.create({
    courseTitle: {
        fontFamily: "LaRojaPDF",
        textAlign: "center",
        color: "white",
        fontSize: 20,
        fontWeight: "bold",
    },
    pin: {
        fontFamily: "LaRojaPDF",
        textAlign: "center",
        padding: 10,
        fontSize: 30,
    },
    classTitle: {
        fontFamily: "LaRojaAnchaPDF",
        textAlign: "center",
        color: "white",
        fontSize: 16,
        fontWeight: "bold",
    },
    header: {
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        padding: 10,
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: "#e4002a",
        width: "100%",
    },
    container: {
        padding: 10,
        borderWidth: 1,
        borderTopWidth: 0,
        width: "100%",
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
    },
    headerText: {
        color: "white",
        fontSize: 8,
        fontFamily: "LaRojaAnchaPDF",
        fontWeight: "bold",
    },
    text: {
        fontSize: 10,
        fontFamily: "MontserratPDF",
    },
});