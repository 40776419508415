import { getAuth } from 'firebase/auth';
import { getDatabase, ref, set } from 'firebase/database';

export type LogProps = {
    tenantId?: string,
    screen?: string,
    action?: string,
    description?: string,
    path?: string
}

const customLog = ({ tenantId, screen, path, action, description }: LogProps) => {
    const database = getDatabase();
    const auth = getAuth()
    try {
        if (!auth.currentUser) return null

        set(ref(database, `users/${auth.currentUser.uid}/logs/${new Date().getTime()}`), {
            userId: auth.currentUser?.uid || null,
            tenantId: tenantId || null,
            screen: screen || null,
            action: action || null,
            description: description || null,
            path: path || null,
            createdAt: new Date().toISOString()
        }).then(res => {
            return null
        })

    } catch (err) {
        console.log('Error en customLog', err)
        return null
    }
}

export default customLog;