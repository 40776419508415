import { getApp } from "firebase/app";
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import React, { useContext, useState } from "react";
import { ScrollView, View } from "react-native";
import Button from "../../components/common/Button";
import Footer from "../../components/common/Footer";
import StyledText from "../../components/common/StyledText";
import AuthContext from "../../context/AuthContext";


const Reevaluation = () => {

	const { userData } = useContext(AuthContext);
	const [loading, setLoading] = useState(false)
	const app = getApp()
	const functions = getFunctions(app, "europe-west1")
	process.env.NODE_ENV === "development" && connectFunctionsEmulator(functions, "localhost", 5001);
	const payReevaluation = httpsCallable(functions, "payReevaluation");

	if (!userData?.superAdmin) {
		return <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
			<StyledText style={{ fontSize: 20, fontWeight: "bold", marginTop: 20, textAlign: "center" }}>
				No tienes permiso para acceder a esta página
			</StyledText>
		</View>
	}

	const courses = [
		{
			id: 1,
			name: "Certificado de actualización RFEF UEFA",
			tag: "4ª JORNADA ESPECÍFICA ACTUALIZACIÓN Y RECICLAJE FÚTBOL SALA (ARGANDA DEL REY - MADRI",
			hours: 12.4
		}, {
			id: 2,
			name: "Certificado de actualización RFEF UEFA",
			tag: "JORNADAS DE ACTUALIZACION Y RECICLAJE",
			hours: 5
		}
	]

	async function makeRedsysPayment({ formData }) {

		const form = document.createElement("form")
		const { endpoint } = formData
		if (!endpoint) return
		form.setAttribute("name", "from")
		form.setAttribute("method", "post")
		form.setAttribute("target", "_self")
		form.setAttribute("action", endpoint)
		form.setAttribute("style", "display: none")
		Object.keys(formData)
			.forEach(key => {
				const input = document.createElement("input")
				input.setAttribute("type", "hidden")
				input.setAttribute("name", key)
				input.setAttribute("value", formData[key])
				form.appendChild(input)
			})

		const input = document.createElement("input")
		input.setAttribute("type", "submit")
		input.setAttribute("value", "Pay with credit card")
		form.appendChild(input)
		document.body.appendChild(form)
		form.submit()
		document.body.removeChild(form)
		setTimeout(() => {
			setLoading(false)
		}, 3000)
	}

	const pay = async () => {
		setLoading(true)

		try {
			const { data } = await payReevaluation({ userId: userData.id, originUrl: window.location.href })
			console.log(data)

		} catch (err) {
			console.log(err)
		}
		// const { orderId, form } = data
		// makeRedsysPayment({ formData: form })

	}

	return (
		<>
			<ScrollView style={{ flex: 1, backgroundColor: "white" }} contentContainerStyle={{ flexGrow: 1 }}>
				<View style={{ flexDirection: "row", alignItems: "center", width: "100%", paddingTop: 0, alignSelf: "center" }}>
					<View style={{ flexDirection: "row", backgroundColor: "#06162D", width: "100%", alignItems: "center", justifyContent: 'center', paddingTop: 50, paddingHorizontal: 30, paddingBottom: 20 }}>
						<View style={{ width: "100%", maxWidth: 1200, alignItems: "center", justifyContent: "center", gap: 10, alignSelf: "center" }}>
							<StyledText style={{ fontFamily: "Title", fontSize: 60, color: "white" }}>Certificado de Actualización y Reciclaje</StyledText>
							<StyledText style={{ fontSize: 16, color: "white", textAlign: "center" }}>
								Si dispones de licencias no oficiales de la RFEF, a partir de Julio de 2025 será necesario realizar 15 horas anuales de reciclaje de licencias de fútbol y 5 horas específicas de cada una de las especialidades para poder tramitar licencias.
							</StyledText>
							<StyledText style={{ fontSize: 16, color: "white", textAlign: "center" }}>
								La emisión oficial de este certificado tiene un coste de 25€
							</StyledText>
							<Button
								primary={true}
								round={7}
								onPress={pay}
								label={"Solicitar Certificado"}
							/>
						</View>
					</View>
				</View>


				<View style={{ flexGrow: 1, alignItems: "center" }}>
					<View style={{
						paddingVertical: 15,
						maxWidth: 1250,
						width: "100%",

					}}>
						{courses.length === 0 ?
							<View style={{ height: 300, borderRadius: 10, backgroundColor: "white", alignItems: "center", justifyContent: "center" }}>
								<StyledText style={{ fontSize: 20, fontWeight: "bold", marginTop: 20, textAlign: "center" }}>
									No hay cursos
								</StyledText>
							</View>
							:
							courses.map((x, i) =>
								<View style={[{ marginBottom: 10, backgroundColor: "white", borderRadius: 7, padding: 20, shadowOpacity: .5, shadowColor: "rgba(0,0,0,.2)", shadowRadius: 10, shadowOffset: { width: 0, height: 10 } }]}>
									<StyledText style={{ fontSize: 34, fontFamily: "Title" }}>{x?.name}</StyledText>
									<StyledText style={{ fontSize: 14, fontFamily: "TitleWide" }}>{x?.tag}</StyledText>
									<StyledText style={{ fontSize: 20, fontFamily: "TitleWide" }}>Horas: {x?.hours}</StyledText>
								</View>
							)}

					</View>
				</View>

				<Footer limited={false} />

			</ScrollView>
		</>
	);
};

export default Reevaluation;