import AppLoading from 'expo-app-loading';
import * as Font from 'expo-font';
import { initializeApp } from "firebase/app";
import moment from "moment";
import "moment/locale/es";
import React, { useState } from 'react';
import { initialWindowMetrics, SafeAreaProvider } from 'react-native-safe-area-context';
import { enableScreens } from 'react-native-screens';
import { QueryClient, QueryClientProvider } from 'react-query';
import regeneratorRuntime from "regenerator-runtime";
import AppNavigator from './navigation/AppNavigator';

regeneratorRuntime

const firebaseConfig = {
  apiKey: "AIzaSyB6BW7WUoViFY78YARBR4aXuZ20k8fGCMM",
  authDomain: "rfef-escuela-entrenadores.firebaseapp.com",
  projectId: "rfef-escuela-entrenadores",
  storageBucket: "rfef-escuela-entrenadores.appspot.com",
  messagingSenderId: "217494378237",
  appId: "1:217494378237:web:c6123723865db340891094",
  measurementId: "G-S6LQ8X7D1D",
  databaseURL: "https://rfef-escuela-entrenadores-default-rtdb.europe-west1.firebasedatabase.app"
};

// const firebaseConfig = {
//   apiKey: "AIzaSyCIfsfWxRJ8SyGgurCpMAhPflfNQouprSI",
//   authDomain: "rfef-escuela-entrenadores-dev.firebaseapp.com",
//   projectId: "rfef-escuela-entrenadores-dev",
//   storageBucket: "rfef-escuela-entrenadores-dev.appspot.com",
//   messagingSenderId: "157293833497",
//   appId: "1:157293833497:web:0926cc5fbd1ea127c12988"
// };

initializeApp(firebaseConfig);
const queryClient = new QueryClient();


// replace console.* for disable log on production
if (process.env.NODE_ENV === 'production') {
  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
}

enableScreens();

moment().locale("es");

moment.updateLocale('es', {
  relativeTime: {
    future: 'en %s',
    past: '%s',
    s: 'ahora',
    ss: '%ss',
    m: 'un minuto',
    mm: '%dm',
    h: 'una hora',
    hh: '%dh',
    d: 'un día',
    dd: '%dd',
    M: 'un mes',
    MM: '%dM',
    y: 'un año',
    yy: '%dY'
  }
});


export default function App(props) {

  const [isLoadingComplete, setLoadingComplete] = useState(false);

  if (!isLoadingComplete && !props.skipLoadingScreen) {
    return (
      <AppLoading startAsync={loadResourcesAsync} onError={handleLoadingError} onFinish={() => handleFinishLoading(setLoadingComplete)} />
    );
  } else {
    return (
      <SafeAreaProvider initialMetrics={initialWindowMetrics}>
        <QueryClientProvider client={queryClient}>
          <AppNavigator />
        </QueryClientProvider>
      </SafeAreaProvider>
    );
  }
}

async function loadResourcesAsync() {
  await Promise.all([
    Font.loadAsync({
      'Title': require("./assets/fonts/La_Roja-EstrechaRegular.otf"),
      'TitleWide': require("./assets/fonts/La_Roja-AnchaRegular.otf"),
      'Regular': require("./assets/fonts/MRR.otf"),
      'DemiBold': require("./assets/fonts/MRSB.otf"),
      'Bold': require("./assets/fonts/MRB.otf"),
    }),
  ]);
}

function handleLoadingError(error) {
  console.warn(error);
}

function handleFinishLoading(setLoadingComplete) {
  setLoadingComplete(true);
}