import { useNavigation, useRoute } from '@react-navigation/native';
import React, { useContext, useEffect, useState } from 'react';
import { ScrollView } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import PathContainer from '../../components/itineraryComponentsNew/PathContainer';
import AdvanceContext from '../../context/AdvanceContext';


const Course = () => {

	const { id } = useRoute().params
	const { courses } = useContext(AdvanceContext)
	const isPhone = useMediaQuery({ query: '(max-width: 1000px)' })
	const [course, setCourse] = useState({})
	const navigation = useNavigation()

	useEffect(() => {
		if (id && courses) {
			const course = courses.find(c => c.id === id)
			if (course) {
				if (course?.advance?.status !== "APPROVED") {
					window.location.href = "/alumno/cursos"
					return
				}
				setCourse(course)
			}
		}
	}, [id, courses])



	return (
		<ScrollView style={{ flex: 1 }} contentContainerStyle={{ paddingVertical: 30, paddingHorizontal: 15, paddingTop: isPhone ? 15 : 50, maxWidth: 1200, width: "100%", alignSelf: "center" }}>
			{course?.id && <PathContainer course={course} />}
		</ScrollView>
	);
}

export default Course;
