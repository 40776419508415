import { useNavigation } from '@react-navigation/native';
import { useFirestoreQuery } from '@react-query-firebase/firestore';
import { addDoc, collection, deleteDoc, doc, getFirestore, query, updateDoc } from 'firebase/firestore';
import { deleteObject, getStorage, ref, uploadBytes, uploadString } from 'firebase/storage';
import React, { useContext, useState } from 'react';
import { Pressable, ScrollView, TextInput, View } from 'react-native';
import Button from '../../components/common/Button';
import Modal from '../../components/common/Modal';
import SecureImage from '../../components/common/SecureImage';
import StyledText from '../../components/common/StyledText';
import AuthContext from '../../context/AuthContext';

function secondsToHMS(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.round(seconds % 60);

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

const VideoExercises = () => {

    const [tab, setTab] = React.useState(0)


    return (
        <ScrollView contentContainerStyle={{ padding: 15, gap: 10, maxWidth: 1000, alignItems: "center", width: "100%", alignSelf: "center" }}>
            <View style={{ gap: 10, flexDirection: "row", alignItems: "center", width: "100%" }}>
                <View style={{ flex: 1 }}>
                    <Button color={tab === 0 ? "#0b1831" : "#0b1831"} primary={tab === 0} width='100%' height={35} round={7} onPress={() => setTab(0)} label="Videos" />
                </View>
                <View style={{ flex: 1 }}>
                    <Button color={tab === 1 ? "#0b1831" : "#0b1831"} primary={tab === 1} width='100%' height={35} round={7} onPress={() => setTab(1)} label="Botoneras" />
                </View>
            </View>

            {tab === 0 ? <ExercisesTab /> : tab === 1 ? <SessionsTab /> : null}

        </ScrollView>
    );
}

export default VideoExercises;



const ExercisesTab = () => {
    const { userData } = useContext(AuthContext)
    const storage = getStorage()

    const navigation = useNavigation()
    const [loading, setLoading] = useState<boolean>(false)
    const [editModal, setEditModal] = useState<{ name: string, id: string } | undefined>()
    const inputRef = React.useRef<any>(null)
    const db = getFirestore()
    const docRef = query(collection(db, `users/${userData.id}/videoExercises`))
    const { data, isLoading } = useFirestoreQuery(["videoExercises", userData.id], docRef, {
        subscribe: true
    }, {
        select: (d) => d.docs.map(x => ({ ...x.data(), id: x.id, ref: x.ref })).sort((a, b) => {
            const dateA = a?.updatedAt?.toDate()
            const dateB = b?.updatedAt?.toDate()
            return dateB.getTime() - dateA.getTime()
        })
    })



    const generateThumbnail = async (base64Video) => {

        return new Promise((resolve, reject) => {

            const videoElement = document.createElement('video');
            videoElement.src = URL.createObjectURL(base64Video)
            videoElement.currentTime = 1;


            videoElement.addEventListener('seeked', function () {

                const canvas = document.createElement('canvas');
                canvas.width = videoElement.videoWidth / 5;
                canvas.height = videoElement.videoHeight / 5;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

                const thumbnailBase64 = canvas.toDataURL('image/jpg');
                resolve({
                    thumbnail: thumbnailBase64,
                    duration: videoElement.duration,
                    width: videoElement.videoWidth,
                    height: videoElement.videoHeight
                })
            });

            videoElement.addEventListener('error', function (e) {
                reject(e)
            })
        })
    }

    const edit = () => {
        const docRef = doc(db, `users/${userData.id}/videoExercises/${editModal?.id}`)
        updateDoc(docRef, {
            name: editModal?.name,
            updatedAt: new Date()
        }).then(res => {
            alert("Ejercicio actualizado correctamente")
            setEditModal(undefined)
        }).catch(err => {
            console.log(err)
            alert("Error al actualizar el ejercicio")
        })
    }


    const uploadVideo = async (ev) => {
        setLoading(true)

        try {
            const file = ev.target.files[0] as File


            if (file) {

                if (file.size / 1024 / 1024 > 4000) {
                    alert("El archivo no puede ser mayor a 4000MB")
                    setLoading(false)
                    return
                }
                if (file.type.includes("quicktime")) {
                    alert("El formato de video no es compatible")
                    setLoading(false)
                    return
                }

                try {
                    setLoading(true)
                    // getVideoMetadata(res.uri)
                    const videoData = ((await generateThumbnail(file)) || "") as {
                        thumbnail: string,
                        duration: number,
                        width: number,
                        height: number

                    }

                    const fileRef = ref(storage, `users/${userData.id}/camelotvideos/${new Date().getTime()}-${file.name}`);
                    const thumbnailRef = ref(storage, `users/${userData.id}/camelotvideos/${new Date().getTime()}-thumb-${file.name.slice(0, file.name.lastIndexOf('.'))}.png`);

                    await uploadString(thumbnailRef, videoData.thumbnail.replace(`data:image/png;base64,`, ""), "base64", { contentType: "image/png" })
                    await uploadBytes(fileRef, file)

                    addDoc(collection(db, `users/${userData.id}/videoExercises`), {
                        createdAt: new Date(),
                        updatedAt: new Date(),
                        name: file.name.slice(0, file.name.lastIndexOf('.')) || "Video sin nombre",
                        thumbnail: thumbnailRef.fullPath,
                        media: fileRef.fullPath,
                        events: [],
                        draws: [],
                        duration: videoData.duration,
                        width: videoData.width,
                        height: videoData.height
                    })

                    setLoading(false)

                } catch (err) {
                    alert("Ha ocurrido un error cargando el documento")
                    setLoading(false)
                    console.log(err)
                }
            } else {
                setLoading(false)
            }

        } catch (err) {
            console.log(err)
            alert("Ocurrió un error cargando el docuento")
            setLoading(false)
        }

    }


    const deleteData = async (data) => {
        const p = confirm("¿Estás seguro de que quieres eliminar este ejercicio?")
        if (!p) return

        try {

            if (data.media) {
                const media = ref(storage, data.media)
                await deleteObject(media)
            }

            if (data.thumbnail) {
                const thumbnail = ref(storage, data.thumbnail)
                await deleteObject(thumbnail)
            }


            if (data.draws?.length > 0) {
                data.draws.forEach(async (draw) => {
                    console.log(draw)

                    const base = ref(storage, `users/${userData.id}/videoExercises/${data.id}/draws/base-${draw.id}.png`)
                    await deleteObject(base)


                    const thumbnail = ref(storage, `users/${userData.id}/videoExercises/${data.id}/draws/${draw.id}.png`)
                    await deleteObject(thumbnail)


                    await deleteDoc(doc(db, `users/${userData.id}/videoExercises/${data.id}/draws/${draw.id}`)).catch(err => null)
                })
            }

            await deleteDoc(doc(db, `users/${userData.id}/videoExercises/${data.id}`))

        } catch (err) {
            console.log(err)
            alert("Error al eliminar el ejercicio")
        }
    }

    return (
        <>
            {editModal && <Modal visible={editModal} onClose={() => setEditModal(undefined)}>
                <View style={{ padding: 15, width: 400, maxWidth: "100%", justifyContent: "center" }}>
                    <StyledText style={{ fontSize: 24, fontWeight: "bold", marginBottom: 15 }}>Editar Video</StyledText>
                    <StyledText style={{ fontSize: 18, marginBottom: 15 }}>Nombre</StyledText>
                    <TextInput autoFocus placeholder='Nombre del Ejercicio...' value={editModal?.name} onChange={(ev) => setEditModal({ ...editModal, name: ev.target.value })} style={{ width: "100%", padding: 10, borderRadius: 7, backgroundColor: "#f3f3f3" }} />
                </View>
                <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", gap: 10, padding: 15 }}>
                    <Button color="#f3f3f3" height={35} round={7} onPress={() => setEditModal(undefined)} label="Cancelar" icon={undefined} children={undefined} textColor={undefined} />
                    <Button height={35} round={7} onPress={edit} label="Guardar" icon={undefined} color={undefined} children={undefined} textColor={undefined} />
                </View>
            </Modal>}

            <input onChange={uploadVideo} ref={inputRef} type="file" id="file" accept="video/*" style={{ display: "none" }} />

            <View style={{ flexDirection: "row", alignItems: "center", width: "100%" }}>
                <View style={{ flex: 1 }}>
                    <StyledText style={{ fontFamily: "TitleWide", fontSize: 24, fontWeight: "bold" }}>Mis Videos</StyledText>
                </View>
                <Button height={35} round={7} loading={loading} onPress={() => inputRef?.current?.click()} label="Cargar Video" icon={undefined} color={undefined} children={undefined} textColor={undefined} />
            </View>
            {data?.length === 0 ?
                <View style={{ padding: 100, gap: 15, alignItems: "center", justifyContent: "center", width: "100%", borderRadius: 8, backgroundColor: "#f3f3f3" }}>
                    <StyledText>No hay Videos</StyledText>
                    <Button height={35} round={7} loading={loading} onPress={() => inputRef?.current?.click()} label="Cargar Video" icon={undefined} color={undefined} children={undefined} textColor={undefined} />
                </View>
                :
                data?.map((ex: any) => (
                    <Pressable
                        key={ex.id}
                        onPress={() => navigation.navigate("VideoExercise", { id: ex.id })}
                        style={{ width: "100%", padding: 15, borderRadius: 7, backgroundColor: "white", flexDirection: "row", alignItems: "center", justifyContent: "space-between", gap: 10, shadowColor: "#000", shadowOffset: { width: 0, height: 5 }, shadowOpacity: 0.15, shadowRadius: 10, elevation: 5 }}
                    >
                        <View style={{ flex: 1, flexDirection: "row", alignItems: "center", gap: 10 }}>
                            <SecureImage uri={ex.thumbnail} style={{ width: 100, height: 60, borderRadius: 8 }} placeholder={undefined} />
                            <View style={{ gap: 5, flex: 1 }}>
                                <StyledText numberOfLines={1} style={{ fontFamily: "DemiBold", fontSize: 18 }}>{ex.name}</StyledText>
                                <StyledText style={{ fontSize: 14 }}>Duración: {secondsToHMS(ex.duration)}</StyledText>
                            </View>
                        </View>
                        <View style={{ flexDirection: "row", gap: 10 }}>
                            <Button color="#0b1831" height={35} round={7} onPress={() => setEditModal({ name: ex.name, id: ex.id })} label="Editar" primary={false} icon={undefined} children={undefined} textColor={undefined} />
                            <Button color="#0b1831" height={35} round={7} onPress={() => navigation.navigate("VideoExercise", { id: ex.id })} label="Analizar" icon={undefined} children={undefined} textColor={undefined} />
                            <Button iconSize={18} height={35} paddingHorizontal={5} round={7} onPress={() => deleteData(ex)} icon="trash" color={undefined} children={undefined} textColor={undefined} />
                        </View>
                    </Pressable>
                ))}
        </>
    )
}

const SessionsTab = () => {
    const { userData } = useContext(AuthContext)

    const navigation = useNavigation()
    const db = getFirestore()
    const docRef = query(collection(db, `users/${userData.id}/eventSets`))
    const { data, isLoading } = useFirestoreQuery(["eventSets", userData.id], docRef, {
        subscribe: true
    }, {
        select: (d) => d.docs.map(x => ({ ...x.data(), id: x.id, ref: x.ref })).sort((a, b) => {
            const dateA = a.updatedAt.toDate()
            const dateB = b.updatedAt.toDate()
            return dateB.getTime() - dateA.getTime()
        })
    })


    const deleteData = async (id: string) => {
        const p = confirm("¿Estás seguro de que quieres eliminar esta botonera?")
        if (!p) return
        deleteDoc(doc(db, `users/${userData.id}/eventSets/${id}`)).then(res => {
            alert("Botonera eliminado correctamente")
        }).catch(err => {
            alert("Error al eliminar la botonera")
        })
    }

    return (
        <>
            <View style={{ flexDirection: "row", alignItems: "center", width: "100%" }}>
                <View style={{ flex: 1 }}>
                    <StyledText style={{ fontFamily: "TitleWide", fontSize: 24, fontWeight: "bold" }}>Mis Botoneras</StyledText>
                </View>
                <Button height={35} round={7} onPress={() => navigation.navigate("EventSet", { id: "new" })} label="Nueva Botonera" />
            </View>
            {data?.length === 0 ?
                <View style={{ padding: 100, gap: 15, alignItems: "center", justifyContent: "center", width: "100%", borderRadius: 8, backgroundColor: "#f3f3f3" }}>
                    <StyledText>No hay botoneras</StyledText>
                    <Button height={35} round={7} onPress={() => navigation.navigate("EventSet", { id: "new" })} label="Nueva Botonera" />
                </View>
                :
                data?.map((ex: any) => (
                    <Pressable
                        key={ex.id}
                        onPress={() => navigation.navigate("EventSet", { id: ex.id })}
                        style={{ width: "100%", padding: 15, borderRadius: 7, backgroundColor: "white", flexDirection: "row", alignItems: "center", justifyContent: "space-between", gap: 10, shadowColor: "#000", shadowOffset: { width: 0, height: 5 }, shadowOpacity: 0.15, shadowRadius: 10, elevation: 5 }}
                    >
                        <View style={{ flexDirection: "row", alignItems: "center", gap: 10 }}>
                            {/* <SecureImage uri={ex.thumbnail} style={{ width: 100, height: 60, borderRadius: 8 }} /> */}
                            <StyledText style={{ fontSize: 18 }}>{ex.name}</StyledText>
                        </View>
                        <View style={{ flexDirection: "row", gap: 10 }}>
                            <Button color="#0b1831" height={35} round={7} onPress={() => navigation.navigate("EventSet", { id: ex.id })} label="Editar" />
                            <Button iconSize={18} height={35} paddingHorizontal={5} round={7} onPress={() => deleteData(ex.id)} icon="trash" />
                        </View>
                    </Pressable>
                ))}
        </>
    )
}
