import { Ionicons } from '@expo/vector-icons';
import { useNavigation, useRoute } from '@react-navigation/native';
import { addDoc, collection, doc, getDoc, getFirestore, serverTimestamp, setDoc, updateDoc } from 'firebase/firestore';
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ActivityIndicator, Image, Pressable, ScrollView, View, useWindowDimensions } from 'react-native';
import Button from '../../components/common/Button';
import StyledText from '../../components/common/StyledText';
import SelectField from '../../components/formComponents/SelectField';
import TextField from '../../components/formComponents/TextField';
import AuthContext from '../../context/AuthContext';
import Board from './Board';

import { Document, Font, Image as ImagePDF, PDFDownloadLink, Page, StyleSheet, Text as TextPDF, View as ViewPDF } from "@react-pdf/renderer";
import { getDownloadURL, getStorage, ref, uploadBytes, uploadString } from 'firebase/storage';
import { omit } from 'lodash';
import LaRojaAnchaBold from "../../assets/fonts/La_Roja-AnchaBold.otf";
import LaRojaAncha from "../../assets/fonts/La_Roja-AnchaRegular.otf";
import LaRojaBold from "../../assets/fonts/La_Roja-EstrechaBold.otf";
import LaRoja from "../../assets/fonts/La_Roja-EstrechaRegular.otf";
import MontserratBold from "../../assets/fonts/MRB.otf";
import Montserrat from "../../assets/fonts/MRR.otf";
import Modal from '../../components/common/Modal';


Font.register({
    family: "LaRojaPDF",
    fonts: [
        {
            src: LaRoja,
        },
        {
            src: LaRojaBold,
            fontWeight: "bold",
        },
    ],
});
Font.register({
    family: "MontserratPDF",
    fonts: [
        {
            src: Montserrat,
        },
    ],
});
Font.register({
    family: "MontserratBoldPDF",
    fonts: [
        {
            src: MontserratBold,
        },
    ],
});
Font.register({
    family: "LaRojaAnchaPDF",
    fonts: [
        {
            src: LaRojaAncha,
        },
        {
            src: LaRojaAnchaBold,
            fontWeight: "bold",
        },
    ],
});

const defaultValues = {
    name: "",
    time: "",
    space: "",
    age: "",
    numberOfPlayers: "",
    complexity: "",
    strategy: "",
    habilities: "",
    intention: "",
    dynamic: "",
    gameSituation: "",
    coordination: "",
    link: "",
    goals: "",
    explanation: "",
    data: null,
    thumbnail: ""
}

const TemplateQRPage = ({ data, image }) => {

    return (
        <Document>
            <Page size={"A4"} wrap={true} style={{ paddingBottom: 10 }}>
                <ViewPDF wrap={true}>
                    <ViewPDF fixed style={{ flexDirection: "row", backgroundColor: "#e4002a", width: "100%", padding: 20, paddingVertical: 10, alignItems: "center", marginBottom: 10 }}>
                        <ImagePDF allowDangerousPaths src={"https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/OF4VYpRfgUJEH5rG2syJ%2Fadmin%2Fimages%2F1654849409559-logo-rfef.png?alt=media&token=4e19e230-b0dc-44b2-b58d-558d94f177a8"} style={{ width: 30, height: 30, marginRight: 10 }} />
                        <TextPDF style={styles.classTitle}>Entrega Ejercicio</TextPDF>
                    </ViewPDF>

                    <ViewPDF style={{ padding: 20, paddingTop: 0 }}>

                        <ViewPDF style={{ width: "100%", flexDirection: "row", marginBottom: 10, }}>
                            <ViewPDF style={{ flex: 1 }}>
                                <ViewPDF style={styles.header}>
                                    <TextPDF style={[styles.headerText, { marginLeft: 5 }]}>Detalles del Ejercicio</TextPDF>
                                </ViewPDF>
                                <ViewPDF style={[styles.container, { padding: 0, flexDirection: "row" }]}>
                                    <ViewPDF style={{ width: "20%", flexDirection: "column", backgroundColor: "#f4f4f4" }}>
                                        <ViewPDF style={{ justifyContent: "center", paddingHorizontal: 10, height: 25, width: "100%", borderBottomWidth: 1 }}><TextPDF style={styles.text}>Título</TextPDF></ViewPDF>
                                        <ViewPDF style={{ justifyContent: "center", paddingHorizontal: 10, height: 25, width: "100%", borderBottomWidth: 1 }}><TextPDF style={styles.text}>Estrategia</TextPDF></ViewPDF>
                                        <ViewPDF style={{ justifyContent: "center", paddingHorizontal: 10, minHeight: 25, paddingVertical: 5, height: "auto", width: "auto" }}><TextPDF style={styles.text}>Objetivos</TextPDF></ViewPDF>
                                    </ViewPDF>
                                    <ViewPDF style={{ width: "80%", flexDirection: "column" }}>
                                        <ViewPDF style={{ justifyContent: "center", paddingHorizontal: 10, height: 25, width: "100%", borderBottomWidth: 1, borderLeftWidth: 1 }}><TextPDF style={styles.text}>{data.name}</TextPDF></ViewPDF>
                                        <ViewPDF style={{ justifyContent: "center", paddingHorizontal: 10, height: 25, width: "100%", borderBottomWidth: 1, borderLeftWidth: 1 }}><TextPDF style={styles.text}>{data.strategy}</TextPDF></ViewPDF>
                                        <ViewPDF style={{ justifyContent: "center", paddingHorizontal: 10, minHeight: 25, paddingVertical: 5, height: "auto", width: "100%", borderLeftWidth: 1 }}><TextPDF style={styles.text}>{data.goals}</TextPDF></ViewPDF>
                                    </ViewPDF>
                                </ViewPDF>

                                <ViewPDF style={[styles.container, { padding: 0, flexDirection: "row", marginTop: 5 }]}>
                                    <ViewPDF style={{ flex: 1, flexDirection: "column" }}>
                                        <ViewPDF style={{ backgroundColor: "#f4f4f4", justifyContent: "center", paddingHorizontal: 10, height: 25, width: "100%", borderTopWidth: 1, borderBottomWidth: 1, borderRightWidth: 1 }}><TextPDF style={styles.text}>Espacio</TextPDF></ViewPDF>
                                        <ViewPDF style={{ justifyContent: "center", paddingHorizontal: 10, height: 25, width: "100%", borderRightWidth: 1 }}><TextPDF style={styles.text}>{data.space}</TextPDF></ViewPDF>
                                    </ViewPDF>
                                    <ViewPDF style={{ flex: 1, flexDirection: "column" }}>
                                        <ViewPDF style={{ backgroundColor: "#f4f4f4", justifyContent: "center", paddingHorizontal: 10, height: 25, width: "100%", borderTopWidth: 1, borderBottomWidth: 1, borderRightWidth: 1 }}><TextPDF style={styles.text}>Tiempo</TextPDF></ViewPDF>
                                        <ViewPDF style={{ justifyContent: "center", paddingHorizontal: 10, height: 25, width: "100%", borderRightWidth: 1 }}><TextPDF style={styles.text}>{data.time}</TextPDF></ViewPDF>
                                    </ViewPDF>
                                    <ViewPDF style={{ flex: 1, flexDirection: "column" }}>
                                        <ViewPDF style={{ backgroundColor: "#f4f4f4", justifyContent: "center", paddingHorizontal: 10, height: 25, width: "100%", borderTopWidth: 1, borderBottomWidth: 1, borderRightWidth: 1 }}><TextPDF style={styles.text}>Situación de Juego</TextPDF></ViewPDF>
                                        <ViewPDF style={{ justifyContent: "center", paddingHorizontal: 10, height: 25, width: "100%", borderRightWidth: 1 }}><TextPDF style={styles.text}>{data.gameSituation}</TextPDF></ViewPDF>
                                    </ViewPDF>
                                    <ViewPDF style={{ flex: 1, flexDirection: "column" }}>
                                        <ViewPDF style={{ backgroundColor: "#f4f4f4", justifyContent: "center", paddingHorizontal: 10, height: 25, width: "100%", borderTopWidth: 1, borderBottomWidth: 1 }}><TextPDF style={styles.text}>Coordinación</TextPDF></ViewPDF>
                                        <ViewPDF style={{ justifyContent: "center", paddingHorizontal: 10, height: 25, width: "100%" }}><TextPDF style={styles.text}>{data.coordination}</TextPDF></ViewPDF>
                                    </ViewPDF>

                                </ViewPDF>


                                <ViewPDF style={[styles.container, { padding: 0, flexDirection: "row", marginTop: 5 }]}>
                                    <ViewPDF style={{ flex: 1, flexDirection: "column" }}>
                                        <ViewPDF style={{ backgroundColor: "#f4f4f4", justifyContent: "center", paddingHorizontal: 10, height: 25, width: "100%", borderTopWidth: 1, borderBottomWidth: 1, borderRightWidth: 1 }}><TextPDF style={styles.text}>Habilidades Coordinativas</TextPDF></ViewPDF>
                                        <ViewPDF style={{ justifyContent: "center", paddingHorizontal: 10, height: 25, width: "100%", borderRightWidth: 1 }}><TextPDF style={styles.text}>{data.habilities}</TextPDF></ViewPDF>
                                    </ViewPDF>
                                    <ViewPDF style={{ flex: 1, flexDirection: "column" }}>
                                        <ViewPDF style={{ backgroundColor: "#f4f4f4", justifyContent: "center", paddingHorizontal: 10, height: 25, width: "100%", borderTopWidth: 1, borderBottomWidth: 1 }}><TextPDF style={styles.text}>Intención /  Acción Táctica</TextPDF></ViewPDF>
                                        <ViewPDF style={{ justifyContent: "center", paddingHorizontal: 10, height: 25, width: "100%" }}><TextPDF style={styles.text}>{data.intention}</TextPDF></ViewPDF>
                                    </ViewPDF>


                                </ViewPDF>
                            </ViewPDF>
                        </ViewPDF>

                        <ViewPDF style={{ width: "100%", flexDirection: "row", marginBottom: 10, marginTop: 0 }} wrap={false}>
                            <ViewPDF style={{ flex: 1 }}>
                                <ViewPDF style={styles.header}>
                                    <TextPDF style={[styles.headerText, { marginLeft: 5 }]}>Descripción Gráfica</TextPDF>
                                </ViewPDF>
                                <ViewPDF style={[styles.container, { padding: 2, alignItems: "center", justifyContent: "center" }]}>
                                    {image ? <ImagePDF allowDangerousPaths src={image} style={{ width: "80%", height: "auto", maxHeight: 400, objectFit: "contain" }} /> : null}
                                </ViewPDF>
                            </ViewPDF>
                        </ViewPDF>

                        <ViewPDF style={{ width: "100%", flexDirection: "row", marginBottom: 0, marginTop: 0 }}>
                            <ViewPDF style={{ flex: 1 }}>
                                <ViewPDF style={styles.header}>
                                    <TextPDF style={[styles.headerText, { marginLeft: 5 }]}>Consigna / Explicación</TextPDF>
                                </ViewPDF>
                                <ViewPDF style={styles.container}>
                                    <TextPDF style={styles.text}>{data.explanation}</TextPDF>
                                </ViewPDF>
                            </ViewPDF>
                        </ViewPDF>



                    </ViewPDF>
                </ViewPDF>
            </Page>


        </Document>
    );
};

const Exercise = () => {

    const { userData } = useContext(AuthContext)
    const { id } = useRoute().params as any
    const db = getFirestore()
    const navigation = useNavigation()
    const storage = getStorage()

    const [isLoading, setIsLoading] = React.useState(false)
    const [viewBoard, setViewBoard] = React.useState(false)
    const [dataThumb, setDataThumb] = React.useState(null)

    const screen = useWindowDimensions()

    const {
        handleSubmit,
        control,
        reset,
        getValues,
        setValue,
        formState: { errors },
    } = useForm({
        mode: "onChange",
        defaultValues,
    });

    useEffect(() => {
        lockBack()
        return () => unlockBack()
    }, [])

    const lockBack = () => {
        const html = document.querySelector('html');
        html.style.overscrollBehaviorX = 'none';
    }

    const unlockBack = () => {
        const html = document.querySelector('html');
        html.style.removeProperty('overscroll-behavior-x');
    }


    useEffect(() => {
        reset(defaultValues)
        if (userData.id && id && id !== "new") {
            fetchExercise()
        }
        if (id === "new") setDataThumb(null)
    }, [userData?.id, id])

    const fetchExercise = async () => {
        setIsLoading(true)
        setDataThumb(null)

        const path = `users/${userData.id}/exercises/${id}`
        const docRef = doc(db, path)
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {

            const data = docSnap.data()

            reset(docSnap.data())

            if (data.thumbnail) {
                await getDownloadURL(ref(storage, data.thumbnail)).then(async res => {
                    setDataThumb(res)
                })
            }
            if (data.snapshot) {
                await getDownloadURL(ref(storage, data.snapshot))
                    .then(async res => {
                        fetch(res)
                            .then(res => res.json())
                            .then(d => {
                                setValue("data", d)
                            }).catch(err => {
                                console.log(err)
                            })
                    }).catch(err => {
                        console.log(err)
                    })
            }

            setIsLoading(false)
        } else {
            setIsLoading(false)
            alert("No existe el ejercicio")
        }
    }

    const onSubmit = (data: any) => {

        if (id === "new") {
            addDoc(collection(db, `users/${userData.id}/exercises`), {
                ...omit(data, ["data"]),
                updatedAt: serverTimestamp(),
                createdAt: serverTimestamp(),
            }).then(res => {
                navigation.setParams({ id: res.id })
            }).catch(err => {
                alert("Ha ocurrido un error al guardar el ejercicio")
            })
        } else {
            updateDoc(doc(db, `users/${userData.id}/exercises/${id}`), {
                ...omit(data, ["data"]),
                data: null,
                updatedAt: serverTimestamp(),
            }).then(res => {
                alert("Ejercicio guardado correctamente")
            }).catch(err => {
                alert("Ha ocurrido un error al guardar el ejercicio")
            })
        }
    }

    const saveDraw = async (ev) => {

        const docRef = id === "new" ? doc(collection(db, `users/${userData.id}/exercises`)) : doc(db, `users/${userData.id}/exercises/${id}`)
        const thumbnailUri = `users/${userData.id}/exercises/${docRef.id}.png`
        const snapshotUri = `users/${userData.id}/exercises/${docRef.id}.json`

        setValue("thumbnail", thumbnailUri)

        const thumbnailRef = ref(storage, thumbnailUri)
        const snapshotRef = ref(storage, snapshotUri)

        try {

            const thumbnail = await uploadBytes(thumbnailRef, ev.svg)
            const snapshot = await uploadString(snapshotRef, ev.snap, "raw")

            const values = {
                ...omit(getValues(), ["data"]),
                thumbnail: thumbnailUri,
                snapshot: snapshotUri,
                updatedAt: serverTimestamp(),
                data: null,
            }

            if (id === "new") {
                setDoc(docRef, {
                    ...values,
                    createdAt: serverTimestamp(),
                }).then(res => {
                    navigation.setParams({ id: docRef.id })
                    alert("Ejercicio guardado correctamente")
                })

            } else {
                updateDoc(docRef, values).then(res => {
                    alert("Ejercicio guardado correctamente")
                })
            }

            setViewBoard(false)

        } catch (error) {
            console.log(error)
            alert("Ha ocurrido un error al guardar el ejercicio")
        }

        if (thumbnailRef) {
            getDownloadURL(thumbnailRef).then(res => {
                setDataThumb(res)
            })
        }
    }

    if (isLoading) return (
        <View style={{ flex: 1, minHeight: 400, alignItems: "center", justifyContent: "center" }}>
            <ActivityIndicator />
        </View>
    )

    return (
        <>
            {viewBoard ?
                <Modal modalContainerStyle={{ height: "100%", width: "100%", padding: 0 }} padding={0} margin={0}>
                    <View style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: viewBoard ? 100 : -1 }}>
                        <Board
                            closeCallback={() => setViewBoard(false)}
                            saveCallback={saveDraw}
                            initialData={getValues("data")}
                            id={id}
                            style={{ flex: 1 }}
                        />
                    </View>
                </Modal>
                :
                null
            }
            <ScrollView pointerEvents={viewBoard ? "none" : undefined} contentContainerStyle={{ zIndex: viewBoard ? -1 : undefined, padding: 15, gap: 10, maxWidth: 1000, width: "100%", alignSelf: "center" }}>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Pressable onPress={() => navigation.navigate("Exercises")}>
                        <Ionicons name="chevron-back" size={24} color="black" />
                    </Pressable>
                    <View style={{ flex: 1 }}>
                        <StyledText style={{ fontFamily: "TitleWide", fontSize: 24, fontWeight: "bold" }}>{id !== "new" ? "Editar" : "Nuevo"} Ejercicio</StyledText>
                    </View>
                    <View style={{ flexDirection: "row", gap: 10 }}>
                        {id !== "new" &&
                            <PDFDownloadLink
                                document={<TemplateQRPage data={getValues()} image={dataThumb} />}
                                style={{ textDecoration: "none" }}
                                fileName={`${getValues("name") + "-" + new Date().toISOString()}.pdf`}
                            >
                                {({ loading }) =>
                                    // <Button primary disabled={loading} label={"Descargar"} />
                                    <Button
                                        textStyles={{ textDecoration: "none" }}
                                        height={35}
                                        round={7}
                                        disabled={loading}
                                        label="Imprimir"
                                        primary={false}
                                        onPress={() => { }}
                                    />
                                }
                            </PDFDownloadLink>
                        }

                        <Button color="#0b1831" height={35} round={7} onPress={() => setViewBoard(true)} label="Abrir Pizarra" />
                        <Button height={35} round={7} onPress={handleSubmit(onSubmit)} label={"Guardar"} />
                    </View>
                </View>
                <View style={{ width: "100%", padding: 15, borderRadius: 7, backgroundColor: "white", gap: 10, shadowColor: "#000", shadowOffset: { width: 0, height: 5 }, shadowOpacity: 0.15, shadowRadius: 10, elevation: 5 }}>
                    <TextField error={errors.name} control={control} name="name" label="Nombre" />
                    <View style={{ flexDirection: 'row', gap: 10 }}>
                        <View style={{ flex: 1 }}>
                            <TextField error={errors.goals} control={control} name="goals" label="Objetivos del Ejercicio" multiline={"true"} textinputstyle={{ minHeight: 100, paddingTop: 15 }} />
                        </View>
                        <View style={{ flex: 1 }}>
                            <TextField error={errors.explanation} control={control} name="explanation" label="Explicación / Detalles" multiline={"true"} textinputstyle={{ minHeight: 100, paddingTop: 15 }} />
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', gap: 10 }}>
                        <TextField error={errors.time} control={control} name="time" label="Tiempo(min)" />
                        <TextField error={errors.space} control={control} name="space" label="Espacio" />
                        <TextField error={errors.age} control={control} name="age" label="Edad" />
                    </View>
                    <View style={{ flexDirection: 'row', gap: 10 }}>
                        <TextField error={errors.numberOfPlayers} control={control} name="numberOfPlayers" label="N Jugadores" />
                        <SelectField valueKey="value" labelKey="label" options={complexity} error={errors.complexity} control={control} name="complexity" label="Complejidad" />
                        <View style={{ flex: 1 }} />
                    </View>
                    <View style={{ flexDirection: 'row', gap: 10 }}>
                        <SelectField valueKey="value" labelKey="label" options={strategy} error={errors.strategy} control={control} name="strategy" label="Estrategia" />
                        <SelectField valueKey="value" labelKey="label" options={habilities} error={errors.habilities} control={control} name="habilities" label="Habilidades Coordinativas" />
                        <SelectField valueKey="value" labelKey="label" options={intention} error={errors.intention} control={control} name="intention" label="Intención Táctica" />
                    </View>
                    <View style={{ flexDirection: 'row', gap: 10 }}>
                        <SelectField valueKey="value" labelKey="label" options={dynamic} error={errors.dynamic} control={control} name="dynamic" label="Dinàmica" />
                        <SelectField valueKey="value" labelKey="label" options={gameSituation} error={errors.gameSituation} control={control} name="gameSituation" label="Situación de Juego" />
                        <SelectField valueKey="value" labelKey="label" options={coordination} error={errors.coordination} control={control} name="coordination" label="Coordinación" />
                    </View>

                    {dataThumb &&
                        <View style={{ width: "100%", alignItems: "center", backgroundColor: "#f2f2f2", borderRadius: 7 }}>
                            <Image key={dataThumb} source={{ uri: dataThumb }} style={{ resizeMode: "contain", maxWidth: 600, width: screen?.width, aspectRatio: 16 / 10 }} />
                        </View>
                    }

                </View>
            </ScrollView >
        </>
    );
}

export default Exercise;



const styles = StyleSheet.create({
    courseTitle: {
        fontFamily: "LaRojaPDF",
        textAlign: "center",
        color: "white",
        fontSize: 20,
        fontWeight: "bold",
    },
    pin: {
        fontFamily: "LaRojaPDF",
        textAlign: "center",
        padding: 10,
        fontSize: 30,
    },
    classTitle: {
        fontFamily: "LaRojaAnchaPDF",
        textAlign: "center",
        color: "white",
        fontSize: 16,
        fontWeight: "bold",
    },
    header: {
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        padding: 10,
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: "#e4002a",
        width: "100%",
    },
    container: {
        padding: 10,
        borderWidth: 1,
        borderTopWidth: 0,
        width: "100%",
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
    },
    headerText: {
        color: "white",
        fontSize: 8,
        fontFamily: "LaRojaAnchaPDF",
        fontWeight: "bold",
    },
    text: {
        fontSize: 10,
        fontFamily: "MontserratPDF",
    },
});


const complexity = [
    { label: "Baja", value: "Baja", id: "0" },
    { label: "Media", value: "Media", id: "1" },
    { label: "Alta", value: "Alta", id: "2" },
    { label: "Muy Alta", value: "Muy Alta", id: "3" },
]

const coordination = [
    { label: "Coordinación Equipo", value: "Coordinación Equipo", id: "0" },
    { label: "Coordinación Jugador", value: "Coordinación Jugador", id: "1" },
    { label: "Coordinación Jugadores", value: "Coordinación Jugadores", id: "2" },
]

const gameSituation = [
    { label: "Estructura Completa", value: "Estructura Completa", id: "0" },
    { label: "Intersectorial", value: "Intersectorial", id: "1" },
    { label: "Sectorial", value: "Sectorial", id: "2" },
]


const dynamic = [
    { label: "ADM", value: "ADM", id: "0" },
    { label: "Extensiva", value: "Extensiva", id: "1" },
    { label: "Fuerza", value: "Fuerza", id: "2" },
    { label: "Intensiva (acción)", value: "Intensiva (acción)", id: "3" },
    { label: "Intensiva (interacción)", value: "Intensiva (interacción)", id: "4" },
    { label: "Recuperación", value: "Recuperación", id: "5" },
    { label: "Resistencia", value: "Resistencia", id: "6" },
    { label: "Velocidad", value: "Velocidad", id: "7" },
]

const intention = [
    { label: "1 vs 1", value: "1 vs 1", id: "0" },
    { label: "2 vs 1", value: "2 vs 1", id: "1" },
    { label: "2 vs 2", value: "2 vs 2", id: "2" },
    { label: "3 vs 3 ", value: "3 vs 3 ", id: "3" },
    { label: "4 vs 4", value: "4 vs 4", id: "4" },
    { label: "ABP Defensiva", value: "ABP Defensiva", id: "5" },
    { label: "ABP Ofensiva", value: "ABP Ofensiva", id: "6" },
    { label: "Amplitud", value: "Amplitud", id: "7" },
    { label: "Apoyos", value: "Apoyos", id: "8" },
    { label: "Ataque Organizado", value: "Ataque Organizado", id: "9" },
    { label: "Cobertura", value: "Cobertura", id: "10" },
    { label: "Conservar", value: "Conservar", id: "11" },
    { label: "Contraataque", value: "Contraataque", id: "12" },
    { label: "Defensa Inicio de Juego", value: "Defensa Inicio de Juego", id: "13" },
    { label: "Defensa de Juego Directo", value: "Defensa de Juego Directo", id: "14" },
    { label: "Defensa Organizada", value: "Defensa Organizada", id: "15" },
    { label: "Desmarques", value: "Desmarques", id: "16" },
    { label: "Dividir", value: "Dividir", id: "17" },
    { label: "Evitar Progresión", value: "Evitar Progresión", id: "18" },
    { label: "Fase Defensiva", value: "Fase Defensiva", id: "19" },
    { label: "Fase Ofensiva", value: "Fase Ofensiva", id: "20" },
    { label: "Fijar", value: "Fijar", id: "21" },
    { label: "Finalizar", value: "Finalizar", id: "22" },
    { label: "Inicio de Juego", value: "Inicio de Juego", id: "23" },
    { label: "Juego Directo", value: "Juego Directo", id: "24" },
    { label: "Mantener", value: "Mantener", id: "25" },
    { label: "Marcaje", value: "Marcaje", id: "26" },
    { label: "Orientar", value: "Orientar", id: "27" },
    { label: "Permuta", value: "Permuta", id: "28" },
    { label: "Presionar", value: "Presionar", id: "29" },
    { label: "Primer Atacante", value: "Primer Atacante", id: "30" },
    { label: "Primer Defensor", value: "Primer Defensor", id: "31" },
    { label: "Profundidad", value: "Profundidad", id: "32" },
    { label: "Progresar", value: "Progresar", id: "33" },
    { label: "Proteger Portería", value: "Proteger Portería", id: "34" },
    { label: "Recuperar", value: "Recuperar", id: "33" },
    { label: "Reinicio de Juego", value: "Reinicio de Juego", id: "34" },
    { label: "Replegar", value: "Replegar", id: "35" },
    { label: "Segundo Atacante", value: "Segundo Atacante", id: "36" },
    { label: "Segundo Defensor", value: "Segundo Defensor", id: "37" },
    { label: "Temporizar", value: "Temporizar", id: "38" },
    { label: "Tercer Atacante", value: "Tercer Atacante", id: "39" },
    { label: "Tercer Defensor", value: "Tercer Defensor", id: "40" },
]


const habilities = [
    { label: "Actuación por Desmarcación", value: "Actuación por Desmarcación", id: "0" },
    { label: "Arrancar/Frenar", value: "Arrancar/Frenar", id: "1" },
    { label: "Cambiar de Dirección", value: "Cambiar de Dirección", id: "2" },
    { label: "Conducción", value: "Conducción", id: "3" },
    { label: "Control", value: "Control", id: "4" },
    { label: "Despejes", value: "Despejes", id: "5" },
    { label: "Desplazamientos", value: "Desplazamientos", id: "6" },
    { label: "Disparos", value: "Disparos", id: "7" },
    { label: "Entrada", value: "Entrada", id: "8" },
    { label: "Equilibrarse", value: "Equilibrarsen", id: "9" },
    { label: "Giros", value: "Giroses", id: "10" },
    { label: "Intercepción", value: "Intercepción", id: "11" },
    { label: "Pase", value: "Pase", id: "12" },
    { label: "Protección", value: "Protección", id: "13" },
    { label: "Regate", value: "Regate", id: "14" },
    { label: "Saltos", value: "Saltos", id: "15" },
]


const strategy = [
    { label: "Acciones a Balón Parado", value: "Acciones a Balón Parado", id: "0" },
    { label: "Acciones Combinadas", value: "Acciones Combinadas", id: "1" },
    { label: "Circuito", value: "Circuito", id: "2" },
    { label: "Conservación", value: "Conservación", id: "3" },
    { label: "Juego Adaptado al Fútbol", value: "Juego Adaptado al Fútbol", id: "4" },
    { label: "Juego de Posición", value: "Juego de Posición", id: "5" },
    { label: "Juego de Posición Específico", value: "Juego de Posición Específico", id: "6" },
    { label: "Oleadas", value: "Oleadas", id: "7" },
    { label: "Partidos", value: "Partidos", id: "8" },
    { label: "Posesión", value: "Posesión", id: "9" },
    { label: "Rueda de Pases", value: "Rueda de Pases", id: "10" },
    { label: "Situaciones Reducidas", value: "Situaciones Reducidas", id: "11" },
    { label: "Trabajo de Líneas", value: "Trabajo de Líneas", id: "12" },
]