import React from 'react';

import { createStackNavigator } from '@react-navigation/stack';
import Embedded from '../screens/stackEmbedded/Embeded';

const Stack = createStackNavigator();

const StackEmbedded = () => {
    return (
        <Stack.Navigator
            detachInactiveScreens={true}
            screenOptions={{
                cardStyle: { flex: 1 },
                title: "RFEF",
                headerShown: false,
            }}>
            <Stack.Screen options={{ title: "Cursos Federativos" }} name="Embedded" component={Embedded} />
        </Stack.Navigator>
    );
}


export default StackEmbedded;