import { useNavigation } from "@react-navigation/native";
import chroma from "chroma-js";
import { orderBy, uniqBy } from "lodash";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { ScrollView, TextInput, View } from "react-native";
import { useQuery } from "react-query";
import { useMediaQuery } from "react-responsive";
import FilterSelector from "../../components/common/FilterSelector";
import Footer from "../../components/common/Footer";
import StyledText from "../../components/common/StyledText";
import CourseCardSquare from "../../components/publicComponents/CourseCardSquare";
import fixDate from "../../utils/fixDate";

const filters = [
	{
		name: "Todos",
	}, {
		name: "Fútbol",
		categories: [
			{
				name: "Todos",
			}, {
				name: "CURSO DE ENTRENADOR DE FÚTBOL UEFA PRO"
			}, {
				name: "CURSO DE ENTRENADOR DE FÚTBOL UEFA A"
			}, {
				name: "CURSO DE ENTRENADOR DE FÚTBOL UEFA B"
			}, {
				name: "CURSO DE ENTRENADOR DE FÚTBOL UEFA C"
			}, {
				name: "UEFA GRASSROOTS LEADER"
			}, {
				name: "UEFA GOALKEEPER A"
			}, {
				name: "UEFA GOALKEEPER B"
			}, {
				name: "ESPECIALISTA ENTRENAMIENTO PORTEROS FÚTBOL NACIONAL C"
			}
		]
	}, {
		name: "Futsal",
		categories: [
			{
				name: "FÚTBOL SALA NACIONAL PROFESIONAL"
			}, {
				name: "FÚTBOL SALA NACIONAL A"
			}, {
				name: "FÚTBOL SALA NACIONAL B"
			}, {
				name: "FÚTBOL SALA NACIONAL C"
			}, {
				name: "FÚTBOL SALA PORTEROS NACIONAL B"
			}, {
				name: "FÚTBOL SALA PORTEROS NACIONAL C"
			}
		]

	}, {
		name: "Fútbol Playa",
		categories: [
			{
				name: "ENTRENADOR DE FÚTBOL PLAYA NACIONAL B"
			}
		]
	}, {
		name: "Proceso de Solicitud",
		categories: [
			{
				name: "PROCESO SOLICITUD LICENCIA UEFA PRO"
			}, {
				name: "PROCESO SOLICITUD LICENCIA UEFA A"
			}, {
				name: "PROCESO SOLICITUD LICENCIA UEFA B"
			}, {
				name: "PROCESO SOLICITUD LICENCIA UEFA C"
			}, {
				name: "PROCESO SOLICITUD LICENCIA UEFA FUTSAL B"
			}, {
				name: "PROCESO SOLICITUD LICENCIA NACIONAL C FÚTBOL SALA"
			}, {
				name: "PROCESO DE SOLICITUD LICENCIA UEFA GOALKEEPER B"
			}
		]
	}, {
		name: "Reconocimiento de competencias UEFA",
		categories: [
			{
				name: "RECONOCIMIENTO DE COMPETENCIAS DE FORMACIÓN Y PRÁCTICAS DE MOVILIDAD DEPORTIVA INTERNACIONAL UEFA PRO"
			}, {
				name: "RECONOCIMIENTO DE COMPETENCIAS DE FORMACIÓN Y PRÁCTICAS DE MOVILIDAD DEPORTIVA INTERNACIONAL UEFA A "
			}, {
				name: "RECONOCIMIENTO DE COMPETENCIAS DE FORMACIÓN Y PRÁCTICAS DE MOVILIDAD DEPORTIVA INTERNACIONAL UEFA B"
			}, {
				name: "RECONOCIMIENTO DE COMPETENCIAS DE FORMACIÓN Y PRÁCTICAS DE MOVILIDAD DEPORTIVA INTERNACIONAL UEFA FUTSAL B"
			}
		]
	}, {
		name: "Master",
		categories: [
			{
				name: "MÁSTER DE RENDIMIENTO Y REEDUCACIÓN FUNCIONAL DEPORTIVA"
			}
		]
	}, {
		name: "Curso superior universitario",
		categories: [
			{
				name: "CURSO SUPERIOR UNIVERSITARIO DE DIRECTOR DEPORTIVO EN FUTBOL"
			}, {
				name: "CURSO SUPERIOR UNIVERSITARIO DE ANALISIS TACTICO, SCOUTING Y GESTION DE RECURSOS TECNOLOGICOS APLICADOS AL FUTBOL"
			}, {
				name: "CURSO SUPERIOR UNIVERSITARIO FIFA/CIES/RFEF/URJC DE GESTIÓN DEL DEPORTE"
			}, {
				name: "CURSO SUPERIOR UNIVERSITARIO DE ENTRENADOR DE FUTBOL, DIRECCION DE EQUIPO Y METODOLOGIA"
			}
		]
	}, {
		name: "Cursos E-Learning",
		categories: [
			{
				name: "CURSO AVANZADO DE INSTATSCOUT"
			}
		]
	}
]
const CoachesCourses = () => {

	const [modality, setModality] = useState("Todos")
	const [courseType, setCourseType] = useState("Todos")
	const [courseMonth, setCourseMonth] = useState("Todos")
	const [territorial, setTerritorial] = useState("Todos")
	const [search, setSearch] = useState("")


	const isPhone = useMediaQuery({ query: '(max-width: 1000px)' })
	const navigation = useNavigation()

	const scale = chroma.scale(['#e4002b', '#8e051d']).colors(5);


	const { data } = useQuery({
		queryKey: ['courses'],
		queryFn: () => fetch('https://onformacion.comerciogo.workers.dev/courses').then((res) => res.json()),
		select: querySnapshot => querySnapshot?.filter(x => x?.tenantId !== "OF4VYpRfgUJEH5rG2syJ").map(doc => {
			const initDate = fixDate(doc?.initDate)
			const endDate = fixDate(doc?.endDate)
			const preInitDate = fixDate(doc?.preInitDate)
			const preEndDate = fixDate(doc?.preEndDate)
			return {
				...doc,
				initDate,
				endDate,
				preInitDate,
				preEndDate,
			}
		}) || []
	})



	const filteredCourses = useMemo(() => (
		orderBy(data?.filter(x => x.preEndDate >= new Date()), "preEndDate", "asc")
			?.filter(el =>
				(el?.name?.toLocaleLowerCase().includes(search.toLocaleLowerCase()) || el?.city?.toLocaleLowerCase().includes(search.toLocaleLowerCase()) || el?.tag?.toLocaleLowerCase().includes(search.toLocaleLowerCase())) &&
				(modality === "Todos" || el?.courseType?.toLocaleLowerCase() === modality?.toLocaleLowerCase()) &&
				(courseType === "Todos" || el?.courseCategory?.toLocaleLowerCase() === courseType?.toLocaleLowerCase()) &&
				(courseMonth === "Todos" || moment(courseMonth)?.toDate() < el?.preInitDate) &&
				(territorial === "Todos" || el.tenantName === territorial)
			)
	), [data, modality, courseType, courseMonth, territorial])

	return (
		<>
			<ScrollView style={{ flex: 1, backgroundColor: "white" }} contentContainerStyle={{ flexGrow: 1 }}>
				<View style={{ flexDirection: "row", alignItems: "center", width: "100%", paddingTop: 0, alignSelf: "center" }}>
					<View style={{ flexDirection: "row", backgroundColor: "#06162D", width: "100%", alignItems: "center", justifyContent: 'center', paddingTop: 50, paddingHorizontal: 30, paddingBottom: 80 }}>
						<View style={{ width: "100%", maxWidth: 1200, alignItems: "center", justifyContent: "center", gap: 10, alignSelf: "center" }}>
							<StyledText style={{ fontFamily: "Title", fontSize: 60, color: "white" }}>Escuela de Entrenadores</StyledText>
							<StyledText style={{ fontSize: 16, color: "white", textAlign: "center" }}>Estás en la página donde se muestran los cursos que ofrecen las Escuelas de Entrenadores de las Federaciones Territoriales. Ajustando estos filtros podrás encontrar los cursos que necesitas, puedes buscar por tipo de curso, por federación territorial donde quieres realizarlo o por la fecha de inicio, se mostrarán los resultados según tus preferencias.</StyledText>
							<TextInput value={search} onChangeText={(ev) => setSearch(ev)} style={{ marginTop: 20, width: "100%", alignSelf: "center", borderRadius: 5, backgroundColor: "white", paddingHorizontal: 15, alignItems: "center", height: 45 }} placeholder="Buscar..." />
						</View>
					</View>
				</View>
				<View style={{ flexDirection: isPhone ? "column" : "row", alignItems: "center", width: "100%", marginTop: -40, maxWidth: 1200, alignSelf: "center" }}>

					<FilterSelector
						options={["Todos", ...uniqBy(data, "courseType")?.map(x => x?.courseType)]}
						title={"Modalidad"}
						onChange={(value) => setModality(value)}
						background={scale[0]}
						searchable={true}
						value={modality}
					/>
					<FilterSelector
						options={["Todos", ...uniqBy(data, "courseCategory")?.map(x => x?.courseCategory)]}
						title={"Tipo de Curso"}
						onChange={(value) => setCourseType(value)}
						background={scale[1]}
						searchable={true}
						value={courseType}
					/>
					<FilterSelector
						options={
							[{ name: "Todos" }, ...new Array(12).fill(0).map((el, i) => ({ name: moment().add(i, "months").format("MMMM YYYY") }))]
						}
						title={"Inicio del Curso"}
						labelKey="name"
						valueKey="name"
						onChange={(value) => setCourseMonth(value)}
						background={scale[2]}
						searchable={true}
						value={courseMonth}
					/>
					<FilterSelector
						options={["Todos", ...uniqBy(data, "tenantName")?.map(x => x?.tenantName)]}
						title={"Federación"}
						onChange={(value) => setTerritorial(value)}
						value={territorial}
						background={scale[4]}
						searchable={true}
					/>
				</View>

				<View style={{ flexGrow: 1 }}>
					<View style={{
						paddingVertical: 15,
						flexWrap: "wrap",
						flexDirection: "row",
						alignItems: "flex-start",
						justifyContent: "flex-start",

					}}>

						{filteredCourses?.length === 0 ?
							<View style={{ flex: 1, paddingHorizontal: 15, minHeight: 200, alignItems: "center", justifyContent: "center", backgroundColor: 'white' }} >
								<StyledText style={{ fontSize: 20, textAlign: "center", color: "black", opacity: .2 }}>No hay cursos que coincidan con tu búsqueda</StyledText>
							</View>
							:
							filteredCourses?.map((el, i) => (
								<CourseCardSquare key={i} onPress={() => navigation.navigate("PreviewCourseScreen", { tenantId: el.tenantId, id: el.id })} data={el} />
							))}
					</View>
				</View>

				<Footer limited={false} />

			</ScrollView>
		</>
	);
};

export default CoachesCourses;