import { Ionicons } from '@expo/vector-icons';
import { Camera, CameraType } from 'expo-camera';
import jsQR from 'jsqr';
import React, { useEffect, useRef, useState } from 'react';
import { ActivityIndicator, Platform, TouchableOpacity, View } from 'react-native';
import WebCam from "react-webcam";
import StyledText from './common/StyledText';

const { requestAnimationFrame } = global

const QRScan = ({ enabled, height, width, onFind, closeCallback }) => {

   const [loading, setLoading] = useState(true);
   const cameraRef = useRef(null);
   const [cameraType, setCameraType] = useState(CameraType.back);
   const [permissions, requestPermissions] = Camera.useCameraPermissions();
   const [isAvailable, setIsAvailable] = useState(false);
   const [facingMode, setFacingMode] = useState("environment");

   const videoRef = useRef();

   const close = () => {
      // videoRef?.current && videoRef?.current?.pausePreview();
      closeCallback()
      setIsAvailable(false)
   }

   useEffect(() => {
      if (permissions?.granted) {
         (async () => {
            navigator.mediaDevices().then(devices => alert(devices))
            const perm = await requestPermissions();
            const isCameraAvailable = await Camera.isAvailableAsync();

            if (perm?.canAskAgain && !perm.granted) {
               closeCallback();
               return alert("Necesitas habilitar permisos para poder utilizar el escaneo de QR's");
            }

            if (isCameraAvailable) {
               if (Platform.OS === "web") {
                  const availableCameras = await Camera.getAvailableCameraTypesAsync();
                  if (availableCameras.length === 0) {
                     closeCallback();
                     return alert("Error al acceder a la cámara, por favor comprueba que está disponible o usa otro dispositivo")
                  }
                  const frontCamera = availableCameras.find(c => c === "front");
                  if (!frontCamera) {
                     closeCallback();
                     return alert("Error al acceder a la cámara, por favor comprueba que está disponible o usa otro dispositivo")
                  } else {
                     // setCameraType(Platform.OS !== "web" ? CameraType.back : CameraType.front);
                     setIsAvailable(true);
                     setLoading(false)
                  }
               }
            }
         }
         )
      } else {
         setIsAvailable(true);
         setLoading(false)
         // console.log(" no perm paso por aqui")
         // setIsAvailable(true)
      }

   }, [permissions?.granted])


   useEffect(() => {
      if (cameraRef.current && !videoRef.current) {
         const videoEl = cameraRef.current?.video;
         videoRef.current = videoEl;
         const { width, height } = videoEl.getBoundingClientRect();
         const canvas = document.createElement('canvas');
         const ctx = canvas.getContext('2d');
         canvas.width = width;
         canvas.height = height;

         function tick() {
            // console.log("TICK", enabled, cameraRef.current)
            if (!enabled || !cameraRef.current) return;
            if (videoEl.readyState === videoEl.HAVE_ENOUGH_DATA) {
               ctx.drawImage(videoEl, 0, 0, width, height);
               let imageData = ctx.getImageData(0, 0, width, height);
               let code = jsQR(imageData.data, imageData.width, imageData.height, {
                  inversionAttempts: 'dontInvert'
               });

               if (code) {
                  // console.log("JACKPOT")
                  videoEl?.current?.pausePreview();
                  onFind(code.data)
                     .then(() => {
                        cameraRef.current = null
                        closeCallback()
                     })
                  return
               } else {
                  // console.log("NO CODE TRY AGAIN")
                  setTimeout(() => tick(), 250)
               }
            } else {
               // console.log("NO DATA TRY AGAIN")
               setTimeout(() => tick(), 250)
            }
            // requestAnimationFrame(tick)
         }
         tick();
      }

      // return () => close();

   }, [cameraRef.current, videoRef.current, isAvailable, loading])

   return (
      <View style={{ backgroundColor: "black", width: screen.width - 25, height: screen.width - 25, maxWidth: 500, maxHeight: 500 }}>
         <TouchableOpacity onPress={close} style={{ position: "absolute", zIndex: 30, top: 0, right: 0, height: 45, width: 45, alignItems: "center", justifyContent: "center" }}>
            <Ionicons name="close" size={30} color="white" />
         </TouchableOpacity>
         <TouchableOpacity onPress={() => setFacingMode(prev => prev === "enviroment" ? "user" : "enviroment")}
            style={{ position: "absolute", zIndex: 30, bottom: 0, right: 0, height: 45, width: 45, alignItems: "center", justifyContent: "center" }}>
            <Ionicons name="md-swap-horizontal" size={24} color="white" />
         </TouchableOpacity>

         {!enabled ?
            <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
               <StyledText>Sin dispositivo activo</StyledText>
            </View>
            :
            loading ?
               <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
                  <ActivityIndicator color="white" />
               </View>
               :
               (isAvailable)
                  ? <WebCam
                     ref={cameraRef}
                     // onCanPlay={() => console.log("CAN PLAY")}
                     // onUserMedia={e => console.log({ e })}
                     onUserMediaError={e => console.log({ e })}
                     // height={300}
                     // width={300}
                     videoConstraints={{
                        width: 300,
                        height: 300,
                        facingMode: {
                           exact: facingMode,
                           ideal: facingMode
                        }
                     }}
                     audio={false}
                  />
                  //    <Camera
                  //    useCamera2Api={Platform.OS === "android"}
                  //    ref={cameraRef}
                  //    style={{ width: screen.width - 25, height: screen.width - 25, maxWidth: 500, maxHeight: 500 }}
                  //    type={cameraType}>
                  //    <View style={{ width: screen.width - 25, height: screen.width - 25, maxWidth: 500, maxHeight: 500 }} >
                  //    </View>
                  // </Camera>
                  : null
         }
      </View>
   );
}

export default QRScan;
