import { FontAwesome } from '@expo/vector-icons';
import moment from 'moment';
import React from 'react';
import { ImageBackground, TouchableOpacity, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import StyledText from '../common/StyledText';

const CourseCardSquare = ({ onPress, data }) => {

    const isPhone = useMediaQuery({ query: '(max-width: 1000px)' })

    return (
        <TouchableOpacity onPress={onPress} style={{ width: isPhone ? "100%" : "25%", aspectRatio: isPhone ? undefined : 1 }}>
            <ImageBackground source={{ uri: data.image }} style={{ padding: 20, justifyContent: "flex-end", width: "100%", height: "100%" }}>
                <View style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "#2b384c", opacity: .9 }} />
                <View>
                    <View>
                        <StyledText style={{ fontSize: 20, fontFamily: "Title", color: "#f9bb2c" }}>{data.courseType} {data.initDate && data.endDate && <StyledText style={{ marginLeft: 10, color: "white", fontSize: 20, opacity: .5, fontFamily: "Title" }} >Fechas: {moment(data?.initDate).format("DD-MM-YYYY")} - {moment(data?.endDate).format("DD-MM-YYYY")}</StyledText>}</StyledText>
                        <StyledText style={{ color: "white", fontFamily: "Bold", fontSize: 24 }} numberOfLines={4}>{data.name}</StyledText>
                        <StyledText style={{ color: "white", opacity:.7, fontFamily: "DemiBold", fontSize: 18 }} numberOfLines={4}>{data.tag}</StyledText>

                        <StyledText numberOfLines={1} style={{ fontSize: 14, color: "white", marginTop: 10 }}>{data?.tenantName}</StyledText>
                    </View>
                </View>
                <View style={{ position: "absolute", right: 20, top: 20, alignSelf: "flex-end", alignItems: "center", justifyContent: 'center', width: 35, height: 35, borderRadius: 18, backgroundColor: "#f9bb2c" }}>
                    <FontAwesome name="plus" size={20} style={{ marginTop: 2 }} color="#2b384c" />
                </View>
            </ImageBackground>
        </TouchableOpacity >
    );
}

export default CourseCardSquare;