import { Ionicons } from "@expo/vector-icons";
import { Video } from "expo-av";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Image, PanResponder, Platform, Text, TouchableOpacity, View, useWindowDimensions } from "react-native";
import { WebView } from "react-native-webview";

import PDFReader from "rn-pdf-reader-js";

import { useIsFocused } from "@react-navigation/native";
import * as DocumentPicker from "expo-document-picker";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import UploadMission from "../itineraryComponents/UploadMission";
import AudioPlayer from "./AudioPlayer";

let ReactPlayer, playbackInterval;

if (Platform.OS === "web") {
	ReactPlayer = require("react-player").default;
}

const MediaViewerManager = forwardRef(({
	type,
	url,
	cover = "",
	height,
	width,
	minHeight = 200,
	resizeMode = "cover",
	autoSize = true,
	tracking = false,
	playbackCallback = null,
	videoProps,
	positionCallback = () => null,
	uploadType,
	documentCallback = () => null,
	mandatory = false,
	onLoadCallback = () => null,
	pauseCallback = () => null,
	endCallback = () => null
}, reference) => {


	const screenWidth = useWindowDimensions().width
	const [signed, setSigned] = useState("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=")
	const [signedCover, setSignedCover] = useState("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=")

	const focus = useIsFocused()
	const storage = getStorage()
	const videoRef = useRef();


	const [containerWidth, setContainerWidth] = useState(screenWidth >= 900 ? 900 : screenWidth);
	const [aspectRatio, setAspectRatio] = useState(1.77);
	const [size, setSize] = useState({
		width: 0,
		height: 0,
	});
	const [uploadedDoc, setUploadedDoc] = useState({});
	const [filePreview, setFilePreview] = useState(null);
	const [embedded, setEmbedded] = useState(null);
	const [userResponse, setUserResponse] = useState(null);
	const [playing, setPlaying] = useState(false);

	const mockUploadContent = {
		content: {
			description: "",
			seconds: 40000,
		},
	};

	useEffect(() => {
		// CONTROLS IF THE SCREEN IS NOT FOCUSED AND THE VIDEO IS PLAYING
		!focus && setPlaying(false);
		return () => setPlaying(false)
	}, [focus])

	useEffect(() => {
		if (url) {
			if (url.includes("http")) {
				setSigned(url)
			} else {
				const imageRef = ref(storage, url)
				getDownloadURL(imageRef).then(url => {
					setSigned(url)
				}).catch(err => {
					console.log(err)
					setSigned("")
				})
			}
		}
		if (type === "EMBEDDED") {
			let parsedUrl = new URL(url);
			const searchParams = Object.fromEntries(Array(...parsedUrl.searchParams.entries()));
			setEmbedded(searchParams.v);
		}
	}, [url])

	useEffect(() => {
		if (cover) {
			if (cover.includes("http")) {
				setSignedCover(cover)
			} else {
				const imageRef = ref(storage, cover)
				getDownloadURL(imageRef).then(url => {
					setSignedCover(url)
				}).catch(err => {
					console.log(err)
					setSignedCover("")
				})
			}
		}
		type === "TEXT" || (type === "LINK" && Image.getSize(cover, (width, height) => setSize({ height, width })));
	}, [cover])


	// useEffect(() => {
	// 	tracking && type === "VIDEO" && playbackInstance && videoProps && videoProps.positionMillis && playbackInstance.current.setPositionAsync(videoProps.positionMillis);
	// }, [url]);


	const getNativePosition = async (ev) => {
		const d = ev.durationMillis;
		const c = ev.positionMillis;
		const p = {
			playedSeconds: c / 1000,
			played: (c / d),
		}
		positionCallback(p);
		ev.didJustFinish && endCallback()
	};

	const panResponder = useRef(
		PanResponder.create({
			onStartShouldSetPanResponder: (evt, gestureState) => true,
		})
	).current;

	const getMediaDimensions = (element) => {
		let ratio;

		if (Platform.OS === "web" && autoSize) {
			ratio = element.target.videoWidth / element.target.videoHeight;
			setSize({
				width: containerWidth,
				height: containerWidth / ratio,
			});
			setAspectRatio(ratio);
			const vid = document.getElementsByTagName("video")[0];
			vid.height = containerWidth / ratio;
			vid.setAttribute("playsinline", "");
			vid.setAttribute("webkit-playsinline", "");
		} else {
			ratio = element.naturalSize.width / element.naturalSize.height;
			setSize({
				height: containerWidth / ratio,
				width: containerWidth,
			});
		}
	};

	const uploadDocument = async () => {
		let result = await DocumentPicker.getDocumentAsync({ type: "application/*" })
			.then((res) => res)
			.catch((err) => console.log(err));

		setUploadedDoc(result);
		documentCallback(result);

		if (Platform.OS === "web") {
			const blob = window.URL.createObjectURL(result.file, { type: "application/pdf" });
			setFilePreview(blob);
		}
	};

	useImperativeHandle(reference, () => ({
		seekTo: (position) => {
			if (videoRef.current) {
				videoRef.current.seekTo(position);
			}
		}
	}))


	return (
		<View onLayout={ev => setSize({ ...size, width: ev.nativeEvent.layout.width })} style={{ backgroundColor: "black", borderRadius: 10, overflow: "hidden", height, width, minHeight }}>
			{(type === "TEXT" || type === "LINK") && (
				<View style={{ ...size, height: size.height * (size.height / size.width), minHeight: 200, width: "100%" }}>
					<Image style={{ flex: 1, resizeMode: "cover" }} source={{ uri: signedCover }} />
				</View>
			)}
			{url && type === "VIDEO" && (
				<View
					onLayout={(ev) => setContainerWidth(ev.nativeEvent.layout.width)}
					style={{ ...size, height: containerWidth / aspectRatio, width: "100%", overflow: "hidden", backgroundColor: "black", borderRadius: 7, overflow: "hidden" }}
				>
					{mandatory && Platform.OS === "web" && (
						<TouchableOpacity onPress={() => setPlaying(!playing)} style={{ backgroundColor: "white", alignItems: "center", justifyContent: "center", position: "absolute", zIndex: 10, bottom: 10, left: 10, borderRadius: 25, width: 50, height: 50 }}>
							<Ionicons style={{ marginLeft: 3, marginTop: 2 }} name={!playing ? "ios-play" : "ios-pause"} size={30} color="black" />
						</TouchableOpacity>
					)}
					{Platform.OS === "web" ?
						<ReactPlayer
							ref={videoRef}
							controls={!mandatory}
							playing={playing}
							config={{
								youtube: {
									playerVars: { showinfo: 0, rel: 0, modestbranding: 1 }
								},
								vimeo: {
									playerOptions: { responsive: false, width: "100%" }
								},
								file: {
									attributes: {
										...videoProps,
										controlsList: 'nodownload',
										controls: !mandatory,
										onContextMenu: e => e.preventDefault()
									}
								}
							}}
							url={signed}
							width="100%"
							height={size.width / aspectRatio}
							onPlay={() => setPlaying(true)}
							onPause={() => {
								setPlaying(false)
								pauseCallback()
							}}
							onDuration={onLoadCallback}
							onEnded={endCallback}
							onProgress={(e) => {
								playing && positionCallback(e)
							}}
						/>
						:
						<Video
							style={{ flex: 1 }}
							onReadyForDisplay={getMediaDimensions}
							resizeMode={resizeMode}
							useNativeControls={!mandatory}
							progressUpdateIntervalMillis={1000}
							onPlaybackStatusUpdate={getNativePosition}
							// ref={playbackInstance}
							source={{ uri: signed }}
							{...videoProps}
						/>
					}
				</View>
			)}

			{url && type === "AUDIO" && (
				<AudioPlayer source={signed} cover={signedCover} millis={videoProps?.positionMillis || 0} {...videoProps} onPlaybackStatusUpdate={getNativePosition} />
			)}

			{url && type === "DOCUMENT" && (
				<View {...panResponder.panHandlers} style={{ height: 400 }}>
					{Platform.OS !== "web" ? (
						<PDFReader
							style={{ flex: 1 }}
							withScroll
							renderType="DIRECT_URL"
							source={{
								uri: signed,
							}}
						/>
					) : (
						<object onError={(ev) => console.log(ev)} data={signed} type="application/pdf" width="100%" height={400}></object>
					)}
				</View>
			)}

			{type === "EMBEDDED" &&
				(Platform.OS !== "web" ? (
					<WebView
						source={`https://www.youtube.com/embed/${embedded}`}
						style={{ height: "auto", minHeight: 200, width: "100%" }}
					/>
				) : (
					<>
						{mandatory && Platform.OS === "web" && (
							<TouchableOpacity onPress={() => setPlaying(!playing)} style={{ backgroundColor: "white", alignItems: "center", justifyContent: "center", position: "absolute", zIndex: 10, bottom: 10, left: 10, borderRadius: 25, width: 50, height: 50 }}>
								<Ionicons style={{ marginLeft: 3, marginTop: 2 }} name={playing ? "ios-pause" : "ios-play"} size={30} color="black" />
							</TouchableOpacity>
						)}
						<ReactPlayer
							ref={videoRef}
							controls={!mandatory}
							playing={playing}
							config={{
								youtube: {
									playerVars: { showinfo: 0, rel: 0, modestbranding: 1 }
								},
								vimeo: {
									playerOptions: { responsive: false, width: "100%" }
								},
								file: {
									attributes: {
										controlslist: "nodownload",
										...videoProps,
										controls: !mandatory
									}
								}
							}}
							url={url}
							width="100%"
							height={size.width / aspectRatio}
							onPlay={() => setPlaying(true)}
							onDuration={onLoadCallback}
							// onReady={(ev) => onLoadCallback(ev)}
							onProgress={(e) => {
								// playing && console.log(e)
								playing && positionCallback(e)
							}}
							onEnded={endCallback}
							onPause={() => {
								setPlaying(false)
								pauseCallback()
							}}
						/>
					</>
				))}

			{type === "UPLOAD" && uploadType?.video ? (
				<View style={{ backgroundColor: "white", height: "100%", borderBottomLeftRadius: 15, borderBottomEndRadius: 15, flex: 1 }}>
					<UploadMission data={mockUploadContent} responseCallback={(response) => setUserResponse(response)} userResponse={userResponse} />
				</View>
			) : type === "UPLOAD" && !uploadType?.video ? (
				<>
					{uploadedDoc.uri && (
						<TouchableOpacity
							style={{ height: 40, width: 200, alignItems: "center", borderRadius: 7, backgroundColor: "#1d527f", justifyContent: "center", alignSelf: "flex-end" }}
							onPress={() => uploadDocument()}
						>
							<Text style={{ fontSize: 18, fontFamily: "DemiBold", color: "white" }}>Cambiar Archivo</Text>
						</TouchableOpacity>
					)}
					{url || ((Platform.OS === "web" && filePreview) || uploadedDoc?.uri) ? (
						<View {...panResponder.panHandlers} style={{ height: 400, marginTop: 10, marginBottom: 10 }}>
							{Platform.OS !== "web" ? (
								<PDFReader
									style={{ flex: 1 }}
									withScroll
									renderType="DIRECT_URL"
									source={{
										uri: url ? url : uploadedDoc?.uri,
									}}
								/>
							) : (
								<object onError={(ev) => console.log(ev)} data={url ? url : Platform.OS === "web" ? filePreview : uploadedDoc.uri} type="application/pdf" width="100%" height={400}></object>
							)}
						</View>
					) : (
						<View style={{ backgroundColor: "#f2f2f2", borderRadius: 5, flex: 1, minHeight: 200, marginTop: 10, marginBottom: 10, alignItems: "center", justifyContent: "center" }}>
							<Text style={{ fontSize: 18, fontFamily: "DemiBold", color: "black" }}>No has cargado ningún Archivo</Text>
							<TouchableOpacity
								style={{ height: 40, marginTop: 10, width: 200, alignItems: "center", borderRadius: 7, backgroundColor: "#1d527f", justifyContent: "center", alignSelf: "center" }}
								onPress={() => uploadDocument()}
							>
								<Text style={{ fontSize: 18, fontFamily: "DemiBold", color: "white" }}>Cargar Archivo</Text>
							</TouchableOpacity>
						</View>
					)}
				</>
			) : null}
		</View>
	);
});

export default MediaViewerManager;
