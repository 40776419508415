import { AssetRecordType, createShapeId, getHashForString, useEditor } from "@tldraw/tldraw"

const FieldSelector = ({ fields, visible, closeCallback }) => {
    const editor = useEditor()

    // const fields = [
    //     "https://static.vecteezy.com/system/resources/previews/000/234/128/original/soccer-field-background-vector.jpg",
    //     "https://media.istockphoto.com/id/915087622/vector/illustration-of-a-soccer-field-football-field-or-soccer-field-background.jpg?s=612x612&w=0&k=20&c=YDCtDshPYtpzGXpQdNVrt6sku6hAheDUUm4fsZtnJo0=",
    //     "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b9/Soccer_field_-_empty.svg/640px-Soccer_field_-_empty.svg.png",
    //     "https://images.photowall.com/products/44663/soccer-field.jpg?h=699&q=85",
    //     "https://muralsyourway.vtexassets.com/arquivos/ids/236597/Wooden-Basketball-Court-Wallpaper-Mural.jpg?v=638164851443830000",
    //     "https://dondeycuanto.com/wp-content/uploads/2016/05/cuanto-mide-un-campo-de-futbol-sala.png",
    //     "https://png.pngtree.com/thumb_back/fh260/background/20221125/pngtree-blue-futsal-field-strategy-board-background-image_1492314.jpg",
    //     "https://us.123rf.com/450wm/doimogoju/doimogoju1404/doimogoju140400346/27805413-fondo-pista-de-futbol-cancha-de-f%C3%BAtbol-sala-de-juegos-del-deporte-para-el-dise%C3%B1o.jpg",
    //     "https://img.freepik.com/vector-premium/plantilla-campo-futbol-sala-vista-superior-campo-deportivo-recreacion-activa_294779-440.jpg",
    //     "https://media.istockphoto.com/id/499754120/vector/realistic-basketball-court-illustration.jpg?s=612x612&w=0&k=20&c=chbZnGbOq3nxNp0sIEh7tH1rsrwmt6JDDf7b5JcUp58=",
    //     "https://t3.ftcdn.net/jpg/01/64/58/06/360_F_164580683_f4V0MNPtqeZUPeUKmhm75OeoA94U0wtV.jpg",
    //     "https://t3.ftcdn.net/jpg/04/87/48/04/360_F_487480418_5w6GFRCvAa6KCnF2anNbaAfsOSjN2aB6.jpg",
    //     "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/Beachsoccer_pitch.png/250px-Beachsoccer_pitch.png",
    //     "https://blog.clickforfoot.com/wp-content/uploads/2020/02/Dimension-terrin-de-beach-soccer-scaled.jpg",
    //     "https://www.perfect-tennis.com/wp-content/uploads/2019/02/tennis-court-dimensions.jpg",
    //     "https://cdn.vectorstock.com/i/preview-1x/82/08/tennis-court-vector-13638208.jpg",
    //     "https://media.istockphoto.com/id/1137031992/vector/tennis-court-or-field-realistic-blackboard-for-tactic-plan-colorful-vector-illustration.jpg?s=612x612&w=0&k=20&c=EYj9yib4gQXAerqmRWxcgHWGTv9gcriWvCVLtEgeSJk="

    // ]

    const addField = (field) => {

        const assetId = AssetRecordType.createId(getHashForString(field.url))
        const fieldId = createShapeId("field" + Math.random())

        editor.createAssets([
            {
                id: assetId,
                type: 'image',
                typeName: 'asset',
                meta: {},
                props: {
                    src: field.url,
                    w: field.width,
                    h: field.height,
                    name: "field",
                    isAnimated: false,
                    mimeType: "image/jpeg"
                }
            }
        ])

        const currentPoint = editor.getViewportPageCenter()

        // // Create a shape
        editor.createShapes([
            {
                id: fieldId,
                type: 'image',
                x: currentPoint.x - field.width / 2,    
                y: currentPoint.y - field.height / 2,
                isLocked: false,
                props: {
                    assetId: assetId,
                    w: field.width,
                    h: field.height,
                },
            }
        ])
        editor.sendToBack([fieldId])

        closeCallback()

    }

    if (!visible) return

    return (
        <>
            <button onClick={closeCallback} style={{ cursor: "pointer", zIndex: 10000, background: "transparent", border: 0, position: "absolute", top: 0, right: 0, width: 35, height: 35, display: "flex", alignItems: "center", justifyContent: "center" }}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" style={{ width: 20, height: 20 }}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </button>
            <div style={{
                position: "absolute",
                top: 0,
                right: 0,
                zIndex: 1000,
                backgroundColor: "white",
                padding: 10,
                maxHeight: "70vh",
                borderRadius: 10,
                overflow: "auto",
                maxWidth: 350,
                display: "flex",
                flexDirection: "column",
                gap: 10,
            }}>

                {fields.map((element, i) => (
                    <div key={i}>
                        <h2 style={{ fontFamily: "Montserrat" }}>{element.name}</h2>
                        <div style={{

                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            gap: 10,
                        }}>
                            {element.items.map((item, i) => (
                                <button key={i} onClick={() => addField(item)} style={{ cursor: "pointer", height: 150, width: 150, background: "#f3f3f3", border: 0, borderRadius: 10, overflow: "hidden" }}>
                                    <img src={item.url} width={"100%"} height={"100%"} style={{ objectFit: "contain" }} />
                                </button>
                            ))}
                        </div>
                    </div>
                ))
                }

                {/* {fields.map((field, i) => (
                    <button key={i} onClick={() => addField(field)} style={{ cursor: "pointer", height: 150, width: 150, background: "#f3f3f3", border: 0, borderRadius: 10, overflow: "hidden" }}>
                        <img src={field.url} width={120} height={120} style={{ objectFit: "contain" }} />
                    </button>
                ))} */}
            </div>
        </>
    )
}


export default FieldSelector