import { Ionicons } from '@expo/vector-icons';
import * as ImagePicker from "expo-image-picker";
import { doc, getFirestore, updateDoc } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadString } from 'firebase/storage';
import { get } from 'lodash';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import * as Animatable from "react-native-animatable";
import { useMediaQuery } from 'react-responsive';
import placeholder from "../../assets/images/placeholder.jpeg";
import AuthContext from '../../context/AuthContext';
import { getStatusColor, getStatusName } from '../../utils/getStatus';
import PaymentsModal from '../PaymentsModal';
import Button from '../common/Button';
import SecureImage from '../common/SecureImage';
import StyledText from '../common/StyledText';

function convertToPlain(html) {
    var tempDivElement = document.createElement("div");
    tempDivElement.innerHTML = html;
    return tempDivElement.textContent || tempDivElement.innerText || "";
}

const CourseHorizontalCard = ({ onPress, el, course, changeDataCallback, coursePayment, firstPayment, uploadCallback, viewCallback, paymentCallback, disabled, paymentWarning }) => {
    const isPhone = useMediaQuery({ query: '(max-width: 1000px)' })
    const [loadingFile, setLoadingFile] = useState(false)
    const { userData, impersonatedUser } = useContext(AuthContext)
    const isFree = parseInt(course?.programFee || 0) === 0
    const [paymentsModal, setPaymentsModal] = useState(false);
    const firstPaymentPaid = firstPayment ? get(firstPayment, "paymentMethod", null) === "BANK TRANSFER" && get(firstPayment, "status", null) === "PAID" : true
    const firstPaymentFile = get(firstPayment, "link", null)
    const missingPreinscription = get(course, "preinscriptionFee", 0) > 0 && !firstPayment

    const storage = getStorage();
    const db = getFirestore()

    const loadDocument = async (index) => {
        setLoadingFile(true)
        ImagePicker.launchImageLibraryAsync({
            allowsEditing: true
        }).then(async res => {

            if (!res.canceled) {
                const uri = res.assets[0].uri
                const init = uri.split(";")[0]
                const type = init.split('/')[1];

                try {
                    setLoadingFile(true)
                    const file = ref(storage, `courses/${course?.tenantId}/${course?.id}/${userData.id}/paymentReceip-${new Date().getTime()}.${type}`);
                    await uploadString(file, uri.replace(`${init};base64,`, ""), "base64", {
                        contentType: init.replace("data:", ""),
                    }).then(res => {
                        const arr = [...coursePayment?.payments] || []
                        arr[index].link = res.ref.fullPath
                        const docRef = doc(db, `tenants/${course?.tenantId}/courses/${course?.id}/payments/${userData.id}`);
                        updateDoc(docRef, {
                            payments: arr
                        }).then(res => {
                            alert("El documento se guardó correctamente, recuerda que debes esperar a que el administrador lo valide, si te equivocaste al cargar el documento puedes modificarlo antes de ser validado")
                            setLoadingFile(false)
                        }).catch(err => {
                            console.log(err)
                            alert("Ha ocurrido un error guardando el documento")
                            setLoadingFile(false)
                        })

                    }).catch(err => {
                        console.log(err)
                        alert("Ha ocurrido un error cargando el documento")
                        setLoadingFile(false)
                    })
                    setLoadingFile(false)

                } catch (err) {
                    alert("Ha ocurrido un error cargando el documento")
                    setLoadingFile(false)
                    console.log(err)
                }
            } else {
                setLoadingFile(false)
            }
            return res
        }).catch(err => {
            alert("Ocurrió un error cargando el docuento")
            console.log(err)
            setLoadingFile(false)
            return null
        })

        setTimeout(() => {
            setLoadingFile(false)
        }, 3000)
    }

    if (disabled) {
        return (
            <Animatable.View
                animation={"fadeIn"}
                duration={500}
                style={{
                    width: "100%",
                    marginBottom: 20,
                    borderRadius: 10,
                    backgroundColor: "#fff",
                    height: isPhone ? "auto" : 250
                }}
                key={course?.id}
            >
                <View style={{ flexDirection: isPhone ? "column" : "row", alignItems: "center" }}>
                    <View style={{ width: isPhone ? "100%" : "30%", height: isPhone ? 150 : "100%", alignItems: "center" }}>
                        {el?.advance?.status === "REJECTED" ?
                            <Button height={35} round={5} label={"Descartado"} onPress={() => null} color="red" style={{ position: "absolute", top: 5, left: 5, zIndex: 10 }} />
                            : el?.advance?.finalGradeStatus ?
                                <Button height={35} round={5} onPress={() => null} label={getStatusName(el?.advance?.finalGradeStatus)} color={getStatusColor(el?.advance?.finalGradeStatus)} style={{ position: "absolute", top: 5, left: 5, zIndex: 10 }} />
                                : null
                        }
                        <SecureImage
                            style={{
                                width: "100%",
                                borderRadius: 10,
                                height: isPhone ? 150 : 250
                            }}
                            uri={el.image}
                            placeholder={placeholder}
                        />
                    </View>
                    <View style={{ width: isPhone ? "100%" : "70%", padding: 20, height: "100%", flex: 1, justifyContent: "space-between" }}>
                        <View style={{ alignItems: "flex-start" }}>
                            <StyledText numberOfLines={1} style={{ fontSize: 30, marginBottom: 0, fontFamily: "Title" }}>{el.name}</StyledText>
                            <StyledText numberOfLines={1} style={{ fontSize: 18, marginBottom: 0, fontFamily: "DemiBold", opacity: .8 }}>{el.tag}</StyledText>
                            <View style={{ paddingVertical: 10 }}>
                                <StyledText numberOfLines={2} style={{ fontFamily: "Regular", fontSize: 14, color: "#303030" }}>
                                    {convertToPlain(el.description)}
                                </StyledText>
                            </View>
                            <CourseDates initDate={el.initDate} endDate={el.endDate} />
                        </View>

                        <View style={{ flexDirection: isPhone ? "column" : "row", gap: 10 }}>
                            {(el?.advance?.status === "PENDINGREVIEW" || el?.advance?.status === "CHANGESREQUIRED") ?
                                <View style={{ marginTop: isPhone ? 10 : 0 }}>
                                    {el?.advance?.status === "CHANGESREQUIRED" && <View style={{ position: "absolute", top: -10, right: 0, alignItems: "center", justifyContent: "center", width: 25, height: 25, borderRadius: 18, backgroundColor: "orange", zIndex: 10 }}>
                                        <Ionicons name="alert" color="white" size={15} />
                                    </View>}
                                    <Button disabled={impersonatedUser} onPress={changeDataCallback} height={35} round={5} style={{ zIndex: 2 }} label="Modificar Preinscripción" />
                                </View>
                                : null
                            }

                            {!isFree && el?.advance?.status !== "REJECTED" && firstPayment?.paymentMethod !== "TPV" && !firstPaymentPaid ?
                                <View style={{ flexDirection: "row", gap: 10 }}>
                                    <Button height={35} loading={loadingFile} onPress={() => loadDocument(0)} round={5} primary={false} label="Cargar Justificante Pago" />
                                    {firstPaymentFile ?
                                        <Button color="#0b1831" height={35} onPress={() => {
                                            const docRef = ref(storage, firstPaymentFile);
                                            getDownloadURL(docRef).then((url) => {
                                                window.open(url, "_blank")
                                            }).catch((error) => {
                                                alert("Ha ocurrido un error abriendo el documento")
                                                console.log(error)
                                            });
                                        }} round={5} primary={true} label="Ver" />
                                        : null
                                    }
                                </View>
                                : null
                            }


                            {missingPreinscription ?
                                <Button height={35} onPress={() => setPaymentsModal(course)} round={5} primary={false} label="Gestión Pagos" />
                                : null
                            }
                        </View>

                        {el?.advance?.status === "REJECTED" && el?.advance?.rejectReason ?
                            <View style={{ backgroundColor: "red", padding: 10, borderRadius: 6 }}>
                                <StyledText style={{ color: "white", fontSize: 16, fontFamily: "Regular" }}>{el?.advance?.rejectReason}</StyledText>
                            </View>
                            : null
                        }

                    </View>
                </View>
            </Animatable.View>
        )
    }
    return (
        <>
            <Animatable.View key={course?.id} animation={"fadeIn"} duration={500}>
                <TouchableOpacity
                    onPress={onPress}
                    disabled={disabled}
                    style={{
                        width: "100%",
                        marginBottom: 20,
                        borderRadius: 10,
                        backgroundColor: "#fff",
                        height: isPhone ? "auto" : 250,
                    }}
                >
                    <View style={{ flexDirection: isPhone ? "column" : "row", alignItems: "center" }}>
                        <View style={{ width: isPhone ? "100%" : "30%", height: isPhone ? 150 : "100%", alignItems: "center" }}>
                            {el?.advance?.status === "REJECTED" ?
                                <Button height={35} round={5} label={"Descartado"} onPress={() => null} color="red" style={{ position: "absolute", top: 5, left: 5, zIndex: 10 }} />
                                : el?.advance?.finalGradeStatus ?
                                    <Button height={35} round={5} onPress={() => null} label={getStatusName(el?.advance?.finalGradeStatus)} color={getStatusColor(el?.advance?.finalGradeStatus)} style={{ position: "absolute", top: 5, left: 5, zIndex: 10 }} />
                                    : null
                            }
                            <SecureImage
                                style={{
                                    width: "100%",
                                    borderRadius: 10,
                                    height: isPhone ? 150 : 250
                                }}
                                uri={el.image}
                                placeholder={placeholder}
                            />
                        </View>
                        <View style={{ width: isPhone ? "100%" : "70%", padding: 20, height: "100%", flex: 1, justifyContent: "space-between" }}>
                            <View style={{ alignItems: "flex-start" }}>
                                <StyledText numberOfLines={1} style={{ fontSize: 30, marginBottom: 0, fontFamily: "Title" }}>{el.name}</StyledText>
                                <StyledText numberOfLines={1} style={{ fontSize: 18, marginBottom: 0, fontFamily: "DemiBold", opacity: .8 }}>{el.tag}</StyledText>
                                <View style={{ paddingVertical: 10 }}>
                                    <StyledText numberOfLines={2} style={{ fontFamily: "Regular", fontSize: 14, color: "#303030" }}>
                                        {convertToPlain(el.description)}
                                    </StyledText>
                                </View>
                                <CourseDates initDate={el.initDate} endDate={el.endDate} />
                            </View>

                            <View style={{ flexDirection: isPhone ? "column" : "row", gap: 10 }}>
                                {el?.advance?.status === "REJECTED" && <View style={{ backgroundColor: "red", padding: 10, borderRadius: 6 }}>
                                    <StyledText style={{ color: "white", fontSize: 16, fontFamily: "Regular" }}>{el?.advance?.rejectReason}</StyledText>
                                </View>}

                                {(el?.advance?.status === "PENDINGREVIEW" || el?.advance?.status === "CHANGESREQUIRED") &&
                                    <View style={{ marginTop: isPhone ? 10 : 0 }}>
                                        {el?.advance?.status === "CHANGESREQUIRED" && <View style={{ position: "absolute", top: -10, right: 0, alignItems: "center", justifyContent: "center", width: 25, height: 25, borderRadius: 18, backgroundColor: "orange", zIndex: 10 }}>
                                            <Ionicons name="alert" color="white" size={15} />
                                        </View>}
                                        <Button disabled={impersonatedUser} onPress={changeDataCallback} height={35} round={5} style={{ zIndex: 2 }} label="Modificar Preinscripción" />
                                    </View>
                                }
                                {el?.advance?.status === "COMPLETED" && <Button onPress={changeDataCallback} round={5} label="Licencias y Diplomas" />}
                                <Button onPress={onPress} height={35} round={5} style={{ zIndex: 2 }} label="Ir al Curso" />
                                {el?.advance?.status === "CHANGESREQUIRED" && <View style={{ position: "absolute", top: -10, right: 0, alignItems: "center", justifyContent: "center", width: 25, height: 25, borderRadius: 18, backgroundColor: "orange", zIndex: 10 }}>
                                    <Ionicons name="alert" color="white" size={15} />
                                </View>}
                                {isFree
                                    ? null
                                    : <View>
                                        {!paymentWarning ? null :
                                            <View style={{ position: "absolute", top: -10, right: -10, alignItems: "center", justifyContent: "center", width: 25, height: 25, borderRadius: 18, backgroundColor: "orange", zIndex: 10 }}>
                                                <Ionicons name="alert" color="white" size={15} />
                                            </View>
                                        }
                                        <Button height={35} onPress={() => setPaymentsModal(course)} round={5} primary={false} label="Gestión Pagos" />
                                    </View>
                                }
                            </View>

                        </View>
                    </View>
                </TouchableOpacity>
            </Animatable.View>
            {paymentsModal ? <PaymentsModal setOpen={setPaymentsModal} course={paymentsModal} courseId={paymentsModal?.id} userData={userData} tenantId={paymentsModal?.tenantId} originUrl={"/alumno/cursos"} /> : null}
        </>
    );
}

export default CourseHorizontalCard;


const CourseDates = ({ initDate, endDate }) => {
    return (
        <View style={{ flexDirection: "row", gap: 10, padding: 10, backgroundColor: "#f2f2f2", marginBottom: 10, paddingHorizontal: 15, borderRadius: 7 }}>
            <Ionicons name="calendar-sharp" size={20} />
            <StyledText style={{ fontSize: 20, fontFamily: "Title" }}>Inicio {moment(initDate).format("DD-MM-YYYY")}</StyledText>
            <Ionicons name="calendar-sharp" size={20} />
            <StyledText style={{ fontSize: 20, fontFamily: "Title" }}>Final {moment(endDate).format("DD-MM-YYYY")}</StyledText>
        </View>
    )
}