import { Ionicons } from "@expo/vector-icons";
import { useFirestoreQueryData } from "@react-query-firebase/firestore";
import { getApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, updateEmail, updatePassword } from "firebase/auth";
import { collection, doc, getFirestore, query, runTransaction, serverTimestamp, updateDoc, where } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import { getStorage, ref, uploadString } from "firebase/storage";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { ActivityIndicator, ScrollView, StyleSheet, View, useWindowDimensions } from "react-native";
import * as yup from "yup";
import LinearBarIndicator from "../../components/LinearBarIndicator";
import Stepper from "../../components/Stepper";
import Button from "../../components/common/Button";
import Modal from "../../components/common/Modal";
import SecureImage from "../../components/common/SecureImage";
import StyledText from "../../components/common/StyledText";
import ImageField from "../../components/formComponents/ImageField";
import SelectField from "../../components/formComponents/SelectField";
import TextField from "../../components/formComponents/TextField";
import AdvanceContext from "../../context/AdvanceContext";
import AuthContext from "../../context/AuthContext";
import countries from "../../utils/countries.json";
import provinces from "../../utils/provinces.json";
import useYupValidationResolver from "../../utils/useYupValidationResolver";
import ValidateSpanishID from "../../utils/validateSpanishId";
import Hint from "../../components/common/Hint";


import { useRoute } from "@react-navigation/native";
import { useMediaQuery } from "react-responsive";


const ProfileHome = () => {
	const { customization } = useContext(AuthContext);

	const isPhone = useMediaQuery({ query: '(max-width: 1000px)' })

	const params = useRoute().params

	const [tab, setTab] = useState(params?.tab === "licencias" ? 1 : 0)

	// const changeTab = (n) => {
	// 	setTab(n)
	// }


	return (
		<ScrollView style={{ flex: 1 }} contentContainerStyle={{ paddingVertical: isPhone ? 20 : 30, paddingHorizontal: 15, maxWidth: 1200, width: "100%", alignSelf: "center" }}>

			<View style={{ flexDirection: "row", flexWrap: "wrap", justifyContent: "flex-end" }}>

				<StyledText style={{ fontFamily: "title", fontSize: isPhone ? 35 : 45, textAlign: "right", color: "black" }}>Datos Personales</StyledText>

			</View>

			<ProfileInfo />
		</ScrollView >
	);
};

ProfileHome.navigationOptions = {
	header: null,
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: "#fff",
		marginBottom: 60,
	},
	shadow: {
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 5,
		},
		shadowOpacity: 0.2,
		shadowRadius: 10.5,
		elevation: 10,
	},
	imageRedond: {
		height: 40,
		width: 40,
		borderRadius: 20,
		resizeMode: "cover",
		borderWidth: 1,
		borderColor: "gainsboro",
		marginRight: 10,
	},
	topicBox: {
		width: 300,
		padding: 15,
		marginBottom: 10,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		borderWidth: 1,
		borderRadius: 4,
		borderColor: "gainsboro",
	}
});

export default ProfileHome;



const ProfileInfo = () => {

	const { customization, userData } = useContext(AuthContext)
	const { advances, courses } = useContext(AdvanceContext)


	const isPhone = useMediaQuery({ query: '(max-width: 1000px)' })

	const [changePassword, setChangePassword] = useState(false)
	const [changeEmail, setChangeEmail] = useState(false)
	const [editDataModal, setEditDataModal] = useState(false)
	const [editSensitiveDataModal, setEditSensitiveDataModal] = useState(false)

	const firebaseDateToDate = (d) => d?.toDate ? d.toDate() : moment(d).toDate()
	const hasPendingCourses = courses.some(x => (x?.advance?.status === "PENDINGREVIEW" || x?.advance?.status === "CHANGESREQUIRED") && firebaseDateToDate(x.endDate) > new Date())

	return (
		<>
			<View style={{ flexDirection: isPhone ? "column" : "row" }}>

				<View style={{ flex: isPhone ? null : 1, height: "auto", paddingTop: 20 }}>
					<View style={{ width: "100%" }}>
						<View style={[{ backgroundColor: "white", borderRadius: 7 }]}>
							<View style={{ paddingHorizontal: 20, paddingTop: 20 }}>
								<View style={{ flexDirection: "row", marginBottom: 10, gap:8, alignItems: "flex-start" }}>
									<StyledText style={{ fontSize: 16, marginBottom: 10, fontFamily: "Regular", color: customization?.mainColor }}>Foto tipo DNI</StyledText>
									<Hint title="Muestra correcta foto DNI"  rules={['Debe estar mirando de frente', 'Debe tener fondo blanco','Debe ser una foto actual y reconocible']} image={'https://firebasestorage.googleapis.com/v0/b/cdnonformacion.uno.work/o/hint-dni.png?alt=media&token=e502d0de-d3a8-44e9-a023-36e392a7eb21'} color={'#111'} />
								</View>
								<View style={{ height: 200, width: 200, alignSelf: "center" }}>
									<SecureImage
										key={userData.image}
										style={{
											flex: 1,
											borderRadius: 7,
											resizeMode: "contain",
											backgroundColor: "#f3f3f3"
										}}
										uri={userData.image}
										placeholder={undefined}
									/>
								</View>
							</View>
							<View style={{ padding: 20 }}>
								<FieldView label={"Nombre"} value={userData?.name + " " + userData?.surname} />
								<FieldView label={"Email"} value={userData?.email} />
								<FieldView label={"Teléfono"} value={userData?.phone || "-"} />
								<View style={{ flexDirection: "row" }}>
									<FieldView label={"Fecha de nacimiento"} value={userData?.birthDate ? moment(userData?.birthDate?.toDate ? userData?.birthDate?.toDate() : userData?.birthDate).format("DD/MM/YYYY") : "-"} style={{ marginRight: 10 }} />
									<FieldView label={"Sexo"} value={userData?.gender === "male" ? "Hombre" : userData?.gender === "female" ? "Mujer" : "-"} />
								</View>
							</View>
						</View>
					</View>

				</View>
				{isPhone && <View style={{ width: 15 }} />}
				<View style={{ flex: isPhone ? undefined : 1, height: "auto", paddingTop: 20 }}>

					<View style={{ width: "100%" }}>
						<View style={[{ backgroundColor: "white", borderRadius: 7 }]}>
							<View style={{ paddingHorizontal: 20, paddingTop: 20, flexDirection: "row", height: 200, flex: 1, width: "100%" }}>

								<View style={{ flex: 1, height: 230 }}>
								<View style={{ flexDirection: "row", marginBottom: 10, gap:8, alignItems: "flex-start" }}>
									<StyledText style={{ fontSize: 16, marginBottom: 10, fontFamily: "Regular", color: customization?.mainColor }}>Foto Documento de Identificaci&oacute;n (delante)</StyledText>
									<Hint title="Muestra Anverso DNI" rules={['Debe estar bien encuadrado', 'Debe estar enfocado','Debe estar correctamente iluminado']} image={'https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/users%2Flb0kmM1fCBSUS8Is6VrbEUIuEGG3%2FidFront_1710764725714.png?alt=media&token=b1734ced-56e4-4462-be8f-180110d23b1e'} color={'#111'}/>
								</View>
									<SecureImage
										key={userData.idFront || userData.dniFront}
										style={{
											marginRight: 10,
											height: 250,
											flex: 1,
											resizeMode: "contain",
											borderRadius: 15,
											backgroundColor: "#f3f3f3",
											aspectRatio: 1
										}}
										aspectRatio={1}
										uri={userData.idFront || userData.dniFront}
										placeholder={undefined}
									/>
								</View>
								<View style={{ marginLeft: 10, flex: 1, height: 230 }}>
								<View style={{ flexDirection: "row", marginBottom: 10, gap:8, alignItems: "flex-start" }}>
									<StyledText style={{ fontSize: 16, marginBottom: 10, fontFamily: "Regular", color: customization?.mainColor }}>Foto Documento de Identificaci&oacute;n (detr&aacute;s)</StyledText>
									<Hint title="Muestra Reverso DNI" rules={['Debe estar bien encuadrado', 'Debe estar enfocado','Debe estar correctamente iluminado']} image={'https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/users%2Flb0kmM1fCBSUS8Is6VrbEUIuEGG3%2FidBack_1710764726396.png?alt=media&token=0ddf09fb-bc77-4fe1-ae7e-3ab6a7cac669'} color={'#111'}/>
								</View>
									<SecureImage
										key={userData.idBack || userData.dniBack}
										style={{
											height: 250,
											flex: 1,
											resizeMode: "contain",
											borderRadius: 15,
											backgroundColor: "#f3f3f3",
											aspectRatio: 1
										}}
										uri={userData.idBack || userData.dniBack}
										placeholder={undefined}
									/>
									
								</View>


							</View>
							<View style={{ padding: 20 }}>
								<View style={{ flexDirection: "row" }}>
									<FieldView label={"Tipo de Documento"} value={userData?.idType || "-"} style={{ marginRight: 10 }} />
									<FieldView label={"N de Documento"} value={userData?.idNumber || userData?.idCardNumber || "-"} />
								</View>
								<FieldView label={"Fecha de Validez"} value={(userData?.idExpirationDate) ? moment(userData?.idExpirationDate?.toDate ? userData?.idExpirationDate?.toDate() : userData?.idExpirationDate).format("DD/MM/YYYY") : "-"} />

								<View style={{ flexDirection: "row" }}>
									<FieldView label={"Pais de Nacimiento"} value={userData?.birthCountry || "-"} style={{ marginRight: 10 }} />
									<FieldView label={"Nacionalidad"} value={userData?.nationality || "-"} />
								</View>
								<FieldView label={"Dirección"} value={userData?.address || "-"} />
							</View>
						</View>
					</View>


				</View>
			</View>
			<View style={{ backgroundColor: "white", marginTop: 15, borderRadius: 7, padding: 15, gap: 10, flexDirection: isPhone ? "column" : "row", alignItems: "center", justifyContent: "flex-end" }}>

				<Button
					primary
					label="Modificar datos"
					color="#0b1831"
					height={35}
					round={7}
					width={isPhone ? "100%" : undefined}
					onPress={() => {
						if (hasPendingCourses) {
							alert("No puedes editar tus datos mientras tengas preinscripciones abiertas. Modifica tus tados en los datos de preinscripción")
						} else {
							setEditDataModal(true)
						}
					}}
				/>


				<Button label="Cambiar contraseña" color="#0b1831" primary height={35} round={7} width={isPhone ? "100%" : undefined} onPress={() => setChangePassword(true)} />
				<Button label="Cambiar email" primary height={35} round={7} width={isPhone ? "100%" : undefined} onPress={() => setChangeEmail(true)} />
			</View>
			<View style={{ backgroundColor: "white", marginTop: 15, gap: 15, borderRadius: 7, padding: 15, flexDirection: isPhone ? "column" : "row", alignItems: "center", justifyContent: courses?.length > 0 ? "space-between" : "center" }}>
				{courses?.length > 0 ?
					<StyledText style={{ textAlign: isPhone ? "center" : undefined }}>Para la edición de alguno de los datos de tu perfil se necesita validación por parte del administrador</StyledText>
					:
					<StyledText style={{ textAlign: "center" }}>Para completar el perfil debes rellenar una preinscripción a un curso o proceso, en cuanto se acepte la preinscripción se verán reflejados en tu perfil</StyledText>
				}
				{courses?.length > 0 ?
					<Button
						primary
						label="Modificar datos identificación"
						height={35}
						round={7}
						width={isPhone ? "100%" : undefined}
						onPress={() => {
							if (hasPendingCourses) {
								alert("No puedes editar tus datos mientras tengas preinscripciones abiertas. Modifica tus tados en los datos de preinscripción")
							} else {
								setEditSensitiveDataModal(true)
							}
						}}
					/>
					: null
				}
			</View>

			{changePassword ? <ChangePasswordModal onClose={() => setChangePassword(false)} /> : null}
			{changeEmail ? <ChangeEmailModal onClose={() => setChangeEmail(false)} /> : null}
			{editDataModal ? <EditDataModal setOpen={() => setEditDataModal(false)} /> : null}
			{editSensitiveDataModal ? <EditSensitiveDataModal setOpen={() => setEditSensitiveDataModal(false)} course={undefined} courseId={undefined} preinscriptionData={undefined} /> : null}
		</>
	)
}

const ChangePasswordModal = ({ onClose }) => {

	const [loading, setLoading] = useState(false)

	const schema = yup.object().shape({
		password: yup.string().min(6, "Mínimo 6 caracteres").max(20, "Máximo 20 caracteres").required("Campo requerido"),
		repeatPassword: yup.string().min(6, "Mínimo 6 caracteres").max(20, "Máximo 20 caracteres").required("Campo requerido").oneOf([yup.ref("password"), null], "Las contraseñas no coinciden")
	})

	const auth = getAuth();
	const resolver = useYupValidationResolver(schema)

	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		mode: "onChange",
		resolver,
		defaultValues: {
			previousPassword: "",
			password: "",
			repeatPassword: ""
		},
	});



	const changePass = async (values) => {
		setLoading(true)
		const user = auth.currentUser;
		signInWithEmailAndPassword(auth, user.email, values.previousPassword).then(user => {

			updatePassword(user.user, values.password).then(() => {
				alert("Contraseña actualizada correctamente")
				onClose()
			}).catch((error) => {
				alert(error.message)
				setLoading(false)
			});

		}).catch(err => {
			alert("Error al actualizar la contraseña, las credenciales no coinciden")
			setLoading(false)
		})

	}

	return (
		<Modal modalContainerStyle={{ width: "100%", maxWidth: 450 }}>
			<View>
				<StyledText style={{ fontFamily: "Title", fontSize: 32 }}>Cambiar la contraseña</StyledText>
			</View>
			<View style={{ paddingVertical: 15 }}>
				<TextField
					control={control}
					name="previousPassword"
					type="previousPassword"
					placeholder="Contraseña Actual..."
					secureInput={true}
					error={errors.previousPassword}
					label="Contraseña Actual"
				/>
				<TextField
					control={control}
					name="password"
					type="password"
					placeholder="Contraseña..."
					secureInput={true}
					error={errors.password}
					containerProps={{ style: { marginTop: 15 } }}
					label="Contraseña"
				/>
				<TextField
					containerProps={{ style: { marginTop: 15 } }}
					control={control}
					name="repeatPassword"
					type="password"
					placeholder="Repite Contraseña..."
					secureInput={true}
					error={errors.repeatPassword}
					label="Repite Contraseña"
				/>
			</View>
			<View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
				<Button disabled={loading} round={7} height={35} color="#f2f2f2" label="Cerrar" onPress={onClose} />
				<Button disabled={loading} loading={loading} round={7} height={35} label="Cambiar" onPress={handleSubmit(changePass)} />
			</View>
		</Modal>
	)
}

const ChangeEmailModal = ({ onClose }) => {

	const app = getApp()
	const auth = getAuth()
	const db = getFirestore()
	const functions = getFunctions(app, "europe-west1")
	process.env.NODE_ENV === "development" && connectFunctionsEmulator(functions, "localhost", 5001);

	const [loading, setLoading] = useState(false)

	const schema = yup.object().shape({
		email: yup.string().email("Email no válido").required("Campo requerido"),
		repeatEmail: yup.string().email("Email no válido").required("Campo requerido").oneOf([yup.ref("email"), null], "Los emails no coinciden"),
		password: yup.string().required("Campo requerido"),
	})

	const resolver = useYupValidationResolver(schema)

	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		mode: "onChange",
		resolver,
		defaultValues: {
			email: "",
			repeatEmail: "",
			password: ""
		},
	});



	const changeEmail = async (values) => {

		setLoading(true)


		const canUpdateEmail = httpsCallable(functions, 'canUpdateEmail');

		const sendUserVerificationEmail = httpsCallable(functions, "sendUserVerificationEmail")



		const user = auth.currentUser;

		signInWithEmailAndPassword(auth, user.email, values.password).then(user => {

			return canUpdateEmail({ email: values.email }).then(res => {
				return runTransaction(db, async (transaction) => {
					const userRef = doc(db, "users", user.user.uid)

					transaction.update(userRef, { email: values.email });

					updateEmail(user.user, values.email).then(async () => {

						const sendMail = await sendUserVerificationEmail({ email: values.email }).then(res => {
							return true
						}).catch(err => {
							return false
						})
						alert(`Email actualizado correctamente${sendMail ? "" : " pero no se ha podido enviar el email de verificación, haga click en enviar email de verificación en la siguiente pantalla."}`)
						window.location = "/email-verification"
						onClose()
						setLoading(false)
					}).catch((err) => {
						throw new Error(err)
					});
				})
			}).catch(err => {
				throw new Error(err)
			})

		}).catch(err => {
			if (err.message.includes("auth/wrong-password")) {
				alert("Contraseña incorrecta")
			} else {
				alert(err.message.replace("FirebaseError: ", ""))
			}
			setLoading(false)
		})


	}

	return (
		<Modal modalContainerStyle={{ width: "100%", maxWidth: 450 }}>
			<View>
				<StyledText style={{ fontFamily: "Title", fontSize: 32 }}>Cambiar el email</StyledText>
				<StyledText style={{ marginTop: 10 }}>Una vez pulses el botón cambiar, se enviará un correo de verificación al nuevo email. Asegurate de introducir el email correctamente o tu cuenta podría quedar bloqueada.</StyledText>
			</View>
			<View style={{ paddingVertical: 15 }}>
				<TextField
					control={control}
					name="password"
					type="password"
					secureInput={true}
					placeholder="Contraseña..."
					error={errors.password}
					label="Contraseña Actual"
				/>
				<TextField
					containerProps={{ style: { marginTop: 15 } }}
					control={control}
					name="email"
					type="email"
					placeholder="Email..."
					error={errors.email}
					label="Email"
				/>
				<TextField
					containerProps={{ style: { marginTop: 15 } }}
					control={control}
					name="repeatEmail"
					type="email"
					placeholder="Repetir Email..."
					error={errors.repeatEmail}
					label="Repetir Email"
				/>
			</View>
			<View style={{ marginTop: 20, flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
				<Button disabled={loading} round={7} height={35} color="#f2f2f2" label="Cerrar" onPress={onClose} />
				<Button disabled={loading} loading={loading} round={7} height={35} label="Cambiar" onPress={handleSubmit(changeEmail)} />
			</View>
		</Modal>
	)
}

const EditDataModal = ({ setOpen }) => {

	const db = getFirestore()
	const storage = getStorage()

	const [step, setStep] = useState(0)
	const [loading, setLoading] = useState(false)

	const [error, setError] = useState(null);

	const sh = useWindowDimensions().height
	const isPhone = useMediaQuery({ query: '(max-width: 1000px)' })
	const { customization, userData } = useContext(AuthContext)
	const imageRef = useRef(null)
	const idFrontRef = useRef(null)
	const idBackRef = useRef(null)
	const documentTypeRef = useRef()



	const validationSchema = yup.object({
		image: yup.string().required("La imagen del documento es Requerida"),
		phone: yup.string().required("El telefono es Requerido").min(7, "El teléfono debe tener al menos 7 dígitos"),
		address: yup.string().required("La dirección es Requerida").min(3, "La dirección debe tener al menos 3 caracteres"),
		province: yup.string().required("La provincia de residencia es Requerida"),
		town: yup.string().required("La ciudad de residencia es Requerida"),
		postalCode: yup.string().required("El código postal es Requerido").min(5, "Debe tener al menos 5 caracteres").max(10, "Debe tener 10 caracteres máximo"),
		idFront: yup.string().required("Este campo es Requerido"),
		idBack: yup.string().required("Este campo es Requerido"),
		idExpirationDate: yup.date().typeError("Debes introducir una fecha válida").min(new Date(), "El documento de identidad no puede estar caducado").required("Este campo es Requerido"),
	});

	const resolver = useYupValidationResolver(validationSchema);

	const {
		handleSubmit,
		watch,
		trigger,
		control,
		formState: { errors },
	} = useForm({
		mode: "onChange",
		resolver: resolver,
		defaultValues: {
			image: userData.image || "",
			phone: userData.phone || "",
			address: userData.address || "",
			province: userData.province || "",
			town: userData.town || "",
			postalCode: userData.postalCode || userData.cp || "",
			idFront: userData.idFront || userData.idCardFront || "",
			idBack: userData.idBack || userData.idCardBack || "",
			idExpirationDate: userData.idExpirationDate ? moment(userData.idExpirationDate?.toDate ? userData.idExpirationDate?.toDate() : userData.idExpirationDate).format("YYYY-MM-DD") : "",
		},
	})

	const province = watch("province")

	const uploadImage = (uri, name) => {
		return new Promise((resolve, reject) => {
			const file = ref(storage, `users/${userData.id}/${name}`)
			uploadString(file, uri.replace("data:image/png;base64,", ""), "base64", {
				contentType: "image/png",
			}).then(res => {
				resolve(file.fullPath)
			}).catch(err => {
				// console.log(err)
				reject(err)
			})
		});
	}

	const submitForm = async (values) => {
		setLoading(true)

		const userRef = doc(db, "users", userData.id)


		const i = imageRef?.current?.getCanvas().toDataURL()
		const iff = idFrontRef?.current?.getCanvas().toDataURL()
		const ib = idBackRef?.current?.getCanvas().toDataURL()
		const p = await uploadImage(i, `image_${new Date().valueOf()}.png`)
		const piff = await uploadImage(iff, `idFront_${new Date().valueOf()}.png`)
		const pib = await uploadImage(ib, `idBack_${new Date().valueOf()}.png`)

		const birthDate = values.birthDate ? { birthDate: new Date(values.birthDate) } : {}
		const idExpirationDate = values.idExpirationDate ? { idExpirationDate: new Date(values.idExpirationDate) } : {}

		await updateDoc(userRef, {
			...values,
			...birthDate,
			...idExpirationDate,
			image: p,
			idFront: piff,
			idBack: pib,
		}).then(res => {
			setStep(2);
			setTimeout(() => {
				window.location.reload()
			}, 1000)
		}).catch(err => {
			alert("Ha ocurrido un error al actualizar los datos del usuario")
		})
		setLoading(false);

	}

	const canGoNextStep = () => {

		const fieldToValidate =
			step === 0
				? ["image", "phone", "address", "province", "town", "postalCode"]
				: step === 1
					? ["idBack", "idFront", "idExpirationDate"]
					: null

		trigger(fieldToValidate)
			.then((res) => {
				res && setStep(step + 1);
			});
	};




	return (
		<Modal modalContainerStyle={{ maxHeight: sh - 50, width: "95%", maxWidth: 750 }} duration={100} padding={0} >

			{loading ?
				<View style={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0, zIndex: 10, backgroundColor: "white", alignItems: "center", justifyContent: "center", }}>
					<ActivityIndicator color={customization.mainColor} size="large" />
					<StyledText style={{ fontSize: 34, fontFamily: "Title", marginTop: 20 }}>Comprobando información</StyledText>
				</View>
				: null
			}

			<View style={{ width: "100%" }}>

				{/* STEPPER */}
				{step !== 4 ? <View style={{ padding: 15 }}>
					<Stepper steps={[
						{
							label: "Datos personales",
							step: 0,
						},
						{
							label: "Documentación",
							step: 1,
						},
					]} onPress={(ev) => setStep(ev)} step={step} />
				</View> : null}


				<ScrollView style={{ maxHeight: sh - 220, flex: 1 }} contentContainerStyle={{ paddingHorizontal: 15, flexGrow: 1 }}>
					{/* DATOS PERSONALES */}
					<View
						pointerEvents={step === 0 ? "auto" : "none"}
						style={{ position: step === 0 ? "relative" : 'absolute', overflow: "hidden", height: step === 0 ? "auto" : 0, opacity: step === 0 ? 1 : 0 }} >
						<ImageField
							ref={imageRef}
							control={control}
							name="image"
							key="image"
							label="Foto de Perfil (Fondo Blanco)"
							error={errors["image"]}
							initialValue={userData.image}
							height={300}
							hint={{title:"Muestra correcta foto DNI" , color: '#111', rules: ['Debe estar mirando de frente', 'Debe tener fondo blanco','Debe ser una foto actual y reconocible'], image: 'https://firebasestorage.googleapis.com/v0/b/cdnonformacion.uno.work/o/hint-dni.png?alt=media&token=e502d0de-d3a8-44e9-a023-36e392a7eb21'}}
						/>
						<View style={{ width: "100%", flexDirection: isPhone ? "column" : "row", marginBottom: 15 }}>
							<TextField error={errors["phone"]} containerProps={{ style: { marginTop: isPhone ? 10 : 0, flex: 1 } }} name={"phone"} key="phone" label="Teléfono" placeholder="Teléfono..." control={control} />
						</View>
						
						<View style={{ width: "100%", flexDirection: "row", marginBottom: 15 }}>
							<TextField error={errors["address"]} name={"address"} key="address" label="Dirección actual" placeholder="Dirección..." control={control} />
						</View>
						<View style={{ width: "100%", flexDirection: isPhone ? "column" : "row", marginBottom: 15 }}>
							<SelectField error={errors["province"]} options={provinces} valueKey="provincia" labelKey="provincia" name={"province"} key="province" label="Provincia" placeholder="Provincia..." control={control} />
							<SelectField error={errors["town"]} containerProps={{ style: { marginLeft: isPhone ? 0 : 10, marginTop: isPhone ? 10 : 0, flex: 1 } }} options={provinces.find(el => el.provincia === province)?.localidades || []} name={"town"} key="town" label="Localidad" placeholder="Localidad..." control={control} />
							<TextField error={errors["postalCode"]} containerProps={{ style: { marginLeft: isPhone ? 0 : 10, marginTop: isPhone ? 10 : 0, flex: 1 } }} name={"postalCode"} key="postalCode" label="Código Postal" placeholder="Código Postal..." control={control} />
						</View>
					</View>

					{/* DATOS DE DNI */}
					<View
						pointerEvents={step === 1 ? "auto" : "none"}
						style={{ position: step === 1 ? "relative" : 'absolute', overflow: "hidden", height: step === 1 ? "auto" : 0, opacity: step === 1 ? 1 : 0 }}>
						<View style={{ width: "100%", flexDirection: isPhone ? "column" : "row", marginBottom: 15 }}>
							<TextField error={errors["idExpirationDate"]} containerProps={{ style: { marginTop: isPhone ? 10 : 0, flex: 1 } }} type="date" name={"idExpirationDate"} key="idExpirationDate" label="Fecha de Validez" placeholder="Fecha de Validez..." control={control} />
						</View>

						<View style={{ flexDirection: isPhone ? "column" : "row", }}>
							
							<ImageField
								ref={idFrontRef}
								control={control}
								name="idFront"
								key="idFront"
								label="Frontal"
								error={errors["idFront"]}
								initialValue={userData.idFront}
								stencilProps={{
									movable: true,
									resizable: true,
								}}
								height={250}
								containerProps={{
									style: {
										width: 350,
										height: 300
									}
								}}
								zoomButtons={true}
								hint={{title:"Muestra Anverso DNI" , color: '#111', rules: ['Debe estar bien encuadrado', 'Debe estar enfocado','Debe estar correctamente iluminado'], image: 'https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/users%2Flb0kmM1fCBSUS8Is6VrbEUIuEGG3%2FidFront_1710764725714.png?alt=media&token=b1734ced-56e4-4462-be8f-180110d23b1e'}}
							/>
							<ImageField
								ref={idBackRef}
								height={250}
								containerProps={{
									style: {
										marginLeft: isPhone ? 0 : 10,
										width: 350,
										height: 300
									}
								}}
								zoomButtons={true}
								stencilProps={{
									movable: true,
									resizable: true,
								}}
								control={control}
								name="idBack"
								key="idBack"
								initialValue={userData.idBack}
								label="Trasera"
								error={errors["idBack"]}
								hint={{title:"Muestra Reverso DNI" , color: '#111', rules: ['Debe estar bien encuadrado', 'Debe estar enfocado','Debe estar correctamente iluminado'], image: 'https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/users%2Flb0kmM1fCBSUS8Is6VrbEUIuEGG3%2FidBack_1710764726396.png?alt=media&token=0ddf09fb-bc77-4fe1-ae7e-3ab6a7cac669'}}
							/>
						</View>
					</View>




					{/* REGISTRO CORRECTO */}
					<View pointerEvents={step === 2 ? "auto" : "none"} style={{ position: step === 2 ? "relative" : 'absolute', overflow: "hidden", height: step === 2 ? "auto" : 0, opacity: step === 2 ? 1 : 0 }} >
						<View style={{ alignItems: "center", justifyContent: "center", flex: 1 }}>
							<Ionicons name={"checkmark-circle-outline"} color={"green"} size={120} />
							<StyledText style={{ fontSize: 34, fontFamily: "Title", textAlign: "center" }}>¡Tus datos se han modificado con éxito!</StyledText>
							<StyledText style={{ textAlign: "center", fontSize: 16, maxWidth: 500, marginVertical: 15, fontFamily: "DemiBold" }}>
								Hemos modificado tu información correctamente, si has introducido alguno de los datos erroneamente, puedes modificarlos en cualquier momento.
							</StyledText>

							<View style={{ flexDirection: "row", marginTop: 10, marginBottom: 30, alignItems: "center", justifyContent: "center" }}>
								<Button style={{ marginRight: 10 }} round={7} label="Cerrar" primary onPress={() => {
									setOpen(false)
								}} />
							</View>
						</View>
					</View>


				</ScrollView>
			</View >



			{/* BOTONES INFERIORES */}
			{step !== 2 ?
				<View
					style={{ padding: 15, flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
					<Button
						disabled={loading}
						onPress={() => step === 0 ? setOpen(false) : setStep(step - 1)}
						label={step === 0 ? "Cerrar" : "Volver"}
						round={5}
						height={40}
						color="gainsboro"
					/>

					<Button
						disabled={loading}
						onPress={step === 1 ? handleSubmit(submitForm) : () => canGoNextStep()}
						label={loading ? "Cargando.." : (step === 1) ? "Completar" : "Siguiente"}
						round={5}
						height={40}
					/>
				</View>
				: null
			}

		</Modal>
	)
}

const EditSensitiveDataModal = ({ setOpen, course, courseId, preinscriptionData }) => {

	const app = getApp()
	const db = getFirestore()
	const storage = getStorage()
	const functions = getFunctions(app, "europe-west1")

	process.env.NODE_ENV === "development" && connectFunctionsEmulator(functions, "localhost", 5001);

	const [loading, setLoading] = useState(false)
	const [success, setSuccess] = useState(false)

	const [error, setError] = useState(null);

	const sh = useWindowDimensions().height
	const isPhone = useMediaQuery({ query: '(max-width: 1000px)' })
	const { customization, userData } = useContext(AuthContext)
	const { advances } = useContext(AdvanceContext)
	const idFrontRef = useRef(null)
	const idBackRef = useRef(null)

	const documentTypeRef = useRef()

	const today = new Date();

	const validationSchema = yup.object({
		name: yup.string().required("El nombre es Requerido"),
		surname: yup.string().required("El apellido es Requerido"),
		birthDate: yup.date().typeError("Debes introducir una fecha válida").min(new Date("1920-01-01"), "La fecha no puede ser menor a 1920").max(new Date(today.setFullYear(today.getFullYear() - 16)), "Debes tener mas de 16 años").required("La fecha de nacimiento es Requerida"),
		nationality: yup.string().required("La nacionalidad es Requerida"),
		birthCountry: yup.string().required("El pais de nacimiento es Requerido"),
		birthProvince: yup.string().required("La provincia de nacimiento es Requerida"),
		gender: yup.string().oneOf(["male", "female"], "El género es Requerido").required("El género es Requerido"),
		idNumber: yup.string().trim().test("validateId", "El Número de documento introducido no es válido", (ev) => {
			const p = ValidateSpanishID.ValidateSpanishID(ev)
			return documentTypeRef.current === "passport" ? true : p.valid
		}).required("El número de identificación es obligatorio"),
		idType: yup.string().oneOf(["dni", "nie", "passport"], "El tipo de documento es obligatorio").required("El tipo de documento es obligatorio"),
	});

	const resolver = useYupValidationResolver(validationSchema);

	const {
		handleSubmit,
		watch,
		trigger,
		control,
		formState: { errors },
	} = useForm({
		mode: "onChange",
		resolver: resolver,
		defaultValues: {
			name: userData.name || "",
			surname: userData.surname || "",
			gender: userData.gender || "",
			birthDate: userData.birthDate ? moment(userData.birthDate?.toDate ? userData.birthDate?.toDate() : userData.birthDate).format("YYYY-MM-DD") : "",
			idExpirationDate: userData.idExpirationDate ? moment(userData.idExpirationDate?.toDate ? userData.idExpirationDate?.toDate() : userData.idExpirationDate).format("YYYY-MM-DD") : "",
			nationality: userData.nationality || "ESP",
			birthCountry: userData.birthCountry || "ESP",
			birthProvince: userData.birthProvince || "",
			idFront: userData.idFront || "",
			idBack: userData.idBack || "",
			idType: (userData.idType || "").toLowerCase(),
			idNumber: userData.idNumber || userData.idCardNumber || "",
		},
	})

	const birthCountry = watch("birthCountry")
	const documentType = watch("idType")

	useEffect(() => {
		documentTypeRef.current = documentType
		trigger("idNumber")
	}, [documentType])

	const uploadImage = (uri, name) => {
		return new Promise((resolve, reject) => {
			const file = ref(storage, `users/${userData.id}/${name}`)
			uploadString(file, uri.replace("data:image/png;base64,", ""), "base64", {
				contentType: "image/png",
			}).then(res => {
				resolve(file.fullPath)
			}).catch(err => {
				// console.log(err)
				reject(err)
			})
		});
	}

	const checkIdNumber = httpsCallable(functions, "checkIdNumber")

	const submitForm = async (values) => {
		const iff = idFrontRef?.current?.getCanvas().toDataURL()
		const ib = idBackRef?.current?.getCanvas().toDataURL()
		setLoading(true)

		const lastTenant = advances.sort((a, b) => b.createdAt - a.createdAt)[0]?.tenantId || false

		if (!lastTenant) {
			alert("Debes preinscribirte a un curso antes de poder editar tus datos")
			setLoading(false)
			return
		}

		const tenantRef = doc(db, `tenants/${lastTenant}/users`, userData.uid)
		const piff = await uploadImage(iff, `idFront_${new Date().valueOf()}.png`)
		const pib = await uploadImage(ib, `idBack_${new Date().valueOf()}.png`)

		try {

			checkIdNumber({ idNumber: values.idNumber }).then(res => {

				updateDoc(tenantRef, {
					...values,
					birthDate: new Date(values.birthDate),
					idExpirationDate: new Date(values.idExpirationDate),
					requestedAt: serverTimestamp(),
					changesRequested: true,
					idNumber: (values.idNumber || "").toLocaleUpperCase().replace(/[^A-Z0-9]+/ig, ""),
					idFront: piff,
					idBack: pib,
				}).then(res => {
					setSuccess(true)
					setLoading(false);
				}).catch(err => {
					setError(err)
					setLoading(false);
				})
			}).catch(err => {
				// console.log(err.message)
				alert("Error solicitando la modificación de los datos. Ya existe un usuario con este número de documento de identidad asociado")
				setLoading(false)
				// setFormError("idNumber", { message: err.message }, { shouldFocus: true })
			})


		} catch (err) {
			setError(err)
			setLoading(false)
		}
	}

	return (
		<Modal modalContainerStyle={{ maxHeight: sh - 50, width: "95%", maxWidth: 750 }} duration={100} padding={0} >

			{loading ?
				<View style={{ paddingVertical: 50, borderRadius: 10, backgroundColor: "white", alignItems: "center", justifyContent: "center", }}>
					<ActivityIndicator color={customization.mainColor} size="large" />
					<StyledText style={{ fontSize: 34, fontFamily: "Title", marginTop: 20 }}>Comprobando información</StyledText>
				</View>
				: null
			}

			{!loading && !success ?
				<View style={{ width: "100%" }}>
					<ScrollView style={{ maxHeight: sh - 150, flex: 1 }} contentContainerStyle={{ paddingHorizontal: 15, paddingTop: 20, flexGrow: 1 }}>

						<View>
							<StyledText style={{ fontFamily: "Title", fontSize: 32 }}>Modificar Datos</StyledText>
						</View>

						{/* DATOS PERSONALES */}
						<View>
							<View style={{ width: "100%", flexDirection: "row", marginBottom: 15 }}>
								<TextField error={errors["name"]} name={"name"} key="name" label="Nombre" placeholder="Nombre..." control={control} />
								<TextField error={errors["surname"]} containerProps={{ style: { marginLeft: 10, flex: 1 } }} name={"surname"} key="surname" label="Apellidos" placeholder="Apellidos..." control={control} />
							</View>
							<View style={{ width: "100%", flexDirection: isPhone ? "column" : "row", marginBottom: 15 }}>
								<TextField error={errors["birthDate"]} type="date" name={"birthDate"} key="birthDate" label="Fecha de Nacimiento" placeholder="Fecha de Nacimiento..." control={control} />
								<SelectField error={errors["gender"]} containerProps={{ style: { marginLeft: isPhone ? 0 : 10, marginTop: isPhone ? 10 : 0, flex: 1 } }} defaultValue={null} options={[{ label: "Hombre", value: "male" }, { label: "Mujer", value: "female" }]} labelKey="label" valueKey={"value"} name={"gender"} key="gender" label="Género" placeholder="Género..." control={control} />
							</View>
							<View style={{ width: "100%", flexDirection: "row", marginBottom: 15 }}>
								<SelectField error={errors["nationality"]} options={countries} valueKey="code" labelKey="name" name={"nationality"} key="nationality" label="Nacionalidad" placeholder="Nacionalidad..." control={control} />
								<SelectField error={errors["birthCountry"]} containerProps={{ style: { marginLeft: 10, flex: 1 } }} options={countries} valueKey="code" labelKey="name" name={"birthCountry"} key="birthCountry" label="Pais de Nacimiento" placeholder="Pais de Nacimiento..." control={control} />
							</View>
							<View style={{ width: "100%", flexDirection: "row", marginBottom: 15 }}>
								{birthCountry === "ESP" ?
									<SelectField error={errors["birthProvince"]} defaultValue={null} options={provinces} valueKey="provincia" labelKey="provincia" name={"birthProvince"} key="birthProvince" label="Provincia de Nacimiento" placeholder="Provincia de Nacimiento..." control={control} />
									:
									<TextField error={errors["birthProvince"]} name={"birthProvince"} key="birthProvince" label="Provincia de Nacimiento" placeholder="Provincia de Nacimiento..." control={control} />
								}
							</View>
							<View style={{ width: "100%", flexDirection: isPhone ? "column" : "row", marginBottom: 15 }}>
								<SelectField error={errors["idType"]} labelKey={"label"} valueKey={"value"} options={[{ label: "DNI", value: "dni" }, { label: "NIE", value: "nie" }, { label: "Pasaporte", value: "passport" }]} name={"idType"} key="idType" label="Tipo de Documento" placeholder="Tipo de Documento..." control={control} />
								<TextField error={errors["idExpirationDate"]} containerProps={{ style: { marginLeft: isPhone ? 0 : 10, marginTop: isPhone ? 10 : 0, flex: 1 } }} type="date" name={"idExpirationDate"} key="idExpirationDate" label="Fecha de Validez" placeholder="Fecha de Validez..." control={control} />
								<TextField error={errors["idNumber"]} containerProps={{ style: { marginLeft: isPhone ? 0 : 10, marginTop: isPhone ? 10 : 0, flex: 1 } }} name={"idNumber"} key="idNumber" label="Número de Documento" placeholder="Número de documento..." control={control} />
							</View>

						</View>
						<View>

							<View>
								<View style={{ flexDirection: isPhone ? "column" : "row", }}>
									<ImageField
										ref={idFrontRef}
										control={control}
										name="idFront"
										key="idFront"
										label="Frontal"
										error={errors["idFront"]}
										initialValue={userData.idFront}
										stencilProps={{
											movable: true,
											resizable: true,
										}}
										height={250}
										containerProps={{
											style: {
												width: 350,
												height: 300
											}
										}}
										zoomButtons={true}
										hint={{title:"Muestra Anverso DNI" , color: '#111', rules: ['Debe estar bien encuadrado', 'Debe estar enfocado','Debe estar correctamente iluminado'], image: 'https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/users%2Flb0kmM1fCBSUS8Is6VrbEUIuEGG3%2FidFront_1710764725714.png?alt=media&token=b1734ced-56e4-4462-be8f-180110d23b1e'}}
									/>
									<ImageField
										ref={idBackRef}
										height={250}
										containerProps={{
											style: {
												marginLeft: isPhone ? 0 : 10,
												width: 350,
												height: 300
											}
										}}
										zoomButtons={true}
										stencilProps={{
											movable: true,
											resizable: true,
										}}
										control={control}
										name="idBack"
										key="idBack"
										initialValue={userData.idBack}
										label="Trasera"
										error={errors["idBack"]}
										hint={{title:"Muestra Reverso DNI" , color: '#111', rules: ['Debe estar bien encuadrado', 'Debe estar enfocado','Debe estar correctamente iluminado'], image: 'https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/users%2Flb0kmM1fCBSUS8Is6VrbEUIuEGG3%2FidBack_1710764726396.png?alt=media&token=0ddf09fb-bc77-4fe1-ae7e-3ab6a7cac669'}}
									/>
								</View>
							</View>
						</View>
					</ScrollView>
					{/* BOTONES INFERIORES */}
					<View
						style={{ padding: 15, flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
						<Button
							disabled={loading}
							onPress={() => setOpen(false)}
							label={"Volver"}
							round={5}
							height={40}
							color="gainsboro"
						/>

						<Button
							loading={loading}
							disabled={loading}
							onPress={handleSubmit(submitForm)}
							label={"Solicitar Cambios"}
							round={5}
							height={40}
						/>
					</View>
				</View >
				: null
			}

			{/* REGISTRO CORRECTO */}
			{success ?
				<View style={{ alignItems: "center", justifyContent: "center", flex: 1 }}>
					<Ionicons name={"checkmark-circle-outline"} color={"green"} size={120} />
					<StyledText style={{ fontSize: 34, fontFamily: "Title", textAlign: "center" }}>¡Se han solicitado los cambios con éxito!</StyledText>
					<StyledText style={{ textAlign: "center", fontSize: 16, maxWidth: 500, marginVertical: 15, fontFamily: "DemiBold" }}>
						Una vez que el administrador haya revisado los cambios, recibirás una notificación con la confirmación y se actualizarán en un perfil.
					</StyledText>

					<View style={{ flexDirection: "row", marginTop: 10, marginBottom: 30, alignItems: "center", justifyContent: "center" }}>
						<Button style={{ marginRight: 10 }} round={7} label="Cerrar" primary onPress={() => setOpen(false)} />
					</View>
				</View>
				: null
			}

		</Modal>
	)
}

const FieldView = ({ label, value, style = {} }) => {
	const { customization } = useContext(AuthContext)
	return (
		<View style={{ marginVertical: 10, flex: 1, ...style }}>
			<StyledText style={{ fontSize: 16, marginBottom: 10, fontFamily: "Regular", color: customization?.mainColor }}>{label}</StyledText>
			<View style={{ backgroundColor: "#f2f2f2", borderRadius: 5, paddingHorizontal: 10, paddingVertical: 5 }}>
				<StyledText style={{ fontSize: 16, fontFamily: "Regular", color: "black" }}>{value}</StyledText>
			</View>
		</View>
	)
}

const UserStats = () => {
	return (
		{/* <Animatable.View animation={"fadeInUp"} style={{ width: "100%", marginTop: 20 }}>
		<View style={[styles.shadow, { backgroundColor: "white", paddingHorizontal: 20, paddingVertical: 20, borderRadius: 20, }]}>
			<View style={{ marginBottom: 20 }}>
				<Text style={{ fontSize: 24, fontFamily: "DemiBold", color: "darkgray", borderRadius: 7 }}>Mis Estadísticas</Text>
			</View>
			<View style={{ flexDirection: "row", flexWrap: "wrap" }}>
				<View style={{ marginHorizontal: "1%", marginVertical: "1%", backgroundColor: "#F9F9F9", paddingVertical: 20, paddingHorizontal: 10, borderRadius: 15, width: "31%", alignItems: "center", justifyContent: "center" }}>
					<View style={{ position: "absolute", top: -15, left: -5, transform: [{ rotateZ: "0deg" }] }}>
						<Text style={{ fontSize: 36 }}>🔥</Text>
					</View>
					<StyledText style={{ color: customization.mainColor, fontSize: 50, fontWeight: "bold", color: "black" }}>
						0
					</StyledText>
					<StyledText style={{ textAlign: "center" }}>
						Racha de Días Activo
					</StyledText>
				</View>
				<View style={{ marginHorizontal: "1%", marginVertical: "1%", backgroundColor: "#F9F9F9", paddingVertical: 20, paddingHorizontal: 10, borderRadius: 15, width: "31%", alignItems: "center", justifyContent: "center" }}>
					<View style={{ position: "absolute", top: -15, left: -5, transform: [{ rotateZ: "0deg" }] }}>
						<Text style={{ fontSize: 40 }}>🎓</Text>
					</View>
					<StyledText style={{ color: customization.mainColor, fontSize: 50, fontWeight: "bold", color: "black" }}>
						0
					</StyledText>
					<StyledText style={{ textAlign: "center" }}>
						Contenidos Visualizados
					</StyledText>
				</View>
				<View style={{ marginHorizontal: "1%", marginVertical: "1%", backgroundColor: "#F9F9F9", paddingVertical: 20, paddingHorizontal: 10, borderRadius: 15, width: "31%", alignItems: "center", justifyContent: "center" }}>
					<View style={{ position: "absolute", top: -15, left: -5, transform: [{ rotateZ: "0deg" }] }}>
						<Text style={{ fontSize: 36 }}>❤️</Text>
					</View>
					<StyledText style={{ color: customization.mainColor, fontSize: 50, fontWeight: "bold", color: "black" }}>
						0
					</StyledText>
					<StyledText style={{ textAlign: "center" }}>
						Likes en Contenidos
					</StyledText>
				</View>
				<View style={{ marginHorizontal: "1%", marginVertical: "1%", backgroundColor: "#F9F9F9", paddingVertical: 20, paddingHorizontal: 10, borderRadius: 15, width: "31%", alignItems: "center", justifyContent: "center" }}>
					<View style={{ position: "absolute", top: -15, left: -5, transform: [{ rotateZ: "0deg" }] }}>
						<Text style={{ fontSize: 36 }}>💬</Text>
					</View>
					<StyledText style={{ color: customization.mainColor, fontSize: 50, fontWeight: "bold", color: "black" }}>
						0
					</StyledText>
					<StyledText style={{ textAlign: "center" }}>
						Comentarios en Contenidos
					</StyledText>
				</View>
			</View>
		</View>

	</Animatable.View> */}
	)
}

const UserAchievements = () => {
	return (
		{/* {customization?.achievements?.length > 0 &&
					<Animatable.View animation={"fadeInUp"} style={{ width: "100%", marginTop: 20 }}>
						<View style={[styles.shadow, { backgroundColor: "white", paddingHorizontal: 20, paddingVertical: 20, borderRadius: 20, height: "auto" }]}>

							<View style={{ marginBottom: 20 }}>
								<Text style={{ fontSize: 24, fontFamily: "DemiBold", color: "darkgray", borderRadius: 7 }}>Logros</Text>
							</View>

							<FlatList
								data={customization?.achievements}
								renderItem={({ item, index }) => (
									<View
										style={{
											opacity: data.points ? (data.points < item.pointsToAchieve ? 0.4 : 1) : 0.4,
											flex: 1,
											margin: 10,
											backgroundColor: "#f2f2f2",
											paddingVertical: 15,
											paddingHorizontal: 15,
											borderRadius: 10,
											flexDirection: "row",
											alignItems: "center",
											justifyContent: "flex-start"
										}}>
										<SecureImage
											animation="bounceInLeft"
											duration={700}
											delay={100 * index}
											easing="ease-in"
											style={{ height: 80, width: 80 }}
											uri={item.image || "https://www.flaticon.com/svg/vstatic/svg/1170/1170611.svg?token=exp=1618222951~hmac=13b361bb681557e55c6ad7298d603ebf"}
										/>
										<View style={{ marginLeft: 15 }}>
											<Text numberOfLines={2} style={{ fontSize: 20, marginVertical: 10, fontFamily: "DemiBold", color: "darkgray" }}>{item.name}</Text>
											<Text style={{ fontFamily: "DemiBold", fontSize: 20, color: customization.mainColor }}>{item.pointsToAchieve}</Text>
										</View>
									</View>
								)}
								numColumns={1}
								keyExtractor={(item, index) => item.id}
							/>
						</View>
					</Animatable.View>
				} */}
	)
}

const UserLevel = () => {
	return (
		<View style={[styles.shadow, { padding: 20, borderRadius: 25, width: "100%", backgroundColor: "white" }]}>
			{customization?.levels?.length > 0 &&
				<View style={{ paddingHorizontal: 10, paddingBottom: 15 }}>
					<LinearBarIndicator backgroundColor="gainsboro" rounded={6} height={12} gradient={["#B4EC51", "#429321"]} width={"95%"} percentage={percentage} />
				</View>
			}
			<View style={{ flexDirection: "row", }}>

				{customization?.levels?.length > 0 &&
					<SecureImage style={{ width: 80, height: 80, marginRight: 20 }} uri={customization?.levels?.filter(el => el.pointsToAchieve <= (userData.points || 0)).sort((a, b) => a.pointsToAchieve - b.pointsToAchieve)[0]?.image} />
				}
				<View>
					<StyledText style={{ fontSize: 45, fontWeight: "bold" }}>{userData.points ? userData.points : 0} <StyledText style={{ fontSize: 20 }}>Puntos</StyledText></StyledText>
					{customization?.levels?.length > 0 &&
						<StyledText style={{ fontSize: 22, fontFamily: "DemiBold", color: customization.mainColor }}>
							Nivel {customization?.levels?.filter(el => el.pointsToAchieve <= (userData.points || 0)).sort((b, a) => a.pointsToAchieve - b.pointsToAchieve)[0]?.name}
						</StyledText>
					}
				</View>
			</View>
		</View>
	)
}

const UserPayments = () => {
	const db = getFirestore()
	const storage = getStorage()
	const sc = useWindowDimensions()
	const { userData, tenant, customization } = useContext(AuthContext);
	const docRef = query(collection(db, `payments`), where("userId", "==", userData.id));
	const { data, isLoading } = useFirestoreQueryData(["payments", { id: userData.id }], docRef);

	return (
		<View style={{ marginTop: 20, flexDirection: isPhone ? "column" : "row" }}>
			<View animation={"fadeInUp"} duration={400} style={{ width: "100%" }}>
				{data?.length === 0 ?
					<View style={[styles.shadow, { backgroundColor: "white", borderRadius: 20, minHeight: 200, alignItems: "center", justifyContent: "center" }]}>
						<StyledText style={{ fontSize: 22, marginBottom: 10, fontFamily: "DemiBold", color: "darkgray" }}>Sin Pagos o Transacciones</StyledText>
					</View>
					:
					data?.map(el => {
						return (
							<View key={el.id} style={[styles.shadow, { marginBottom: 10, backgroundColor: "white", flexDirection: "row", borderRadius: 10, padding: 20 }]}>
								<View style={{ flex: 1 }}>
									{el?.courseId && <StyledText style={{ fontSize: 34, fontFamily: "Title" }}>{userData?.courses?.find(t => t.id === el?.courseId)?.name || ""}</StyledText>}
									{el?.createdAt && <StyledText style={{ marginBottom: 5, fontSize: 16, }}>Realizado: {moment(el?.createdAt?.toDate()).format("DD-MM-YYYY")}</StyledText>}
									{el?.AMOUNT && <StyledText style={{ fontSize: 16, }}>Cantidad: {el?.AMOUNT}{el?.CURRENCY}</StyledText>}
								</View>
								{/* <View style={{ alignItems: "center", }}>
									<FileButton uri={el.licenseUriFront || el.licenseUri} label="Licencia" style={{ marginBottom: 10 }} />
									<FileButton uri={el.diplomaUri} label="Diploma" />
								</View> */}
							</View>
						)
					})
				}
			</View>
		</View >
	)
}

