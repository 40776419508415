import { Ionicons } from "@expo/vector-icons";
import { getApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import React, { useContext, useEffect, useState } from "react";
import { Image, ImageBackground, KeyboardAvoidingView, ScrollView, Text, TextInput, View, useWindowDimensions } from "react-native";
import Button from "../../components/common/Button";
import StyledText from "../../components/common/StyledText";
import AuthContext from "../../context/AuthContext";
import customLog from "../../utils/customLog";

const Login = ({ navigation }) => {

	const isMobile = useWindowDimensions().width < 600
	const [checking, setChecking] = useState(false);
	const [passwordRequired, setPasswordRequired] = useState(true)
	const [sent, setSent] = useState(false);

	const [error, setError] = useState(null);
	const [resetPassword, setResetPassword] = useState(false);
	const [sendResetPassword, setSendResetPassword] = useState(false);
	const { userData } = useContext(AuthContext);

	const app = getApp()
	const auth = getAuth()
	const functions = getFunctions(app, "europe-west1")
	process.env.NODE_ENV === "development" && connectFunctionsEmulator(functions, "localhost", 5001);

	const [form, setForm] = useState({
		identifier: "",
		password: "",
	});

	useEffect(() => {
		userData.id && navigation.navigate("App");
	}, [])

	const reset = () => {
		setError(false)
		setResetPassword(false)
		setSendResetPassword(false)
		setSent(false)
		setChecking(false)
		setForm({
			identifier: "",
			password: "",
		})
	}

	const handleLogin = async () => {

		if (resetPassword) {
			setChecking(true);
			const sendUserPasswordReset = httpsCallable(functions, "sendUserPasswordReset")
			sendUserPasswordReset({ email: form.identifier }).then(res => {
				setChecking(false);
				setSendResetPassword(true)
				// alert("Se ha enviado un correo para restablecer la contraseña")
			}).catch(err => {
				setChecking(false);
				setError({ message: "Ocurrió un error enviando el mensaje de recuperación de contraseña. Asegurate que introdujiste el correo electrónico correctamente." });
			})
		} else if (passwordRequired) {

			const email = form.identifier.trim().toLocaleLowerCase();
			const password = form.password.trim();
			setChecking(true);
			signInWithEmailAndPassword(auth, email, password)
				.then(async (re) => {
					customLog({ screen: "Login", action: "login", description: "Usuario logueado"})
					if (!re.user.emailVerified) {
						navigation.navigate("VerifyEmail")
					} else {
						window.location.reload();
					}
				}).catch(err => {
					const code = err.code;
					if (code === "auth/wrong-password") {
						setError({ message: "Usuario o Contraseña Incorrectos. Si es la primera vez que accedes a la nueva plataforma de ONFormación, pero ya estás registrado, deberás recuperar contraseña antes de acceder o emplear el botón de acceso sin contraseña" });
					} else if (code === "auth/invalid-email") {
						setError({ message: "El Email introducido no es válido válido" });
					} else if (code === "auth/user-not-found") {
						setError({ message: "Usuario o Contraseña Incorrectos. Asegurate que introdujiste el correo electrónico y la contraseña corectamente" });
					} else if (code === "auth/too-many-requests") {
						setError({ message: "Demasiados intentos. Inténtalo más tarde" });
					} else {
						setError({ message: "Ocurrió un error, inténtalo de nuevo" });
					}
					setChecking(false)
				})
		} else {
			setChecking(true);

			const sendUserPasswordlessEmail = httpsCallable(functions, "sendUserPasswordlessEmail")

			sendUserPasswordlessEmail({ email: form.identifier })
				.then(() => {
					window.localStorage.setItem("emailForSignIn", form.identifier);
					setChecking(false);
					setSent(true);
				})
				.catch((error) => {
					setError({ message: "Ocurrió un error enviando el correo de acceso sin contraseña. Asegurate que introdujiste el correo electrónico correctamente." });
					// setError({ message: error.message });
					setChecking(false);
					setSent(false);
					// setError(error);
				});
			// authFunctions.signIn({ identifier: form.identifier, password: form.password });
		}
	};

	return (
		<KeyboardAvoidingView style={{ flex: 1, height: "100%" }} behavior="padding" enabled>
			<Button
				style={{ position: "absolute", height: 35, top: 15, left: 15, zIndex: 10 }}
				round={7}
				height={35}
				width={35}
				color="white"
				icon={"chevron-back"}
				reverse
				onPress={() => {
					reset()
					navigation.canGoBack() ? navigation.goBack() : navigation.navigate("StackPublic", { screen: "HomeScreen" })
				}}
			/>
			<ScrollView
				contentContainerStyle={{
					flexGrow: 1,
					alignItems: "center",
					backgroundColor: "white",
				}}
				style={{
					flex: 1,
					width: "100%"
				}}>
				<View style={{ position: "absolute", filter: "hue-rotate(290deg)", zIndex: 1, height: "100%", width: "100%", top: 0, left: 0, right: 0, bottom: 0 }}>
				</View>
				<ImageBackground
					style={{
						flex: 1,
						width: "100%",
						alignItems: "center",
						justifyContent: "center",
						zIndex: 2,
						paddingVertical: 15
					}}
					source={{ uri: "https://images.unsplash.com/photo-1511204338744-5d4e9b3ffee0?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=900&ixid=MnwxfDB8MXxyYW5kb218MHx8c29jY2VyfHx8fHx8MTY2NDg5MjU5Ng&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1600" }}
					resizeMethod="resize">
					<View style={{ backgroundColor: "rgba(0,0,0,.6)", flex: 1, position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: 1 }} />
					<View style={{ zIndex: 2, width: "100%", height: "100%", maxWidth: 600, flex: 1, alignItems: "center", paddingHorizontal: 20, justifyContent: "center" }}>

						{/* <View style={{ maxWidth: 500, marginHorizontal: 15, flexDirection: 'row', backgroundColor: "#fcbb2c", padding: 10, borderRadius: 7, position: "absolute", top: 10 }}>
							<Ionicons name="warning-outline" color="white" size={40} style={{ marginRight: 10 }} />
							<StyledText style={{ color: "white", fontFamily: "DemiBold" }}>Si es la primera vez que accedes a la nueva plataforma de ONFormación, pero ya estás registrado, deberás recuperar contraseña antes de acceder o emplear el botón de acceso sin contraseña</StyledText>
						</View> */}

						<View style={{ height: 200, width: 200, marginBottom: 0 }}>
							<Image style={{ resizeMode: "contain", height: 200, width: 200 }} source={{ uri: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Flogo-icon.svg?alt=media&token=def11157-2ecf-426c-a199-76329a14bbdc" }} />
							{/* <Image style={{ resizeMode: "contain", height: 150, width: 300 }} source={logo} /> */}
						</View>
						{!passwordRequired && !sent && <View style={{ marginBottom: 20 }}>
							<Text style={{ color: "white", fontSize: 22, textAlign: "center" }}>
								Si ya estás registrado, introduce tu correo electrónico y recibirás un enlace de acceso en unos segundos.
							</Text>
						</View>}
						{!sendResetPassword && resetPassword && <View style={{ marginBottom: 20 }}>
							<Text style={{ color: "white", fontSize: 22, textAlign: "center" }}>
								Introduce tu email para recibir un enlace para restablecer tu contraseña.
							</Text>
						</View>}


						{sendResetPassword ?
							<View>
								<StyledText style={{ marginBottom: 20, textAlign: "center", fontSize: 20, color: "white" }}>Se ha enviado un mensaje de recuperación de contraseña al email indicado. Siga los pasos para restablecer su contraseña. Si en unos minutos no lo ha recibido compruebe la carpeta de Spam</StyledText>
								<Button height="35px" round={5} primary label="Volver" onPress={() => {
									setResetPassword(false)
									setSendResetPassword(false)
								}} />
							</View>
							: sent ?
								<View>
									<StyledText style={{ marginBottom: 20, textAlign: "center", fontSize: 20, color: "white" }}>Se ha enviado un correo de verificación para acceder a la plataforma, si no lo ha recibido compruebe la carpeta de Spam</StyledText>
									<Button loading={checking} disabled={checking} height="35px" round={5} primary label="Volver a Enviar" onPress={handleLogin} />

								</View>
								:
								<View style={{ gap: 25, width: "100%" }}>
									<View>
										<View style={{ alignItems: "flex-start", marginBottom: 10, width: "100%" }}>
											<StyledText style={{ fontFamily: "DemiBold", color: "white", fontSize: 16, opacity: .8 }}>Email</StyledText>
										</View>
										<TextInput
											textContentType="emailAddress"
											clearButtonMode="while-editing"
											autoCapitalize="none"
											placeholder="Email"
											keyboardType="email-address"
											placeholderTextColor={"gainsboro"}
											style={{
												borderColor: error ? "#E9312B" : "gainsboro",
												color: "black",
												height: 42,
												width: "100%",
												backgroundColor: "white",
												fontFamily: "Regular",
												fontSize: 16,
												padding: 15,
												borderRadius: 5,
												borderWidth: error ? 2 : 1,
												outlineWidth: 0,
											}}
											onChangeText={(text) => setForm({ ...form, identifier: text.toLowerCase().trim() })}
											value={form && form.identifier}
										/>
										{passwordRequired && !resetPassword &&
											<>
												<View style={{ marginTop: 20, marginBottom: 10, alignItems: "flex-start", width: "100%" }}>
													<StyledText style={{ fontFamily: "DemiBold", color: "white", fontSize: 16, opacity: .8 }}>Contraseña</StyledText>
												</View>
												<TextInput
													textContentType="password"
													autoCapitalize="none"
													placeholder="Contraseña"
													placeholderTextColor={"gainsboro"}
													style={{
														borderColor: error ? "#E9312B" : "gainsboro",
														color: "black",

														height: 42,
														width: "100%",
														backgroundColor: "white",
														fontFamily: "Regular",
														fontSize: 16,
														padding: 15,
														borderRadius: 5,
														borderWidth: error ? 2 : 1,
														outlineWidth: 0,
													}}
													onChangeText={(text) => setForm({ ...form, password: text })}
													value={form && form.password}
													secureTextEntry={true}
												/>
											</>
										}
									</View>

									{error ?
										<View style={{ flexDirection: 'row', backgroundColor: "#E9312B", padding: 10, borderRadius: 7, alignItems: "center" }}>
											<Ionicons name="warning-outline" color="white" size={40} style={{ marginRight: 10 }} />
											<StyledText style={{ color: "white", fontFamily: "DemiBold" }}>{error.message || "Error desconocido"}</StyledText>
										</View>
										:
										null
									}

									{/* <Text style={{ fontFamily: "Regular", color: "red", textAlign: "center", marginTop: 15, fontSize: 16, marginBottom: 20 }}>{error && (error.message || "Error desconocido")}</Text> */}

									<View style={{ gap: 10, width: "100%" }}>
										<View style={{ flexDirection: "row", width: "100%", gap: 10, justifyContent: resetPassword ? "center" : "space-between" }}>
											{!resetPassword && <Button height="35px" style={{ flex: 1 }} round={5} color="#0b1831" onPress={() => navigation.navigate("Register")} label="Registrarme" />}
											<Button height="35px" style={{ flex: 1 }} round={5} onPress={handleLogin} loading={checking} label={!passwordRequired ? "Enviar Enlace" : resetPassword ? "Recuperar" : "Acceder"} />
										</View>

										<View style={{ width: "100%", gap: 10 }}>

											<Button
												height="35px"
												style={{ flex: 1, gap: 5, width: "100%" }}
												round={5}
												color="white"
												onPress={() => {
													setError(false)
													setPasswordRequired(!passwordRequired)
													setResetPassword(false)
												}}
												icon={!passwordRequired ? "key" : "mail-outline"}
												label={!passwordRequired ? "Acceso con Contraseña" : "Acceso sin Contraseña"}
											/>

											{passwordRequired ?
												<Button
													height={35}
													style={{ flex: 1, width: "100%", gap: 5 }}
													round={5}
													color={resetPassword ? "white" : "#fcbb2c"}
													reverse={resetPassword}
													icon={resetPassword ? "arrow-back" : undefined}
													onPress={() => {
														setResetPassword(!resetPassword)
														setError(false)
													}}
													label={resetPassword ? "Volver al Login" : "Recuperar Contraseña"}
												/>
												: null
											}
										</View>
									</View>
								</View>
						}

					</View>
				</ImageBackground>
			</ScrollView>
		</KeyboardAvoidingView>
	);
};

export default Login;
