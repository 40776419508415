import { FontAwesome, Ionicons } from "@expo/vector-icons";
import { useFirestoreQuery } from "@react-query-firebase/firestore";
import { getApp } from "firebase/app";
import { collection, getFirestore, limit, orderBy, query, updateDoc } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import { getDownloadURL, getStorage, ref, uploadString } from "firebase/storage";
import { get, omit } from "lodash";
import moment from "moment";
import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { ActivityIndicator, ScrollView, TouchableOpacity, View, useWindowDimensions } from "react-native";
import { useMediaQuery } from "react-responsive";
import * as yup from "yup";
import Stepper from "../../components/Stepper";
import Button from "../../components/common/Button";
import Modal from "../../components/common/Modal";
import StyledText from "../../components/common/StyledText";
import CustomFormCV from "../../components/customForms/CustomFormCV";
import CustomFormDeclaracionJurada from "../../components/customForms/CustomFormDeclaracionJurada";
import CustomFormDeclaracionMedica from "../../components/customForms/CustomFormDeclaracionMedica";
import ImageField from "../../components/formComponents/ImageField";
import MultiField from "../../components/formComponents/MultiField";
import SelectField from "../../components/formComponents/SelectField";
import TextField from "../../components/formComponents/TextField";
import AuthContext from "../../context/AuthContext";
import countries from "../../utils/countries.json";
import getValidationType from "../../utils/getValidationType";
import provinces from "../../utils/provinces.json";
import sanitizeObject from "../../utils/sanitizeObject";
import useYupValidationResolver from "../../utils/useYupValidationResolver";

const steps = [
    {
        label: "Datos personales",
        step: 0,
    },
    {
        label: "Documentación",
        step: 1,
    },
    {
        label: "Datos Adicionales",
        step: 2,
    }
]

const EditDataModal = ({ setOpen, course, courseId, preinscriptionData }) => {

    const isPhone = useMediaQuery({ query: '(max-width: 1000px)' })
    const today = new Date()
    const [openSubform, setOpenSubform] = useState(false);
    const [step, setStep] = useState(0)
    const [loading, setLoading] = useState(false)
    const [loadingNext, setLoadingNext] = useState(false)
    const [registerSuccess, setRegisterSuccess] = useState(false)

    const sh = useWindowDimensions().height
    const { customization, tenant, userData } = useContext(AuthContext)
    const imageRef = useRef()
    const idFrontRef = useRef()
    const idBackRef = useRef()
    const documentTypeRef = useRef()
    const db = getFirestore()
    const storage = getStorage()
    const app = getApp()
    const functions = getFunctions(app, "europe-west1")
    process.env.NODE_ENV === "development" && connectFunctionsEmulator(functions, "localhost", 5001);

    const collectionRef = query(collection(db, `${course.ref.path}/messages`), orderBy("createdAt", "desc"), limit(1));

    const { data, isLoading } = useFirestoreQuery(["courseavs", "messages", courseId, userData.id], collectionRef, {}, {
        select: snapshot => {
            return snapshot?.docs?.map(doc => ({ ...doc.data(), id: doc.id, ref: doc.ref })) || []
        },
        onError: (err) => console.log(err)
    })

    const validationSchema = yup.object({
        name: yup.string().required("El nombre es Requerido"),
        surname: yup.string().required("El apellido es Requerido"),
        image: yup.string().required("La imagen del documento es Requerida"),
        phone: yup.string().required("El telefono es Requerido").min(7, "El teléfono debe tener al menos 7 dígitos"),
        address: yup.string().required("La dirección es Requerida").min(3, "La dirección debe tener al menos 3 caracteres"),
        birthDate: yup.date().typeError("Debes introducir una fecha válida").min(new Date("1920-01-01"), "La fecha no puede ser menor a 1920").max(new Date(today.setFullYear(today.getFullYear() - 16)), "Debes tener mas de 16 años").required("La fecha de nacimiento es Requerida"),
        nationality: yup.string().required("La nacionalidad es Requerida"),
        birthCountry: yup.string().required("El pais de nacimiento es Requerido"),
        birthProvince: yup.string().required("La provincia de nacimiento es Requerida"),
        gender: yup.string().oneOf(["male", "female"], "El género es Requerido").required("El género es Requerido").typeError("El género es Requerido"),
        province: yup.string().required("La provincia de residencia es Requerida"),
        town: yup.string().required("La ciudad de residencia es Requerida"),
        postalCode: yup.string().required("El código postal es Requerido").min(5, "Debe tener al menos 5 caracteres").max(10, "Debe tener 10 caracteres máximo"),
        idFront: yup.string().required("Este campo es Requerido"),
        idBack: yup.string().required("Este campo es Requerido"),
        idExpirationDate: yup.date().typeError("Debes introducir una fecha válida").min(new Date(), "El documento de identidad no puede estar caducado").required("Este campo es Requerido").typeError("Debes introducir una fecha válida"),
        // idNumber: yup.string().trim().test("validateId", "El Número de documento introducido no es válido", (ev) => {
        //     const p = ValidateSpanishID.ValidateSpanishID(ev)
        //     return documentTypeRef.current === "passport" ? true : p.valid
        // }).required("El número de identificación es obligatorio"),
        // idType: yup.string().oneOf(["dni", "nie", "passport"], "El tipo de documento es obligatorio").required("El tipo de documento es obligatorio"),
        ...preinscriptionData.reduce((acc, item) => {
            if (item.type.includes("form") || item.type.includes("16") || item.type.includes("12") || item.type.includes("51")) {
                return {
                    ...acc,
                    [item.key]: yup.object().test("formChecker", "El formulario debe estar completado", ev => {
                        const t = ev && Object.keys(ev)?.length > 0
                        return item.required ? t : true
                    }).typeError(item.name + " Este formulario está incompleto o contiene errores")
                }
            } else {
                return { ...acc, [item.key]: getValidationType(item.type, "Este campo es obligatorio", item.required) }
            }
        }, {})
    });

    const resolver = useYupValidationResolver(validationSchema);


    const {
        handleSubmit,
        setValue,
        getValues,
        watch,
        reset,
        trigger,
        setError,
        control,
        formState: { errors },
    } = useForm({
        mode: "onChange",
        resolver: resolver,
        defaultValues: {
            image: course.image || "",
            name: course.name || "",
            surname: course.surname || "",
            phone: course.phone || "",
            birthDate: moment(course?.birthDate.toDate ? course?.birthDate.toDate() : course?.birthDate).format("YYYY-MM-DD"),
            nationality: course.nationality,
            birthCountry: course.birthCountry,
            birthProvince: course.birthProvince,
            address: course.address,
            province: course.province,
            town: course.town,
            postalCode: course.postalCode,
            idType: course.idType,
            idFront: course.idFront,
            idBack: course.idBack,
            idNumber: course.idNumber,
            gender: course.gender,
            idExpirationDate: moment(course?.idExpirationDate.toDate ? course?.idExpirationDate.toDate() : course?.idExpirationDate).format("YYYY-MM-DD"),
            ...preinscriptionData.reduce((acc, el) => ({
                ...acc,
                [el.key]: course[el.key] || null
            }), {})
        }
    })


    // const documentType = watch("idType")

    // useEffect(() => {
    //     documentTypeRef.current = documentType
    //     trigger("idNumber")
    // }, [documentType])

    const birthCountry = watch("birthCountry")
    const province = watch("province")

    const uploadImage = (uri, name) => {
        return new Promise((resolve, reject) => {
            const file = ref(storage, `users/${userData.id}/${name}`)

            uploadString(file, uri.replace("data:image/png;base64,", ""), "base64", {
                contentType: "image/png",
            }).then(res => {
                resolve(file.fullPath)
            }).catch(err => {
                console.log(err)
                reject(err)
            })
        });
    }

    const submitForm = async (values) => {
        setLoading(true)

        try {
            let p, piff, pib
            // console.log(values.image, course.image)
            if (values.image !== course.image) {
                const i = imageRef.current.getCanvas().toDataURL()
                p = await uploadImage(i, `imageChanged_${new Date().valueOf()}.png`)
            }

            if (values.idFront !== course.idFront) {
                const iff = idFrontRef.current.getCanvas().toDataURL()
                piff = await uploadImage(iff, `idFront_${new Date().valueOf()}.png`)
            }
            if (values.idBack !== course.idBack) {
                const ib = idBackRef.current.getCanvas().toDataURL()
                pib = await uploadImage(ib, `idBack_${new Date().valueOf()}.png`)
            }
            const birthDate = values.birthDate ? { birthDate: new Date(values.birthDate) } : {}
            const idExpirationDate = values.idExpirationDate ? { idExpirationDate: new Date(values.idExpirationDate) } : {}
            const ss = sanitizeObject(omit(values, ["idType", "email", "idNumber"]))
            const variables = {
                ...ss,
                ...birthDate,
                ...idExpirationDate,
                // idNumber: (values.idNumber || "").toLocaleUpperCase().replace(/[^A-Z0-9]+/ig, ""),
                ...(course.status !== "APPROVED" && { status: "PENDINGREVIEW" }),
                ...(p && { image: p }),
                ...(piff && { idFront: piff }),
                ...(pib && { idBack: pib }),
            }

            updateDoc(course.ref, variables).then(res => {
                setStep(4)
                setRegisterSuccess(true)
                setLoading(false)
            }).catch(err => {
                console.log(err)
                setLoading(false)
            })

        } catch (err) {
            console.log(err)
            setLoading(false)
            alert("Error al actualizar los datos, por favor intenta de nuevo")
        }
        // addDoc(ref).then(res => setCompleted(true))
    }

    const checkIdNumber = httpsCallable(functions, "checkIdNumber")

    const canGoNextStep = () => {
        const fieldToValidate =
            step === 0
                ? ["name", "surname", "image", "birthDate", "phone", "nationality", "nationality", "birthCountry", "birthProvince", "address", "province", "town", "postalCode"]
                : step === 1
                    ? ["idBack", "idFront", "idExpirationDate"]
                    : step === 2 ?
                        preinscriptionData.map((field, idx) => field.key) || []
                        :
                        []
        setLoadingNext(true)
        trigger(fieldToValidate)
            .then((res) => {
                if (res) {
                    if (step === 1 // && !userData.idNumber
                    ) {
                        const idNumber = getValues("idNumber")
                        checkIdNumber({ idNumber }).then(res => {
                            setStep(step + 1)
                            setLoadingNext(false)
                        }).catch(err => {
                            setError("idNumber", { message: err.message }, { shouldFocus: true })
                            setLoadingNext(false)
                        })
                    } else {
                        setStep(step + 1)
                        setLoadingNext(false)
                    }
                } else {
                    setLoadingNext(false)
                }
            }).catch(err => {
                setLoadingNext(false)
            })
    };

    function renderCustomForm(type, closeCb, submitCb, initialData) {
        switch (type) {
            case 16:
                return <CustomFormCV closeCallback={closeCb} submitCallback={submitCb} initialData={initialData} />
            case 12:
                return <CustomFormDeclaracionJurada closeCallback={closeCb} submitCallback={submitCb} initialData={initialData} />
            case 51:
                return <CustomFormDeclaracionMedica closeCallback={closeCb} submitCallback={submitCb} initialData={initialData} />
            default:
                throw new Error("El tipo de formulario es incorrecto y/o no existe")
        }
    }


    return <Modal modalContainerStyle={{ maxHeight: sh - 50, width: "95%", maxWidth: 750 }} duration={100} padding={0} >
        {loading &&
            <View style={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0, zIndex: 10, backgroundColor: "white", alignItems: "center", justifyContent: "center", }}>
                <ActivityIndicator color={customization.mainColor} size="large" />
                <StyledText style={{ fontSize: 34, fontFamily: "Title", marginTop: 20 }}>Comprobando información</StyledText>
            </View>
        }
        <View style={{ width: "100%" }}>
            {step !== 4 ? <View style={{ padding: 15 }}>
                <Stepper steps={steps} onPress={(ev) => setStep(ev)} step={step} />
            </View> : null}
            <ScrollView style={{ maxHeight: sh - 220, flex: 1 }} contentContainerStyle={{ paddingHorizontal: 20, flexGrow: 1 }}>
                <View style={{ paddingBottom: 10 }}>

                    {course?.status === "CHANGESREQUIRED" && !registerSuccess ?
                        <View style={{ backgroundColor: "orange", borderRadius: 5, padding: 10 }}>

                            {!isLoading && data?.length > 0 && <StyledText style={{ color: "white", fontSize: 12, marginTop: 5 }}>{moment(data?.sort((a, b) => a?.createdAt?.toDate() > b?.createdAt?.toDate())[0]?.createdAt?.toDate()).format("DD-MM-YYYY HH:mm")}</StyledText>}
                            <View style={{ flex: 1 }}>
                                <StyledText style={{ fontFamily: "DemiBold", color: "white", fontSize: 16, whiteSpace: "break-spaces" }}>{isLoading ? "Por favor, revisa los datos y envía los cambios. solicitados" : data?.sort((a, b) => a?.createdAt?.toDate() > b?.createdAt?.toDate())[0]?.body || "Por favor, revisa los datos y envía los cambios. solicitados"}</StyledText>
                            </View>

                        </View>
                        : null
                    }
                </View>
                <View
                    pointerEvents={step === 0 ? "auto" : "none"}
                    style={{ position: step === 0 ? "relative" : 'absolute', overflow: "hidden", height: step === 0 ? "auto" : 0, opacity: step === 0 ? 1 : 0 }} >
                    <ImageField
                        ref={imageRef}
                        control={control}
                        name="image"
                        key="image"
                        label="Foto de Perfil (Fondo Blanco)"
                        error={errors["image"]}
                        initialValue={getValues("image")}
                        height={300}
						hint={{title:"Muestra correcta foto DNI" , color: '#111', rules: ['Debe estar mirando de frente', 'Debe tener fondo blanco','Debe ser una foto actual y reconocible'], image: 'https://firebasestorage.googleapis.com/v0/b/cdnonformacion.uno.work/o/hint-dni.png?alt=media&token=e502d0de-d3a8-44e9-a023-36e392a7eb21'}}
                        
                    />
                    <View style={{ width: "100%", flexDirection: "row", marginBottom: 15 }}>
                        <TextField error={errors["name"]} name={"name"} key="name" label="Nombre" placeholder="Nombre..." control={control} />
                        <TextField error={errors["surname"]} containerProps={{ style: { marginLeft: 10, flex: 1 } }} name={"surname"} key="surname" label="Apellidos" placeholder="Apellidos..." control={control} />
                    </View>
                    <View style={{ width: "100%", flexDirection: isPhone ? "column" : "row", marginBottom: 15 }}>
                        <TextField error={errors["birthDate"]} type="date" name={"birthDate"} key="birthDate" label="Fecha de Nacimiento" placeholder="Fecha de Nacimiento..." control={control} />
                        <TextField error={errors["phone"]} containerProps={{ style: { marginLeft: isPhone ? 0 : 10, marginTop: isPhone ? 10 : 0, flex: 1 } }} name={"phone"} key="phone" label="Teléfono" placeholder="Teléfono..." control={control} />
                        <SelectField error={errors["gender"]} containerProps={{ style: { marginLeft: isPhone ? 0 : 10, marginTop: isPhone ? 10 : 0, flex: 1 } }} defaultValue={null} options={[{ label: "Hombre", value: "male" }, { label: "Mujer", value: "female" }]} labelKey="label" valueKey={"value"} name={"gender"} key="gender" label="Género" placeholder="Género..." control={control} />
                    </View>
                    <View style={{ width: "100%", flexDirection: "row", marginBottom: 15 }}>
                        <SelectField error={errors["nationality"]} options={countries} valueKey="code" labelKey="name" name={"nationality"} key="nationality" label="Nacionalidad" placeholder="Nacionalidad..." control={control} />
                        <SelectField error={errors["birthCountry"]} containerProps={{ style: { marginLeft: 10, flex: 1 } }} options={countries} valueKey="code" labelKey="name" name={"birthCountry"} key="birthCountry" label="Pais de Nacimiento" placeholder="Pais de Nacimiento..." control={control} />
                    </View>
                    <View style={{ width: "100%", flexDirection: "row", marginBottom: 15 }}>
                        {birthCountry === "ESP" ?
                            <SelectField error={errors["birthProvince"]} defaultValue={null} options={provinces} valueKey="provincia" labelKey="provincia" name={"birthProvince"} key="birthProvince" label="Provincia de Nacimiento" placeholder="Provincia de Nacimiento..." control={control} />
                            :
                            <TextField error={errors["birthProvince"]} name={"birthProvince"} key="birthProvince" label="Provincia de Nacimiento" placeholder="Provincia de Nacimiento..." control={control} />
                        }
                    </View>

                    <View style={{ width: "100%", flexDirection: "row", marginBottom: 15 }}>
                        <TextField error={errors["address"]} name={"address"} key="address" label="Dirección actual" placeholder="Dirección..." control={control} />
                    </View>
                    <View style={{ width: "100%", flexDirection: isPhone ? "column" : "row", marginBottom: 15 }}>
                        <SelectField error={errors["province"]} options={provinces} valueKey="provincia" labelKey="provincia" name={"province"} key="province" label="Provincia" placeholder="Provincia..." control={control} />
                        <SelectField error={errors["town"]} containerProps={{ style: { marginLeft: isPhone ? 0 : 10, marginTop: isPhone ? 10 : 0, flex: 1 } }} options={provinces.find(el => el.provincia === province)?.localidades || []} name={"town"} key="town" label="Localidad" placeholder="Localidad..." control={control} />
                        <TextField error={errors["postalCode"]} containerProps={{ style: { marginLeft: isPhone ? 0 : 10, marginTop: isPhone ? 10 : 0, flex: 1 } }} name={"postalCode"} key="postalCode" label="Código Postal" placeholder="Código Postal..." control={control} />
                    </View>
                </View>
                <View
                    pointerEvents={step === 1 ? "auto" : "none"}
                    style={{ position: step === 1 ? "relative" : 'absolute', overflow: "hidden", height: step === 1 ? "auto" : 0, opacity: step === 1 ? 1 : 0 }}>
                    <View style={{ width: "100%", flexDirection: isPhone ? "column" : "row", marginBottom: 15 }}>
                        <SelectField disabled error={errors["idType"]} labelKey={"label"} valueKey={"value"} options={[{ label: "DNI", value: "dni" }, { label: "NIE", value: "nie" }, { label: "Pasaporte", value: "passport" }]} name={"idType"} key="idType" label="Tipo de Documento" placeholder="Tipo de Documento..." control={control} />
                        <TextField error={errors["idExpirationDate"]} containerProps={{ style: { marginLeft: isPhone ? 0 : 10, marginTop: isPhone ? 10 : 0, flex: 1 } }} type="date" name={"idExpirationDate"} key="idExpirationDate" label="Fecha de Validez" placeholder="Fecha de Validez..." control={control} />
                        <TextField disabled error={errors["idNumber"]} containerProps={{ style: { marginLeft: isPhone ? 0 : 10, marginTop: isPhone ? 10 : 0, flex: 1 } }} name={"idNumber"} key="idNumber" label="Número de Documento" placeholder="Número de documento..." control={control} />
                    </View>
                    <View style={{ flexDirection: isPhone ? "column" : "row", }}>
                        <ImageField
                            ref={idFrontRef}
                            control={control}
                            name="idFront"
                            key="idFront"
                            label="Frontal"
                            error={errors["idFront"]}
                            initialValue={getValues("idFront")}
                            stencilProps={{
                                movable: true,
                                resizable: true,
                            }}
                            height={250}
                            containerProps={{
                                style: {
                                    width: 350,
                                    height: 300
                                }
                            }}
                            zoomButtons={true}
							hint={{title:"Muestra Anverso DNI" , color: '#111', rules: ['Debe estar bien encuadrado', 'Debe estar enfocado','Debe estar correctamente iluminado'], image: 'https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/users%2Flb0kmM1fCBSUS8Is6VrbEUIuEGG3%2FidFront_1710764725714.png?alt=media&token=b1734ced-56e4-4462-be8f-180110d23b1e'}}
                        />
                        <ImageField
                            ref={idBackRef}
                            height={250}
                            containerProps={{
                                style: {
                                    marginLeft: isPhone ? 0 : 10,
                                    width: 350,
                                    height: 300
                                }
                            }}
                            zoomButtons={true}
                            stencilProps={{
                                movable: true,
                                resizable: true,
                            }}
                            control={control}
                            name="idBack"
                            key="idBack"
                            initialValue={getValues("idBack")}
                            label="Trasera"
                            error={errors["idBack"]}
							hint={{title:"Muestra Reverso DNI" , color: '#111', rules: ['Debe estar bien encuadrado', 'Debe estar enfocado','Debe estar correctamente iluminado'], image: 'https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/users%2Flb0kmM1fCBSUS8Is6VrbEUIuEGG3%2FidBack_1710764726396.png?alt=media&token=0ddf09fb-bc77-4fe1-ae7e-3ab6a7cac669'}}
                        />
                    </View>
                </View>
                {preinscriptionData?.length > 0 ?
                    <View
                        pointerEvents={step === 2 ? "auto" : "none"}
                        style={{ position: step === 2 ? "relative" : 'absolute', overflow: "hidden", height: step === 2 ? "auto" : 0, opacity: step === 2 ? 1 : 0 }} >
                        <View style={{ width: "100%", marginBottom: 1 }}>
                            <StyledText style={{ fontSize: 34, fontFamily: "Title" }}>Datos Adicionales</StyledText>
                            {preinscriptionData.map((field, idx) => {
                                if (field.type && field.key) {
                                    return field.type.includes("form") || field.type.includes("16") || field.type.includes("12") || field.type.includes("51") ?
                                        <View key={idx} style={{ width: "100%", paddingBottom: 10, }}>
                                            <View key={`${field.name}`}>
                                                <StyledText style={{ fontSize: 16, color: "gray" }}>{field.name}</StyledText>
                                                <View style={{ flexDirection: "row", alignItems: "center", flex: 1, marginVertical: 10 }}>

                                                    <Button
                                                        round={5}
                                                        style={{ flex: 1 }}
                                                        height={35}
                                                        label={getValues(field.key) ? "Modificar" : "Editar"}
                                                        onPress={() => {
                                                            const form = getValues(field.key);
                                                            const defaultValues = {
                                                                ...(form ? form : {}),
                                                                courseName: course?.course?.name || "",
                                                                federationName: course?.course?.tenantName || ""
                                                            };
                                                            setOpenSubform({
                                                                key: field.key,
                                                                formId: field.id,
                                                                formRef: field.ref,
                                                                index: idx,
                                                                defaultValues,
                                                                documentType: (field.type === "form-NrCcaX760nhT7MsGnn3C" || field.type.includes("16")) ? 16 : (field.type === "form-g9BGWNtCXMVF3PZtya5X" || field.type.includes("12")) ? 12 : (field.type === "form-Rs0Uvb149qAQ5jihS55A" || field.type.includes("51")) ? 51 : field.documentType
                                                            })
                                                        }}
                                                    />

                                                    {getValues(field.key) ?
                                                        <FontAwesome name="check" color="#e4002b" size={20} style={{ marginLeft: 10 }} />
                                                        :
                                                        null
                                                    }
                                                </View>
                                            </View>
                                            {get(errors, field.key) ? (
                                                <StyledText style={{ marginTop: 10, fontSize: 14, color: "red" }}>
                                                    {get(errors, field.key)?.message}
                                                </StyledText>
                                            ) : null}
                                        </View>
                                        :
                                        <Fragment key={idx}>
                                            <MultiField
                                                name={field.key}
                                                error={errors[field.key]}
                                                route={`preinscriptionDocuments/${course.tenantId}/course/${courseId}/${userData?.id}`}
                                                label={field.name}
                                                type={field.type}
                                                required={field.required}
                                                customRef={field.ref}
                                                containerProps={{ style: { marginBottom: 10 } }}
                                                setValue={value => setValue(field.key, value)}
                                                control={control}
                                            />
                                            {field.template ?
                                                <TouchableOpacity onPress={() => {
                                                    getDownloadURL(ref(storage, field.template)).then(res => {
                                                        window.open(res, "_blank")
                                                    }).catch(err => {
                                                        console.log({ err })
                                                        alert("No se ha podido descargar el archivo")
                                                    })
                                                }} style={{ marginBottom: 15, flexDirection: "row", alignItems: "center", columnGap: 5, paddingHorizontal: 15, paddingVertical: 10, backgroundColor: "#f2f2f2" }}>
                                                    <Ionicons name="download-outline" size={20} />
                                                    <StyledText style={{ fontSize: 16, color: "black" }}>Descargar plantilla {field.name}</StyledText>
                                                </TouchableOpacity>
                                                : null
                                            }
                                        </Fragment>
                                }
                            })
                            }
                        </View>
                    </View>
                    : null}

                <View pointerEvents={(step === 4) ? "auto" : "none"} style={{ position: step === 4 ? "relative" : 'absolute', overflow: "hidden", height: step === 4 ? "auto" : 0, opacity: step === 4 ? 1 : 0 }} >
                    <View style={{ alignItems: "center", justifyContent: "center", flex: 1 }}>
                        <Ionicons name={registerSuccess ? "checkmark-circle-outline" : "warning-outline"} color={!registerSuccess ? "orange" : "green"} size={120} />
                        <StyledText style={{ fontSize: 34, fontFamily: "Title", textAlign: "center" }}>{registerSuccess ? "Se han modificado tus datos de preinscripción con éxito" : "Ocurrió un error modificando tus datos de preinscripción"}</StyledText>
                        {registerSuccess ?
                            <StyledText style={{ textAlign: "center", fontSize: 16, maxWidth: 500, marginVertical: 15, fontFamily: "DemiBold" }}>
                                Revisaremos tus datos y te enviaremos un correo electrónico con la confirmación de tu preinscripción.
                            </StyledText>
                            :
                            <StyledText style={{ textAlign: "center", fontSize: 16, maxWidth: 500, marginVertical: 15, fontFamily: "DemiBold" }}>
                                Vuelve a intentarlo en unos minutos o ponte en contacto con nosotros. Disculpa las molestias
                            </StyledText>
                        }
                        <View style={{ flexDirection: "row", marginTop: 10, marginBottom: 30, alignItems: "center", justifyContent: "center" }}>
                            <Button
                                style={{ marginRight: 10 }}
                                round={7}
                                label="Cerrar"
                                onPress={() => {
                                    setOpen(null)
                                }}
                            />
                        </View>
                    </View>
                </View>
            </ScrollView>
        </View >
        {step < 3 ?
            <View style={{ paddingHorizontal: 20, paddingBottom: 20, marginTop: 20, flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                <Button
                    disabled={loadingNext || loading}
                    onPress={() => step === 0 ? setOpen(null) : setStep(step - 1)}
                    label={step === 0 ? "Cerrar" : "Volver"} round={"5px"}
                    height={40}
                    color="gainsboro"
                />
                <Button
                    disabled={loadingNext || loading}
                    loading={loadingNext}
                    onPress={(preinscriptionData.length > 0 ? step === 2 : step === 1) ? handleSubmit(submitForm) : () => canGoNextStep()}
                    label={loading ? "Cargando.." : (preinscriptionData.length > 0 ? step === 2 : step === 1) ? "Guardar" : "Siguiente"}
                    round={5}
                    height={40}
                />
            </View>
            : null}
        {(step === 2 && openSubform) ?
            (openSubform?.documentType === 16 || openSubform?.documentType === 12 || openSubform?.documentType === 51 ?
                renderCustomForm(
                    openSubform.documentType,
                    () => setOpenSubform(false),
                    (data) => {
                        setValue(openSubform?.key, data)
                        setOpenSubform(false)
                    },
                    openSubform.defaultValues,
                )
                :
                <Modal padding={0} modalContainerStyle={{ maxWidth: 900, width: "95%", backgroundColor: "white", borderRadius: 7 }} >
                    <Subform
                        defaultValues={openSubform.defaultValues}
                        formRef={openSubform?.formRef}
                        update={(ev) => setValue(openSubform?.key, ev)}
                        fieldRoute={`preinscriptionDocuments/${course.tenantId}/course/${courseId}/${userData?.id}`}
                        index={openSubform.index}
                        formId={openSubform.formId}
                        onClose={() => setOpenSubform(false)}
                    />
                </Modal>
            )
            : null}
    </Modal >
}


export default EditDataModal;