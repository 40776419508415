import moment from "moment"
import { group } from "radash"

export default function (advances, courses) {

	const ads = advances?.filter(el => el.status === "APPROVED") || []
	const cor = ads?.map(el => courses?.find(x => x.id === el.courseId)).filter(x => x) || []

	const reduceClasses = (sections, original, ref) => {
		return sections?.reduce((acc, curr) => {
			if (curr?.sections?.length > 0) {
				const classes = original ? curr?.classes : curr?.classes?.map(el => ({
					...el,
					ref,
					originalStart: el.start,
					originalEnd: el.end,
					start: moment(el.start?.toDate ? el.start?.toDate() : el.start).format("YYYY-MM-DD"),
					startTime: moment(el.start?.toDate ? el.start?.toDate() : el.start).format("HH:mm"),
					end: moment(el.end?.toDate ? el.end?.toDate() : el.end).format("YYYY-MM-DD"),
					endTime: moment(el.end?.toDate ? el.end?.toDate() : el.end).format("HH:mm")
				})) || []
				return [...acc, ...(classes || []), ...reduceClasses(curr?.sections, false, ref)]
			} else {
				const classes = original ? curr?.classes : curr?.classes?.map(el => ({
					...el,
					ref,
					originalStart: el.start,
					originalEnd: el.end,
					start: moment(el.start?.toDate ? el.start?.toDate() : el.start).format("YYYY-MM-DD"),
					startTime: moment(el.start?.toDate ? el.start?.toDate() : el.start).format("HH:mm"),
					end: moment(el.end?.toDate ? el.end?.toDate() : el.end).format("YYYY-MM-DD"),
					endTime: moment(el.end?.toDate ? el.end?.toDate() : el.end).format("HH:mm")
				})) || []
				return [...acc, ...(classes || [])]
			}
		}, [])
	}

	const allClasses = cor?.reduce((acc, cur) => [...acc, ...(reduceClasses(cur?.contentDetails, false, cur?.ref) || [])], [])
	const allClassesOriginal = cor?.reduce((acc, cur) => [...acc, ...(reduceClasses(cur?.contentDetails, true, cur?.ref) || []),], [])
	const processedClasses = group(allClasses, f => f.start)

	const newObj = {}

	Object.keys(processedClasses).map(key => {
		newObj[key] = {
			marked: true,
			dots: processedClasses[key].map(el => el.title)
		}
	})

	const firebaseDateChecker = (d) => d === null ? null : d?.toDate ? d.toDate() : new Date(d)

	const reduceContents = (sections, path, courseId) => {
		return sections?.reduce((acc, curr, index) => {
			if (curr?.sections?.length > 0) {
				const contents = curr?.contents?.map((el, idx) => {
					return {
						...el,
						start: firebaseDateChecker(el.start),
						end: firebaseDateChecker(el.end),
						deadlines: el?.deadlines?.map(el => ({ ...el, start: firebaseDateChecker(el.start), end: firebaseDateChecker(el.end) })) || [],
						path: path,
						content: idx,
						courseId
					}
				}) || []
				return [...acc, ...(contents || []), ...reduceContents(curr?.sections, path + "-" + index, courseId)]
			} else {
				const contents = curr?.contents?.map((el, idx) => {
					return {
						...el,
						start: firebaseDateChecker(el.start),
						end: firebaseDateChecker(el.end),
						deadlines: el?.deadlines?.map(el => ({ ...el, start: firebaseDateChecker(el.start), end: firebaseDateChecker(el.end) })) || [],
						path: `${index}`,
						content: idx < 0 ? 0 : idx,
						courseId
					}
				}) || []

				return [...acc, ...(contents || [])]
			}
		}, [])
	}

	const allContents = cor?.reduce((acc, cur) => [...acc, ...(reduceContents(cur?.contentDetails, "0", cur?.id) || [])], [])
	const nextContents = allContents?.filter(el => el?.deadlines?.length > 0)?.filter(el => el?.deadlines?.some(el => el.start <= new Date() && el.end >= new Date())) || []


	return {
		calendarClasses: newObj,
		allClasses,
		allClassesOriginal,
		nextContents
	}

}

