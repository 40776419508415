import _ from 'lodash';
import * as yup from 'yup';

function getValidationType(type, message = "", required) {
    switch (type) {
        case 'text':
            return required ?
                yup.string().required(message)
                :
                yup.string();;
        case 'textarea':
            return required ?
                yup.string().required(message)
                :
                yup.string();
        case 'document':
            return required ?
                yup.string().required(message)
                :
                yup.string();
        case 'number':
            return required ?
                yup.string().required(message)
                :
                yup.string();
        case 'boolean':
            return required ?
                yup.string().required(message)
                :
                yup.string();
        case 'date':
            return required ?
                yup.date().required(message)
                :
                yup.date();
        case 'datetime':
            return required ?
                yup.date().required(message)
                :
                yup.date();
        case 'time':
            return required ?
                yup.string().required(message)
                :
                yup.string();
        case 'selector':
            return required ?
                yup.string().required(message)
                :
                yup.string();
        default:
            return yup.string();
    }
}

function generateErrMessage(fieldName) {
    return _.capitalize(fieldName) + " es obligatorio"
}

export function generateDynamicYupSchema(fields) {
    const schema = {};
    fields.forEach((field) => {
        schema[field.name] = getValidationType(
            field.type,
            generateErrMessage(field.name),
            field?.required ?? false,
        );
    });

    return yup.object(schema);
}