// import { AssetRecordType, TLUiMenuGroup, TLUiOverrides, Tldraw, createShapeId, getSvgAsImage, menuItem, toolbarItem, useEditor } from '@tldraw/tldraw';
import { AssetRecordType, DefaultMainMenu, DefaultMainMenuContent, TLComponents, TLUiOverrides, Tldraw, TldrawUiMenuGroup, TldrawUiMenuItem, createShapeId, getSvgAsImage, toolbarItem, useEditor } from '@tldraw/tldraw';
import React, { useMemo } from 'react';


import '@tldraw/tldraw/tldraw.css';
import { ActivityIndicator } from 'react-native';
import { ArrowShapeTool } from '../../components/board/customArrow/ArrowShapeTool';
import { ArrowShapeUtil } from '../../components/board/customArrow/ArrowShapeUtil';
import ItemSelector from '../../components/board/menus/ItemSelector';
import { ItemShapeTool, ItemShapeUtil } from '../../components/board/shapes/item/ItemShapeUtil';

const utils = [ItemShapeUtil, ArrowShapeUtil]
const tools = [ItemShapeTool, ArrowShapeTool]
export default function ({ style = {}, initialData, saveCallback, closeCallback, id, element }) {

    const [items, setItems] = React.useState(false)
    const [loading, setLoading] = React.useState(false)

    const uiOverrides: TLUiOverrides = useMemo(() => ({
        tools(editor, tools) {

            tools.element = {
                id: 'element',
                icon: "avatar",
                label: 'Element' as any,
                kbd: 'i',
                readonlyOk: false,
                onSelect: () => {
                    if (items) {
                        setItems(false)
                        editor?.setCurrentTool("select")
                    } else {
                        setItems(true)
                    }
                }
            }

            tools.customarrow = {
                id: "customarrow",
                icon: "chevron-right",
                label: "Conducción",
                kbd: "",
                readonlyOk: true,
                onSelect: () => {
                    editor.setCurrentTool("customarrow");
                },

            };


            return tools
        },
        toolbar(_app, toolbar, { tools }) {
            //BOTTOM TOOLBAR

            toolbar.splice(5, 0, toolbarItem(tools.element))
            toolbar.splice(5, 0, toolbarItem(tools.customarrow))
            return toolbar
        },

    }), [initialData])


    function CustomMainMenu() {
        const editor = useEditor()

        return (
            <DefaultMainMenu>
                <TldrawUiMenuGroup id="example">
                    <TldrawUiMenuItem
                        id="closeItem"
                        label="Salir"
                        icon="exit"
                        readonlyOk
                        onSelect={() => {
                            closeCallback()
                        }}
                    />
                    <TldrawUiMenuItem
                        id="save"
                        label="Guardar"
                        icon="save"
                        readonlyOk
                        onSelect={async (source) => {

                            setLoading(true)
                            const editorData = editor.store.getSnapshot()
                            // const ids = [...editor.currentPageShapeIds]
                            const frameId = createShapeId("frame:" + element.id)
                            const svg = await editor.getSvg([frameId], {
                                scale: 1,
                                background: false,
                                padding: 0,
                            })
                            const dataURL = await getSvgAsImage(svg, false, { type: "png", quality: 1, scale: 1 })

                            saveCallback({ snap: editorData, svg: dataURL })
                            setLoading(false)
                        }}
                    />
                </TldrawUiMenuGroup>
                <DefaultMainMenuContent />
            </DefaultMainMenu>
        )
    }

    const components: TLComponents = {
        MainMenu: CustomMainMenu,
    }



    return (
        <>
            {loading ?
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", position: 'fixed', zIndex: 100, background: "rgba(0,0,0,.5)", inset: 0, top: 0, }}>
                    <ActivityIndicator />
                </div>
                : null
            }
            <div className="tldraw__editor" style={{ position: 'fixed', inset: 0, ...style }} >
                <Tldraw
                    autoFocus
                    overrides={uiOverrides}
                    shapeUtils={utils}
                    tools={tools}
                    components={components}
                    snapshot={initialData}
                    persistenceKey={initialData ? id : undefined}
                    onMount={(editor) => {

                        if (initialData) return

                        const assetId = AssetRecordType.createId(element.id)
                        const fieldId = createShapeId("asset:" + element.id)
                        const frameId = createShapeId("frame:" + element.id)

                        editor.createShapes([
                            {
                                id: frameId,
                                type: "frame",
                                x: 0,
                                y: 0,
                                props: {
                                    name: "Video",
                                    w: element.width,
                                    h: element.height,
                                }
                            }
                        ])

                        editor.createAssets([
                            {
                                id: assetId,
                                type: 'image',
                                typeName: 'asset',
                                meta: {
                                    w: element.width,
                                    h: element.height,
                                },
                                props: {
                                    src: element.thumbnail,
                                    w: element.width,
                                    h: element.height,
                                    name: "asset",
                                    isAnimated: false,
                                    mimeType: "image/png",
                                }
                            }
                        ])


                        editor.createShapes([
                            {
                                id: fieldId,
                                type: 'item',
                                x: 0,
                                y: 0,
                                isLocked: true,
                                props: {
                                    assetId: assetId,
                                    w: element.width,
                                    h: element.height
                                },
                            }
                        ])

                        editor.sendToBack([fieldId])
                    }}
                >
                    <ItemSelector elements={data.items} visible={items} closeCallback={() => setItems(false)} tool="item" />

                </Tldraw>

            </div>
        </>
    )
}


const data = {
    items: [
        {
            id: "focus",
            name: "Focos",
            items: [
                {
                    id: "1",
                    name: "focus-red",
                    description: "foco-Rojo",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Ffocus-red.png?alt=media&token=f15f0c65-d92a-43c4-8bf4-c8f00fae13d7",
                    width: 512,
                    height: 1570,
                },
                {
                    id: "2",
                    name: "focus-green",
                    description: "foco-Verde",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Ffocus-green.png?alt=media&token=64dd5d4c-44c4-4c09-8d35-424add05b181",
                    width: 512,
                    height: 1570,
                },
                {
                    id: "3",
                    name: "focus-blue",
                    description: "foco-Azul",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Ffocus-blue.png?alt=media&token=ddbe22e7-b834-4b5e-85cb-c58c2c10ceb3",
                    width: 512,
                    height: 1570,
                },
                {
                    id: "4",
                    name: "focus-black",
                    description: "foco-Black",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Ffocus-black.png?alt=media&token=23eb4f68-fa82-4d1a-8abb-0763520d8906",
                    width: 512,
                    height: 1570,
                },

            ]
        },
        {
            id: "halo",
            name: "Halos",
            items: [
                {
                    id: "halo-1",
                    name: "halo-red",
                    description: "Halo Rojo",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fhalo-red.png?alt=media&token=3f1921ff-da3f-468f-a299-ef3253c5a7cf",
                    width: 512,
                    height: 522,
                },
                {
                    id: "halo-2",
                    name: "halo-green",
                    description: "Halo Verde",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fhalo-green.png?alt=media&token=6ff77a7f-8d1c-4c0b-8294-823d990653b4",
                    width: 512,
                    height: 522,
                },
                {
                    id: "halo-3",
                    name: "halo-blue",
                    description: "Halo Azul",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fhalo-blue.png?alt=media&token=0b44ab0a-3c74-43e6-9b75-10a5c14411b5",
                    width: 512,
                    height: 522,
                },
                {
                    id: "halo-4",
                    name: "halo-black",
                    description: "Halo Black",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fhalo-black.png?alt=media&token=385e2b2e-2a07-4f1f-9a2f-4b87f4664eb6",
                    width: 512,
                    height: 522,
                },

            ]
        },
        {
            id: "rings",
            name: "Anillos",
            items: [
                {
                    id: "ring-1",
                    name: "ring-red",
                    description: "Anillo Rojo",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fring-red.png?alt=media&token=10ddd622-0e0f-4396-ac66-eb53dd2fd927",
                    width: 512,
                    height: 210,
                },
                {
                    id: "ring-2",
                    name: "ring-green",
                    description: "Anillo Verde",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fring-green.png?alt=media&token=be874a25-4b59-4dc4-8d91-f3275a73831f",
                    width: 512,
                    height: 210,
                },
                {
                    id: "ring-3",
                    name: "ring-blue",
                    description: "Anillo Azul",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fring-blue.png?alt=media&token=60e80b2f-efa5-4524-a3fc-1da62549566d",
                    width: 512,
                    height: 210,
                },
                {
                    id: "ring-4",
                    name: "ring-black",
                    description: "Anillo Black",
                    url: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fring-black.png?alt=media&token=c9975c5f-7575-47b0-a01a-fe85819da524",
                    width: 512,
                    height: 210,
                },

            ]
        }
    ],
    layouts: []
}