import { useFirestoreDocument } from '@react-query-firebase/firestore';
import { doc, getFirestore } from 'firebase/firestore';
import _ from "lodash";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ScrollView, StyleSheet, useWindowDimensions, View } from 'react-native';
import { generateDynamicYupSchema } from "../../utils/generateDynamicYupSchema";
import useYupValidationResolver from '../../utils/useYupValidationResolver';
import MultiField from '../formComponents/MultiField';
import Button from './Button';
import StyledText from './StyledText';

function parseVal(type, value) {
    if (type === "date") {
        return moment(value).format("YYYY-MM-DD")
    }
    if (type === "datetime") {
        return moment(value).format("YYYY-MM-DD HH:mm")
    }
    return value;
}

function normalizeObj(obj, dataTypes) {
    return _.reduce(obj, function (fields, value, key) {
        const type = dataTypes?.find(f => f.name === key)?.type;
        return {
            ...fields,
            [key]: parseVal(type, value)
        }
    }, {})
}

function camelToTitle(str) {
    return str.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); })
}

function Subform({
    formId,
    formRef,
    update,
    defaultValues,
    onClose,
    fieldRoute,
    index,
}) {

    const db = getFirestore();
    const [schema, setSchema] = useState({})
    const ref = (formRef && typeof formRef === "string") ?
        doc(db, formRef)
        :
        formRef;
    const screen = useWindowDimensions()
    const {
        data,
        isLoading,
        isSuccess,
    } = useFirestoreDocument(["customForm", formId], ref, { subscribe: false },
        {
            select: doc => {
                const d = { ...doc.data(), id: doc.id }
                const fields = d.fields.map(obj => ({ ...obj, required: true, name: _.camelCase(obj.name) }))
                const fieldMap = {};
                fields?.forEach((field, idx) => fieldMap[field.name] = d.fields[idx].name)
                return ({ ...d, fields, fieldMap })
            }
        })

    useEffect(() => {
        if (isSuccess && data.fields) {
            const schema = generateDynamicYupSchema(data.fields)
            setSchema(schema)
        }
    }, [isSuccess])

    async function onSubmit(values) {
        const form = {
            fields: normalizeObj(values, data.fields),
            type: "form",
            name: data.name,
            ref: formRef?.path ? formRef.path : formRef,
        }

        update(index, form)
        onClose();
    }

    const resolver = useYupValidationResolver(schema)

    const {
        control,
        handleSubmit,
        trigger,
        reset,
        formState: { errors },
    } = useForm({
        mode: "onChange",
        resolver,
        defaultValues: defaultValues,
    })

    useEffect(() => {
        reset(defaultValues)
    }, [defaultValues])

    return (
        <>
            <ScrollView style={{ maxHeight: screen.height - 120 }} contentContainerStyle={{ paddingHorizontal: 20 }}>
                {
                    (isLoading) ?
                        null
                        :
                        <View>
                            <StyledText style={{ paddingVertical: 20, fontSize: 34, fontFamily: "Title", }}>{data?.name}</StyledText>
                            {data?.fields?.map((field, key) => {
                                return (
                                    <View
                                        key={key}
                                        style={{ marginBottom: 10 }}>
                                        <MultiField
                                            key={field.name}
                                            error={errors[field.name]}
                                            customRef={field.ref}
                                            placeholder={`${_.capitalize(field.name)}...`}
                                            control={control}
                                            name={field.name}
                                            label={camelToTitle(field.name)}
                                            type={field.type}
                                            route={fieldRoute}
                                            required={field.required}
                                        />
                                    </View>
                                )
                            })
                            }
                        </View>
                }
            </ScrollView>
            <View style={styles.btnContainer}>
                <Button
                    label='Cerrar'
                    color="gainsboro"
                    round={"5px"}
                    height={40}
                    onPress={onClose}
                />
                <Button
                    label="Guardar"
                    round={"5px"}
                    height={40}
                    onPress={handleSubmit(onSubmit, err => console.log(err))}
                />
            </View>
        </>
    )
}

export default Subform

const styles = StyleSheet.create({
    container: {
        flexGrow: 1
    },
    btnContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 15
    }
})