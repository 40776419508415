import { Ionicons } from '@expo/vector-icons';
import { useNavigation, useRoute } from '@react-navigation/native';
import chroma from "chroma-js";
import { addDoc, collection, doc, getDoc, getFirestore, updateDoc } from 'firebase/firestore';
import { isNumber } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { Pressable, ScrollView, View } from 'react-native';
import { v4 as uuidv4 } from "uuid";
import Button from '../../components/common/Button';
import StyledText from '../../components/common/StyledText';
import TextField from '../../components/formComponents/TextField';
import AuthContext from '../../context/AuthContext';

const defaultValues = {
    name: "",
    events: [
        {
            eventId: uuidv4(),
            name: "Evento 1",
            abr: "E1",
            color: chroma.random().hex(),
            timeAfter: 5,
            timeBefore: 5
        }
    ]
}

const EventSet = () => {

    const navigation = useNavigation();
    const route = useRoute()
    const { id } = route.params;

    const { userData } = useContext(AuthContext)

    const db = getFirestore()


    useEffect(() => {
        reset(defaultValues)
        if (userData.id && id && id !== "new") fetchExercise()
    }, [id, userData])


    const fetchExercise = async () => {
        const docRef = doc(db, `users/${userData.id}/eventSets/${id}`)
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            reset(docSnap.data())
        } else {
            alert("No existe el ejercicio")
        }
    }


    const {
        handleSubmit,
        control,
        watch,
        reset,
        formState: { errors },
    } = useForm({
        mode: "onChange",
        defaultValues: defaultValues,
    });

    const onSubmit = (data) => {
        if (id === "new") {
            addDoc(collection(db, `users/${userData.id}/eventSets`), {
                ...data,
                createdAt: new Date(),
                updatedAt: new Date()
            }).then(res => {
                alert("Botonera creada correctamente")
                navigation.navigate("VideoExercises")
            }).catch(err => {
                alert("Error al crear la botonera")
            })
        } else {
            updateDoc(doc(db, `users/${userData.id}/eventSets/${id}`), {
                ...data,
                updatedAt: new Date()
            }).then(res => {
                alert("Botonera actualizada correctamente")
                navigation.navigate("VideoExercises")
            }).catch(err => {
                alert("Error al actualizar la botonera")
            })
        }
    };


    return (
        <ScrollView contentContainerStyle={{ padding: 15, gap: 10, maxWidth: 1000, width: "100%", alignSelf: "center" }}>
            <View style={{ gap: 10 }}>
                <View style={{ flexDirection: "row", alignItems: "center" }}>

                    <Pressable onPress={() => navigation.navigate("VideoExercises")}>
                        <Ionicons name="chevron-back" size={24} color="black" />
                    </Pressable>

                    <View style={{ flex: 1 }}>
                        <StyledText style={{ fontFamily: "TitleWide", fontSize: 24, fontWeight: "bold" }}>Editar Botonera</StyledText>
                    </View>

                    <View style={{ flexDirection: "row", gap: 10 }}>
                        <Button height={35} round={7} onPress={handleSubmit(onSubmit)} label={"Guardar"} />
                    </View>
                </View>

                <View style={{ flex: 1, padding: 10, borderWidth: 1, borderColor: "gainsboro", backgroundColor: "white", borderRadius: 7, gap: 15 }}>
                    <TextField label="Nombre" placeholder="Nombre de la Botonera..." control={control} errors={errors} name="name" />
                </View>

                <EventRepeater control={control} errors={errors} watch={watch} />

            </View>
        </ScrollView>
    );
}


const EventRepeater = ({ control, errors, watch }) => {

    const { fields, append, remove } = useFieldArray({
        control,
        name: "events",
    });

    const [eventSelected, setEventSelected] = useState(0)




    return (
        <View style={{ flexDirection: "row", alignItems: "flex-start", gap: 15 }}>
            <View style={{ flex: 1, padding: 10, borderWidth: 1, borderColor: "gainsboro", backgroundColor: "white", borderRadius: 7, gap: 15 }}>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <View style={{ flex: 1 }}>
                        <StyledText style={{ fontFamily: "TitleWide" }}>Botones</StyledText>
                    </View>
                    <Button height={35} width={35} paddingHorizontal={0} color="#0b1831" round={7} onPress={() => {
                        append({
                            name: `Evento ${fields.length + 1}`,
                            abr: `E${fields?.length + 1}`,
                            color: chroma.random().hex(),
                            timeAfter: 5,
                            timeBefore: 5,
                            eventId: uuidv4()
                        })
                        setEventSelected(fields.length)
                    }} icon={"add"}
                    />
                </View>
                <View style={{ gap: 5 }}>
                    {fields.map((item, index) => {
                        return (
                            <ListEventButton
                                key={item.id}
                                item={item}
                                color={watch(`events[${index}].color`)}
                                name={watch(`events[${index}].name`)}
                                eventSelected={eventSelected === index}
                                clickCallback={() => setEventSelected(index)}
                                deleteCallback={() => {
                                    setEventSelected(fields.length === 0 ? null : 0)
                                    remove(index)
                                }} />
                        )
                    })}
                </View>
            </View>

            <View style={{ flex: 3, padding: 20, borderWidth: 1, borderColor: "gainsboro", backgroundColor: "white", borderRadius: 7 }}>
                {isNumber(eventSelected) ?
                    <View key={eventSelected} style={{ gap: 20 }}>
                        <View style={{ flexDirection: "row", gap: 10, alignItems: "flex-end" }}>
                            <View style={{ flex: 3 }}>
                                <TextField label="Nombre" control={control} errors={errors} name={`events[${eventSelected}].name`} />

                            </View>
                            <View style={{ flex: 1 }}>
                                <TextField label="Abreviatura" control={control} errors={errors} name={`events[${eventSelected}].abr`} />
                            </View>
                        </View>
                        <View style={{ flexDirection: "row", gap: 10 }}>
                            <TextField label="Color" type="color" control={control} errors={errors} name={`events[${eventSelected}].color`} />
                            <TextField label="Tiempo Anterio (s)" type="number" control={control} errors={errors} name={`events[${eventSelected}].timeAfter`} />
                            <TextField label="Tiempo Posterior (s)" type="number" control={control} errors={errors} name={`events[${eventSelected}].timeBefore`} />
                        </View>

                    </View>
                    :
                    <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
                        <StyledText style={{ opacity: .2 }}>Seleccione un evento</StyledText>
                    </View>
                }
            </View>

        </View>
    )

}

const ListEventButton = ({ clickCallback, deleteCallback, item, eventSelected, color, name }) => {
    return (
        <Pressable onPress={clickCallback} style={{ backgroundColor: "#f8f8f8", padding: 5, borderRadius: 5, flexDirection: "row", gap: 10, alignItems: "center", borderWidth: 2, borderColor: eventSelected ? "orange" : "transparent" }}>
            <View style={{ backgroundColor: color, width: 30, height: 30, borderRadius: 5 }} />
            <View style={{ flex: 1 }}>
                <StyledText numberOfLines={1} style={{ fontFamily: "DemiBold" }}>{name || ""}</StyledText>
            </View>
            <Button
                icon="trash"
                iconSize={16}
                height={35}
                width={"35px"}
                paddingHorizontal={0}
                color="#f8f8f8"
                round={7}
                onPress={deleteCallback} />
        </Pressable>
    )
}

export default EventSet;

const colors = [
    "black",
    "white",
    "red",
    "green",
    "blue",
    "yellow",
    "orange",
    "pink",
    "purple",
    "brown",
    "grey",
    "gainsboro",
    "#aefaef",
    "tomato",
    "dodgerblue",
    "mediumseagreen",
    "slateblue",
    "violet",
    "gold",
    "khaki",
    "rebeccapurple",
    "limegreen",
    "teal",
    "royalblue",
    "darkslategrey",
];