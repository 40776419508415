import { omit } from "lodash";
import moment from "moment";
import React from "react";
import { Control, Controller } from "react-hook-form";
import { Platform, View } from "react-native";
import RNPickerSelect from 'react-native-picker-select';
import StyledText from "../common/StyledText";

type Props = {
	label?: string,
	control: Control<any>,
	name: string,
	options?: Array<any>,
	moreInfo?: string,
	error?: boolean,
	rules?: any,
	containerProps?: any,
	type?: string,
	placeholder?: string,
	valueKey?: string,
	labelKey?: string,
	disabled?: boolean,
	style?: any,
	defaultValue?: any,
}

const SelectField = ({ label, options = [], control, moreInfo, name, error = false, rules = {}, defaultValue, containerProps = { style: { flex: 1 } }, type = "text", ...props }: Props) => {

	return (
		<View {...containerProps} >
			{label && (
				<StyledText style={{ color: props?.labelColor || "gray", fontSize: 16, marginBottom: 10 }}>
					{label}
				</StyledText>
			)}

			<View >
				<Controller
					{...omit(props, ["labelKey", "valueKey", "disabled"])}
					control={control}
					name={name}
					rules={rules}
					render={({ field }) => {
						const v = field.value
							? type === "date"
								? field?.value ? moment(field.value).format("YYYY-MM-DD") : ""
								: type === "datetime-local"
									? field?.value ? moment(field.value).format("YYYY-MM-DD[T]hh:mm") : ""
									: field.value || ""
							: "";
						return (

							Platform.OS === "web" ?
								<View style={{ borderRadius: 7, borderWidth: 1, borderColor: "gainsboro", backgroundColor: "white", paddingRight: 10 }}>
									<select
										disabled={props.disabled}
										// {...omit(props, ["labelKey", "valueKey"])}
										// type={type}
										defaultValue={defaultValue}
										style={{ height: 45, width: "auto", borderWidth: 0, borderRadius: 5, paddingRight: 10, paddingLeft: 10, ...(props.style || {}) }}
										onChange={(ev) => {
											const v = type === "number" ? (ev.target.valueAsNumber === 0 ? 0 : ev.target.valueAsNumber || null) : type === "date" ? ev.target.valueAsDate : ev.target.value;
											field.onChange(v);
										}}
										value={v || ""}
									>
										<option key={"null"} style={{ opacity: .4 }} value={""}> {props.placeholder || "Selecciona..."} </option>
										{options.map((option, i) => (
											<option key={i} value={option[props.valueKey] || option}>{option[props.labelKey] || option}</option>
										))}
									</select>
								</View>
								:
								<RNPickerSelect
									{...field}
									{...omit(props, ["labelKey", "valueKey"])}
									items={options}
									onValueChange={(ev) => {
										field.onChange(ev)
									}}
								/>
						);
					}}
				/>

				{moreInfo && (
					<StyledText style={{ marginTop: 10, fontSize: 12 }}>
						{moreInfo}
					</StyledText>
				)}
				{error && (
					<StyledText style={{ marginTop: 10, fontSize: 14, color: "red" }}>
						{error?.message}
					</StyledText>
				)}
			</View>
		</View>
	);
};

export default SelectField;
