import { Ionicons } from "@expo/vector-icons";
import { useFirestoreDocumentData, useFirestoreQuery } from "@react-query-firebase/firestore";
import { getApp } from "firebase/app";
import { collection, doc, DocumentReference, getDoc, getDocs, getFirestore, query, where } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import moment from "moment";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import Button from "../../components/common/Button";
import Modal from "../../components/common/Modal";
import StyledText from "../../components/common/StyledText";
import LinearBarIndicator from "../../components/LinearBarIndicator";
import AdvanceContext from "../../context/AdvanceContext";
import AuthContext from "../../context/AuthContext";


import { useNavigation, useRoute } from "@react-navigation/native";
import { clamp, get } from "lodash";
import { first } from "radash";
import { useMediaQuery } from "react-responsive";
import generatePdf from "../../utils/generatePdf";


const LicensesScreen = () => {

	const isPhone = useMediaQuery({ query: '(max-width: 1000px)' })
	const [tab, setTab] = useState(0)

	return (
		<ScrollView style={{ flex: 1 }} contentContainerStyle={{ paddingVertical: isPhone ? 20 : 30, paddingHorizontal: 15, maxWidth: 1200, width: "100%", alignSelf: "center" }}>
			<View style={{ flexDirection: "row", flexWrap: "wrap", justifyContent: "flex-end", gap: 15 }}>

				<TouchableOpacity onPress={() => setTab(0)} >
					<StyledText style={{ fontFamily: "title", fontSize: isPhone ? 35 : 45, textAlign: "center", color: tab === 0 ? "#E4022A" : "black" }}>Licencias y Diplomas</StyledText>
				</TouchableOpacity>

				<TouchableOpacity onPress={() => setTab(1)} >
					<StyledText style={{ fontFamily: "title", fontSize: isPhone ? 35 : 45, textAlign: "center", color: tab === 1 ? "#E4022A" : "black" }}>Certificados de Reciclaje</StyledText>
				</TouchableOpacity>

			</View>
			{tab === 0 ?
				<UserLicenses />
				:
				<UserReevaluation />
			}
		</ScrollView >
	);
};

LicensesScreen.navigationOptions = {
	header: null,
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: "#fff",
		marginBottom: 60,
	},
	shadow: {
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 5,
		},
		shadowOpacity: 0.2,
		shadowRadius: 10.5,
		elevation: 10,
	},
	imageRedond: {
		height: 40,
		width: 40,
		borderRadius: 20,
		resizeMode: "cover",
		borderWidth: 1,
		borderColor: "gainsboro",
		marginRight: 10,
	},
	topicBox: {
		width: 300,
		padding: 15,
		marginBottom: 10,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		borderWidth: 1,
		borderRadius: 4,
		borderColor: "gainsboro",
	}
});

export default LicensesScreen;

type ReevaluationCertificate = {
	userRef: DocumentReference,
	courses: {
		ref: DocumentReference,
		courseType: string,
		courseCategory: string,

	}[],
}

const UserReevaluation = () => {
	const db = getFirestore()
	const { customization } = useContext(AuthContext)
	const { userData } = useContext(AuthContext)
	const { advances, courses, payments } = useContext(AdvanceContext)
	const navigation = useNavigation()

	const [type, setType] = useState(0)

	const docRef = userData.ref ? query(collection(db, `reevaluationCertificates`), where("userRef", "==", userData.ref)) : null
	const { data, isLoading } = useFirestoreQuery(["reevaluationCertificates", { id: userData.id }], docRef, {
		subscribe: true,
	}, {
		refetchOnMount: "always",
		enabled: !!userData?.ref,
		select: d => d.docs.map(x => ({ ...x.data(), id: x.id, ref: x.ref })).sort((a, b) => {
			const dateA = a?.createdAt?.toDate()
			const dateB = b?.createdAt?.toDate()
			return dateB.getTime() - dateA.getTime()
		})
	});

	const advancesWithCourses = useMemo(() => courses.map(x => ({
		...x.advance,
		courseType: x.courseType,
		courseCategory: x.courseCategory,
		reevaluateSoccer: x.reevaluateSoccer || false,
		reevaluateBeach: x.reevaluateBeach || false,
		reevaluateGoalkeeper: x.reevaluateGoalkeeper || false,
		reevaluateFutsal: x.reevaluateFutsal || false,
		time: parseInt(get(x, "extraData.Horas_revaluacion", "0"))
	})), [courses])

	const reevaluations = useMemo(() => (advancesWithCourses || [])
		?.filter(x => x.courseType === "Revaluación licencias y formación continua")
		//filter the last three years since the finalGradeDate
		?.filter(x => moment(fixDate(x.finalGradeDate)).isSameOrAfter(moment().subtract(3, "years")))
		?.filter(x => x.finalGradeStatus === "APPROVED")
		, [advancesWithCourses])

	const lastCertificate = useMemo(() => first(data), [data])

	const totalTimeSoccer = useMemo(() => reevaluations?.filter(x => x.reevaluateSoccer).reduce((acc, x) => acc + x.time, 0), [reevaluations])
	const totalTimeBeach = useMemo(() => reevaluations?.filter(x => x.reevaluateBeach).reduce((acc, x) => acc + x.time, 0), [reevaluations])
	const totalTimeGoalkeeper = useMemo(() => reevaluations?.filter(x => x.reevaluateGoalkeeper).reduce((acc, x) => acc + x.time, 0), [reevaluations])
	const totalTimeFutsal = useMemo(() => reevaluations?.filter(x => x.reevaluateFutsal).reduce((acc, x) => acc + x.time, 0), [reevaluations])

	const percentageSoccer = useMemo(() => clamp(totalTimeSoccer > 0 ? Math.round(totalTimeSoccer / 15 * 100) : 0, 0, 100), [totalTimeSoccer])
	const percentageBeach = useMemo(() => clamp(totalTimeBeach > 0 ? Math.round(totalTimeBeach / 5 * 100) : 0, 0, 100), [totalTimeBeach])
	const percentageGoalkeeper = useMemo(() => clamp(totalTimeGoalkeeper > 0 ? Math.round(totalTimeGoalkeeper / 5 * 100) : 0, 0, 100), [totalTimeGoalkeeper])
	const percentageFutsal = useMemo(() => clamp(totalTimeFutsal > 0 ? Math.round(totalTimeFutsal / 5 * 100) : 0, 0, 100), [totalTimeFutsal])

	//The conditions to enable the button are
	// 1. The user must have at lest 10 hours of totalTimeSoccer plus 5 hours of any other type of time
	// 2. Or the user must have at least 15 hours of any time
	const isButtonEnabled = useMemo(() => (totalTimeSoccer > 10 && (totalTimeBeach > 5 || totalTimeGoalkeeper > 5 || totalTimeFutsal > 5))
		||
		(totalTimeSoccer > 15 || totalTimeBeach > 15 || totalTimeGoalkeeper > 15 || totalTimeFutsal > 15)
		, [totalTimeSoccer, totalTimeBeach, totalTimeGoalkeeper, totalTimeFutsal])


	return (
		<View style={{ marginTop: 20, gap: 15, paddingBottom: 100 }}>


			<View style={{ width: "100%", backgroundColor: "white", borderRadius: 7, padding: 20, gap: 10 }}>
				<StyledText style={{ marginBottom: 15, fontSize: 22, fontFamily: "TitleWide", }}>Proceso de renovación de licencia y obtención de certificado</StyledText>
				<StyledText style={{ fontSize: 18 }}>En cada jornada de reciclaje debemos pasar el código QR o PIN a la entrada y salida para que contabilice las horas que hemos asistido. Una vez finalizada la jornada, la federación territorial que organiza el evento, debe poner el APTO en nuestra calificación final para que esas horas se sumen a nuestro gestor de horas.</StyledText>
				<StyledText style={{ fontSize: 18 }}>Una vez realizadas las 15h de reciclaje, pueden darse dos casos:</StyledText>
				<StyledText style={{ fontSize: 18, paddingLeft: 20 }}>1. <StyledText style={{ fontFamily: "DemiBold" }}>Eres poseedor de licencia UEFA:</StyledText> Las licencias UEFA caducan el 31.12. del tercer año desde su obtención, por lo tanto, aquellas licencias emitidas, por ejemplo, en marzo del año 2021, caducarán el 31.12.2024. En este intervalo de validez de la licencia, debes realizar las 15h de actualización, que se irán acumulando en tu área personal dentro del apartado de licencias y diplomas. Una vez realizadas las 15h, 60 días antes de caducar tu licencia, por ejemplo, el 30.10.2024 aparecerá un botón para RENOVAR la licencia, se abonarán 25€ de gestión y automáticamente la licencia aparecerá renovada en nuestra área personal.</StyledText>
				<StyledText style={{ fontSize: 18, paddingLeft: 20 }}>2. <StyledText style={{ fontFamily: "DemiBold" }}>NO tienes licencia UEFA:</StyledText> Deberás realizar las 15h de actualización antes del 01.07.2025 para poder tramitar la licencia. El 01.05.2025, si tienes las 15h realizadas, te aparecerá un botón para obtener el CERTIFICADO DE ACTUALIZACIÓN, se abonarán 25€ de gestión y automáticamente dispondremos del certificado de haber realizado las 15h de actualización con una validez de hasta 31.12.2028, que tendrás que presentar para tramitar la licencia.</StyledText>

				<StyledText style={{ marginBottom: 10, fontSize: 22, fontFamily: "TitleWide", marginTop: 20 }}>Descarga de certificados</StyledText>
				<View style={{ flexDirection: "row", gap: 10 }}>
					<Button primary={type === 0} height={35} round={7} onPress={() => setType(0)} label="Cursos de Revaluación" />
					<Button primary={type === 3} height={35} round={7} onPress={() => setType(3)} icon={"time-outline"} reverse label="Historial de Certificados" />
				</View>
			</View>



			{type === 0 ?
				<View>
					<View style={{ flex: 1, justifyContent: "center" }}>
						<StyledText style={{ marginBottom: 15, fontSize: 22, fontFamily: "DemiBold", color: customization.mainColor }}>Cursos de Reciclaje Fútbol</StyledText>
					</View>
					{
						reevaluations?.length === 0 ?
							<View style={[{ backgroundColor: "white", borderRadius: 20, minHeight: 200, alignItems: "center", justifyContent: "center" }]}>
								<StyledText style={{ fontSize: 22, marginBottom: 10, fontFamily: "DemiBold", color: "darkgray" }}>Sin certificados de reciclaje</StyledText>
							</View>
							:
							<View style={{ gap: 10 }}>
								<View style={[{ gap: 10, padding: 15, borderRadius: 15, backgroundColor: "white" }, styles.shadow]}>
									<StyledText style={{ marginBottom: 10, fontSize: 22, fontFamily: "TitleWide" }}>Horas de Revaluacion</StyledText>
									<View style={{ flexDirection: "row", alignItems: "center", gap: 10 }}>
										<View >
											<StyledText style={{ fontSize: 38, fontFamily: "Title" }}>Fútbol</StyledText>
										</View>
										<View style={{ flexGrow: 1 }}>
											<LinearBarIndicator
												barStyle={{ borderRadius: 5 }}
												width={"100%"}
												height={15}
												fillColor={percentageSoccer < 33 ? "#e4002a" : percentageSoccer < 66 ? "#e49c00" : "#68b631"}
												percentage={percentageSoccer}
											/>
										</View>
										<View style={{ flexDirection: "row", gap: 10 }}>
											<StyledText style={{ fontSize: 30, fontFamily: "Title" }}>{totalTimeSoccer}/15 horas</StyledText>
										</View>
									</View>
									<View style={{ flexDirection: "row", alignItems: "center", gap: 10 }}>
										<View >
											<StyledText style={{ fontSize: 38, fontFamily: "Title" }}>Fútbol Sala</StyledText>
										</View>
										<View style={{ flexGrow: 1 }}>
											<LinearBarIndicator
												barStyle={{ borderRadius: 5 }}
												width={"100%"}
												height={15}
												fillColor={percentageFutsal < 33 ? "#e4002a" : percentageFutsal < 66 ? "#e49c00" : "#68b631"}
												percentage={percentageFutsal}
											/>
										</View>
										<View style={{ flexDirection: "row", gap: 10 }}>
											<StyledText style={{ fontSize: 30, fontFamily: "Title" }}>{totalTimeBeach}/5 horas</StyledText>
										</View>
									</View>
									<View style={{ flexDirection: "row", alignItems: "center", gap: 10 }}>
										<View >
											<StyledText style={{ fontSize: 38, fontFamily: "Title" }}>Fútbol Playa</StyledText>
										</View>
										<View style={{ flexGrow: 1 }}>
											<LinearBarIndicator
												barStyle={{ borderRadius: 5 }}
												width={"100%"}
												height={15}
												fillColor={percentageBeach < 33 ? "#e4002a" : percentageBeach < 66 ? "#e49c00" : "#68b631"}
												percentage={percentageBeach}
											/>
										</View>
										<View style={{ flexDirection: "row", gap: 10 }}>
											<StyledText style={{ fontSize: 30, fontFamily: "Title" }}>{totalTimeGoalkeeper}/5 horas</StyledText>
										</View>
									</View>
									<View style={{ flexDirection: "row", alignItems: "center", gap: 10 }}>
										<View >
											<StyledText style={{ fontSize: 38, fontFamily: "Title" }}>Porteros</StyledText>
										</View>
										<View style={{ flexGrow: 1 }}>
											<LinearBarIndicator
												barStyle={{ borderRadius: 5 }}
												width={"100%"}
												height={15}
												fillColor={percentageGoalkeeper < 33 ? "#e4002a" : percentageGoalkeeper < 66 ? "#e49c00" : "#68b631"}
												percentage={percentageGoalkeeper}
											/>
										</View>
										<View style={{ flexDirection: "row", gap: 10 }}>
											<StyledText style={{ fontSize: 30, fontFamily: "Title" }}>{totalTimeFutsal}/5 horas</StyledText>
										</View>
									</View>
									<Button disabled={!isButtonEnabled} primary={type === 0} height={35} round={7} onPress={() => console.log(0)} label="Generar Certificado" />
								</View>
								{reevaluations?.map((el, i) => {
									const course = courses?.find(c => c.id === el.courseRef.id)
									const tenantName = get(course, "tenantName", "")

									return (
										<CardReevaluation data={course} key={i} />
									)
								})}
							</View>
					}
				</View>
				:
				<View>
					<View style={{ flex: 1, justifyContent: "center" }}>
						<StyledText style={{ marginBottom: 15, fontSize: 22, fontFamily: "DemiBold", color: customization.mainColor }}>Certificados de Reciclaje</StyledText>
					</View>
					{!data || data?.length === 0 ?
						<View style={[{ backgroundColor: "white", borderRadius: 20, minHeight: 200, alignItems: "center", justifyContent: "center" }]}>
							<StyledText style={{ fontSize: 22, marginBottom: 10, fontFamily: "DemiBold", color: "darkgray" }}>Sin certificados de reciclaje</StyledText>
						</View>
						:
						data?.map((el, i) => {
							return (
								<CardReevaluation data={el} key={i} />
							)
						})}
				</View>

			}


		</View >
	)
}


const CardReevaluation = ({ data }) => {
	return (
		<View style={{ flex: 1, backgroundColor: "white", padding: 15, borderRadius: 5 }}>
			<StyledText style={{ fontSize: 16, fontFamily: "TitleWide" }}>{data?.tenantName}</StyledText>
			<StyledText style={{ fontSize: 22 }}>{data?.name}</StyledText>
			<StyledText style={{ fontSize: 16, marginBottom: 20 }}>{data?.tag}</StyledText>
			<StyledText style={{ fontSize: 16, }}>Inicio {fixDate(data?.initDate)?.toDateString()} - Final {fixDate(data?.endDate)?.toDateString()}</StyledText>
		</View>
	)
}

const UserLicenses = () => {
	const db = getFirestore()

	const { userData, customization } = useContext(AuthContext);
	const { advances, courses } = useContext(AdvanceContext)

	const navigation = useNavigation()

	const sortLicenses = (data, hierarchy) => {

		const d = data?.docs?.map(x => ({ ...x.data(), id: x.id, ref: x.ref }))

		if (!hierarchy) {
			const f = [
				{
					name: "Otras",
					licenses: d
				}
			]
			return f
		}
		const allLicenses = hierarchy.reduce((acc, cur) => [...acc, ...cur.licenses], [])
		const hierarchyLicenses = hierarchy.map(discipline => {
			return ({
				discipline: discipline.name,
				licenses: discipline.licenses.map(license => {
					return d.find(el => el?.licenseRef?.id === license)
				}).filter(x => x)[0]
			})
		}).filter(x => x.licenses)


		const f = [
			...hierarchyLicenses.map(el => ({
				name: el.discipline,
				licenses: [el.licenses]
			})),
			{
				name: "Otras",
				licenses: d.filter(x => !allLicenses.includes(x.licenseRef.id))
			}
		]

		return f
	}


	const licRef = doc(db, `config/licensesHierarchy`);
	const { data: dataH, isLoading: isLoadingH } = useFirestoreDocumentData(["licensesHierarchy"], licRef)



	const docRef = userData.ref ? query(collection(db, `licenses`), where("userRef", "==", userData.ref)) : null
	const { data, isLoading } = useFirestoreQuery(["licenses", { id: userData.id }], docRef, {
		subscribe: true,
	}, {
		refetchOnMount: "always",
		enabled: !!userData?.ref,
		select: d => sortLicenses(d, dataH?.hierarchy)
	});

	const flatLicenses = useMemo(() => data?.map(x => x.licenses).flat(), [data])



	const extraDiplomas = (advances || []).filter(x => x.finalGradeStatus === "APPROVED" && x.needValidation !== true).map(d => {

		const course = courses?.find(c => c.id === d.courseId)
		const license = flatLicenses?.find(l => l?.courseId === course?.id)
		return ({
			userRef: userData.ref,
			courseName: d.courseName,
			courseCompletedDate: d.finalGradeDate,
			courseInitDate: course?.initDate || null,
			diplomaUri: license?.diplomaUri || null,
			licenseNumber: null,
			licenseUriFront: null,
			idLicense: d.id || "",
			discipline: course?.courseType || "",
			diplomaRef: course?.diploma || null,
			advance: d,
			withDiploma: course?.withDiploma || false,
			course: course
		})
	})


	return (
		<View style={{ marginTop: 20, gap: 15 }}>
			<View style={{ width: "100%" }}>
				<View style={{ flexDirection: "row", justifyContent: "center", gap: 10, marginBottom: 5 }}>
					<View style={{ flex: 1, justifyContent: "center" }}>
						<StyledText style={{ marginBottom: 15, fontSize: 22, fontFamily: "DemiBold", color: customization.mainColor }}>Licencias</StyledText>
					</View>
					{userData?.superAdmin ? <Button height={35} round={7} onPress={() => navigation.navigate("Reevaluation")} label="Certificado de Reciclaje" /> : null}
				</View>
				{isLoading ?
					<View style={[{ backgroundColor: "white", borderRadius: 20, minHeight: 200, alignItems: "center", justifyContent: "center" }]}>
						<StyledText style={{ fontSize: 22, marginBottom: 10, fontFamily: "DemiBold", color: "darkgray" }}>Cargando Licencias</StyledText>
					</View>
					:
					data?.length === 0 || (data.length === 1 && data[0]?.licenses?.length === 0) ?
						<View style={[{ backgroundColor: "white", borderRadius: 20, minHeight: 200, alignItems: "center", justifyContent: "center" }]}>
							<StyledText style={{ fontSize: 22, marginBottom: 10, fontFamily: "DemiBold", color: "darkgray" }}>Sin Licencias</StyledText>
						</View>
						:
						data?.map((el, i) => {
							return (
								<View key={i} style={{ gap: 10 }}>
									{data?.length > 1 ?
										<View>
											<StyledText>{el.name}</StyledText>
										</View>
										: null
									}
									{el?.licenses?.map((l, i) => {
										return <License license={l} courses={courses} key={i} />
									})}

								</View>
							)
						})
				}
			</View>
			<View style={{ width: "100%" }}>
				<StyledText style={{ marginBottom: 15, fontSize: 22, fontFamily: "DemiBold", color: customization.mainColor }}>Diplomas</StyledText>
				{extraDiplomas?.filter(x => (x.withDiploma && x.diplomaRef) || x.diplomaUri)?.length === 0 ?
					<View style={[{ backgroundColor: "white", borderRadius: 20, minHeight: 200, alignItems: "center", justifyContent: "center" }]}>
						<StyledText style={{ fontSize: 22, marginBottom: 10, fontFamily: "DemiBold", color: "darkgray" }}>Sin Diplomas</StyledText>
					</View>
					:
					extraDiplomas?.filter(x => (x.withDiploma && x.diplomaRef) || x.diplomaUri)?.map((el, i) => {
						return (
							<Diploma license={el} key={i} />
						)
					})
				}
			</View>

			<View>
				<StyledText style={{ fontSize: 12 }}>*Estos documentos en ningún caso sustituye a los diplomas y/o las licencias / credenciales pendientes de recibir. A su vez podrá utilizarse ante el Comité de Entrenadores correspondiente para tramitar licencia de entrenador en la Real Federación Española de futbol mientras se está a la espera de recibir el diploma y la licencia / credencial oficiales.</StyledText>
			</View>
		</View >
	)
}

const fixDate = (date) => !date ? null : date?.toDate ? date.toDate() : moment(date).toDate()


const useCourseHours = (courses, license, db) => {

	const [hoursDone, setHoursDone] = useState(0)

	useEffect(() => {
		calculateHoursDone()
	}, [courses, license])

	const calculateHoursDone = async () => {

		let hours = 0
		for await (const course of courses) {
			if (course.videoCourse) {
				const advanceRef = collection(db, course.advance.ref.path, "videoAttendance")
				const hoursOfVideo = await getDocs(advanceRef).then(res => res.docs.map(x => x.data()))
				const filteredHours = hoursOfVideo.filter(x => x.checks?.filter(y => y.status).length >= 2)

				hours += clamp(filteredHours.reduce((acc, x) => acc + x.lastWatched / 60 / 60, 0), 0, get(course, "extraData.Horas_revaluacion", 15))

			} else {
				const hoursLicense = course?.renewLicenses?.find(l => l?.license?.id === license.licenseRef.id)?.hours
				hours += clamp((hoursLicense || 0), 0, get(course, "extraData.Horas_revaluacion", 15))
			}
		}

		// const hoursDone = courses?.reduce((acc, c) => {
		// 	// Usar las horas del curso que ha hecho el alumno redondeando hacia arriba
		// 	// Maximo las horas que ponga en los datos del curso
		// 	const hoursLicense = c?.renewLicenses?.find(l => l?.license?.id === license.licenseRef.id)?.hours
		// 	return acc + (hoursLicense || 0)
		// }, 0)

		setHoursDone(Math.round(hours * 100) / 100)
	}

	// const hoursDone = courses?.reduce((acc, c) => {
	// 	// Usar las horas del curso que ha hecho el alumno redondeando hacia arriba
	// 	// Maximo las horas que ponga en los datos del curso
	// 	const hoursLicense = c?.renewLicenses?.find(l => l?.license?.id === license.licenseRef.id)?.hours
	// 	return acc + (hoursLicense || 0)
	// }, 0)

	return { hoursDone }
}

const License = ({ license, courses }) => {
	const [generatingPdf, setGeneratingPdf] = useState(false)
	const storage = getStorage()
	const db = getFirestore()
	const navigation = useNavigation()


	const { userData } = useContext(AuthContext)
	const [loading, setLoading] = useState(false)
	const [renewModal, setRenewModal] = useState(false)
	const [successModal, setSuccessModal] = useState(false)
	const [errorModal, setErrorModal] = useState(false)



	//PARTE RELACIONADA CON LOS PAGOS DE LICENCIA
	const app = getApp()
	const functions = getFunctions(app, "europe-west1")
	process.env.NODE_ENV === "development" && connectFunctionsEmulator(functions, "localhost", 5001);
	const payLicense = httpsCallable(functions, "payLicense");


	const params = useRoute().params

	useEffect(() => {

		if (params?.paymentStatus === "OK") {
			setSuccessModal(true)
		} else if (params?.paymentStatus === "KO") {
			setErrorModal(true)
		}
	}, [params])

	async function makeRedsysPayment({ formData }) {

		const form = document.createElement("form")
		const { endpoint } = formData
		if (!endpoint) return
		form.setAttribute("name", "from")
		form.setAttribute("method", "post")
		form.setAttribute("target", "_self")
		form.setAttribute("action", endpoint)
		form.setAttribute("style", "display: none")
		Object.keys(formData)
			.forEach(key => {
				const input = document.createElement("input")
				input.setAttribute("type", "hidden")
				input.setAttribute("name", key)
				input.setAttribute("value", formData[key])
				form.appendChild(input)
			})

		const input = document.createElement("input")
		input.setAttribute("type", "submit")
		input.setAttribute("value", "Pay with credit card")
		form.appendChild(input)
		document.body.appendChild(form)
		form.submit()
		document.body.removeChild(form)
		setTimeout(() => {
			setLoading(false)
		}, 3000)
	}

	const pay = async () => {
		setLoading(true)

		const { data } = await payLicense({ licenseId: license.id, originUrl: window.location.href, name: userData.name, surname: userData.surname })

		if (data.tpvType === "redsys") {
			const { orderId, form } = data

			makeRedsysPayment({ formData: form })

		}

		if (data.tpvType === "addonpayments") {
			const { orderId, hppPayByLink } = data
			location.href = hppPayByLink;
			setLoading(false)
		}
	}

	const coursesWithRenewalTime = courses
		?.filter(c => c.renewLicenses?.some(l => l?.license?.id === license.licenseRef.id))
		.filter(c => fixDate(c?.initDate) >= fixDate(license.renewalDate || license.createdAt))
		?.filter(x => x?.advance?.finalGradeStatus === "APPROVED" || x.videoCourse)


	const { hoursDone } = useCourseHours(coursesWithRenewalTime, license, db)


	const canRenew = license.renewalTime ? hoursDone >= license.renewalTime : false
	//! GET THIS VARIABLE BASED ON THE LICENSE DATA
	const isTimeToRenew = fixDate(license.expirationDate) < moment(new Date()).add(6, "month").toDate()



	const totalDays = moment(fixDate(license?.expirationDate)).diff(moment(fixDate(license?.renewalDate || license?.createdAt)), "days")
	const daysLeft = moment(fixDate(license?.expirationDate)).diff(moment(), "days")

	const percentage = ((totalDays - (totalDays - daysLeft)) / totalDays) * 100



	return (
		<>
			<View style={[{ marginBottom: 10, backgroundColor: "white", flexDirection: "row", borderRadius: 7, padding: 20 }]}>
				<View style={{ flex: 1, gap: 10 }}>
					<View style={{ flexDirection: "row", alignItems: "center" }}>
						{percentage <= 0 ? <Ionicons name="warning-outline" size={30} color="#e4002a" style={{ marginRight: 10 }} /> : null}
						<StyledText style={{ fontSize: 34, fontFamily: "Title" }}>{license?.name}</StyledText>

					</View>
					{license?.number ? <StyledText style={{ fontFamily: "TitleWide", fontSize: 20, }}>Número de Licencia: {license?.prefix}{license?.number}</StyledText> : null}
					{license?.renewalTime ? <View>
						<LinearBarIndicator
							style={{ marginTop: 10 }}
							barStyle={{ borderRadius: 5 }}
							width={"100%"}
							height={15}
							fillColor={percentage < 33 ? "#e4002a" : percentage < 66 ? "#e49c00" : "#68b631"}
							percentage={percentage}
						/>
					</View> : null}
					<View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap", gap: 10 }}>
						<View style={{ flexDirection: "row", gap: 10 }}>
							<View style={{ padding: 10, backgroundColor: "#f2f2f2", borderRadius: 5, gap: 10, justifyContent: "center" }}>
								{(license.renewalDate || license?.createdAt) ? <StyledText style={{ fontFamily: "TitleWide", fontSize: 16, }}>Emitida: {moment(fixDate(license.renewalDate || license?.createdAt)).format("DD-MM-YYYY")}</StyledText> : null}
								{(license?.expirationDate && license?.renewalTime) ? <StyledText style={{ fontFamily: "TitleWide", fontSize: 16 }}>Caduca: {moment(fixDate(license?.expirationDate)).format("DD-MM-YYYY")}</StyledText> : null}
							</View>
							{license?.renewalTime ?
								<View style={{ alignItems: "center", gap: 5, backgroundColor: "#f2f2f2", padding: 10, borderRadius: 5 }}>
									<StyledText style={{ fontSize: 16, }}>Horas de Renovación</StyledText>
									<StyledText style={{ fontSize: 22, fontFamily: "TitleWide" }}>{hoursDone}h de {license?.renewalTime}h</StyledText>
								</View>
								: null}
						</View>
						<View style={{ alignItems: "center", flexDirection: "row", gap: 10 }}>
							{license.renewalTime ? canRenew ?
								isTimeToRenew ?
									<Button primary={false} round={7} onPress={() => setRenewModal(true)} label="Renovación" />
									: null
								:
								<Button primary={false} round={7} onPress={() => navigation.navigate("StackPublic", { screen: "CoachesSchool" })} label="Buscar cursos" />
								: null
							}

							{(license.licenseRef || license.licenseUri) && <Button loading={generatingPdf} label="Licencia" round={7} primary={true}

								onPress={async () => {
									setGeneratingPdf(true)

									try {
										if (license?.licenseUri) {
											getDownloadURL(ref(storage, license.licenseUri)).then(url => {
												window.open(url, "_blank")
											}).then(res => {
												setGeneratingPdf(false)
											}).catch(e => {
												alert("No se ha podido descargar el archivo")
												setGeneratingPdf(false)
											})
										} else {
											const courseAv = license.courseRef instanceof DocumentReference ?
												await getDoc(doc(db, license.courseRef.path, "courseavs", userData.id)).then(res => res.data()) :
												userData

											await generatePdf(license.licenseRef, { ...courseAv, ...license }, db).then(res => {
												setGeneratingPdf(false)
											})
											// await generateCertificate(license.licenseRef, userData, license.courseRef, license)
										}

									} catch (e) {
										alert("Ocurrió un error generando la licencia")
									}

								}} />}
						</View>

					</View>

				</View>
			</View>
			{renewModal ? <Modal
				onClickOutside={setRenewModal}
				modalContainerStyle={{ width: "95%", maxWidth: 500 }}
				padding={15}
			>
				<View style={{ alignItems: "center" }}>
					<Ionicons name={"card-outline"} color={"#0b1831"} size={120} />
					<StyledText style={{ fontSize: 34, fontFamily: "Title", textAlign: "center" }}>Se va a proceder al pago de la licencia</StyledText>


					<StyledText style={{ textAlign: "center", fontSize: 16, maxWidth: 500, marginVertical: 15, fontFamily: "DemiBold" }}>
						Para generar la Licencia Renovada o el Certificado de Actualización deberás abonar {license?.renewalPrice} euros de coste de expedición.
					</StyledText>

					<View style={{ flexDirection: "row", width: "100%", marginTop: 10, alignItems: "center", justifyContent: "space-between" }}>
						<Button loading={loading} disabled={loading} height={35} color="gainsboro" round={7} label="Cerrar" primary onPress={() => setRenewModal(false)} />
						<Button loading={loading} disabled={loading} height={35} round={7} label="Pagar" primary onPress={pay} />
					</View>
				</View>
			</Modal> : null}
			{successModal ? <Modal
				onClickOutside={setSuccessModal}
				modalContainerStyle={{ width: "95%", maxWidth: 500 }}
				padding={15}
			>
				<View style={{ alignItems: "center" }}>
					<Ionicons name={"checkmark-circle-outline"} color={"green"} size={120} />
					<StyledText style={{ fontSize: 34, fontFamily: "Title", textAlign: "center" }}>Se ha procesado el pago correctamente</StyledText>
					<StyledText style={{ textAlign: "center", fontSize: 16, maxWidth: 500, marginVertical: 15, fontFamily: "DemiBold" }}>
						En breves podrás descargar tu nueva licencia.
					</StyledText>

					<View style={{ flexDirection: "row", width: "100%", marginTop: 10, alignItems: "center", justifyContent: "center" }}>
						<Button loading={loading} disabled={loading} height={35} color="gainsboro" round={7} label="Cerrar" primary onPress={() => setSuccessModal(false)} />
					</View>
				</View>
			</Modal> : null}
			{errorModal ? <Modal
				onClickOutside={setErrorModal}
				modalContainerStyle={{ width: "95%", maxWidth: 500 }}
				padding={15}
			>
				<View style={{ alignItems: "center" }}>
					<Ionicons name={"alert-circle-outline"} color={"red"} size={120} />
					<StyledText style={{ fontSize: 34, fontFamily: "Title", textAlign: "center" }}>Se ha producido un error al procesar el pago</StyledText>
					<StyledText style={{ textAlign: "center", fontSize: 16, maxWidth: 500, marginVertical: 15, fontFamily: "DemiBold" }}>
						Si crees que es un error, contacta con nosotros.
					</StyledText>

					<View style={{ flexDirection: "row", width: "100%", marginTop: 10, alignItems: "center", justifyContent: "space-between" }}>
						<Button loading={loading} disabled={loading} height={35} color="gainsboro" round={7} label="Cerrar" primary onPress={() => setErrorModal(false)} />
						<Button loading={loading} disabled={loading} height={35} round={7} label="Volver a Intentar" primary onPress={pay} />
					</View>
				</View>
			</Modal> : null}
		</>
	)
}

const Diploma = ({ license }) => {
	const [generatingPdf, setGeneratingPdf] = useState(false)
	const db = getFirestore()


	const generateCertificate = async (docRef, advance, course) => {
		try {
			generatePdf(docRef, { ...{ ...(advance || {}), userName: advance.name }, ...course }, db).then(res => {
				setGeneratingPdf(false)
			})
		} catch (err) {
			console.log(err)
			setGeneratingPdf(false)
		}
	}

	return (
		<View style={[{ marginBottom: 10, backgroundColor: "white", flexDirection: "row", borderRadius: 7, padding: 20 }]}>
			<View style={{ flex: 1 }}>
				<StyledText style={{ fontSize: 34, fontFamily: "Title" }}>{license?.courseName}</StyledText>
				{license?.courseInitDate && <StyledText style={{ marginBottom: 5, fontSize: 16, }}>Inicio del Curso: {moment(license?.courseInitDate?.toDate ? license?.courseInitDate?.toDate() : license?.courseInitDate).format("DD-MM-YYYY")}</StyledText>}
				{license?.courseCompletedDate && <StyledText style={{ marginBottom: 5, fontSize: 16, }}>Curso Completado: {moment(license?.courseCompletedDate?.toDate ? license?.courseCompletedDate?.toDate() : license?.courseCompletedDate).format("DD-MM-YYYY")}</StyledText>}
				{license?.licenseNumber && <StyledText style={{ fontSize: 16, }}>Número de Licencia: #{license?.licenseNumber}</StyledText>}
			</View>
			<View style={{ alignItems: "center", justifyContent: "center" }}>
				{license.licenseUriFront && <FileButton uri={license.licenseUriFront || license.licenseUri} label="Licencia" style={{ marginBottom: 10 }} />}
				{license.diplomaUri && <FileButton uri={license.diplomaUri} label="Diploma" />}
				{!license.diplomaUri && license.diplomaRef && <Button loading={generatingPdf} label="Diploma" round={7} primary={true} onPress={async () => {
					setGeneratingPdf(true)
					await generateCertificate(license.diplomaRef, license.advance, license.course)

				}} />}
			</View>
		</View>
	)
}

const FileButton = ({ uri, label, style = {} }) => {
	const [signed, setSigned] = useState(null)
	const storage = getStorage()

	useEffect(() => {
		if (uri) {
			const fileRef = ref(storage, uri)
			getDownloadURL(fileRef).then(res => {
				setSigned(res)
			}).catch(err => {
				console.log(err)
			})
		}
	}, [uri])

	if (signed) {
		return <Button onPress={() => window.open(signed, "_blank")} round={7} primary={true} label={label} style={style} />
	} else {
		return null
	}
}
