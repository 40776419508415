import { createStackNavigator } from "@react-navigation/stack";
import { useFirestoreQuery } from "@react-query-firebase/firestore";
import { DocumentReference, collectionGroup, getFirestore, orderBy, query, runTransaction, where } from "firebase/firestore";
import { chunk } from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";
import HeaderWeb from "../components/HeaderWeb";
import AdvanceContext from "../context/AdvanceContext";
import AuthContext from "../context/AuthContext";
import CalendarScreen from "../screens/stackProfile/CalendarScreen";
import GhostLogin from "../screens/stackProfile/GhostLogin";
import IframeScreen from '../screens/stackProfile/IframeScreen';
import LicensesScreen from "../screens/stackProfile/LicensesScreen";
import MessagesScreen from "../screens/stackProfile/MessagesScreen";
import ProfileHome from "../screens/stackProfile/ProfileHome";
import Reevaluation from "../screens/stackProfile/Reevaluation";
import ToolScreen from '../screens/stackProfile/ToolScreen';
import Board from "../screens/stackSmartNew/Board";
import Course from "../screens/stackSmartNew/Course";
import CourseForum from "../screens/stackSmartNew/CourseForum";
import EventSet from "../screens/stackSmartNew/EventSet";
import Exercise from "../screens/stackSmartNew/Exercise";
import Exercises from "../screens/stackSmartNew/Exercises";
import Session from "../screens/stackSmartNew/Session";
import SingleTopic from "../screens/stackSmartNew/SingleTopic";
import SmartHome from "../screens/stackSmartNew/SmartHome";
import VideoExercise from "../screens/stackSmartNew/VideoExercise";
import VideoExercises from "../screens/stackSmartNew/VideoExercises";
import fixDate from "../utils/fixDate";
// import StackContent from "./StackContent";
// import StackProfile from "./StackProfile";
// import StackSmartNew from "./StackSmartNew";

const Stack = createStackNavigator()

export default function MainTabNavigator() {


	const db = getFirestore()
	const { userData } = useContext(AuthContext);
	const [courses, setCourses] = useState([]);
	const [loading, setLoading] = useState(true)


	const userCoursesRef = userData?.id ? query(collectionGroup(db, "courseavs"), where("userId", "==", userData.id)) : null
	const { data: advances, isLoading } = useFirestoreQuery(["courseavs", userData.id], userCoursesRef,
		{
			subscribe: true
		}, {
		refetchOnMount: "always",
		select: snapshot => {
			const d = snapshot?.docs?.map(doc => ({ ...doc.data(), id: doc.id, ref: doc.ref, courseRef: doc.ref.parent.parent })) || []
			return d
		},
		onError: (err) => console.log(err),
	})

	const userPaymentsRef = userData?.id ? query(collectionGroup(db, "payments"), where("userId", "==", userData.id), orderBy("userName", "asc")) : null
	const { data: payments } = useFirestoreQuery(["paymentsUser", userData.id], userPaymentsRef, { subscribe: true }, {
		refetchOnMount: "always",
		select: snapshot => {
			const d = snapshot?.docs?.map(doc => ({ ...doc.data(), id: doc.id, ref: doc.ref, courseRef: doc.ref.parent.parent })) || []
			return d
		},
		onError: (err) => console.log(err)
	})

	useEffect(() => {
		if (advances?.length > 0) {
			fetchCourses(advances)
		} else if (!isLoading) {
			setLoading(false)
		}
	}, [isLoading, advances?.length]);

	const fetchCourses = async (advances) => {

		let courses = []
		await runTransaction(db, async (t) => {
			const chunked = chunk(advances, 50)
			for await (const chunk of chunked) {
				await Promise.all(chunk.map(async doc => {
					// console.log("DOC", doc?.courseRef)
					if (doc?.courseRef instanceof DocumentReference) {
						try {
							const course = await t.get(doc?.courseRef).then(res => {
								if (res.exists()) {
									return { ...res.data(), id: res.id, ref: res.ref }
								} else {
									return false
								}
							})
							if (course) {
								const initDate = fixDate(course?.initDate)
								const endDate = fixDate(course?.endDate)
								courses.push({ ...course, initDate, endDate, advance: doc })
								return
							} else {
								return
							}
						} catch (err) {
							console.log(err)
							return
						}
					} else {
						return
					}

				}))
			}
		})

		setLoading(false)
		setCourses(courses)
	}

	const header = useCallback(() => <HeaderWeb />, [])

	return (
		<AdvanceContext.Provider value={{ advances, courses, payments, isLoading: loading }}>
			<Stack.Navigator screenOptions={{ title: "Cursos", cardStyle: { flex: 1 }, header }} >

				<Stack.Screen name="SmartHome" component={SmartHome} options={{ title: "Mis Programas" }} />
				<Stack.Screen name="Course" component={Course} />
				<Stack.Screen name="ProfileHome" component={ProfileHome} options={{ title: "Mi Perfil" }} />
				<Stack.Screen name="LicensesScreen" component={LicensesScreen} options={{ title: "Mis Licencias" }} />
				<Stack.Screen name="Reevaluation" component={Reevaluation} options={{ title: "Reevaluación de Licencias" }} />
				<Stack.Screen name="MessagesScreen" component={MessagesScreen} options={{ title: "Notificaciones" }} />
				<Stack.Screen name="ToolScreen" component={ToolScreen} options={{ title: "Herramientas" }} />
				<Stack.Screen name="IframeScreen" component={IframeScreen} options={{ title: "Herramientas" }} />
				<Stack.Screen name="CalendarScreen" component={CalendarScreen} options={{ title: "Mi Calendario" }} />
				<Stack.Screen name="CourseForum" component={CourseForum} options={{ title: "Foro del Curso" }} />
				<Stack.Screen name="SingleTopic" component={SingleTopic} options={{ title: "Foro del Curso" }} />


				<Stack.Screen name="Board" component={Board} options={{ title: "Pizarra Camelot" }} />
				<Stack.Screen name="Exercise" component={Exercise} options={{ title: "Ejercicio Camelot" }} />
				<Stack.Screen name="Sessions" component={Session} options={{ title: "Sesión Camelot" }} />
				<Stack.Screen name="Exercises" component={Exercises} options={{ title: "Camelot Tareas" }} />

				<Stack.Screen name="VideoExercises" component={VideoExercises} options={{ title: "Camelot Video" }} />
				<Stack.Screen name="VideoExercise" component={VideoExercise} options={{ title: "Ejercicio Video Camelot" }} />
				<Stack.Screen name="EventSet" component={EventSet} options={{ title: "Botonera Camelot" }} />

				<Stack.Screen name="GhostLogin" component={GhostLogin} />

			</Stack.Navigator>
		</AdvanceContext.Provider>



	);
}