import { Ionicons } from "@expo/vector-icons";
import React, { useContext, useRef, useState } from "react";
import { ActivityIndicator, Platform, ScrollView, Text, TouchableOpacity, View, useWindowDimensions } from "react-native";

import * as Linking from "expo-linking";
import * as WebBrowser from "expo-web-browser";
import moment from "moment";
import ImageRel from "../../components/common/ImageRel";
import SafeBottomTextInput from "../../components/common/SafeBottomTextInput";

import AdvanceContext from "../../context/AdvanceContext";
import AuthContext from "../../context/AuthContext";

import { useNavigation, useRoute } from "@react-navigation/native";
import { useFirestoreDocument, useFirestoreQuery } from "@react-query-firebase/firestore";
import { addDoc, collection, deleteDoc, doc, getFirestore, increment, orderBy, query, serverTimestamp, updateDoc } from "firebase/firestore";
import Button from "../../components/common/Button";
import Modal from "../../components/common/Modal";
import { decideColor } from "../../utils/functions";

if (Platform.OS !== "web") {
	ParsedText = require("react-native-parsed-text").default;
}


const SingleTopic = () => {
	const navigation = useNavigation()
	const route = useRoute()
	const params = route.params
	const { userData, customization } = useContext(AuthContext);
	const { courses } = useContext(AdvanceContext)
	const db = getFirestore()
	const topicID = params?.id;
	const courseID = params?.courseID;

	const SW = useWindowDimensions().width

	const [posting, setPosting] = useState(false);

	const [body, setBody] = useState("");
	const [confirm, setConfirm] = useState(false);

	const course = courses.find(c => c.id === courseID)

	const tenant = course ? doc(db, "tenants/" + course.tenantId) : null

	const scrollRef = useRef();
	const topicRef = tenant ? doc(db, `${tenant.path}/courses/${courseID}/topics`, topicID) : null;
	const { data: topic, isLoading: isLoadingTopic } = useFirestoreDocument([tenant, courseID, topicID], topicRef, { subscribe: true }, {
		enabled: !!tenant && !!courseID && !!topicID,
		select: doc => ({ id: doc.id, ...doc.data(), ref: doc.ref }),
		onSuccess: d => {
			scrollRef.current &&
				setTimeout(() => {
					scrollRef.current.scrollToEnd({ animated: true });
				}, 50);
		},
		onError: e => console.log(e)
	})
	const colRef = tenant ? collection(db, `${tenant.path}/courses/${courseID}/topics/${topicID}/messages`) : null
	const messagesCol = tenant ? query(colRef, orderBy("createdAt", "desc")) : null
	const { data, isLoading } = useFirestoreQuery([tenant, courseID, topicID, "messages"], messagesCol, { subscribe: true }, {
		enabled: !!tenant && !!courseID && !!topicID,
		select: d => d.docs.map(doc => ({ id: doc.id, ...doc.data(), ref: doc.ref })),
		onSuccess: d => {
			scrollRef.current &&
				setTimeout(() => {
					scrollRef.current.scrollToEnd({ animated: true });
				}, 50);
		},
		onError: e => console.log(e)
	})


	const sendMessage = () => {
		const variables = {
			body,
			user: {
				id: userData.id,
				ref: userData.ref,
				name: userData.name,
				surname: userData.surname,
				image: userData.image,
			},
			createdAt: serverTimestamp(),
			updatedAt: serverTimestamp(),
			topic: topicRef
		};

		addDoc(colRef, variables)
			.then(() => {
				setBody("");
				updateDoc(topicRef, {
					responses: increment(1),
					lastActive: serverTimestamp(),
				});
			});
	};

	const toogleSubscribe = () => {
		upSubscribe({ variables: { topicID } });
	};

	const removeMessage = (msg) => {
		setConfirm(msg.id);

		// Alert.alert(
		//   'Estas a punto de eliminar este mensaje',
		//   'Estas seguro que deseas eliminarlo. Esta acción no puede ser deshecha',
		//   [
		//     { text: 'Cancelar' },
		//     {
		//       text: 'Eliminar',
		//       onPress: () => firestore().collection("tenants").doc(tenant.id).collection("forums").doc(forumID).collection("topics").doc(topicID).collection("messages").doc(msg.id).delete().then(res =>
		//         firestore().collection("tenants").doc(tenant.id).collection("forums").doc(forumID).collection("topics").doc(topicID).update({
		//           responses: firestore.FieldValue.increment(-1),
		//           lastActive: firestore.FieldValue.serverTimestamp()
		//         }))
		//     },
		//   ]
		// );
	};

	if (isLoading || isLoadingTopic)
		return (
			<View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
				<ActivityIndicator size="large" />
			</View>
		);
	return (
		<View style={{ flex: 1, backgroundColor: "white" }}>
			{confirm && (
				<Modal>
					<Text style={{ fontSize: 24, fontFamily: "DemiBold" }}>Estás a punto de eliminar este mensaje</Text>
					<Text style={{ fontSize: 16, fontFamily: "Regular", marginTop: 15 }}>Estás seguro que deseas eliminarlo? esta operación no puede ser deshecha</Text>
					<View style={{ flexDirection: "row", justifyContent: "space-between", marginTop: 15 }}>
						<Button onPress={() => setConfirm(false)} color="gray" label="Cancelar" />
						<Button
							onPress={() => {
								deleteDoc(doc(db, colRef.path, confirm)).then((res) => {

									updateDoc(topicRef, {
										responses: increment(-1),
										lastActive: serverTimestamp(),
									})
										.then((res) => {
											setConfirm(false);
										})
								})
							}}
							label="Confirmar"
						/>
					</View>
				</Modal>
			)}
			{/* {Platform.OS === "web" && <HeaderWeb />} */}
			{Platform.OS === "web" && SW > 1000 && <SafeBottomTextInput web={Platform.OS === "web" && SW > 1000} loading={posting} input={body} writeCallback={(ev) => setBody(ev)} sendCallback={() => sendMessage()} />}
			<ScrollView
				ref={scrollRef}
				showsVerticalScrollIndicator={false}
				contentContainerStyle={{
					flexGrow: 1,
					paddingHorizontal: 5,
					maxWidth: 800,
					width: "100%",
					alignSelf: "center",
				}}>
				<View style={{ height: 60, alignItems: "center", flexDirection: "row" }}>
					<TouchableOpacity onPress={() => navigation.navigate("Course", { id: courseID })} style={{ alignItems: "center", flexDirection: "row" }}>
						<Ionicons name="arrow-back" style={{ marginRight: 10 }} size={30} />
						<Text style={{ fontFamily: "DemiBold", fontSize: 18 }}>Volver</Text>
					</TouchableOpacity>
				</View>
				<View
					// colors={["#017676", "#679BA2"]}
					style={[styles.topic, { backgroundColor: customization.mainColor, borderRadius: 10, marginBottom: 5, marginTop: 0, paddingBottom: 20 }]}>
					<View style={{ paddingHorizontal: 15, borderRadius: 10 }}>
						<View style={styles.userBox}>
							<View style={{ flexDirection: "row", flex: 1 }}>
								<ImageRel placeholder={"https://picsum.photos/200/300/?blur=2"} source={topic?.user?.image} style={styles.userImg} />
								<View style={{ justifyContent: "center" }}>
									<View style={{ justifyContent: "center" }}>
										<Text numberOfLines={2} truncate={true} style={{ fontFamily: "DemiBold", fontSize: 16, color: decideColor(customization.mainColor) }}>
											{topic?.user?.name}
										</Text>
									</View>
									{/* <Text style={{ fontFamily: "Regular", fontSize: 12, color: "white" }}>
										{data && moment(data.topic.createdAt).format("YYYY-MM-DD HH:mm")}
									</Text> */}
								</View>
							</View>

							{/* <TouchableOpacity disabled={subscribing} onPress={() => toogleSubscribe()} style={{ marginLeft: 50, width: 50, height: 50, alignItems: "center", justifyContent: "center" }}>
								<Ionicons
								name={data.topic &&
									Array.isArray(topic.subscribed) &&
									data.topic.subscribed.map(s => s.id).includes(id) ?
									"ios-notifications" : "ios-notifications-outline"}
								size={30}
								color="white"
								/>
							</TouchableOpacity> */}
						</View>
						<View style={{ marginBottom: 10 }}>
							<Text style={{ fontSize: 24, color: decideColor(customization.mainColor), marginBottom: 20, marginTop: 10, fontFamily: "DemiBold" }}>{topic?.title}</Text>
							{Platform.OS !== "web" ? (
								<ParsedText
									style={{ fontFamily: "Regular", fontSize: 16, color: decideColor(customization.mainColor) }}
									parse={[
										{ type: "url", style: { textDecorationLine: "underline", fontFamily: "DemiBold" }, onPress: async (ev) => await WebBrowser.openBrowserAsync(ev.toLowerCase().includes("://") ? ev : `https://${ev}`) },
										{ type: "phone", style: { textDecorationLine: "underline", fontFamily: "DemiBold" }, onPress: (ev) => Linking.openURL(`tel:${ev}`) },
										{ type: "email", style: { textDecorationLine: "underline", fontFamily: "DemiBold" }, onPress: (ev) => Linking.openURL(`mailto:${ev}`) },
									]}
									childrenProps={{ allowFontScaling: false }}>
									{topic?.body}
								</ParsedText>
							) : (
								<Text style={{ color: decideColor(customization.mainColor), fontFamily: "Regular" }}>{topic?.body}</Text>
							)}
						</View>
					</View>
				</View>

				{data?.map((msg, i) => {

					return (
						<View key={i} style={{ backgroundColor: "#f5f5f5", width: "100%", marginVertical: 5, borderRadius: 10, padding: 10 }}>
							<View style={styles.respUserBox}>
								<View style={{ flexDirection: "row", alignItems: "center" }}>
									<ImageRel style={styles.respAvatar} source={msg?.user?.image} />
									<View>
										<Text style={{ fontFamily: "DemiBold", fontSize: 16 }}>{msg.user && msg.user.name} {msg.user && msg.user.surname}</Text>
										<Text style={{ fontFamily: "DemiBold", fontSize: 12, color: "gainsboro" }}>{moment(msg.createdAt ? msg.createdAt.toDate() : "").format("DD MMMM, HH:mm")}</Text>
									</View>
								</View>

								{msg.user && msg.user.id === userData.id && (
									<TouchableOpacity onPress={() => removeMessage(msg)} style={{ height: 50, width: 50, alignItems: "center", justifyContent: "center" }}>
										<Ionicons name="md-trash" size={25} color="gray" />
									</TouchableOpacity>
								)}
							</View>

							<View style={{ marginTop: 10, paddingLeft: 10, paddingVertical: 15 }}>
								{Platform.OS !== "web" ? (
									<ParsedText
										style={{ fontFamily: "Regular", fontSize: 16, color: "black" }}
										parse={[
											{ type: "url", style: { textDecorationLine: "underline", fontFamily: "DemiBold" }, onPress: async (ev) => await WebBrowser.openBrowserAsync(ev.toLowerCase().includes("://") ? ev : `https://${ev}`) },
											{ type: "phone", style: { textDecorationLine: "underline", fontFamily: "DemiBold" }, onPress: (ev) => Linking.openURL(`tel:${ev}`) },
											{ type: "email", style: { textDecorationLine: "underline", fontFamily: "DemiBold" }, onPress: (ev) => Linking.openURL(`mailto:${ev}`) },
										]}
										childrenProps={{ allowFontScaling: false }}>
										{msg.body}
									</ParsedText>
								) : (
									<Text>{msg.body}</Text>
								)}
							</View>
						</View>
					);
				})}
			</ScrollView>

			{Platform.OS !== "web" && SW < 1000 && <SafeBottomTextInput loading={posting} input={body} writeCallback={(ev) => setBody(ev)} sendCallback={() => sendMessage()} />}
		</View>
	);
};

const styles = {
	userBox: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		width: "100%",
		marginVertical: 10,
	},
	userImg: {
		width: 50,
		height: 50,
		borderRadius: 25,
		borderWidth: 1,
		borderColor: "gainsboro",
		marginRight: 15,
	},
	btn: {
		// position:"absolute",
		// bottom:0,
		// right:0,
		width: 50,
		alignItems: "center",
		justifyContent: "center",
	},
	respUserBox: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
	},
	respAvatar: {
		width: 50,
		height: 50,
		borderRadius: 25,
		marginRight: 10,
	},
	response: {
		padding: 10,
		paddingBottom: 0,
		borderRadius: 4,
		marginBottom: 15,
		width: "100%",
		elevation: 2,
		shadowColor: "black",
		shadowRadius: 5,
		shadowOpacity: 0.5,
	},
	topic: {
		width: "100%",
		shadowOffset: {
			width: 0,
			height: 5,
		},
		shadowColor: "black",
		shadowRadius: 10,
		elevation: 10,
		shadowOpacity: 0.2,
	},
};
export default SingleTopic;
