import { Ionicons } from "@expo/vector-icons";
import chroma from "chroma-js";
import React, { useContext, useRef } from "react";
import { ActivityIndicator, Text, TouchableWithoutFeedback, ViewProps } from "react-native";
import * as Animatable from "react-native-animatable";
import AuthContext from "../../context/AuthContext";

type ButtonProps = {
	onPress: () => void;
	icon?: any;
	round?: number;
	iconSize?: number;
	label?: string;
	textStyles?: any;
	disabled?: boolean;
	reverse?: boolean;
	primary?: boolean;
	height?: number;
	width?: number | string;
	fontSize?: number;
	color?: string;
	children?: any;
	textColor?: string;
	loading?: boolean;
	paddingHorizontal?: number;
	title?: string;
} & ViewProps

const Button = ({ onPress, icon, round = 25, iconSize = 25, label = "", textStyles = {}, disabled = false, reverse = false, primary = true, height = 50, width = "auto", fontSize = 16, color, children, textColor, loading = false, paddingHorizontal = 25, title, ...rest }: ButtonProps) => {
	const { customization } = useContext(AuthContext);
	const buttonRef = useRef(null);

	const pressIn = { from: { scale: 1 }, to: { scale: 0.95 } };
	const pressOut = { from: { scale: 0.95 }, to: { scale: 1 } };

	const decideColor = () => {
		if (chroma(color || customization?.mainColor || "black").luminance() > 0.5) {
			return "#000000";
		} else {
			return "#ffffff";
		}
	};


	return (
		<TouchableWithoutFeedback accessibilityLabel={title} disabled={disabled} onPressIn={() => buttonRef?.current?.animate(pressIn)} onPressOut={() => buttonRef?.current?.animate(pressOut)} onPress={onPress}>
			<Animatable.View
				{...rest}
				ref={buttonRef}
				onLayout={() => {
					if (title) {
						buttonRef?.current?.setNativeProps({
							title: title,
						});
					}
				}}
				style={{
					backgroundColor: primary ? color || customization?.mainColor || "black" : "transparent",
					borderColor: !primary ? color || customization?.mainColor || "black" : "transparent",
					borderWidth: 3,
					opacity: disabled ? 0.3 : 1.0,
					...(rest?.style || {}),
					height: height,
					width: width,
					paddingHorizontal: paddingHorizontal,
					alignItems: "center",
					justifyContent: "center",
					borderRadius: round,
					flexDirection: reverse ? "row" : "row-reverse",
					cursor: "pointer",
				}}
				duration={200}>
				{loading ? (
					<ActivityIndicator color={textColor ? textColor : primary ? decideColor() : color || customization?.mainColor || "white"} />
				) : children ? (
					children
				) : (
					<>
						{icon && <Ionicons selectable={false} style={{ marginLeft: 0, marginRight: 0 }} size={iconSize} name={icon} color={textColor ? textColor : primary ? decideColor() : color || customization?.mainColor || "white"} />}
						{label && <Text
							selectable={false}
							style={{
								fontFamily: "DemiBold",
								fontSize: fontSize,
								textAlign: "center",
								color: textColor ? textColor : primary ? decideColor() : color || customization?.mainColor || "white",
								...(textStyles || {})
							}}>
							{label}
						</Text>}
					</>
				)}
			</Animatable.View>
		</TouchableWithoutFeedback>
	);
};

export default Button;
