import { Ionicons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { useFirestoreDocumentData } from "@react-query-firebase/firestore";
import { doc, getFirestore } from "firebase/firestore";
import React from "react";
import { Dimensions, FlatList, ScrollView, StyleSheet, TouchableOpacity, View, useWindowDimensions } from "react-native";
import StyledText from "../../components/common/StyledText";

const screenWidth = Dimensions.get("window").width;

const ToolScreen = () => {

    const sc = useWindowDimensions()
    const sh = useWindowDimensions().height
    const isPhone = sc.width < 1000
    const db = getFirestore()

    const { navigate } = useNavigation();

    const docRef = doc(db, "tools/customTools")
    // const { data: tools } = useFirestoreQuery(["tools"], docRef, {},
    // 	{ select: q => (q?.docs || []).map(doc => ({ ...doc.data(), id: doc.id, ref: doc.ref })) }
    // )
    const { data, isLoading } = useFirestoreDocumentData(
        ["tools"],
        docRef,
        {},
        {}
    );

    return (
        <ScrollView contentContainerStyle={styles.outerWrapper}>
            <View style={styles.wrapper}>
                <StyledText style={{ fontFamily: "title", fontSize: isPhone ? 35 : 45, textAlign: "left" }}>HERRAMIENTAS</StyledText>
                {!isLoading &&
                    <FlatList
                        data={data.categories}
                        renderItem={category => {
                            return (
                                <View style={{ gap: 10, paddingVertical: 10, marginVertical: 10, backgroundColor: "white", borderRadius: 10, padding: 15 }}>
                                    <StyledText style={{ fontFamily: "TitleWide", fontSize: 20, color: "grey", fontWeight: 600 }}>{category.item.name}</StyledText>
                                    <View style={{ gap: 5 }}>
                                        {category.item.tools.map((t, i) => (
                                            <TouchableOpacity key={i} style={styles.row} onPress={() => {
                                                if (t?.type === "link") {
                                                    window.open(t?.url, "_blank")
                                                }
                                                else if (t?.type === "iframe") {
                                                    navigate("IframeScreen", { id: t?.id })
                                                }
                                            }}>
                                                <View>
                                                    <StyledText style={{ fontSize: 16 }}>{t?.name || ""}</StyledText>
                                                    {/* <StyledText style={{ fontSize: 12, color: "grey" }}>{t?.item?.description || ""}</StyledText> */}
                                                </View>
                                                <View>
                                                    <Ionicons
                                                        style={{ marginLeft: 0, marginRight: 0 }}
                                                        size={30}
                                                        name={(t?.type === "link") ? "link" : "reader-outline"}
                                                    />
                                                </View>
                                            </TouchableOpacity>
                                        ))}
                                    </View>
                                </View>
                            )
                        }}
                        keyExtractor={(_, index) => index.toString()}
                    />
                }
            </View>
        </ScrollView>
    );
};

ToolScreen.navigationOptions = {
    header: null,
};

const styles = StyleSheet.create({
    outerWrapper: {
        flexDirection: "row",
        justifyContent: "center"
    },
    wrapper: {
        paddingTop: "2em",
        maxWidth: 800,
        width: "90%"
    },
    row: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#f9f9f9",
        borderRadius: 5,
        // borderBottomWidth: 2,
        // borderBottomColor: "gainsboro",
        padding: 10
    }
});

export default ToolScreen;
