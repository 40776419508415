import React, { useContext, useEffect } from "react";
import { TouchableOpacity, View, useWindowDimensions } from "react-native";


import { ScrollView } from "react-native-gesture-handler";

import moment from "moment";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Button from "../../components/common/Button";
import Modal from "../../components/common/Modal";
import StyledText from "../../components/common/StyledText";
import CheckBoxField from "../../components/formComponents/CheckBoxField";
import AuthContext from "../../context/AuthContext";
import useYupValidationResolver from "../../utils/useYupValidationResolver";
import TextField from "../formComponents/TextField";


const CustomFormDeclaracionJurada = ({ submitCallback, closeCallback, initialData }) => {

    const { userData } = useContext(AuthContext)
    const screen = useWindowDimensions()
    const validationSchema = yup.object({
        terms: yup.boolean().oneOf([true], "Debe aceptar los términos y condiciones"),
        location: yup.string().required("Debe ingresar la ubicación"),
    });

    const resolver = useYupValidationResolver(validationSchema);

    const {
        handleSubmit,
        reset,
        setValue,
        getValues,
        watch,
        trigger,
        control,
        formState: { errors },
    } = useForm({
        mode: "onChange",
        resolver: resolver,
        defaultValues: {
            location: userData.town || userData.province || "",
            date: new Date(),
            terms: false,
            name: userData.name || "",
            surname: userData.surname || "",
            idNumber: userData.idNumber || "",
        },
    });

    useEffect(() => {
        if (initialData) {
            reset({
                location: userData.town || userData.province || "",
                terms: false,
                ...initialData,
                date: new Date(),
                name: userData.name || "",
                surname: userData.surname || "",
                idNumber: userData.idNumber || "",
            })
        }
    }, [initialData])

    return (
        <Modal padding={0} modalContainerStyle={{ maxWidth: 900, width: "95%", minWidth: 400, backgroundColor: "white", borderRadius: 20 }} >
            <ScrollView contentContainerStyle={{ padding: 20 }} style={{ maxHeight: screen.height - 120 }}>
                <StyledText style={{ fontSize: 34, fontFamily: "Title", fontWeight: "bold" }}>Declaración Jurada</StyledText>
                <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "#f2f2f2", borderRadius: 10, padding: 15, marginVertical: 20 }}>
                    <StyledText style={{ fontWeight: "bold" }}>El Sr/a {userData.name} {userData.surname} con {userData.idType} {userData.idNumber}.</StyledText>
                </View>
                <View style={{ paddingHorizontal: 15 }}>
                    <StyledText style={{ marginBottom: 15 }}>Primero. Reconozco que he sido informado de todas las bases y normas que regulan este curso a través de la presente convocatoria.</StyledText>
                    <StyledText style={{ marginBottom: 15 }}>Segundo. Reconozco expresamente haber recibido información que, en el momento en que se inicien las clases del mencionado curso y que, por cualquier motivo o causa, tuviese que interrumpir mi asistencia a las clases o no pudiese completar o finalizar el mismo, se produce automáticamente la pérdida del derecho a devolución del importe total o parcial de la cuota abonada por este, circunstancia como firmante libre y voluntariamente acepto expresamente</StyledText>
                    <StyledText style={{ marginBottom: 15 }}>Tercero. Declaro y acepto que en caso de incumplimiento de los pagos que me comprometo a realizar para cursar el presente, seré declarado de baja en el curso con la pérdida de todos los derechos académicos y administrativos correspondientes, quedando la deuda para otra poster iorinscripción.</StyledText>
                    <StyledText style={{ marginBottom: 15 }}>Cuarto. El abajo firmante DECLARA BAJO SU RESPONSABILIDAD la veracidad tanto de la información consignada, como de los documentos aportados. En este sentido, el firmante manifiesta que los documentos presentados son copia fiel del original, y se compromete a remitir, en caso de ser requerido para ello, originales o copia compulsada de los mismo. Quedo advertido de que la inexactitud o falsedad de cualquier dato, información o documentos que se incorporen a la presente declaración responsable, o la no presentación de la documentación original o compulsada requerida, determinará la imposibilidad de continuar con el ejercicio del derecho o actividad afectada desde el momento en que se tenga constancia de tales hechos, sin perjuicio de las responsabilidades penales, civiles, administrativas o federativas a que hubiera lugar. </StyledText>
                    <StyledText >Y para que así conste ante quien proceda, con los efectos indicados acepto las presentes condiciones y firmo la siguiente declaración:</StyledText>
                </View>

                <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "#f2f2f2", borderRadius: 10, padding: 15, marginVertical: 20 }}>
                    <StyledText style={{ marginRight: 15 }}>En</StyledText>
                    <TextField style={{ height: 40, marginHorizontal: 15 }} placeholder="Localización" name="location" control={control} error={errors["location"]} />
                    <StyledText style={{ marginLeft: 15 }}>a {moment().format("DD [de] MMMM [de] YYYY")}</StyledText>
                </View>
                {
                    errors["location"]
                        ? <StyledText style={{ color: "red", marginBottom: 15 }}>{errors["location"].message}</StyledText>
                        : null
                }
                <CheckBoxField error={errors["terms"]} name="terms" key="terms" label={
                    <View style={{ flexDirection: "row" }}>
                        <StyledText style={{ color: "gray", fontSize: 16 }}>
                            Aceptacion de {" "}
                        </StyledText>
                        <TouchableOpacity onPress={() => window.open("https://onformacion.rfef.es/page/proteccion-datos", "_blank")}>
                            <StyledText style={{ color: "gray", textDecoration: "underline", fontSize: 16 }}>
                                condiciones y proteccion de datos de caracter personal
                            </StyledText>
                        </TouchableOpacity>
                    </View>
                } control={control} />
            </ScrollView>
            <View style={{ padding: 20, flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                <Button height="35px" color="#f2f2f2" label="Volver" round="7px" onPress={closeCallback} />
                <Button height="35px" label="Guardar" round="7px" onPress={handleSubmit(submitCallback)} />
            </View>
        </Modal >
    )
}


export default CustomFormDeclaracionJurada