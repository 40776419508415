import moment from 'moment';
import React from 'react';
import { View } from 'react-native';
import StyledText from '../common/StyledText';


const DateFancyDisplayer = ({ date, containerStyles = {} }) => {
    return (
        <View style={{ position: "relative", width: 60, borderRadius: 5, alignItems: "center", justifyContent: "center", backgroundColor: "#f2f2f2", paddingVertical: 5, ...containerStyles }}>
            <StyledText style={{ fontWeight: "bold", fontFamily: "Title", fontSize: 40, }}>
                {date && moment(date).format("DD")}
            </StyledText>
            <StyledText style={{ fontFamily: "Title", fontSize: 14, marginTop: -5 }}>
                {date && moment(date).format("MMMM")}
            </StyledText>
        </View>
    );
}

export default DateFancyDisplayer;