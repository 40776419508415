import { Ionicons } from "@expo/vector-icons";
import { useFirestoreQuery } from "@react-query-firebase/firestore";
import { collection, collectionGroup, getFirestore, orderBy, query, serverTimestamp, updateDoc, where } from "firebase/firestore";
import { getDownloadURL, getMetadata, getStorage, ref } from "firebase/storage";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ActivityIndicator, Dimensions, FlatList, ScrollView, TextInput, TouchableOpacity, View, useWindowDimensions } from "react-native";
import { useMediaQuery } from "react-responsive";
import Button from "../../components/common/Button";
import Modal from "../../components/common/Modal";
import SecureImage from "../../components/common/SecureImage";
import StyledText from "../../components/common/StyledText";
import AuthContext from "../../context/AuthContext";
import fixDate from "../../utils/fixDate";

const screenWidth = Dimensions.get("window").width;

const MessagesScreen = ({ navigation }) => {

	const { userData, tenant, setChatVisible, customization, setNotificationsCount } = useContext(AuthContext);

	const sh = useWindowDimensions().height
	const isPhone = useMediaQuery({ query: '(max-width: 1000px)' })
	const db = getFirestore()
	const storage = getStorage();

	const [type, setType] = useState(0)
	const [open, setOpen] = useState(null)
	const [search, setSearch] = useState("")
	const [searchChats, setSearchChats] = useState("")
	const docRef = userData?.uid ?
		type === 0 ?
			query(collection(db, `users/${userData.uid}/notifications`), where("read", "==", false), orderBy("createdAt", "desc"))
			: query(collection(db, `users/${userData.uid}/notifications`), where("read", "==", true), orderBy("createdAt", "desc"))
		: null
	const { data: notifications, isLoading } = useFirestoreQuery(["notifications", type === 0 ? "notRead" : "read", { user: userData?.uid }], docRef,
		{ subscribe: true },
		{
			enabled: !!userData?.uid,
			select: q => q?.docs?.map((d) => ({ ...d.data(), ref: d.ref })),
			onError: e => console.log(e)
		});


	const conversationsRef = userData?.uid ? query(collectionGroup(db, "conversations"), where("user", "==", userData.uid), orderBy("lastMessageSent", "desc")) : null

	const { data: conversations, isError } = useFirestoreQuery(
		["openConversations"],
		conversationsRef,
		{ subscribe: true },
		{
			enabled: !!userData?.uid,
			select: (d) => d?.docs?.map((doc) => ({ ...doc.data(), id: doc.id, ref: doc.ref })),
			onError: e => console.log(e)
		}
	);

	const markAsRead = (ref) => {
		updateDoc(ref, {
			read: true,
			updatedAt: serverTimestamp(),
			readAt: serverTimestamp()
		})
		// setNotificationsCount(el => el - 1)
	}

	const openNotification = (item) => {
		if (!item.read) {
			markAsRead(item.ref)
		}
		setOpen(item)
	}

	const NotificationItem = useCallback(({ item }) => {
		return (
			<TouchableOpacity onPress={() => openNotification(item)} style={{ alignItems: "center", padding: 20, width: "100%", borderRadius: 7, backgroundColor: 'white', flexDirection: "row" }}>
				{!item.read && <View style={{ width: 10, height: 10, borderRadius: 8, marginRight: 10, backgroundColor: customization.mainColor }} />}
				<View style={{ flex: 1 }}>
					<StyledText style={{}}>Recibido hace {moment(item.createdAt.toDate()).fromNow()}</StyledText>
					<StyledText style={{ fontFamily: "DemiBold", fontSize: 18 }}>{item.subject}</StyledText>
				</View>
			</TouchableOpacity>
		)
	}, [notifications, search, type])

	const data = useMemo(() =>
		notifications?.filter(el => el.body?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.subject?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase())) || []
		, [type, notifications, search])

	const dataConversations = useMemo(() => conversations?.filter(el => el.teacherName?.toLocaleLowerCase()?.includes(searchChats?.toLocaleLowerCase()) || el?.courseName?.toLocaleLowerCase()?.includes(searchChats?.toLocaleLowerCase())), [conversations, searchChats])
	return (
		<>
			<FlatList
				renderItem={NotificationItem}
				data={isLoading ? [] : data}
				ListEmptyComponent={
					isLoading ?
						<View style={{ height: 300, borderRadius: 10, backgroundColor: "white", alignItems: "center", justifyContent: "center" }}>
							<ActivityIndicator size="large" color="red" />
						</View>
						:
						<View style={{ height: 300, borderRadius: 10, backgroundColor: "white", alignItems: "center", justifyContent: "center" }}>
							<StyledText style={{ fontSize: 22, marginBottom: 10, fontFamily: "DemiBold", color: "darkgray" }}>No tienes Notificaciones</StyledText>
						</View>
				}
				keyExtractor={(_, index) => index.toString()}
				ListHeaderComponent={
					<View>
						{conversations?.length > 0 ? <View style={{ marginBottom: 15 }}>
							<View style={{ flexDirection: "row", justifyContent: "flex-end" }}>
								<TextInput value={searchChats} placeholder={"Buscar..."} style={{ fontFamily: "DemiBold", fontSize: 16, marginTop: 5, width: "100%", borderColor: "gainsboro", borderRadius: 5, backgroundColor: "white", paddingHorizontal: 10, height: 45, borderWidth: 1 }} onChangeText={setSearchChats} />
								<View style={{ paddingLeft: 20 }}>
									<StyledText style={{ fontFamily: "title", fontSize: isPhone ? 35 : 45, textAlign: "right" }}>Conversaciones ({(conversations?.filter(el => el.teacherName?.toLocaleLowerCase()?.includes(searchChats?.toLocaleLowerCase()) || el?.courseName?.toLocaleLowerCase()?.includes(searchChats?.toLocaleLowerCase())) || [])?.length || 0})</StyledText>
								</View>
							</View>

							<ScrollView
								horizontal={true}
								// showsHorizontalScrollIndicator={false}
								contentContainerStyle={{ paddingVertical: 10, flexGrow: 1 }}
							>
								{dataConversations?.length === 0 ?
									<View style={{ height: 150, width: "100%", borderRadius: 10, backgroundColor: "white", alignItems: "center", justifyContent: "center" }}>
										<StyledText style={{ fontSize: 22, marginBottom: 10, fontFamily: "DemiBold", color: "darkgray" }}>Sin conversaciones</StyledText>
									</View>
									:
									dataConversations?.map((item, index) => (
										<TouchableOpacity key={index}
											onPress={() => setChatVisible({
												...item,
												name: item?.teacherName,
												courseRef: item?.course
											})}
											style={{ backgroundColor: "white", borderRadius: 7, marginRight: 10, flexDirection: "row", alignItems: "center", padding: 10, paddingHorizontal: 15, maxWidth: 300 }}
										>
											{item?.unreadMessagesUser ? <View style={{ zIndex: 2, backgroundColor: "red", position: "absolute", top: 0, left: 0, borderBottomRightRadius: 4, paddingHorizontal: 5, paddingVertical: 3 }}>
												<StyledText style={{ color: "white", fontSize: 12, fontWeight: "DemiBold" }}>{item?.unreadMessagesUser}</StyledText>
											</View> : null}
											<SecureImage uri={item?.teacherImage} style={{ width: 60, height: 60, borderRadius: 30 }} placeholder={undefined} />
											<View style={{ flex: 1, marginLeft: 10 }}>
												<StyledText style={{ fontFamily: "DemiBold", fontSize: 18 }}>{item?.teacherName}</StyledText>
												<StyledText style={{ marginTop: 5, opacity: .7 }}>Recibido hace {moment(fixDate(item?.createdAt)).fromNow()}</StyledText>
											</View>
										</TouchableOpacity>
									))}

							</ScrollView>
						</View>
							: null
						}
						<View style={{ marginBottom: 15 }}>
							<View style={{ flexDirection: isPhone ? "column" : "row", justifyContent: "flex-end", alignItems: "center", gap: 10 }}>
								<View style={{ flex: isPhone ? 1 : undefined, flexDirection: "row", width: isPhone ? "100%" : undefined, alignItems: "center", justifyContent: "center" }}>
									<Button style={{ marginRight: 5, flex: isPhone ? 1 : undefined }} label="Sin Leer" primary={type === 0} height={35} onPress={() => setType(0)} round={7} />
									<Button style={{ marginRight: 5, flex: isPhone ? 1 : undefined }} label="Leídas" primary={type === 1} height={35} onPress={() => setType(1)} round={7} />
								</View>
								<View style={{ flex: 1, flexDirection: "row", justifyContent: "flex-end", alignItems: "center", gap: 10 }}>
									<TextInput value={search} placeholder={"Buscar..."} style={{ fontFamily: "DemiBold", fontSize: 16, marginTop: 5, width: "100%", borderColor: "gainsboro", borderRadius: 5, backgroundColor: "white", paddingHorizontal: 10, height: 45, borderWidth: 1 }} onChangeText={setSearch} />
									<View style={{ paddingLeft: 20 }}>
										<StyledText style={{ fontFamily: "title", fontSize: isPhone ? 35 : 45, textAlign: "right" }}>Mis Notificaciones ({data?.length || 0})</StyledText>
									</View>
								</View>


							</View>
						</View>
					</View>
				}
				contentContainerStyle={{
					paddingVertical: 30,
					paddingHorizontal: 10,
					width: "100%",
					maxWidth: 1200,
					alignSelf: "center",
					gap: 10
				}}
				showsVerticalScrollIndicator={false}
			/>
			{open ?
				<Modal onClickOutside={() => setOpen(false)} modalContainerStyle={{ width: "95%", maxWidth: 600, maxHeight: screen.height - 50 }} padding={0}>
					<View style={{ padding: 15, paddingBottom: 0 }}>
						<View style={{ backgroundColor: "#f6f6f6", padding: 10, borderRadius: 6, flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
							<View style={{ flex: 1 }}>
								<StyledText >{moment(open?.createdAt?.toDate()).format("DD-MM-YYYY [a las] HH:mm")}</StyledText>
							</View>
							{open?.sentBy && (
								<View style={{ flex: 1 }}>
									<StyledText numberOflines={1}>{open?.sentBy}</StyledText>
								</View>
							)}

						</View>
						<StyledText style={{ fontFamily: "Title", fontSize: 34 }}>{open?.subject}</StyledText>
					</View>
					<ScrollView style={{ maxHeight: sh - 150, flex: 1 }} contentContainerStyle={{ paddingHorizontal: 15, flexGrow: 1 }}>
						<div dangerouslySetInnerHTML={{ __html: open?.body || "<p></p>" }} style={{ whiteSpace: "break-spaces", fontFamily: "Regular", fontSize: 16, color: "#303030", marginTop: 0, marginBottom: 5 }} />
					</ScrollView>

					{open?.files?.filter(x => x).length > 0 ? <RenderLinks links={open?.files} storage={storage} /> : null}

					<View style={{ paddingBottom: 15, paddingHorizontal: 15, flexDirection: "row", alignItems: "center", justifyContent: 'space-between' }}>
						<Button label="Cerrar" height={35} color="#f3f3f3" onPress={() => setOpen(false)} round={7} />
					</View>
				</Modal>
				: null
			}
		</>
	);
};

MessagesScreen.navigationOptions = {
	header: null,
};

const RenderLinks = ({ links, storage }) => {

	return (
		<View>
			<View style={{ marginLeft: 15 }}>
				<StyledText style={{ color: 'grey' }}>Archivos adjuntos: </StyledText>
			</View>
			<View style={{ padding: 15 }}>
				{links.filter(x => x)?.map((link, i) => <SecureLink key={i} link={link} storage={storage} />)}
			</View>
		</View >
	)
}

const SecureLink = ({ link, storage }) => {

	const [url, setUrl] = useState(null)
	const [name, setName] = useState(null)

	useEffect(() => {
		if (link) {
			const docRef = ref(storage, link);
			getDownloadURL(docRef)
				.then((link) => {
					setUrl(link)
				})
				.catch((err) => console.log(err));

			getMetadata(docRef)
				.then((metadata) => setName(metadata.name))
				.catch((err) => console.log(err));
		}
	}, [link])

	return (
		<a href={url} target="_blank" style={{ textDecoration: 'none' }}>
			<View style={{ alignItems: "center", backgroundColor: "#f5f5f5", padding: 10, borderRadius: 5, marginBottom: 5, flexDirection: "row" }}>
				<Ionicons name="document-attach-outline" size={20} color="grey" style={{ marginRight: 10 }} />
				<StyledText numberOflines={1} style={{ numberOflines: 1 }}>{name}</StyledText>
			</View>
		</a>
	)

}


export default MessagesScreen;
