import {
    CollectionReference, getDocs
} from "firebase/firestore";
import { get } from "lodash";
import { UseMutationResult } from "react-query";
import { ContentCourse, ExamCourse, SectionCourse } from "../types/CourseTypes";
import { ContentAdvance, CourseAdvance, ExamAdvance } from "../types/models";
import fixDate from "./fixDate";



type Props = {
    sectionContents: (ContentCourse | ExamCourse)[],
    colRef: CollectionReference,
    masterAdvance: CourseAdvance,
    section: SectionCourse,
    userId: string,
    mutationAdvance: UseMutationResult
}

const autoCalification = async ({
    sectionContents,
    colRef,
    masterAdvance,
    section,
    userId,
    mutationAdvance
}: Props) => {

    console.log({ sectionContents, colRef, masterAdvance, section, userId, mutationAdvance })

    let allContentsCorrected = true

    const advancesQuery = await getDocs(colRef)
    const userAdvances = advancesQuery.docs.map(x => ({ ...x.data() as ContentAdvance | ExamAdvance, id: x.id }))

    sectionContents.forEach(c => {
        const ad = userAdvances.find(x => x.contentId === c.id)
        if (!ad) {
            allContentsCorrected = false
        }
    })

    // IF ALL CONTENTS ARE CORRECTED THEN AUTO GRADE
    let grades = get(masterAdvance, "grades", [])

    const grade = grades.find(g => g.id === section.id)
    const gradeIndex = grades.findIndex(g => g.id === section.id)

    sectionContents.forEach(c => {
        const ad = userAdvances.find(x => x.contentId === c.id)
        if (!ad) {
            allContentsCorrected = false
        }
    })

    if (!allContentsCorrected && !grade?.status) {
        console.log("MISSING CORRECTED EXERCISES")
        return
    }

    let status = "APPROVED"
    let gradeNumber = 0
    // CALCULATE IF IT CAN BE GRADED AUTOMATICALLY


    const advances = sectionContents.map(c => {
        const ad = userAdvances.filter(x => x.contentId === c.id).sort((a, b) => fixDate(a.updatedAt).valueOf() - fixDate(b.updatedAt).valueOf()).pop()
        return {
            content: c,
            advance: ad
        }
    })


    if (advances.some(x => !x.advance)) status = null
    else if (advances.some(x => x.advance.status === "FAILED")) status = "FAILED"
    else if (advances.some(x => x.advance.status === "COMPLETED" && x.advance.grade < 5)) status = "FAILED"
    else if (advances.some(x => x.advance.status === "NOTSUBMITTED")) { status = null }
    else if (advances.some(x => x.advance.status === "NOTRATED")) { status = null }
    else if (advances.some(x => x.advance.status === "CHANGESREQUIRED")) { status = null }
    else if (advances.some(x => x.advance.status === "PENDINGREVIEW")) { status = null }

    if ((status === "FAILED" || status === "APPROVED") && advances.every(x => x.advance)) {
        const totalPercentage = advances.reduce((acc, current) => acc + get(current, "content.contentPercentage", 0), 0)
        advances.forEach(x => {
            const contentPercentage = get(x, "content.contentPercentage", null)
            if (x.advance.grade !== null) {
                if (contentPercentage) {
                    gradeNumber += (x.advance.grade === 0 && x.advance.status === "APPROVED" ? 10 : x.advance.grade) * contentPercentage / (totalPercentage || 100)
                }
            } else {
                if (contentPercentage) {
                    const totalGrade = x.advance.status === "APPROVED" ? 10 : 0
                    gradeNumber += totalGrade * contentPercentage / (totalPercentage || 100)
                }
            }
        })
    } else {
        gradeNumber = null
    }

    if (grade) {
        grades[gradeIndex] = {
            grade: gradeNumber,
            status: status,
            comments: grade?.comments || "",
            updatedAt: new Date(),
            id: grade?.id || section?.id || "",
            updatedBy: userId,
        }

    } else {
        const newGrade = {
            grade: gradeNumber,
            status: status || null,
            comments: "",
            updatedAt: new Date(),
            id: section?.id,
            updatedBy: userId,
        }
        grades.push(newGrade)
    }

    mutationAdvance.mutate({ grades })

}

export default autoCalification