import { useNavigation } from "@react-navigation/native";
import React from "react";
import { ScrollView, View } from "react-native";
import { useQuery } from "react-query";
import Footer from "../../components/common/Footer";
import StyledText from "../../components/common/StyledText";
import CourseCardSquare from "../../components/publicComponents/CourseCardSquare";
import fixDate from "../../utils/fixDate";


const Recognition = () => {

	const navigation = useNavigation()

	const { isLoading, error, data } = useQuery({
		queryKey: ['courses'],
		queryFn: () => fetch('https://onformacion.comerciogo.workers.dev/courses').then((res) => res.json()),
		select: querySnapshot => (querySnapshot?.filter(x => x?.tenantId === "OF4VYpRfgUJEH5rG2syJ" && x?.courseType === "Reconocimiento de Competencias")
			.map(doc => {
				const initDate = fixDate(doc?.initDate)
				const endDate = fixDate(doc?.endDate)
				return {
					...doc,
					initDate,
					endDate,
				}
			}) || [])
			.sort((a, b) => a.initDate.getTime() - b.initDate.getTime())
	})

	return (
		<>
			<ScrollView style={{ flex: 1, backgroundColor: "white" }} contentContainerStyle={{ flexGrow: 1 }}>
				<View style={{ flexDirection: "row", alignItems: "center", width: "100%", paddingTop: 0, alignSelf: "center" }}>
					<View style={{ flexDirection: "row", backgroundColor: "#06162D", width: "100%", paddingTop: 50, paddingHorizontal: 30, paddingBottom: 50 }}>
						<View style={{ flex: 1 }}>
							<StyledText style={{ fontFamily: "Title", textAlign: "center", fontSize: 60, color: "white" }}>Reconocimiento de Competencias</StyledText>
						</View>
					</View>
				</View>

				<View style={{ maxWidth: 860, alignSelf: "center", paddingVertical: 20, gap: 20 }}>
					<StyledText style={{ fontSize: 18, textAlign: "center" }}>Este proceso va destinado a todas las personas interesadas en obtener el certificado de reconocimiento de competencias de entrenador UEFA.</StyledText>
					<StyledText style={{ fontSize: 18, textAlign: "center" }}>Para el acceso al mismo los candidatos deben estar en posesión del Título de Técnico Deportivo o Técnico Deportivo Superior en Fútbol, teniendo que acreditar ante la Comisión de Calidad de la RFEF (órgano competente al respecto) el haber adquirido dichos conocimientos y experiencia, disponiendo de las competencias equivalentes a la formación librada bajo el amparo de la RFEF en el contexto de la Convención.</StyledText>
					<StyledText style={{ fontSize: 18, textAlign: "center" }}>En estos enlaces podrá inscribirse, en función del <strong>certificado de reconocimiento de competencias</strong> al que desee acceder.</StyledText>

				</View>

				<View style={{ flexGrow: 1 }}>
					<View style={{
						paddingVertical: 15,
						flexWrap: "wrap",
						flexDirection: "row",
						alignItems: "flex-start",
						justifyContent: "flex-start",

					}}>

						{data?.length === 0 ?
							<View style={{ flex: 1, paddingHorizontal: 15, minHeight: 200, alignItems: "center", justifyContent: "center", backgroundColor: 'white' }} >
								<StyledText style={{ fontSize: 20, textAlign: "center", color: "black", opacity: .2 }}>No hay Procesos de solicitud abiertos en este momento</StyledText>
							</View>
							:
							data?.map((el, i) => (
								<CourseCardSquare key={i} onPress={() => navigation.navigate("PreviewCourseScreen", { tenantId: el.tenantId, id: el.id })} data={el} is />
							))}
					</View>
				</View>

				<Footer />

			</ScrollView >
		</>
	);
};

export default Recognition;