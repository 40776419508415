import { Ionicons } from "@expo/vector-icons";
import { Image, Modal, Text, TouchableOpacity, View, StyleSheet } from "react-native";
import React, { useState } from 'react'
import StyledText from "./StyledText";

const Hint = ({ title, image, color, rules, iconSize = 12 }) => {

	const [isVisible, setIsVisible] = useState(false);

	// Función para mostrar el popover
	const showPopover = () => {
		setIsVisible(true);
	};

	// Función para ocultar el popover
	const hidePopover = () => {
		setIsVisible(false);
	};
	return (
		<View style={styles.container}>
			{/* Botón que activa el popover */}
			<TouchableOpacity onPress={showPopover} >
				<Ionicons name="information-circle-outline" size={iconSize} color={color} />
			</TouchableOpacity>

			{/* El Modal que actúa como Popover */}
			<Modal
				transparent={true}
				visible={isVisible}
				animationType="fade"
				onRequestClose={hidePopover}
			>

				<View style={styles.overlay} >
					<View style={styles.popover}>
						<TouchableOpacity
							onPress={hidePopover}
							style={styles.closeButton}
						>
							<Ionicons name="close-outline" size={30} />
						</TouchableOpacity>
						<StyledText style={{ ...{ fontSize: 24, fontFamily: "TitleWide" }, ...(styles?.popoverText || {}) }}>
							{title}
						</StyledText>
						<Image
							source={{ uri: image }}
							style={styles.popoverImage}
						/>
						<View style={{ alignSelf: 'flex-start' }}>
							{
								rules && rules.map((rule, i) => <StyledText key={i} style={{ ...(styles.popoverRules || {}), ...{ fontSize: 18 } }}>
									<Ionicons name="arrow-forward-sharp" size={12} color={'#111'} />
									{rule}
								</StyledText>)
							}
						</View>
					</View>
				</View>
			</Modal>
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		justifyContent: 'center',
		alignItems: 'center',
	},
	button: {
		padding: 10,
		backgroundColor: '#007BFF',
		borderRadius: 5,
	},
	buttonText: {
		color: '#FFF',
		fontSize: 16,
	},
	overlay: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
	},
	popover: {
		width: 375,
		padding: 20,
		backgroundColor: '#FFF',
		borderRadius: 10,
		alignItems: 'center',
		position: 'relative'
	},
	popoverText: {
		fontSize: 16,

		marginTop: 10,
	},
	popoverRules: {
		fontSize: 12,
		textAlign: 'left',
		marginBottom: 10,
		marginTop: 10,
		display: 'flex',
		flexDirection: 'row',
		gap: 4,
	},
	popoverImage: {
		width: 300,
		height: 250,
		marginBottom: 10,
		resizeMode: 'contain',
	},
	closeButton: {
		position: 'absolute',
		top: 4,
		right: 4,
	},
	closeButtonText: {
		color: '#FFF',
		fontSize: 16,
	},
});

export default Hint
