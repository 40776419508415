import React, { useEffect, useState } from "react";
import { View, Image, Text, KeyboardAvoidingView, ImageBackground } from "react-native"
import { confirmPasswordReset, getAuth, isSignInWithEmailLink, signInWithEmailAndPassword, signInWithEmailLink, verifyPasswordResetCode } from "firebase/auth";
import { useNavigation } from "@react-navigation/native";
import { useForm } from "react-hook-form";
import Button from "../../components/common/Button";
import TextField from "../../components/formComponents/TextField";
import useYupValidationResolver from "../../utils/useYupValidationResolver";
import * as yup from "yup";

const PasswordReset = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false)
    const [sent, setSent] = useState(false)
    const [view, setView] = useState(false)
    const auth = getAuth();
    const navigation = useNavigation()

    let params = (new URL(window.location)).searchParams;
    let oobCode = params.get('oobCode')
    let email = params.get('email')
    let mode = params.get('mode')

    useEffect(() => {
        // Confirm the link is a sign-in with email link.
        verifyPasswordResetCode(auth, oobCode).catch(err => {
            alert("Este enlace no es válido o ha caducado, por favor vuelva a intentarlo")
            navigation.navigate("Login")

        })
    }, []);

    const handleReset = (values) => {
        setLoading(true)
        setError(false)
        setSent(false)
        confirmPasswordReset(auth, oobCode, values.password).then(res => {
            alert("Contraseña cambiada correctamente")
            signInWithEmailAndPassword(auth, email, values.password)
                .then(res => null)
                .catch(err => {
                    navigation.navigate("Login")
                })
            setSent(true)
            console.log(res)
            setLoading(false)
        }).catch(err => {
            alert("Ocurrió un error cambiando la contraseña")
            console.log(err)
            setLoading(false)
            setError(true)
        })
    }

    const validationSchema = yup.object({
        password: yup.string().required("Este campo es obligatorio").min(6, "La contraseña debe tener al menos 6 caracteres"),
        passwordConfirm: yup.string().required("Este campo es obligatorio").oneOf([yup.ref('password'), null], 'Las contraseñas no coinciden'),
    });

    const resolver = useYupValidationResolver(validationSchema);

    const {
        control,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver,
        defaultValues: {
            password: "",
            passwordConfirm: "",
        },
    })

    return (
        <KeyboardAvoidingView style={{ flex: 1, alignItems: "center", backgroundColor: "white", height: "100%" }} behavior="padding" enabled>
            <Button
                style={{ position: "absolute", height: 35, top: 15, left: 15, zIndex: 10 }}
                round={7}
                height={35}
                width={35}
                color="white"
                icon={"chevron-back"}
                reverse
                onPress={() => navigation.canGoBack() ? navigation.goBack() : navigation.navigate("StackPublic", { screen: "HomeScreen" })}
            />
            <View style={{ position: "absolute", filter: "hue-rotate(290deg)", zIndex: 1, height: "100%", width: "100%", top: 0, left: 0, right: 0, bottom: 0 }} />
            <ImageBackground
                style={{
                    flex: 1,
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 2,
                }}
                source={{ uri: "https://images.unsplash.com/photo-1511204338744-5d4e9b3ffee0?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=900&ixid=MnwxfDB8MXxyYW5kb218MHx8c29jY2VyfHx8fHx8MTY2NDg5MjU5Ng&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1600" }}
                resizeMethod="resize">
                <View style={{ backgroundColor: "rgba(0,0,0,.6)", flex: 1, position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: 1 }} />
                <View style={{ zIndex: 2, width: "100%", height: "100%", maxWidth: 600, flex: 1, alignItems: "center", paddingHorizontal: 20, justifyContent: "center" }}>
                    <View style={{ height: 200, width: 200, marginBottom: 0 }}>
                        <Image style={{ resizeMode: "contain", height: 200, width: 200 }} source={{ uri: "https://www.rfef.es/themes/custom/rfef/logo-icon.svg" }} />
                    </View>
                    {sent && <View style={{ marginBottom: 20 }}>
                        <Text style={{ color: "white", fontSize: 22, textAlign: "center" }}>
                            Se ha cambiado la contraseña correctamente. Serás redirigido automaticamente a la pantalla de inicio.
                        </Text>
                    </View>}
                    {error && <View style={{ marginBottom: 20 }}>
                        <Text style={{ color: "white", fontSize: 22, textAlign: "center" }}>
                            Ocurrió un error al cambiar la contraseña
                        </Text>
                    </View>}

                    {!sent ? <>
                        <View style={{ marginBottom: 10 }}>
                            <Text style={{ color: "white", fontSize: 22, textAlign: "center" }}>
                                Introduce una nueva contraseña
                            </Text>
                        </View>
                        <View style={{ marginVertical: 15, width: "100%", maxWidth: 600 }}>
                            <TextField error={errors.password} labelColor="white" secureInput={true} control={control} label="Contraseña" name="password" type="password" containerProps={{ style: { marginBottom: 10 } }} />
                            <TextField error={errors.passwordConfirm} labelColor="white" secureInput={true} control={control} label="Repetir Contraseña" name="passwordConfirm" type="password" />

                        </View>
                        <View style={{ marginTop: 10 }}>
                            <Button height="35px" round={5} onPress={handleSubmit(handleReset)} disabled={loading} loading={loading} label={"Cambiar Contraseña"} />
                        </View>
                    </> : null}


                </View>
            </ImageBackground>
        </KeyboardAvoidingView>
    );
};

export default PasswordReset;
