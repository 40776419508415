import { Timestamp } from 'firebase/firestore';
import moment from 'moment';
import { useEffect, useState } from 'react';

const fixDate = (date) => date instanceof Timestamp ? date.toDate() : date

export default function useLastDebtWarning({ courses = [], payments = [] }) {
    const [upToDatePayments, setUpToDatePayments] = useState([]);

    useEffect(() => {
        if (courses?.length === 0 || payments?.length === 0) return;
        let flag = false;
        const upToDatePayments = Object.fromEntries(courses.map(c => {
            const thisPayment = payments.find(p => p.course?.id === c.id)
            const discountAmount = thisPayment?.discountAmount || 0
            let remainingDiscount = discountAmount
            const discountedTerms = (c?.payment || [])
                .filter(p => p.active).sort((a, b) => moment(fixDate(a.endDate) || 0).isBefore(moment(fixDate(b.endDate) || 0)) ? 1 : -1)
                .map(p => {
                    if (remainingDiscount > 0) {
                        if (remainingDiscount > p.amount) {
                            remainingDiscount -= p.amount
                            return { ...p, amount: 0 }
                        } else {
                            const remainingAmount = p.amount - remainingDiscount
                            remainingDiscount = 0
                            return { ...p, amount: remainingAmount }
                        }
                    } else { return p }
                })
                .reverse()
            const pastTerms = discountedTerms.filter(p => p.active && moment(fixDate(p.endDate)).isBefore(moment()))
            const shouldHavePaid = pastTerms.reduce((acc, it) => acc + (it?.amount) || 0, 0)
            const havePaid = (thisPayment?.payments || []).filter(p => !p.preinscriptionPayment).reduce((acc, it) => acc + it.amount, 0)
            if (havePaid < shouldHavePaid) {
                flag = true
            }
            return [c.id, {
                name: c.name,
                upToDate: havePaid >= shouldHavePaid
            }]
        }))
        if (flag) {
            const lastDebtWarning = moment(localStorage.getItem("lastDebtWarning"))
            const diff = moment().diff(lastDebtWarning)
            // const arr = Object.values(upToDatePayments).filter(p => !p.upToDate)
            if (!lastDebtWarning || diff > (24 * 60 * 60 * 1000)) {
                // modalCb({
                //     show: true,
                //     notUpToDate: `${arr.length > 1 ? " los" : "l"} curso${arr.length > 1 ? "s" : ""}${arr.reduce((acc, it, i) => acc + (i + 1 === arr.length ? " y " : ", ") + (it.name || ""), "").slice(1)}`
                // })
                localStorage.setItem("lastDebtWarning", new Date().toISOString())
            }
        }
        setUpToDatePayments(upToDatePayments);
    }, [courses?.length, payments?.length])

    return upToDatePayments;
}