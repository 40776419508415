import { Timestamp } from "firebase/firestore"
import moment from "moment"

const fixDate = (date) => {
    if (typeof date === "string") {
        return moment(date).toDate()
    } else if (date instanceof Timestamp) {
        return date.toDate()
    } else if (date instanceof Date) {
        return date
    } else if (typeof date === "object" && date?.secodns) {
        return moment(date?.seconds * 1000).toDate()
    } else {
        return new Date()
    }
}

export default fixDate