import React, { useContext, useEffect } from "react";
import { View, useWindowDimensions } from "react-native";


import { ScrollView } from "react-native-gesture-handler";

import moment from "moment";
import { useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";
import Button from "../../components/common/Button";
import Modal from "../../components/common/Modal";
import StyledText from "../../components/common/StyledText";
import CheckBoxField from "../../components/formComponents/CheckBoxField";
import TextField from "../../components/formComponents/TextField";
import AuthContext from "../../context/AuthContext";
import useYupValidationResolver from "../../utils/useYupValidationResolver";


const CustomFormCV = ({ submitCallback, closeCallback, initialData }) => {
    console.log(initialData)
    const { userData } = useContext(AuthContext)
    const screen = useWindowDimensions()

    const validationSchema = yup.object({
        terms: yup.boolean().oneOf([true], "Debes aceptar los términos y condiciones para continuar"),
        location: yup.string().required("Debes seleccionar una ubicación"),
    });

    const resolver = useYupValidationResolver(validationSchema);

    const {
        handleSubmit,
        reset,
        control,
        formState: { errors },
    } = useForm({
        mode: "onChange",
        resolver: resolver,
        defaultValues: {
            languages: [{ name: "", level: "" }],
            studies: [{ title: "", obtained: "", date: "" }],
            federativeStudies: [{ title: "", obtained: "", date: "" }],
            playerExperience: [{ season: "", club: "", category: "" }],
            coachExperience: [{ season: "", club: "", category: "" }],
            international: [{ times: "", country: "" }],
            location: "",
            date: new Date(),
            terms: false,
            name: userData.name || "",
            surname: userData.surname || "",
            idNumber: userData.idNumber || "",
        },
    });

    useEffect(() => {
        if (initialData) {
            reset({
                languages: [{ name: "", level: "" }],
                studies: [{ title: "", obtained: "", date: "" }],
                federativeStudies: [{ title: "", obtained: "", date: "" }],
                playerExperience: [{ season: "", club: "", category: "" }],
                coachExperience: [{ season: "", club: "", category: "" }],
                international: [{ times: "", country: "" }],
                location: "",
                terms: false,
                date: new Date(),
                ...initialData,
                name: initialData.name || userData.name || "",
                surname: initialData.surname || userData.surname || "",
                idNumber: initialData.idNumber || userData.idNumber || "",
            })
        }
    }, [initialData])

    return (
        <Modal padding={0} modalContainerStyle={{ maxWidth: 900, width: "95%", minWidth: 400, backgroundColor: "white", borderRadius: 20 }} >
            <ScrollView contentContainerStyle={{ padding: 20 }} style={{ maxHeight: screen.height - 120 }}>
                <StyledText style={{ fontSize: 34, fontFamily: "Title", fontWeight: "bold" }}>Currículo Vitae</StyledText>
                <StyledText style={{ fontSize: 16, marginBottom: 0 }}>Completa los campos del formulario con experiencia en cada una de las areas indicadas.</StyledText>
                <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "#f2f2f2", borderRadius: 10, padding: 15, marginVertical: 20 }}>
                    <StyledText style={{ fontWeight: "bold" }}>Yo {userData.name} {userData.surname} con {userData.idType} {userData.idNumber}, declaro que los siguientes datos personales son verdaderos y que puede demostrarlo en cualquier momento.</StyledText>
                </View>

                <FieldRepeater control={control} errors={errors} path="languages"
                    key="languages"
                    title="Idiomas"
                    subfields={[{
                        key: "name",
                        label: "Idioma",
                        placeholder: "Idioma...",
                        type: "text"
                    }, {
                        key: "level",
                        label: "Nivel",
                        placeholder: "Nivel...",
                        type: "text"
                    }]} />

                <FieldRepeater control={control} errors={errors} path="studies"
                    key="studies"
                    title="Estudios que posee"
                    subfields={[{
                        key: "title",
                        label: "Título",
                        placeholder: "Título...",
                        type: "text"
                    }, {
                        key: "obtained",
                        label: "Obtenido En",
                        placeholder: "Obtenido...",
                        type: "text"
                    }, {
                        key: "date",
                        label: "Fecha",
                        placeholder: "Fecha...",
                        type: "date"
                    }]} />

                <FieldRepeater control={control} errors={errors} path="federativeStudies"
                    key="federativeStudies"
                    title="Formación Federativa"
                    subfields={[{
                        key: "title",
                        label: "Título",
                        placeholder: "Título...",
                        type: "text"
                    }, {
                        key: "obtained",
                        label: "Obtenido En",
                        placeholder: "Obtenido...",
                        type: "text"
                    }, {
                        key: "date",
                        label: "Fecha",
                        placeholder: "Fecha...",
                        type: "date"
                    }]} />
                <FieldRepeater control={control} errors={errors} path="playerExperience"
                    key="playerExperience"
                    title="Experiencia como Jugador"
                    subfields={[{
                        key: "season",
                        label: "Temporada",
                        placeholder: "Temporada...",
                        type: "text"
                    }, {
                        key: "club",
                        label: "Club",
                        placeholder: "Club...",
                        type: "text"
                    }, {
                        key: "category",
                        label: "Categoría",
                        placeholder: "Categoría...",
                        type: "text"
                    }]} />
                <FieldRepeater control={control} errors={errors} path="international"
                    key="international"
                    title="Internacional"
                    subfields={[{
                        key: "times",
                        label: "Número de veces",
                        placeholder: "Número de Veces...",
                        type: "number"
                    }, {
                        key: "country",
                        label: "Internacional Como",
                        placeholder: "Internacional Como...",
                        type: "text"
                    }]} />
                <FieldRepeater control={control} errors={errors} path="coachExperience"
                    key="coachExperience"
                    title="Experiencia como Entrenador"
                    subfields={[{
                        key: "season",
                        label: "Temporada",
                        placeholder: "Temporada...",
                        type: "text"
                    }, {
                        key: "club",
                        label: "Club",
                        placeholder: "Club...",
                        type: "text"
                    }, {
                        key: "category",
                        label: "Categoría",
                        placeholder: "Categoría...",
                        type: "text"
                    }]} />
                <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "#f2f2f2", borderRadius: 10, padding: 15, marginVertical: 20 }}>
                    <StyledText>En </StyledText>
                    <TextField control={control} name="location" placeholder="Lugar..." style={{ marginLeft: 15, marginRight: 15, height: 35 }} />
                    <StyledText>a {moment().format("DD [de] MMMM [de] YYYY")}</StyledText>
                </View>
                {errors["location"]
                    ? <StyledText style={{ color: "red", marginBottom: 10 }}>{errors["location"].message}</StyledText>
                    : null
                }
                <CheckBoxField error={errors["terms"]} name="terms" key="terms" label={"Aceptacion de condiciones y proteccion de datos de caracter personal"} control={control} />
            </ScrollView>
            <View style={{ padding: 20, flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                <Button height="35px" color="#f2f2f2" label="Volver" round="7px" onPress={closeCallback} />
                <Button height="35px" label="Guardar" round="7px" onPress={handleSubmit(submitCallback)} />
            </View>
        </Modal>
    )
}


const FieldRepeater = ({ control, errors, path, subfields, title }) => {

    const { fields, append, remove } = useFieldArray({ control, name: path })

    return (
        <View style={{ marginTop: 20 }}>
            <StyledText style={{ fontSize: 20, fontWeight: "bold", marginBottom: 20 }}>{title}</StyledText>
            <View style={{ flexDirection: "row", alignItems: "center", marginBottom: 10 }}>
                {subfields.map((subfield, index) => (
                    <View key={subfield.key} style={{ flex: 1 }}>
                        <StyledText >{subfield.label}</StyledText>
                    </View>
                ))}
                <View style={{ width: 55 }} />
            </View>
            {fields.map((item, index) => (
                <View key={item.id} style={{ flexDirection: "row", alignItems: "center", marginBottom: 10 }}>
                    {subfields.map((subfield) => (
                        <View key={subfield.key + item.id} style={{ flex: 1, marginRight: 10 }}>
                            <TextField
                                type={subfield.type}
                                style={{ height: 35 }}
                                placeholder={subfield.placeholder}
                                control={control}
                                name={`${path}[${index}].${subfield.key}`}
                                key={`${subfield.key}-${item.id}`}
                            />
                        </View>
                    ))}
                    <View style={{ width: 55 }} >
                        <Button height="35px" width="35px" icon="trash" round="7px" color={fields.length === 1 ? "gray" : null} onPress={() => remove(index)} disabled={fields.length === 1} />
                    </View>
                </View>

            ))}
            <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }}>
                <Button style={{ alignSelf: "flex-end" }} primary={false} height="35px" width="35px" icon="add" round="7px" onPress={() => append(subfields.reduce((acc, cur) => ({ ...acc, [cur.key]: "" }), {}))} />
            </View>
        </View>
    )
}


export default CustomFormCV