import React, { useEffect, useState } from 'react';
import PdfReader from "rn-pdf-reader-js";
import { Platform, View } from 'react-native';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import Button from './Button';

const SecureVideo = ({ uri, style = {} }) => {
    const [file, setFile] = useState(null)
    const storage = getStorage()

    useEffect(() => {
        if (uri) {
            if (uri.includes("http")) {
                setFile(uri)
            } else if (uri.includes("base64")) {
                setFile(uri)
            } else {
                const fileRef = ref(storage, uri)
                getDownloadURL(fileRef).then(res => {
                    setFile(res)
                }).catch(err => {
                    console.log(err)
                })
            }
        }
    }, [uri])

    return (
        <View style={{ flex: 1 }}>
            <View style={{ flex: 1 }}>
                {Platform.OS !== "web" ? (
                    <PdfReader
                        style={{ flex: 1, ...style }}
                        withScroll
                        renderType="DIRECT_URL"
                        source={{
                            uri: file
                        }}
                    />
                ) : (
                    <video controls src={file} style={{ flex: 1, contentFit: "contain", ...style }} />
                )}
            </View>
        </View>
    );
}

export default SecureVideo;