import { Ionicons } from "@expo/vector-icons";
import { omit } from "lodash";
import React, { HTMLInputTypeAttribute, forwardRef, useState } from "react";
import { Control, Controller, FieldError } from "react-hook-form";
import { Platform, TextInput, View } from "react-native";
import { TouchableOpacity } from "react-native-web";
import StyledText from "../common/StyledText";

type Props = {
	control: Control<any>,
	name: string,
	label: string,
	placeholder?: string,
	moreInfo?: string,
	error?: FieldError,
	rules?: any,
	trim?: boolean,
	autoCapitalize?: any,
	labelColor?: string,
	secureInput?: boolean,
	containerProps?: any,
	type?: HTMLInputTypeAttribute,
	multiline?: boolean,
	editable?: boolean,
} & React.InputHTMLAttributes<HTMLInputElement>;


const TextField = forwardRef(({ control, label, moreInfo, name, error, editable, rules = {}, trim = false, placeholder, multiline = false, autoCapitalize = undefined, labelColor = "gray", secureInput = false, containerProps = { style: { flex: 1 } }, type = "text", ...props }: Props, ref) => {

	const [showPass, setShowPass] = useState(false)

	return (
		<View {...containerProps} style={{ ...(containerProps?.style || {}) }}>
			{label && (
				<StyledText style={{ color: labelColor, fontSize: 16, marginBottom: 10 }}>
					{label}
				</StyledText>
			)}

			{moreInfo && (
				<StyledText style={{ marginBottom: 5, fontSize: 12 }}>
					{moreInfo}
				</StyledText>
			)}

			<View>
				<Controller
					{...omit(props, ["disabled"])}
					name={name}
					control={control}
					rules={rules}
					render={({ field }) => {
						return (
							Platform.OS === "web" ?
								<View style={{ flex: 1 }}>
									{multiline ?
										<textarea
											{...field}
											{...omit(props, ["alignItems", "numberOfLines"])}
											placeholder={placeholder}
											editable={editable}
											// type={secureInput ? !showPass ? "password" : "text" : type}
											style={{ borderRadius: 7, border: "1px solid gainsboro", borderColor: "gainsboro", height: 42, width: "auto", fontFamily: "Regular", borderRadiuus: 5, paddingRight: secureInput ? 50 : 10, paddingLeft: 10, ...(props.style || {}), ...(props.textinputstyle || {}) }}
											onBlur={() => {
												const v = type === "number" ? props?.step ? parseFloat(field.value) : parseInt(field.value) : field.value;
												field.onChange(secureInput ? v.trim() : trim ? v.trim() : v);
											}}
											onChange={ev => {
												field.onChange(trim ? ev.target.value.trim() : ev.target.value);
											}}
										/>
										:
										<input
											{...field}
											{...omit(props, ["alignItems", "numberOfLines", "disabled"])}
											editable={editable}
											disabled={props.disabled}
											ref={ref}
											placeholder={placeholder}
											autoCapitalize={autoCapitalize}
											type={secureInput ? !showPass ? "password" : "text" : type}
											style={{ minHeight: 42, borderRadius: 7, border: "1px solid gainsboro", borderColor: "gainsboro", height: 42, width: "auto", fontFamily: "Regular", borderRadiuus: 5, paddingRight: secureInput ? 50 : 10, paddingLeft: 10, ...(props.style || {}), ...(props.textinputstyle || {}) }}
											onBlur={() => {
												const v = type === "number" ? props?.step ? parseFloat(field.value) : parseInt(field.value) : field.value;
												field.onChange(secureInput ? v.trim() : trim ? v.trim() : v);
											}}
											onChange={ev => {
												field.onChange(trim ? ev.target.value.trim() : ev.target.value);
											}}
											value={field.value || ""}
										/>
									}
									{secureInput && <TouchableOpacity
										onPress={() => setShowPass(!showPass)}
										style={{ height: 50, width: 50, alignItems: "center", justifyContent: "center", position: "absolute", right: 0, }}
									>
										<Ionicons color="gainsboro" name={showPass ? "eye-off" : "eye"} size={30} />
									</TouchableOpacity>}
								</View>
								:
								<TextInput
									{...field}
									{...props}
									type={type}
									placeholder={placeholder}
									autoCapitalize={autoCapitalize}
									style={{ borderRadius: 7, borderWidth: 1, borderColor: "gainsboro", height: 45, webkitAppearance: "none", width: "100%", borderRadiuus: 5, paddingHorizontal: 10, ...(props.style || {}) }}
									onBlur={() => {
										const v = type === "number" ? props?.step ? parseFloat(field.value) : parseInt(field.value) : field.value;
										field.onChange(secureInput ? v.trim() : trim ? v.trim() : v);
									}}
								/>
						);
					}}
				/>


				{error && (
					<StyledText style={{ marginTop: 10, marginBottom: 0, fontSize: 14, color: "red" }}>
						{error?.message}
					</StyledText>
				)}
			</View>
		</View>
	);
});

export default TextField;
