import React, { useContext, useState } from "react";
import {
    ActivityIndicator, KeyboardAvoidingView, Text, TextInput,
    TouchableOpacity, View
} from "react-native";
import Element from '../../constants/Elements';
import AuthContext from '../../context/AuthContext';

import { getAuth, sendPasswordResetEmail } from "firebase/auth";


const Lost = ({ navigation }) => {

    const { recoverPassword } = useContext(AuthContext);
    const [form, setForm] = useState({ email: '' })
    const [template, setTemplate] = useState({})
    const [errorForm, setErrorForm] = useState(false)
    const [tip, setTip] = useState("")
    const [checking, setChecking] = useState(false)
    const mainlogo = Element('mainLogo')

    const auth = getAuth()

    const resetFunc = () => {
        setChecking(true)
        sendPasswordResetEmail(auth, form.email).then((a) => {
            setTip("El correo electrónico se ha enviado correctamente. Revisa tu bandeja de entrada, en breves momentos podrás recuperar tu contraseña.")
            setChecking(false)
        }).catch((error) => {
            setTip(error)
            setErrorForm(true)
            setChecking(false)
        });
    }



    return (
        <KeyboardAvoidingView style={{ flex: 1, alignItems: 'center', backgroundColor: "black" }} behavior="padding" enabled>
            <View
                style={{
                    flex: 1,
                    width: '90%',
                    marginHorizontal: '5%',
                    maxWidth: 500,
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <Text style={{ color: "white", fontFamily: "DemiBold", fontSize: 32, marginBottom: 30 }}>¿Olvidaste tus claves?</Text>
                <Text style={{ color: "white", fontFamily: "DemiBold", fontSize: 18, marginBottom: 50 }}>
                    Escribe tu email asociado a tu cuenta en la aplicación y te enviaremos un enlace para poder recuperar tu contraseña.
                </Text>

                <TextInput
                    textContentType="emailAddress"
                    clearButtonMode="while-editing"
                    autoCapitalize="none"
                    placeholder="Email"
                    placeholderTextColor={errorForm ? "gainsboro" : "gainsboro"}
                    style={{
                        borderColor: errorForm ? "#E99E2B" : "white",
                        color: "black",
                        height: 55,
                        width: '100%',
                        backgroundColor: "white",
                        fontFamily: "Regular",
                        fontSize: 18,
                        padding: 15,
                        borderRadius: 15,
                        borderWidth: errorForm ? 4 : 1
                    }}
                    onChangeText={text => setForm({ ...form, email: text })}
                    value={form && form.identifier}
                />

                <Text style={{ color: "white", fontFamily: "Regular", marginTop: 20 }}>{tip}</Text>
                <TouchableOpacity
                    style={{
                        height: 50,
                        marginTop: 25,
                        paddingHorizontal: 50,
                        backgroundColor: "white",
                        borderRadius: 25,
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                    onPress={resetFunc}
                >
                    {checking ? (
                        <ActivityIndicator color={"black"} />
                    ) : (
                        <Text
                            style={{
                                color: "black",
                                fontSize: 18,
                                fontFamily: "DemiBold"
                            }}
                        >
                            Solicitar Recuperación
                        </Text>
                    )}
                </TouchableOpacity>

                <TouchableOpacity style={{ marginTop: 20 }} onPress={() => navigation.navigate("Login")}>
                    <Text style={{ color: "white", fontSize: 18, fontFamily: "DemiBold" }}>Volver al Inicio</Text>
                </TouchableOpacity>

            </View>
        </KeyboardAvoidingView>
    );
}

export default Lost;