import { getAuth } from "firebase/auth";
import React, { useContext } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import Footer from "../../components/common/Footer";
import SecureImage from "../../components/common/SecureImage";
import SecurePDF from "../../components/common/SecurePDF";
import SecureVideo from "../../components/common/SecureVideo";
import StyledText from "../../components/common/StyledText";
import AuthContext from "../../context/AuthContext";

const fileTypes = Object.freeze({
	document: ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'],
	image: ['jpg', 'jpeg', 'png', 'gif'],
	audio: ['mp3', 'wav', 'ogg'],
	video: ['mp4', 'avi', 'mov', 'wmv', 'flv', 'webm', 'm4v'],

})
const DownloadPage = ({ route }) => {
	const { customization } = useContext(AuthContext)
	const { url } = route.params
	const decodeUrl = decodeURI(url) || ''
	const type = decodeUrl.toLocaleLowerCase().split('.').pop() || '';
	const auth = getAuth()

	return (
		<>
			<ScrollView style={{ flex: 1, backgroundColor: "white" }} contentContainerStyle={{ flexGrow: 1 }}>
				{/* SLIDER TOP */}
				<View style={{ height: 100, backgroundColor: customization.mainColor, borderBottomWidth: 1, borderBottomColor: "white" }}>
					{/* <Image source={{ uri: "https://images.pexels.com/photos/274506/pexels-photo-274506.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" }} style={{ position: "absolute", resizeMode: "cover", top: 0, left: 0, right: 0, bottom: 0 }} /> */}
					{/* <LinearGradient start={{ x: 0, y: 0 }} end={{ x: 0, y: -1 }} colors={["rgba(0,0,0,.6)", "transparent"]} style={{ position: "absolute", resizeMode: "cover", top: 0, left: 0, right: 0, bottom: 0 }}></LinearGradient> */}
					<View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
						<StyledText style={{ fontSize: 45, color: "white", fontFamily: "Title" }}>
							Contenido descargable
						</StyledText>
					</View>
				</View>
				<View style={{ height: 10, width: "100%", backgroundColor: "#003349" }} />

				{auth?.currentUser?.uid ?
					<View style={{ paddingVertical: 15, flexGrow: 1, maxWidth: 1000, alignSelf: "center", width: "100%", paddingHorizontal: 20, }}>
						{fileTypes.video.includes(type) ? <SecureVideo uri={decodeUrl} style={{ borderRadius: 7, backgroundColor: "gainsboro", width: "100%", height: "75vh", minHeight: 400 }} /> : null}
						{fileTypes.document.includes(type) ? <SecurePDF uri={decodeUrl} style={{ borderRadius: 7, backgroundColor: "gainsboro", width: "100%", height: "75vh", minHeight: 400 }} /> : null}
						{fileTypes.image.includes(type) ? <SecureImage placeholder={"https://brandemia.org/sites/default/files/inline/images/03-rfef-imagenes-brandemia-blog_0.jpg"} uri={decodeUrl} style={{ borderRadius: 7, backgroundColor: "gainsboro", width: "100%", height: "75vh", minHeight: 400 }} /> : null}
					</View>
					:
					<View style={{ paddingVertical: 15, flexGrow: 1, maxWidth: 1000, alignSelf: "center", width: "100%", paddingHorizontal: 20, }}>
						<StyledText style={{ textAlign: "center", paddingVertical: 20 }}>Debes iniciar sesión para poder descargar el contenido</StyledText>
					</View>
				}

				<Footer />
			</ScrollView>
		</>
	);
};

export default DownloadPage;


const styles = StyleSheet.create({
	wrapper: {},
	slide1: {
		flex: 1,
	},
	slide2: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "#97CAE5",
	},
	slide3: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "#92BBD9",
	},
	text: {
		color: "#fff",
		fontSize: 30,
		fontWeight: "bold",
	},
	shadow: {
		shadowColor: "rgba(0, 0, 0, 1)",
		shadowOffset: { width: 0, height: 7 },
		shadowOpacity: 0.1,
		shadowRadius: 10,
		elevation: 10,
	},
});
