import { LinearGradient } from 'expo-linear-gradient';
import React, { useEffect } from 'react';
import { View } from 'react-native';
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';
// import console = require('console');

window._frameTimestamp = null

const LinearBarIndicator = ({ animationSpeed = 700, styles, height = 10, rounded = 5, width = null, percentage = 0, backgroundColor = "gainsboro", fillColor = "green", gradient = null }) => {

    const value = useSharedValue(0)

    useEffect(() => {
        value.value = percentage
    }, [percentage])


    const animatedStyles = useAnimatedStyle(() => {
        return {
            width: withTiming(`${value.value}%`, { duration: animationSpeed }),
        }
    })


    return (
        <View
            style={[{
                // flex: 1, 
                backgroundColor: backgroundColor,
                height: height,
                width: width,
                borderRadius: rounded
            }, { ...styles }]}
        >


            <Animated.View style={[{ height: height, borderRadius: rounded, backgroundColor: fillColor }, animatedStyles]}>
                {gradient ?
                    <LinearGradient start={[1, 1]} end={[0, 0]} colors={gradient} style={{ flex: 1, borderRadius: rounded }} />
                    :
                    null
                }
            </Animated.View>

        </View>
    )
}

export default LinearBarIndicator;