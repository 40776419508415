
const translations = {
    en: {
        categories: 'Categories',
        featured: 'Featured',
        contents: 'Contents',
        share: 'Share',
        sharesomething: 'Comparte algo nuevo!',
        publish: 'Publicar',
        nocontent: 'No contents',
        mycourses: "My Courses",
        nocourses: "No tienes cursos activos actualmente",
        completed: "Completados",
        forums: "Foros",
        popularthreads: "Hilos Populares",
        ranking: "Rankings",
        news: "Novedades",
        "No Courses Available": "No Courses Available",
        "No Courses Completed": "No Courses Completed",
        "No Courses Rejected": "No Courses Rejected",
        "Go Back": "Go Back",
        "No Courses Pending Review": "No Courses Pending Review",
    },
    "es-ES": {
        categories: 'Categorías',
        featured: 'Destacado',
        contents: 'Contenidos',
        share: 'Compartir',
        sharesomething: 'Comparte algo nuevo!',
        publish: 'Publicar',
        nocontent: 'No hay contenidos',
        mycourses: "Mis Módulos",
        nocourses: "No tienes cursos activos actualmente",
        completed: "Completados",
        forums: "Foros",
        popularthreads: "Hilos Populares",
        ranking: "Rankings",
        news: "Novedades",
        "No Courses Available": "No tienes cursos disponibles",
        "No Courses Completed": "No tienes cursos completados",
        "No Courses Rejected": "No tienes cursos rechazados",
        "Go Back": "Volver",
        "No Courses Pending Review": "No tienes cursos pendientes de revisión",
    },
    "es": {
        categories: 'Categorías',
        featured: 'Destacado',
        contents: 'Contenidos',
        share: 'Compartir',
        sharesomething: 'Comparte algo nuevo!',
        publish: 'Publicar',
        nocontent: 'No hay contenidos',
        mycourses: "Mis Módulos",
        nocourses: "No tienes cursos activos actualmente",
        completed: "Completados",
        forums: "Foros",
        popularthreads: "Hilos Populares",
        ranking: "Rankings",
        news: "Novedades",
        "No Courses Available": "No tienes cursos disponibles",
        "No Courses Completed": "No tienes cursos completados",
        "No Courses Rejected": "No tienes cursos rechazados",
        "Go Back": "Volver",
        "No Courses Pending Review": "No tienes cursos pendientes de revisión",
    },
};

export default translations