import React, { useCallback, useMemo } from 'react';
import { StyleSheet, TouchableOpacity, View, Text } from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import { useFirestoreDocument, useFirestoreQuery } from '@react-query-firebase/firestore';
import { addDoc, collection, deleteDoc, doc, getFirestore, increment, orderBy, query, serverTimestamp, setDoc, updateDoc } from 'firebase/firestore';
import { Bubble, GiftedChat } from 'react-native-gifted-chat';
import { useMediaQuery } from 'react-responsive';
import { v4 } from 'uuid';
import SecureImage from './common/SecureImage';
import StyledText from './common/StyledText';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';

const TutorChat = ({ data, name, teacher, user, courseRef, mainColor, closeCallback }) => {


    const db = getFirestore()
    const storage = getStorage()

    const isPhone = useMediaQuery({ query: '(max-width: 1000px)' })

    const docRef = doc(db, `${courseRef.path}/conversations/${user}${teacher}`)

    const conversation = useFirestoreDocument(["conversation", courseRef.id, user + teacher], docRef, {}, {
        onSuccess: (data) => {
            if (!data.exists()) {
                createConversation()
            }
        }
    })
    const colRef = collection(db, `${courseRef.path}/conversations/${user}${teacher}/messages`)
    const queryRef = query(colRef, orderBy("createdAt", "desc"))
    const { data: messages } = useFirestoreQuery([teacher, user, "messages"], queryRef,
        { subscribe: true },
        {
            select: (d) => d.docs.map((doc) => ({ ...doc.data(), createdAt: doc.data().createdAt.toDate(), ref: doc.ref, id: doc.id })),
            onSuccess: () => markAsRead(),
            onError: e => console.log(e)
        })


    const createConversation = async () => {
        setDoc(docRef, {
            userName: data?.userName || "",
            teacherName: data?.teacherName || "",
            userImage: data?.userImage || "",
            teacherImage: data?.teacherImage || "",
            courseName: data?.courseName || "",
            courseTag: data?.courseTag || "",
            user,
            teacher,
            course: courseRef,
            lastMessage: "",
            updatedAt: serverTimestamp(),
            createdAt: serverTimestamp(),
            messages: 0,
            unreadMessagesUser: 0,
            unreadMessagesTeacher: 0,
            tenantId: courseRef.parent.parent.id,
            readUser: true,
        }, { merge: true })
    }

    const markAsRead = async () => {
        updateDoc(docRef, {
            updatedAt: serverTimestamp(),
            unreadMessagesUser: 0,
            tenantId: courseRef.parent.parent.id,
            readUser: true,
        })
    }

    const submitMessage = async (v) => {

        updateDoc(docRef, {
            readUser: true,
            readTeacher: false,
            updatedAt: serverTimestamp(),
            lastMessageSent: serverTimestamp(),
            messages: increment(1),
            unreadMessagesTeacher: increment(1),
            tenantId: courseRef.parent.parent.id,
            courseName: data?.courseName || "",
            courseTag: data?.courseTag || "",
            lastMessage: v.text
        })
        addDoc(colRef, v)
        // setMessage("")
    }

    const deleteMessages = async () => {
        await Promise.all(messages.map(async el => await deleteDoc(el.ref)))
        deleteDoc(docRef)
    }

    const onSend = useCallback((messages = []) => {
        const last = [...messages].pop()
        submitMessage(last)
    }, [])

    const dataMessages = useMemo(() => messages || [], [messages])

    const renderChatEmpty = useCallback(() => (
        <View style={{ transform: [{ rotate: "180deg" }, { rotateY: "180deg" }], flex: 1, alignItems: "center", justifyContent: "center" }}>
            <StyledText style={{ fontFamily: "DemiBold", fontSize: 18, opacity: .2 }}>No hay mensajes</StyledText>
        </View>
    ), [])

    const renderBubble = useCallback(props => {
        return (
            <Bubble
                {...props}
                textStyle={{
                    left: {
                        margin: 5,
                        color: "black"
                    },
                    right: {
                        margin: 5,
                        color: 'white',
                    },
                }}
                wrapperStyle={{
                    left: {
                        padding: 7,
                        backgroundColor: 'gainsboro',
                        borderRadius: 6,
                        borderTopLeftRadius: 0,
                        padding: 0
                    },
                    right: {
                        borderRadius: 6,
                        borderTopRightRadius: 0,
                        padding: 7,
                        backgroundColor: mainColor,
                        padding: 0
                    },
                }}
            />
        )
    }, [])

    const renderSend = useCallback((props) => {
        const { text, messageIdGenerator, user, onSend } = props
        return (
            <TouchableOpacity
                {...props}
                onPress={() => {
                    if (text && onSend) {
                        onSend({ text: text.trim(), user: user, _id: messageIdGenerator() }, true);
                    }
                }
                } style={{ width: 45, height: 45, alignItems: "center", justifyContent: "center" }}>
                <Ionicons name="send" size={25} color="gray" />
            </TouchableOpacity>
        )
    }, [])

    const renderCustomView = useCallback(props => {
        const filePath = props?.currentMessage?.file
        if(filePath){
            return (
                <TouchableOpacity
                    onPress={async () => {
                        const signedURL = await getDownloadURL(ref(storage, filePath))
                        window.open(signedURL, "_blank", "noopener")
                    }}
                >
                    <View
                        style={{ flexDirection: "row", alignItems: "center", paddingHorizontal: 10, paddingVertical: 5, backgroundColor: "#F0F0F0", margin: 5, borderRadius: 4 }}
                    >
                        <Ionicons name="attach-outline" size={18} color="gray" style={{ marginLeft: -5, marginBottom: -2, marginRight: 3 }} />
                        <Text>{ filePath.split("/").slice(-1)[0] }</Text>
                    </View>
                </TouchableOpacity>
            )
        }
    }, [])

    return (
        <View style={[styles.shadow, { zIndex: 20, position: "absolute", bottom: isPhone ? 0 : 10, left: isPhone ? 0 : 10, backgroundColor: "white", borderRadius: 7, width: isPhone ? "100%" : 350, height: isPhone ? "100%" : 450 }]}>
            <View style={{ flex: 1 }}>
                <View style={{ flexDirection: "row", width: "100%", borderRadius: 5, height: 45, backgroundColor: "#f2f2f2" }}>
                    {/* <TouchableOpacity onPress={deleteMessages} style={{ position: "absolute", right: -50, height: 45, width: 45, alignItems: "center", justifyContent: "center" }}>
                        <Ionicons size={20} color={"gray"} name={"trash"} />
                    </TouchableOpacity> */}
                    <View style={{ height: "100%", alignItems: "center", justifyContent: "center" }}>
                        <SecureImage uri={data.teacherImage} style={{ width: 30, height: 30, borderRadius: 15 }} />
                    </View>
                    <View style={{ paddingLeft: 10, justifyContent: "center", flex: 1 }}>
                        <StyledText style={{ fontFamily: "Title", fontSize: 25 }} numberOfLines={1}>{name}</StyledText>
                    </View>
                    <TouchableOpacity onPress={closeCallback} style={{ position: "absolute", right: 0, height: 45, width: 45, alignItems: "center", justifyContent: "center" }}>
                        <Ionicons size={20} color={"gray"} name={"close"} />
                    </TouchableOpacity>
                    {/* <TouchableOpacity onPress={deleteMessages} style={{ position: "absolute", right: 40, height: 45, width: 45, alignItems: "center", justifyContent: "center" }}>
                        <Ionicons size={20} color={"gray"} name={"trash"} />
                    </TouchableOpacity> */}
                </View>

                <GiftedChat
                    messages={dataMessages}
                    onSend={onSend}
                    user={{
                        _id: user,
                    }}
                    showUserAvatar={false}
                    renderAvatar={null}
                    placeholder="Escribe un mensaje..."
                    timeTextStyle={{ left: { fontSize: 10 }, right: { fontSize: 10 } }}
                    renderChatEmpty={renderChatEmpty}
                    renderBubble={renderBubble}
                    renderCustomView={renderCustomView}
                    renderSend={renderSend}
                    messageIdGenerator={v4}
                />
            </View>
        </View>
    );
}

export default TutorChat

const styles = StyleSheet.create({
    shadow: {
        shadowColor: "rgba(0, 0, 0, 0.3)",
        shadowOffset: { width: 0, height: 5 },
        shadowOpacity: 0.4,
        shadowRadius: 10,
        elevation: 10,
    }
});