import React, { useContext } from 'react';
import { TouchableOpacity, View } from 'react-native';
import AuthContext from '../context/AuthContext';
import StyledText from './common/StyledText';

const Stepper = ({ steps = 0, step, onPress, width = "100%" }) => {
    const { customization } = useContext(AuthContext)
    return (
        <View style={{ flexDirection: "row", alignSelf: "center", width: width, alignItems: "center", justifyContent: "space-evenly" }}>
            {steps.map((el, i, arr) => (
                <React.Fragment key={i}>
                    <TouchableOpacity
                        style={{
                            flex: 1,
                            paddingVertical: 10,
                            borderTopLeftRadius: i === 0 ? 7 : 0,
                            borderBottomLeftRadius: i === 0 ? 7 : 0,
                            borderTopRightRadius: i === arr.length - 1 ? 7 : 0,
                            borderBottomRightRadius: i === arr.length - 1 ? 7 : 0,
                            justifyContent: "center",
                            paddingLeft: 10,
                            borderWidth: 2,
                            borderRightWidth: el.step < arr.length - 1 ? 0 : 2,
                            borderColor: step >= el.step ? "#071e3b" : "gainsboro",
                            backgroundColor: step >= el.step ? "#071e3b" : "white"
                        }}
                        disabled={i + 1 > step}
                        onPress={() => onPress(el.step)}
                    >
                        <StyledText numberOfLines={1} style={{ fontFamily: "Regular", fontSize: 12, color: step >= el.step ? "white" : "gainsboro" }}>Paso {i + 1}</StyledText>
                        <StyledText numberOfLines={1} style={{ fontFamily: "DemiBold", fontSize: 14, color: step >= el.step ? "white" : "gainsboro" }}>{el.label}</StyledText>
                    </TouchableOpacity>
                    {/* {el + 1 < steps && <View style={{ height: 3, borderRadius: 2, flex: 1, backgroundColor: "gainsboro" }} />} */}
                </React.Fragment>
            ))}
        </View>
    )
}

export default Stepper;