import { useNavigation } from '@react-navigation/native';
import { useFirestoreQuery } from '@react-query-firebase/firestore';
import { collection, deleteDoc, doc, getDoc, getFirestore, query, runTransaction, serverTimestamp } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytes, uploadString } from 'firebase/storage';
import React, { useContext } from 'react';
import { Pressable, ScrollView, TextInput, View } from 'react-native';
import Button from '../../components/common/Button';
import SecureImage from '../../components/common/SecureImage';
import StyledText from '../../components/common/StyledText';
import AuthContext from '../../context/AuthContext';


const Exercises = () => {
    // const { userData } = useContext(AuthContext)
    const [tab, setTab] = React.useState(0)

    // const navigation = useNavigation()

    // const db = getFirestore()
    // const docRef = query(collection(db, `users/${userData.id}/exercises`))
    // const { data, isLoading } = useFirestoreQuery(["exercises", userData.id], docRef, {
    //     subscribe: true
    // }, {
    //     select: (d) => d.docs.map(x => ({ ...x.data(), id: x.id, ref: x.ref })).sort((a,b) => {
    //         const dateA = a.updatedAt.toDate()
    //         const dateB = b.updatedAt.toDate()
    //         return dateB.getTime() - dateA.getTime()
    //     })
    // })


    // const deleteExercise = async (id: string) => {
    //     const p = confirm("¿Estás seguro de que quieres eliminar este ejercicio?")
    //     if (!p) return
    //     deleteDoc(doc(db, `users/${userData.id}/exercises/${id}`)).then(res => {
    //         alert("Ejercicio eliminado correctamente")
    //     }).catch(err => {
    //         alert("Error al eliminar el ejercicio")
    //     })
    // }


    return (
        <ScrollView contentContainerStyle={{ padding: 15, gap: 10, maxWidth: 1000, alignItems: "center", width: "100%", alignSelf: "center" }}>
            <View style={{ gap: 10, flexDirection: "row", alignItems: "center", width: "100%" }}>
                <View style={{ flex: 1 }}>
                    <Button color={tab === 0 ? "#0b1831" : "#0b1831"} primary={tab === 0} width='100%' height={35} round={7} onPress={() => setTab(0)} label="Ejercicios" />
                </View>
                <View style={{ flex: 1 }}>
                    <Button color={tab === 1 ? "#0b1831" : "#0b1831"} primary={tab === 1} width='100%' height={35} round={7} onPress={() => setTab(1)} label="Sesiones" />
                </View>
                {/* <View style={{ flex: 1 }}>
                    <Button color={tab === 2 ? "#0b1831" : "#0b1831"} primary={tab === 2} width='100%' height={35} round={7} onPress={() => setTab(2)} label="Ciclos" />
                </View> */}
            </View>

            {tab === 0 ? <ExercisesTab /> : tab === 1 ? <SessionsTab /> : tab === 2 ? <CyclesTab /> : null}

        </ScrollView>
    );
}

export default Exercises;



const ExercisesTab = () => {
    const { userData } = useContext(AuthContext)

    const navigation = useNavigation()
    const storage = getStorage()

    const [duplicating, setDuplicating] = React.useState(false)
    const [search, setSearch] = React.useState("")

    const db = getFirestore()
    const docRef = query(collection(db, `users/${userData.id}/exercises`))
    const { data, isLoading } = useFirestoreQuery(["exercises", userData.id], docRef, {
        subscribe: true
    }, {
        select: (d) => d.docs.map(x => ({ ...x.data(), id: x.id, ref: x.ref }))
    })


    const deleteData = async (id: string) => {
        const p = confirm("¿Estás seguro de que quieres eliminar este ejercicio?")
        if (!p) return
        deleteDoc(doc(db, `users/${userData.id}/exercises/${id}`)).then(res => {
            alert("Ejercicio eliminado correctamente")
        }).catch(err => {
            alert("Error al eliminar el ejercicio")
        })
    }

    const duplicateData = async (id: string): Promise<string | null> => {
        try {
            setDuplicating(true)
            const confirmed = confirm("¿Estás seguro de que quieres duplicar este ejercicio?");
            if (!confirmed) return null;

            const docRef = doc(db, `users/${userData.id}/exercises/${id}`);
            const docSnap = await getDoc(docRef);

            if (!docSnap.exists()) {
                console.error("Document does not exist");
                return null;
            }

            const data = docSnap.data();
            console.log("Original data:", data);

            const newExerciseRef = doc(collection(db, `users/${userData.id}/exercises`));
            const newId = newExerciseRef.id;

            const newData = {
                ...data,
                name: `${data.name} (duplicado)`,
                createdAt: serverTimestamp(),
            };

            const result = await runTransaction(db, async (transaction) => {
                const snapshotPath = data.snapshot ? await duplicateFile(data.snapshot, `users/${userData.id}/exercises/${newId}.json`, true) : null;
                const thumbnailPath = data.thumbnail ? await duplicateFile(data.thumbnail, `users/${userData.id}/exercises/${newId}.png`, false) : null;

                if (snapshotPath) newData.snapshot = snapshotPath;
                if (thumbnailPath) newData.thumbnail = thumbnailPath;

                transaction.set(newExerciseRef, newData);

                return { success: true, newId };
            });

            if (result.success) {
                console.log("Exercise duplicated successfully");
                return result.newId;
            } else {
                console.error("Transaction failed");
                return null;
            }
        } catch (error) {
            console.error("Error duplicating exercise:", error);
            return null;
        } finally {
            setDuplicating(false);
        }
    };

    const duplicateFile = async (path: string, newPath: string, isJson: boolean): Promise<string | null> => {
        try {
            const fileRef = ref(storage, path);
            const url = await getDownloadURL(fileRef);
            const response = await fetch(url);

            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

            const newFileRef = ref(storage, newPath);

            if (isJson) {
                const jsonData = await response.json();
                await uploadString(newFileRef, JSON.stringify(jsonData));
            } else {
                const blob = await response.blob();
                await uploadBytes(newFileRef, blob);
            }

            return newPath;
        } catch (error) {
            console.error(`Error duplicating file (${path}):`, error);
            return null;
        }
    };

    const filteredExercises = React.useMemo(() => {
        if (!search) return data
        return data?.filter(x => x?.name?.toLowerCase().includes(search.toLowerCase()))
    }, [data, search])


    return (
        <>
            <View style={{ flexDirection: "row", alignItems: "center", width: "100%", gap: 10 }}>
                <View style={{ flex: 1 }}>
                    <StyledText style={{ fontFamily: "TitleWide", fontSize: 24, fontWeight: "bold" }}>Mis Ejercicios</StyledText>
                </View>
                <TextInput value={search} onChangeText={ev => setSearch(ev)} style={{ borderWidth: 1, borderColor: "gainsboro", flex: 1, minWidth: 200, borderRadius: 5, backgroundColor: "white", paddingHorizontal: 15, alignItems: "center", height: 35 }} placeholder="Buscar..." />
                <Button height={35} round={7} onPress={() => navigation.navigate("Exercise", { id: "new" })} label="Nuevo Ejercicio" />
            </View>
            {filteredExercises?.length === 0 ?
                <View style={{ padding: 100, gap: 15, alignItems: "center", justifyContent: "center", width: "100%", borderRadius: 8, backgroundColor: "#f3f3f3" }}>
                    <StyledText>No hay ejercicios</StyledText>
                    <Button height={35} round={7} onPress={() => navigation.navigate("Exercise", { id: "new" })} label="Nuevo Ejercicio" />
                </View>
                :
                filteredExercises?.map((ex: any) => (
                    <Pressable
                        key={ex.id}
                        onPress={() => navigation.navigate("Exercise", { id: ex.id })}
                        style={{ width: "100%", padding: 15, borderRadius: 7, backgroundColor: "white", flexDirection: "row", alignItems: "center", justifyContent: "space-between", gap: 10, shadowColor: "#000", shadowOffset: { width: 0, height: 5 }, shadowOpacity: 0.15, shadowRadius: 10, elevation: 5 }}
                    >
                        <View style={{ flexDirection: "row", alignItems: "center", gap: 10 }}>
                            <SecureImage uri={ex.thumbnail} style={{ width: 100, height: 60, borderRadius: 8 }} />
                            <StyledText style={{ fontSize: 18 }}>{ex.name}</StyledText>
                        </View>
                        <View style={{ flexDirection: "row", gap: 10 }}>
                            <Button primary={false} height={35} round={7} onPress={() => navigation.navigate("Exercise", { id: ex.id })} label="Ver" />
                            <Button iconSize={18} height={35} paddingHorizontal={5} round={7} onPress={() => duplicateData(ex.id)} icon="copy" color={"#0B1831"} loading={duplicating} disabled={duplicating} />
                            <Button iconSize={18} height={35} paddingHorizontal={5} round={7} onPress={() => deleteData(ex.id)} icon="trash" />
                        </View>
                    </Pressable>
                ))}
        </>
    )
}

const SessionsTab = () => {
    const { userData } = useContext(AuthContext)

    const navigation = useNavigation()
    const db = getFirestore()
    const docRef = query(collection(db, `users/${userData.id}/sessions`))
    const { data, isLoading } = useFirestoreQuery(["sessions", userData.id], docRef, {
        subscribe: true
    }, {
        select: (d) => d.docs.map(x => ({ ...x.data(), id: x.id, ref: x.ref })).sort((a, b) => {
            const dateA = a.updatedAt.toDate()
            const dateB = b.updatedAt.toDate()
            return dateB.getTime() - dateA.getTime()
        })
    })


    const deleteData = async (id: string) => {
        const p = confirm("¿Estás seguro de que quieres eliminar esta sesión?")
        if (!p) return
        deleteDoc(doc(db, `users/${userData.id}/sessions/${id}`)).then(res => {
            alert("Sesión eliminado correctamente")
        }).catch(err => {
            alert("Error al eliminar la sesión")
        })
    }

    return (
        <>
            <View style={{ flexDirection: "row", alignItems: "center", width: "100%" }}>
                <View style={{ flex: 1 }}>
                    <StyledText style={{ fontFamily: "TitleWide", fontSize: 24, fontWeight: "bold" }}>Mis Sesiones</StyledText>
                </View>
                <Button height={35} round={7} onPress={() => navigation.navigate("Sessions", { id: "new" })} label="Nueva Sesión" />
            </View>
            {data?.length === 0 ?
                <View style={{ padding: 100, gap: 15, alignItems: "center", justifyContent: "center", width: "100%", borderRadius: 8, backgroundColor: "#f3f3f3" }}>
                    <StyledText>No hay sesiones</StyledText>
                    <Button height={35} round={7} onPress={() => navigation.navigate("Sessions", { id: "new" })} label="Nueva Sesión" />
                </View>
                :
                data?.map((ex: any) => (
                    <Pressable
                        key={ex.id}
                        onPress={() => navigation.navigate("Sessions", { id: ex.id })}
                        style={{ width: "100%", padding: 15, borderRadius: 7, backgroundColor: "white", flexDirection: "row", alignItems: "center", justifyContent: "space-between", gap: 10, shadowColor: "#000", shadowOffset: { width: 0, height: 5 }, shadowOpacity: 0.15, shadowRadius: 10, elevation: 5 }}
                    >
                        <View style={{ flexDirection: "row", alignItems: "center", gap: 10 }}>
                            {/* <SecureImage uri={ex.thumbnail} style={{ width: 100, height: 60, borderRadius: 8 }} /> */}
                            <StyledText style={{ fontSize: 18 }}>{ex.name}</StyledText>
                        </View>
                        <View style={{ flexDirection: "row", gap: 10 }}>
                            <Button height={35} round={7} onPress={() => navigation.navigate("Sessions", { id: ex.id })} label="Ver" />
                            <Button iconSize={18} height={35} paddingHorizontal={5} round={7} onPress={() => deleteData(ex.id)} icon="trash" />
                        </View>
                    </Pressable>
                ))}
        </>
    )
}

const CyclesTab = () => {

    const { userData } = useContext(AuthContext)

    const navigation = useNavigation()
    const db = getFirestore()
    const docRef = query(collection(db, `users/${userData.id}/cycles`))
    const { data, isLoading } = useFirestoreQuery(["cycles", userData.id], docRef, {
        subscribe: true
    }, {
        select: (d) => d.docs.map(x => ({ ...x.data(), id: x.id, ref: x.ref })).sort((a, b) => {
            const dateA = a.updatedAt.toDate()
            const dateB = b.updatedAt.toDate()
            return dateB.getTime() - dateA.getTime()
        })
    })


    const deleteData = async (id: string) => {
        const p = confirm("¿Estás seguro de que quieres eliminar este ciclo?")
        if (!p) return
        deleteDoc(doc(db, `users/${userData.id}/cycles/${id}`)).then(res => {
            alert("Ciclo eliminado correctamente")
        }).catch(err => {
            alert("Error al eliminar el ciclo")
        })
    }

    return (
        <>
            <View style={{ flexDirection: "row", alignItems: "center", width: "100%" }}>
                <View style={{ flex: 1 }}>
                    <StyledText style={{ fontFamily: "TitleWide", fontSize: 24, fontWeight: "bold" }}>Mis ciclos</StyledText>
                </View>
                <Button height={35} round={7} onPress={() => navigation.navigate("Cycles", { id: "new" })} label="Nuevo Ciclo" />
            </View>

            {data?.length === 0 ?
                <View style={{ padding: 100, gap: 15, alignItems: "center", justifyContent: "center", width: "100%", borderRadius: 8, backgroundColor: "#f3f3f3" }}>
                    <StyledText>No hay sesiones</StyledText>
                    <Button height={35} round={7} onPress={() => navigation.navigate("Cycles", { id: "new" })} label="Nuevo Ciclo" />
                </View>
                :
                data?.map((ex: any) => (
                    <Pressable
                        key={ex.id}
                        onPress={() => navigation.navigate("Cycles", { id: ex.id })}
                        style={{ width: "100%", padding: 15, borderRadius: 7, backgroundColor: "white", flexDirection: "row", alignItems: "center", justifyContent: "space-between", gap: 10, shadowColor: "#000", shadowOffset: { width: 0, height: 5 }, shadowOpacity: 0.15, shadowRadius: 10, elevation: 5 }}
                    >
                        <View style={{ flexDirection: "row", alignItems: "center", gap: 10 }}>
                            <SecureImage uri={ex.thumbnail} style={{ width: 100, height: 60, borderRadius: 8 }} />
                            <StyledText style={{ fontSize: 18 }}>{ex.name}</StyledText>
                        </View>
                        <View style={{ flexDirection: "row", gap: 10 }}>
                            <Button height={35} round={7} onPress={() => navigation.navigate("Cycles", { id: ex.id })} label="Ver" />
                            <Button iconSize={18} height={35} paddingHorizontal={5} round={7} onPress={() => deleteData(ex.id)} icon="trash" />
                        </View>
                    </Pressable>
                ))}
        </>
    )
}