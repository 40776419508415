export const getStatusName = (status) => {
    switch (status) {
        case "CONVALIDATED":
            return "Convalidado"
        case "NOTRATED":
            return "No Evaluado"
        case "FAILED":
            return "No Apto"
        case "APPROVED":
            return "Apto"
        default:
            return ""
    }
}

export const getStatusColor = (status) => {
    switch (status) {
        case "CONVALIDATED":
            return "#0b1831"
        case "NOTRATED":
            return "gray"
        case "FAILED":
            return "#e4002a"
        case "APPROVED":
            return "#0b1831"
        default:
            return ""
    }
}