import React from "react";
// import CheckBoxField from "./CheckBoxField";
import SelectField from "./SelectField";
import TextField from "./TextField";
// import CustomFormField from "./CustomFormField";
import { useFirestoreDocument } from "@react-query-firebase/firestore";
import { doc, getFirestore } from "firebase/firestore";
import CheckBoxField from "./CheckBoxField";
import FileField from "./FileField";

const MultiField = ({ type, required, name, route, customRef, editable = true, error, ...props }) => {

    const db = getFirestore()
    const docRef = customRef ? customRef?.path ? customRef : doc(db, customRef) : null;
    const { data } = useFirestoreDocument([name], docRef, {}, {
        enabled: !!docRef,
        select: (d) => d.exists() ? ({ ...d.data(), id: d.id }) : null
    });

    if (type === "text") {
        return (
            <TextField
                error={error}
                size="small"
                type="text"
                name={name}
                required={required}
                {...props}
            />
        );
    } else if (type === "textarea") {
        return (
            <TextField
                error={error}
                style={{ minHeight: 200 }}
                multiline={true}
                size="small"
                name={name}
                resize="vertical"
                required={required}
                {...props}
            />
        );
    } else if (type.includes("document")) {
        return (
            <FileField
                error={error}
                name={name}
                route={route}
                minimal={false}
                required={required}
                editable={editable}
                {...props}
            />
        );
    } else if (type === "number") {
        return (
            <TextField
                error={error}
                size="small"
                name={name}
                type="number"
                required={required}
                {...props}
            />
        );
    } else if (type === "boolean") {
        return (
            <CheckBoxField
                size="small"
                error={error}
                name={name}
                required={required}
                {...props}
            />
        );
    } else if (type === "date") {
        return (
            <TextField
                error={error}
                size="small"
                type="date"
                name={name}
                required={required}
                {...props}
            />
        );
    } else if (type === "datetime") {
        return (
            <TextField
                error={error}
                size="small"
                type="datetime-local"
                name={name}
                required={required}
                {...props}
            />
        );
    } else if (type === "time") {
        return (
            <TextField
                error={error}
                size="small"
                type="time"
                name={name}
                required={required}
                {...props}
            />
        );
    } else if (type.includes("selector")) {
        return (
            <SelectField
                error={error}
                size="small"
                type="selector"
                name={name}
                required={required}
                options={data?.options || []}
                placeholder={data?.name || props?.placeholder}
                {...props}
            />
        );
    } else if (type.includes("form")) {
        return (
            null
            // <CustomFormField
            //     size="small"
            //     name={name}
            //     // key={key}
            //     form={customData}
            //     setValue={setValue}
            //     {...props}
            // />
        );
    } else {
        return (
            <TextField
                error={error}
                size="small"
                name={name}
                required={required}
                type="text"
                {...props}
            />
        );
    }
};

export default MultiField;
