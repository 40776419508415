import React, { useCallback } from 'react';


// import Notifications from "../screens/stackContent/Notifications"
import { createStackNavigator } from '@react-navigation/stack';
import HeaderWeb from '../components/HeaderWeb';
import CoachesCourses from '../screens/stackPublic/CoachesCourses';
import CoachesCoursesSingle from '../screens/stackPublic/CoachesCoursesSingle';
import Contact from '../screens/stackPublic/Contact';
import DownloadPage from '../screens/stackPublic/DownloadPage';
import GlobalPage from '../screens/stackPublic/GlobalPage';
import HomeScreen from '../screens/stackPublic/HomeScreen';
import PaymentRedirectScreen from '../screens/stackPublic/PaymentRedirect';
import PreviewCourseScreen from '../screens/stackPublic/PreviewCourseScreen';
import Process from '../screens/stackPublic/Process';
import QualityForm from '../screens/stackPublic/QualityForm';
import Recognition from '../screens/stackPublic/Recognition';
import ReevaluationUefaPro from '../screens/stackPublic/ReevaluationUEFAPro';
import RfefPrograms from '../screens/stackPublic/RfefPrograms';
import UniversityPrograms from '../screens/stackPublic/UniversityPrograms';
import VirtualReevaluation from '../screens/stackPublic/VirtualReevaluation';

const Stack = createStackNavigator();

const StackPublic = ({ route, navigation }) => {
	const header = useCallback(() => <HeaderWeb />, [])
	return (
		<Stack.Navigator
			screenOptions={{
				title: "RFEF",
				cardStyle: { flex: 1 },
				header
			}}>
			<Stack.Screen options={{ title: "Inicio" }} name="HomeScreen" component={HomeScreen} />
			<Stack.Screen options={{ title: "Programas RFEF" }} name="RfefPrograms" component={RfefPrograms} />
			<Stack.Screen options={{ title: "Curso" }} name="PreviewCourseScreen" component={PreviewCourseScreen} />
			<Stack.Screen options={{ title: "Escuela de Entrenadores" }} name="CoachesSchool" component={CoachesCourses} />
			<Stack.Screen options={{ title: "Escuela de Entrenadores" }} name="SingleCoachesSchool" component={CoachesCoursesSingle} />
			<Stack.Screen options={{ title: "Sello de Calidad" }} name="QualityForm" component={QualityForm} />
			<Stack.Screen options={{ title: "Reconocimiento de Competencias" }} name="Recognition" component={Recognition} />
			<Stack.Screen options={{ title: "Programas Universitarios" }} name="UniversityPrograms" component={UniversityPrograms} />
			<Stack.Screen name="GlobalPage" component={GlobalPage} />
			<Stack.Screen options={{ title: "Descargas" }} name="DownloadPage" component={DownloadPage} />
			<Stack.Screen options={{ title: "Contacto" }} name="Contact" component={Contact} />
			<Stack.Screen options={{ title: "Procesando Pago" }} name="PaymentRedirect" component={PaymentRedirectScreen} />
			<Stack.Screen options={{ title: "Proceso de Solicitud de Licencias" }} name="Process" component={Process} />
			<Stack.Screen options={{ title: "Reevaluación UEFA PRO 2024" }} name="ReevaluationUefaPro" component={ReevaluationUefaPro} />
			<Stack.Screen options={{ title: "Revaluación Virtual" }} name="VirtualReevaluation" component={VirtualReevaluation} />
			{/* <Stack.Screen name="ContentHome" component={ContentHome} />
            <Stack.Screen name="Post" component={Post} options={{ title: "Contenido" }} />
            <Stack.Screen name="Comments" component={Comments} options={{ title: "Comentarios" }} />
            <Stack.Screen name="Category" component={Category} options={{ title: "Categoría" }} />
            <Stack.Screen name="Search" component={Search} options={{header: () => <View />}}/>
            <Stack.Screen name="Notifications" component={Notifications} /> */}
		</Stack.Navigator>
	);
}


export default StackPublic;
