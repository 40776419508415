
export default function makeCecabankPayment({ formData }) {
    const form = document.createElement("form")
    const input = document.createElement("input")
    input.setAttribute("type", "submit")
    form.appendChild(input)

    console.log("cacabank form data", formData)

    form.setAttribute("method", "POST")
    form.setAttribute("enctype", "application/x-www-form-urlencoded")
    form.setAttribute("action", formData.action)
    form.setAttribute("style", "display: none;")
    // form.setAttribute("TARGET", "_self")

    const merchantIdINPUT = document.createElement("input")
    merchantIdINPUT.setAttribute("type", "hidden")
    merchantIdINPUT.setAttribute("name", "MerchantID")
    merchantIdINPUT.setAttribute("value", `${formData.MerchantID}`)
    form.appendChild(merchantIdINPUT)

    const acquirerBININPUT = document.createElement("input")
    acquirerBININPUT.setAttribute("type", "hidden")
    acquirerBININPUT.setAttribute("name", "AcquirerBIN")
    acquirerBININPUT.setAttribute("value", `${formData.AcquirerBIN}`)
    form.appendChild(acquirerBININPUT)

    const terminalIdINPUT = document.createElement("input")
    terminalIdINPUT.setAttribute("type", "hidden")
    terminalIdINPUT.setAttribute("name", "TerminalID")
    terminalIdINPUT.setAttribute("value", `${formData.TerminalID}`)
    form.appendChild(terminalIdINPUT)

    const urlOkINPUT = document.createElement("input")
    urlOkINPUT.setAttribute("type", "hidden")
    urlOkINPUT.setAttribute("name", "URL_OK")
    urlOkINPUT.setAttribute("value", `${formData.URL_OK}`)
    form.appendChild(urlOkINPUT)

    const urlNokINPUT = document.createElement("input")
    urlNokINPUT.setAttribute("type", "hidden")
    urlNokINPUT.setAttribute("name", "URL_NOK")
    urlNokINPUT.setAttribute("value", `${formData.URL_NOK}`)
    form.appendChild(urlNokINPUT)

    const firmaINPUT = document.createElement("input")
    firmaINPUT.setAttribute("type", "hidden")
    firmaINPUT.setAttribute("name", "Firma")
    firmaINPUT.setAttribute("value", `${formData.Firma}`)
    form.appendChild(firmaINPUT)

    const cifradoINPUT = document.createElement("input")
    cifradoINPUT.setAttribute("type", "hidden")
    cifradoINPUT.setAttribute("name", "Cifrado")
    cifradoINPUT.setAttribute("value", `${formData.Cifrado}`)
    form.appendChild(cifradoINPUT)

    const orderIdINPUT = document.createElement("input")
    orderIdINPUT.setAttribute("type", "hidden")
    orderIdINPUT.setAttribute("name", "Num_operacion")
    orderIdINPUT.setAttribute("value", `${formData.Num_operacion}`)
    form.appendChild(orderIdINPUT)

    const amountINPUT = document.createElement("input")
    amountINPUT.setAttribute("type", "hidden")
    amountINPUT.setAttribute("name", "Importe")
    amountINPUT.setAttribute("value", `${formData.Importe}`)
    form.appendChild(amountINPUT)

    const currencyINPUT = document.createElement("input")
    currencyINPUT.setAttribute("type", "hidden")
    currencyINPUT.setAttribute("name", "TipoMoneda")
    currencyINPUT.setAttribute("value", `${formData.TipoMoneda}`)
    form.appendChild(currencyINPUT)

    const exponentINPUT = document.createElement("input")
    exponentINPUT.setAttribute("type", "hidden")
    exponentINPUT.setAttribute("name", "Exponente")
    exponentINPUT.setAttribute("value", `${formData.Exponente}`)
    form.appendChild(exponentINPUT)

    const pagoSoportadoINPUT = document.createElement("input")
    pagoSoportadoINPUT.setAttribute("type", "hidden")
    pagoSoportadoINPUT.setAttribute("name", "Pago_soportado")
    pagoSoportadoINPUT.setAttribute("value", `${formData.Pago_soportado}`)
    form.appendChild(pagoSoportadoINPUT)

    const idiomaINPUT = document.createElement("input")
    idiomaINPUT.setAttribute("type", "hidden")
    idiomaINPUT.setAttribute("name", "Idioma")
    idiomaINPUT.setAttribute("value", `${formData.Idioma}`)
    form.appendChild(idiomaINPUT)

    // const descriptionInput = document.createElement("input")
    // descriptionInput.setAttribute("type", "hidden")
    // descriptionInput.setAttribute("name", "Clave_encriptacion")
    // descriptionInput.setAttribute("value", `${formData.Clave_encriptacion}`)
    // form.appendChild(descriptionInput)


    // Object.keys(omit(formData, "action")).forEach(key => {
    //     const INPUT = document.createElement("INPUT")
    //     INPUT.setAttribute("TYPE", "hidden")
    //     INPUT.setAttribute("NAME", key)
    //     INPUT.setAttribute("VALUE", `${formData[key]}`)
    //     form.appendChild(INPUT)
    // })

    document.body.appendChild(form)
    console.log(form)

    form.submit()

    document.body.removeChild(form)
} 