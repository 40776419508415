import { useNavigation } from "@react-navigation/native";
import { useFirestoreQueryData } from "@react-query-firebase/firestore";
import { collection, getFirestore, query, where } from "firebase/firestore";
import React, { useContext } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import Footer from "../../components/common/Footer";
import StyledText from "../../components/common/StyledText";
import AuthContext from "../../context/AuthContext";
const GlobalPage = ({ route }) => {
	const navigation = useNavigation()
	const { customization } = useContext(AuthContext)
	const db = getFirestore()
	const { slug } = route.params
	const docRef = query(collection(db, `globalPages`), where("slug", "==", slug));
	const { data, isLoading, error } = useFirestoreQueryData(["globalPage", { slug }], docRef, {}, {
		select: data => data[0],
		onSuccess: data => navigation.setOptions({ title: data.title })
	});


	if (isLoading) return null
	if (error) return <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
		<StyledText style={{ fontSize: 90, fontFamily: "Title" }}>
			Error Cargando la página
		</StyledText>
	</View>
	if (!data) return <>
		<View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
			<StyledText style={{ fontSize: 90, fontFamily: "Title" }}>
				Esta página no existe
			</StyledText>
		</View>
		<Footer />
	</>
	return (
		<>
			<ScrollView style={{ flex: 1, backgroundColor: "white" }} contentContainerStyle={{ flexGrow: 1 }}>
				{/* SLIDER TOP */}
				<View style={{ height: 150, backgroundColor: customization.mainColor, borderBottomWidth: 1, borderBottomColor: "white" }}>
					{/* <Image source={{ uri: "https://images.pexels.com/photos/274506/pexels-photo-274506.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" }} style={{ position: "absolute", resizeMode: "cover", top: 0, left: 0, right: 0, bottom: 0 }} /> */}
					{/* <LinearGradient start={{ x: 0, y: 0 }} end={{ x: 0, y: -1 }} colors={["rgba(0,0,0,.6)", "transparent"]} style={{ position: "absolute", resizeMode: "cover", top: 0, left: 0, right: 0, bottom: 0 }}></LinearGradient> */}
					<View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
						<StyledText style={{ fontSize: 60, color: "white", fontFamily: "Title" }}>
							{data?.title}
						</StyledText>
					</View>
				</View>
				<View style={{ height: 10, width: "100%", backgroundColor: "#003349" }} />

				<View style={{ paddingVertical: 15, flexGrow: 1, maxWidth: 1000, alignSelf: "center", width: "100%", paddingHorizontal: 20, }}>
					<div dangerouslySetInnerHTML={{ __html: data?.content || "<p></p>" }} style={{ fontFamily: "Regular", fontSize: 18, color: "#303030" }} />
					{/* <HTML
						baseFontStyle={{ fontFamily: "Regular", fontSize: 18, color: "#303030", }}
						source={{ html: data?.content ? data?.content : "<p></p>" }}
						imagesMaxWidth={800}
						staticContentMaxWidth={800}
						onLinkPress={async (evt, href) => Platform.OS === "web" ? window.open(href, "_blank") : await WebBrowser.openBrowserAsync(href.toLowerCase().includes("://") ? href : `https://${href}`)}
					/> */}
				</View>

				<Footer />
			</ScrollView>
		</>
	);
};

export default GlobalPage;


const styles = StyleSheet.create({
	wrapper: {},
	slide1: {
		flex: 1,
	},
	slide2: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "#97CAE5",
	},
	slide3: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "#92BBD9",
	},
	text: {
		color: "#fff",
		fontSize: 30,
		fontWeight: "bold",
	},
	shadow: {
		shadowColor: "rgba(0, 0, 0, 1)",
		shadowOffset: { width: 0, height: 7 },
		shadowOpacity: 0.1,
		shadowRadius: 10,
		elevation: 10,
	},
});
