const swapIcon = (iconName) => {
    switch (iconName) {
        case "TEXT":
            return { icon: "book-outline", name: "Lectura" };
        case "LINK":
            return { icon: "link-outline", name: "Enlace" };
        case "VIDEO":
            return { icon: "videocam-outline", name: "Video" };
        case "EMBEDDED":
            return { icon: "logo-youtube", name: "Youtube" };
        case "AUDIO":
            return { icon: "ear-outline", name: "Audio" };
        case "WEBINAR":
            return { icon: "desktop-sharp", name: "Webinar" };
        case "EVENT":
            return { icon: "calendar-sharp", name: "Evento" };
        case "UPLOAD":
            return { icon: "cloud-upload", name: "Subir" };
        case "TEST":
            return { icon: "document-text", name: "Encuesta" };
        case "EXAM":
            return { icon: "list", name: "Cuestionario" };
        case "DOCUMENT":
            return { icon: "document", name: "Lectura" };
        case "FORUM":
            return { icon: "people", name: "Participar en el foro" };
        default:
            return { icon: "medal", name: "Cuestionario" };
    }
};

export default swapIcon;