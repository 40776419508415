import React, { useContext, useEffect, useRef, useState } from 'react';

import { Ionicons } from '@expo/vector-icons';
import { Video } from 'expo-av';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import * as Animated from 'react-native-animatable';
import AuthContext from '../../context/AuthContext';
import CircularIndicator from '../CircularIndicator';


const AudioPlayer = ({ source, cover = "", autoplay = false, onPlaybackStatusUpdate, videoProps, playback, millis }) => {

    const soundObject = useRef()
    const { customization } = useContext(AuthContext)
    const [playing, setPLaying] = useState(autoplay)
    const [duration, setDuration] = useState(null)
    const [position, setPosition] = useState(0)

    const [deg, setDeg] = useState(0)
    const imageRef = useRef(null)

    const _getMMSSFromMillis = (millis) => {
        const totalSeconds = millis / 1000;
        const seconds = Math.floor(totalSeconds % 60);
        const minutes = Math.floor(totalSeconds / 60);

        const padWithZero = number => {
            const string = number.toString();
            if (number < 10) {
                return "0" + string;
            }
            return string;
        };
        return padWithZero(minutes) + ":" + padWithZero(seconds);
    }

    useEffect(() => {
        playing && imageRef.current.animate(rotate)
    }, [deg])

    useEffect(() => {
        soundObject && soundObject.current.setPositionAsync(millis)
    }, [millis])

    const toogleAudio = () => {
        !playing ? soundObject.current.playAsync() : soundObject.current.pauseAsync()
        setPLaying(!playing)
    }

    const goBackwards = (s = 10000) => {
        soundObject.current.setPositionAsync(position - s)
    }

    const goForward = (s = 10000) => {
        soundObject.current.setPositionAsync(position + s)
    }

    const playbackUp = (s) => {
        if (s.isLoaded) {
            setPosition(s.positionMillis)
            setDuration(s.durationMillis)
            if (s.isPlaying) {
                setDeg(s.positionMillis / 500)
            } else if (s.didJustFinish) {
                setPosition(0)
                soundObject.current.setPositionAsync(0)
                setPLaying(false)
            }
        }
    }

    const rotate = {
        0: {
            rotate: `${deg}deg`
        },
        1: {
            rotate: `${deg + 2}deg`
        },
        easing: "linear"
    }



    return (
        <View style={{ width: "100%", height: 330, alignItems: "center", justifyContent: "center" }}>
            <View style={{ width: 250, height: 250 }}>
                <Video
                    style={{ display: "none" }}
                    shouldPlay={autoplay}
                    useNativeControls={true}
                    resizeMode="contain"
                    onPlaybackStatusUpdate={(ev) => {
                        onPlaybackStatusUpdate(ev)
                        playbackUp(ev)
                    }}
                    progressUpdateIntervalMillis={1000}
                    ref={soundObject}
                    source={{ uri: source }}
                    {...videoProps}
                />
                <Animated.View useNativeDriver={true} style={{ height: 250, width: 250 }} ref={imageRef} >
                    <Image style={{ position: "absolute", top: 0, left: 0, height: "100%", width: "100%", borderRadius: 175 }} source={{ uri: cover }} />
                </Animated.View>

                {duration && <CircularIndicator transitionSpeed={100} backgroundColor="gainsboro" style={{ position: "absolute", top: -3, left: -3 }} strokeColor={customization.mainColor} strokeWidth={5} r={125} percentage={position / duration * 100} />}


                <View style={{ flexDirection: "row", justifyContent: "center", position: "absolute", top: 100, alignItems: "center", width: "100%" }}>
                    <TouchableOpacity onLongPress={() => goBackwards(20000)} onPress={() => goBackwards()} style={{ ...styles.shadow, alignItems: "center", justifyContent: "center", height: 40, width: 40, borderRadius: 20, backgroundColor: "white" }}>
                        <Ionicons size={20} color={"black"} name={"play-back"} />
                        {/* <Text style={{ fontFamily: "DemiBold", fontSize: 7 }}>-10s</Text> */}
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => toogleAudio()} style={{ ...styles.shadow, marginHorizontal: 10, alignItems: "center", justifyContent: "center", height: 60, width: 60, borderRadius: 30, backgroundColor: "white" }}>
                        <Ionicons style={{ top: 2, left: playing ? 1 : 3 }} size={30} color={customization.mainColor} name={!playing ? "md-play" : "md-pause"} />
                    </TouchableOpacity>
                    <TouchableOpacity onLongPress={() => goForward(20000)} onPress={() => goForward()} style={{ ...styles.shadow, alignItems: "center", justifyContent: "center", height: 40, width: 40, borderRadius: 20, backgroundColor: "white" }}>
                        <Ionicons size={20} color={"black"} name={"play-forward"} />
                    </TouchableOpacity>
                </View>
            </View>
            <Text style={{ marginTop: 10, fontFamily: "DemiBold", fontSize: 18 }}>{_getMMSSFromMillis(position)} - {_getMMSSFromMillis(duration)}</Text>
        </View>
    );
}

export default AudioPlayer;

const styles = StyleSheet.create({
    shadow: {
        shadowColor: "rgba(0, 0, 0, 0.3)",
        shadowOffset: { width: 0, height: 5 },
        shadowOpacity: 0.4,
        shadowRadius: 10,
        elevation: 10,
    }
});