import { Entypo, Ionicons } from '@expo/vector-icons';
import { useFirestoreDocumentData } from '@react-query-firebase/firestore';
import { getApp } from 'firebase/app';
import { arrayUnion, collection, doc, DocumentReference, getFirestore, serverTimestamp, setDoc, updateDoc } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions, httpsCallable } from 'firebase/functions';
import { get, last } from 'lodash';
import moment from 'moment';
import React, { useContext } from 'react';
import { useForm } from "react-hook-form";
import { ScrollView, TouchableOpacity, useWindowDimensions, View } from 'react-native';
import { useQuery } from 'react-query';
import { useMediaQuery } from 'react-responsive';
import * as yup from 'yup';
import TextField from "../components/formComponents/TextField";
import AuthContext from '../context/AuthContext';
import fixDate from '../utils/fixDate';
import useYupValidationResolver from "../utils/useYupValidationResolver";
import Button from './common/Button';
import Modal from './common/Modal';
import StyledText from './common/StyledText';

const ClassModal = ({ data, advanceRef, openCallback, onClose }) => {
  const db = getFirestore();

  const sh = useWindowDimensions().height;

  const isPhone = useMediaQuery({ query: '(max-width: 1000px)' })
  const { userData } = useContext(AuthContext);

  const tenantId = get(advanceRef, "parent.parent.parent.parent.id")

  const colRef = collection(db, `${advanceRef?.path}/courseavs/${userData.id}/attendance`)
  const docRef = doc(db, `${colRef?.path}/${data.id}`)

  const { data: attendanceData } = useFirestoreDocumentData(["advance", advanceRef.id, "attendance", data?.id], docRef, {
    subscribe: true
  }, {
    enabled: !!data?.id,
    refetchOnMount: "always",
    onError: e => console.log(e)
  })

  const validationSchema = yup.object().shape({
    pinStart: yup.number().typeError("Introduce el PIN para continuar").required(),
    pinEnd: yup.number().typeError("Introduce el PIN para continuar").required(),
  });


  const resolver = useYupValidationResolver(validationSchema);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver,
    defaultValues: {
      pinStart: 0,
      pinEnd: 0
    },
  });

  function isClassInTime(lowerBound, upperBound) {
    const timestamp = new Date().getTime();

    const start = moment(lowerBound.toDate ? lowerBound.toDate() : moment(lowerBound).toDate())
    const end = moment(upperBound.toDate ? upperBound.toDate() : moment(upperBound).toDate())

    const endTime = start.toDate().getTime()
    const startTime = end.toDate().getTime()

    const lowerBoundWithExtraHour = start.subtract(1, "hour").toDate().getTime();
    const upperBoundWithExtraHour = end.add(1, "hour").toDate().getTime();

    const isBefore = timestamp < lowerBoundWithExtraHour;
    const isAfter = timestamp > upperBoundWithExtraHour;
    const isValidStart = timestamp > lowerBoundWithExtraHour && timestamp < endTime
    const isValidEnd = timestamp < upperBoundWithExtraHour && timestamp > startTime
    const isValid = (timestamp > lowerBoundWithExtraHour) && (timestamp < upperBoundWithExtraHour);

    return { isBefore, isAfter, isValidStart, isValidEnd, isValid, lowerBoundWithExtraHour };
  }

  const { isValid } = isClassInTime(data?.start, data?.end);

  const handleValidatePin = async (value) => {
    if (!attendanceData) {
      if (value.pinStart === data.pinStart) {
        await setDoc(docRef, {
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
          userId: userData.id,
          entries: [
            {
              createdAt: new Date(),
              type: true,
            },
          ],
        });
        alert("Entrada registrada correctamente")
      } else {
        alert('El PIN de entrada incorrecto')
      }
    } else {
      const lastElement = last(attendanceData.entries)?.type
      if (lastElement) {
        if (value.pinEnd === data.pinEnd) {
          await updateDoc(docRef, {
            updatedAt: serverTimestamp(),
            userId: userData.id,
            entries: arrayUnion({
              createdAt: new Date(),
              type: false,
            }),
          });
          alert("Salida registrada correctamente")
        } else {
          alert('El PIN de salida incorrecto')
        }
      } else {
        if (value.pinStart === data.pinStart) {
          await updateDoc(docRef, {
            updatedAt: serverTimestamp(),
            userId: userData.id,
            entries: arrayUnion({
              createdAt: new Date(),
              type: true,
            }),
          });
          alert("Entrada registrada correctamente")
        } else {
          alert('El PIN de entrada incorrecto')
        }
      }
    }
    return;
  };


  const isStart = !attendanceData?.entries || last(attendanceData?.entries || [])?.type === false


  return (
    <Modal
      onClickOutside={onClose}
      modalContainerStyle={{ width: "95%", maxWidth: 900 }}
      padding={0}
    >
      <ScrollView
        style={{ maxHeight: sh - 110, flex: 1 }}
        contentContainerStyle={{
          paddingTop: 20,
          paddingHorizontal: 15,
          flexGrow: 1,
        }}
      >
        <View
          style={{
            minWidth: "80%",
            maxWidth: 900,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <View style={{ width: "100%" }}>
            <View style={{ marginBottom: 20, flexDirection: "row" }}>
              {checkEndDate(data?.start?.toDate ? data?.start?.toDate() : data?.start, 15, false) &&
                checkEndDate(data?.end?.toDate ? data?.end?.toDate() : data?.end, 15, true) && (
                  <TouchableOpacity
                    style={{
                      width: 45,
                      height: 45,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onPress={openCallback}
                  >
                    <Ionicons
                      name="qr-code"
                      size={24}
                      color="black"
                    />
                  </TouchableOpacity>
                )}
              <StyledText style={{ fontFamily: "Title", fontSize: 34 }}>
                {data?.title}
              </StyledText>
            </View>
            <View style={{ flexDirection: isPhone ? "column" : "row" }}>
              <View style={{ flex: isPhone ? null : 1, marginRight: 10 }}>
                <View>
                  <StyledText style={{ marginBottom: 5 }}>Inicio</StyledText>
                  <View
                    style={{
                      flexDirection: "row",
                      padding: 10,
                      borderRadius: 5,
                      backgroundColor: "#f3f3f3",
                      alignItems: "center",
                    }}
                  >
                    <Ionicons
                      name="calendar-outline"
                      size={25}
                      color="gray"
                    />
                    <StyledText style={{ marginLeft: 10 }}>
                      {data?.start && moment(fixDate(data?.start)).format("DD-MM-YYYY [a las] HH:mm")}
                    </StyledText>
                  </View>
                </View>
                <View style={{ marginTop: 10 }}>
                  <StyledText style={{ marginBottom: 5 }}>Final</StyledText>
                  <View
                    style={{
                      flexDirection: "row",
                      padding: 10,
                      borderRadius: 5,
                      backgroundColor: "#f3f3f3",
                      alignItems: "center",
                    }}
                  >
                    <Ionicons
                      name="calendar-outline"
                      size={25}
                      color="gray"
                    />
                    <StyledText style={{ marginLeft: 10 }}>
                      {data?.end && moment(fixDate(data?.end)).format("DD-MM-YYYY [a las] HH:mm")}
                    </StyledText>
                  </View>
                </View>
                <View style={{ marginTop: 10 }}>
                  <StyledText style={{ marginBottom: 5 }}>
                    Tipo de Sesión
                  </StyledText>
                  <View
                    style={{
                      marginBottom: 10,
                      flexDirection: "row",
                      padding: 10,
                      borderRadius: 5,
                      backgroundColor: "#f3f3f3",
                      alignItems: "center",
                    }}
                  >
                    <Ionicons name="hourglass" size={25} color="gray" />
                    <StyledText style={{ marginLeft: 10 }}>
                      {getSessionType(data?.sessionType)}
                    </StyledText>
                  </View>
                </View>


                {isValid ? (
                  <>
                    <View style={{ gap: 10 }} >
                      <View style={{ flex: 1, flexDirection: "row", alignItems: "flex-start" }}>
                        <View style={{ height: 45, justifyContent: "center", alignItems: "center" }}>
                          <Entypo
                            name={isStart ? "login" : "log-out"}
                            size={25}
                            color="gray"
                            style={{ marginEnd: "16px" }}
                          />
                        </View>
                        <TextField
                          containerProps={{ style: { flex: 1 } }}
                          type="number"
                          size="small"
                          placeholder={`PIN de ${isStart ? "Entrada" : "Salida"}...`}
                          name={isStart ? 'pinStart' : 'pinEnd'}
                          error={get(errors, isStart ? 'pinStart' : 'pinEnd', undefined)}
                          key={isStart ? 'pinStart' : "pinEnd"}
                          control={control}
                        />
                        <Button
                          style={{ marginLeft: "16px" }}
                          height={45}
                          round={7}
                          label={`Validar`}
                          onPress={handleSubmit(handleValidatePin)}
                        />

                      </View>


                    </View>
                  </>
                ) : null}

                {data?.contentType ? (
                  <View style={{ marginTop: 10 }}>
                    <StyledText style={{ marginBottom: 5 }}>
                      Tipo de Contenido
                    </StyledText>
                    <View
                      style={{
                        flexDirection: "row",
                        padding: 10,
                        borderRadius: 5,
                        backgroundColor: "#f3f3f3",
                        alignItems: "center",
                      }}
                    >
                      {/* {swapIcon(data?.contentType)} */}
                      <StyledText style={{ marginLeft: 10 }}>
                        {data?.contentType}
                      </StyledText>
                    </View>
                  </View>
                ) : null}
              </View>

              {(data?.teachers?.length > 0 || data?.notes || data?.link || data?.location) ? (
                <View
                  style={{
                    flex: isPhone ? null : 1,
                    marginTop: isPhone ? 10 : 0,
                  }}
                >
                  {data?.link && (
                    <View>
                      <StyledText style={{ marginBottom: 5 }}>
                        Enlace de la sesión
                      </StyledText>
                      <TouchableOpacity
                        onPress={() => window.open(data?.link, "_blank")}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            padding: 10,
                            borderRadius: 5,
                            backgroundColor: "#f3f3f3",
                            alignItems: "center",
                            gap: 10
                          }}
                        >
                          <Ionicons name="link" size={25} color="gray" />
                          <StyledText wordBreak="break-all" numberOfLines={1}>
                            {data?.link}
                          </StyledText>
                        </View>
                      </TouchableOpacity>
                    </View>
                  )}
                  {data?.location && (
                    <View style={{ marginTop: data?.link ? 10 : 0 }}>
                      <StyledText style={{ marginBottom: 5 }}>
                        Lugar de la sesión
                      </StyledText>
                      <TouchableOpacity
                        onPress={() => window.open(`https://www.google.com/maps/search/?api=1&query=${encodeURI(data?.location)}`, "_blank")}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            padding: 10,
                            borderRadius: 5,
                            backgroundColor: "#f3f3f3",
                            alignItems: "center",
                            gap: 10
                          }}
                        >
                          <Ionicons name="map-outline" size={25} color="gray" />
                          <StyledText numberOfLines={1}>{data?.location}</StyledText>
                        </View>
                      </TouchableOpacity>
                    </View>
                  )}
                  {data?.teachers?.length > 0 && (
                    <View style={{ marginTop: 10 }}>
                      <StyledText style={{ marginBottom: 5 }}>
                        Profesores
                      </StyledText>
                      <View
                        style={{
                          flexDirection: "row",
                          padding: 10,
                          borderRadius: 5,
                          backgroundColor: "#f3f3f3",
                          alignItems: "center",
                        }}
                      >
                        <View style={{ flex: 1 }}>
                          {data?.teachers?.map((teacher, i) => (
                            <TeacherName key={i} teacher={teacher} tenantId={tenantId} />
                          ))}
                        </View>
                      </View>
                    </View>
                  )}
                  {data?.notes && (
                    <View style={{ marginTop: 10 }}>
                      <StyledText style={{ marginBottom: 5 }}>
                        Notas
                      </StyledText>
                      <View
                        style={{
                          flexDirection: "row",
                          padding: 10,
                          borderRadius: 5,
                          backgroundColor: "#f3f3f3",
                          alignItems: "flex-start",
                        }}
                      >
                        <Ionicons
                          name="newspaper-outline"
                          size={25}
                          color="gray"
                          style={{ marginRight: 10 }}
                        />
                        <View
                          style={{
                            height: "100%",
                            flex: 1,
                            justifyContent: "center",
                          }}
                        >
                          <StyledText>{data?.notes}</StyledText>
                        </View>
                      </View>
                    </View>
                  )}
                </View>
              ) : null}
            </View>
          </View>
        </View>
        {attendanceData?.entries && (
          <View>
            <StyledText style={{ marginVertical: 10 }}>
              Registros de Asistencia
            </StyledText>
            {attendanceData?.entries?.length === 0 ? (
              <View style={{ backgroundColor: "#f2f2f2", padding: 10 }}>
                <StyledText>Sin registros de la clase</StyledText>
              </View>
            ) : (
              attendanceData?.entries?.map((entry, i) => (
                <View
                  key={i}
                  style={{
                    backgroundColor: "#f2f2f2",
                    borderRadius: 7,
                    padding: 10,
                    flexDirection: "row",
                    marginBottom: 5,
                  }}
                >
                  <Ionicons
                    size={30}
                    name={entry?.type ? "enter-outline" : "exit-outline"}
                    color={entry?.type ? "green" : "red"}
                  />
                  <View style={{ flex: 1, paddingLeft: 10 }}>
                    <StyledText
                      style={{ fontFamily: "DemiBold", fontSize: 20 }}
                    >
                      {moment(fixDate(entry?.createdAt)).format("HH:mm [el] DD-MM-YYYY")}
                    </StyledText>
                    <StyledText>
                      {entry?.type ? "Entrada" : "Salida"}
                    </StyledText>
                  </View>
                </View>
              ))
            )}
          </View>
        )}
      </ScrollView>
      <View style={{ padding: 15, alignItems: "flex-start" }}>
        <Button height={35} round={7} label="Cerrar" onPress={onClose} />
      </View>
    </Modal>
  );
}

export default ClassModal

const checkEndDate = (time, offset, positive) => {
  const now = new Date()
  const temp = new Date(time)
  const timeWithOffset = temp.setMinutes(temp.getMinutes() + offset)
  return positive ? timeWithOffset > now : timeWithOffset < now
};

const getSessionType = (type) => {
  switch (type) {
    case "presentialHours":
      return "Horas Presenciales";
    case "onlineHours":
      return "Horas Online";
    case "presentialOnlineHours":
      return "Horas Presenciales Virtuales";
    case "practiceHours":
      return "Horas en Club";
    case "fieldHours":
      return "Horas en Campo";
    default:
      return "";
  }
};

const TeacherName = ({ teacher, tenantId }) => {

  const app = getApp()
  const functions = getFunctions(app, "europe-west1")
  process.env.NODE_ENV === "development" && connectFunctionsEmulator(functions, "localhost", 5001);
  const teacherId = teacher instanceof DocumentReference ? teacher?.path.includes("tenants") ? teacher.path.split("/").pop() : teacher : teacher.split("/").pop()
  const getTeacher = httpsCallable(functions, "getTeacher");


  const { data, isLoading } = useQuery({
    queryKey: ["teacher", teacherId],
    queryFn: async () => getTeacher({
      userId: teacherId,
      tenantId: tenantId
    }).then(res => res.data),
  })

  // const [name, setName] = useState("");

  // useEffect(() => {
  //   getDoc(teacher)
  //     .then((res) => setName((res.data()?.name || "") + " " + (res.data()?.surname || "")))
  //     .catch((err) => setName("Error al Cargar el Profesor"));
  // }, [teacher]);

  return <StyledText numberOfLines={1} truncate>{get(data, "name", "") + " " + get(data, "surname", "")}</StyledText>;
};
