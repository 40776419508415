// import { CaretRightOutlined, PauseOutlined } from '@ant-design/icons';
import { TimelineState } from '@xzdarcy/react-timeline-editor';
// import { Select } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { View } from 'react-native';
import Button from '../common/Button';
import StyledText from '../common/StyledText';


export const Rates = [0.2, 0.5, 1.0, 1.5, 2.0];

const TimelinePlayer: FC<{
    videoRef: React.MutableRefObject<HTMLVideoElement>;
    timelineState: React.MutableRefObject<TimelineState>;
    autoScrollWhenPlay: React.MutableRefObject<boolean>;
    timelineValues: { scaleWidth: number, scale: number, startLeft: number }
    selectedEvent: boolean
    deleteCallback: () => void
    selectedDraw: string
    deleteDrawCallback: () => void
    editDrawCallback: () => void
    renderCallback?: () => void
    downloadDrawCallback: () => void
    moveCallback: (ev: number, selected: any) => void

}> = ({ timelineState, autoScrollWhenPlay, videoRef, timelineValues = { scaleWidth: 160, scale: 6, startLeft: 20 }, selectedEvent, deleteCallback, selectedDraw, deleteDrawCallback, editDrawCallback, renderCallback, downloadDrawCallback, moveCallback }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [time, setTime] = useState(0);
    const [rate, setRate] = useState(1);

    useEffect(() => {
        if (!timelineState.current) return;

        const engine = timelineState.current;
        engine.listener.on('play', () => {
            setIsPlaying(true)
        });
        engine.listener.on('paused', () => {
            setIsPlaying(false)
        });
        engine.listener.on('afterSetTime', ({ time }) => {
            setTime(time)
            moveCallback(time, selectedDraw)
            const playing = engine.isPlaying
            if (autoScrollWhenPlay.current && playing) {
                const autoScrollFrom = timelineState.current.target.clientWidth / 2;
                // const autoScrollFrom = 300;
                const left = time * (timelineValues.scaleWidth / timelineValues.scale) + timelineValues.startLeft - autoScrollFrom;
                timelineState.current.setScrollLeft(left)
            }
        });
        engine.listener.on('setTimeByTick', ({ time }) => {
            setTime(time);
            moveCallback(time, selectedDraw)

        });

        return () => {
            if (!engine) return;
            engine.pause();
            engine.listener.offAll();
        };

    }, [selectedDraw]);


    const handlePlayOrPause = () => {
        if (isPlaying) {
            videoRef.current.pause()
            setIsPlaying(false)
        } else {
            videoRef.current.play()
            setIsPlaying(true)
        }
    };


    const handleRateChange = (rate) => {
        if (!timelineState.current) return;
        timelineState.current.setPlayRate(rate);
        setRate(rate)
        videoRef?.current?.playbackRate(rate)
    };


    const timeRender = (time: number) => {
        const float = (parseInt((time % 1) * 100 + '') + '').padStart(2, '0');
        const min = (parseInt(time / 60 + '') + '').padStart(2, '0');
        const second = (parseInt((time % 60) + '') + '').padStart(2, '0');
        return <>{`${min}:${second}.${float.replace('0.', '')}`}</>;
    };

    return (
        <>
            <View style={{ height: 40, width: "100%", backgroundColor: "black", flexDirection: "row", alignItems: "center", gap: 10, paddingHorizontal: 15 }}>
                <Button title="Play/Pause" icon={isPlaying ? "pause" : "play"} round={7} onPress={handlePlayOrPause} color="black" width={"35px"} height={35} paddingHorizontal={0} />
                <View style={{ flex: 1, alignItems: "center" }}>
                    <StyledText style={{ fontSize: 18, color: "white" }}>{timeRender(time)}</StyledText>
                </View>
                {/* {selectedDraw && <Button title="Duración Dibujo" iconSize={18} icon="time" round={7} onPress={downloadDrawCallback} color={"#191c1d"} width={"35px"} height={35} paddingHorizontal={0} />} */}
                {selectedDraw && <Button title="Descargar Dibujo" iconSize={18} icon="image" round={7} onPress={downloadDrawCallback} color={"#191c1d"} width={"35px"} height={35} paddingHorizontal={0} />}
                {selectedDraw && <Button title="Editar Dibujo" iconSize={18} icon="brush" round={7} onPress={editDrawCallback} color={"#191c1d"} width={"35px"} height={35} paddingHorizontal={0} />}
                {selectedDraw && <Button title="Eliminar Dibujo" iconSize={18} icon="trash" round={7} onPress={deleteDrawCallback} color={"red"} width={"35px"} height={35} paddingHorizontal={0} />}
                {selectedEvent && <Button title="Renderizar Evento" iconSize={18} icon="md-download" round={7} onPress={renderCallback} color={"#191c1d"} width={"35px"} height={35} paddingHorizontal={0} />}
                {selectedEvent && <Button title="Eliminar Evento" iconSize={18} icon="trash" round={7} onPress={deleteCallback} color={"red"} width={"35px"} height={35} paddingHorizontal={0} />}
                <Button label="x.5" round={7} onPress={() => handleRateChange(.5)} color={rate === .5 ? "orange" : "black"} width={"35px"} height={35} paddingHorizontal={0} />
                <Button label="x1" round={7} onPress={() => handleRateChange(1)} color={rate === 1 ? "orange" : "black"} width={"35px"} height={35} paddingHorizontal={0} />
                <Button label="x2" round={7} onPress={() => handleRateChange(2)} color={rate === 2 ? "orange" : "black"} width={"35px"} height={35} paddingHorizontal={0} />

            </View>
        </>
    );
};

export default TimelinePlayer;