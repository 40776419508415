import { useNavigation } from "@react-navigation/native";
import React from "react";
import { ScrollView, View } from "react-native";
import { useQuery } from "react-query";
import Footer from "../../components/common/Footer";
import StyledText from "../../components/common/StyledText";
import CourseCardSquare from "../../components/publicComponents/CourseCardSquare";
import fixDate from "../../utils/fixDate";


const Process = () => {


	const navigation = useNavigation()

	// const db = getFirestore()
	// const coursesRef = query(collection(db, "publicCourses"), where("visible", "==", true), where("preEndDate", ">=", moment().toDate()))
	// const { data } = useFirestoreQuery([`courses`], coursesRef, {}, {
	// 	select: querySnapshot => querySnapshot.docs
	// 		.filter(el => el.data()?.tenantId === "OF4VYpRfgUJEH5rG2syJ")
	// 		.filter(x => x.data()?.courseType === "Proceso de Solicitud")
	// 		.map(doc => {
	// 			const d = doc.data()
	// 			const initDate = d?.initDate?.toDate ? moment(d.initDate.toDate()) : null
	// 			const endDate = d?.endDate?.toDate ? moment(d.endDate.toDate()) : null
	// 			return {
	// 				...d,
	// 				initDate,
	// 				endDate,
	// 				id: doc.id
	// 			}
	// 		}) || []
	// });


	const { isLoading, error, data } = useQuery({
		queryKey: ['courses'],
		queryFn: () => fetch('https://onformacion.comerciogo.workers.dev/courses').then((res) => res.json()),
		select: querySnapshot => querySnapshot?.filter(x => x?.tenantId === "OF4VYpRfgUJEH5rG2syJ" && x.courseType === "Proceso de Solicitud")
			.map(doc => {
				const initDate = fixDate(doc?.initDate)
				const endDate = fixDate(doc?.endDate)
				return {
					...doc,
					initDate,
					endDate,
				}
			}) || []
	})


	return (
		<>
			<ScrollView style={{ flex: 1, backgroundColor: "white" }} contentContainerStyle={{ flexGrow: 1 }}>
				<View style={{ flexDirection: "row", alignItems: "center", width: "100%", paddingTop: 0, alignSelf: "center" }}>
					<View style={{ flexDirection: "row", backgroundColor: "#06162D", width: "100%", paddingTop: 50, paddingHorizontal: 30, paddingBottom: 50 }}>
						<View style={{ flex: 1 }}>
							<StyledText style={{ fontFamily: "Title", textAlign: "center", fontSize: 60, color: "white" }}>Proceso de Solicitud</StyledText>
						</View>
					</View>
				</View>

				<View style={{ maxWidth: 860, alignSelf: "center", paddingVertical: 20, gap: 20 }}>
					<StyledText style={{ fontSize: 18, textAlign: "center" }}>Este proceso de <strong>solicitud de obtención de Licencia UEFA</strong>, tiene como objetivo principal que un Entrenador de Fútbol formado en el marco federativo español, pueda acreditar sus competencias y obtener el correspondiente <strong>Diploma y Licencia UEFA,</strong> facilitando así continuar la formación en este nuevo itinerario formativo UEFA.</StyledText>
					<StyledText style={{ fontSize: 18, textAlign: "center" }}>La formación de entrenadores está enmarcada en la nueva <strong>Convención de Entrenadores de la UEFA 2020</strong>, siendo esta una herramienta creada para regular la <strong>formación de entrenadores en Europa</strong>, trabajar en su mejora continua y facilitar el movimiento entre países de los entrenadores.</StyledText>
					<StyledText style={{ fontSize: 18, textAlign: "center" }}>Más abajo pueden encontrar la información e inscripción relativa a estos procesos.</StyledText>
				</View>

				<View style={{ flexGrow: 1 }}>
					<View style={{
						paddingVertical: 15,
						flexWrap: "wrap",
						flexDirection: "row",
						alignItems: "flex-start",
						justifyContent: "flex-start",

					}}>

						{data?.length === 0 ?
							<View style={{ flex: 1, paddingHorizontal: 15, minHeight: 200, alignItems: "center", justifyContent: "center", backgroundColor: 'white' }} >
								<StyledText style={{ fontSize: 20, textAlign: "center", color: "black", opacity: .2 }}>No hay Procesos de solicitud abiertos en este momento</StyledText>
							</View>
							:
							data?.map((el, i) => (
								<CourseCardSquare key={i} onPress={() => navigation.navigate("PreviewCourseScreen", { tenantId: el.tenantId, id: el.id })} data={el} is />
							))}
					</View>
				</View>

				<Footer />

			</ScrollView >
		</>
	);
};

export default Process;