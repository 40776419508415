import { Ionicons } from "@expo/vector-icons";
import { useLinkProps, useRoute } from "@react-navigation/native";
import React from 'react';
import { Text, View } from 'react-native';

const getRouteName = (route) => {
    let r = []
    if (route?.params) {
        r.push(...getRouteName(route.params))
    }
    if (route?.screen) {
        r.push(route?.screen)
    }
    return r
}

const Link = ({ children, to = {}, action, text = "", icon = null, iconSize = 24, iconStyle = {}, containerStyle = {}, style = {}, accentColor = "#F1B04F", ...rest }) => {

    if (to.screen) {

        const [isHovered, setIsHovered] = React.useState(false);
        const { onPress, ...props } = useLinkProps({ to, action });

        const route = useRoute()
        const active = getRouteName(to).includes(route.name)

        return (
            <View
                accessibilityRole="link"
                onClick={onPress}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                {...props}
                {...rest}
                style={{
                    ...containerStyle,
                    transitionDuration: '150ms',
                    opacity: isHovered ? 0.8 : 1,
                }}
            >
                {icon ?
                    <Ionicons name={icon} size={iconSize} color={active ? accentColor : isHovered ? accentColor : "#fff"} style={iconStyle} />
                    :
                    null
                }

                {text ?
                    <Text style={{
                        transitionDuration: '150ms',
                        fontSize: 14,
                        ...style,
                        color: active ? accentColor : isHovered ? accentColor : "#fff",
                    }}>
                        {text}
                    </Text>
                    : null
                }

                {children}
            </View>
        );
    } else {
        const [isHovered, setIsHovered] = React.useState(false);

        const route = useRoute()
        const active = getRouteName(to).includes(route.name)

        return (
            <View
                accessibilityRole="link"
                onClick={rest.onPress}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                {...rest}
                style={{
                    ...containerStyle,
                    cursor: "pointer",
                    transitionDuration: '150ms',
                    opacity: isHovered ? 0.8 : 1,
                }}
            >
                {icon ?
                    <Ionicons name={icon} size={iconSize} color={active ? accentColor : isHovered ? accentColor : "#fff"} style={iconStyle} />
                    :
                    null
                }

                {text ?
                    <Text style={{
                        transitionDuration: '150ms',
                        fontSize: 14,
                        ...style,
                        color: active ? accentColor : isHovered ? accentColor : "#fff",
                    }}>
                        {text}
                    </Text>
                    : null
                }

                {children}
            </View>
        )
    }
};

export default Link