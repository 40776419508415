import { FontAwesome, Ionicons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { useFirestoreDocumentData, useFirestoreQuery } from "@react-query-firebase/firestore";
import { getApp } from "firebase/app";
import { collection, doc, getFirestore, query, where } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import moment from "moment";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Platform, ScrollView, TouchableOpacity, View } from "react-native";
import * as yup from "yup";
import Button from "../../components/common/Button";
import Footer from "../../components/common/Footer";
import Modal from "../../components/common/Modal";
import StyledText from "../../components/common/StyledText";
import SelectField from "../../components/formComponents/SelectField";
import TextField from "../../components/formComponents/TextField";
import CourseCardSquare from "../../components/publicComponents/CourseCardSquare";
import useYupValidationResolver from "../../utils/useYupValidationResolver";

const QualityForm = () => {


	const navigation = useNavigation()

	const db = getFirestore()
	const app = getApp()

	const functions = getFunctions(app, "europe-west1");
	process.env.NODE_ENV === "development" && connectFunctionsEmulator(functions, "localhost", 5001);



	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);
	const [termsModal, setTermsModal] = useState(false)



	const docRef = doc(db, `globalPages/lnDtyT3wuH2CHkOsRCPc`)
	const { data, isLoading, error: errorLoadingLOPD } = useFirestoreDocumentData(["globalPage", { slug: "proteccion-datos" }], docRef, {}, {});

	const coursesRef = query(collection(db, "publicCourses"), where("visible", "==", true), where("preEndDate", ">=", moment().toDate()))
	const { data: dataCourses } = useFirestoreQuery([`courses`], coursesRef, {}, {
		select: querySnapshot => querySnapshot.docs
			.filter(el => el.data()?.tenantId === "OF4VYpRfgUJEH5rG2syJ")
			.filter(x => x.data()?.courseType === "Sello de Calidad")
			.map(doc => {
				const d = doc.data()
				const initDate = d?.initDate?.toDate ? moment(d.initDate.toDate()) : null
				const endDate = d?.endDate?.toDate ? moment(d.endDate.toDate()) : null
				return {
					...d,
					initDate,
					endDate,
					id: doc.id
				}
			}) || []
	});



	const schema = yup.object().shape({
		centerName: yup.string().required("Este campo es obligatorio"),
		currentTeaching: yup.string().required("Este campo es obligatorio"),
		previousCertification: yup.string(),
		address: yup.string().required("Este campo es obligatorio"),
		postalCode: yup.string().required("Este campo es obligatorio"),
		community: yup.string().required("Este campo es obligatorio"),
		province: yup.string().required("Este campo es obligatorio"),
		city: yup.string().required("Este campo es obligatorio"),
		responsibleName: yup.string().required("Este campo es obligatorio"),
		role: yup.string().required("Este campo es obligatorio"),
		phone: yup.string().required("Este campo es obligatorio"),
		email: yup.string().required("Este campo es obligatorio"),
		tenant: yup.string().required("Este campo es obligatorio"),
		lopd: yup.boolean().oneOf([true], "Debe aceptar los terminos y condiciones").required("Debe aceptar los terminos y condiciones")
	})

	const resolver = useYupValidationResolver(schema)

	const {
		control,
		handleSubmit,
		reset,
		watch,
		trigger,
		setValue,
		formState: { errors }
	} = useForm({
		resolver,
		mode: "onChange",
		defaultValues: {
			centerName: "",
			currentTeaching: "",
			previousCertification: "",
			address: "",
			postalCode: "",
			community: "",
			province: "",
			city: "",
			responsibleName: "",
			role: "",
			phone: "",
			email: "",
			tenant: "",
			lopd: false
		},
	})

	const sendForm = async (values) => {

		setLoading(true)

		const submitContactForm = httpsCallable(functions, "submitContactForm", { region: "europe-west1" })

		submitContactForm({ ...values }).then(res => {
			setSuccess(true)
			setLoading(false)
		}).catch(err => {
			setError(true)
			setLoading(false)
		})
	}

	const resetState = () => {
		setSuccess(false)
		setError(false)
		reset({
			centerName: "",
			currentTeaching: "",
			previousCertification: "",
			address: "",
			postalCode: "",
			community: "",
			province: "",
			city: "",
			responsibleName: "",
			role: "",
			phone: "",
			email: "",
			tenant: "",
			lopd: false
		})
	}

	const lopd = watch("lopd")

	return (
		<>
			<ScrollView style={{ flex: 1, backgroundColor: "white" }} contentContainerStyle={{ flexGrow: 1 }}>
				<View style={{ flexDirection: "row", alignItems: "center", width: "100%", paddingTop: 0, alignSelf: "center" }}>
					<View style={{ flexDirection: "row", backgroundColor: "#003349", width: "100%", paddingTop: 50, paddingHorizontal: 30, paddingBottom: 50 }}>
						<View style={{ flex: 1 }}>
							<StyledText style={{ fontFamily: "Title", textAlign: "center", fontSize: 60, color: "white" }}>Sello de Calidad para las escuelas de entrenadores</StyledText>
						</View>
					</View>
				</View>

				<View style={{ maxWidth: 860, alignSelf: "center", paddingVertical: 20, gap: 20 }}>
					<StyledText style={{ fontSize: 18, textAlign: "center" }}>La Real Federación Española de Fútbol ha puesto en marcha un “Sello de Calidad” en la formación de técnicos y entrenadores de fútbol y futbol sala para que todas aquellas entidades, empresas, asociaciones o Administraciones Públicas que desarrollen programas de formación de fútbol puedan ponerlos en valor en relación con los estándares de calidad fijados por la UEFA en este ámbito.</StyledText>
					<StyledText style={{ fontSize: 18, textAlign: "center" }}>Así, los titulares de centros de formación de entrenadores y técnicos de fútbol y futbol sala, tanto centros autorizados docentes homologados por el Ministerio de Educación y las Comunidades Autónomas como escuelas de entrenadores dependientes de una federación de fútbol, podrán voluntariamente someter su modelo formativo y sus estándares de calidad a una validación externa realizada por expertos independientes.</StyledText>
					<StyledText style={{ fontSize: 18, textAlign: "center" }}>Habrá un sello de calidad “Estándar”, con una validez de dos años, y un certificado con nivel de calidad “Premium”, con una validez de cuatro años. Se tendrá en cuenta el cumplimiento de requisitos de tipo administrativo, de instalaciones, del programa de formación y del profesorado. Todos los centros con el Sello de Calidad entrarán a formar parte del Registro de Centros Certificados y serán publicados en la web de la RFEF.</StyledText>
					<StyledText style={{ fontSize: 18, textAlign: "center" }}>Todos aquellos centros de formación que estén interesados en la obtención del Sello de Calidad de la Real Federación Española de Fútbol deberán introducir sus datos de contacto rellenando el siguiente formulario: </StyledText>
				</View>


				<View style={{ paddingVertical: 50, alignItems: "center", justifyContent: "flex-start", flexGrow: 1, paddingHorizontal: 20 }}>

					{!success ?
						<View style={{ width: "100%", maxWidth: 800, flex: 1, alignItems: "center", paddingHorizontal: 20, justifyContent: "flex-start", zIndex: 2 }}>
							<StyledText style={{ marginBottom: 20, fontSize: 16 }}>Si tienes alguna consulta que hacernos o necesitas ayuda con algo, no dudes en contactarnos rellenando el siguiente formulario y te responderemos lo antes posible.</StyledText>
							<View style={{ width: "100%" }}>
								<TextField error={errors["email"]} name={"email"} key="email" label="Email" placeholder="Email..." control={control} />
							</View>
							<View style={{ width: "100%", flexDirection: "row", marginTop: 10 }}>
								<TextField error={errors["name"]} name={"name"} key="name" label="Nombre Completo" placeholder="Nombre Completo..." control={control} />

							</View>
							<View style={{ width: "100%", marginTop: 10 }}>
								<SelectField
									label="Categoría"
									control={control}
									error={errors["category"]}
									name="category"

									options={[
										"Escuela de Entrenadores",
										"Licencias",
										"Incidencias en la plataforma",
										"Otros"
									]}
								/>
							</View>
							<View style={{ width: "100%", marginTop: 10 }}>
								{/* <SelectField
									label="Federación"
									control={control}
									error={errors["tenant"]}
									valueKey="value"
									labelKey="label"
									name="tenant"
									options={tenants}
								/> */}
							</View>
							<View style={{ width: "100%", marginTop: 10 }}>
								<TextField error={errors["subject"]} name={"subject"} key="subject" label="Asunto" placeholder="Asunto..." control={control} />
							</View>


							<View style={{ width: "100%", marginTop: 10 }}>
								<TextField
									numberOfLines={10}
									multiline={Platform.OS === "web" ? "true" : true}
									style={{ minHeight: 150, padding: 15, resize: "vertical" }}
									resize="vertical"
									alignItems="flex-start"
									error={errors["message"]}
									name={"message"}
									key="message"
									label="Mensaje"
									placeholder="Mensaje..."
									control={control} />
							</View>
							<View style={{ width: "100%", marginTop: 20 }}>
								<TouchableOpacity onPress={() => {
									if (lopd) {
										setValue("lopd", false)
										trigger("lopd")
									} else {
										setTermsModal(true)
									}
								}
								} style={{ flex: 1, flexDirection: "row", alignItems: "center" }}>
									<View style={{ marginRight: 10, borderWidth: 3, borderColor: "black", borderRadius: 4, width: 30, height: 30, alignItems: "center", justifyContent: "center" }}>
										{lopd && <FontAwesome name={"check"} size={18} color={"black"} />}
									</View>
									<View style={{ flex: 1 }}>
										<StyledText style={{ color: "black", fontSize: 16 }}>Declaro que he leído y acepto los términos y condiciones</StyledText>
									</View>
								</TouchableOpacity>
								{errors?.lopd && (
									<StyledText style={{ marginTop: 10, fontSize: 12, color: "red" }}>
										{errors?.lopd?.message}
									</StyledText>
								)}
								{/* <CheckBoxField checkboxColor="white" labelColor="white" error={errors["lopd"]} name={"lopd"} key="lopd" label="Al registrarme acepto los terminos y condiciones" control={control} /> */}
							</View>

							{termsModal ? <Modal padding={0} modalContainerStyle={{ maxHeight: screen?.height - 100, maxWidth: 550, width: "95%", borderRadius: 10 }}>

								<ScrollView style={{ flex: 1 }} contentContainerStyle={{ padding: 15 }}>
									<StyledText style={{ fontSize: 34, fontFamily: "Title", color: "black", textAlign: "center" }}>Terminos y Condiciones</StyledText>
									{!errorLoadingLOPD ?
										<div dangerouslySetInnerHTML={{ __html: data?.content || "<p></p>" }} style={{ fontFamily: "Regular", fontSize: 16, color: "#303030" }} />
										:
										<View style={{ alignItems: "center" }}>
											<StyledText>Ocurrió un error cargando los terminos y condiciones, para acceder a ellos siga el siguiente enlace</StyledText>
											<Button color={"#E4002B"} label="Finalizar" height={35} round={7} onPress={() => {
												window.open("https://onformacion.rfef.es/proteccion-datos", "_blank")
											}} />
										</View>
									}
								</ScrollView>
								<View style={{ padding: 15, flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
									<Button color={"#f2f2f2"} label="Rechazar" height={35} round={7} onPress={() => {
										trigger("lopd")
										setValue("lopd", false)
										setTermsModal(false)
									}} />
									<Button color={"#E4002B"} label="Aceptar" height={35} round={7} onPress={() => {
										setValue("lopd", true)
										trigger("lopd")
										setTermsModal(false)
									}} />

								</View>

							</Modal > : null}



							<View style={{ marginTop: 25, width: "100%", alignItems: "flex-start" }}>
								<Button height={35} round={7} onPress={handleSubmit(sendForm)} loading={loading} label="Enviar" />
							</View>

						</View>
						:
						error ?
							<View style={{ width: "100%", maxWidth: 600, flex: 1, alignItems: "center", paddingHorizontal: 20, justifyContent: "flex-start", zIndex: 2 }}>
								<View style={{ alignItems: "center", justifyContent: "center", flex: 1 }}>
									<Ionicons name="close-circle-outline" color={"red"} size={120} />
									<StyledText style={{ fontSize: 34, fontFamily: "Title", textAlign: "center" }}>Ocurrió un error al enviar el mensaje</StyledText>

									<StyledText style={{ textAlign: "center", fontSize: 16, maxWidth: 500, marginVertical: 15, fontFamily: "DemiBold" }}>
										Disculpa las molestias, vuelva a intentarlo
									</StyledText>
									<View style={{ flexDirection: "row", marginTop: 10, marginBottom: 30, alignItems: "center", justifyContent: "center" }}>
										<Button height={35} onPress={() => setError(false)} label="Volver" round={7} />
									</View>
								</View>

							</View>
							:
							<View style={{ width: "100%", maxWidth: 600, flex: 1, alignItems: "center", paddingHorizontal: 20, justifyContent: "flex-start", zIndex: 2 }}>
								<View style={{ alignItems: "center", justifyContent: "center", flex: 1 }}>
									<Ionicons name={"checkmark-circle-outline"} color={"green"} size={120} />
									<StyledText style={{ fontSize: 34, fontFamily: "Title", textAlign: "center" }}>¡Tu mensaje se ha enviado con éxito!</StyledText>

									<StyledText style={{ textAlign: "center", fontSize: 16, maxWidth: 500, marginVertical: 15, fontFamily: "DemiBold" }}>
										Te responderemos con la mayor brevedad posible
									</StyledText>
									<View style={{ flexDirection: "row", marginTop: 10, marginBottom: 30, alignItems: "center", justifyContent: "center" }}>
										<Button height={35} onPress={resetState} label="Volver" round={7} />
									</View>
								</View>

							</View>
					}


				</View>

				<View style={{ flexGrow: 1 }}>
					<View style={{
						paddingVertical: 15,
						flexWrap: "wrap",
						flexDirection: "row",
						alignItems: "flex-start",
						justifyContent: "flex-start",

					}}>

						{dataCourses?.length === 0 ?
							<View style={{ flex: 1, paddingHorizontal: 15, minHeight: 200, alignItems: "center", justifyContent: "center", backgroundColor: 'white' }} >
								<StyledText style={{ fontSize: 20, textAlign: "center", color: "black", opacity: .2 }}>No hay procesos de solicitud abiertos en este momento</StyledText>
							</View>
							:
							dataCourses?.map((el, i) => (
								<CourseCardSquare key={i} onPress={() => navigation.navigate("PreviewCourseScreen", { tenantId: el.tenantId, id: el.id })} data={el} />
							))}
					</View>
				</View>

				<Footer limited={false} />

			</ScrollView >
		</>
	);
};

export default QualityForm;