import { Ionicons } from '@expo/vector-icons';
import React, { useContext, useEffect, useState } from 'react';
import { Dimensions, TextInput, TouchableOpacity, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import AuthContext from '../../context/AuthContext';
import Modal from "./Modal";
import StyledText from './StyledText';

const height = Dimensions.get("window").height


const FilterSelector = ({ background = "#e4002b", title = "", options = [], labelKey = null, valueKey = null, searchable = false, onChange, value = "Todos" }) => {

    const isPhone = useMediaQuery({ query: '(max-width: 1000px)' })

    const { customization } = useContext(AuthContext)
    const [open, setOpen] = useState(false)
    const [search, setSearch] = useState("")

    useEffect(() => {
        setSearch("")
    }, [open])


    return (
        <>
            <View style={{ width: isPhone ? "100%" : "auto", padding: 20, backgroundColor: background, flex: 1 }}>
                <StyledText style={{ fontFamily: "Title", marginBottom: 10, fontSize: 25, color: "#f9bb2c" }}>{title}</StyledText>
                <TouchableOpacity onPress={() => setOpen(true)} style={{ backgroundColor: "#061934", padding: 10, paddingVertical: 20, flexDirection: "row", alignItems: "center" }}>
                    <View style={{ flex: 1 }}>
                        <StyledText numberOfLines={1} style={{ fontFamily: "Title", fontSize: 25, color: "white" }}>{value}</StyledText>
                    </View>
                    <Ionicons name="chevron-down" color={"white"} size={16} />
                </TouchableOpacity>
            </View>
            {open &&
                <Modal onClickOutside={() => setOpen(false)} modalContainerStyle={{ width: "100%", maxWidth: 600, maxHeight: height - 80, overflow: "auto" }}>
                    <View style={{ backgroundColor: "white", padding: 0, paddingVertical: 0, }}>
                        {searchable &&
                            <View style={{ width: "100%", marginBottom: 10 }}>
                                <TextInput style={{ borderWidth: 1, borderColor: "gainsboro", paddingHorizontal: 15, paddingVertical: 10, borderRadius: 6 }} value={search} onChangeText={ev => setSearch(ev)} placeholder="Buscar..." />

                            </View>
                        }
                        {options.filter(el => (labelKey ? el[labelKey] : el)?.toLocaleLowerCase().includes(search.toLocaleLowerCase())).map((option, index) => {
                            return (
                                <TouchableOpacity onPress={() => {
                                    setOpen(false)
                                    onChange(valueKey ? option[valueKey] : option)
                                }} key={index} style={{ paddingHorizontal: 10, paddingVertical: 5, width: "100%", borderBottomWidth: 1, borderBottomColor: "gainsboro" }}>
                                    <StyledText style={{ fontFamily: "TitleWide", color: value === (labelKey ? option[labelKey] : option) ? customization.mainColor : "black", fontSize: 16 }}>{labelKey ? option[labelKey] : option}</StyledText>
                                </TouchableOpacity>
                            )
                        })}
                    </View>
                </Modal>
            }
        </>
    );
}

export default FilterSelector;