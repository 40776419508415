import { AssetRecordType, createShapeId, getHashForString, useEditor } from "@tldraw/tldraw"

const ItemSelector = ({ elements, visible, closeCallback, tool = "image" }) => {
    const editor = useEditor()


    const addField = (element) => {

        const assetId = AssetRecordType.createId(getHashForString(element.url))
        const fieldId = createShapeId("asset" + Math.random())


        editor.createAssets([
            {
                id: assetId,
                type: 'image',
                typeName: 'asset',
                meta: {},
                props: {
                    src: element.url,
                    w: element.width,
                    h: element.height,
                    name: "asset",
                    isAnimated: false,
                    mimeType: "image/jpeg"
                }
            }
        ])

        const currentPoint = editor.getViewportPageCenter()

        // // Create a shape
        editor.createShapes([
            {
                id: fieldId,
                type: tool,
                x: currentPoint.x - element.width / 2,
                y: currentPoint.y - element.height / 2,
                isLocked: false,
                props: {
                    
                    // preserveAspectRatio:false,
                    assetId: assetId,
                    w: element.width,
                    h: element.height,
                },
            }
        ])
        editor.bringToFront([fieldId])


    }

    if (!visible) return

    return (
        <div>
            <button onClick={closeCallback} style={{ cursor: "pointer", zIndex: 10000, background: "transparent", border: 0, position: "absolute", top: 0, right: 0, width: 35, height: 35, display: "flex", alignItems: "center", justifyContent: "center" }}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" style={{ width: 20, height: 20 }}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </button>
            <div style={{
                position: "absolute",
                top: 0,
                right: 0,
                zIndex: 1000,
                backgroundColor: "white",
                padding: 10,
                maxHeight: "70vh",
                borderRadius: 10,
                overflow: "auto",
                maxWidth: 350,
                gap: 10,
                justifyContent: 'center'
            }}>

                {elements.map((element, i) => (
                    <div key={i}>
                        <h2 style={{ fontFamily: "Montserrat" }}>{element.name}</h2>
                        <div style={{

                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            gap: 10,
                        }}>
                            {element.items.map((item, i) => (
                                <button key={i} onClick={() => addField(item)} style={{ cursor: "pointer", height: 75, width: 75, background: "#f3f3f3", border: 0, borderRadius: 10, overflow: "hidden" }}>
                                    <img src={item.url} width={"100%"} height={"100%"} style={{ objectFit: "contain" }} />
                                </button>
                            ))}
                        </div>
                    </div>
                ))
                }
            </div >
        </div >
    )
}


export default ItemSelector