import { Ionicons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import chroma from "chroma-js";
import React, { useState } from "react";
import { Image, Linking, Pressable, ScrollView, View } from "react-native";
import { useMediaQuery } from "react-responsive";
import Button from "../../components/common/Button";
import Footer from "../../components/common/Footer";
import StyledText from "../../components/common/StyledText";


const VirtualReevaluation = () => {


	const isPhone = useMediaQuery({ query: '(max-width: 1000px)' })
	const navigation = useNavigation()

	const scale = chroma.scale(['#e4002b', '#8e051d']).colors(5);



	return (
		<>
			<ScrollView style={{ flex: 1, backgroundColor: "white" }} contentContainerStyle={{ flexGrow: 1 }}>
				<View style={{ flexDirection: "row", alignItems: "center", width: "100%", paddingTop: 0, alignSelf: "center" }}>
					<View style={{ flexDirection: "row", backgroundColor: "#06162D", width: "100%", alignItems: "center", justifyContent: 'center', paddingTop: 50, paddingHorizontal: 30, paddingBottom: 80 }}>
						<View style={{ width: "100%", maxWidth: 1200, alignItems: "center", justifyContent: "center", gap: 10, alignSelf: "center" }}>
							<StyledText style={{ fontFamily: "Title", fontSize: 60, color: "white", textAlign: "center" }}>Revaluación de su Licencias UEFA</StyledText>
							<StyledText style={{ fontSize: 22, color: "white", textAlign: "center" }}>
								Desde la Escuela de Entrenadores de la Real Federación Española de Fútbol, hemos habilitado un curso de revaluación virtual hasta 15 de diciembre de 2024, con la oferta formativa correspondiente a las licencias de entrenador UEFA C, B, A y PRO, donde deberás visualizar por completo 15h.
							</StyledText>


						</View>
					</View>
				</View>

				<View style={{ maxWidth: 1200, width: "100%", zIndex: 3, alignSelf: "center", gap: 20, paddingVertical: 40, paddingHorizontal: 10 }}>

					<StyledText style={{ fontSize: 22, textAlign: "center" }}>
						Llevaremos un control de asistencia y visualización, donde te aparecerán varios avisos de presencialidad hasta el final de los vídeos, a los que deberemos responder para superar la video tarea.
					</StyledText>

					<StyledText style={{ fontSize: 22, textAlign: "center" }}>
						Para preinscribirte a la revaluación virtual, deberás buscar y clicar sobre el curso que corresponde a tu licencia, completar tus datos personales y adjuntar una foto de tu licencia. En 24/48h recibirás un email confirmando la matrícula al curso, por favor, revisa la carpeta spam o correo no deseado.
					</StyledText>

					<StyledText style={{ fontSize: 22, textAlign: "center" }}>
						Una vez realizadas las 15h, verás en tu área personal, en el apartado de LICENCIAS, un nuevo botón que pone "RENOVAR LICENCIA". Clicando sobre él, accederemos al pago de los 25€ y se generará automáticamente la licencia ya renovada hasta 31.12.2027, aunque no aparecerá hasta días antes de finalizar su caducidad, lo normal a finales de diciembre.
					</StyledText>
					<StyledText style={{ fontSize: 24, marginTop: 20, fontWeight: "bold", color: "black" }}>Elija su Licencia</StyledText>
					<View style={{ flexDirection: isPhone ? "column" : "row", gap: 10, width: "100%", alignSelf: "center", marginBottom: 50 }}>

						<View style={{ flex: 1, backgroundColor: "white", padding: 20, borderRadius: 7, height: "auto", borderWidth: 2, borderColor: "#E4002B" }}>
							<View style={{ flex: 1, paddingTop: 0, gap: 10 }}>
								<Image
									style={{ width: "100%", aspectRatio: 1.77, resizeMode: "cover", borderRadius: 15, overflow: "hidden", marginBottom: 10 }}
									source={{ uri: "https://storage.googleapis.com/rfef-escuela-entrenadores.appspot.com/OF4VYpRfgUJEH5rG2syJ/admin/images/Futbol%20%2829%29.jpeg?GoogleAccessId=firebase-adminsdk-s5asd%40rfef-escuela-entrenadores.iam.gserviceaccount.com&Expires=2051222400&Signature=LcdfVU2aGTySYDPGFFPyIcBI8PFSxNgHqe8iHC2bua4cM5A9KChCmoOrIcAPb%2FEo3RhtYyqeOjFuhbtUfJ0KnEfJz9x0Ke4KA3zDzLsfvx9%2B8PfdvT5cwFFIEOeS7Qkz7KZe80ty%2B9PtYzRMdlNHnEDlE5J7XjaIGiOa1FRB%2BCeeeOd1c4mpfxxniPq29pW1bpvFrrNtP2D0GiSrJb3w6%2BOUPd6L8NVXsYW7mPVuX8bKqmQMu2FQ6dCpNXD0xCAY4TdvSql%2FGjyzVn%2B%2B5FvxkpfK%2BtPaMRMCDvtbvfeBpuIu3%2FBdi7iENsVHbh7UPgOu9%2Fc1Ih12HTtVRkQcIpTL%2Bw%3D%3D" }}
								/>
								<View style={{ flexDirection: "row", alignItems: "center", width: "100%", paddingTop: 0, alignSelf: "center", gap: 10 }}>

									<StyledText style={{ fontSize: 24, fontFamily: "TitleWide", color: "black" }}>UEFA C</StyledText>

								</View>
								<Button round={7} label="Preinscribirse" onPress={() => Linking.openURL("https://onformacion.rfef.es/fed/OF4VYpRfgUJEH5rG2syJ/curso/W70goqplocPE1vIgEQKN")} />
							</View>
						</View>
						<View style={{ flex: 1, backgroundColor: "white", padding: 20, borderRadius: 7, height: "auto", borderWidth: 2, borderColor: "#E4002B" }}>
							<View style={{ flex: 1, paddingTop: 0, gap: 10 }}>
								<Image
									style={{ width: "100%", aspectRatio: 1.77, resizeMode: "cover", borderRadius: 15, marginBottom: 10 }}
									source={{ uri: "https://storage.googleapis.com/rfef-escuela-entrenadores.appspot.com/OF4VYpRfgUJEH5rG2syJ/admin/images/Futbol%20%2868%29.jpeg?GoogleAccessId=firebase-adminsdk-s5asd%40rfef-escuela-entrenadores.iam.gserviceaccount.com&Expires=2051222400&Signature=WeoMyeUwSMjAIJOpLHk51t8sAQ8FYXLlA0oX%2FrqGvS2Q2ifYAJqSAPkFhZwb2fxG%2Bp0czsEACtXCCJ4GyOC1dhaRQzMQIpwC%2FNLZ6%2BFHPxWPcCx%2F6r3phUwfVvE%2FjttVwucpQw7lSK%2B9X%2BTc8iQfujgrzTvlGlzdZIixNm%2BPqEdxCwz1DRn4LWs9YvZCz77rfMtm0U0h1RNhIBEPnBUyIdlpNhxPF7Ep34q2TOH38uW2rXZ88MWbUPBXwc7qmWNZW4wz2F1kMWXMwa518OzKnaOEjzG1P%2BLmDprKMb3aLiHJKUIxCjLMncaw3l9kO3EdOkGFeggz76XrXwCSQTzLaA%3D%3D" }}
								/>
								<View style={{ flexDirection: "row", alignItems: "center", width: "100%", paddingTop: 0, alignSelf: "center", gap: 10 }}>

									<StyledText style={{ fontSize: 24, fontFamily: "TitleWide", color: "black" }}>UEFA B</StyledText>

								</View>
								<Button round={7} label="Preinscribirse" onPress={() => Linking.openURL("https://onformacion.rfef.es/fed/OF4VYpRfgUJEH5rG2syJ/curso/SVEiaAXL4bfFR35qMfaR")} />
							</View>
						</View>
						<View style={{ flex: 1, backgroundColor: "white", padding: 20, borderRadius: 7, height: "auto", borderWidth: 2, borderColor: "#E4002B" }}>
							<View style={{ flex: 1, paddingTop: 0, gap: 10 }}>
								<Image
									style={{ width: "100%", aspectRatio: 1.77, resizeMode: "cover", borderRadius: 15, marginBottom: 10 }}
									source={{ uri: "https://storage.googleapis.com/rfef-escuela-entrenadores.appspot.com/OF4VYpRfgUJEH5rG2syJ/admin/images/Futbol%20%2879%29.jpeg?GoogleAccessId=firebase-adminsdk-s5asd%40rfef-escuela-entrenadores.iam.gserviceaccount.com&Expires=2051222400&Signature=osftVc9BSEGwoulAPzjonPAfTvJEAOvdB5m1F4OgX%2BkffKxfuOHL64VepUl%2FaKe5J2KH4bKJaSxz%2F%2B5yh7xYmm7eOtVU5qVGGJ6ysmbhqk2oFzy2DmLUK14Z3zpQVjo0kDUguVvdHOD2t4rhEdutYNsqvW%2BUZtbG18hZKmp9x2NdhBAyPDmVJb6OBqTDT7Wfc3ir2UJiSOMssCtV34KhlG1KqgZW%2BtxcwkUrFpEmOhinA1Lm6rfAP7rOqw5lgkj4ieagayzagUnL%2BHLQngeUYqmkNBlYcD9CMajSO3oa%2BlFXZIYmD5PvNDguIrLg6lfy6JJhn%2FGESHsZTd%2Fwpky4tw%3D%3D" }}
								/>
								<View style={{ flexDirection: "row", alignItems: "center", width: "100%", paddingTop: 0, alignSelf: "center", gap: 10 }}>

									<StyledText style={{ fontSize: 24, fontFamily: "TitleWide", color: "black" }}>UEFA A</StyledText>

								</View>
								<Button round={7} label="Preinscribirse" onPress={() => Linking.openURL("https://onformacion.rfef.es/fed/OF4VYpRfgUJEH5rG2syJ/curso/kF5F8gAFVwvzlKWlkXM5")} />
							</View>
						</View>
						<View style={{ flex: 1, backgroundColor: "white", padding: 20, borderRadius: 7, height: "auto", borderWidth: 2, borderColor: "#E4002B" }}>
							<View style={{ flex: 1, paddingTop: 0, gap: 10 }}>
								<Image
									style={{ width: "100%", aspectRatio: 1.77, resizeMode: "cover", borderRadius: 15, marginBottom: 10 }}
									source={require("/assets/images/reevaluacion-virtual.jpeg")}
								/>
								<View style={{ flexDirection: "row", alignItems: "center", width: "100%", paddingTop: 0, alignSelf: "center", gap: 10 }}>

									<StyledText style={{ fontSize: 24, fontFamily: "TitleWide", color: "black" }}>UEFA PRO</StyledText>

								</View>
								<Button round={7} label="Preinscribirse" onPress={() => Linking.openURL("https://onformacion.rfef.es/fed/OF4VYpRfgUJEH5rG2syJ/curso/RKb0BZOPnBhN29uUF1zp")} />
							</View>
						</View>
					</View>
					{/* <View >
						<StyledText style={{ fontSize: 24, marginBottom: 20, fontWeight: "bold", fontFamily: "TitleWide", color: "black" }}>Proceso de Renovación</StyledText>

						<StyledText style={{ fontSize: 20 }}>1 - Elija su opción preferida: presencial o virtual.</StyledText>
						<StyledText style={{ fontSize: 20 }}>2 - Complete las 15 horas requeridas de formación.</StyledText>
						<StyledText style={{ fontSize: 20 }}>3 - Acceda a su área personal en la plataforma de la RFEF.</StyledText>
						<StyledText style={{ fontSize: 20 }}>4 - Busque el botón "RENOVAR LICENCIA" en el apartado de licencias.</StyledText>
						<StyledText style={{ fontSize: 20 }}>5 - Realice el pago de 25€ para la renovación.</StyledText>
						<StyledText style={{ fontSize: 20 }}>6 - Su licencia se renovará automáticamente hasta el 31.12.2027.</StyledText>

					</View> */}
					{/* <CollapsableTwo /> */}
					{/* <CollapsableOne /> */}
				</View>

				<Footer limited={false} />

			</ScrollView>
		</>
	);
};

export default VirtualReevaluation;


const IconRow = ({ icon, text }) => {
	return (
		<View style={{ flexDirection: "row", alignItems: "center", width: "100%", paddingTop: 0, alignSelf: "center", gap: 10 }}>
			<Ionicons name={icon} size={24} />
			<StyledText style={{ fontSize: 18, fontWeight: "bold", color: "black" }}>{text}</StyledText>
		</View>
	)
}


const CollapsableOne = () => {
	const [isOpen, setIsOpen] = useState(false)

	return (
		<View style={{ backgroundColor: "white", padding: 20, borderRadius: 10, shadowColor: "rgba(0,0,0,.2)", shadowOffset: { width: 0, height: 5 }, shadowRadius: 10, elevation: 5 }}>

			<Pressable onPress={() => setIsOpen(!isOpen)} style={{ flexDirection: "row", alignItems: "center", width: "100%", justifyContent: "space-between", gap: 10 }}>
				<StyledText style={{ marginBottom: 0, fontSize: 24, fontWeight: "bold", fontFamily: "TitleWide", color: "black" }}>Instrucciones Revaluacion Virtual</StyledText>
				<Ionicons name="add" size={24} color="black" />
			</Pressable>

			<View style={{ gap: 10, height: isOpen ? undefined : 0, overflow: "hidden" }}>
				<StyledText style={{ marginTop: 20, fontSize: 20 }}>Te damos la bienvenida a la Revaluación Virtual de la licencia UEFA, A partir del día 11 a las 9 am hora española, se activará el curso dentro de la plataforma https://onformacion.rfef.es/ accede a la plataforma desde el botón de ACCESO situado en la parte superior a la derecha, si no recuerdas la contraseña puedes recuperarla desde esa pantalla y dentro de MIS PROGRAMAS verás el curso.</StyledText>
				<Image
					style={{ width: "100%", height: "400px", resizeMode: "contain", borderRadius: 15, marginBottom: 10 }}
					source={{ uri: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Frevaluacionlanding%2Fimage001.png?alt=media&token=cb0d0f44-534a-4893-8b51-c0f45bfb26c6" }}
				/>

				<StyledText style={{ fontSize: 20 }}>Una vez accedas al curso te aparecerán 2 opciones;</StyledText>
				<View style={{ paddingLeft: 20 }}>
					<StyledText style={{ fontSize: 18 }}><StyledText style={{ fontWeight: "bold" }}> -Video tareas de 15h de actualización.</StyledText> Consiste en visualizar las 15h de video tareas donde deberás entrar para hacer la revaluación completa. </StyledText>
					<StyledText style={{ fontSize: 18 }}><StyledText style={{ fontWeight: "bold" }}> -Certificados de otras federaciones nacionales,</StyledText> donde deberás subir los certificados que hayas realizado en otros países del convenio UEFA, si los subes en este apartado se descontarán de las 15 horas que debes realizar.</StyledText>
				</View>
				<Image
					style={{ width: "100%", height: "400px", resizeMode: "contain", borderRadius: 15, marginBottom: 10 }}
					source={{ uri: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Frevaluacionlanding%2Fimage002.png?alt=media&token=56121bd1-5612-42c1-aa32-96b296cb0b70" }}
				/>
				<StyledText style={{ fontSize: 20 }}>Dentro de las video tareas tendrás a tu disposición los videos, que se irán habilitando para ir realizando la visualización y completando las horas. En la parte inferior del video te aparecerá el horario de visualización del mismo.</StyledText>
				<Image
					style={{ width: "100%", height: "400px", resizeMode: "contain", borderRadius: 15, marginBottom: 10 }}
					source={{ uri: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Frevaluacionlanding%2Fimage003.png?alt=media&token=692208da-e7b1-4b3e-933b-8b4626d46fd0" }}
				/>

				<StyledText style={{ fontSize: 20 }}>Este año para comprobar la asistencia tendrás que clicar en los botones que aparecerán en pantalla de forma aleatoria y, muy importante, al finalizar el video clicar en COMPLETAR CONTENIDO. Te aparecerá una advertencia, informando en cada video.</StyledText>
				<View style={{ paddingLeft: 20 }}>
					<StyledText style={{ fontSize: 18 }}> - A lo largo del video aparecerán <StyledText style={{ fontWeight: "bold" }}>3 mensajes de confirmación</StyledText> de visualización.</StyledText>
					<StyledText style={{ fontSize: 18 }}> - Para superar este ejercicio <StyledText style={{ fontWeight: "bold" }}>deberás confirmar al menos 2</StyledText> de las 3 veces que aparezca ese mensaje.</StyledText>
					<StyledText style={{ fontSize: 18 }}> - Tendrás <StyledText style={{ fontWeight: "bold" }}>90 segundos</StyledText> para confirmar cada mensaje.</StyledText>
					<StyledText style={{ fontSize: 18 }}> - Puedes <StyledText style={{ fontWeight: "bold" }}>repetir tantas veces como quieras</StyledText> el ejercicio durante el tiempo disponible en las convocatorias.</StyledText>
				</View>
				<Image
					style={{ width: "100%", height: "400px", resizeMode: "contain", borderRadius: 15, marginBottom: 10 }}
					source={{ uri: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Frevaluacionlanding%2Fimage005.png?alt=media&token=23c1ebed-8ac1-4286-8db5-600c4bc2241a" }}
				/>
				<StyledText style={{ fontSize: 20 }}>Realizadas las 15h te aparecerá en tu apartado de LICENCIAS el botón de RENOVACIÓN, clicando sobre él te llevará al TPV de pago donde deberás abonar los 25€ de generar la licencia y la tendrás en automáticamente renovada en tu área personal, también se mandará a imprimir para en un plazo de 3 meses poder pasar a recogerla físicamente por las oficinas de la RFEF o enviar un mensajero a por ella con una autorización.</StyledText>
				<Image
					style={{ width: "100%", height: "400px", resizeMode: "contain", borderRadius: 15, marginBottom: 10 }}
					source={{ uri: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Frevaluacionlanding%2Fimage007.png?alt=media&token=3f225a9f-9112-482c-950a-84a8e89e4f93" }}
				/>
				<StyledText style={{ fontSize: 20 }}>Esperamos que las ponencias que te mostramos sean de tu interés y sirvan para seguir formándote y avanzando como entrenador.</StyledText>
				<Button
					round={7}
					height={35}
					style={{ marginTop: 20 }}
					label="Descarga el Programa"
					onPress={() => Linking.openURL("https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Frevaluacionlanding%2FProgramacio%CC%81n%20Revaluacio%CC%81n%20UEFA%20Virtual.pdf?alt=media&token=4c2bfb9f-9b73-46af-9c9f-706fbb7e4159")}
				/>
			</View>
		</View>
	)
}

const CollapsableTwo = () => {
	const [isOpen, setIsOpen] = useState(false)

	return (
		<View style={{ backgroundColor: "white", padding: 20, borderRadius: 10, shadowColor: "rgba(0,0,0,.2)", shadowOffset: { width: 0, height: 5 }, shadowRadius: 10, elevation: 5 }}>

			<Pressable onPress={() => setIsOpen(!isOpen)} style={{ flexDirection: "row", alignItems: "center", width: "100%", justifyContent: "space-between", gap: 10 }}>
				<StyledText style={{ marginBottom: 0, fontSize: 24, fontWeight: "bold", fontFamily: "TitleWide", color: "black" }}>Instrucciones Revaluacion Presencial</StyledText>
				<Ionicons name="add" size={24} color="black" />
			</Pressable>

			<View style={{ gap: 10, height: isOpen ? undefined : 0, overflow: "hidden" }}>
				<StyledText style={{ marginTop: 20, fontSize: 20 }}>Bienvenido a las jornadas presenciales de la Revaluación Licencia UEFA PRO</StyledText>

				<StyledText style={{ fontSize: 20 }}><StyledText style={{ fontWeight: "bold" }}>Lugar: </StyledText>jornada presencial se realizará en la sede de la RFEF en las Rozas de Madrid,</StyledText>
				<StyledText style={{ fontSize: 20 }}><StyledText style={{ fontWeight: "bold" }}>Horario: </StyledText>el viernes 11 desde las 15h hasta las 20:30h</StyledText>
				<StyledText style={{ fontSize: 20 }}><StyledText style={{ fontWeight: "bold" }}>Acceso: </StyledText>Podrás aparcar en el parking exterior y en los alrededores.</StyledText>
				<StyledText style={{ fontSize: 20 }}><StyledText style={{ fontWeight: "bold" }}>Pautas:</StyledText></StyledText>

				<View style={{ paddingLeft: 20 }}>
					<StyledText style={{ fontSize: 18 }}> - Recomendamos asistir con 30 minutos de antelación.</StyledText>
					<StyledText style={{ fontSize: 18 }}> - Deberás acreditarte en los mostradores de la entrada organizados por apellidos, donde te entregaremos un obsequio de bienvenida.</StyledText>
					<StyledText style={{ fontSize: 18 }}> - Al acceder tendrás que escanear el código QR desde el móvil, que te mostraremos en unos tótems en la entrada junto a cada mostrador, para ello tienes que entrar en https://onformacion.rfef.es/ acceder a la plataforma y clicar sobre ESCANER. En caso de no poder tendrás un área de incidencias.</StyledText>
					<StyledText style={{ fontSize: 18 }}> - A partir de las 14h estará habilitado un café de bienvenida</StyledText>
					<StyledText style={{ fontSize: 18 }}> - Al finalizar la jornada no olvides escanear el código QR de salida para que te computen las horas realizadas.</StyledText>
					<StyledText style={{ fontSize: 18 }}> - Esperamos que disfrutes de la jornada.</StyledText>
				</View>

				<StyledText style={{ fontSize: 20 }}>Realizadas las 15h te aparecerá en tu apartado de LICENCIAS el botón de RENOVACIÓN, clicando sobre él te llevará al TPV de pago donde deberás abonar los 25€ de generar la licencia y la tendrás en automáticamente renovada en tu área personal, también se mandará a imprimir para en un plazo de 3 meses poder pasar a recogerla físicamente por las oficinas de la RFEF o enviar un mensajero a por ella con una autorización.</StyledText>
				<Image
					style={{ width: "100%", height: "400px", resizeMode: "contain", borderRadius: 15, marginBottom: 10 }}
					source={{ uri: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Frevaluacionlanding%2Fimage007.png?alt=media&token=3f225a9f-9112-482c-950a-84a8e89e4f93" }}
				/>
				<StyledText style={{ fontSize: 20 }}>Esperamos que las ponencias que te mostramos sean de tu interés y sirvan para seguir formándote y avanzando como entrenador.</StyledText>
				<Button
					round={7}
					height={35}
					style={{ marginTop: 20 }}
					label="Descarga el Programa"
					onPress={() => Linking.openURL("https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Frevaluacionlanding%2FPrograma%20Revaluacion%20Licencia%20UEFA%20Presencial.pdf?alt=media&token=e0350b86-c550-4ed6-aee9-08ca6072e4f4")}
				/>
			</View>
		</View>
	)
}
