import { getApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { connectFunctionsEmulator, getFunctions, httpsCallable } from 'firebase/functions';
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Image, ImageBackground, KeyboardAvoidingView, ScrollView, Text, TouchableOpacity, View, useWindowDimensions } from "react-native";
import Button from "../../components/common/Button";
import TextField from "../../components/formComponents/TextField";

import { FontAwesome } from "@expo/vector-icons";
import { useFirestoreDocumentData } from "@react-query-firebase/firestore";
import { doc, getFirestore } from "firebase/firestore";
import { get } from "lodash";
import * as yup from "yup";
import Modal from "../../components/common/Modal";
import StyledText from "../../components/common/StyledText";
import SelectField from "../../components/formComponents/SelectField";
import useYupValidationResolver from "../../utils/useYupValidationResolver";
import ValidateSpanishID from "../../utils/validateSpanishId";

const Register = ({ navigation }) => {
    const screen = useWindowDimensions()
    const [success, setSuccess] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null);
    const [termsModal, setTermsModal] = useState(false)
    const [recordModal, setRecordModal] = useState(false)


    const documentTypeRef = useRef("dni")

    const app = getApp()
    const auth = getAuth()
    const functions = getFunctions(app, "europe-west1")
    process.env.NODE_ENV === "development" && connectFunctionsEmulator(functions, "localhost", 5001);
    const db = getFirestore()
    const dataProtectionRef = doc(db, `globalPages/lnDtyT3wuH2CHkOsRCPc`)
    const recordPermisionRef = doc(db, `globalPages/MeCQrlI1bc6HQ4u3ib6F`)

    const { data, isLoading, error: errorLoadingLOPD } = useFirestoreDocumentData(["globalPage", { slug: "proteccion-datos" }], dataProtectionRef, {}, {});
    const { data: dataRecordPermision, error: errorLoadingPermision } = useFirestoreDocumentData(["globalPage", { slug: "permiso-grabación" }], recordPermisionRef, {}, {});


    useEffect(() => {
        auth.currentUser && navigation.navigate("StackPublic", { screen: "Home" })
    }, [])

    const handleRegister = async (values) => {
        if (values.email !== values.repeatEmail) return alert("El correo electrónico debe coincidir")
        setLoading(true)
        setError(false)
        const registerUser = httpsCallable(functions, "registerUser", {
            region: "europe-west1"
        })

        registerUser({
            email: values.email,
            password: values.password,
            name: values.name,
            surname: values.surname,
            idNumber: values.idNumber.toLocaleUpperCase().replace(/[^A-Z0-9]+/ig, ""),
            idType: values.idType,
        }).then(res => {
            // console.log(res)

            signInWithEmailAndPassword(auth, values.email, values.password).then(res => {
                console.log(res)
            }).catch(err => {
                console.log(err)
            })

            setSuccess(true)
            setLoading(false)
        }).catch(err => {
            console.log(err.message)
            setLoading(false)
            setError(err.message)
        })
    };


    const sendEmailAgain = () => {
        const user = auth.currentUser.email
        setLoading(true)
        setError(false)
        const sendUserVerificationEmail = httpsCallable(functions, "sendUserVerificationEmail", {
            region: "europe-west1"
        })

        sendUserVerificationEmail({ email: user }).then(res => {
            setSuccess(true)
            setLoading(false)
        }).catch(err => {
            setLoading(false)
            setError("Debe esperar 5 minutos para volver a enviar el correo de verificación")
        })
    }


    const validationSchema = yup.object({
        email: yup.string().trim().email("El email introducido no es válido").required("El email es obligatorio"),
        repeatEmail: yup.string().trim().email("El email introducido no es válido").required("El email es obligatorio").oneOf([yup.ref('email'), null], 'El email debe coincidir'),
        password: yup.string().trim().min(6, "La contraseña debe tener al menos 6 caracteres").required("La contraseña es obligatoria"),
        name: yup.string().trim().required("El nombre es obligatorio"),
        surname: yup.string().trim().required("El apellido es obligatorio"),
        idNumber: yup.string().trim().test("validateId", "El documento introducido no es válido", (ev) => {
            const p = ValidateSpanishID.ValidateSpanishID(ev)
            return documentTypeRef.current === "passport" ? true : p.valid
        }).required("El número de identificación es obligatorio"),
        idType: yup.string().oneOf(["dni", "nie", "passport"]).required("El tipo de documento es obligatorio"),
        lopd: yup.boolean().oneOf([true], "Debe aceptar los terminos y condiciones").required("Debe aceptar los terminos y condiciones"),
        record: yup.boolean().oneOf([true, false], "Debe dar permiso para la grabación"),

    });
    const resolver = useYupValidationResolver(validationSchema);

    const {
        handleSubmit,
        setValue,
        getValues,
        watch,
        reset,
        trigger,
        control,
        formState: { errors },
    } = useForm({
        mode: "onSubmit",
        resolver,
        defaultValues: {
            email: "",
            repeatEmail: "",
            password: "",
            name: "",
            surname: "",
            idType: "dni",
            idNumber: "",
            lopd: false,
            record: false
        },
    });

    const documentType = watch("idType")
    const lopd = watch("lopd")
    const record = watch("record")

    useEffect(() => {
        documentTypeRef.current = documentType
    }, [documentType])

    return (
        <>
            <KeyboardAvoidingView style={{ flex: 1, height: "100%" }} behavior="padding">
                <Button
                    style={{ position: "absolute", height: 35, top: 15, left: 15, zIndex: 10 }}
                    round={7}
                    height={35}
                    width={35}
                    color="white"
                    icon={"chevron-back"}
                    reverse
                    onPress={() => {
                        reset()
                        navigation.canGoBack() ? navigation.goBack() : navigation.navigate("StackPublic", { screen: "HomeScreen" })
                    }}
                />
                <ScrollView contentContainerStyle={{ flexGrow: 1 }} style={{ flex: 1 }}>

                    <ImageBackground
                        style={{
                            flex: 1,
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "center",
                            zIndex: 2,
                            paddingVertical: 20
                        }}
                        source={{ uri: "https://images.unsplash.com/photo-1543351611-58f69d7c1781?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1287&q=80" }}
                        resizeMethod="resize"
                    >

                        <View style={{ backgroundColor: "rgba(0,0,0,.8)", flex: 1, position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: 1 }} />

                        {!success ?
                            <View style={{ width: "100%", maxWidth: 600, flex: 1, alignItems: "center", paddingHorizontal: 20, justifyContent: "center", zIndex: 2 }}>
                                <View style={{ height: 150, width: 300, marginBottom: 10 }}>
                                    <Image style={{ resizeMode: "contain", height: 150, width: 300 }} source={{ uri: "https://www.rfef.es/themes/custom/rfef/logo-icon.svg" }} />
                                    {/* <Image style={{ resizeMode: "contain", height: 150, width: 300 }} source={require("../../assets/images/logo.png")} /> */}
                                </View>
                                <View style={{ marginBottom: 0 }}>
                                    <Text style={{ color: "white", fontSize: 22, textAlign: "center" }}>
                                        Formulario de Afiliación
                                    </Text>
                                </View>
                                <View style={{ width: "100%" }}>
                                    <TextField trim rules={[]} labelColor="white" error={errors["email"]} name={"email"} key="email" label="Email" placeholder="Email..." control={control} />
                                </View>
                                <View style={{ width: "100%", marginTop: 10 }}>
                                    <TextField trim rules={[]} labelColor="white" error={errors["repeatEmail"]} name={"repeatEmail"} key="repeatEmail" label="Repite el Email" placeholder="Repite el Email..." control={control} />
                                </View>
                                <View style={{ width: "100%", flexDirection: "row", marginTop: 10 }}>
                                    <TextField labelColor="white" error={errors["name"]} name={"name"} key="name" label="Nombre" placeholder="Nombre..." control={control} />
                                    <TextField labelColor="white" containerProps={{
                                        style: {
                                            marginLeft: 10,
                                            flex: 1
                                        }
                                    }} error={errors["surname"]} name={"surname"} key="surname" label="Apellidos" placeholder="Apellidos..." control={control} />
                                </View>
                                <View style={{ width: "100%", marginTop: 10, flexDirection: "row" }}>
                                    <SelectField
                                        options={[
                                            { label: "DNI", value: "dni" },
                                            { label: "NIE", value: "nie" },
                                            { label: "Pasaporte", value: "passport" }
                                        ]}
                                        labelKey="label"
                                        valueKey="value"
                                        labelColor="white"
                                        error={errors["idType"]}
                                        name={"idType"}
                                        key="idType"
                                        label="Tipo de Documento"
                                        placeholder="Tipo de Documento..."
                                        control={control}
                                    />
                                    <TextField
                                        autoComplete="off"
                                        containerProps={{
                                            style: {
                                                marginLeft: 10,
                                                flex: 1
                                            }
                                        }}
                                        labelColor="white"
                                        error={errors["idNumber"]}
                                        name={"idNumber"}
                                        key="idNumber"
                                        label="Número de Documento"
                                        placeholder="Número de Documento..."
                                        control={control}
                                    />
                                </View>
                                <View style={{ width: "100%", marginTop: 10 }}>
                                    <TextField trim labelColor="white" error={errors["password"]} name={"password"} key="password" secureInput label="Contraseña (Min. 6 caracteres)" placeholder="Contraseña..." control={control} />
                                </View>
                                <View style={{ width: "100%", marginTop: 20 }}>
                                    {/**CHECK PARA ACEPTAR LOS TERMINOS Y CONDICIONES  */}
                                    <TouchableOpacity
                                        onPress={() => {
                                            if (lopd) {
                                                setValue("lopd", false)
                                                trigger("lopd")
                                            } else {
                                                setTermsModal(true)
                                            }
                                        }}
                                        style={{ flex: 1, flexDirection: "row", alignItems: "center" }}
                                    >
                                        <View style={{ marginRight: 10, borderWidth: 3, borderColor: "white", borderRadius: 4, width: 30, height: 30, alignItems: "center", justifyContent: "center" }}>
                                            {lopd && <FontAwesome name={"check"} size={18} color={"white"} />}
                                        </View>
                                        <View style={{ flex: 1 }}>
                                            <StyledText style={{ color: "white", fontSize: 16 }}>Al registrarme acepto los terminos y condiciones</StyledText>
                                        </View>
                                    </TouchableOpacity>{errors?.lopd && (
                                        <StyledText style={{ marginTop: 10, fontSize: 12, color: "red" }}>
                                            {get(errors, "lopd.message", "")}
                                        </StyledText>
                                    )}
                                    {/**CHECK PARA ACEPTAR LA GRABACION */}
                                    <TouchableOpacity
                                        onPress={() => {
                                            if (record) {
                                                setValue("record", false)
                                                trigger("record")
                                            } else {
                                                setRecordModal(true)
                                            }
                                        }}
                                        style={{ flex: 1, flexDirection: "row", alignItems: "center", marginTop: 10 }}
                                    >
                                        <View style={{ marginRight: 10, borderWidth: 3, borderColor: "white", borderRadius: 4, width: 30, height: 30, alignItems: "center", justifyContent: "center" }}>
                                            {record && <FontAwesome name={"check"} size={18} color={"white"} />}
                                        </View>

                                        <View style={{ flex: 1 }}>
                                            <StyledText style={{ color: "white", fontSize: 16 }}>Al registrarme, acepto la grabación</StyledText>
                                        </View>

                                    </TouchableOpacity>

                                    {errors?.record && (
                                        <StyledText style={{ marginTop: 10, fontSize: 12, color: "red" }}>
                                            {get(errors, "record.message", "")}
                                        </StyledText>
                                    )}
                                </View>


                                {error && (
                                    <View
                                        style={{
                                            width: "100%",
                                            borderRadius: 10,
                                            marginTop: 15,
                                            paddingHorizontal: 15,
                                            alignItems: "center",
                                            justifyContent: "center",
                                            paddingVertical: 15,
                                            backgroundColor: "#E9312B",
                                        }}
                                    >
                                        <Text style={{ fontFamily: "Regular", color: "white", fontSize: 18 }}>{error}</Text>
                                    </View>
                                )}
                                <View style={{ marginTop: 25 }}>
                                    <Button round={7} height="35px" onPress={handleSubmit(handleRegister)} loading={loading} label="Registrarme" />
                                </View>

                                <TouchableOpacity style={{ marginTop: 20 }} onPress={() => navigation.navigate("Login")}>
                                    <Text style={{ color: "white", fontSize: 18, fontFamily: "DemiBold" }}>¿Ya eres miembro? Accede aquī</Text>
                                </TouchableOpacity>
                            </View>
                            :
                            <View style={{ width: "100%", maxWidth: 600, flex: 1, alignItems: "center", paddingHorizontal: 20, justifyContent: "center", zIndex: 2 }}>

                                <View style={{ height: 150, width: 300, marginBottom: 20 }}>
                                    <Image style={{ resizeMode: "contain", height: 150, width: 300 }} source={{ uri: "https://www.rfef.es/themes/custom/rfef/logo-icon.svg" }} />
                                </View>
                                <View style={{ marginBottom: 20 }}>
                                    <Text style={{ color: "white", fontSize: 30, textAlign: "center" }}>
                                        ¡El registro se ha realizado correctamente!
                                    </Text>
                                </View>
                                <View style={{ width: "100%", marginTop: 0 }}>
                                    <Text style={{ color: "white", fontSize: 18, fontFamily: "Regular", textAlign: "center" }}>
                                        En breve recibirás un email para verificar la dirección introducida.
                                        Si en unos minutos no lo has recibido, revisa la carpeta de SPAM o haz click en el botón volver a enviar el email.
                                    </Text>
                                </View>
                                {error && (
                                    <View
                                        style={{
                                            width: "100%",
                                            borderRadius: 10,
                                            marginTop: 15,
                                            paddingHorizontal: 15,
                                            alignItems: "center",
                                            justifyContent: "center",
                                            paddingVertical: 15,
                                            backgroundColor: "#E9312B",
                                        }}
                                    >
                                        <Text style={{ fontFamily: "Regular", color: "white", fontSize: 18 }}>{error}</Text>
                                    </View>
                                )}
                                <View style={{ width: "100%", marginTop: 30 }}>
                                    <Button loading={loading} onPress={sendEmailAgain} label="Volver a enviar" round={7} />
                                </View>

                            </View>
                        }

                    </ImageBackground>
                </ScrollView>
            </KeyboardAvoidingView>

            {termsModal ? <Modal padding={0} modalContainerStyle={{ maxHeight: screen?.height - 150, maxWidth: 550, width: "95%", borderRadius: 10 }}>

                <ScrollView style={{ flex: 1 }} contentContainerStyle={{ padding: 15 }}>
                    <StyledText style={{ fontSize: 34, fontFamily: "Title", color: "black", textAlign: "center" }}>Terminos y Condiciones</StyledText>
                    {!errorLoadingLOPD ?
                        <div dangerouslySetInnerHTML={{ __html: data?.content || "<p></p>" }} style={{ fontFamily: "Regular", fontSize: 16, color: "#303030" }} />
                        :
                        <View style={{ alignItems: "center" }}>
                            <StyledText>Ocurrió un error cargando los terminos y condiciones, para acceder a ellos siga el siguiente enlace</StyledText>
                            <Button color={"#E4002B"} label="Finalizar" height="35px" round="7px" onPress={() => {
                                window.open("https://onformacion.rfef.es/proteccion-datos", "_blank")
                            }} />
                        </View>
                    }
                </ScrollView>
                <View style={{ padding: 15, flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                    <Button color={"#f2f2f2"} label="Rechazar" height="35px" round="7px" onPress={() => {
                        setValue("lopd", false)
                        trigger("lopd")
                        setTermsModal(false)
                    }} />
                    <Button color={"#E4002B"} label="Aceptar" height="35px" round="7px" onPress={() => {
                        setValue("lopd", true)
                        trigger("lopd")
                        setTermsModal(false)
                    }} />

                </View>

            </Modal > : null}
            {recordModal ? <Modal padding={0} modalContainerStyle={{ maxHeight: screen?.height - 150, maxWidth: 550, width: "95%", borderRadius: 10 }}>

                <ScrollView style={{ flex: 1 }} contentContainerStyle={{ padding: 15 }}>
                    <StyledText style={{ fontSize: 34, fontFamily: "Title", color: "black", textAlign: "center" }}>GRABACIÓN</StyledText>
                    {!errorLoadingPermision ?
                        <div dangerouslySetInnerHTML={{ __html: dataRecordPermision?.content || "<p></p>" }} style={{ fontFamily: "Regular", fontSize: 16, color: "#303030" }} />
                        :
                        <View style={{ alignItems: "center" }}>
                            <StyledText>Ocurrió un error cargando los terminos y condiciones, para acceder a ellos siga el siguiente enlace</StyledText>
                            <Button color={"#E4002B"} label="Finalizar" height="35px" round="7px" onPress={() => {
                                window.open("https://onformacion.rfef.es/proteccion-datos", "_blank")
                            }} />
                        </View>
                    }
                </ScrollView>
                <View style={{ padding: 15, flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                    <Button color={"#f2f2f2"} label="Rechazar" height="35px" round="7px" onPress={() => {
                        setValue("record", false)
                        trigger("record")
                        setRecordModal(false)
                    }} />
                    <Button color={"#E4002B"} label="Aceptar" height="35px" round="7px" onPress={() => {
                        setValue("record", true)
                        trigger("record")
                        setRecordModal(false)
                    }} />

                </View>

            </Modal > : null}
        </>
    );
};

export default Register;
